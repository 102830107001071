// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the side menu state domain
 */
const selectSideMenuState = () => (state) => state.get('sidemenu');

/**
 * Select the auth state
 */

const selectIsSideMenuOpen = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('isSideMenuOpen'));

const selectShouldResizeMap = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('shouldResizeMap'));

const selectIsActiveBarOpen = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('isActiveBarOpen'));

const selectIsFleetOverviewOpen = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('isFleetOverviewOpen'));

const selectMultipleSelect = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('multipleSelect'));

const selectSelectedVehicles = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('selectedVehicles'));

const selectSelectedVehiclesData = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('selectedVehiclesData'));

const selectCurrentRightSideMenu = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('currentRightSideMenu'));
const selectIsLangUnitConfigured = () =>
    createSelector(selectSideMenuState(), (substate) => substate.get('isLangUnitConfigured'));

export {
    selectIsSideMenuOpen,
    selectShouldResizeMap,
    selectIsActiveBarOpen,
    selectIsFleetOverviewOpen,
    selectMultipleSelect,
    selectSelectedVehicles,
    selectSelectedVehiclesData,
    selectCurrentRightSideMenu,
    selectIsLangUnitConfigured,
};
