// @flow

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectLoggedInAsUser, selectUser } from 'services/Authentication/selectors';
import { adminLogoutAsUser } from 'services/Authentication/thunks';
import { injectIntl } from 'react-intl';
import Impersonation from 'components/Impersonation';

const ImpersonationContainer = (props) => <Impersonation {...props} />;

const mapStateToProps = createStructuredSelector({
    loggedInAsUser: selectLoggedInAsUser(),
    user: selectUser(),
});

const mapDispatchToProps = { adminLogoutAsUser };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ImpersonationContainer));
