// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import Workbook from 'react-excel-workbook';
import { i18nDistanceSymbol } from 'services/Units/thunks';
import excel from 'assets/excel_icon.png';

class GeoExportExcel extends React.Component<Props, State> {
    render() {
        const { data, title, bar, dataKey, intl, totalBar, dateRange } = this.props;
        const columns = bar.map((item) => (
            <Workbook.Column key={item.barName} label={item.name} value={item.barName} />
        ));
        const totalcolumn = (
            <Workbook.Column
                key={'total'}
                label={intl.formatMessage({
                    id: 'containers.Reports.Generators.total.algo',
                })}
                value={'total'}
            />
        );
        return totalBar ? (
            <Workbook
                filename={title.replace(/[\/]/g, '&') + '_' + dateRange + '.xlsx'}
                element={<img width={'35px'} height={'35px'} src={excel} />}
            >
                <Workbook.Sheet data={data} name="Sheet A">
                    <Workbook.Column
                        key={dataKey}
                        label={
                            dataKey == 'geo_name'
                                ? intl.formatMessage({
                                      id: 'views.Reports.SideMenu.Dashboard.Geofences.mode',
                                  })
                                : intl.formatMessage({
                                      id: 'components.SideMenu.Vehicles',
                                  })
                        }
                        value={dataKey}
                    />
                    {columns}
                    {totalcolumn}
                </Workbook.Sheet>
            </Workbook>
        ) : (
            <Workbook
                filename={title.replace(/[\/]/g, '&') + '_' + dateRange + '.xlsx'}
                element={<img width={'35px'} height={'35px'} src={excel} />}
            >
                <Workbook.Sheet data={data} name="Sheet A">
                    <Workbook.Column
                        key={dataKey}
                        label={
                            dataKey == 'geo_name'
                                ? intl.formatMessage({
                                      id: 'views.Reports.SideMenu.Dashboard.Geofences.mode',
                                  })
                                : intl.formatMessage({
                                      id: 'components.SideMenu.Vehicles',
                                  })
                        }
                        value={dataKey}
                    />
                    {columns}
                </Workbook.Sheet>
            </Workbook>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            i18nDistanceSymbol,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(GeoExportExcel))
);
