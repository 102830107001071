import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import DatePicker from 'antd/es/date-picker';
import { connect } from 'react-redux';

const { RangePicker } = DatePicker;

const RangeDatePicker = (props) => {
    const [dates, setDates] = useState([]);
    const [hackValue, setHackValue] = useState();
    const [value, setValue] = useState();
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'hours') > 48;
        const tooEarly = dates[1] && dates[1].diff(current, 'hours') > 48;
        return tooEarly || tooLate;
    };

    const onOpenChange = open => {
        if (open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        }
    };
    const onChange = (val) => {
        setValue(val);
        props.onCalendarChange(val);
    };
    return (
        <RangePicker
            value={hackValue || value}
            disabledDate={disabledDate}
            onCalendarChange={val => setDates(val)}
            onChange={onChange}
            onOpenChange={onOpenChange}
            showTime={{ format: 'HH:mm' }}
            format={'YYYY-MM-DD HH:mm'}
            placeholder={[props.intl.formatMessage({
                id: 'containers.Reports.Generators.Driving.From',
            }), props.intl.formatMessage({
                id: 'containers.Reports.Generators.Driving.To',
            })]}
        />
    );
};
export default connect(
)(injectIntl(RangeDatePicker));
