// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const DataListHeader = styled.div`
    width: 100%;
`;

DataListHeader.Item = styled.div`
    grid-column: span 4;
    cursor: pointer;
`;

DataListHeader.Container = styled.div``;

DataListHeader.GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 60px);
    ${DataListHeader.Item}:nth-child(odd) {
        background-color: ${colors.grayF8};
    }
`;

DataListHeader.Split = styled.div`
    height: ${(props) => props.height || '1px'};
    background-color: ${(props) => props.color || `${colors.black}`};
`;

DataListHeader.HeaderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 35px);
`;

DataListHeader.HeaderCaret = styled.img`
    width: 14px;
    height: 9px;
`;

DataListHeader.HeaderText = styled.div`
    margin-right: 14px;
    font-weight 600;
    float: left;
`;

DataListHeader.Header = styled.div`
    height: 35px;
`;

DataListHeader.HeaderItem = styled.div`
    cursor: pointer;
`;

DataListHeader.FilterContainer = styled.div`
    width: 50%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 50px;
    padding-right: 23px;
`;
DataListHeader.SearchContainer = styled.div`
    margin-right: 10px;
    flex: 1;
`;

DataListHeader.ControllerContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

DataListHeader.ControllerHeader = styled.div`
    display: felx;
    align-items: center;
    flex: 1;
    font-weight: 600;
    font-size: 22px;
`;

DataListHeader.ActionContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
`;

DataListHeader.ActionButtonContainer = styled.div``;

DataListHeader.ActionSelect = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
`;

DataListHeader.ExtraButton = styled.div`
    margin-left: 10px;
`;

export default DataListHeader;
