// @flow

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { bindActionCreators } from 'redux';
import Header from 'components/Modals/Header';
import Modal from 'components/Modals';
import OutlineButton from 'components/OutlineButton';

import { Confirmation, Submit } from 'styles/modal';
import colors from 'styles/colors';

import { closeModal } from 'services/Modal/thunks';

type Props = {
    headerTextId: ?string,
    messageTextId: ?string,
    confirmationCallback: () => void,
    closeModal: () => void,
};

class ConfirmationModal extends React.PureComponent<Props> {
    handleConfirm = () => {
        this.props.confirmationCallback();
    };

    handleCancel = () => this.props.closeModal();

    render() {
        return (
            <Modal
                size={{
                    width: 55,
                    height: 38,
                }}
            >
                <Confirmation>
                    <Header headerTextId={this.props.headerTextId} />
                    <div style={{height: '60px'}}>
                        <FormattedMessage id={this.props.messageTextId} />
                    </div>
                    <Submit width={100}>
                        <Submit.Button margin={'0 4.5px 0 0'}>
                            <OutlineButton
                                outlineColor={colors.green63}
                                backgroundColor={colors.green63}
                                hoverBackgroundColor={colors.white}
                                hoverTextColor={colors.green63}
                                textColor={colors.white}
                                onClick={this.handleConfirm}
                            >
                                <FormattedMessage
                                    id={'components.Confirmation.DeleteUser.Confirm'}
                                />
                            </OutlineButton>
                        </Submit.Button>
                        <Submit.Button margin={'0 0 0 4.5px'}>
                            <OutlineButton
                                outlineColor={colors.green63}
                                backgroundColor={colors.white}
                                hoverBackgroundColor={colors.green63}
                                hoverTextColor={colors.white}
                                textColor={colors.black}
                                onClick={this.handleCancel}
                            >
                                <FormattedMessage
                                    id={'components.Confirmation.DeleteUser.Cancel'}
                                />
                            </OutlineButton>
                        </Submit.Button>
                    </Submit>
                </Confirmation>
            </Modal>
        );
    }
}

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            closeModal,
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(ConfirmationModal);
