/* eslint-disable flowtype-errors/show-errors */
// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';
import { selectLocale } from 'services/Language/selectors';
import { SideMenu, Header, Image } from 'styles/sidemenu';
import { toggleSideMenu, showSideMenu, hideSideMenu } from 'services/SideMenu/thunks';
import { withRouter } from 'react-router-dom';
import CloseButton from 'components/CloseButton';
import colors from 'styles/colors';
import React from 'react';
import type { ReduxDispatch } from 'types';
import OrganizationsImage from 'assets/organization-black.svg';
import Users from 'assets/users.svg';

type Props = {
    animationState: ?string,
};

type State = {
    showSideMenu: boolean,
};

class SideMenuComponent extends React.PureComponent<Props, State> {
    componentDidMount() {
        this.props.showSideMenu();
    }

    handleToggleSideMenu = () => {
        this.props.toggleSideMenu();
    };

    handleOnClickCloseButton = () => this.props.toggleSideMenu();

    renderDefault = () => (
        <React.Fragment>
            <SideMenu.Item>
                <Image.Container>
                    <Image src={OrganizationsImage} width={32} height={32} />
                </Image.Container>
                <SideMenu.ItemText>
                    <SideMenu.NavItem to={`/${this.props.locale}/interne/orgs`}>
                        <FormattedMessage id="components.SideMenu.Organizations" />
                    </SideMenu.NavItem>
                </SideMenu.ItemText>
            </SideMenu.Item>
            <SideMenu.Item>
                <Image.Container>
                    <Image src={Users} width={32} height={32} />
                </Image.Container>
                <SideMenu.ItemText>
                    <SideMenu.NavItem to={`/${this.props.locale}/interne/users`}>
                        <FormattedMessage id="components.SideMenuOrganization.Users" />
                    </SideMenu.NavItem>
                </SideMenu.ItemText>
            </SideMenu.Item>
            <SideMenu.Split />
            {/* <SideMenu.Item>
                    <Image.Container>
                        <Image src={ReportsImage} width={22} height={27} />
                    </Image.Container>
                    <SideMenu.ItemText>
                        <FormattedMessage id="components.SideMenu.Reports" />
                    </SideMenu.ItemText>
                </SideMenu.Item>
                <SideMenu.Split />
                <SideMenu.Item>
                    <Image.Container>
                        <Image src={AdminImage} width={31} height={30} />
                    </Image.Container>
                    <SideMenu.ItemText>
                        <FormattedMessage id="components.SideMenu.Administration" />
                    </SideMenu.ItemText>
                </SideMenu.Item> */}
            {/* <SideMenu.Split /> */}
        </React.Fragment>
    );

    render() {
        return (
            <SideMenu isOpen={this.props.isOpen}>
                <SideMenu.Container style={{ zIndex: '2' }} animation={this.props.animationState}>
                    <SideMenu.Header>
                        <Header>
                            <Header.Text color={colors.white}>
                                <FormattedMessage id="components.SideMenu.Header" />
                            </Header.Text>
                        </Header>
                    </SideMenu.Header>
                    <SideMenu.Split />
                    <SideMenu.ListContainer>
                        {this.props.children ? this.props.children : this.renderDefault()}
                    </SideMenu.ListContainer>
                    <CloseButton
                        isOpen={this.props.isOpen}
                        onClick={this.handleToggleSideMenu}
                        top={24}
                        size={30}
                        zIndex={5}
                        backgroundColor={colors.sideMenuGreen}
                    />
                </SideMenu.Container>
                {/* <CloseButton
                    isOpen={this.props.isOpen}
                    onClick={this.handleOnClickCloseButton}
                    offset={this.props.offset}
                    zIndex={1}
                /> */}
                {/* <Footer>
                    <Image.Container>
                        <Image src={FavouritesImage} width={25} height={22} />
                    </Image.Container>
                    <span>
                        <FormattedMessage id="components.SideMenu.Favourites" />
                    </span>
                </Footer> */}
            </SideMenu>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    locale: selectLocale(),
    isOpen: selectIsSideMenuOpen(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            toggleSideMenu,
            hideSideMenu,
            showSideMenu,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SideMenuComponent)
);
