// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screens from 'styles/screen';

const List = styled.ul`
    list-style: none;
    height: 80px;
    padding: 0;
    display: flex;
    align-items: center;
`;

const Item = styled.li`
    margin: ${(props) => props.margin || '0 0 0 0'};
    display: block;
    position: static;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    ${(props) =>
        props.clicked &&
        `
        box-shadow: 2px 0 1px 1px rgba(0, 0, 0, 0.16);
    `} z-index: 3;
    opacity: ${(props) => (props.disabled ? '0.3' : '1.0')};
`;

Item.CheckboxWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: -32px;
    transform: translateY(-50%);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: ${({ multipleSelect }) => (multipleSelect ? '14px' : '0px')};
    transition: all 250ms ease-in-out 0s;
`;

Item.Checkbox = styled.input.attrs({
    type: 'checkbox',
})`
    pointer-events: none;
`;

Item.Container = styled.div`
    display: flex;
    align-items: center;
    border-left: 4px solid ${({ borderColor }) => borderColor.toString()};
    height: auto;
    min-height: 80px;
    height: auto !important;    /* for IE as it does not support min-height */
    height: 80px;               /* for IE as it does not support min-height */
    flex-direction: row;
    position: relative;
    transition: all 250ms ease-in-out 0s;
    transform: translateX(0);
    padding-left: 51px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: ${({ multipleSelect }) => (multipleSelect ? 50 : 25)}px;
    margin-right: 25px;
    @media (max-width: ${screens.small.replace('6', '5')}) {
    }
`;

Item.Name = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    justify-content: ${({ direction }) => direction || 'center'};
`;

Item.Option = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    white-space: nowrap;
    margin-right: 5px;
    justify-content: ${({ direction }) => direction || 'center'};
`;

Item.NameContainer = styled.div`
    display: flex;
    max-width: 225px;
    align-items: center;
    justify-content: center;
    font-family: OpenSans;
    font-weight: 800;
    font-size: 14px;
    width: 100%;
    color: ${(props) => props.color};
    // padding: 5px;
    // margin-top: -5px;
`;

Item.OptionsContainer = styled.div`
    display: flex;
    max-width: 225px;
    align-items: center;
    justify-content: center;
    font-family: OpenSans;
    font-weight: 800;
    font-size: 14px;
    color: ${(props) => props.color};
    text-align: right;
    padding-right: 10px;
`;

Item.Column = styled.div`
    ${(props) => (props.flex || props.flex === '0' ? `flex: ${props.flex};` : '')} display: flex;
    flex-direction: column;
`;

Item.DateTime = styled.div`
    display: block;
    overflow: hidden;

    > div {
        padding: 0;
        margin-right: 5px;
        float: left;
        font-size: ${(props) => props.fontSize || 9}px;
        text-transform: uppercase;
    }
`;

Item.Text = styled.div`
    font-family: OpenSans;
    font-weight: ${(props) => props.fontWeight || 600};
    font-size: ${(props) => props.fontSize || 11}px;
    line-height: 13.5px;
    padding: ${(props) => props.padding || '0'};
    color: ${(props) => props.color || colors.black};
    white-space: ${(props) => (props.wrap ? 'normal' : 'nowrap')};
`;

Item.Address = styled.div`
    font-family: OpenSans;
    font-weight: 600;
    font-size: ${(props) => props.fontSize || 11}px;
    line-height: 13.5px;
    padding: ${(props) => props.padding || '0'};
    color: ${(props) => props.color || colors.black};
    white-space: ${(props) => (props.wrap ? 'normal' : 'nowrap')};
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > div {
        display: inline;
    }
`;

Item.AddressSegment = styled.div`
    margin-right: 5px;
`;

Item.Row = styled.div`
    width: 100%;
    display: block;
    ${({ flex }) => `flex: ${flex};`} flex-direction: row;
`;

Item.Icon = styled.img`
    margin: ${(props) => props.margin || '0'};
    object-fit:contain;
`;

Item.StatusIcon = styled.div`
    display: flex;
    flex-direction:column;
    position: absolute;
    left: 15px;
    margin: auto 0;
    transition: all 250ms ease-in-out 0s;
`;

Item.StatusIconWrapper = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
`;

Item.Indicator = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`;

List.Container = styled.div`
    flex: 1;
    flex-direction: column;
    display: block;
    height: 20px;
    margin-left: 40px;
    margin-bottom: 10px;
`;

List.Item = styled.li`
    display: flex;
    font-family: OpenSans;
    font-weight: 600;
    font-size: 11px;
    margin-right: 15px;
`;

List.SplitVertical = styled.div`
    height: calc(100% - 30px);
    width: 1px;
    background-color: ${colors.grayE4};
`;

Item.Split = styled.div`
    height: 1px;
    background-color: ${(props) => props.color || `${colors.black}`};
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
`;

Item.Hoovered = styled.div`
    display: flex;
    flex-direction: column;
    transition: all 250ms ease-in-out 0s;

    &:hover {
        div {
            transition: all 250ms ease-in-out 0s;
        }
        > div:first-child {
            ${(props) => (!props.disabled ? `background: ${colors.grayE0};` : '')} ${(props) =>
                !props.disabled
                    ? `padding-left: 60px;`
                    : ''}

            > div:first-child > div:first-child {
                ${(props) => (!props.disabled ? `left: 20px;` : '')};
            }
        }
    }
`;

export { Item, List };
