// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import BackgroundImage from 'assets/background.png';
import Orca from 'assets/orca-sidemenu-bg.png';

const ForgotPasswordPage = styled.div`
    display: flex;
    flex: 1;
`;

ForgotPasswordPage.ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
ForgotPasswordPage.LoginButton = styled.button`
    display: flex;
    font-family: Gotham-Medium;
    color: ${colors.white};
    background-color: ${colors.black};
    border: none;
    width: 100%;
    height: 45px;
    margin-top: 45px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 5px;

    @media (min-width: ${screen.small}) {
        width: 160px;
    }
`;

ForgotPasswordPage.Background = styled.div`
    width: 100%;
    background-image: url(${BackgroundImage});
    background-size: cover;
`;

ForgotPasswordPage.ForgotPassword = styled.div`
    font-size: 14px;
    color: ${colors.gray46};
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

ForgotPasswordPage.Icon = styled.img`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

ForgotPasswordPage.Split = styled.div`
    height: 1px;
    background-color: ${colors.green82};
`;

const Content = styled.div`
    display: flex;
    position: fixed;
    width: 350px;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    flex-direction: column;
    background-color: ${colors.sideMenuGreen};
    background-image: url(${Orca});
`;

Content.Logo = styled.div`
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 38px;
`;

Content.LogoImage = styled.img`
    height: 102px;
    width: 190px;
`;

Content.Text = styled.div`
    color: ${colors.green73};
    font-size: ${(props) => props.fontSize || '16px'};
`;

Content.ForgotPasswordText = styled.div`
    font-family: OpenSans;
    font-weight: 600;
    font-size: 14px;
    color: ${colors.white};
    margin-bottom: 50px;
`;

Content.Form = styled.form`
    width: 100%;
    margin-top: 20px;
`;

export { Content, ForgotPasswordPage };
