import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApCheckIcon, ApVehicleIcon } from '../Icons';
import './styles.scss';
import {
    selectDateAvailabity,
    selectReservationDate,
    selectReservation,
} from '../../services/AutoPartage/selectors';
import { getDateAvailability } from '../../services/AutoPartage/thunks';
import moment from 'moment';

function generateHoursArray() {
    const hoursArray = [];
    let startHour = 0;
    const endHour = 24;
    const interval = 2;

    while (startHour < endHour) {
        var hourRange;
        if (startHour == 22) {
            hourRange = '22:00 - 00:00';
        } else {
            hourRange = `${startHour.toString().padStart(2, '0')}:00 - ${(startHour + interval)
                .toString()
                .padStart(2, '0')}:00`;
        }
        hoursArray.push(hourRange);
        startHour += interval;
    }

    return hoursArray;
}
function ApReservationHour(props) {
    const [time, setTime] = useState('');
    const [availablityArray, setAvailibityArray] = useState([]);
    const [loading, setLoading] = useState(true);
    const hoursArray = generateHoursArray();
    const [startIndex, setStartIndex] = useState(0)
    useEffect(() => {
        const isToday = moment().isSame(props.reservationDate, 'day');
        if (isToday) {
            const currentHour = parseInt(moment().format("HH"));
            const startIndexCalculated = currentHour / 2;
            setStartIndex(startIndexCalculated);
        }
    }, [props.reservationDate])
    useEffect(() => {
        const endDate = moment(props.reservationDate).add(1, 'd');
        const endDateString = endDate.format('YYYY-MM-DD');
        const date = {
            dateDebut: props.reservationDate + 'T00:00:00.000Z',
            dateFin: endDateString + 'T00:00:00.000Z',
            sectorId: props.clickedSector.idSector
        };
        props.getDateAvailability(date);
        if (props.reservation) {
            const dateTimeStart = new Date(props.reservation.dateReservationDebut);
            const dateTimeEnd = new Date(props.reservation.dateReservationFin);
            let heurStart = dateTimeStart.getHours();
            let heurEnd = dateTimeEnd.getHours();
            if (heurStart < 10) {
                heurStart = `0${heurStart}`
            }
            if (heurEnd < 10) {
                heurEnd = `0${heurEnd}`
            }
            setTime(`${heurStart}:00 - ${heurEnd}:00`);
        }
        //setTime('22:00 - 00:00');
    }, []);
    useEffect(
        () => {
            if (props.availablityArray) {
                setAvailibityArray(props.availablityArray);
                setLoading(false);
            }
        },
        [props.availablityArray]
    );
    useEffect(
        () => {
            //sending selected reservation hours to parent stepper

        }, [])
    useEffect(() => {
        if (props.availablityArray) {
            setAvailibityArray(props.availablityArray);
            setLoading(false);
            console.log(loading);
        }
    }, [props.availablityArray])
    useEffect(() => {
        //sending selected reservation hours to parent stepper
        props.sendReservationHours(time);
    }, [time])

    const handleChange = (event) => {
        setTime(event.target.value);
    }
    return (
        loading ? ('') :
            (<Select
                className={"ap_time_selector"}
                value={time}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Without label' }}
                defaultOpen={true}
            >{availablityArray.slice(startIndex).map((item, index) => {
                const vehiclesCount = item.vehiclesCount;
                const isAvailable = item.isAvailable;
                const chipColor = vehiclesCount === 0 ? '#6E6E6E' : ((vehiclesCount > 5) ? '#4F9C20' : '#cb750291');
                const isSelected = (time === hoursArray.slice(startIndex)[index]);
                const isDisabled = !vehiclesCount || (vehiclesCount === 0) || (!isAvailable);
                return <MenuItem value={hoursArray.slice(startIndex)[index]} disabled={isDisabled} style={{display:'flex', justifyContent:'space-evenly', padding:'5px'}}>
                    <div>{hoursArray.slice(startIndex)[index]}</div>
                    <Box className='ap_available_vehicles_box'>
                        {isAvailable ?
                            (<Chip
                                sx={{ backgroundColor: chipColor }}
                                className={"ap_available_vehicles"}
                                icon={<ApVehicleIcon />}
                                // label={vehiclesCount.toString().padStart(2, '0')}>
                                label={vehiclesCount ? vehiclesCount.toString().padStart(2, '0') : 0}>
                            </Chip>)
                            : (
                                <Chip
                                    sx={{ backgroundColor: '#6E6E6E' }}
                                    className={"ap_available_vehicles "}
                                    label={"Reservé"}>
                                </Chip>
                            )
                        }
                        <div className="ap_selection_checkmark">{(isSelected ? (<ApCheckIcon />) : (''))}</div>
                    </Box>
                </MenuItem>
            })}
            </Select>
            ));
}
const mapStateToProps: Object = createStructuredSelector({
    reservationDate: selectReservationDate(),
    availablityArray: selectDateAvailabity(),
    reservation: selectReservation(),
});
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getDateAvailability,
        },
        dispatch
    );
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ApReservationHour))
);
