// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const OrganizationPage = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: ${(props) => (!props.isOpen ? '30px 50px 0 110px;' : '30px 50px 0 50px;')};
`;

OrganizationPage.Item = styled.div`
    grid-column: span 3;
    cursor: pointer;
`;

OrganizationPage.Container = styled.div``;

OrganizationPage.GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 60px);
    ${OrganizationPage.Item}:nth-child(odd) {
        background-color: ${colors.grayF8};
    }
`;

OrganizationPage.Split = styled.div`
    height: ${(props) => props.height || '1px'};
    background-color: ${(props) => props.color || `${colors.black}`};
`;

OrganizationPage.HeaderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 35px);
`;

OrganizationPage.HeaderCaret = styled.img`
    width: 14px;
    height: 9px;
`;

OrganizationPage.HeaderText = styled.div`
    font-weight ${(props) => props.fontWeight};
    font-size: ${(props) => props.fontSize || '18px'};
    margin-top: ${(props) => props.marginTop};
    float: left;
`;

OrganizationPage.Header = styled.div`
    height: 35px;
`;

OrganizationPage.HeaderItem = styled.div`
    cursor: pointer;
`;

OrganizationPage.FilterContainer = styled.div`
    width: 50%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 50px;
    padding-right: 23px;
    & > div {
        margin-right: 10px;
    }
`;
OrganizationPage.SearchContainer = styled.div`
    margin-right: 10px;
`;

OrganizationPage.ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;
OrganizationPage.ActionButtonContainer = styled.div`
    flex: 1;
    margin-left: 10px;
    ${(props) => (props.shrink ? 'flex-grow: 0.2' : null)};
`;

OrganizationPage.DataHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 50px;
`;

OrganizationPage.ViewMore = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
`;

OrganizationPage.GroupButtons = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

OrganizationPage.ParentInfo = styled.div`
    padding: 12px 6px;
`;

export default OrganizationPage;
