// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectEquipmentState = () => (state) => state.get('equipment');

const selectEquipmentTypes = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('equipmentTypes'));

const selectEquipment = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('equipment'));

const selectEquipmentPagination = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('paginated'));

const selectEquipmentError = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('error'));

const selectEquipmentSearchResults = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('equipmentSearchResults'));

const selectEquipmentAssociationErrors = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('associationError'));

const selectFuelLevelData = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('fuelLevelData'));

const selectLoading = () =>
    createSelector(selectEquipmentState(), (substate) => substate.get('loading'));

export {
    selectEquipmentTypes,
    selectEquipment,
    selectEquipmentPagination,
    selectEquipmentSearchResults,
    selectEquipmentAssociationErrors,
    selectEquipmentError,
    selectFuelLevelData,
    selectLoading,
};
