// @flow

import { fromJS } from 'immutable';
import {
    TOGGLE_SHOW_USER_MODAL,
    TOGGLE_SHOW_ORGANIZATION_MODAL,
    TOGGLE_SHOW_EQUIPMENT_MODAL,
    TOGGLE_SHOW_EQUIPMENT_CONFIG_MODAL,
    TOGGLE_CONFIRMATION_MODAL,
    CHANGEPASSWORD_LOADING,
    CHANGEPASSWORD_SUCCESS,
    CHANGEPASSWORD_FAILURE,
    TOGGLE_SHOW_CHANGEPASSWORD_MODAL,
    CLOSE_MODAL,
} from './actions';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_USER_SUCCESS,
    EDIT_ORGANIZATION_SUCCESS,
} from 'services/Organization/actions';

import {
    CREATE_BRANCH_DIVISION_SUCCESS,
    GET_BRANCH_USER_SUCCESS,
    EDIT_BRANCH_SUCCESS,
    GET_BRANCH_SUCCESS,
} from 'services/Branch/actions';

import {
    EDIT_DIVISION_SUCCESS,
    GET_DIVISION_SUCCESS,
    GET_DIVISION_USER_SUCCESS,
} from 'services/Division/actions';

import {
    ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS,
    ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS,
    ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS,
} from 'services/Equipment/actions';

import { EDIT_USER_SUCCESS } from 'services/User/actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    showModal: false,
    modalType: null,
    modalAction: null,
    userId: -1,
    equipmentId: -1,
});

function modalServiceReducer(state = initialState, action: GenericActionType) {
    switch (action.type) {
        case TOGGLE_SHOW_ORGANIZATION_MODAL: {
            return state
                .set('showModal', !state.get('showModal'))
                .set('modalType', action.modalType)
                .set('modalAction', action.modalAction);
        }
        case TOGGLE_SHOW_USER_MODAL: {
            return state
                .set('showModal', !state.get('showModal'))
                .set('modalType', action.modalType)
                .set('modalAction', action.modalAction)
                .set('userId', action.userId ? action.userId : -1);
        }
        case TOGGLE_SHOW_EQUIPMENT_MODAL: {
            return state
                .set('showModal', !state.get('showModal'))
                .set('modalType', action.modalType)
                .set('modalAction', action.modalAction)
                .set('equipmentId', action.equipmentId);
        }
        case TOGGLE_SHOW_EQUIPMENT_CONFIG_MODAL: {
            return state
                .set('showModal', !state.get('showModal'))
                .set('modalType', action.modalType)
                .set('modalAction', action.modalAction)
                .set('equipmentId', action.equipmentId);
        }
        case TOGGLE_CONFIRMATION_MODAL: {
            return state
                .set('showModal', !state.get('showModal'))
                .set('modalType', action.modalType)
                .set('confirmationData', fromJS(action.data));
        }
        case TOGGLE_SHOW_CHANGEPASSWORD_MODAL: {
            return state
                .set('showModal', false)
                .set('modalType', action.modalType)
                .set('modalError', false)
                .set('modalLoading', false);
        }
        case CHANGEPASSWORD_LOADING: {
            return state.set('modalError', false).set('modalLoading', true);
        }
        case CHANGEPASSWORD_SUCCESS: {
            return state
                .set('showModal', false)
                .set('modalType', null)
                .set('modalLoading', false);
        }
        case CHANGEPASSWORD_FAILURE: {
            return state.set('modalError', action.data).set('modalLoading', false);
        }
        case CLOSE_MODAL: {
            return state
                .set('showModal', false)
                .set('modalType', null)
                .set('modalAction', null)
                .set('userId', -1)
                .set('equipmentId', -1);
        }
        case GET_ORGANIZATION_SUCCESS:
        case GET_ORGANIZATION_USER_SUCCESS:
        case EDIT_ORGANIZATION_SUCCESS:
        case GET_BRANCH_SUCCESS:
        case EDIT_BRANCH_SUCCESS:
        case GET_BRANCH_USER_SUCCESS:
        case CREATE_BRANCH_DIVISION_SUCCESS:
        case GET_DIVISION_SUCCESS:
        case EDIT_DIVISION_SUCCESS:
        case GET_DIVISION_USER_SUCCESS:
        case ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS:
        case ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS:
        case ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS:
        case EDIT_USER_SUCCESS: {
            return state
                .set('showModal', false)
                .set('modalType', null)
                .set('modalAction', false);
        }
        case LOCATION_CHANGE: {
            return state
                .set('showModal', false)
                .set('modalType', null)
                .set('modalAction', false);
        }
        default:
            return state;
    }
}

export default modalServiceReducer;
