// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Dashboard } from 'styles/dashboard';

import dateFns from 'date-fns';

import type {
    ReduxDispatch,
    ImmutableList,
    ImmutableMap,
    UserType,
    ColumnType,
    PaginatedType,
} from 'types';

import OrganizationListItem from 'components/OrganizationListItemEconomie';
import DashboardDataComponent from 'components/DashboardDataComponent';
import GroupButton from 'components/GroupButton';
import OutlineButton from 'components/OutlineButton';

import { selectLocale } from 'services/Language/selectors';

import CaretDown from 'assets/caret-down.png';
import CaretUp from 'assets/caret-up.png';

import colors from 'styles/colors';

type SortFunction = ?(a: string | number, b: string | number) => void;

type Props = {
    columns: Array<ColumnType>,
    id: ?string,
    dataKeys: ?Array<string>,
    caretSource: ?string,
    tableData: ?ImmutableList<string>,
    totalItemsCount: ?number,
    tableFooter: ?ImmutableList<string>,
    users: ?boolean,
    list?: boolean,
    onClickDataItem: ?(number) => void,
    onClickViewMore: ?() => void,
    onClickEditUser: ?(user: UserType) => void,
    onClickDeleteUser: ?(user: UserType) => void,
    onClickHeaderItem: ?(key: string, sortMethod: SortFunction) => void,
    locale: string,
    component: ?ReactComponent,
    paginated: ?PaginatedType,
    loading: ?boolean,
    itemsDisplayed?: number,
    showHeaderCaret?: boolean,
    report?: boolean,
    reportFilterText: *,
};

type State = {
    showViewMore: boolean,
    showUserOptions: ?number,
    showEquipmentOptions: ?number,
    sortKey: string,
    // sortedData: ?ImmutableList<string>,
};

class DashboardComponent extends React.PureComponent<Props, State> {
    static defaultProps = {
        list: false,
        paginated: null,
        sliceUsers: true,
        itemsDisplayed: 10,
        showHeaderCaret: true,
        report: false,
    };

    state = {
        showViewMore: false,
        showUserOptions: -1,
        showEquipmentOptions: -1,
        sortKey: '',
        // sortedData: null,
    };

    handleOnClickDataItem = (id: number) => () => {
        this.props.onClickDataItem(id);
    };

    handleShowUserOptions = (user: UserType) => {
        if (this.state.showUserOptions === user.get('userId')) {
            return this.setState({
                showUserOptions: -1,
            });
        }

        this.setState({
            showUserOptions: user.get('userId'),
        });
    };

    handleShowEquipmentOptions = (equipment: EquipmentType) => {
        if (this.state.showEquipmentOptions === equipment.get('id')) {
            return this.setState({
                showEquipmentOptions: -1,
            });
        }

        this.setState({
            showEquipmentOptions: equipment.get('id'),
        });
    };

    formatLastLogin = (user) => {
        if (!user.get('lastLogin')) {
            return null;
        }

        return dateFns.format(user.get('lastLogin'), 'DD/MM/YYYY - h:mm A');
    };

    getHeaderCaret = (key: string) => (this.state.sortKey === key ? CaretUp : CaretDown);

    /**
     * Render that shows
     * @param {number} columnSize Decides on how many columns
     * @param {boolean} showExtraColumns show or not total stop time
     * @param {boolean} tableFooter get totalVehicleUtilisationTime && totalVehicleDistance
     * @return {Component} rendered component
     */
    renderFooterItem = (columnSize, showExtraColumns, tableFooter) => {
        return (
            <Dashboard.FooterContainer columns={columnSize}>
                <Dashboard.FooterItem key={this.props.columns[0].key}>
                    <Dashboard.FooterText>
                        <FormattedMessage id={'containers.Reports.Generators.Total'} />{' '}
                        {this.props.totalItemsCount}{' '}
                        <FormattedMessage id={'containers.Reports.Generators.Trajet'} />{' '}
                        {this.props.reportFilterText.date}
                        <br />
                        {this.props.reportFilterText.time}
                    </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                    <Dashboard.FooterText>
                        {tableFooter.get('totalVehicleUtilisationTime')}
                        <br />
                        {!showExtraColumns && tableFooter.get('totalVehicleStopTime')}
                    </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                    <Dashboard.FooterText>
                        {tableFooter.get('totalVehicleTimeIdle')}
                    </Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                    <Dashboard.span>
                        {tableFooter.get('totalVehicleDistance')}
                    </Dashboard.span>
                </Dashboard.FooterItem>
            </Dashboard.FooterContainer>
        );
    };

    renderTableData = () => {
        let dataItems = this.props.tableData;
        if (!this.props.tableData) {
            return false;
        }

        if (this.state.sortedData && this.state.sortedData.size > 0) {
            dataItems = this.state.sortedData;
        }

        if (
            typeof this.props.onClickViewMore === 'function' &&
            !this.props.list &&
            dataItems &&
            dataItems.size > this.props.itemsDisplayed
        ) {
            // show the first 10 items.
            dataItems = dataItems.take(this.props.itemsDisplayed);
            this.setState({
                showViewMore: true,
            });
        }

        if (this.props.report) {
            dataItems = dataItems.take(this.props.itemsDisplayed);
        }

        if (
            this.props.paginated &&
            this.props.paginated.get('currentPage') !== this.props.paginated.get('lastPage')
        ) {
            this.setState({
                showViewMore: true,
            });
        }

        const listItems = dataItems.map((data: ImmutableMap, index: number) => {
            let componentProps = {
                rowSpan: this.props.columns.length,
                data,
            };

            if (this.props.component) {
                componentProps = {
                    rowSpan: this.props.columns.length,
                    data,
                    ...this.props,
                    showUserOptions: this.state.showUserOptions,
                    showEquipmentOptions: this.state.showEquipmentOptions,
                    handleShowUserOptions: this.handleShowUserOptions,
                    handleShowEquipmentOptions: this.handleShowEquipmentOptions,
                };
            }

            if (!data) {
                return false;
            }

            return (
                <Dashboard.DataItemCustom
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${data.get(this.props.id)}-${index}`}
                    columns={this.props.columns.length}
                    onClick={
                        this.props.onClickDataItem
                            ? this.handleOnClickDataItem(data.get(this.props.id))
                            : null
                    }
                >
                    {this.props.component ? (
                        <DashboardDataComponent
                            {...componentProps}
                            component={this.props.component}
                        />
                    ) : (
                        <OrganizationListItem
                            columns={this.props.columns}
                            data={data}
                            rowSpan={this.props.columns.length}
                        />
                    )}
                </Dashboard.DataItemCustom>
            );
        });
        return (
            <Dashboard.GridContainerCustom columns={this.props.columns.length}>
                {listItems}
            </Dashboard.GridContainerCustom>
        );
    };

    renderShowMoreResults = () => {
        return this.props.tableData.size > 0 ? (
            <Dashboard.ViewMore onClick={this.props.onClickViewMore}>
                <OutlineButton outlineColor={colors.green73} hoverTextColor={colors.white}>
                    {this.props.loading ? (
                        <FormattedMessage id={'components.Loading'} />
                    ) : (
                        <FormattedMessage id={'views.BranchPage.ViewMore'} />
                    )}
                </OutlineButton>
            </Dashboard.ViewMore>
        ) : null;
    };

    

    render() {
        const tmp = this.props.tableData;
        let columnSize = 7;
        let distance = 10;
        let vehName = 4;
        let utilisationTime = 9;
        let totalTimeIdle = 2;
        let keyOnCount = 6;
        let keyOffCoun = 12;
        let autokeyOnCount = 0;
        let autokeyOffCount = 5;
        let coupeMotorEcotime = 3;
        let reductionGes = 1;
        let coutEconomise = 8;
        return (
            <Dashboard loading={this.props.loading}>
                <Dashboard.HeaderContainer columns={columnSize}>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[vehName].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[utilisationTime].label}
                            <br />
                            {this.props.columns[totalTimeIdle].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[distance].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[keyOnCount].label}
                            <br />
                            {this.props.columns[keyOffCoun].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[autokeyOnCount].label}
                            <br />
                            {this.props.columns[autokeyOffCount].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[coupeMotorEcotime].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[coutEconomise].label}
                            <br />
                            {this.props.columns[reductionGes].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                </Dashboard.HeaderContainer>
                <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
                {this.renderTableData()}
                {this.state.showViewMore ? this.renderShowMoreResults() : null}
                <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
            </Dashboard>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    locale: selectLocale(),
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(DashboardComponent)
);
