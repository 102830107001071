import React, { Component } from 'react';
import SvgParser from './SvgParser';

const svgCache = new Map(); // Cache for storing fetched SVG data
const fetchPromises = new Map(); // Map to track ongoing fetch promises

export default class SvgColor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            svg: null
        };
        this.getSvgData = this.getSvgData.bind(this);
    }

    componentDidMount() {
        this.getSvgData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.svg !== this.props.svg) {
            this.getSvgData();
        }
    }

    async getSvgData() {
        const { svg } = this.props;

        if (svgCache.has(svg)) {
            this.setState({ svg: svgCache.get(svg) });
            return;
        }

        if (fetchPromises.has(svg)) {
            const data = await fetchPromises.get(svg);
            this.setState({ svg: data });
            return;
        }

        const fetchPromise = fetch(svg)
            .then(res => {
                let reader = res.body.getReader();
                let decoder = new TextDecoder('utf-8');
                return reader.read().then(result => decoder.decode(result.value));
            })
            .then(data => {
                svgCache.set(svg, data);
                fetchPromises.delete(svg);
                this.setState({ svg: data });
                return data;
            })
            .catch(error => {
                console.error('Error fetching SVG:', error);
                fetchPromises.delete(svg);
            });

        fetchPromises.set(svg, fetchPromise);
    }

    render() {
        let { svg } = this.state;
        const { colors, width, rotate } = this.props;

        if (svg != null) {
            svg = SvgParser.decode(svg);
            svg = colors != null && Array.isArray(colors) ? SvgParser.changeColors(svg, colors) : svg;
            svg = rotate != null && !isNaN(rotate) ? SvgParser.changeRotate(svg, rotate) : svg;
            svg = SvgParser.encode(svg);

            const style = width != null && !isNaN(width) ? { width: width } : {};

            return (
                <div>
                    <img src={svg} style={style} alt="SVG" />
                </div>
            );
        } else {
            return <div />;
        }
    }
}
