// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Button = styled.div`
    height: 100%;
    cursor: pointer;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width || '100%'};
    height: 40px;
    white-space: nowrap;
`;

Button.Text = styled.div`
    color: ${({ color }) => color};
`;

const Container = styled.div`
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    border: 1px solid ${({ outlinecolor }) => outlinecolor};
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    font-family: OpenSans-SemiBold;


    ${({ stopAnimation }) =>
        !stopAnimation &&
        `
        transition: background-color 250ms ease-out;
    `} ${({ backgroundcolor }) =>
        backgroundcolor &&
        `
            ${Button} {
                background-color: ${backgroundcolor};
            }
    `}&:hover {
        border: 1px solid
            ${({ outlinecolor, hoverOutlineColor }) => hoverOutlineColor || outlinecolor};

        background-color: ${(props) =>
            props.disabled ? colors.grayE0 : props.hoverBackgroundColor || props.outlinecolor};

        ${Button.Text} {
            color: ${({ hoverTextColor }) => hoverTextColor};
        }
    }

    ${(props) =>
        props.disabled
            ? `
        ${Button} {background-color: ${colors.grayE0}`
            : ''};

    ${(props) =>
        props.selected
            ? `
        ${Button} {
            background-color: ${props.hoverBackgroundColor || props.outlinecolor};
        }
    `
            : ''};
    ${(props) => props.padding && `padding: ${props.padding}`};
`;

export { Button, Container };
