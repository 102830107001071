// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const DriverScoreCard = styled.div`
    .fa-question-circle {
        font-size: 20px;
        background: ${colors.white};
        color: ${colors.green57};
        border-radius: 20px;
        margin-left: 10px;
        cursor: pointer;
    }
    .ant-tooltip-content{
        background-color: white;
        color: #202020;
    }
    .styles__Tooltip-gaiqOM .jRJhxq{
        background-color: white;
        color: #202020;
    }
    .ant-tooltip-inner{
        background-color: white;
        color: #202020;
    }
`;
DriverScoreCard.TableVirtual = styled.div`
    .ReactVirtualized__Grid.bottomRightGrid{
        overflow-x: hidden !important;
    }
`;

DriverScoreCard.Header = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: black;
    .header {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
    }
`;

DriverScoreCard.AntdTooltip = styled.div`
    .fa-question-circle {
        font-size: 20px;
        background: ${colors.white};
        color: ${colors.green57};
        border-radius: 20px;
        margin-left: 10px;
        cursor: pointer;
    }

`;


export default DriverScoreCard;
