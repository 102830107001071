// @flow

// TYPES

// REDUX TYPES
export const GET_MAINTENANCE_SUCCESS = 'app/services/Maintenance/GET_MAINTENANCE_SUCCESS';
export const GET_MAINTENANCE_ERROR = 'app/services/Maintenance/GET_MAINTENANCE_ERROR';
export const GET_SELECTED_VEHICLE_SUCCESS = 'app/services/Maintenance/GET_SELECTED_VEHICLE_SUCCESS';
export const GET_MAINTENANCE_VEHICLE_HISTORY_SUCCESS =
    'app/services/Maintenance/GET_MAINTENANCE_VEHICLE_HISTORY_SUCCESS';
export const GET_CONFIG_MAINTENANCE_SUCCESS =
    'app/services/Maintenance/GET_CONFIG_MAINTENANCE_SUCCESS';

export const GET_SELECTED_VEHICLE_ALARMS = 'app/services/Maintenance/GET_SELECTED_VEHICLE_ALARMS';

export const GET_MAINTENANCE_VEHICLE_BY_MAINT_ID =
    'app/services/Maintenance/GET_MAINTENANCE_VEHICLE_BY_MAINT_ID';

export const GET_REINITIALIZE_ALARMS = 'app/services/Maintenance/GET_REINITIALIZE_ALARMS';

export const GET_MAINTENANCE_EMAILS = 'app/services/Maintenance/GET_MAINTENANCE_EMAILS';

export const GET_MAINTENANCE_MESSAGE = 'app/services/Maintenance/GET_MAINTENANCE_MESSAGE';

// MAIN ACTIONS
/**
 *
 */
export const receivedMaintenanceSuccess = (alarms: object) => ({
    type: GET_MAINTENANCE_SUCCESS,
    alarms,
});

export const receivedMaintenanceVehHistorySuccess = (vehAlarms: object) => ({
    type: GET_MAINTENANCE_VEHICLE_HISTORY_SUCCESS,
    vehAlarms,
});

export const receivedConfigMaintenanceSuccess = (configMaintenance: object) => ({
    type: GET_CONFIG_MAINTENANCE_SUCCESS,
    configMaintenance,
});

export const receivedMaintenanceError = () => ({
    type: GET_MAINTENANCE_ERROR,
});

export const receivedVehicleSuccess = (vehicle: object) => ({
    type: GET_SELECTED_VEHICLE_SUCCESS,
    vehicle,
});

export const receivedVehicleAlarmSuccess = (vehicleAlarm: object) => ({
    type: GET_SELECTED_VEHICLE_ALARMS,
    vehicleAlarm,
});

export const receivedMaintenanceVehByMaintIdSuccess = (alarms: object) => ({
    type: GET_MAINTENANCE_VEHICLE_BY_MAINT_ID,
    alarms,
});

export const receivedReinitializeAlarmsSuccess = (alarms: object) => ({
    type: GET_REINITIALIZE_ALARMS,
    alarms,
});

export const receivedMaintenanceEmailSuccess = (emails: object) => ({
    type: GET_MAINTENANCE_EMAILS,
    emails,
});

export const receivedMaintenanceMsgSuccess = (msg: object) => ({
    type: GET_MAINTENANCE_MESSAGE,
    msg,
});
