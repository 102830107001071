// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';
import socketMiddleware from './services/socket/socketMiddleware';

const configureStore = (initialState = {}, history, socketClient) => {
    // List middleware here
    let middlewares = [routerMiddleware(history), thunk, socketMiddleware(socketClient)];


    // Add support for Redux dev tools
    const composeEnhancers =
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
        compose;

    const store = createStore(
        rootReducer,
        fromJS(initialState),
        composeEnhancers(applyMiddleware(...middlewares))
    );

    return store;
};

export default configureStore;
