// @flow

import {
    receivedToggleOrganizationModal,
    receivedToggleEquipmentModal,
    receivedToggleEquipmentConfigModal,
    receivedToggleUserModal,
    receivedCloseModal,
    receivedToggleChangePasswordModal,
    receivedChangePasswordLoading,
    receivedChangePasswordSuccess,
    receivedChangePasswordFailure,
    receivedToggleConfirmationModal,
} from './actions';

import { changePassword } from '../Authentication/resources';

export const toggleOrganizationModal = (action: ?string) => (dispatch: ReduxDispatch) =>
    dispatch(receivedToggleOrganizationModal(action));

export const toggleChangePasswordModal = (action: ?string) => (dispatch: ReduxDispatch) =>
    dispatch(receivedToggleChangePasswordModal(action));

export const changeUserPassword = (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    dispatch(receivedChangePasswordLoading());

    return changePassword(oldPassword, newPassword, confirmPassword, locale, token)
        .then((response) => {
            return dispatch(receivedChangePasswordSuccess(response));
        })
        .catch((error) => {
            return dispatch(receivedChangePasswordFailure(error));
        });
};

export const getChangePasswordPasswordError = () => getState().getIn(['modalError']); // .getIn(['modalError', 'locale']);

export const toggleUserModal = (action: ?string, userId: ?number) => (dispatch: ReduxDispatch) =>
    dispatch(receivedToggleUserModal(action, userId));

export const toggleEquipmentModal = (action: ?string, equipmentId: ?number) => (
    dispatch: ReduxDispatch
) => dispatch(receivedToggleEquipmentModal(action, equipmentId));

export const toggleConfirmationModal = (data: *) => (dispatch: ReduxDispath) =>
    dispatch(receivedToggleConfirmationModal(data));

export const closeModal = () => (dispatch: ReduxDispatch) => dispatch(receivedCloseModal());

export const toggleEquipementConfigModal = (action: ?string, equipmentId: ?number) => (
    dispatch: ReduxDispatch
) => dispatch(receivedToggleEquipmentConfigModal(action, equipmentId));
