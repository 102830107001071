// @flow

import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
    GET_EQUIPMENT_TYPES_SUCCESS,
    GET_EQUIPMENT,
    GET_EQUIPMENT_FAIL,
    GET_EQUIPMENT_SEARCH_RESULTS_SUCCESS,
    ASSOCIATE_EQUIPMENT_DIVISION_ERROR,
    ASSOCIATE_EQUIPMENT_BRANCH_ERROR,
    ASSOCIATE_EQUIPMENT_ORGANIZATION_ERROR,
    ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS,
    ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS,
    ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS,
    CREATE_EQUIPMENT_ERROR,
    GET_FUEL_LEVEL_SUCCESS,
    CLEAR_FUEL_LEVEL,
    GRAPH_LOADING
} from './actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    equipmentTypes: {},
    equipment: [],
    equipmentSearchResults: [],
    equipmentSearchQuery: '',
    paginated: {},
    associationError: null,
    error: {},
    fuelLevelData: {},
    loading: false,
});

function equipmentServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case GRAPH_LOADING: {
            return state.set('loading', true);
        }
        case GET_EQUIPMENT_TYPES_SUCCESS: {
            return state.set('equipmentTypes', fromJS(action.equipmentTypes));
        }
        case GET_EQUIPMENT: {
            const equipment = state.get('equipment').concat(fromJS(action.response.data));
            return state.set('equipment', equipment).set(
                'paginated',
                fromJS({
                    currentPage: action.response.meta.currentPage,
                    lastPage: action.response.meta.lastPage,
                })
            );
        }
        case GET_EQUIPMENT_FAIL: {
            return state.set('equipment', fromJS({})).set('loading', false);
        }
        case GET_EQUIPMENT_SEARCH_RESULTS_SUCCESS: {
            const equipment =
                state.get('equipmentSearchQuery') === action.searchQuery
                    ? state.get('equipmentSearchResults').concat(fromJS(action.response.data))
                    : fromJS(action.response.data);
            return state
                .set('equipmentSearchResults', equipment)
                .set(
                    'paginated',
                    fromJS({
                        currentPage: action.response.meta.currentPage,
                        lastPage: action.response.meta.lastPage,
                    })
                )
                .set('equipmentSearchQuery', action.searchQuery);
        }
        case ASSOCIATE_EQUIPMENT_DIVISION_ERROR:
        case ASSOCIATE_EQUIPMENT_BRANCH_ERROR:
        case ASSOCIATE_EQUIPMENT_ORGANIZATION_ERROR: {
            return state.set('associationError', fromJS(action.error));
        }
        case ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS:
        case ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS:
        case ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS: {
            return state.set('associationError', null);
        }
        case LOCATION_CHANGE: {
            return state.set('associationError', null);
        }
        case CREATE_EQUIPMENT_ERROR:
            return state.set('error', action.payload.error);
        case GET_FUEL_LEVEL_SUCCESS:
            return state.set('fuelLevelData', action.fuelLevelData).set('loading', false);
        case CLEAR_FUEL_LEVEL: {
            return state.set('fuelLevelData', fromJS({}));
        }
        default:
            return state;
    }
}

export default equipmentServiceReducer;
