// @flow

import {
    receivedCategorieError,
    startLoading,
    receivedGetCategoriesSuccess,
} from './actions';
import {
    retrieveCategories,
    retrieveCategorie,
    addCategorie,
    deleteCategorie,
    editCategorie,
    updateCategorieVehicles,
    requestAddVehiclesToCategorie,
    requestDeleteVehiclesFromCategorie,

} from './resources';
import { parseJWT } from 'helpers';

/**
 * Fetch categories for current branch
 */
export const getCategoriesByBranch = () => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    dispatch(startLoading());

    return retrieveCategories(token, branchId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            dispatch(receivedGetCategoriesSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
};

export const getCategorieById = (categorieId) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);

    return retrieveCategorie(token, categorieId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            return response.data;
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
};

export const createCategorie = (categorieName) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];
    const data = {
        categorieName
    }

    return addCategorie(token, branchId, data)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            dispatch(getCategoriesByBranch());
            return response.data
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
}

export const removeCategorie = (categorieId) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);

    return deleteCategorie(token, categorieId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            dispatch(getCategoriesByBranch())
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
}

export const editCategorieName = (categorieId, categorieName) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = {
        categorieName
    }

    return editCategorie(token, categorieId, data)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            dispatch(getCategoriesByBranch());
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
}

export const editCategorieVehicles = (categorieId, vehicles) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = {
        vehicles
    }

    dispatch(startLoading());

    return updateCategorieVehicles(token, categorieId, data)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            // dispatch(receivedGetCategoriesSuccess(response.data));
            dispatch(getCategoriesByBranch());
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
}

export const addVehiclesToCategorie = (categorieId, vehicles) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = {
        vehicles
    }

    dispatch(startLoading());

    return requestAddVehiclesToCategorie(token, categorieId, data)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            dispatch(getCategoriesByBranch());
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
}

export const removeVehiclesFromCategorie = (categorieId, vehicles) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = {
        vehicles
    }

    dispatch(startLoading());

    return requestDeleteVehiclesFromCategorie(token, categorieId, data)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedCategorieError());
            }
            dispatch(getCategoriesByBranch());
        })
        .catch((error) => {
            dispatch(receivedCategorieError());
        });
}
