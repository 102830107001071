import React, { useState, useEffect } from 'react';
import {
    ApReservationStepIcon,
    ApCheckMarkIcon,
    ApCloseIcon,
    ApSectorIcon,
    ApStepperSeperator,
    ApSearchIcon,
} from '../Icons';
import ApReservationTime from '../ApReservationTime';
import ApSectorComponent from '../ApSectorComponent';
import ApConfirmationInfo from '../ApConfirmationInfo';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './styles.scss';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Input from '@mui/material/Input';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import {
    closeReservationModal,
    saveReservationDate,
    saveReservationHours,
    createReservation,
    editReservationById,
    selectedRows
} from '../../services/AutoPartage/thunks';
import {
    selectReservationModal,
    selectReservation,
    selectRows,
} from '../../services/AutoPartage/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ApReservationHour from '../ApReservationHour';
import { StyledEngineProvider } from '@mui/material/styles';
import moment from 'moment';
import SectorListComponent from "../SectorListComponent";


function ApStepper(props) {
    const steps = [props.intl.formatMessage({
        id:
            'components.AP.Stepper.Steps.Sector',
    })
        ,
    props.intl.formatMessage({
        id:
            'components.AP.Stepper.Steps.Date',
    }),
    props.intl.formatMessage({
        id:
            'components.AP.Stepper.Steps.Hour',
    }),
    props.intl.formatMessage({
        id:
            'components.AP.Stepper.Steps.Confirmation',
    })];
    const [activeStep, setActiveStep] = useState(0);
    const [date, setDate] = useState('');
    const [hours, setHours] = useState('');

    const [editDate, setEditDate] = useState('');
    const [editStartHours, setEditStartHours] = useState('');
    const [editEndHours, setEditEndHours] = useState('');
    const [clickedSector, setClickedSector] = useState(null);
    useEffect(
        () => {
            if (props.reservation) {
                const [
                    dateReservation,
                    timeStartReservation,
                ] = props.reservation.dateReservationDebut.split(' ');
                setEditDate(dateReservation);

            }
        },
        [props.reservation]
    );

    useEffect(() => {
        if (props.reservation) {
            const dateTimeStart = new Date(props.reservation.dateReservationDebut);
            const dateTimeEnd = new Date(props.reservation.dateReservationFin);
            setDate(props.reservation.dateReservationDebut.split(' ', 1));
            setHours(`${dateTimeStart.getHours()}:${dateTimeEnd.getMinutes()}0 - ${dateTimeEnd.getHours()}:${dateTimeEnd.getMinutes()}0`);
        }
    }, []);
    const handleDateChange = (date) => {
        setDate(date);
    };
    const handleHoursChange = (hours) => {
        setHours(hours);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleConfirm = () => {
        //.add(1, 'd');
        //format data to be sent using date and hours as basis
        let [startTime, endTime] = hours.split(' - ');
        const startDate = moment(`${date} ${startTime}`).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        const [hour, minute] = startTime.split(':');
        const hourInt = parseInt(hour);
        const endDate =
            hourInt > 21 ? moment(`${date} ${endTime}`).add(1, 'd') : moment(`${date} ${endTime}`);
        const endDateFormatted = endDate.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        const fullDate = {
            dateDebut: startDate,
            dateFin: endDateFormatted,
            sectorId: clickedSector.idSector
        };
        if (props.reservation) {
            console.log('id', props.selectedRow);
            props.editReservationById(props.selectedRow, fullDate)
            setDate('');
            setHours('');
            setEditDate('');
            props.selectedRows([]);


        }
        else {
            props.createReservation(fullDate);
        }

        props.closeReservationModal(false);
    };
    const handleClose = () => {
        props.closeReservationModal(false);
    };
    const handleNextDate = () => {
        //save date to redux
        props.saveReservationDate(date);
        handleNext();
    };
    const handleNextHours = () => {
        // save selected hours to redux
        props.saveReservationHours(hours);
        handleNext();
    };
    return (
        <StyledEngineProvider injectFirst>
            <Container maxWidth="sm" className="ap_stepper_container">
                <Button onClick={handleClose} className={'ap_close_button'}>
                    <ApCloseIcon />
                </Button>
                <Stepper activeStep={activeStep} sx={{ mt: 5 }} connector={<ApStepperSeperator />}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel
                                    StepIconComponent={() =>
                                        ApReservationStepIcon(index, activeStep)
                                    }
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length - 1 ? (
                    <React.Fragment>
                        <Container className={'ap_confirmation_container'}>
                            <Grid container spacing={2} className={'ap_confirmation_info'}>
                                {/*<Grid item xs={6}>*/}
                                {/*    <ApSectorComponent />*/}
                                {/*</Grid>*/}
                                <Grid item xs={6} className={'ap_information_grid'}>
                                    <ApConfirmationInfo clickedSector={clickedSector} />
                                    <Box className={'ap_confirmation_buttons'}>
                                        <Button
                                            color="inherit"
                                            onClick={handleBack}
                                            className={'ap_stepper_button_back'}
                                        >
                                            <FormattedMessage id={'components.AP.stepper.buttons.back'} />

                                        </Button>
                                        <Button
                                            onClick={handleConfirm}
                                            className={'ap_stepper_button_next'}
                                        >
                                            <ApCheckMarkIcon />{' '}
                                            <div style={{ marginLeft: '1px' }}>
                                                <FormattedMessage id={'components.AP.stepper.buttons.confirm'} />
                                            </div>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </React.Fragment>
                ) : activeStep === steps.length - 4 ? (
                    <Container className="ap_sector_container">
                        {/*<Box className={'ap_search_bar'}>*/}
                        {/*    <ApSearchIcon />*/}
                        {/*    <Input*/}
                        {/*        disableUnderline*/}
                        {/*        placeholder={props.intl.formatMessage({*/}
                        {/*            id:'components.AP.stepper.searchBar'*/}
                        {/*        })}*/}
                        {/*        sx={{ width: '100%' }}*/}
                        {/*    />*/}
                        {/*</Box>*/}
                        {/*    <ButtonGroup*/}
                        {/*    sx={{*/}
                        {/*        display: 'flex',*/}
                        {/*        flexDirection: 'row',*/}
                        {/*        justifyContent: 'center',*/}
                        {/*        marginTop: '10px',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Button className={'ap_sector_button'}>*/}
                        {/*        <ApSectorIcon />*/}
                        {/*        <div style={{ marginLeft: '5px' }}>Mont-Wright</div>*/}
                        {/*    </Button>*/}
                        {/*</ButtonGroup>*/}

                        <SectorListComponent setClickedSector={setClickedSector}/>
                        {/*<ApSectorComponent />*/}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                padding: '10px',
                            }}
                        >
                            <Button
                                color="inherit"
                                onClick={handleClose}
                                className={'ap_stepper_button_back'}
                            >
                                <FormattedMessage id={'components.AP.stepper.buttons.cancel'} />
                            </Button>
                            <Button disabled={!clickedSector} onClick={handleNext} className={'ap_stepper_button_next'}>
                                <ApCheckMarkIcon />
                                <div style={{ marginLeft: '1px' }}>
                                    <FormattedMessage id={'components.AP.stepper.buttons.next'} />
                                </div>
                            </Button>
                        </Box>
                    </Container>
                ) : activeStep === steps.length - 3 ? (
                    <Container className={'ap_time_container'}>
                        <ApReservationTime
                            sendReservationDate={handleDateChange}
                            editReservationDate={props.reservation ? editDate : null}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                mt: '20px',
                            }}
                        >
                            <Button
                                color="inherit"
                                onClick={handleBack}
                                className={'ap_stepper_button_back'}
                            >
                                <FormattedMessage id={'components.AP.stepper.buttons.back'} />

                            </Button>
                            <Button onClick={handleNextDate} className={'ap_stepper_button_next'}>
                                <ApCheckMarkIcon />
                                <div style={{ marginLeft: '1px' }}>
                                    <FormattedMessage id={'components.AP.stepper.buttons.next'} />
                                </div>
                            </Button>
                        </Box>
                    </Container>
                ) : (
                    <Container className={'ap_hour_container'}>
                        <ApReservationHour sendReservationHours={handleHoursChange} clickedSector={clickedSector} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                mt: '5px',
                            }}
                        >
                            <Button
                                color="inherit"
                                onClick={handleBack}
                                className={'ap_stepper_button_back'}
                            >
                                <FormattedMessage id={'components.AP.stepper.buttons.back'} />

                            </Button>
                            <Button onClick={handleNextHours} className={'ap_stepper_button_next'} disabled={(hours == "")}>
                                <ApCheckMarkIcon />
                                <div style={{ marginLeft: '1px' }}>
                                    <FormattedMessage id={'components.AP.stepper.buttons.next'} />
                                </div>
                            </Button>
                        </Box>
                    </Container>
                )}
            </Container>
        </StyledEngineProvider>
    );
}
const mapStateToProps: Object = createStructuredSelector({
    reservationModal: selectReservationModal(),
    reservation: selectReservation(),
    selectedRow: selectRows()
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            closeReservationModal,
            saveReservationDate,
            saveReservationHours,
            createReservation,
            editReservationById,
            selectedRows
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ApStepper))
);
