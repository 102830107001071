// @flow

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';
import {getVehicles} from '../../services/Vehicle/thunks';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DrivingReportGenerator, InputLabel, ReportGeneratorContainer} from "../../styles/reports";
import FormInputDate from "../../components/FormInputDate";
import {Split} from "../../styles/common";
import colors from "../../styles/colors";
import {selectVehicles} from "../../services/Vehicle/selectors";
import {Cell, Legend, Pie, PieChart, Tooltip as RechartTooltip} from "recharts";
import {renderCustomizedLabelPie, renderLegend} from "../../helpers";
import {i18nDistanceSymbol} from "services/Units/thunks";
import * as Excel from "exceljs";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';
import moment from "moment";
import {getDriverScoreCard} from "../../services/Driver/thunks";
import DriverScoreCard from "./styles";
import {Buttons} from "../ReportGenerator/Header/styles";
import OutlineButton from "../../components/OutlineButton";
import {HeaderText} from "../ReportDataTable/styles";
import VirtualizedTable from '../../components/VirtualizedTable'
import {Radio} from 'antd';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../assets/orca-logo-black-min.png";
import domtoimage from 'dom-to-image';
import Tooltip from '@mui/material/Tooltip';
import tooltipClasses from '@mui/material/Tooltip';
import {styled as tooltipStyled} from "@material-ui/core/styles";
import ReportsInputDate from "../../components/ReportsInputDate";

export const ReportComportement = (props) => {
    const [datas, setDatas] = useState(null);
    const [pieChartDatas, setPieChartDatas] = useState(null);
    const [datasChart, setDatasChart] = useState(null);
    const [periodTo, setPeriodTo] = useState(moment(new Date()).format('YYYY-MM'));
    const [periodFrom, setPeriodFrom] = useState(moment().subtract(1, 'months').format('YYYY-MM'));
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD 00:00:00'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD 00:00:00'));
    const [value, setValue] = useState(1);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("scoreFinal");
    const [pieChartDatasLegend, setPieChartDatasLegend] = useState(null);
    const [emptyData, setEmptyData] = useState(null);
    const [dateLimit, setDateLimit ] = useState(false);
    const [dateLimitPositionLeft, setDateLimitPositionLeft ] = useState(true);
    const currentDate = moment().format("YYYY-MM");
    useEffect(() => {
        const data = {
            "reportType": value === 2 ? 0 : 1,
            "startDate": startDate,
            "endDate": endDate
        }
        props.getDriverScoreCard(data)
            .then((response) => {
                setDatas(response.data)
            });
    }, [startDate, endDate, value]);

    useEffect(() => {
        setStartDate(moment(periodFrom).startOf('month').format('YYYY-MM-DD 00:00:00'))
        setEndDate(moment(periodTo).endOf('month').format('YYYY-MM-DD 00:00:00'))

    }, [periodTo, periodFrom]);

    useEffect(() => {
        setDatasChart([
            {
                name: props.intl
                    .formatMessage({id: 'containers.ReportComportement.Acceleration'})
                    .toString()+': ',
                value: '15%',
            },
            {
                name: props.intl
                    .formatMessage({id: 'containers.ReportComportement.Braking'})
                    .toString()+': ',
                value: '15%',
            },
            {
                name: props.intl
                    .formatMessage({id: 'containers.ReportComportement.Turn'})
                    .toString()+': ',
                value: '15%',
            },
            {
                name: props.intl
                    .formatMessage({id: 'containers.ReportComportement.Impact'})
                    .toString()+': ',
                value: '30%',
            },
            {
                name: props.intl
                    .formatMessage({id: 'containers.ReportComportement.speeding'})
                    .toString()+': ',
                value: '25%',
            }])
    }, []);

    const dataPie = (isLegend) =>{
        return (
            [
                {
                    name: props.intl.formatMessage({id: 'containers.ReportComportement.LowRisk'}).toString(),
                    value: datas.average.lowRiskCount, fill: !isLegend ? datas.average.lowRiskCount !== 0 ? '#61aa35' : 'white' :  '#61aa35'
                },
                {
                    name: props.intl.formatMessage({id: 'containers.ReportComportement.MildRIsk'}).toString(),
                    value: datas.average.mildRiskCount, fill:!isLegend ? datas.average.mildRiskCount !== 0 ? '#eada09' : 'white' : '#eada09'
                },
                {
                    name: props.intl.formatMessage({id: 'containers.ReportComportement.MediumRisk'}).toString(),
                    value: datas.average.mediumRiskCount, fill: !isLegend ? datas.average.mediumRiskCount !== 0 ? '#eb8b15' : 'white': '#eb8b15'
                },
                {
                    name: props.intl.formatMessage({id: 'containers.ReportComportement.HighRisk'}).toString(),
                    value: datas.average.highRiskCount, fill: !isLegend ? datas.average.highRiskCount !== 0 ? '#eb4034' : 'white': '#eb4034'
                },
            ]
        )
    }
    useEffect(() => {
        setEmptyData([
            {
                name: 'No Data',
                value: 1, fill: '#eeeeee'
            },

        ])
        if (datas) {
            setPieChartDatas(dataPie(false))

            setPieChartDatasLegend(dataPie(true))

        }
    }, [datas]);

    const renderPieChart = () =>{
        const values = pieChartDatas.map(item=>item.value)
        const sum = values.reduce((acc, value) => {
            return acc + value;
        });
        return(
                <PieChart width={550} height={350}>
                <Legend
                    layout="vertical"
                    verticalAlign="middle"
                    align="right"
                    wrapperStyle={{borderColor: colors.green61}}
                    content={renderLegend(pieChartDatasLegend, i18nDistanceSymbol())}
                />

                <Pie
                    dataKey={'value'}
                    //isAnimationActive={false}
                    data={sum === 0 ? emptyData : pieChartDatas}
                    cx="54%"
                    cy="50%"
                    paddingAngle={2}
                    minAngle={8}
                    outerRadius={120}
                    innerRadius={60}
                    fill="#fff"
                    label={sum === 0 ? false : renderCustomizedLabelPie}
                >
                </Pie>
                    {sum === 0 ? '' : <RechartTooltip/>}

            </PieChart>

        )
    }

    let blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const handleExcel = () => {
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let sheet = workbook.addWorksheet('My Sheet', {
            headerFooter: {firstHeader: "Hello Exceljs", firstFooter: "Hello World"}
        });
        const generateAt = props.intl
            .formatMessage({id: 'containers.Maintenance.VehicleDetail.PdfGenerated'})
            .toString();

        let name = value === 1 ? props.intl
            .formatMessage({id: 'containers.ReportComportement.Driver'})
            .toString() : props.intl
            .formatMessage({id: 'containers.ReportComportement.Vehicle'})
            .toString();
        sheet.mergeCells('D1', 'F1');
        sheet.getCell('D1').value = `${props.intl
            .formatMessage({id: 'containers.ReportComportement.AverageScore'})
            .toString()} : ${datas.average.averageFleetScore}`
        sheet.mergeCells('A1', 'C1');
        sheet.getCell('A1').value = props.intl
            .formatMessage({id: 'containers.ReportComportement.ReportBy'})
            .toString() + name
        sheet.getCell('A3').value = generateAt + moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

        sheet.mergeCells('D3', 'E3');
        sheet.getCell('D3').value = props.intl
            .formatMessage({id: 'containers.ReportComportement.Classification'})
            .toString()
        sheet.getCell('D4').value = `${props.intl
            .formatMessage({id: 'containers.ReportComportement.LowRisk'})
            .toString()} : ${datas.average.lowRiskCount}`
        sheet.getCell('D5').value = `${props.intl
            .formatMessage({id: 'containers.ReportComportement.MildRIsk'})
            .toString()} : ${datas.average.mildRiskCount}`
        sheet.getCell('D6').value = `${props.intl
            .formatMessage({id: 'containers.ReportComportement.MediumRisk'})
            .toString()} : ${datas.average.mediumRiskCount}`
        sheet.getCell('D7').value = `${props.intl
            .formatMessage({id: 'containers.ReportComportement.HighRisk'})
            .toString()} : ${datas.average.highRiskCount}`

        const datasKeys = Object.keys(datas.details[0])
        sheet.getRow(13).values = [name,
            props.intl
                .formatMessage({id: 'containers.ReportComportement.Distance'})
                .toString()+' (km)',
            props.intl
                .formatMessage({id: 'containers.ReportComportement.SuddenAccelerations'})
                .toString()+' (%)',
            props.intl
                .formatMessage({id: 'containers.ReportComportement.SuddenBraking'})
                .toString()+' (%)',
            props.intl
                .formatMessage({id: 'containers.ReportComportement.HarshTurns'})
                .toString()+' (%)',
            props.intl
                .formatMessage({id: 'containers.ReportComportement.Impact'})
                .toString()+' (%)',
            props.intl
                .formatMessage({id: 'containers.ReportComportement.speeding'})
                .toString()+' (%)',
/*             props.intl
                .formatMessage({id: 'containers.ReportComportement.IdleTime'})
                .toString()+' (%)', */
            // props.intl
            //     .formatMessage({id: 'containers.ReportComportement.NmbEvents'})
            //     .toString(),
            props.intl
                .formatMessage({id: 'containers.ReportComportement.FinalScore'})
                .toString()+' (%)'];
        datasKeys.map((item) => {
            sheet.columns = [
                {key: 'name', width: 20},
                {key: "distance", width: 15},
                {key: "acceleration_brusque", width: 25},
                {key: "freinage_brusque", width: 20},
                {key: "virage_brusque", width: 20},
                {key: "impact", width: 10},
                {key: "depassement_vitesse_nb", width: 25},
                //{key: "idleTime", width: 25},
                //{key: "deplacement_na_nb", width: 25},
                {key: "scoreFinal", width: 20},

            ]
        })
        datas.details.forEach(function (item, index) {
            sheet.addRow({
                name: item.name,
                distance:  parseFloat(item.distance/1000).toFixed(2),
                acceleration_brusque: item.acceleration_brusque,
                freinage_brusque: item.freinage_brusque,
                virage_brusque: item.virage_brusque,
                impact: item.impact,
                depassement_vitesse_nb: item.depassement_vitesse_nb,
                //idleTime: item.idleTime,
               // deplacement_na_nb: item.deplacement_na_nb,
                scoreFinal: item.scoreFinal,
            })
        })
        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {type: blobType});
            FileSaver.saveAs(blob, props.intl
                .formatMessage({id: 'containers.ReportComportement.ExcelPdfName'})
                .toString());
        });

    }
    const handleConvertToPDF = () => {
        const table = document.getElementById('PieChartId');
        const scale = 2
        domtoimage.toPng(table, {
            height: table.offsetHeight * scale,
            style: {
                transform: `scale(${scale}) translate(${table.offsetWidth / 2 / scale}px, ${table.offsetHeight / 2 / scale}px)`
            },
            width: table.offsetWidth * scale
        }).then((canvas) => {
            let name = value === 1 ? props.intl
                .formatMessage({id: 'containers.ReportComportement.Driver'})
                .toString() : props.intl
                .formatMessage({id: 'containers.ReportComportement.Vehicle'})
                .toString()
            let imgTabla = new Image();
            imgTabla.src = canvas;
            const doc = new jsPDF('l');
            const generateAt = props.intl
                .formatMessage({id: 'containers.Maintenance.VehicleDetail.PdfGenerated'})
                .toString();
            doc.addImage(Logo, 'png', 12, 8);
            doc.setFont(undefined, 'bold');
            doc.text(props.intl
                .formatMessage({id: 'containers.ReportComportement.ReportBy'})
                .toString() + name, 80, 27);
            doc.setFontSize(10);
            doc.text(props.intl
                .formatMessage({id: 'containers.ReportComportement.AverageScore'})
                .toString() +datas.average.averageFleetScore + ' %', 110, 40);
            doc.addImage(imgTabla, 'png', 90, 35, 130, 70);
            doc.setFontSize(8);
            doc.text(generateAt + moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 250, 10);

            let pdfDatas = []
            datas.details.forEach((item, index, array) => {
                pdfDatas.push([item.name,
                    parseFloat(item.distance/1000).toFixed(2),
                    item.acceleration_brusque,
                   item.freinage_brusque,
                   item.virage_brusque,
                    item.impact,
                    item.depassement_vitesse_nb,
                   //item.idleTime,
                   // item.deplacement_na_nb,
                   item.scoreFinal])
            })

            doc.autoTable({
                head: [[name, props.intl
                    .formatMessage({id: 'containers.ReportComportement.Distance'})
                    .toString()+' (km)',
                    props.intl
                        .formatMessage({id: 'containers.ReportComportement.SuddenAccelerations'})
                        .toString()+' (%)',
                    props.intl
                        .formatMessage({id: 'containers.ReportComportement.SuddenBraking'})
                        .toString()+' (%)',
                    props.intl
                        .formatMessage({id: 'containers.ReportComportement.HarshTurns'})
                        .toString()+' (%)',
                    props.intl
                        .formatMessage({id: 'containers.ReportComportement.Impact'})
                        .toString()+' (%)',
                    props.intl
                        .formatMessage({id: 'containers.ReportComportement.speeding'})
                        .toString()+' (%)',
/*                     props.intl
                        .formatMessage({id: 'containers.ReportComportement.IdleTime'})
                        .toString()+' (%+)', */
                    // props.intl
                    //     .formatMessage({id: 'containers.ReportComportement.NmbEvents'})
                    //     .toString(),
                    props.intl
                        .formatMessage({id: 'containers.ReportComportement.FinalScore'})
                        .toString()+' (%)',]],
                theme: 'grid',
                startY: doc.autoTable.previous.finalY !== undefined ? doc.autoTable.previous.finalY + 28 : 100,
                margin: {top: 20},
                headStyles: {textColor: 'white', fillColor: '#63AC37', fontSize: '8',},
                bodyStyles: {textColor: 'black', fillColor: 'white', fontSize: '8',},
                body: pdfDatas,
            })
            doc.save(props.intl
                .formatMessage({id: 'containers.ReportComportement.ExcelPdfName'})
                .toString() + '.pdf');
        })
    };
    const handleDatePeriodFrom = (event) => {
        if(dateLimit){
            setDateLimit(false);
        }
        let dateString = event.target.value;
        const dateFrom = moment(dateString);
        if(dateFrom.isAfter(currentDate)){
            dateString = currentDate;
        }
        if(dateFrom.isAfter(periodTo)){
            setPeriodTo(dateString);
        }
        let dateTo= moment(periodTo);
        let differenceInDays= dateTo.diff(dateFrom, 'days');
        if(differenceInDays > 366){
            setDateLimit(true);
            setDateLimitPositionLeft(true);
            const newDateTo = dateFrom.add(366,'days');
            const formattedDate = newDateTo.format('yyyy-MM')
            setPeriodTo(formattedDate);
        }
        setPeriodFrom(dateString);
    }
    const handleDatePeriodTo = (event) => {
        if(dateLimit){
            setDateLimit(false);
        }
        let dateString = event.target.value;
        const dateTo = moment(dateString);
        if(dateTo.isAfter(currentDate)){
            dateString = currentDate;
        }
        if(dateTo.isBefore(periodFrom)){
            setPeriodFrom(dateString);
        }
        let dateFrom= moment(periodFrom);
        let differenceInDays= dateTo.diff(dateFrom, 'days');
        console.log(differenceInDays);
        if(differenceInDays > 366){
            setDateLimit(true);
            setDateLimitPositionLeft(false);
            const newDatefrom = dateTo.subtract(366,'days');
            const formattedDateFrom = newDatefrom.format('yyyy-MM');
            setPeriodFrom(formattedDateFrom);
        }
        setPeriodTo(dateString);

    }
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const columns = [

        {
            name: "name",
            header: value === 1 ? props.intl
                .formatMessage({id: 'containers.ReportComportement.Driver'})
                .toString() : props.intl
                .formatMessage({id: 'containers.ReportComportement.Vehicle'})
                .toString(),
            width: '16%',

        },
        {
            name: "distance",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.Distance'})
                .toString()+' (km)',
            width: '11%',
            cell: (value) => {
                return (
                    <div>
                        {parseFloat(value.distance/1000).toFixed(2)}
                    </div>
                );
            },

        },
        {
            name: "acceleration_brusque",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.SuddenAccelerations'})
                .toString()+' (%)',
            width: '12%',
            cell: (value) => {
                return (
                    <div>
                        {value.acceleration_brusque}
                    </div>
                );
            },

        },
        {
            name: "freinage_brusque",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.SuddenBraking'})
                .toString()+' (%)',
            width: '12%',
            cell: (value) => {
                return (
                    <div>
                        {value.freinage_brusque}
                    </div>
                );
            },
        },
        {
            name: "virage_brusque",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.HarshTurns'})
                .toString()+' (%)',
            width: '12%',
            cell: (value) => {
                return (
                    <div>
                        {value.virage_brusque}
                    </div>
                );
            },
        },
        {
            name: "impact",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.Impact'})
                .toString()+' (%)',
            width: '11%',
            cell: (value) => {
                return (
                    <div>
                        {value.impact}
                    </div>
                );
            },
        },
        {
            name: "depassement_vitesse_nb",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.speeding'})
                .toString()+' (%)',
            width: '13%',
            cell: (value) => {
                return (
                    <div>
                        {value.depassement_vitesse_nb}
                    </div>
                );
            },
        },
/*         {
            name: "idleTime",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.IdleTime'})
                .toString()+' (%)',
            width: '11%',
            cell: (value) => {
                return (
                    <div>
                        {value.idleTime}
                    </div>
                );
            },
        }, */

        {
            name: "scoreFinal",
            header: props.intl
                .formatMessage({id: 'containers.ReportComportement.FinalScore'})
                .toString()+' (%)',
            width: '12%',
            onHeaderClick() {
                handleRequestSort("scoreFinal");
            },
            cell: (value) => {

                  return  (
                        <div>
                            {value.scoreFinal}
                        </div>
                    )

            },
        }
    ]
    const handleRequestSort = property => {
        const isDesc = orderBy === property && order === "desc";
        const newOrder = isDesc ? "asc" : "desc";
        setOrder(newOrder);
        setOrderBy(property);

        onChangeTableSort(newOrder, property);
    };

    const onChangeTableSort = (order, property) => {
        const newData = stableSort(datas.details, getComparator(order, property));
        setDatas({"average": datas.average,"details":newData});
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    };
    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };
    const LightTooltip = tooltipStyled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 12,
        },
    }));
    return (
        <DriverScoreCard>
            <ReportGeneratorContainer>
                <DriverScoreCard.Header>
                    <HeaderText className={'header'}>{props.intl
                        .formatMessage({id: 'containers.ReportComportement.DrivingBehavior'})
                        .toString()}</HeaderText>
                    <Buttons>
                        <OutlineButton
                            width={'150px'}
                            onClick={handleConvertToPDF}
                            outlineColor={colors.green63}
                            textColor={colors.green63}
                            hoverTextColor={colors.white}
                        >
                            <FormattedMessage id={'containers.Reports.Generators.Export.Pdf'}/>
                        </OutlineButton>

                        <OutlineButton
                            width={'150px'}
                            onClick={handleExcel}
                            outlineColor={colors.green63}
                            textColor={colors.green63}
                            hoverTextColor={colors.white}
                        >
                            <FormattedMessage id={'containers.Reports.Generators.Export.Xls'}/>
                        </OutlineButton>

                    </Buttons>
                </DriverScoreCard.Header>

                <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'}/>
                <div>
                    <DrivingReportGenerator>
                        <ReportsInputDate
                            monthDateType={true}
                            startDate={periodFrom}
                            onChangeStartDate={(event) => handleDatePeriodFrom(event)}
                            endDate={periodTo}
                            onChangeEndDate={(event) => handleDatePeriodTo(event)}
                            errorEndDate={periodTo}
                            dateLimit={dateLimit}
                            dateLimitPositionLeft={dateLimitPositionLeft}
                        >
                        </ReportsInputDate>

                    </DrivingReportGenerator>
                    <Radio.Group style={{display: 'flex', flexDirection: 'column', width: '15%'}} onChange={onChange}
                                 value={value}>
                        <Radio value={1}>{props.intl
                            .formatMessage({id: 'containers.ReportComportement.PerDriver'})
                            .toString()}</Radio>
                        <Radio value={2}>{props.intl
                            .formatMessage({id: 'containers.ReportComportement.PerVehicle'})
                            .toString()}</Radio>
                    </Radio.Group>
                    {datas ?
                        <h5 style={{textAlign: 'center',}}>{props.intl
                            .formatMessage({id: 'containers.ReportComportement.AverageFleetScore'})
                            .toString()}
                            : {datas.average.averageFleetScore} %</h5>
                        :
                        null}
                    <div id={'VirtualTableId'} style={{display: 'flex', margin: 'auto', padding: '30px', width:'30%'}}>
                        <div id={'PieChartId'}>
                            {pieChartDatas ?
                                renderPieChart() : null}
                        </div>
                    </div>
                </div>

                <DriverScoreCard.TableVirtual style={{marginBottom: '60px', display:'flex'}}>
                    <VirtualizedTable
                        data={datas ? stableSort(datas.details, getComparator(order, orderBy))  : []}
                        //order={order}
                        //orderBy={orderBy}
                        columns={columns}
                    />
                    <div style={{position:'relative', right:'2%', zIndex:'1', top:'15px',height:'0px'}}>
                        <LightTooltip title={
                            <div>
                                {props.intl
                                    .formatMessage({id: 'containers.ReportComportement.AverageFleetScoreProportion'})
                                    .toString()}
                                <div>
                                    {
                                        datasChart ? datasChart.map((item)=>{
                                            return(
                                                <div>{item.name}  {item.value}</div>

                                            )
                                        }): null
                                    }
                                </div>
                            </div>

                        } arrow>
                            <i className=' fa fa-question-circle' />
                        </LightTooltip>
                    </div>
                </DriverScoreCard.TableVirtual>

            </ReportGeneratorContainer>
        </DriverScoreCard>
    );
};

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector({
    equipment: selectVehicles(),
});

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getVehicles,
            getDriverScoreCard,
        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(ReportComportement));
