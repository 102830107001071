// @flow

import request from 'services/request';
import adminRequest from 'services/adminRequest';
import requestFAPI from 'services/requestFAPI';

import type { DrivingReportGeneratorType, MoveReportGeneratorType } from 'types';

/**
 *
 * @param {string} token
 * @param {*} data
 */
export function requestGenerateDrivingReport(
    token: string,
    data: object
): Promise<> {
    const url = `reports/overspeed`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token
    );
}

// /**
//  *
//  * @param {string} token
//  * @param {*} data
//  */
// export function requestGenerateDrivingReport(
//     token: string,
//     data: object
// ): Promise<> {
//     const url = `reports/overspeed`;
//     return adminRequest(
//         url,
//         {
//             method: 'POST',
//             data,
//         },
//         true,
//         token
//     );
// }

export function requestGenerateMoveReport(
    locale: string,
    token: string,
    data: MoveReportGeneratorType
) {
    return request(
        `${locale}/reports/move`,
        {
            method: 'POST',
            data,
        },
        true,
        token,
        true,
        data.format !== 'display'
            ? {
                  'Content-Type': 'application/json',
              }
            : {},
        data.format !== 'display' ? 'blob' : 'json'
    );
}

export function requestGenerateMoveV1Report(
    locale: string,
    token: string,
    data: MoveReportGeneratorType
) {
    return adminRequest(
        `reports/moveV1`,
        {
            method: 'POST',
            data,
        },
        true,
        token,
        true,
        data.format !== 'display'
            ? {
                  'Content-Type': 'application/json',
              }
            : {},
        data.format !== 'display' ? 'blob' : 'json'
    );
}

export function requestGenerateMoveV1ProPerso(
    token: string,
    reportId: number,

) {
    return adminRequest(
        `reports/moveV1/${reportId}`,
        {
            method: 'PUT',


        },
        true,
        token,
        true,

    );
}

export function requestGenerateEconomieReport(
    locale: string,
    token: string,
    data: DrivingReportGeneratorType
) {
    return request(
        `${locale}/reports/economie`,
        {
            method: 'POST',
            data,
        },
        true,
        token,
        true,
        data.format !== 'display'
            ? {
                  'Content-Type': 'application/json',
              }
            : {},
        data.format !== 'display' ? 'blob' : 'json'
    );
}

/**
 *
 * @param {string} token
 */
export function requestGenerateDrivingBehaviourReport(userId, token: string): Promise<> {
    const url = `sisense/${userId}/login`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 *
 * @param {string} token
 */
export function requestGenerateDashboardReportSummary(
    startDate: string,
    endDate: string,
    token: string,
    data: *
): Promise<> {
    const url = `dashboard/reportsummary/${startDate}/${endDate}`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 *
 * @param {string} token
 */
export function requestGenerateDashboardRealtimesSummary(
    startDate: string,
    endDate: string,
    token: string
): Promise<> {
    const url = `dashboard/realtimesummary/${startDate}/${endDate}`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 *
 * @param {string} token
 */
export function requestGenerateDashboardGeofenceSummary(
    startDate: string,
    endDate: string,
    token: string
): Promise<> {
    const url = `dashboard/geofencesummary/${startDate}/${endDate}`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 *
 * @param {string} token
 */
export function requestGenerateDashboardInstantSummary(token: string): Promise<> {
    const url = `dashboard/instantsummary`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 *
 * @param {string} token
 */
export function requestGenerateGeofencingHistoryReport(token: string, data): Promise<> {
    const url = `getGeofenceHistory`;

    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        false,
        token,
        true,
        data.format !== 'display'
            ? {
                  'Content-Type': 'application/json',
              }
            : {},
        data.format !== 'display' ? 'blob' : 'json'
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} serialID
 */
export function getEquipmentLocations(
    token: string,
    link: string,
    serialID: number,
    startDate: string,
    endDate: string,
    pageNumber: number,
    pageSize: number
): Promise<> {
    const url = link
        ? link.substring(9)
        : `Fleet/Equipment/VEHICLE_ID/${serialID}/Locations/${startDate}/${endDate}/${pageNumber}?pageSize=${pageSize}`;
    return requestFAPI(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {*} data
 * @param {number} vehicleId
 */
export function retrieveHistoryModification(token: string, vehicleId: number, data: *): Promise<> {
    const url = `vehicles/${vehicleId}/modifications-history/`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token,
    );
}

export function retrieveHistoryModificationByBranchId(token: string, branchId: number, data: *): Promise<> {
    const url = `branches/${branchId}/modifications-history/`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token,
    );
}

export async function   fixCoordinates (entryCoordinates,exitCoordinates){
    const url = `https://api.mapbox.com/matching/v5/mapbox/driving/${entryCoordinates[0]},${entryCoordinates[1]};${exitCoordinates[0]},${exitCoordinates[1]}?geometries=geojson&access_token=pk.eyJ1IjoiZ2VvdGhlbnRpYyIsImEiOiJjamw2bDg4bmIyeWppM2txamJvY3JudHI1In0.U4zE1TCsDDzb44WqzAFpTg`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.tracepoints && data.tracepoints.length > 0) {
       return {entryCoordinates:data.tracepoints[0].location,exitCoordinates:data.tracepoints[1].location}
      }
    } catch (error) {
      console.error('Error fixing coordinates:', error);
    }
  }