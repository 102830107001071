import React, { useState, useContext, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Table from 'antd/es/table';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import { bindActionCreators } from 'redux';
import { getVehicleById, patchHorometerOdometer } from '../../../services/Vehicle/thunks';
import { handleHotometerFormat, kmToMeters, metersToKilometers } from '../helpers';
import { changeUnit, i18nDistanceSymbol, setTextunit } from '../../../services/Units/thunks';

const EdiTable = (props) => {
    const EditableContext = React.createContext(null);
    const [tableScrollHeight, setTableScrollHeight] = useState(500);

    const updateTableScrollHeight = () => {
        const calculatedHeight = window.innerHeight * 0.6;
        setTableScrollHeight(calculatedHeight);
    };

    useEffect(() => {
        updateTableScrollHeight();
        window.addEventListener('resize', updateTableScrollHeight);
        return () => {
            window.removeEventListener('resize', updateTableScrollHeight);
        };
    }, []);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {

        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);

            if (dataIndex === 'horometer') {
                form.setFieldsValue({
                    [dataIndex]: handleHotometerFormat(record[dataIndex]),
                });
            }
            if (dataIndex === 'odometer') {
                form.setFieldsValue({
                    [dataIndex]: `${props.setTextunit(
                        metersToKilometers(record[dataIndex]),
                        'odometer',
                    )}`,
                });
            }
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        if (editable) {
            children = editing ? (
                <Form.Item
                    name={dataIndex}
                >
                    <Input type={'number'} step={'0.01'} ref={inputRef} onPressEnter={save} onBlur={save}
                           style={{ width: '60%' }} />
                </Form.Item>
            ) : (
                <div
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{children}</td>;
    };

    const handleSave = (row) => {
        const newData = [...props.dataSource];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });

        let horometer = newData[index].horometer;
        if (props.dataSource[index].horometer !== newData[index].horometer) {
            horometer *= 3600;
            newData[index].horometer = horometer;
        }

        let odometre = newData[index].odometer;
        if (props.dataSource[index].odometer !== newData[index].odometer) {
            odometre = kmToMeters(newData[index].odometer);
            odometre = props.i18nDistanceSymbol() === 'km' ? odometre : odometre / 0.621;
            newData[index].odometer = odometre;
        }
        props.updateVehicles(newData);
        props.getVehicleById(newData[index].id)
            .then((response) => {
                const vehicleDatas = {
                    odometer: (parseInt(odometre)),
                    horometer: parseInt(horometer),
                };
                props.patchHorometerOdometer(newData[index].id, vehicleDatas);
            });

    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns2 = props.columns.map((col) => {
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });
    return (
        <div>
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                dataSource={props.dataSource}
                columns={columns2}
                scroll={{ y: tableScrollHeight }}
                rowKey='id'
                pagination={false}
                size={"middle"}

            />
            {/*<Loader loading={!props.isDataLoaded && props.dataSource.length === 0} />*/}
        </div>
    );
};
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {

            patchHorometerOdometer,
            getVehicleById,
            setTextunit,
            changeUnit,
            i18nDistanceSymbol,
        },
        dispatch,
    );
// EXPORTS
export default connect(
    null,
    mapDispatchToProps,
)(injectIntl(EdiTable));
