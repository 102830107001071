// @flow

import { push } from 'react-router-redux';

import {
    receivedCreateUserSuccess,
    receivedCreateUserFailed,
    receivedDeleteUserError,
    receivedEditUserSuccess,
    receivedCreateUserValidationError,
} from './actions';

import { fetchGroups } from './resources';
import {
    GET_ORGANIZATION_USER_SUCCESS,
    receivedDeleteOrganizationUser,
} from 'services/Organization/actions';

import {
    DELETE_DIVISION_USER,
    GET_DIVISION_USER_SUCCESS,
    receivedDeleteDivisionUser,
} from 'services/Division/actions';

import {
    DELETE_BRANCH_USER,
    GET_BRANCH_USER_SUCCESS,
    receivedDeleteBranchUser,
} from 'services/Branch/actions';

import {
    CREATE_BRANCH_USER,
    CREATE_DIVISION_USER,
    EDIT_DIVISION_USER,
    EDIT_BRANCH_USER,
} from 'services/Modal/actions';

import { getBranchData } from 'services/Branch/thunks';

import { getOrganizationData } from 'services/Organization/thunks';

import { getDivisionData } from 'services/Division/thunks';

import {
    createUserRequest,
    createUserAssociationRequest,
    editUserRequest,
    deleteUserRequest,
} from 'services/Organization/resources';
import { parseJWT } from 'helpers';
import type {
    ReduxDispatch,
    ResponseErrorType,
    ImmutableMap,
    UserDataType,
    Organization,
} from 'types';

/**
 *
 * @param {*} userData
 * @param {*} createType user assosciation, branch user | division user | organization user
 */
export const createUser = (
    userData: UserDataType,
    createType: string,
    organizationId: ?number,
    branchId: ?number,
    divisionId: ?number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    const id = organizationId;
    // let associationUrl = `${locale}/organizations/${organizationId}/users`;
    const type = GET_ORGANIZATION_USER_SUCCESS;
    // if (createType === CREATE_BRANCH_USER && organizationId && branchId) {
    //     id = branchId;
    userData.branchId = Number(organizationId);
    //     associationUrl = `${locale}/organizations/${organizationId}/branches/${branchId}/users`;
    //     type = GET_BRANCH_USER_SUCCESS;
    // }

    // if (createType === CREATE_DIVISION_USER && organizationId && branchId && divisionId) {
    //     id = divisionId;
    //     associationUrl = `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/users`;
    //     type = GET_DIVISION_USER_SUCCESS;
    // }

    return createUserRequest(userData, token, locale)
        .then((userResponse) => {
            dispatch(getOrganizationData(organizationId));
            return userResponse.data.userId
        })
        .catch((error) => {
            dispatch(receivedCreateUserFailed(error));
        });
};

export const editUser = (
    userId: number,
    data: UserDataType,
    action: string,
    organizationId: ?number,
    branchId: ?number,
    divisionId: ?number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return editUserRequest(userId, data, token, locale).then((response) => {
        return dispatch(receivedEditUserSuccess({ id: userId, ...data }));
        // if (action === EDIT_BRANCH_USER) {
        // TODO Change this to update reducer
        // } else if (action === EDIT_DIVISION_USER) {
        //     return dispatch(getDivisionData(organizationId, branchId, divisionId));
        // } else {
        //     return dispatch(getOrganizationData(organizationId));
        // }
    });
};

export const deleteUser = (userId: number, action: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return deleteUserRequest(userId, token, locale).then(() => {
        let dispatchAction = receivedDeleteOrganizationUser;
        if (action === DELETE_BRANCH_USER) {
            dispatchAction = receivedDeleteBranchUser;
        }
        if (action === DELETE_DIVISION_USER) {
            dispatchAction = receivedDeleteDivisionUser;
        }
        return dispatch(dispatchAction(userId));
    });
};

export const deleteUserError = (property: ?string) => (dispatch: ReduxDispatch) =>
    dispatch(receivedDeleteUserError(property));

export const validateCreateUser = (user: UserDataType) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const locale = getState().getIn(['language', 'locale']);
    const requiredText = locale === 'fr' ? 'Champ requis' : 'Field is required';
    const errors = {};

    const optionalFields = [
        'branchId',
        'password',
        'comments',
        'sendWelcomeEmail',
        'newUserRoles',
        'currentUserId',
        'currentUser',
        'roles',
        'lang',
        'units',
        'groups',
    ];

    if (user.selectedRoles.length === 0) {
        errors['selectedRoles'] = requiredText;
    }
    if (user.groupsList.length > 0 && user.selectedGroups.length === 0) {
        errors['selectedGroups'] = requiredText;
    }

    Object.keys(user).forEach((prop) => {
        if (!user[prop] && optionalFields.indexOf(prop) === -1) {
            errors[prop] = requiredText;
        }
    });
    return new Promise((resolve, reject) => {
        if (Object.keys(errors).length > 0) {
            return dispatch(receivedCreateUserValidationError(errors));
        } else {
            return resolve();
        }
    });
};

export const fetchUserGroups = (user) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return fetchGroups(token, branchId)
        .then((response) => {
            return response.data
        }
    );
};