// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const CheckInput = styled.div`
    display: flex;
    height: 45px;
    background-color: ${colors.white};
    flex-direction: row;
    align-items: center;
`;

CheckInput.Input = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid ${(props) => props.borderColor};
`;

CheckInput.Image = styled.img``;

CheckInput.Label = styled.div`
    color: ${colors.gray75};
    font-family: OpenSans;
    font-size: 16px;
    margin-right: 5px;
`;

export default CheckInput;
