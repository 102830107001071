// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import {
    BarChart,
    CartesianGrid,
    Legend,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Bar,
    Brush,
} from 'recharts';
import Loader from 'components/Loader';
import colors from 'styles/colors';
import GeoExportExcel from 'components/GeoExportExcel';
import { Resizable, ResizableBox } from 'react-resizable';


class GeoVBarChart extends React.Component<Props, State> {
    render() {
        const { data, bar, title, loading, dateRange } = this.props;
        return (
            <ResizableBox
                className={'element'}
                minConstraints={[400, 500]}
                transformScale={0.75}
                width={400}
                height={500}
                axis={"x"}
                style={{ borderColor: colors.green61 }}
                handle={(h) => <span className={`custom-handle custom-handle-${h}`} />}>
                <span className="d-inline-block" style={{
                    textAlign: 'center',
                    color: colors.green61,
                    textTransform: 'uppercase',
                    textDecorationLine: 'underline'
                }} >{title} </span>
                <hr />
                {loading ? (
                    <Loader loading />
                ) : (
                    [data?<div className={"export"}><GeoExportExcel title={title} dateRange={dateRange} bar={bar} data={data} dataKey={"vehicle_numb"} /></div>:null, <ResponsiveContainer>
                        <BarChart
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                type="category"
                                dataKey="vehicle_numb"
                                style={{ fontSize: '10px' }}
                            />
                            <YAxis type="number" style={{ fontSize: '10px' }} />
                            <Tooltip />
                            <Legend
                                verticalAlign="bottom"
                                wrapperStyle={{ lineHeight: '20px', fontSize: '10px' }}
                            />
                            <Brush
                                dataKey="vehicle_numb"
                                height={10}
                                stroke={colors.gray88}
                                style={{ fontSize: '10px' }}
                                startIndex={0}
                                endIndex={data.length >= 5 ? 5 : data.length - 1}
                            />
                            {bar.length > 0 &&
                                bar.map((element) => (
                                    <Bar
                                        name={element.name}
                                        dataKey={element.barName}
                                        stackId={element.barStackId}
                                        stroke={element.stroke}
                                        fill={element.fill}
                                    />
                                ))}
                        </BarChart>
                    </ResponsiveContainer>]
                )}
            </ResizableBox>
        );
    }
}

export default injectIntl(GeoVBarChart);
