// @flow

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import Header from 'components/Modals/Header';
import FormGroup from 'components/Modals/FormGroup';
import FormInputText from 'components/FormInputText';
import CheckInput from 'components/CheckInput';
import OutlineButton from 'components/OutlineButton';
import Error from 'components/Error';

import colors from 'styles/colors';
import { ErrorContainer, ErrorText } from './style';
import { parseJWT } from 'helpers';
import { fromJS } from 'immutable';

import { deleteUserError, validateCreateUser, fetchUserGroups } from 'services/User/thunks';
import { fetchBranchGroup } from 'services/Branch/thunks';
import { selectUser } from 'services/Authentication/selectors';
import { selectUserErrors } from 'services/User/selectors';

import { selectModalAction, selectUserId } from 'services/Modal/selectors';
import { selectLocale } from 'services/Language/selectors';
import { selectUnit } from 'services/Units/selectors';
import { closeModal } from 'services/Modal/thunks';

import { Modal, Organization, CheckField, Submit } from 'styles/modal';

import type { UserDataType } from 'types';
import Select from 'antd/es/select';
import message from 'antd/es/message';
import { selectRoles } from 'services/Admin/selectors';
import { getCurrentUserRoles, updateRoles } from 'services/Admin/thunks';

type State = {
    firstName: string,
    lastName: string,
    password: string,
    email: string,
    comments: string,
    sendWelcomeEmail: boolean,
    currentUserId: integer,
    currentUser: *,
    roles: *,
    selectedRoles: Array,
    newUserRoles: Array,
    branchId: number,
};

type Props = {
    header: string,
    onSubmit: (organization: State) => void,
    closeModal: () => void,
    userAction: string,
};

class CreateUserModalComponent extends React.PureComponent<Props, State> {
    state = {
        firstName: '',
        lastName: '',
        password: '',
        email: '',
        comments: '',
        sendWelcomeEmail: true,
        lang: 'fr',
        units: 'metric',
        currentUserId: null,
        currentUser: null,
        selectedRoles: [],
        newUserRoles: [],
        roles: null,
        groupsList: null,
        groups: [],
        selectedGroups: null,
        defaultGroup: null,
        branchId: null,
    };

    componentDidMount() {
        let user;
        let branchId;
        if (this.props.data && this.props.data.size > 0) {
            user = this.findUser(this.props.data, this.props.userId);
        }
        this.props.getCurrentUserRoles();
        if (user) {
            this.hydrateState(user);
            this.showRolesModal(user);
            this.showGroupsModal(user);
            branchId = user.get('branchId');
        } else {
            this.handleRoleSelect('user');
            branchId = this.props.branchId;
        }

        this.props.fetchBranchGroup(branchId).then((groups) => {
            const defaultGroup = groups.find((group) => {
                return group.isDefault;
            });
            this.setState({
                lang: this.props.locale,
                units: this.props.unit,
                groupsList: groups,
                defaultGroup,
                selectedGroups: this.state.selectedGroups || [defaultGroup.name],
            });
        });
        // this.setPassword();
    }

    componentWillUnmount() {
        this.props.deleteUserError();
    }

    generatePassword = () => {
        const length = 16;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    };

    setPassword = () => {
        if (this.props.userAction === 'CREATE') {
            this.setState({
                password: this.generatePassword(),
            });
        }
    };

    hydrateState = (data: UserDataType) => {
        const newState = {};
        Object.keys(this.state).forEach((key: string) => {
            newState[key] = data.get(key);
        });

        this.setState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    findUser = (userList: ImmutableList, userId: number) =>
        userList.find((data) => data.get('id') === userId);

    handleOnChangeText = (key: string) => (event: Event) => {
        const value = event.target.value;
        if (
            this.props.errors &&
            this.props.errors.get === 'function' &&
            this.props.errors.get(key) &&
            this.props.errors.get(key).size > 0
        ) {
            this.props.deleteUserError(key);
        }

        this.setState({
            [key]: value,
        });
    };

    handleToggleCheckField = (key: string) => () => {
        this.setState((prevState) => ({
            [key]: !prevState[key],
        }));
    };

    handleSubmit = () => {
        this.setState({ lang: this.props.locale, units: this.props.unit });
        this.handleGroupsSubmit();
        this.props.validateCreateUser(this.state).then(() => {
            if (this.state.currentUserId) {
                this.handleRolesSubmit();
                this.props.onSubmit(this.state, this.props.userId);
            } else {
                this.props.onSubmit(this.state, this.props.userId).then((id) => {
                    if (typeof id === 'number') {
                        this.setState({ currentUserId: id });
                        setTimeout(() => {
                            this.handleRolesSubmit();
                        }, 5000);
                    }
                });
            }
        });
    };

    handleCancel = () => this.props.closeModal();

    getErrors = (key: string) => {
        if (this.props.errors && typeof this.props.errors.get === 'function') {
            if (this.props.errors && this.props.errors.get(key)) {
                return this.props.errors.get(key);
            }
        }
        return false;
    };

    handleRolesChange = (value) => {
        this.setState({ selectedRoles: value });
    };

    handleRoleSelect = (value) => {
        this.setState((prevState) => ({
            selectedRoles: [...prevState.selectedRoles, value],
        }));
    };

    handleRoleDeselect = (value) => {
        const array = [...this.state.selectedRoles]; // make a separate copy of the array
        const index = array.indexOf(value);
        const jwt = parseJWT(this.props.user.get('token'));
        if (
            jwt.permissions &&
            Array.isArray(jwt.permissions) &&
            jwt.permissions.indexOf(`assign:${value}`) !== -1 &&
            index !== -1
        ) {
            array.splice(index, 1);
            this.setState({ selectedRoles: array });
            console.log(`deSelected: ${value}`);
        } else {
            message.error(
                this.props.intl.formatMessage({
                    id: 'components.Modals.Form.Admin.unAuthorized', // 'unauthorized! ';
                })
            );
        }
    };

    handleGroupsChange = (value) => {
        this.setState({ selectedGroups: value });
    };

    handleGroupSelect = (value) => {
        this.setState((prevState) => ({
            selectedGroups: [...prevState.selectedGroups, value],
        }));
    };

    handleGroupDeselect = (value) => {
        const array = [...this.state.selectedGroups]; // make a separate copy of the array
        const index = array.indexOf(value);
        array.splice(index, 1);
        this.setState({ selectedGroups: array });
    };

    handleGroupsSubmit = () => {
        const { groupsList, selectedGroups, defaultGroup } = this.state;
        let groups;
        if (defaultGroup) {
            groups = [defaultGroup.groupId];
        } else {
            groups = [groupsList[0].groupId];
        }

        if (selectedGroups.length > 0) {
            groups = selectedGroups.map((name) => {
                const group = groupsList.find((element) => element.name === name);
                return group.groupId;
            });
        }
        this.setState({ groups });
    };

    showGroupsModal = (record) => {
        const groups = record
            .get('groups')
            .toArray()
            .map((group) => {
                if (typeof group !== 'number') {
                    return group.get('name');
                }
            });
        this.setState({ selectedGroups: groups });
    };

    showRolesModal = (record) => {
        const roles = record
            .get('roles')
            .toArray()
            .map((r) => r.get('name'));
        this.setState({
            currentUserId: record.get('id'),
            currentUser: record,
            selectedRoles: roles,
        });
    };

    handleRolesCancel = () => {
        this.setState({
            currentUserId: null,
            currentUser: null,
            selectedRoles: [],
        });
    };

    handleRolesSubmit = () => {
        const tmp = this.state.selectedRoles;
        const newRoleState = this.state.selectedRoles.map((role) => {
            return this.getRolesFromName(role);
        });
        console.log(newRoleState);
        const t = fromJS(newRoleState);
        this.setState({
            roles: fromJS(newRoleState),
        });
        let userRoles = [];
        if (this.state.currentUser) {
            userRoles = this.state.currentUser
                .get('roles')
                .toArray()
                .map((r) => r.get('name'));
        }

        let toAdd = this.inFirstOnly(this.state.selectedRoles, userRoles);
        let toDelete = this.inSecondOnly(this.state.selectedRoles, userRoles);
        if (toAdd.length > 0) {
            toAdd = toAdd.map((role) => {
                return this.mapToRoleId(role);
            });
        console.log('user id', this.state.currentUserId)
            this.props
                .updateRoles(this.state.currentUserId, toAdd, 'POST')
                .then(() => {
                    if (this.props.showError) {
                        message.error('failed');
                    } else {
                        if (toDelete.length === 0) {
                            this.handleRolesCancel();
                            //this.fetchOrgData();
                        }
                    }
                })
                .catch((error: Object) => {
                    console.log(error);
                });
        }
        if (toDelete.length > 0) {
            toDelete = toDelete.map((role) => {
                return this.mapToRoleId(role);
            });
            this.props
                .updateRoles(this.state.currentUserId, toDelete, 'DELETE')
                .then(() => {
                    if (this.props.showError) {
                        message.error('failed');
                    } else {
                        this.handleRolesCancel();
                        //this.fetchOrgData();
                    }
                })
                .catch((error: Object) => {
                    console.log(error);
                });
        }
        //
    };

    mapToRoleId = (role) => {
        for (let i = 0; i < this.props.roles.length; i++) {
            if (role === this.props.roles[i].name) {
                return this.props.roles[i].id;
            }
        }
    };

    getRolesFromName = (role) => {
        for (let i = 0; i < this.props.roles.length; i++) {
            if (role === this.props.roles[i].name) {
                return this.props.roles[i];
            }
        }
    };

    compareArrays = (list1, list2, operationIsUnion) => {
        const result = [];

        for (let i = 0; i < list1.length; i++) {
            const item1 = list1[i];
            let found = false;
            for (let j = 0; j < list2.length; j++) {
                if (item1 === list2[j]) {
                    found = true;
                    break;
                }
            }
            if (found === operationIsUnion) {
                result.push(item1);
            }
        }
        return result;
    };

    inFirstOnly = (list1, list2) => {
        return this.compareArrays(list1, list2, false);
    };

    inSecondOnly = (list1, list2) => {
        return this.inFirstOnly(list2, list1);
    };

    inBoth = (list1, list2) => {
        return this.compareArrays(list1, list2, true);
    };

    render() {
        const rolesMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.roles', // 'Roles ';
        });

        const selectMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.select', // 'Please select'
        });

        const selectGroupMessage = 'group';
        // this.props.intl.formatMessage({
        //     id: 'select group'
        // });

        const submit = this.props.intl.formatMessage({
            id: 'components.Modals.Save',
        });

        const cancel = this.props.intl.formatMessage({
            id: 'components.Modals.Cancel',
        });
        return (
            <Organization>
                <Header headerTextId={this.props.header} />
                {this.props.errors &&
                    !this.props.errors.get && (
                        <Modal.Error>
                            <Error text={this.props.errors} />
                        </Modal.Error>
                    )}
                {/*
                 TODO: This is BAD
                 Validation is done in thunks.js / validateCreateUser()
                 Validation only look at submitted data, not form itself,
                 so `required` argument is totally ignored.
                 This needs to be redone.
                 */}
                <Organization.Content>
                    <FormGroup columns={2}>
                        <FormInputText
                            value={this.state.firstName}
                            placeholderId={'components.Modals.Form.User.FirstName'}
                            onChange={this.handleOnChangeText('firstName')}
                            error={this.getErrors('firstName')}
                            required
                        />
                        <FormInputText
                            value={this.state.lastName}
                            placeholderId={'components.Modals.Form.User.LastName'}
                            onChange={this.handleOnChangeText('lastName')}
                            error={this.getErrors('lastName')}
                            required
                        />
                    </FormGroup>
                    <FormGroup columns={1}>
                        <FormInputText
                            value={this.state.email}
                            placeholderId={'components.Modals.Form.User.Email'}
                            type={'email'}
                            onChange={this.handleOnChangeText('email')}
                            error={this.getErrors('email')}
                            required
                        />
                        {/* <FormInputText
                            value={this.state.password}
                            type={'password'}
                            placeholderId={'components.Modals.Form.User.Password'}
                            onChange={this.handleOnChangeText('password')}
                            error={this.getErrors('password')}
                            // required
                        /> */}
                    </FormGroup>
                    <FormGroup columns={1} size={100}>
                        <FormInputText
                            value={this.state.comments}
                            placeholderId={'components.Modals.Form.User.Comments'}
                            onChange={this.handleOnChangeText('comments')}
                            error={this.getErrors('comments')}
                            multiline
                        />
                    </FormGroup>
                    <FormGroup columns={1}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FormattedMessage id={'components.Modals.Form.Admin.roles'} />:
                            <ErrorContainer error={this.getErrors('selectedRoles')}>
                                <Select
                                    mode="multiple"
                                    size={10}
                                    placeholder={selectMessage}
                                    value={this.state.selectedRoles}
                                    onChange={this.handleRoleChange}
                                    onDeselect={this.handleRoleDeselect}
                                    onSelect={this.handleRoleSelect}
                                    style={{ width: '100%' }}
                                >
                                    {this.props.roles &&
                                        this.props.roles.map((role) => {
                                            const jwt = parseJWT(this.props.user.get('token'));
                                            if (
                                                jwt.permissions &&
                                                Array.isArray(jwt.permissions) &&
                                                jwt.permissions.indexOf(`assign:${role.name}`) !==
                                                    -1
                                            ) {
                                                return (
                                                    <Select.Option key={role.name}>
                                                        {role.name}
                                                    </Select.Option>
                                                );
                                            }
                                            return [];
                                        })}
                                </Select>
                            </ErrorContainer>
                        </div>
                        {this.getErrors('selectedRoles') && (
                            <ErrorText>{this.getErrors('selectedRoles')}</ErrorText>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FormattedMessage id={'components.Modals.Form.Admin.groups'} />:
                            <ErrorContainer error={this.getErrors('selectedGroups')}>
                                <Select
                                    mode="multiple"
                                    size={10}
                                    placeholder={selectGroupMessage}
                                    value={this.state.selectedGroups}
                                    onChange={this.handleGroupChange}
                                    onDeselect={this.handleGroupDeselect}
                                    onSelect={this.handleGroupSelect}
                                    style={{ width: '100%' }}
                                >
                                    {this.state.groupsList &&
                                        this.state.groupsList.map((group) => {
                                            return (
                                                <Select.Option key={group.name}>
                                                    {group.name}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </ErrorContainer>
                        </div>
                        {this.getErrors('selectedGroups') && (
                            <ErrorText>{this.getErrors('selectedGroups')}</ErrorText>
                        )}
                        <CheckField>
                            <CheckInput
                                value={this.state.sendWelcomeEmail}
                                placeholderId={'components.Modals.Form.User.SendWelcomeEmail'}
                                onChange={this.handleToggleCheckField('sendWelcomeEmail')}
                            />
                        </CheckField>
                    </FormGroup>
                </Organization.Content>
                <Submit>
                    <Submit.Button margin={'0 4.5px 0 0'}>
                        <OutlineButton
                            outlineColor={colors.green63}
                            backgroundColor={colors.green63}
                            hoverBackgroundColor={colors.white}
                            hoverTextColor={colors.green63}
                            textColor={colors.white}
                            onClick={this.handleSubmit}
                        >
                            <FormattedMessage id={'components.Modals.Send'} />
                        </OutlineButton>
                    </Submit.Button>
                    <Submit.Button margin={'0 0 0 4.5px'}>
                        <OutlineButton
                            outlineColor={colors.green63}
                            backgroundColor={colors.white}
                            hoverBackgroundColor={colors.green63}
                            hoverTextColor={colors.white}
                            textColor={colors.black}
                            onClick={this.handleCancel}
                        >
                            <FormattedMessage id={'components.Modals.Cancel'} />
                        </OutlineButton>
                    </Submit.Button>
                </Submit>
            </Organization>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    action: selectModalAction(),
    errors: selectUserErrors(),
    userId: selectUserId(),
    roles: selectRoles(),
    user: selectUser(),
    locale: selectLocale(),
    unit: selectUnit(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            deleteUserError,
            closeModal,
            validateCreateUser,
            getCurrentUserRoles,
            updateRoles,
            fetchBranchGroup,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CreateUserModalComponent));
