// @flow

import React from 'react';

import { injectIntl } from 'react-intl';

import Error from 'components/Error';

import { Container, InputDate, Icon } from './styles';
import colors from 'styles/colors';
import { format } from 'date-fns';

import CalendarIcon from 'assets/calendar-icon.svg';
import dateFns from 'date-fns';

type Props = {
    onChange?: () => void,
    value?: ?string,
    defaultValue?: ?string,
    error: ?boolean,
    readOnly?: boolean,
    minDate?: ?string,
    maxDate?: ?string,
    monthDateType?: boolean,
};

class FormInputDate extends React.PureComponent<Props> {
    state = {
        val: '',
    };

    convertValue = () => {
        const formatDate = dateFns.format(this.props.value, 'YYYY/MM/DD');
        this.setState({ val: formatDate });
    };

    render() {
        return (
            <Container
                borderColor={this.props.error && colors.status.red}
                width={this.props.width || '230px'}
            >
                <InputDate
                    //style={{ position: 'relative', width: '100%', height: '100%'}}
                    type={this.props.monthDateType && this.props.monthDateType === true ? 'month' : 'date'}
                    placeholder="YYYY/MM/DD"
                    value={ this.props.value }
                    onChange={this.props.onChange}
                />
                <Icon src={CalendarIcon} width={23} height={24} />
            </Container>
        );
    }
}

FormInputDate.defaultProps = {
    readOnly: false,
};

export default injectIntl(FormInputDate);
