// @flow

import request from 'services/adminRequest';
import isoRequest from 'services/isoRequest';

import type { EquipmentType } from 'types';

export function associateEquipmentRequest(
    url: string,
    data: { branchId: number, equipmentId: number },
    token: string
): Promise<> {
    return request(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token
    );
}

export function createEquipmentRequest(
    data: EquipmentType,
    token: string,
    locale: string
): Promise<> {
    return request(
        `vehicles`,
        {
            method: 'POST',
            data,
        },
        false,
        token
    );
}

export function editEquipmentRequest(
    data: EquipmentType,
    id: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `vehicles/${id}`,
        {
            method: 'PATCH',
            data,
        },
        false,
        token
    );
}

export function deleteEquipmentRequest(
    equipmentId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `vehicles/${equipmentId}`,
        {
            method: 'DELETE',
        },
        false,
        token
    );
}

export function requestAllEquipment(
    page: number,
    name: ?string,
    token: string,
    locale: string
): Promise<> {
    let url = `${locale}/admin/equipment?per_page=25&page=${page}`;

    if (name) {
        url = `${url}&name=${name}`;
    }

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}
export function requestAverageFuelLevel(
    token: string,
    cardNumber: string,
    startDate: string,
    endDate: string
): Promise<> {
    return isoRequest(
        `Fleet/Equipment/SERIAL/${cardNumber}/AverageFuelLevel/${startDate}/${endDate}/1?pageSize=10000`,
        {
            method: 'GET',
        },
        false,
        token
    );
}