// @flow

import { push } from 'react-router-redux';

import {
    requestAuthentication,
    receivedAuthenticationSuccess,
    receivedAuthenticationFail,
    receivedWhoamiSuccess,
    receivedWhoamiFail,
    receivedLogoutSuccess,
    receivedForgotPasswordSuccess,
    receivedForgotPasswordFail,
    receivedLoginAsUser,
    receivedResetPasswordError,
    receivedCreatePasswordError,
    receivedAdminLoginAsUserDelete,
    receivedLogoutAsUser,
    receivedRenewSessionUser,
} from './actions';

import {
    authenticate,
    authenticateAdministrator,
    retrieveAuthenticatedUser,
    authorizeForgotPassword,
    authorizeResetPassword,
    authorizeCreatePassword,
    loginAsUser,
    me,
    refreshUser,
    directAccess,
} from 'services/Authentication/resources';
import type { ReduxDispatch, ResponseErrorType } from 'types';
import auth0 from './auth0';
import { fromJS } from 'immutable';
import { socketConnect, socketListen } from 'services/socket/thunks';
import { receivedClearVehicles } from '../Vehicle/actions';

/**
 * Checks that the Github API Token is valid
 */

export const whoami = (token: string) => (dispatch: ReduxDispatch) =>
    retrieveAuthenticatedUser(token)
        .then((response: Object) => {
            if (response.data.error) {
                dispatch(receivedWhoamiFail(response.data));
            } else {
                dispatch(receivedWhoamiSuccess(response.data));
            }
        })
        .catch((response: ResponseErrorType) => {
            dispatch(receivedWhoamiFail(response));
        });

/**
 * Authenticates a user
 */

export const authenticateUser = (
    token: string,
    expiresIn: number,
    adminLogin: boolean,
    returnUrl: string
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(requestAuthentication());
    const locale = getState().getIn(['language', 'locale']);
    const payload = JSON.parse(window.atob(token.split('.')[1]));
    const url = returnUrl || (adminLogin ? `/${locale}/interne/orgs` : `/${locale}/map`);
    const expiresAt = expiresIn * 1000 + new Date().getTime();
    // todo
    dispatch(socketConnect(token));
    dispatch(socketListen());

    sessionStorage.setItem('api_token', token);
    sessionStorage.setItem('expires_at', expiresAt);
    dispatch(receivedAuthenticationSuccess(token, expiresAt));
    dispatch(push(url));
};

/**
 * Destroys the token found in local storage
 */

export const logoutUser = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const locale = getState().getIn(['language', 'locale']);
    // todo
    if (getState().getIn(['auth', 'loggedInAsUser'])) {
        const warning =
            locale === 'fr'
                ? "Cette action vous déconnectera en tant que cet utilisateur et l'administrateur"
                : 'This action will disconnect you as a user and as an admin';
        if (window.confirm(warning)) {
            auth0.logout();
            sessionStorage.removeItem('api_token');
            sessionStorage.removeItem('api_token_2');
            sessionStorage.removeItem('adminToken');
            sessionStorage.removeItem('localRoutificData');
            sessionStorage.removeItem('expires_at');
            sessionStorage.removeItem('additionalMapData');
        }
    } else {
        auth0.logout();
        sessionStorage.removeItem('api_token');
        sessionStorage.removeItem('localRoutificData');
        sessionStorage.removeItem('expires_at');
        sessionStorage.removeItem('additionalMapData');
    }
    localStorage.removeItem('geothentic:settings:isRealtime');

    // dispatch(receivedLogoutSuccess());
};

export const forgotPassword = (recoveryEmail: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const locale = getState().getIn(['language', 'locale']);
    return authorizeForgotPassword(recoveryEmail, locale)
        .then((response) => dispatch(receivedForgotPasswordSuccess()))
        .catch((error) => dispatch(receivedForgotPasswordFail()));
};

export const resetPassword = (email: string, password: string, token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const locale = getState().getIn(['language', 'locale']);
    return authorizeResetPassword(email, password, locale, token)
        .then(() => dispatch(push(`/${locale}/login`)))
        .catch((error) => dispatch(receivedResetPasswordError(error.data.message)));
};

export const createPassword = (email: string, password: string, token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const locale = getState().getIn(['language', 'locale']);
    return authorizeCreatePassword(email, password, locale, token)
        .then(() => dispatch(push(`/${locale}/login`)))
        .catch((error) => dispatch(receivedCreatePasswordError(error.data.message)));
};

export const adminLoginAsUser = (user: UserType) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const locale = getState().getIn(['language', 'locale']);
    const token = getState().getIn(['auth', 'user', 'token']);

    const userId = user.get('id');

    return loginAsUser(userId, token).then((response) => {
        const presistedUser = user.toJS();
        sessionStorage.setItem('adminToken', token);
        sessionStorage.setItem('api_token', response);
        sessionStorage.setItem(
            'api_token_2',
            JSON.stringify(presistedUser)
        ); /** used to presist data in redux */

        return dispatch(receivedLoginAsUser(response, user));
    });
};

export const loadedUserFromsessionStorage = () => (dispatch: ReduxDispatch) => {
    const token = sessionStorage.getItem('api_token');
    const user = JSON.parse(sessionStorage.getItem('api_token_2') || 'null');
    if (token && user) {
        dispatch(receivedLoginAsUser(token, user));
    }
};

export const adminLogoutAsUser = () => (dispatch: ReduxDispatch, getState: *) => {
    const locale = getState().getIn(['language', 'locale']);
    const message =
        locale === 'fr'
            ? "Cette action vous déconnectera en tant que cet utilisateur et vous retournera en tant que l'administrateur"
            : 'This action will disconnect you as the current user and return you as an admin user';
    if (window.confirm(message)) {
        const adminToken = sessionStorage.getItem('adminToken') || '';
        sessionStorage.removeItem('api_token_2');
        sessionStorage.removeItem('adminToken');
        sessionStorage.setItem('api_token', adminToken);
        sessionStorage.removeItem('localRoutificData');
        sessionStorage.removeItem('expires_at');
        sessionStorage.removeItem('additionalMapData');

        dispatch(receivedLogoutAsUser(adminToken));
        dispatch(push(`/${locale}/interne/orgs`));
    }
};

export const renewUserSession = () => (dispatch: ReduxDispatch) => {
    const token = auth0.getAccessToken();
    const expiresAt = auth0.getTokenExpiresAt();
    // todo
    if (token) {
        dispatch(receivedRenewSessionUser(token, expiresAt));
    }
};
export const adminLoginAsUserDelete = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedAdminLoginAsUserDelete());

export const adminDirectAccess = (user: UserType, branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const locale = getState().getIn(['language', 'locale']);
    const token = getState().getIn(['auth', 'user', 'token']);

    return directAccess(branchId, token).then((response) => {
        const presistedUser = fromJS({
            firstName: 'Admin',
            lastName: '',
        }).toJS();
        sessionStorage.setItem('adminToken', token);
        sessionStorage.setItem('api_token', response);
        sessionStorage.setItem('api_token_2', JSON.stringify(presistedUser));
        sessionStorage.removeItem('additionalMapData');

        dispatch(receivedClearVehicles());
        return dispatch(receivedLoginAsUser(response, presistedUser));
    });
};
