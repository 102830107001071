import React, {useState} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import {Button, message, Upload} from 'antd';
import InputFileComponentStyles from "./styles";
import {FormattedMessage} from "react-intl";


const { Dragger } = Upload;
const InputFileComponent = ({ onRemove, onSave }) => {
    const [fileList, setFileList] = useState([]);
    const props = {
        fileList: fileList,
        beforeUpload: () => false, // prevents automatic upload
        name: 'file',
        multiple: false,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
            const { status } = info.file;
            setFileList(info.fileList);
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove(file) {
            console.log('Removing file', file);
            onRemove(file); // call the onRemove prop function
        },
        onDrop(event) {
            event.preventDefault();
            const droppedFile = event.dataTransfer.files[0];
            const newFileList = [
                ...fileList,
                {
                    uid: Date.now(),
                    name: droppedFile.name,
                    status: 'done',
                    originFileObj: droppedFile,
                },
            ];
            setFileList(newFileList);
            console.log('Dropped files', event.dataTransfer.files);
        },
    };

    const handleSave = () => {
        if (onSave) {
            onSave();
        }
        setFileList([]); // Clear the fileList
    };

    return (
        <InputFileComponentStyles>
            <Dragger accept={'(.xlsx)'} {...props} >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <p className="ant-upload-text" style={{ fontSize: '14px' }}>
                        <FormattedMessage
                            id={'components.UploadFileComponent.uploadMessage'}
                        />
                    </p>
                    <p className="ant-upload-hint" style={{ fontSize: '12px' }}>
                        <FormattedMessage
                            id={'components.UploadFileComponent.uploadHint'}
                        />
                    </p>
                </div>
            </Dragger>
            <Button
                style={{ backgroundColor: '#3f51b5', borderColor: '#3f51b5', width: '100%', color: 'white', marginTop: '10px', }}
                type="primary"
                onClick={handleSave}
            >
                <FormattedMessage id={'components.Driver.saveButton'} />
            </Button>
        </InputFileComponentStyles>
    );
}
export default InputFileComponent;
