// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';
const Detail = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;

Detail.Header = styled.div`
    display: flex;
    flex: 1;
    font-family: OpenSans;
`;

Detail.Image = styled.img`
    width: ${(props) => props.width || '40px'};
    height: ${(props) => props.height || '40px'};
`;

Detail.TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
`;
Detail.Text = styled.div`
    font-weight ${(props) => props.fontWeight};
    font-size: ${(props) => props.fontSize || '18px'};
    margin-top: ${(props) => props.marginTop};
    float: left;
`;

Detail.Action = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
`;

Detail.ActionButton = styled.div`
    width: 100%;
    min-width: 120px;
    margin-top: 5px;
    margin-bottom: 5px;
    @media screen and (min-width: ${screen.medium}) {
        width: 230px;
    }
`;

export default Detail;
