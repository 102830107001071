// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import Tooltip from 'antd/es/tooltip';

import {
    selectConfigurationEmails,
    selectMaintenanceAlarmState,
} from '../../../services/Maintenance/selectors';
import {
    getAllMaintenances,
    getMaintenance,
    getMaintenanceVeh,
    getMaintenanceVehByMaintId,
    insertMaintenance,
    insertMaintenanceEmail,
    insertMaintenanceVehicle,
    linkUnlinkEmailsMaintenance,
    linkUnlinkVehicleMaintenance,
    patchMaintenance,
    patchMaintenanceVehicle,
    removeAlarmVehicle,
    removeAllMaintenanceVehicle,
    removeMaintenanceEmail,
} from '../../../services/Maintenance/thunks';
import { Button, Table, message, Input as InputAntd, Modal } from 'antd';
import VehicleDetailManagement from '../VehicleDetail/styles';
import { Input, SearchInput } from '../../../components/SearchInput/styles';
import { parseJWT } from '../../../helpers';
import { selectUser } from '../../../services/Authentication/selectors';
import { sortArray } from '../helpers';
import AlarmConfig from '../AlarmConfig';
import AlarmManagementStyles from './styles';
import SelectVehiclesAlarms from '../SelectVehiclesAlarms';
import SelectUsersAlarms from '../SelectUsersAlarms';
import AlarmVehicleListStyle from '../AlarmVehicleList/styles';
import { i18nDistanceSymbol } from '../../../services/Units/thunks';

const AlarmManagementContainer = (props) => {
        const [alarmName, setAlarmName] = useState('');
        const [alarmListName, setAlarmListName] = useState([]);
        const [enabledAddbuttonAlarm, setEnableAddButtonAlarm] = useState(true);
        const [selectedAlarm, setSelectedAlarm] = useState(0);
        const [indexAlarmModify, setIndexAlarmModify] = useState(-1);
        const [selectedAlarmName, setSelectedAlarmName] = useState('');
        const [updatedAlarmName, setUpdatedAlarmName] = useState('');
        const [alarmDatas, setAlarmDatas] = useState([]);
        const [datasConfigAlarm, setDatasConfigAlarm] = useState();
        const [datasVehiclesSelected, setDatasVehiclesSelected] = useState();
        const [selectedUsers, setSelectedUsers] = useState();
        const [cumulCycle, setCumulCycle] = useState({});
        const [isModalDelete, setIsModalDelete] = useState(false);
        const [alarmDeleted, setAlarmDeleted] = useState(null);
        const [configAlarms, setConfigAlarms] = useState(true);

        useEffect(() => {
            setAlarmListName(sortArray(props.selectedAlarmList));
            setAlarmDatas(props.selectedAlarmList[0]);
        }, [alarmListName]);
        const jwt = parseJWT(props.user.get('token'));

        const handleCancelModal = () => {
            setIsModalDelete(false);
        };

        const handleOkModal = async () => {
            setIsModalDelete(false);
            await props.removeAlarmVehicle(alarmDeleted.maintId)
                .then(() => {
                    successNotif(
                        props.intl.formatMessage({
                            id: 'containers.Maintenance.Notif.RemoveAlarm',
                        }),
                    );
                })
                .catch(() => {
                    failureNotif(
                        props.intl.formatMessage({
                            id: '  containers.Maintenance.Notif.Error',
                        }),
                    );
                });
            //  update store
            await props.getMaintenance(jwt['https://geothentic.com/branch_id'])
                .then((response) => {
                    setAlarmListName(sortArray(response));
                });
        };

        const isContainAlarmName = (name) => {
            return alarmListName.some((ele) => {
                return capitalizeFirstLetter(ele.name) === capitalizeFirstLetter(name);
            });
        };

        const successNotif = (msg) => {
            message.success(msg, 2);
        };
        const successMsg = (msg) => {
            message.success(msg);
        };
        const errorMsg = (msg) => {
            message.error(msg, 5);
        };
        const failureNotif = (msg) => {
            message.warning(msg, 2);
        };

        const failureNotifPrealert = (msg) => {
            message.error(msg, 5);
        };

        const handleInputAddAlarm = (event) => {
            setEnableAddButtonAlarm(event.target.value.trim() === '');
            setAlarmName(event.target.value);
        };

        const handleAddAlarm = () => {
            if (isContainAlarmName(alarmName)) {
                failureNotif(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.Notif.ErrorContains',
                    }),
                );
            }
            if (alarmName.trim().length > 0 && !isContainAlarmName(alarmName)) {
                setAlarmListName((alarmListName) => alarmListName.concat(alarmName.trim()));

                props
                    .insertMaintenance(jwt['https://geothentic.com/branch_id'], {
                        name: alarmName,
                        distance: 0,
                        hrs: 0,
                        hrs_reminder: 0,
                        distance_reminder: 0,
                        branch_id: jwt['https://geothentic.com/branch_id'],
                        periodic: 0,
                        periodic_reminder: 0,
                        periodic_factor: 'N',
                        periodic_reminder_factor: 'N',
                    })
                    .then((res) => {
                        successNotif(
                            props.intl.formatMessage({
                                id: 'containers.Maintenance.Notif.AddAlarm',
                            }),
                        );
                        setAlarmName('');
                        props.getMaintenance(jwt['https://geothentic.com/branch_id'])
                            .then((response) => {
                                setAlarmListName(sortArray(response));
                            });
                    })

                    .catch(() =>
                        failureNotif(
                            props.intl.formatMessage({
                                id: '  containers.Maintenance.Notif.Error',
                            }),
                        ),
                    );

            }
        };

        const onChange = (value, index) => event => {
            setSelectedAlarmName(value.name);
            setSelectedAlarm(index);
            setAlarmDatas(value);

        };

        const handleDelete = (selectedAlarmName) => async (event) => {
            setAlarmDeleted(selectedAlarmName);
            setIsModalDelete(true);

        };

        const handleModifyAlarm = (index) => (event) => {
            setIndexAlarmModify(index);
        };
        const handleAlarmName = (event) => {
            setUpdatedAlarmName(event.target.value);
        };

        const handleSave = (selectedAlarm) => async (event) => {
            const datas = {
                name: selectedAlarm.name,
                km: selectedAlarm.distance,
                hrsEngine: selectedAlarm.hrs,
                periodic: selectedAlarm.periodic,
                periodicFactor: selectedAlarm.periodicFactor,
                reminderKm: selectedAlarm.distanceReminder,
                reminderHrsEngine: selectedAlarm.hrsReminder,
                reminderPeriodic: selectedAlarm.periodicReminder,
                reminderPeriodicFactor: selectedAlarm.periodicReminderFactor,
                users: [],
                vehicles: [],
            };
            //si l'alarme existe deja
            if (isContainAlarmName(updatedAlarmName.trim())) {
                failureNotif(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.Notif.ErrorContains',
                    }),
                );
            } else if (updatedAlarmName.trim() !== '') {
                datas.name = updatedAlarmName.trim();
                selectedAlarm.name = datas.name; // update original reference
                await props
                    .patchMaintenance(selectedAlarm.maintId, datas)
                    .then(() => {
                        successMsg(
                            props.intl.formatMessage({
                                id: 'containers.Maintenance.Notif.applyChanges',
                            }),
                        );
                    })
                    .catch(() => {
                        errorMsg(
                            props.intl.formatMessage({
                                id: 'containers.Maintenance.Notif.Error',
                            }),
                        );
                    });
                setUpdatedAlarmName('');
                setIndexAlarmModify(-1);
            } else {
                failureNotif(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.Notif.noChanges',
                    }),
                );
            }

        };
        const handleCancel = (event) => {
            setIndexAlarmModify(-1);
        };
        const capitalizeFirstLetter = (str) => {
            if (str !== undefined) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            }

        };
        const handleInsertMaintenance = async (event) => {

            if (configAlarms) {
                if (selectedUsers !== undefined) {
                    await props.linkUnlinkEmailsMaintenance(jwt['https://geothentic.com/branch_id'], alarmDatas.maintId, selectedUsers);
                }

                if (datasVehiclesSelected !== undefined) {
                    await props.linkUnlinkVehicleMaintenance(jwt['https://geothentic.com/branch_id'], alarmDatas.maintId, datasVehiclesSelected);
                }


                if (cumulCycle.length > 0) {
                    cumulCycle.map(async (item) => {
                        await props.patchMaintenanceVehicle(item.vehId, item.datas);
                    });
                }

                if (datasConfigAlarm !== undefined) {
                    if(props.i18nDistanceSymbol()==='miles') {
                        datasConfigAlarm.km = Math.round(datasConfigAlarm.km / 0.621)
                        datasConfigAlarm.reminder_km = Math.round(datasConfigAlarm.reminder_km / 0.621)
                    }
                    await props.patchMaintenance(alarmDatas.maintId, datasConfigAlarm);
                }
                await props.handleManageAlarms(false);
               await successNotif(props.intl.formatMessage({
                    id: 'containers.Maintenance.Warning.Saving',
                }),)
            } else {
                let element = document.getElementById('AlarmConfigComponent');
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                failureNotifPrealert(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.Warning.PreAlert',
                    }),
                );

            }

        };

        const handleCumulCycle = (vehId, datas) => {
            setCumulCycle([...cumulCycle, { vehId: vehId, datas: datas }]);
        };
        const handleConfig = (configAlert) => {

            if (parseInt(configAlert.km) < parseInt(configAlert.reminder_km) || parseInt(configAlert.hrs_engine) < parseInt(configAlert.reminder_hrs_engine) || parseInt(configAlert.periodic) < parseInt(configAlert.reminder_periodic)) {
                setConfigAlarms(false);
            } else {
                setConfigAlarms(true);
            }

            setDatasConfigAlarm(configAlert);
        };
        const handleSelectedVehicles = (selectedVehicles) => {
            setDatasVehiclesSelected(selectedVehicles);
        };

        const handleSelectedUsers = (selectedUsers) => {
            setSelectedUsers(selectedUsers);
        };
        const handleGoBack = () => {
            props.handleManageAlarms(false);
        };

        const columns = [
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfNameOfAlarm' }),
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                render: (value, record, index) => {
                    return (
                        <AlarmManagementStyles.CheckBox>
                            {
                                indexAlarmModify === index ?
                                    (<InputAntd  defaultValue={capitalizeFirstLetter(value)}
                                                onChange={handleAlarmName} />)
                                    : (<div>
                                        <input id={index} name={'alarmList'} type={'radio'}
                                               onChange={onChange(record, index)}
                                               value={selectedAlarm}
                                               defaultChecked={index === 0}
                                        />
                                        <label  htmlFor={index}>
                                            {value && value.length <= 70 ? (
                                                <Tooltip title={value}>
                                                    <span> {value.substring(0, value.length) }</span>
                                                </Tooltip>
                                            ) : capitalizeFirstLetter(value)}
                                        </label>
                                    </div>)
                            }
                        </AlarmManagementStyles.CheckBox>


                    );
                },

            },
            {

                key: 'action',
                render: (value, record, index) => {
                    return (
                        <AlarmManagementStyles.Btn>
                            <Button type='primary'
                                    onClick={indexAlarmModify === index ? handleSave(record) : handleModifyAlarm(index)}>
                                <FormattedMessage
                                    id={indexAlarmModify === index ? 'containers.Maintenance.AlarmManagement.BtnSave' : 'containers.Maintenance.AlarmManagement.BtnModify'} />
                            </Button>

                            <Button type='primary'
                                    onClick={indexAlarmModify === index ? handleCancel : handleDelete(record)}>
                                <FormattedMessage
                                    id={indexAlarmModify === index ? 'containers.Maintenance.Modal.Cancel' : 'containers.Maintenance.AlarmVehicle.Retirer'} />
                            </Button>
                        </AlarmManagementStyles.Btn>);
                },
            },
        ];

        return (

            <VehicleDetailManagement>
                <FormattedMessage id={'containers.Maintenance.AlarmVehicle.Management'} />
                <hr />
                <AlarmManagementStyles>
                    <div className={'title'}><FormattedMessage id={'containers.Maintenance.AlarmManagement.Title'} /></div>

                    <AlarmManagementStyles.SearchContainer>
                        <SearchInput>
                            <Input
                                placeholder={props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfNameOfAlarm' })}
                                name={alarmName}
                                value={alarmName}
                                onChange={handleInputAddAlarm}
                            />
                        </SearchInput>
                        <Button onClick={handleAddAlarm} disabled={enabledAddbuttonAlarm}>
                            <FormattedMessage id={'containers.Maintenance.AlarmManagement.BtnAddAlarm'} />
                        </Button>
                    </AlarmManagementStyles.SearchContainer>
                    <hr />
                    <div>
                        <Table
                            columns={columns}
                            dataSource={alarmListName}
                            rowKey={record => record.name}
                            pagination={false}
                            scroll={{ y: 500 }}
                            width={700}
                        />
                    </div>
                    <hr />
                    <div id={'AlarmConfigComponent'}>
                        <AlarmConfig alarmsDatas={alarmDatas}
                                     handleConfig={handleConfig} />
                    </div>

                    <hr />
                    <SelectVehiclesAlarms vehicleList={props.vehicleList} alarmsDatas={alarmDatas}
                                          handleSelectedVehicles={handleSelectedVehicles}
                                          handleCumulCycle={handleCumulCycle} />
                    <hr />
                    <SelectUsersAlarms handleSelectedUsers={handleSelectedUsers} alarmsDatas={alarmDatas} />
                    <hr />
                    <AlarmVehicleListStyle.TableFooter>
                        <Button onClick={handleGoBack}>
                            <FormattedMessage id={'containers.Maintenance.AlarmManagement.BtnQuit'} />
                        </Button>

                        <Button onClick={handleInsertMaintenance}>
                            <FormattedMessage id={'containers.Maintenance.AlarmManagement.BtnSave'} />
                        </Button>
                    </AlarmVehicleListStyle.TableFooter>

                    <Modal
                        title={props.intl.formatMessage({ id: 'containers.Maintenance.AlarmManagement.DeleteAlarm' })}
                        visible={isModalDelete}
                        onCancel={handleCancelModal}

                        footer={[
                            <AlarmVehicleListStyle.ModalDelete key={'modalDelete'}>
                                <Button
                                    key='no'
                                    className={'redBtn'}
                                    type='primary'
                                    onClick={handleCancelModal}
                                >
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalNo'} />
                                </Button>
                                <Button
                                    key='yes'
                                    className={'greenBtn'}
                                    type='primary'
                                    onClick={handleOkModal}
                                >
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalYes'} />
                                </Button>
                            </AlarmVehicleListStyle.ModalDelete>,

                        ]}>

                        <p>{alarmDeleted ? alarmDeleted.name : null}</p>
                    </Modal>

                </AlarmManagementStyles>


            </VehicleDetailManagement>
        );
    }
;

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector(
    {
        selectedAlarmList: selectMaintenanceAlarmState(),
        user: selectUser(),
        selectedConfigurationEmails: selectConfigurationEmails(),
    });

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            removeAllMaintenanceVehicle,
            getMaintenanceVeh,
            removeMaintenanceEmail,
            removeAlarmVehicle,
            patchMaintenance,
            getMaintenance,
            insertMaintenance,
            insertMaintenanceVehicle,
            insertMaintenanceEmail,
            patchMaintenanceVehicle,
            getAllMaintenances,
            linkUnlinkVehicleMaintenance,
            getMaintenanceVehByMaintId,
            linkUnlinkEmailsMaintenance,
            i18nDistanceSymbol
        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(AlarmManagementContainer));
