/* eslint-disable flowtype/require-parameter-type,react/jsx-no-bind */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useCallback, useEffect, useState } from 'react';
import useStyles from '../../styles/List.styles';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { Loading } from '../../utils/Loading';
import { Message } from '../../utils/Message';
import AlertDialog from '../../utils/AlertDialog';
import SearchField from '../../utils/SearchField';
import { formatString, InfoText } from '../../utils/String';
import { useIntlContext } from '../../context/IntlContext';

const VehicleList = ({ vehicles, isLoading, onDeletedVehicle, isDefaultGroup }) => {
    const classes = useStyles();
    const { intl } = useIntlContext();
    const [deleteVehicle, setDeleteVehicle] = useState(null);
    const [searchVehicles, setSearchVehicles] = useState([]);
    const [searchVehicleValue, setSearchVehicleValue] = useState(null);

    const handleDeleteVehicle = useCallback((vehicleId) => {
        onDeletedVehicle(vehicleId);
        setDeleteVehicle(null);
    });

    const handleSearchChange = useCallback((event) => setSearchVehicleValue(event.target.value));

    useEffect(
        () => {
            if (vehicles) {
                if (!searchVehicleValue) setSearchVehicles(vehicles);
                if (searchVehicleValue) {
                    setSearchVehicles(
                        vehicles.filter(({ name }) => formatString(name).includes(formatString(searchVehicleValue)))
                    );
                }
            }
        },
        [setSearchVehicles, searchVehicleValue, vehicles]
    );

    const DeleteDialog = () =>
        deleteVehicle && (
            <AlertDialog
                onCancel={() => setDeleteVehicle(null)}
                onAgree={() => handleDeleteVehicle(deleteVehicle.id)}
                open={deleteVehicle}
                title={deleteVehicle.name}
                message={'views.Groups.DeleteMessage'}
                cancelText={'views.Groups.Cancel'}
                agreeText={'views.Groups.Yes'}
            />
        );

    if (isLoading || !vehicles) return <Loading />;

    if (vehicles && !vehicles.length) return <Message id={'views.Groups.NoVehicles'} />;

    return (
        <React.Fragment>
            <SearchField onChange={handleSearchChange} label={'views.Groups.SearchVehicle'} />
            <List className={classes.list} dense>
                <InfoText
                    value={`${intl.formatMessage({
                        id: 'views.Groups.Vehicles',
                    })} (${searchVehicles.length})`}
                />
                {searchVehicles.map((vehicle) => (
                    <ListItem key={vehicle.id} className={classes.item} button>
                        <ListItemText primary={vehicle.name} />
                        {!isDefaultGroup ? (
                            <IconButton size={'small'} edge="end" onClick={() => setDeleteVehicle(vehicle)}>
                                <DeleteForever />
                            </IconButton>
                        ) : null }
                    </ListItem>
                ))}
            </List>
            <DeleteDialog />
        </React.Fragment>
    );
};

export default VehicleList;
