// @flow

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import dateFns from 'date-fns';

import GroupButton from 'components/GroupButton';
import OutlineButton from 'components/OutlineButton';

import { adminLoginAsUser, adminDirectAccess } from 'services/Authentication/thunks';
import { selectUser } from 'services/Authentication/selectors';

import { Dashboard } from 'styles/dashboard';
import { User } from './styles';
import colors from 'styles/colors';
import { parseJWT } from 'helpers';
type Props = {
    data: any,
};

class DashboardDataUserItem extends React.Component<Props, State> {
    formatLastLogin = (user) => {
        if (!user.get('lastLogin')) {
            return null;
        }

        return dateFns.format(user.get('lastLogin'), 'YYYY/MM/DD - h:mm A');
    };

    handleShowUserOptions = (user) => () => {
        this.props.handleShowUserOptions(user);
    };

    handleOnClickLoginAsUser = (user: UserType) => (e: Event) => {
        e.stopPropagation();
        this.props.adminLoginAsUser(user).then(() => {
            // redirect user after loging-in as user.
            this.props.history.replace(`/${this.props.locale}/map`);
        });
    };

    handleOnClickEditUser = (user: UserType) => (e: Event) => {
        let enabled = true;
        user.get('groups')
            .toArray()
            .map(group => {
                if(typeof group !== 'number') {
                    return group.get('name');
                } else {
                    enabled = false
                }
            });
        if (enabled) {
            this.props.onClickEditUser(user.get('id'));
            this.props.handleShowUserOptions(user);
        }
    };

    handleOnClickDeleteUser = (user: UserType) => (e: Event) => {
        this.props.onClickDeleteUser(user.get('id'));
        this.props.handleShowUserOptions(user);
    };

    handleOnClickEditRoles = (user: UserType) => (e: Event) => {
        this.props.onClickEditRoles(user);
        this.props.handleShowUserOptions(user);
    };

    renderUserGroups = (user) => {
        const parsed = parseJWT(this.props.user.get('token'));
        const permission = parsed.permissions || [];
        const canImpersonate = permission.indexOf('impersonate:user') !== -1;
        let groups = null;
        if (user.get('groups')) {
            groups = user
                .get('groups')
                .take(3)
                .map((group, index) => (
                    <User.GroupButtonSpan>{typeof group === 'number' ? group : group.get('name')}</User.GroupButtonSpan>
                ));
        }
        return (
            <User.GroupButtons>
                {groups}
                <User.UserOptions style={{marginLeft: '5px'}}>
                    <OutlineButton
                        width={'40px'}
                        outlineColor={colors.green62}
                        selected={this.props.showUserOptions === user.get('id')}
                        onClick={this.handleShowUserOptions(user)}
                        marginTop={'0px'}
                        hoverTextColor={colors.white}
                        backgroundColorHovered={colors.green62}
                    >
                        {'...'}
                    </OutlineButton>
                    {this.props.showUserOptions === user.get('id') && (
                        <User.UserOptionsDropdown>
                            {/* This feature is being deactivated as there are issues with the
                            applying the user’s permissions instead of the Admin permissions. Ref
                            ORCA-1056 */}
                            {/* {canImpersonate && (
                                <User.DropdownItem onClick={this.handleOnClickLoginAsUser(user)}>
                                    <FormattedMessage id={'components.DashboardData.LoginAs'} />
                                </User.DropdownItem>
                            )} */}
                            <User.DropdownItem
                                onClick={this.handleOnClickEditUser(user)}
                                backgroundColorHovered={colors.green62}
                                textColorHovered={colors.status.red}
                            >
                                <FormattedMessage id={'components.DashboardData.Edit'} />
                            </User.DropdownItem>
                            <User.DropdownItem
                                onClick={this.handleOnClickDeleteUser(user)}
                                backgroundColorHovered={colors.green62}
                                textColorHovered={colors.status.red}
                            >
                                <FormattedMessage id={'components.DashboardData.Delete'} />
                            </User.DropdownItem>
                        </User.UserOptionsDropdown>
                    )}
                </User.UserOptions>
            </User.GroupButtons>
        );
    };

    render() {
        const { data } = this.props;
        return (
            <React.Fragment>
                <User.Item>{`${data.get('firstName')} ${data.get('lastName')}`}</User.Item>
                <User.Item>{this.formatLastLogin(data)}</User.Item>
                <User.Item>{this.renderUserGroups(data)}</User.Item>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            adminLoginAsUser,
            adminDirectAccess,
        },
        dispatch
    );

const mapStateToProps: Object = createStructuredSelector({
    user: selectUser(),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardDataUserItem);
