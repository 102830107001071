// @flow

import request from 'services/request';
import requestFAPI from 'services/requestFAPI';
import adminRequest from 'services/adminRequest';
import type { Coordinates } from 'types';
import { MAPBOX_GL_TOKEN } from 'env';

/**
 * Request user vehicles.
 *
 * @param {string} token
 * @param {string} locale
 * @param {string} sort
 * @param {string} order
 */
export function retrieveInternalAPIVehicles(
    token: string,
    locale: string,
    sort: string,
    order: string,
    moduleTypes: string
): Promise<> {
    let url = `${locale}/equipment`;
    url = sort && order ? `${url}?sort=${sort}&order=${order}&moduletypes=${moduleTypes}` : url;
    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}


/**
 * Request to stop anti theft alarm
 *
 * @param {string} token
 * @param {number} id
 */
export function requestStopAntitheftAlarm(
    token: string,
    id: number,
): Promise<> {
    const url = `vehicles/${id}/commands/stop-antitheft-alarm`;
    return adminRequest(
        url,
        {
            method: 'POST',
        },
        false,
        token
    );
}

/**
 * Request driver call by vehicle Id
 *
 * @param {string} token
 * @param {number} id
 */
export function requestDriverCall(
    token: string,
    id: number,
): Promise<> {
    const url = `vehicles/${id}/commands/request-driver-call`;
    return adminRequest(
        url,
        {
            method: 'POST',
        },
        false,
        token
    );
}


/**
 * Request driver call by vehicle Id
 *
 * @param {string} token
 * @param {number} id
 */
export function cancelRequestDriverCall(
    token: string,
    id: number,
): Promise<> {
    const url = `vehicles/${id}/commands/cancel-driver-call`;
    return adminRequest(
        url,
        {
            method: 'POST',
        },
        false,
        token
    );
}

export function retrieveVehicles(
    token: string,
    locale: string,
    sort: string,
    order: string,
    moduleTypes: string,
    page: int
): Promise<> {
    return getVehiclesAdmin(token, page, sort).then(function(result) {
        const ret = {};
        if (result['data']) {
            if (result['data'] && result['data']['links']) {
                ret.links = result['data']['links'];
            }
        }
        ret.data = extractVehicles(result['data']['vehicles']);
        return Promise.resolve(ret);
    });
}

function extractVehicles(vehicles) {
    return vehicles.map((x) => {
        let veh = {};
        veh.id = x.id;
        veh.name = x.name;
        veh.branchId = x.branchId;
        veh.vin = x.vin;
        veh.cardNumber = x.cardNumber;
        veh.licensePlate = x.licensePlate;
        veh.odometer = x.odometer;
        veh.horometer = x.horometer;
        veh.lastUpdate = x.lastUpdate;
        veh.additionalData = x.additional_data;
        veh.sensorMask = x.sensorMask;
        veh.manufacturer = x.manufacturer;
        veh.model = x.model;
        veh.year = x.year;
        veh.buzzerState =x.buzzerState;
        veh.lastEcuData = x.lastEcuData;
        if (x.categorie) {
            veh.categorie = {};
            veh.categorie.categorieId = x.categorie.categorieId;
            veh.categorie.categorieName = x.categorie.categorieName;
            veh.categorie.vehName = x.name;
        } else {
            veh.categorie = {};
            veh.categorie.vehName = x.name;
        }

        if (x.configuration) {
            const configuration = {};
            configuration.activateAuxEvent = x.configuration.activateAuxEvent;
            configuration.activateAux2Event = x.configuration.activateAux2Event;
            configuration.activateAux3Event = x.configuration.activateAux3Event;
            configuration.activateAux4Event = x.configuration.activateAux4Event;
            configuration.aux1Name = x.configuration.aux1Name;
            configuration.aux2Name = x.configuration.aux2Name;
            configuration.aux3Name = x.configuration.aux3Name;
            configuration.aux4Name = x.configuration.aux4Name;
            configuration.garageModeAct = x.configuration.garageModeAct;
            configuration.garageModeChanged = x.configuration.garageModeChanged;
            configuration.rs232Active = x.configuration.rs232Active;
            configuration.aux2TempActive = x.configuration.aux2TempActive;
            configuration.aux3TempActive = x.configuration.aux3TempActive;
            configuration.aux4TempActive = x.configuration.aux4TempActive;
            configuration.activateOverspeed = x.configuration.activateOverspeed;
            veh.configuration = configuration;
        }
        veh.temperaturesRs232 = x.temperaturesRs232;
        veh.co2Rs232 = x.co2Rs232;
        veh.humidityRs232 = x.humidityRs232;

        if (x.latestLocation) {
            veh.latestRealTime = {
                heading: x.latestLocation.heading,
                longitudeWgs84: x.latestLocation.longitudeWgs84,
                latitudeWgs84: x.latestLocation.latitudeWgs84,
                datetime: x.latestLocation.datetime,
                speed: x.latestLocation.speed,
                address: x.latestLocation.address,
                operator: x.actualDriver,
                sensorKey: x.latestLocation.sensorKey,
                sensorAux: x.latestLocation.sensorAux,
                sensorAux2: x.latestLocation.sensorAux2,
                sensorAux3: x.latestLocation.sensorAux3,
                sensorAux4: x.latestLocation.sensorAux4,
                driverName: x.defaultDriverName,
                spreaderData: {
                    solidMatterAmount: x.latestLocation.solid_matter_amount,
                    saltWaterAmount: x.latestLocation.salt_water_amount,
                    traveledDistance: x.latestLocation.traveled_distance,
                    state: x.latestLocation.state,
                    mode: x.latestLocation.mode,
                    additionalData: x.latestLocation.data,
                },
            };

            if (x.rpm && x.rpm > 0) {
                veh.latestRealTime.rpm = x.rpm;
                veh.rpm = x.rpm;
            }
            if (x.temperatures) {
                if (x.temperatures.aux2) {
                    veh.latestRealTime.aux2Temp = x.temperatures.aux2.temperature;
                }
                if (x.temperatures.aux3) {
                    veh.latestRealTime.aux3Temp = x.temperatures.aux3.temperature;
                }
                if (x.temperatures.aux4) {
                    veh.latestRealTime.aux4Temp = x.temperatures.aux4.temperature;
                }
            }

            if (veh.additionalData && veh.additionalData.status) {
                let status = veh.additionalData.status;
                if (status && status.status) {
                    veh.latestRealTime.eventType = {
                        eventTypeId: status.status.event_type_id,
                        description: status.status.description,
                        frenchDescription: status.status.french_description,
                        englishDescription: status.status.english_description,
                        title: 'Périodique',
                        status: 'active',
                    };
                    veh.since = new Date(status.since);
                    veh.sinceTimestamp = status.since;
                }
            } else if (x.eventType) {
                veh.latestRealTime.eventType = {
                    eventTypeId: x.eventType.eventTypeId,
                    description: x.eventType.description,
                    frenchDescription: x.eventType.frenchDescription,
                    englishDescription: x.eventType.englishDescription,
                    title: 'Périodique',
                    status: 'active',
                };
            }

            if (x.latestLocation.address) {
                const address = {
                    streetAddress: x.latestLocation.address.streetAddress,
                    city: x.latestLocation.address.city,
                    province: x.latestLocation.address.province,
                    country: x.latestLocation.address.country,
                };
                veh.address = address;
                if (veh.latestRealTime) {
                    veh.latestRealTime.address = address;
                }
            }
        }

        if (x.additional_data && veh.additionalData.status) {
            let status = veh.additionalData.status;
            if (status && status.status) {
                veh.status = {
                    eventTypeId: status.status.event_type_id,
                    description: status.status.description,
                    frenchDescription: status.status.french_description,
                    englishDescription: status.status.english_description,
                    title: 'Périodique',
                    status: 'active',
                };
            }
        } else if (x.eventType) {
            veh.status = {
                eventTypeId: x.eventType.eventTypeId,
                description: x.eventType.description,
                frenchDescription: x.eventType.frenchDescription,
                englishDescription: x.eventType.englishDescription,
                title: 'Périodique',
                status: 'active',
            };
        }

        if (veh.additionalData) {
            let additionalData = veh.additionalData;
            let icon = undefined;
            let icons = additionalData.icons;
            if (icons) {
                let aux = icons.aux;
                if (veh.latestRealTime && veh.latestRealTime.sensorAux && aux) {
                    icon = aux.aux_1;
                } else if (veh.latestRealTime && veh.latestRealTime.sensorAux2 && aux) {
                    icon = aux.aux_2;
                } else if (veh.latestRealTime && veh.latestRealTime.sensorAux3 && aux) {
                    icon = aux.aux_3;
                } else if (veh.latestRealTime && veh.latestRealTime.sensorAux4 && aux) {
                    icon = aux.aux_4;
                }
                if (!icon && icons.vehicle) {
                    icon = icons.vehicle;
                }
                veh.customIcon = icon;
            }
        }

        if (!veh.since && x.lastUpdate) {
            veh.since = new Date(x.lastUpdate * 1000);
        }

        return veh;
    });
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveVehicle(token: string, locale: string, id: number): Promise<> {
    const url = `${locale}/equipment/${id}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {number} id
 */
export function retrieveVehicleDetail(token: string, id: number): Promise<> {
    const url = `vehicles/${id}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveVehicleById(token: string, id: number): Promise<> {
    const url = `vehicles/${id}`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 * Request user vehicles.
 *
 * @param {string} token
 * @param {string} locale
 */
export function retrieveEventTypes(token: string, locale: string): Promise<> {
    return request(
        `${locale}/eventtypes`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveEquipmentHistory(
    equipmentId: number,
    token: string,
    locale: string,
    periodDateFrom: string = '',
    periodDateTo: string = '',
    periodTimeFrom: string = '',
    periodTimeTo: string = ''
): Promise<> {
    let periodFrom = '';
    if (periodDateFrom && periodTimeFrom) {
        periodFrom = `${periodDateFrom} ${periodTimeFrom}`;
    }

    let periodTo = '';
    if (periodDateTo && periodTimeTo) {
        periodTo = `${periodDateTo} ${periodTimeTo}`;
    }

    return request(
        `${locale}/equipment/${equipmentId}/realtimes?from=${periodFrom}&to=${periodTo}&order=asc`,
        { method: 'GET' },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} serialID
 */
export function retrieveFleetEquipmentById(
    token: string,
    serialID: number,
    startDate: string,
    endDate: string,
    pageNumber: number,
    isTempReport: boolean
): Promise<> {
    const url =
        `Fleet/Equipment/SERIAL/${serialID}/Locations/${startDate}/${endDate}/${pageNumber}?pageSize=` +
        (isTempReport === undefined ? `1000` : `10000`);
    return requestFAPI(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveVehicleHistoryDetail(
    token: string,
    id: number,
    startDate: string,
    endDate: string,
    pageNumber: number,
    includeLineString: boolean = false
): Promise<> {
    const url = `vehicles/${id}/events/${startDate}/${endDate}/${pageNumber}?lineString=${includeLineString}&pageSize=500`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function mapboxDirections(coordinates: Coordinates, isOverview) {
    const profile = 'driving';
    const coordinatesList = coordinates.join(';');

    const url = isOverview
        ? `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinatesList}?geometries=geojson&steps=false&overview=full&access_token=${MAPBOX_GL_TOKEN}`
        : `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinatesList}?geometries=geojson&steps=false&access_token=${MAPBOX_GL_TOKEN}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        '',
        false
    );
}

/**
 *
 * @param {*} vehicleGeoDataChunk
 */
export function mapboxMapMatching(vehicleGeoDataChunk) {
    const profile = 'driving';
    const tidy = 'false';

    // If no coordinates, don't try matching them
    if (typeof vehicleGeoDataChunk === 'undefined' || vehicleGeoDataChunk.length === 0) {
        return [];
    }

    const coordinatesArr = [];
    const timestampsArr = [];
    const radiusesArr = [];

    vehicleGeoDataChunk.forEach((chunk) => {
        coordinatesArr.push(chunk.coordinates);
        // timestamps need to be in epoch time = 1576160217 and NOT 1576160217000
        timestampsArr.push(
            chunk.timestamp.toString().length > 10 ? chunk.timestamp / 1000 : chunk.timestamp
        );

        // Adjust radius based on confidence
        let radius = 5;
        if (chunk.confidence === 0) {
            radius = 50;
        } else if (chunk.confidence === 1) {
            radius = 40;
        } else if (chunk.confidence === 2) {
            radius = 30;
        } else if (chunk.confidence === 3) {
            radius = 25;
        } else if (chunk.confidence === 4) {
            radius = 15;
        } else if (chunk.confidence === 5) {
            radius = 15;
        }
        radiusesArr.push(radius);
    });

    const coordinatesList = coordinatesArr.join(';');
    const timestampsList = timestampsArr.join(';');
    const radiusesList = radiusesArr.join(';');

    const url = `https://api.mapbox.com/matching/v5/mapbox/${profile}/${coordinatesList}?radiuses=${radiusesList}&geometries=geojson&overview=full&tidy=${tidy}&access_token=${MAPBOX_GL_TOKEN}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        '',
        false
    );
}

export function mapboxGeoToAddress(latitude, longitude) {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_GL_TOKEN}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        '',
        false
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveExternalVehicle(
    token: string,
    locale: string,
    id: number,
    source: string
): Promise<> {
    const url = `${locale}/equipment/${id}?source=${source}`;

    return request(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function getVehicleAdmin(id: number, token: string): Promise<> {
    return adminRequest(
        `vehicles/${id}`,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function getVehiclesAdmin(
    token: string,
    page = 1,
    sort = 'name',
    includeCategorie = true
): Promise<> {
    if (sort == 'since') {
        sort = 'lastUpdate';
    }

    const veh = adminRequest(
        `vehicles/?pageSize=500&includeConfiguration=true&includeLatestRealtime=true&pageNumber=` +
            page +
            '&orderBy=' +
            sort +
            '&ascending=false' +
            '&includeCategorie=' +
            includeCategorie,
        {
            method: 'GET',
        },
        false,
        token
    );

    return veh;
}

export function changeWebHookState(id: number, token: string, data): Promise<> {
    return adminRequest(
        `vehicles/${id}`,
        {
            method: 'PATCH',
            data,
        },
        false,
        token
    );
}

/**
 * Edit WebHook by Equipment.
 *
 * @param {string} token
 * @param {number} equipementId
 * @param {*} data
 */
export function editWebHookByEquipementId(token: string, equipementId: number, data: *): Promise<> {
    const url = `vehicles/${equipementId}/webhooks-data`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Edit WebHook by Equipment.
 *
 * @param {string} token
 * @param {number} branchId
 * @param {*} data
 */
export function editGarageModeIcons(token: string, branchId: number, data: *): Promise<> {
    const url = `branches/${branchId}/vehicles/config`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 * @param {*} data
 */
export function updateHorometerOdometer(
    token: string,
    locale: string,
    id: number,
    data: *
): Promise<> {
    const url = `vehicles/${id}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data,
        },
        true,
        token
    );
}
