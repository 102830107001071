// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import  chunk  from 'lodash/chunk';

import colors from 'styles/colors';

import Pagination from 'react-js-pagination';

import OutlineButton from 'components/OutlineButton';
import SelectField from 'components/SelectField';

import DashboardDataTable from 'components/DashboardDataTable';

import { HeaderText, SelectContainer, TableWrapper, Table, Button } from './styles';

import type { ColumnType } from 'types';

type Props = {
    title: string,
    tableData: ImmutableList<any>,
    columns: Array<ColumnType>,
};

type State = {
    itemsDisplayed: number,
    currentPage: number,
    tableData: ?ImmutableList<any>,
    sortKey: string,
};

const SELECT_VALUES = [10, 20, 30, 40, 50];

class ReportDataTable extends React.PureComponent<Props, State> {
    static defaultProps = {};

    state = {
        itemsDisplayed: 10,
        currentPage: 1,
        tableData: this.props.tableData,
        sortKey: '',
    };

    selectOptions = SELECT_VALUES.map((value) => ({
        label: this.props.intl
            .formatMessage({ id: 'containers.Reports.Generators.Driving.ShowResults' })
            .replace('$value', value),
        value,
    }));

    handleOnChangeSelect = (value: number) =>
        this.setState({ itemsDisplayed: value, currentPage: 1 });

    handleOnChangePage = (value: number) => {
        this.setState({ currentPage: value });
    };

    getDefaultSort = (previousKey: string, key: string) => {
        const ascSort = (a, b) => {
            if (a.get(key) < b.get(key)) {
                return -1;
            }

            if (a.get(key) > b.get(key)) {
                return 1;
            }

            if (a.get(key) === b.get(key)) {
                return 0;
            }
        };

        const descSort = (a, b) => {
            if (a.get(key) < b.get(key)) {
                return 1;
            }

            if (a.get(key) > b.get(key)) {
                return -1;
            }

            if (a.get(key) === b.get(key)) {
                return 0;
            }
        };

        return previousKey === key ? descSort : ascSort;
    };

    handleOnClickHeaderItem = (key: string, sortMethod: SortFunction = null) => () => {
        if (!this.state.tableData) {
            return;
        }

        const defaultSort = this.getDefaultSort(this.state.sortKey, key);

        const sort = sortMethod || defaultSort;

        const sortedData = this.state.tableData.sort(sort);
        this.setState({
            tableData: sortedData,
            sortKey: this.state.sortKey === key ? '' : key,
            currentPage: 1,
        });
    };

    getTableData = (data: ImmutableList<>) => {
        // slice up array into index's and
        if (!data || !data.size) {
            return null;
        }

        const { currentPage, itemsDisplayed } = this.state;

        const chunked = chunk(this.state.tableData.toArray(), itemsDisplayed);
        return fromJS(chunked[this.state.currentPage - 1]);
    };

    renderPageButttons = () => {
        if (!this.state.tableData || !this.state.tableData.size) {
            return null;
        }

        return (
            <Button.Container>
                <Pagination
                    onChange={this.handleOnChangePage}
                    activePage={this.state.currentPage}
                    itemsCountPerPage={this.state.itemsDisplayed}
                    totalItemsCount={this.state.tableData.size}
                    pageRangeDisplayed={5}
                    // disabledClass='disabled'
                    // hideNavigation
                    // hideDisabled
                    // hideFirstLastPages
                />
            </Button.Container>
        );
    };

    render() {
        const { title, tableData } = this.props;
        const data = this.getTableData(tableData);

        return (
            <div style={{height:'93% !important'}}>
                <HeaderText>{title}</HeaderText>
                <SelectContainer>
                    <SelectField
                        options={this.selectOptions}
                        value={this.state.itemsDisplayed}
                        maxWidth={200}
                        textColor={colors.black}
                        onChange={this.handleOnChangeSelect}
                    />
                </SelectContainer>
                <TableWrapper>
                    <Table>
                        <DashboardDataTable
                            columns={this.props.columns}
                            tableData={data}
                            itemsDisplayed={this.state.itemsDisplayed}
                            showHeaderCaret={false}
                            onClickHeaderItem={this.handleOnClickHeaderItem}
                            report
                        />
                    </Table>
                </TableWrapper>
                {this.renderPageButttons()}
            </div>
        );
    }
}

export default injectIntl(ReportDataTable);
