import React, { useEffect, useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { ApHomeIcon, ApReservationIcon, ApSearchIcon } from '../../components/Icons';
import { ApVector } from '../../components/Icons';
import './index.scss';
import { getSectors, openReservationModal } from '../../services/AutoPartage/thunks';
import { selectReservationModal } from '../../services/AutoPartage/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import SectorsMap from "../../components/SectorsMap";
import ApSectorList from "../../components/ApSectorList";
import { toggleSideMenu } from "../../services/SideMenu/thunks";
import { Divider, Button } from "antd";
import { selectIsSideMenuOpen } from "../../services/SideMenu/selectors";
import { selectGeofences } from "../../services/Geofence/selectors";
import { getGeofencesByBranch } from "../../services/Geofence/thunks";

function ApSectorsContainer(props) {
    const styles = {
        mapContainer: {
            position: 'absolute',
            height: '84%',
            width: '70%',//51.5%
            margin: 'auto',
            borderRadius: '10px',
            borderColor: '#f7f8f9'
        },
        sectorListOverlay: {
            height: '84%',
            position: 'absolute',
            left: props.isOpen ? '76%' : '75%',
            width: '22%',
            maxHeight: 'calc(100% - 40px)',
            zIndex: 2,
        }
    };

    const mapRef = useRef(null);
    const [sectorsList, setSectorsList] = useState([]);
    const [geofences, setGeofencesList] = useState([]);
    const [isAddingSectorClicked, setIsAddingSectorClicked] = useState(false);
    const [isLoadingSectors, setIsLoadingSectors] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isLoadingAfterConfirmation, setIsLoadingAfterConfirmation] = useState(false);

    useEffect(() => {
        props.getGeofencesByBranch();
    }, []);

    useEffect(() => {
        props.getSectors().then((res) => {
            setSectorsList(res);
        });
    }, [props.getSectors]);

    useEffect(() => {
        if (isAddingSectorClicked === true) {
            setGeofencesList(props.geofences);
        } else if (props.geofences.size > 0 && sectorsList.length > 0) {
            const geoIdsInSector = new Set(sectorsList.map(obj => obj.geofenceId));
            const filtereSectorsInGeo = props.geofences.filter(obj => geoIdsInSector.has(obj.get('geofenceId')));
            setGeofencesList(filtereSectorsInGeo);
        }
    }, [props.geofences.size, sectorsList.length, isAddingSectorClicked]);




    const handleOnClickAddSector = () => {
        setIsLoadingSectors(true);
        setIsAddingSectorClicked(!isAddingSectorClicked);
        setShowCancelButton(!showCancelButton);
        setTimeout(() => {
            setIsLoadingSectors(false);
        }, 2000);
    };

    const handleCancelClick = () => {
        setIsAddingSectorClicked(false);
        setShowCancelButton(false);
    };

    return (
        <React.Fragment>
            <Container maxWidth={false} className="ap-container" style={{ height: '100%' }}>
                <Grid container spacing={2} direction="column" style={{ height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Breadcrumbs separator={(<ApVector />)} aria-label="breadcrumb">
                            <Link
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/"
                            >
                                <ApHomeIcon />
                            </Link>
                            <Link
                                underline="none"
                                sx={{
                                    display: 'flex', alignItems: 'center',
                                    "&:hover": {
                                        color: 'black',
                                    }
                                }}
                                className="ap-links"
                                color="#98A2B3"
                                href="/"
                            >
                                <FormattedMessage id={'containers.AP.Sector.Title'} />
                            </Link>
                        </Breadcrumbs>
                        {showCancelButton ?
                            <Button type="default" style={{ borderRadius: '7px', borderColor: '#62ab36' }}
                                    onClick={handleCancelClick}>
                                <FormattedMessage id={'containers.AP.Sector.Buttons.Cancel'} />
                            </Button> :
                            <Button type="primary"
                                    style={{
                                        display: 'flex',
                                        backgroundColor: '#62ab36',
                                        borderRadius: '7px',
                                        borderColor: '#62ab36',
                                        marginRight: '10px'
                                    }}
                                    onClick={handleOnClickAddSector}
                            >
                                <ApReservationIcon />
                                <div className="ap-reservation-text"><FormattedMessage id={'containers.AP.Sector.Component.Title'} /></div>
                            </Button>
                        }
                    </div>
                    <Divider />
                    <Grid >
                        <div style={{ width: '100%', height: '100%' }}>
                            <div style={styles.mapContainer}>
                                <SectorsMap geofences={geofences} sectorsList={sectorsList} isAddingSectorClicked={isAddingSectorClicked} mapRef={mapRef}  />
                            </div>
                            <div style={styles.sectorListOverlay}>
                                <ApSectorList
                                    sectorsList={sectorsList}
                                    isAddingSectorClicked={isAddingSectorClicked}
                                    setIsAddingSectorClicked={setIsAddingSectorClicked}
                                    setShowCancelButton={setShowCancelButton}
                                    geofences={props.geofences} //geofences
                                    isLoadingSectors={isLoadingSectors}
                                    setShowSuccessMessage={setShowSuccessMessage}
                                    showSuccessMessage={showSuccessMessage}
                                    setIsLoadingAfterConfirmation={setIsLoadingAfterConfirmation}
                                    isLoadingAfterConfirmation={isLoadingAfterConfirmation}
                                    setSectorsList={setSectorsList}
                                    mapRef={mapRef}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

const mapStateToProps = createStructuredSelector({
    reservationModal: selectReservationModal(),
    isOpen: selectIsSideMenuOpen(),
    geofences: selectGeofences(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            openReservationModal,
            toggleSideMenu,
            getGeofencesByBranch,
            getSectors
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ApSectorsContainer))
);
