// @flow

import type { InputEvent } from 'types';

export const CHANGE_UNIT = 'app/Unit/CHANGE_UNIT';
export const DEFAULT_UNIT = 'metric';

export const receivedChangedUnit = (unit: string): GenericActionType => ({
    type: CHANGE_UNIT,
    payload: {
        unit,
    },
});
