import React, { Component } from 'react';
import ErrorPage from '../views/ErrorPage'; // Assure-toi d'importer ton composant de page d'erreur
import axios from 'axios';
import { ADMIN_API_URL } from 'env';
import Logo_Black from 'assets/logo-geothentic-sm.png';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Mettre à jour l'état pour afficher la page d'erreur
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const currentUrl = window.location.href;
    const token = sessionStorage.getItem('api_token');
    // Extract information from the error object
    const errorMessage = `${currentUrl} ${error.toString()} ${info.componentStack}`;
    
    // Add source map information if available
    const sourceURL = info.componentStack.match(/at\s+(.*)\s+\((.*):(\d+):(\d+)\)/);
    const detailedError = sourceURL 
      ? `${errorMessage} [Source: ${sourceURL[2]}:${sourceURL[3]}]` 
      : errorMessage;
    console.error("An error occurred: ", error, info);
  
    axios.post(`${ADMIN_API_URL}log`, {
      message: detailedError,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(err => {
      console.error("Error while sending error report: ", err);
    });
  }

  render() {
    console.log('testing is error', this.state.hasError)
    if (this.state.hasError) {
      return <ErrorPage Logo={Logo_Black} />;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
