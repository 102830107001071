// @flow

import { push } from 'react-router-redux';
import { matchPath } from 'react-router-dom';
import { receivedChangedLocale } from './actions';
import { AUTH0_DOMAIN } from 'env';
import { updateUser } from 'services/Admin/thunks';
import { parseJWT } from 'helpers';

export const changeLocale = (locale: string, updateMetadata: boolean = true) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const currentLocale = getState().getIn(['language', 'locale']);

    // replace current path.
    const currentPath = getState().getIn(['route', 'location', 'pathname']);
    const url = currentPath.replace(currentLocale, locale);
    const split = AUTH0_DOMAIN.split('.');
    const mainDomain = `${split[1]}.${split[2]}`;
    document.cookie = `lang=${locale};domain=${mainDomain};path='/';`;
    localStorage.setItem('locale', locale);
    dispatch(receivedChangedLocale(locale));
    return dispatch(push(url));
};

export const loadLocale = () => (dispatch: ReduxDispatch, getState: any) => {
    let currentLocale = window.location.pathname.substr(1).split('/')[0];
    const storedLocale = getCookie('lang') || localStorage.getItem('locale');
    if (currentLocale !== storedLocale) {
        currentLocale = storedLocale;
    }
    dispatch(changeLocale(currentLocale || storedLocale || 'fr'));
};

const getCookie = (key) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}