import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { toggleSideMenu } from '../../services/SideMenu/thunks';
import { selectIsSideMenuOpen } from '../../services/SideMenu/selectors';
import { circle as turfCircle, featureCollection, point as turfPoint } from '@turf/turf';
import { getGeoJsonPolygone, resetGeofences } from '../../views/MapPage/utils';

function SectorsMap(props) {
    const mapContainerRef = useRef(null);
    const mapRef = props.mapRef || useRef(null);
    const [popup, setPopup] = useState(new mapboxgl.Popup({ closeButton: false, closeOnClick: false }));
    const MAPSTYLE_DEFAULT = 'mapbox://styles/geothentic/ck3kbrwa21sd01coat5pliiq6';
    const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false);

    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: MAPSTYLE_DEFAULT,
                center: [-105.2551, 54.526],
                zoom: 1,
            });

            mapRef.current.on('load', () => {
                setIsMapStyleLoaded(mapRef.current.isStyleLoaded())
                if (Array.isArray(props.geofences) ? props.geofences.length > 0 : props.geofences.size > 0) {
                    handleMapGeofenceSettingsMenuShowAll();
                }
            });

            return () => mapRef.current.remove();
        }
    }, []);

    useEffect(() => {
        if (mapRef.current && (Array.isArray(props.geofences) ? props.geofences.length > 0 : props.geofences.size > 0)) {
            handleMapGeofenceSettingsMenuShowAll();
        }
    }, [props.geofences, props.sectorsList, props.geofences.size, isMapStyleLoaded]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.once('idle', updateFeatureStates);
        }
    }, [props.isAddingSectorClicked]);

    const logPaintProperties = (map) => {
        const fillPaint = map.getPaintProperty('geofences-fill', 'fill-color');
        const outlinePaint = map.getPaintProperty('geofences-outline', 'line-color');
        const polygonFillPaint = map.getPaintProperty('geofences-polygon-fill', 'fill-color');
        const polygonOutlinePaint = map.getPaintProperty('geofences-polygon-outline', 'line-color');
    };

    const handleMapGeofenceSettingsMenuShowAll = () => {
        if (!mapRef.current) return;

        const map = mapRef.current;

        resetGeofences(map);
        const geoJsonCircle = featureCollection([]);
        const geoJsonCircleCenters = featureCollection([]);
        const geoJsonPolygone = getGeoJsonPolygone();
        const bounds = new mapboxgl.LngLatBounds();
        let geofenceData = props.geofences;

        if (Array.isArray(geofenceData) && geofenceData.length === 0) {
            return;
        }

        if (!Array.isArray(geofenceData)) {
            geofenceData = geofenceData.toArray();
        }

        geofenceData.forEach((element, index) => {
            const longitude = element.get('longitude');
            const latitude = element.get('latitude');
            const range = element.get('range');
            const name = element.get('name');
            const polygonPoints = element.get('polygonPoints');

            if (longitude && latitude && range > 0) {
                const options = {
                    steps: 60,
                    units: 'kilometers',
                    properties: {
                        id: element.toJS().geofenceId,  // Unique ID for each feature
                        element,
                        name,
                        shape: 'Circle',
                        radius: range,
                    },
                };
                const point = [longitude, latitude];
                const circle = turfCircle(point, range / 1000, options);
                const center = turfPoint(point, { id: element.toJS().geofenceId, name, shape: 'Circle', radius: range });
                geoJsonCircle.features.push(circle);
                geoJsonCircleCenters.features.push(center);
                bounds.extend(point);
            } else if (polygonPoints) {
                const coords = [];
                polygonPoints.forEach((item) => {
                    const itemLongitude = item.get('longitude');
                    const itemLatitude = item.get('latitude');
                    if (itemLongitude && itemLatitude) {
                        coords.push([itemLongitude, itemLatitude]);
                    }
                });
                geoJsonPolygone.features.push({
                    type: 'Feature',
                    id: element.toJS().geofenceId,  // Unique ID for each feature
                    geometry: { type: 'Polygon', coordinates: [coords] },
                    properties: { element, name },
                });
            }
        });

        addGeofenceLayers(map, geoJsonCircle, geoJsonCircleCenters, geoJsonPolygone);
    };

    const isSectorSelected = (geofenceId) => {
        return props.sectorsList.some(sector => sector.geofenceId === geofenceId);
    };

    const updateFeatureStates = () => {
        if (!mapRef.current) return;

        const map = mapRef.current;
        if (!map.isStyleLoaded()) {
            map.once('styledata', updateFeatureStates);
            return;
        }

        const defaultColor = '#5b87ba';

        if (map.getLayer('geofences-fill')) {
            map.setPaintProperty('geofences-fill', 'fill-color', [
                'case',
                ['boolean', ['feature-state', 'selected'], false], defaultColor,
                defaultColor
            ]);
        }

        if (map.getLayer('geofences-outline')) {
            map.setPaintProperty('geofences-outline', 'line-color', [
                'case',
                ['boolean', ['feature-state', 'selected'], false], defaultColor,
                defaultColor
            ]);
        }

        if (map.getLayer('geofences-polygon-fill')) {
            map.setPaintProperty('geofences-polygon-fill', 'fill-color', [
                'case',
                ['boolean', ['feature-state', 'selected'], false], defaultColor,
                defaultColor
            ]);
        }

        if (map.getLayer('geofences-polygon-outline')) {
            map.setPaintProperty('geofences-polygon-outline', 'line-color', [
                'case',
                ['boolean', ['feature-state', 'selected'], false], defaultColor,
                defaultColor
            ]);
        }

        const geoJsonCircleSource = map.getSource('geofences');
        const geoJsonPolygoneSource = map.getSource('geofences-polygon');

        const geoJsonCircle = geoJsonCircleSource ? geoJsonCircleSource._data : null;
        const geoJsonPolygone = geoJsonPolygoneSource ? geoJsonPolygoneSource._data : null;

        if (geoJsonCircle) {
            geoJsonCircle.features.forEach(feature => {
                const geofenceId = feature.properties.element.get('geofenceId');
                const selected = isSectorSelected(geofenceId);
                map.setFeatureState({ source: 'geofences', id: feature.properties.id }, { selected });
            });
        }

        if (geoJsonPolygone) {
            geoJsonPolygone.features.forEach(feature => {
                const geofenceId = feature.properties.element.get('geofenceId');
                const selected = isSectorSelected(geofenceId);
                map.setFeatureState({ source: 'geofences-polygon', id: feature.id }, { selected });
            });
        }
    };

    const addGeofenceLayers = (map, geoJsonCircle, geoJsonCircleCenters, geoJsonPolygone) => {
        const defaultColor = '#5b87ba';

        if (!map.isStyleLoaded()) {
            map.once('style.load', () => addGeofenceLayers(map, geoJsonCircle, geoJsonCircleCenters, geoJsonPolygone));
            return;
        }

        if (!map.getSource('geofences')) {
            map.addSource('geofences', {
                type: 'geojson',
                data: geoJsonCircle,
            });

            map.addLayer({
                id: 'geofences-fill',
                type: 'fill',
                source: 'geofences',
                layout: {},
                paint: {
                    'fill-color': ['case',
                        ['boolean', ['feature-state', 'selected'], false], defaultColor,
                        defaultColor],
                    'fill-opacity': 0.4,
                },
            });

            map.addLayer({
                id: 'geofences-outline',
                type: 'line',
                source: 'geofences',
                layout: {},
                paint: {
                    'line-color': ['case',
                        ['boolean', ['feature-state', 'selected'], false], defaultColor,
                        defaultColor],
                    'line-width': 2,
                },
            });

            map.addSource('geofence-centers', {
                type: 'geojson',
                data: geoJsonCircleCenters,
            });

            map.addLayer({
                id: 'geofence-centers',
                type: 'circle',
                source: 'geofence-centers',
                paint: {
                    'circle-color': defaultColor,
                    'circle-radius': 5,
                },
            });

            map.addSource('geofences-polygon', {
                type: 'geojson',
                data: geoJsonPolygone,
            });

            map.addLayer({
                id: 'geofences-polygon-fill',
                type: 'fill',
                source: 'geofences-polygon',
                layout: {},
                paint: {
                    'fill-color': ['case',
                        ['boolean', ['feature-state', 'selected'], false], defaultColor,
                        defaultColor],
                    'fill-opacity': 0.4,
                },
            });

            map.addLayer({
                id: 'geofences-polygon-outline',
                type: 'line',
                source: 'geofences-polygon',
                layout: {},
                paint: {
                    'line-color': ['case',
                        ['boolean', ['feature-state', 'selected'], false], defaultColor,
                        defaultColor],
                    'line-width': 2,
                },
            });

            // Add event listeners for hover and click
            map.on('mouseenter', 'geofences-fill', (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const coordinates = e.features[0].geometry.coordinates[0];
                const description = e.features[0].properties.name;
                popup.setLngLat(e.lngLat)
                    .setHTML(`<strong>${description}</strong>`)
                    .addTo(map);
            });

            map.on('mouseleave', 'geofences-fill', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });

            map.on('click', 'geofences-fill', (e) => {
                const coordinates = e.features[0].geometry.coordinates[0];
                const description = e.features[0].properties.name;
                new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(`<strong>${description}</strong>`)
                    .addTo(map);
            });

            // Add event listeners for hover and click on polygons
            map.on('mouseenter', 'geofences-polygon-fill', (e) => {
                map.getCanvas().style.cursor = 'pointer';
                const coordinates = e.features[0].geometry.coordinates[0];
                const description = e.features[0].properties.name;
                popup.setLngLat(e.lngLat)
                    .setHTML(`<strong>${description}</strong>`)
                    .addTo(map);
            });

            map.on('mouseleave', 'geofences-polygon-fill', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });

            map.on('click', 'geofences-polygon-fill', (e) => {
                const coordinates = e.features[0].geometry.coordinates[0];
                const description = e.features[0].properties.name;
                new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(`<strong>${description}</strong>`)
                    .addTo(map);
            });
        } else {
            map.getSource('geofences').setData(geoJsonCircle);
            map.getSource('geofence-centers').setData(geoJsonCircleCenters);
            map.getSource('geofences-polygon').setData(geoJsonPolygone);
        }
        logPaintProperties(map);
        updateFeatureStates();
    }

    useEffect(() => {
        let animationFrameId;

        const animate = () => {
            if (mapContainerRef.current) {
                const targetRightValue = props.isOpen ? '1' : '0';
                const targetMarginValue = props.isOpen ? '0' : 'auto';

                mapContainerRef.current.style.right = targetRightValue;
                mapContainerRef.current.style.margin = targetMarginValue;

                if (mapRef.current) {
                    mapRef.current.resize();
                }
            }

            animationFrameId = requestAnimationFrame(animate);
        };

        animationFrameId = requestAnimationFrame(animate);

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [props.isOpen]);

    const containerStyle = {
        height: '100%',
        width: '100%',
        position: 'relative',
        borderRadius: '10px',
        borderColor: '#64748B',
        transition: 'right 0.5s ease, margin 0.5s ease',
        right: props.isOpen ? '1' : '0',
        margin: props.isOpen ? '0' : 'auto',
    };

    return <div ref={mapContainerRef} style={containerStyle} />;
}

const mapStateToProps = createStructuredSelector({
    isOpen: selectIsSideMenuOpen(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleSideMenu,
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SectorsMap)));
