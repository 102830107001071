import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectReservationModal, selectReservations, selectShowApModal, selectReservationSuccess } from "../../services/AutoPartage/selectors";
import {
    getReservations, hideReservationSuccessMessage
} from "../../services/AutoPartage/thunks";
import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import ApReservationsTable from '../../components/ApReservationsTable';
import ApReservationAction from '../../components/ApReservationAction';
import ApSuccessComponent from '../../components/ApSuccessComponent';
import ApModal from '../../components/ApModal';
import ApDriverCode from '../../components/ApDriverCode';
import ApNavigationBar from '../../components/ApNavigationBar';
import { ApSearchIcon } from '../../components/Icons';
import { fromJS } from "immutable";
import '/app/styles/scss/global.scss'
import './styles.scss'
import ApStepper from '../../components/ApStepper';
import { IconButton, TextField } from '@mui/material';

function ApHomeReservation(props) {
    const [modalState, setModalState] = useState(false);
    useEffect(() => {
        setModalState(sessionStorage.getItem('Code_de_conduite'));
    }, []);

    const [currentReservations, setCurrentReservations] = useState([]);
    const [oldReservations, setOldReservations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reservationSucceeded, setReservationSucceeded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [emptySearchInput, setEmptySearchInput] = useState(true);

    useEffect(() => {
        props.getReservations();
        const interval = setInterval(() => props.getReservations(), 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const filteredReservation = props.reservationsList.filter(reservation => (reservation.statusReservation === 'À venir' || reservation.statusReservation === 'En cours'))
            .map(reservation => {
                reservation.delete = (<ApReservationAction type='delete' />);
                reservation.edit = (<ApReservationAction type='edit' />);
                return reservation;
            });
        setCurrentReservations(filteredReservation);
        const filteredOldReservation = props.reservationsList.filter(reservation => (reservation.statusReservation === 'Terminée' || reservation.statusReservation === 'Annulée'));
        setOldReservations(filteredOldReservation);
        setLoading(false);
    }, [props.reservationsList]);

    useEffect(() => {
        if (props.reservationSuccess) {
            setReservationSucceeded(props.reservationSuccess);
        }
    }, [props.reservationSuccess]);

    useEffect(() => {
        if (reservationSucceeded) {
            setTimeout(() => {
                setReservationSucceeded(false);
                props.hideReservationSuccessMessage();
            }, 10000);
        }
    }, [reservationSucceeded]);

    useEffect(() => {
        if (searchValue === '') {
            setEmptySearchInput(true);
            setCurrentReservations(props.reservationsList.filter(reservation => (reservation.statusReservation === 'À venir' || reservation.statusReservation === 'En cours'))
                .map(reservation => {
                    reservation.delete = (<ApReservationAction type='delete' />);
                    reservation.edit = (<ApReservationAction type='edit' />);
                    return reservation;
                }));
            setOldReservations(props.reservationsList.filter(reservation => (reservation.statusReservation === 'Terminée' || reservation.statusReservation === 'Annulée')));
        } else {
            searchForReservation();
        }
    }, [searchValue]);

    const onCloseMessage = () => {
        setReservationSucceeded(false);
        props.hideReservationSuccessMessage();
    };

    const changeSearchValue = (event) => {
        setSearchValue(event.target.value);
        setEmptySearchInput(event.target.value === '');
    };

    const searchForReservation = () => {
        const searchedReservation = props.reservationsList.filter(reservation => (reservation.id.toString().includes(searchValue)) &&
            (reservation.statusReservation === 'À venir' || reservation.statusReservation === 'En cours'))
            .map(reservation => {
                reservation.delete = (<ApReservationAction type='delete' />);
                reservation.edit = (<ApReservationAction type='edit' />);
                return reservation;
            });
        setCurrentReservations(searchedReservation);
        const filteredOldReservation = props.reservationsList.filter(reservation => (reservation.id.toString().includes(searchValue)) &&
            (reservation.statusReservation === 'Terminée' || reservation.statusReservation === 'Annulée'));
        setOldReservations(filteredOldReservation);
    };

    return (
        <React.Fragment>
            <Container className="ap-container">
                <ApNavigationBar />
                {reservationSucceeded && (<ApSuccessComponent onClose={onCloseMessage} />)}
                <Box className={"ap_search_bar"}>
                    {emptySearchInput && <ApSearchIcon />}
                    <Input disableUnderline
                           placeholder={props.intl.formatMessage({
                               id: 'containers.AP.Home.SearchBar',
                           })}
                           sx={{ width: '100%' }}
                           onChange={changeSearchValue}
                    />
                </Box>
                {modalState && (
                    <ApModal>
                        <ApDriverCode />
                    </ApModal>
                )}
                {props.reservationModal && (
                    <ApModal>
                        <ApStepper />
                    </ApModal>
                )}
                {loading ? '' :
                    <ApReservationsTable
                        rows={currentReservations}
                    />
                }
                <ApReservationsTable
                    rows={oldReservations}
                />
            </Container>
        </React.Fragment>
    );
}

const mapStateToProps = createStructuredSelector({
    reservationsList: selectReservations(),
    reservationModal: selectReservationModal(),
    showDriverCodeModal: selectShowApModal(),
    reservationSuccess: selectReservationSuccess(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getReservations,
            hideReservationSuccessMessage,
        },
        dispatch,
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(ApHomeReservation)),
);
