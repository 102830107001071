// @flow

import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import { CREATE_USER_FAIL, DELETE_USER_ERROR, CREATE_USER_VALIDATION_FAILED } from './actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    userErrors: {},
});

function userServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case CREATE_USER_FAIL: {
            return state.set('userErrors', fromJS(action.error));
        }
        case DELETE_USER_ERROR: {
            if (!action.property) {
                return state.set('userErrors', fromJS({}));
            }
            return state.set('userErrors', state.get('userErrors').delete(action.property));
        }
        case LOCATION_CHANGE: {
            return state.set('userErrors', fromJS({}));
        }
        case CREATE_USER_VALIDATION_FAILED: {
            return state.set('userErrors', fromJS(action.errors));
        }
        default:
            return state;
    }
}

export default userServiceReducer;
