// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

export const SubOrganization = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: ${(props) => (!props.isOpen ? '30px 50px 0 50px;' : '30px 50px 0 50px;')};
`;

SubOrganization.DataHeader = styled.div`
    flex: 3;
`;

SubOrganization.DataSelect = styled.div`
    flex: 1;
    display: flex;
`;

SubOrganization.ManagementContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 50px;
`;

export const DeletedOrganization = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    height: 49px;
    border: 1px solid ${colors.status.red};
    justify-content: center;
    align-items: center;
`;
