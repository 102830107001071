/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useCallback, useState } from 'react';
import useStyles from '../../styles/AddGroup.styles';
import GroupTypeToggle, { groupTypesEnum } from '../GroupTypeToggle';
import VehicleSelectField from './VehicleSelectField';
import SubmitButton from '../inputs/SubmitButton';
import UserSelectField from './UserSelectField';
import { FormattedMessage } from 'react-intl';

const GroupEdition = ({ groupId, selectedGroupType, existingIds, onSubmit, closeModal, title, textButton, width }) => {
    const [ids, setIds] = useState([]);
    const [groupType, setGroupType] = useState(selectedGroupType || groupTypesEnum.vehicle);

    const { vehicleExistingIds, userExistingIds } = existingIds;

    const classes = useStyles();

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            onSubmit({ groupId, ids, groupType });
            closeModal();
        },
        [ids]
    );

    const handleIdsChange = useCallback(
        (values) => {
            setIds(values);
        },
        [ids]
    );

    const handleDelete = useCallback((idToRemove) => {
        setIds(ids.filter((id) => id !== idToRemove));
    });

    const handleGroupTypeChange = useCallback((type) => {
        if (type) {
            setGroupType(type);
        }
    }, []);

    return (
        <div className={classes.paper} style={{ width }}>
            <h4 className={classes.title}>
                <FormattedMessage id={title} />
            </h4>
            <GroupTypeToggle disabled={ids.length > 0} value={groupType} onChange={handleGroupTypeChange} />
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                {groupType === groupTypesEnum.vehicle && (
                    <VehicleSelectField
                        existingIds={vehicleExistingIds}
                        onChange={handleIdsChange}
                        onDelete={handleDelete}
                        values={ids}
                    />
                )}
                {groupType === groupTypesEnum.user && (
                    <UserSelectField
                        existingIds={userExistingIds}
                        onChange={handleIdsChange}
                        onDelete={handleDelete}
                        values={ids}
                    />
                )}
                <div className={classes.button}>
                    <SubmitButton type="submit" disabled={!ids}>
                        <FormattedMessage id={textButton} />
                    </SubmitButton>
                </div>
            </form>
        </div>
    );
};

export default GroupEdition;
