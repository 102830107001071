// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Title, Text } from './styles.js';
import { Container, Content } from 'styles/common';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { replace } from 'react-router-redux';
import { selectLocale } from 'services/Language/selectors';

const Forbidden = (props) => (
    <Container>
        <FormattedMessage id={'views.403.Title'}>
            {(title) => (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            )}
        </FormattedMessage>

        <Content>
            <Title>
                <FormattedMessage id={'views.403.Title'} />
            </Title>
            <Text>
                <FormattedMessage id={'views.403.Text'} />
            </Text>

            <Link to={`/${props.locale}/map`}>
                <FormattedMessage id={'views.403.Return'} />
            </Link>
        </Content>
    </Container>
);

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
});

const mapDispatch = { replace };

export default connect(
    mapStateToProps,
    mapDispatch
)(injectIntl(Forbidden));
