/* eslint-disable flowtype/require-parameter-type,react/jsx-no-bind */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        border: 'none',
    },
    iconButton: {
        padding: 10,
    },
}));

const SearchField = ({ onChange, label, placeholder, }) => {
    const classes = useStyles();

    return (
        <Paper component="form" className={classes.root} elevation={0}>
            <InputBase className={classes.input} placeholder={placeholder} onChange={onChange} />
            <IconButton className={classes.iconButton}>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};

export default SearchField;
