// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import OutlineButton from 'components/OutlineButton';

import { selectEquipmentTypes } from 'services/Equipment/selectors';

import { Equipment } from './styles';
import { Dashboard } from 'styles/dashboard';
import colors from 'styles/colors';

import type { EquipmentType } from 'types';

type Props = {
    data: EquipmentType,
    // showEquipmentOptions: ?number,
};

type State = {};

class DashboardEquipmentItem extends React.Component<Props, State> {
    getEquipmentType = () => {
        const type = this.props.equipmentTypes.find(
            (equipmentType) => equipmentType.get('id') === this.props.data.get('equipmentTypeId')
        );
        if (type) {
            return type.get('name');
        }
    };

    handleOnClick = (equipment: EquipmentType) => (event: Event) => {
        this.props.handleShowEquipmentOptions(equipment);
    };

    handleOnClickEdit = (equipment: EquipmentType) => (event: Event) => {
        this.props.onClickEditEquipment(equipment.get('id'));
        this.props.handleShowEquipmentOptions(equipment);
    };

    handleOnClickDelete = (equipment: EquipmentType) => (event: Event) => {
        this.props.onClickDeleteEquipment(equipment.get('id'));
        this.props.handleShowEquipmentOptions(equipment);
    };

    render() {
        const { data, list } = this.props;
        if (list) {
            return (
                <React.Fragment>
                    <Equipment.Item>
                        {/* {this.getEquipmentType()} */}
                        {data.get('name')}
                    </Equipment.Item>
                    <Equipment.Item>
                        {data.get('operational') ? <React.Fragment>&#x2713;</React.Fragment> : 'x'}
                        <Equipment.Options>
                            <Dashboard.UserOptions>
                                <OutlineButton
                                    onClick={this.handleOnClick(data)}
                                    width={'40px'}
                                    outlineColor={colors.green73}
                                    marginTop={'0px'}
                                    hoverTextColor={colors.white}
                                    selected={this.props.showEquipmentOptions === data.get('id')}
                                >
                                    {'...'}
                                </OutlineButton>
                                {this.props.showEquipmentOptions === data.get('id') && (
                                    <Dashboard.UserOptionsDropdown>
                                        <Dashboard.DropdownItem
                                            onClick={this.handleOnClickEdit(data)}
                                        >
                                            <FormattedMessage
                                                id={'components.DashboardData.Equipment.Edit'}
                                            />
                                        </Dashboard.DropdownItem>
                                        <Dashboard.DropdownItem
                                            onClick={this.handleOnClickDelete(data)}
                                            backgroundColorHovered={colors.status.red}
                                        >
                                            <FormattedMessage
                                                id={'components.DashboardData.Equipment.Delete'}
                                            />
                                        </Dashboard.DropdownItem>
                                    </Dashboard.UserOptionsDropdown>
                                )}
                            </Dashboard.UserOptions>
                        </Equipment.Options>
                    </Equipment.Item>
                </React.Fragment>
            );
        } else {
            return (
                <Equipment.Item>
                    {data.get('name')}
                    <Equipment.Options>
                        <Dashboard.UserOptions>
                            <OutlineButton
                                onClick={this.handleOnClick(data)}
                                width={'40px'}
                                outlineColor={colors.green73}
                                hoverTextColor={colors.white}
                                marginTop={'0px'}
                                selected={this.props.showEquipmentOptions === data.get('id')}
                            >
                                {'...'}
                            </OutlineButton>
                            {this.props.showEquipmentOptions === data.get('id') && (
                                <Dashboard.UserOptionsDropdown>
                                    <Dashboard.DropdownItem onClick={this.handleOnClickEdit(data)}>
                                        <FormattedMessage
                                            id={'components.DashboardData.Equipment.Edit'}
                                        />
                                    </Dashboard.DropdownItem>
                                    <Dashboard.DropdownItem
                                        onClick={this.handleOnClickDelete(data)}
                                        backgroundColorHovered={colors.status.red}
                                    >
                                        <FormattedMessage
                                            id={'components.DashboardData.Equipment.Delete'}
                                        />
                                    </Dashboard.DropdownItem>
                                </Dashboard.UserOptionsDropdown>
                            )}
                        </Dashboard.UserOptions>
                    </Equipment.Options>
                </Equipment.Item>
            );
        }
    }
}

const mapStateToProps = createStructuredSelector({
    equipmentTypes: selectEquipmentTypes(),
});

export default connect(
    mapStateToProps,
    null
)(DashboardEquipmentItem);
