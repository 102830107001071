/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import axios from 'axios';
import _ from 'lodash';
import { useCallback, useReducer } from 'react';
import { ADMIN_API_URL } from 'env';

export const initialState = {
    isFetchingVehicles: false,
    // eslint-disable-next-line no-undefined
    groupId: undefined,
    // eslint-disable-next-line no-undefined
    vehicles: undefined,
    error: null,
};

const vehiclesReducer = (state, action) => {
    switch (action.type) {
        case 'startFetching':
            return {
                ...state,
                isFetchingVehicles: true,
                groupId: (action.payload && action.payload.groupId) || '',
                vehicles: [],
            };
        case 'pageLoaded':
            // eslint-disable-next-line no-case-declarations
            const vehicles = _.unionBy(action.payload, state.vehicles, 'id');
            return { ...state, vehicles };
        case 'vehiclesLoaded':
            return { ...state, isFetchingVehicles: false };
        default:
            throw new Error('Invalid action: ', action);
    }
};

export const useVehicles = () => {
    const [state, dispatch] = useReducer(vehiclesReducer, initialState);
    const { vehicles, isFetchingVehicles } = state;

    const getVehicles = useCallback(async (groupId) => {
        try {
            const params = {
                pageNumber: 1,
                groupId,
            };
            let result = await axios.get(`${ADMIN_API_URL}vehicles`, {
                params,
            });
            if (result.status !== 200) {
                console.warn('Error getting vehicles', result);
            }

            dispatch({ type: 'pageLoaded', payload: result.data.vehicles });

            let next = Array.isArray(result.data.links) ? result.data.links.find((link) => link.rel === 'next') : null;

            while (next) {
                const group = groupId ? `&groupId=${groupId}` : '';
                result = await axios.get(`${ADMIN_API_URL}${next.href}${group}`);
                if (result.status !== 200) {
                    console.warn('Error getting vehicles', result);
                }

                dispatch({ type: 'pageLoaded', payload: result.data.vehicles });

                next = Array.isArray(result.data.links) ? result.data.links.find((link) => link.rel === 'next') : null;
            }

            dispatch({ type: 'vehiclesLoaded' });
        } catch (error) {
            console.error(error);
        }
    }, []);

    const fetchVehiclesByGroupId = useCallback(
        (groupId) => {
            if (!isFetchingVehicles) {
                dispatch({ type: 'startFetching', payload: { groupId } });
                getVehicles(groupId);
            }
        },
        [getVehicles, isFetchingVehicles]
    );

    const fetchVehicles = useCallback(
        () => {
            if (!isFetchingVehicles) {
                dispatch({ type: 'startFetching' });
                getVehicles();
            }
        },
        [getVehicles, isFetchingVehicles]
    );

    return { fetchVehicles, fetchVehiclesByGroupId, vehicles, isFetchingVehicles };
};
