import React from 'react'
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectReservationHours, selectReservationDate } from '../../services/AutoPartage/selectors';
import { Container } from '@mui/material';

export function ApConfirmationInfo(props) {
  //Format confirmation information for display
  const reservationDate = props.reservationDate.replace(/-/g, "/");
  //Formatting hours string
  // Split the hours into two parts, start and end time
  let [startTime, endTime] = props.reservationHours.split(" - ");
  const formattedTime = `${props.intl.formatMessage({
          id:'components.AP.confirmation.from'
      })}
      ${startTime} ${props.intl.formatMessage({
        id:'components.AP.confirmation.to'
      })}
      ${endTime}`;
  return (
    <Container className={"ap_info_container"}>
      <div>
        <span className="ap_confirmation_text">
          <FormattedMessage id={'components.AP.confirmation.date'} />
        </span> <span className="ap_confirmation_information">{reservationDate}</span>
      </div>
      <div className="ap_confirmation_information">
        {formattedTime}
      </div>
      <div>
        <span className="ap_confirmation_text">
          <FormattedMessage id={'components.AP.confirmation.sector'} />
        </span> <span className="ap_confirmation_information"> {props.clickedSector.geofenceName}</span>
      </div>
    </Container>
  )
}
const mapStateToProps: Object = createStructuredSelector({
  reservationDate: selectReservationDate(),
  reservationHours: selectReservationHours(),
});
export default withRouter(
  connect(
    mapStateToProps, {})(injectIntl(ApConfirmationInfo))
);
