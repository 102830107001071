// @flow

import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { combineReducers } from 'redux-immutable';

import languageProviderReducer from 'services/Language/reducer';
import unitsProviderReducer from 'services/Units/reducer';
import authenticationServiceReducer from 'services/Authentication/reducer';
import organizationServiceReducer from 'services/Organization/reducer';
import vehicleServiceReducer from 'services/Vehicle/reducer';
import sideMenuServiceReducer from 'services/SideMenu/reducer';
import modalServiceReducer from 'services/Modal/reducer';
import apReservationsServiceReducer from 'services/AutoPartage/reducer';
import branchServiceReducer from 'services/Branch/reducer';
import divisionServiceReducer from 'services/Division/reducer';
import userServiceReducer from 'services/User/reducer';
import equipmentServiceReducer from 'services/Equipment/reducer';
import reportServiceReducer from 'services/Report/reducer';
import routificerviceReducer from 'services/Routific/reducer';
import driverServiceReducer from 'services/Driver/reducer';
import configServiceReducer from 'services/Config/reducer';
import categorieServiceReducer from 'services/Categorie/reducer';
import GeofenceServiceReducer from 'services/Geofence/reducer';
import PredefinedRoutesServiceReducer from 'services/PredefinedRoutes/reducer';
import AdminServiceReducer from 'services/Admin/reducer';
import socketServiceReducer from 'services/socket/reducer';
import maintenanceServiceReducer from 'services/Maintenance/reducer';

// Initial routing state
const routeInitialState = fromJS({
    location: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return state.merge({
                location: action.payload,
            });
        default:
            return state;
    }
}

const rootReducer = combineReducers({
    language: languageProviderReducer,
    route: routeReducer,
    auth: authenticationServiceReducer,
    // Insert reducers here
    units: unitsProviderReducer,
    organization: organizationServiceReducer,
    vehicle: vehicleServiceReducer,
    sidemenu: sideMenuServiceReducer,
    modal: modalServiceReducer,
    apReservations: apReservationsServiceReducer,
    branch: branchServiceReducer,
    division: divisionServiceReducer,
    user: userServiceReducer,
    equipment: equipmentServiceReducer,
    report: reportServiceReducer,
    routific: routificerviceReducer,
    driver: driverServiceReducer,
    config: configServiceReducer,
    categorie: categorieServiceReducer,
    geofence: GeofenceServiceReducer,
    predefinedroute: PredefinedRoutesServiceReducer,
    users: AdminServiceReducer,
    socket: socketServiceReducer,
    maintenance: maintenanceServiceReducer,
});

export default rootReducer;
