// @flow

// IMPORTS
import React from 'react';
import colors from 'styles/colors';

// icons
import defaultVehicleIcon from '../../assets/statuses/vehicle-green.svg';
import defaultAux1Icon from '../../assets/statuses/aux-1.svg';
import defaultAux2Icon from '../../assets/statuses/aux-2.svg';
import defaultAux3Icon from '../../assets/statuses/aux-3.svg';
import defaultAux4Icon from '../../assets/statuses/aux-4.svg';

import backHoeIcon from '../../assets/custom/vehicleIcons/back-hoe.svg';
import conveyorIcon from '../../assets/custom/vehicleIcons/conveyor.svg';
import forkliftIcon from '../../assets/custom/vehicleIcons/forklift.svg';
import generatorIcon from '../../assets/custom/vehicleIcons/generator.svg';
import groveIcon from '../../assets/custom/vehicleIcons/grove.svg';
import loaderIcon from '../../assets/custom/vehicleIcons/loader.svg';
import minivanIcon from '../../assets/custom/vehicleIcons/minivan.svg';
import paverIcon from '../../assets/custom/vehicleIcons/paver.svg';
import recycleIcon from '../../assets/custom/vehicleIcons/recycle.svg';
import rollerIcon from '../../assets/custom/vehicleIcons/roller.svg';
import semiTrailerIcon from '../../assets/custom/vehicleIcons/semi-trailer.svg';
import snowPlowIcon from '../../assets/custom/vehicleIcons/snow-plow.svg';
import truckTractorIcon from '../../assets/custom/vehicleIcons/truck-tractor.svg';
import truckTrashIcon from '../../assets/custom/vehicleIcons/truck-trash.svg';
import truckIcon from '../../assets/custom/vehicleIcons/truck.svg';
import vehicleIcon from '../../assets/custom/vehicleIcons/vehicle.svg';
import vttIcon from '../../assets/custom/vehicleIcons/VTT.svg';
import defaultVeh from '../../assets/statuses/vehicleIcon-default.svg';
import lightingTower from '../../assets/custom/vehicleIcons/lighting-tower.svg'
import lightingPlatform from '../../assets/custom/vehicleIcons/plate-forme-elevatrice.svg';
import excavator from '../../assets/custom/vehicleIcons/excavatrice.svg';
import boat from '../../assets/custom/vehicleIcons/chaloupe.svg';
import compressor from '../../assets/custom/vehicleIcons/compresseur.svg';
import welder from '../../assets/custom/vehicleIcons/soudeuse.svg';
import drill from '../../assets/custom/vehicleIcons/foreuse.svg';
import ripperTeeth from '../../assets/custom/vehicleIcons/dents-defonceuse.svg';
import forklifts from '../../assets/custom/vehicleIcons/chariot-elevateur.svg';
import toothedBucket from '../../assets/custom/vehicleIcons/godet-a-dents.svg';
import hydraulicJack from '../../assets/custom/vehicleIcons/jack-hydraulique.svg';
import accelerationIcon from '../../assets/custom/auxIcons/acceleration.svg';
import alarmeIcon from '../../assets/custom/auxIcons/alarme.svg';
import alarmeEndIcon from '../../assets/custom/auxIcons/alarmeEnd.svg';
import backDoorIcon from '../../assets/custom/auxIcons/backDoor.svg';
import benneIcon from '../../assets/custom/auxIcons/benne.svg';
import buttonIcon from '../../assets/custom/auxIcons/button.svg';
import decelerationIcon from '../../assets/custom/auxIcons/deceleration.svg';
import driverDoorIcon from '../../assets/custom/auxIcons/driverDoor.svg';
import exceedIcon from '../../assets/custom/auxIcons/exceed.svg';
import exceedEndIcon from '../../assets/custom/auxIcons/exceedEnd.svg';
import fourDoorsIcon from '../../assets/custom/auxIcons/fourDoors.svg';
import frontPlowIcon from '../../assets/custom/auxIcons/frontPlow.svg';
import garbageBinIcon from '../../assets/custom/auxIcons/garbageBin.svg';
import identificationIcon from '../../assets/custom/auxIcons/identification.svg';
import keyStartIcon from '../../assets/custom/auxIcons/keyStart.svg';
import nonAutoriseIcon from '../../assets/custom/auxIcons/nonAutorise.svg';
import passengerDoor from '../../assets/custom/auxIcons/passengerDoor.svg';
import personIcon from '../../assets/custom/auxIcons/person.svg';
import ptoIcon from '../../assets/custom/auxIcons/PTO.svg';
import reverseIcon from '../../assets/custom/auxIcons/reverse.svg';
import seatbeltsIcon from 'assets/custom/auxIcons/seatbelts.svg';
import sidePlowIcon from 'assets/custom/auxIcons/sidePlow.svg';
import beaconIcon from '../../assets/custom/auxIcons/beacon.svg';
import smokeDetectorIcon from '../../assets/custom/auxIcons/smokeDetector.svg';
import startButtonIcon from '../../assets/custom/auxIcons/startButton.svg';
import telephoneIcon from '../../assets/custom/auxIcons/telephone.svg';
import temperatureIcon from '../../assets/custom/auxIcons/temperature.svg';
import personalButtonIcon from '../../assets/custom/auxIcons/personalButton.svg';
import professionalButtonIcon from '../../assets/custom/auxIcons/professionalButton.svg';

/**
 * Remove marker and permanentPopup
 * @param {*} map
 */
export const getIcon = (id) => {

    let icon = '';
    switch (id) {
       case 0:
            icon = defaultVeh;
            break;
       case 1:
            icon = vehicleIcon;
            break;
        case 2:
            icon = backHoeIcon;
            break;
        case 3:
            icon = conveyorIcon;
            break;
        case 4:
            icon = forkliftIcon;
            break;
        case 5:
            icon = generatorIcon;
            break;
        case 6:
            icon = groveIcon;
            break;
        case 7:
            icon = loaderIcon;
            break;
        case 8:
            icon = minivanIcon;
            break;
        case 9:
            icon = paverIcon;
            break;
        case 10:
            icon = recycleIcon;
            break;
        case 11:
            icon = rollerIcon;
            break;
        case 12:
            icon = semiTrailerIcon;
            break;
        case 13:
            icon = snowPlowIcon;
            break;
        case 14:
            icon = truckTractorIcon;
            break;
        case 15:
            icon = truckTrashIcon;
            break;
        case 16:
            icon = truckIcon;
            break;
        case 17:
            icon = vttIcon;
            break;
        case 18:
            icon = accelerationIcon;
            break;
        case 19:
            icon = alarmeIcon;
            break;
        case 20:
            icon = alarmeEndIcon;
            break;
        case 21:
            icon = backDoorIcon;
            break;
        case 22:
            icon = benneIcon;
            break;
        case 23:
            icon = buttonIcon;
            break;
        case 24:
            icon = decelerationIcon;
            break;
        case 25:
            icon = driverDoorIcon;
            break;
        case 26:
            icon = exceedIcon;
            break;
        case 27:
            icon = exceedEndIcon;
            break;
        case 28:
            icon = fourDoorsIcon;
            break;
        case 29:
            icon = frontPlowIcon;
            break;
        case 30:
            icon = garbageBinIcon;
            break;
        case 31:
            icon = identificationIcon;
            break;
        case 32:
            icon = keyStartIcon;
            break;
        case 33:
            icon = nonAutoriseIcon;
            break;
        case 34:
            icon = passengerDoor;
            break;
        case 35:
            icon = personIcon;
            break;
        case 36:
            icon = ptoIcon;
            break;
        case 37:
            icon = reverseIcon;
            break;
        case 38:
            icon = seatbeltsIcon;
            break;
        case 39:
            icon = sidePlowIcon;
            break;
        case 40:
            icon = beaconIcon;
            break;
        case 41:
            icon = smokeDetectorIcon;
            break;
        case 42:
            icon = startButtonIcon;
            break;
        case 43:
            icon = telephoneIcon;
            break;
        case 44:
            icon = temperatureIcon;
            break;
        case 45:
            icon = personalButtonIcon;
            break;
        case 46:
            icon = professionalButtonIcon;
            break;
        case 47:
            icon = lightingTower;
            break;
        case 48:
            icon = lightingPlatform;
            break;
        case 49:
            icon = excavator;
            break;
        case 50:
            icon = boat;
            break;
        case 51:
            icon = compressor;
            break;
        case 52:
            icon = welder;
            break;
        case 53:
            icon = drill;
            break;
        case 54:
            icon = ripperTeeth;
            break;
        case 55:
            icon = forklifts;
            break;
        case 56:
            icon = toothedBucket;
            break;
        case 57:
            icon = hydraulicJack;
            break;
        default:
            break;
    }

    return icon;
};

export const vehicleIconOptions = () => {
    return [
        {
            name: '',
            label: 'components.Modals.Form.Config.CustomIcon.default',
            image: defaultVeh,
            id: 0,
        },
        {
            name: 'keyOn',
            label: 'components.Modals.Form.Config.CustomIcon.Vehicle',
            image: vehicleIcon,
            id: 1,
        },
        {
            name: 'Back Hoe',
            label: 'components.Modals.Form.Config.CustomIcon.BackHoe',
            image: backHoeIcon,
            id: 2,
        },
        {
            name: 'Conveyor',
            label: 'components.Modals.Form.Config.CustomIcon.Conveyor',
            image: conveyorIcon,
            id: 3,
        },
        {
            name: 'Forklift',
            label: 'components.Modals.Form.Config.CustomIcon.Forklift',
            image: forkliftIcon,
            id: 4,
        },
        {
            name: 'Generator',
            label: 'components.Modals.Form.Config.CustomIcon.Generator',
            image: generatorIcon,
            id: 5,
        },
        {
            name: 'Grove',
            label: 'components.Modals.Form.Config.CustomIcon.Grove',
            image: groveIcon,
            id: 6,
        },

        {
            name: 'Loader',
            label: 'components.Modals.Form.Config.CustomIcon.Loader',
            image: loaderIcon,
            id: 7,
        },
        {
            name: 'Minivan',
            label: 'components.Modals.Form.Config.CustomIcon.Minivan',
            image: minivanIcon,
            id: 8,
        },
        {
            name: 'Paver',
            label: 'components.Modals.Form.Config.CustomIcon.Paver',
            image: paverIcon,
            id: 9,
        },
        {
            name: 'Recycle',
            label: 'components.Modals.Form.Config.CustomIcon.Recycle',
            image: recycleIcon,
            id: 10,
        },
        {
            name: 'Roller',
            label: 'components.Modals.Form.Config.CustomIcon.Roller',
            image: rollerIcon,
            id: 11,
        },
        {
            name: 'Semi Trailer',
            label: 'components.Modals.Form.Config.CustomIcon.SemiTrailer',
            image: semiTrailerIcon,
            id: 12,
        },
        {
            name: 'Snow Plow',
            label: 'components.Modals.Form.Config.CustomIcon.SnowPlow',
            image: snowPlowIcon,
            id: 13,
        },
        {
            name: 'Truck Tractor',
            label: 'components.Modals.Form.Config.CustomIcon.Tractor',
            image: truckTractorIcon,
            id: 14,
        },
        {
            name: 'Truck Trash',
            label: 'components.Modals.Form.Config.CustomIcon.GarbageTruck',
            image: truckTrashIcon,
            id: 15,
        },
        {
            name: 'Truck',
            label: 'components.Modals.Form.Config.CustomIcon.Truck',
            image: truckIcon,
            id: 16,
        },
        {
            name: 'VTT',
            label: 'components.Modals.Form.Config.CustomIcon.VTT',
            image: vttIcon,
            id: 17,
        },
        {
            name: 'Lighting Tower',
            label: 'components.Modals.Form.Config.CustomIcon.LightingTower',
            image: lightingTower,
            id: 47,
        },
        {
            name: 'Lifting Platform',
            label: 'components.Modals.Form.Config.CustomIcon.LightingPlatform',
            image: lightingPlatform,
            id: 48,
        },
        {
            name: 'Excavator',
            label: 'components.Modals.Form.Config.CustomIcon.Excavator',
            image: excavator,
            id: 49,
        },
        {
            name: 'Boat',
            label: 'components.Modals.Form.Config.CustomIcon.Boat',
            image: boat,
            id: 50,
        },
        {
            name: 'Compressor',
            label: 'components.Modals.Form.Config.CustomIcon.Compressor',
            image: compressor,
            id: 51,
        },
        {
            name: 'Welder',
            label: 'components.Modals.Form.Config.CustomIcon.Welder',
            image: welder,
            id: 52,
        },
        {
            name: 'Drill Rig',
            label: 'components.Modals.Form.Config.CustomIcon.Drill',
            image: drill,
            id: 53,
        },
        {
            name: 'Ripper Teeth',
            label: 'components.Modals.Form.Config.CustomIcon.RipperTeeth',
            image: ripperTeeth,
            id: 54,
        },
        {
            name: 'Forklift',
            label: 'components.Modals.Form.Config.CustomIcon.Forklifts',
            image: forklifts,
            id: 55,
        },
        {
            name: 'Toothed Bucket',
            label: 'components.Modals.Form.Config.CustomIcon.ToothedBucke',
            image: toothedBucket,
            id: 56,
        },
        {
            name: 'Hydraulic Jack',
            label: 'components.Modals.Form.Config.CustomIcon.HydraulicJack',
            image: hydraulicJack,
            id: 57,
        },
    ];
};

export const auxIconOptions = (type) => {
    let defaultAux = '';
    if (type == 'aux1') {
        defaultAux = defaultAux1Icon;
    } else if (type == 'aux2') {
        defaultAux = defaultAux2Icon;
    } else if (type == 'aux3') {
        defaultAux = defaultAux3Icon;
    } else if (type == 'aux4') {
        defaultAux = defaultAux4Icon;
    }
    return [
        {
            name: '',
            image: defaultAux,
            label: 'components.Modals.Form.Config.CustomIcon.default',
            id: 0,
        },
        {
            name: 'Acceleration',
            image: accelerationIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Acceleration',
            id: 18,
        },
        {
            name: 'Alarme',
            image: alarmeIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Alarm',
            id: 19,
        },
        {
            name: 'Alarme End',
            image: alarmeEndIcon,
            label: 'components.Modals.Form.Config.CustomIcon.AlarmEnd',
            id: 20,
        },
        {
            name: 'Back Door ',
            image: backDoorIcon,
            label: 'components.Modals.Form.Config.CustomIcon.BackDoor',
            id: 21,
        },
        {
            name: 'Benne ',
            image: benneIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Benne',
            id: 22,
        },
        {
            name: 'Button',
            image: buttonIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Button',
            id: 23,
        },
        {
            name: 'Deceleration',
            image: decelerationIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Deceleration',
            id: 24,
        },

        {
            name: 'Driver Door',
            image: driverDoorIcon,
            label: 'components.Modals.Form.Config.CustomIcon.DriverDoor',

            id: 25,
        },
        {
            name: 'Exceed',
            image: exceedIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Exceed',
            id: 26,
        },
        {
            name: 'Exceed End',
            image: exceedEndIcon,
            label: 'components.Modals.Form.Config.CustomIcon.ExceedEnd',
            id: 27,
        },
        {
            name: 'Four Doors',
            image: fourDoorsIcon,
            label: 'components.Modals.Form.Config.CustomIcon.FourDoors',
            id: 28,
        },
        {
            name: 'Front Plow',
            image: frontPlowIcon,
            label: 'components.Modals.Form.Config.CustomIcon.FrontPlow',
            id: 29,
        },
        {
            name: 'Garbage Bin',
            image: garbageBinIcon,
            label: 'components.Modals.Form.Config.CustomIcon.GabageBin',

            id: 30,
        },

        {
            name: 'Identification',
            image: identificationIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Identification',
            id: 31,
        },
        {
            name: 'Key Start',
            image: keyStartIcon,
            label: 'components.Modals.Form.Config.CustomIcon.KeyStart',
            id: 32,
        },
        {
            name: 'Non Autorise',
            image: nonAutoriseIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Unauthorized',
            id: 33,
        },
        {
            name: 'Passenger Door',
            image: passengerDoor,
            label: 'components.Modals.Form.Config.CustomIcon.PassengerDoor',
            id: 34,
        },
        {
            name: 'Person',
            image: personIcon,
            label: 'components.Modals.Form.Config.CustomIcon.PassengerDoor',
            id: 35,
        },
        {
            name: 'PTO',
            image: ptoIcon,
            label: 'components.Modals.Form.Config.CustomIcon.PTO',
            id: 36,
        },
        {
            name: 'reverseIcon  ',
            image: reverseIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Reverse',
            id: 37,
        },
        {
            name: 'Seatbelts',
            image: seatbeltsIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Seatbelts',
            id: 38,
        },
        {
            name: 'Side Plow',
            image: sidePlowIcon,
            label: 'components.Modals.Form.Config.CustomIcon.SidePlow',
            id: 39,
        },

        {
            name: 'Beacon',
            image: beaconIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Beacon',
            id: 40,
        },
        {
            name: 'Smoke Detector',
            image: smokeDetectorIcon,
            label: 'components.Modals.Form.Config.CustomIcon.SmokeDetector',
            id: 41,
        },
        {
            name: 'Start Button',
            image: startButtonIcon,
            label: 'components.Modals.Form.Config.CustomIcon.StartButton',
            id: 42,
        },
        {
            name: 'Telephone',
            image: telephoneIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Telephone',
            id: 43,
        },
        {
            name: 'Temperature',
            image: temperatureIcon,
            label: 'components.Modals.Form.Config.CustomIcon.Temperature',
            id: 44,
        },

        {
            name: 'Personal Button',
            image: personalButtonIcon,
            label: 'components.Modals.Form.Config.CustomIcon.personalButton',
            id: 45,
        },

        {
            name: 'Professional Button',
            image: professionalButtonIcon,
            label: 'components.Modals.Form.Config.CustomIcon.professionalButton',
            id: 46,
        },
    ];
};

// export const getTranslateLabel = (id) => {
//     let label = '';

//     switch (id) {
//         case 1:
//             label = 'components.Modals.Form.Config.CustomIcon.Vehicle';
//             break;
//         case 2:
//             label = 'components.Modals.Form.Config.CustomIcon.BackHoe';
//             break;
//         case 3:
//             label = 'components.Modals.Form.Config.CustomIcon.Conveyor';
//             break;
//         case 4:
//             label = 'components.Modals.Form.Config.CustomIcon.Forklift';
//             break;
//         case 5:
//             label = 'components.Modals.Form.Config.CustomIcon.Generator';
//             break;
//         case 6:
//             label = 'components.Modals.Form.Config.CustomIcon.Grove';
//             break;
//         case 7:
//             label = 'components.Modals.Form.Config.CustomIcon.Loader';
//             break;
//         case 8:
//             label = 'components.Modals.Form.Config.CustomIcon.Minivan';
//             break;
//         case 9:
//             label = 'components.Modals.Form.Config.CustomIcon.Paver';
//             break;
//         case 10:
//             label = 'components.Modals.Form.Config.CustomIcon.Recycle';
//             break;
//         case 11:
//             label = 'components.Modals.Form.Config.CustomIcon.Roller';
//             break;
//         case 12:
//             label = 'components.Modals.Form.Config.CustomIcon.SemiTrailer';
//             break;
//         case 13:
//             label = 'components.Modals.Form.Config.CustomIcon.SnowPlow';
//             break;
//         case 14:
//             label = 'components.Modals.Form.Config.CustomIcon.Tractor';
//             break;
//         case 15:
//             label = 'components.Modals.Form.Config.CustomIcon.Thrash';
//             break;
//         case 16:
//             label = 'components.Modals.Form.Config.CustomIcon.Truck';
//             break;
//         case 17:
//             label = 'components.Modals.Form.Config.CustomIcon.VTT';
//             break;
//         case 18:
//             label = 'components.Modals.Form.Config.CustomIcon.Acceleration';
//             break;
//         case 19:
//             label = 'components.Modals.Form.Config.CustomIcon.Alarm';
//             break;
//         case 20:
//             label = 'components.Modals.Form.Config.CustomIcon.AlarmEnd';
//             break;
//         case 21:
//             label = 'components.Modals.Form.Config.CustomIcon.BackDoor';
//             break;
//         case 22:
//             label = 'components.Modals.Form.Config.CustomIcon.Benne';
//             break;
//         case 23:
//             label = 'components.Modals.Form.Config.CustomIcon.Button';
//             break;
//         case 24:
//             label = 'components.Modals.Form.Config.CustomIcon.Deceleration';
//             break;
//         case 25:
//             label = 'components.Modals.Form.Config.CustomIcon.DriverDoor';
//             break;
//         case 26:
//             label = 'components.Modals.Form.Config.CustomIcon.Exceed';
//             break;
//         case 27:
//             label = 'components.Modals.Form.Config.CustomIcon.ExceedEnd';
//             break;
//         case 28:
//             label = 'components.Modals.Form.Config.CustomIcon.FourDoors';
//             break;
//         case 29:
//             label = 'components.Modals.Form.Config.CustomIcon.FrontPlow';
//             break;
//         case 30:
//             label = 'components.Modals.Form.Config.CustomIcon.GabageBin';
//             break;
//         case 31:
//             label = 'components.Modals.Form.Config.CustomIcon.Identification';
//             break;
//         case 32:
//             label = 'components.Modals.Form.Config.CustomIcon.KeyStart';
//             break;
//         case 33:
//             label = 'components.Modals.Form.Config.CustomIcon.Unauthorized';
//             break;
//         case 34:
//             labelon = 'components.Modals.Form.Config.CustomIcon.PassengerDoor';
//             break;
//         case 35:
//             label = 'components.Modals.Form.Config.CustomIcon.PassengerDoor';
//             break;
//         case 36:
//             label = 'components.Modals.Form.Config.CustomIcon.PTO';
//             break;
//         case 37:
//             label = 'components.Modals.Form.Config.CustomIcon.Reverse';
//             break;
//         case 38:
//             label = 'components.Modals.Form.Config.CustomIcon.Seatbelts';
//             break;
//         case 39:
//             label = 'components.Modals.Form.Config.CustomIcon.SidePlow';
//             break;
//         case 40:
//             label = 'components.Modals.Form.Config.CustomIcon.Beacon';
//             break;
//         case 41:
//             label = 'components.Modals.Form.Config.CustomIcon.SmokeDetector';
//             break;
//         case 42:
//             label = 'components.Modals.Form.Config.CustomIcon.StartButton';
//             break;
//         case 43:
//             label = 'components.Modals.Form.Config.CustomIcon.Telephone';
//             break;
//         case 44:
//             label = 'components.Modals.Form.Config.CustomIcon.Temperature';
//             break;
//         default:
//             break;
//     }
//     return label;
// };
