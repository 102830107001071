/* eslint-disable flowtype/require-valid-file-annotation */
/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable no-unused-vars */
/* eslint-disable flowtype/no-existential-type */
import {
    retrieveUsers,
    retrieveNextUsersPage,
    updateUserPassword,
    performDeleteUser,
    patchUser,
    sendEmailVerification,
    getRoles,
    updateUserRoles,
    getUserById,
    createNewUser,
    retrieveUsersWithQuery,
    configLangUnit,
    getLangUnit,
} from './resources';
import {
    userLoading,
    startLoading,
    dismissLoading,
    loadLangUnit,
    receivedUsersSuccess,
    receivedUsersError,
    receivedNextUsersPageSuccess,
    success,
    Error,
    receivedUserRoles,
    receivedUserSuccess,
    createUserSuccess,
    inputLocked,
    receivedSetLoading,
} from './actions';
import {receivedChangedLocale} from 'services/Language/actions'
import { changeLocale } from 'services/Language/thunks';
import { changeUnit } from 'services/Units/thunks';
import { ImmutableMap } from 'types';
import { parseJWT } from 'helpers';
import { push } from 'react-router-redux';
import { AUTH0_DOMAIN } from 'env';
export const getUsers = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return retrieveUsers(token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedUsersError());
            }
            dispatch(receivedUsersSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedUsersError());
            console.log(error);
        });
};

export const fetchUsersWithQuery = (query: string, pageNumber) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return retrieveUsersWithQuery(query, pageNumber, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedUsersError());
            }
            dispatch(receivedUsersSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedUsersError());
            console.log(error);
        });
}

export const getNextUsersPage = (nextLink: *, lock) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (nextLink !== '') {
        if (lock) {
            dispatch(inputLocked(true));
        }
        dispatch(startLoading());
        const token = getState().getIn(['auth', 'user', 'token']);
        const jwt = parseJWT(token);
        const branchId = jwt['https://geothentic.com/branch_id'];

        return retrieveNextUsersPage(nextLink, token)
            .then((response: Object) => {
                if (response.status && typeof response.status === 'string') {
                    dispatch(receivedUsersError());
                }
                dispatch(receivedNextUsersPageSuccess(response.data));
            })
            .catch((error) => {
                dispatch(receivedUsersError());
                console.log(error);
            });
    } else {
        dispatch(inputLocked(false));
        dispatch(receivedNextUsersPageSuccess([]));
    }
};
export const getAllUsersPage = (nextLink: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    getUsers();
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    retrieveUsers(token);
    return retrieveNextUsersPage(nextLink, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedUsersError());
            }
            dispatch(receivedNextUsersPageSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedUsersError());
            console.log(error);
        });
};
export const updatePassword = (data: *) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(userLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return updateUserPassword(data, token, branchId)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            dispatch(success());
        })
        .catch((error) => {
            dispatch(Error());
            console.log(error);
        });
};

export const deleteUser = (data: *) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(startLoading());
    dispatch(userLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return performDeleteUser(data, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            dispatch(success());
        })
        .catch((error) => {
            dispatch(Error());
            console.log(error);
        });
};

export const updateUser = (data: *) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(userLoading());

    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    if (!jwt) {
        return;
    }
    const branchId = jwt['https://geothentic.com/branch_id'];

    return patchUser(data, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            dispatch(success());
        })
        .catch((error) => {
            dispatch(Error());
            console.log(error);
        });
};
export const sendVerificationMail = (data: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return sendEmailVerification(data, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            dispatch(success());
        })
        .catch((error) => {
            dispatch(Error());
            console.log(error);
        });
};
export const getCurrentUserRoles = (data: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return getRoles(token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            dispatch(receivedUserRoles(response.data));
        })
        .catch((error) => {
            dispatch(Error());
            console.log(error);
        });
};

export const updateRoles = (user: *, data: *, methode: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return updateUserRoles(user, data, methode, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            dispatch(success());
            dispatch(dismissLoading());
        })
        .catch((error) => {
            dispatch(Error());
            dispatch(dismissLoading());
            console.log(error);
        });
};

export const getUser = (userId: *) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return getUserById(userId, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            // dispatch(receivedUserSuccess(response.data));
            return response.data
        })
        .catch((error) => {
            dispatch(Error());
            console.log(error);
        });
};

export const createUser = (data: *) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(startLoading());

    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    data.branchId = Number.parseInt(jwt['https://geothentic.com/branch_id'], 10);

    return createNewUser(data, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(Error());
            }
            dispatch(createUserSuccess(response.data));
            return response.data
        })
        .catch((error) => {
            dispatch(Error());
            console.log(error);
        });
};

export const loadLocaleAndUnits = () => (dispatch: ReduxDispatch, getState: any) => {
    const getCookie = (key) => {
        const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
        return b ? b.pop() : '';
    };
    const storedLocale = getCookie('lang') || localStorage.getItem('locale');
    const storedUnit = getCookie('units') || localStorage.getItem('unit');
    const parsed = parseJWT(sessionStorage.getItem('api_token'));
    if (parsed) {
        const userId = parsed['https://geothentic.com/user_id'];
        dispatch(updateUser({ id: userId, lang: storedLocale, units: storedUnit, updateMetadata: true }));
    }
 
};

export const unlockInput = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(inputLocked(false));
};

export const changeLangUnitConfig = (data: string) => (
    dispatch: ReduxDispatch, getState: ImmutableMap
) => {
    const getCookie = (key) => {
        const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
        return b ? b.pop() : '';
    };
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const userID = jwt['https://geothentic.com/user_id'];
    const lang=(data === 'fr')?data :(data ==='en')?data:(data === 'es')?data :getCookie('lang') || localStorage.getItem('locale');
    const unit=(data === 'imperial')?data :(data ==='metric')?data:getCookie('units') || localStorage.getItem('unit');
    const updated=data? true:false;
    const formatData={
        'updated':updated,
        'lang':lang,
        'units':unit
    };
    const currentLocale = getState().getIn(['language', 'locale']);

    const currentPath = getState().getIn(['route', 'location', 'pathname']);
    const url = currentPath.replace(currentLocale, lang);
    const split = AUTH0_DOMAIN.split('.');
    const mainDomain = `${split[1]}.${split[2]}`;

    localStorage.setItem('locale', lang);
    dispatch(push(url))
    return configLangUnit(userID, formatData, token)
        .then((response) => {
            document.cookie = `lang=${lang};domain=${mainDomain};path='/';`;
            dispatch(receivedChangedLocale(lang));
            return response.data
        }
        );
};

export const getLangUnitConfig = () => (
    dispatch: ReduxDispatch, getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const userID = jwt['https://geothentic.com/user_id'];

    return getLangUnit(userID, token )
        .then((response) => {
            const split = AUTH0_DOMAIN.split('.');
            const mainDomain = `${split[1]}.${split[2]}`;
            document.cookie = `lang=${response.data.lang};domain=${mainDomain};path='/';`;
            dispatch(changeLocale(response.data.lang ));
            dispatch(changeUnit(response.data.units ));
            return response.data
        }
        );
};
