// @flow

import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import {
    GET_BRANCH_DATA_SUCCESS,
    EDIT_BRANCH_SUCCESS,
    GET_BRANCH_USER_SUCCESS,
    DELETE_BRANCH_USER_SUCCESS,
    DELETE_BRANCH_SUCCESS,
    CREATE_BRANCH_DIVISION_SUCCESS,
    DELETE_BRANCH_EQUIPMENT,
} from './actions';

import {
    ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS,
    EDIT_EQUIPMENT_BRANCH_SUCCESS,
} from 'services/Equipment/actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    selectedBranch: {},
    userErrors: {},
});

function branchServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case GET_BRANCH_DATA_SUCCESS: {
            const { branch, ...data } = action.branch;
            return state.set('selectedBranch', fromJS({ ...branch, ...data }));
        }
        case GET_BRANCH_USER_SUCCESS: {
            return state
                .setIn(
                    ['selectedBranch', 'users'],
                    state.getIn(['selectedBranch', 'users']).push(fromJS(action.user))
                )
                .setIn(
                    ['selectedBranch', 'usersCount'],
                    state.getIn(['selectedBranch', 'usersCount']) + 1
                );
        }
        case EDIT_BRANCH_SUCCESS: {
            return state.set('selectedBranch', state.get('selectedBranch').merge(action.branch));
        }
        case DELETE_BRANCH_SUCCESS: {
            return state.setIn(['selectedBranch', 'deleted'], true);
        }
        case LOCATION_CHANGE: {
            return state.set('userErrors', fromJS({}));
        }
        case DELETE_BRANCH_USER_SUCCESS: {
            const userIndex = state
                .getIn(['selectedBranch', 'users'])
                .findIndex((value) => value.get('userId') === action.userId);

            return state
                .setIn(
                    ['selectedBranch', 'users'],
                    state.getIn(['selectedBranch', 'users']).delete(userIndex)
                )
                .setIn(
                    ['selectedBranch', 'usersCount'],
                    state.getIn(['selectedBranch', 'usersCount']) - 1
                );
        }
        case CREATE_BRANCH_DIVISION_SUCCESS: {
            return state
                .setIn(
                    ['selectedBranch', 'divisions'],
                    state.getIn(['selectedBranch', 'divisions']).push(fromJS(action.division))
                )
                .setIn(
                    ['selectedBranch', 'divisionsCount'],
                    state.getIn(['selectedBranch', 'divisionsCount']) + 1
                );
        }
        case ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS: {
            return state.setIn(
                ['selectedBranch', 'equipment'],
                state.getIn(['selectedBranch', 'equipment']).push(fromJS(action.equipment))
            );
        }
        case EDIT_EQUIPMENT_BRANCH_SUCCESS: {
            const equipment = state.getIn(['selectedBranch', 'equipment']);
            const equipmentIndex = equipment.findIndex((e) => e.get('id') === action.equipment.id);
            const list = equipment.update(equipmentIndex, () => fromJS(action.equipment));

            return state.setIn(['selectedBranch', 'equipment'], list);
        }
        case DELETE_BRANCH_EQUIPMENT: {
            const equipmentIndex = state
                .getIn(['selectedBranch', 'equipment'])
                .findIndex((equipment) => equipment.get('id') === action.equipmentId);
            return state.setIn(
                ['selectedBranch', 'equipment'],
                state.getIn(['selectedBranch', 'equipment']).delete(equipmentIndex)
            );
        }
        default:
            return state;
    }
}

export default branchServiceReducer;
