// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
export const selectGeofenceState = () => (state) => state.get('geofence');

export const selectGeofences = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('geofences'));

export const selectGeofenceLoading = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('buttonLoading'));

export const selectGeofenceError = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('configError'));

export const selectDrawMode = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('drawMode'));

export const selectShowGeofences = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('showGeofences'));

export const selectCurrentGeofence = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('selectedGeofence'));

export const selectClickedGeofenceData = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('clickedGeofenceData'));

export const selectCreatingGeofenceMode = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('creatingGeofenceMode'));

export const selectIsGeofenceEditable = () =>
    createSelector(selectGeofenceState(), (substate) => substate.get('isGeofenceEditable'));