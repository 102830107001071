// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
    BarChart,
    CartesianGrid,
    Legend,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Bar,
    Brush,
} from 'recharts';
import Loader from 'components/Loader';
import colors from 'styles/colors';
import { i18nDistanceSymbol } from 'services/Units/thunks';
import GeoExportExcel from 'components/GeoExportExcel';
import { Resizable, ResizableBox } from 'react-resizable';


class GeoHBarChart extends React.Component<Props, State> {
    render() {
        const { data, bar, title, loading, layout, type, i18nDistanceSymbol, totalBar, dateRange } = this.props;

        const secondsToHms = (d) => {
            if (d == 0) return 0;
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);

            var hDisplay = h > 0 ? h + " h, " : "";
            var mDisplay = m > 0 ? m + " m, " : "";
            var sDisplay = s > 0 ? s + " s" : "";
            return hDisplay + mDisplay + sDisplay;
        }

        const CustomTooltip = ({ active, payload, label, type }) => {
            if (active) {
                return (
                    <div className="custom-tooltip">
                        <p className="label">{label}</p>
                        {payload &&
                            payload.map((key) => {
                                return (
                                    <p style={{
                                        color: key.fill
                                    }} className="desc">{`${key.name} : ${type == 'time'
                                        ? secondsToHms(Number(key.value))
                                        : type == 'distance'
                                            ? `${Number(key.value)} ${i18nDistanceSymbol()}`
                                            : Number(key.value)
                                        }`}</p>
                                );
                            })}
                        {payload &&
                            (type == 'time' || type == 'distance') && (
                                <p className="desc">
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.total.algo'}
                                    />{' '}
                                    :{' '}
                                    {type == 'time'
                                        ? secondsToHms(
                                            payload.reduce(
                                                (a, b) => Number(a.value) + Number(b.value)
                                            ))
                                        : `${payload.reduce(
                                            (a, b) => Number(a.value) + Number(b.value)
                                        ).toFixed(0)} ${i18nDistanceSymbol()}`}
                                </p>
                            )}
                    </div>
                );
            }
            return null;
        };

        return (
            <ResizableBox
                className={'element'}
                minConstraints={[400, 500]}
                transformScale={0.75}
                width={400}
                height={500}
                axis={"x"}
                style={{ borderColor: colors.green61 }}
                handle={(h) => <span className={`custom-handle custom-handle-${h}`} />}>
                <span
                    className="d-inline-block"
                    style={{
                        textAlign: 'center',
                        color: colors.green61,
                        textTransform: 'uppercase',
                        textDecorationLine: 'underline',
                    }}
                >
                    {title}{' '}
                </span>
                <hr />
                {loading ? (
                    <Loader loading />
                ) : (
                    [data ? <div className={"export"}><GeoExportExcel totalBar={totalBar} dateRange={dateRange} title={title} bar={bar} data={data} dataKey={"vehicle_numb"} /></div> : null,
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={data}
                            layout={layout}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" style={{ fontSize: '10px' }} />
                            <YAxis
                                type="category"
                                dataKey="vehicle_numb"
                                style={{ fontSize: '10px' }}
                            />
                            <Tooltip content={<CustomTooltip type={type} />} />
                            <Legend
                                verticalAlign="bottom"
                                wrapperStyle={{ lineHeight: '20px', fontSize: '10px' }}
                            />
                            <Brush
                                dataKey="vehicle_numb"
                                height={10}
                                stroke={colors.gray88}
                                style={{ fontSize: '10px' }}
                                startIndex={0}
                                endIndex={data.length >= 5 ? 5 : data.length - 1}
                            />
                            {bar.length > 0 &&
                                bar.map((element) => (
                                    <Bar
                                        name={element.name}
                                        dataKey={element.barName}
                                        stackId={element.barStackId}
                                        stroke={element.stroke}
                                        fill={element.fill}
                                    />
                                ))}
                        </BarChart>
                    </ResponsiveContainer>]
                )}
            </ResizableBox>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            i18nDistanceSymbol,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(GeoHBarChart))
);
