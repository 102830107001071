// @flow

import React from 'react';

import { injectIntl } from 'react-intl';

import Error from 'components/Error';

import { InputField, MultiLineInputField, Container } from './styles';
import colors from 'styles/colors';

type Props = {
    placeholderId: string,
    type: ?string,
    image: ?any,
    value: ?string,
    defaultValue?: ?string,
    onChange: ?() => void,
    multiline: ?boolean,
    error: ?(boolean | string),
    required: ?boolean,
    height: ?number,
    backgroundColor: ?string,
    readOnly: ?boolean,
};

class FormInputText extends React.PureComponent<Props> {
    render() {
        const placeholder = this.props.intl.formatMessage({ id: this.props.placeholderId });

        return (this.props.multiline) ?
            <Container>
                <MultiLineInputField>
                    <MultiLineInputField.Input
                        value={this.props.value}
                        defaultValue={this.props.defaultValue}
                        placeholder={placeholder}
                        onChange={this.props.onChange}
                        readOnly={this.props.readOnly}
                    />
                </MultiLineInputField>
                <React.Fragment>
                    {this.props.error && <Error text={this.props.error} />}
                </React.Fragment>
            </Container>
            :
            <Container>
                <InputField
                    borderColor={
                        this.props.error || this.props.error === ''
                            ? colors.status.red
                            : colors.grayDD
                    }
                    disabled={this.props.disabled}
                    height={this.props.height}
                    backgroundColor={this.props.backgroundColor}
                >
                    <InputField.TextInput
                        type={this.props.type}
                        value={this.props.value}
                        defaultValue={this.props.defaultValue}
                        placeholder={this.props.required ? `${placeholder}*` : placeholder}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                        required={this.props.required}
                        readOnly={this.props.readOnly}
                    />
                    {this.props.image ? <InputField.Image src={this.props.image} /> : null}
                </InputField>
                <React.Fragment>
                    {this.props.error && <Error text={this.props.error} />}
                </React.Fragment>
            </Container>
    }
}

FormInputText.defaultProps = {
    readOnly: false,
};

export default injectIntl(FormInputText);
