// @flow

import { fromJS } from 'immutable';
import { CHANGE_UNIT, DEFAULT_UNIT } from './actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    locale: localStorage.getItem('unit') || DEFAULT_UNIT,
});

function unitsProviderReducer(state = initialState, action: GenericActionType) {
    switch (action.type) {
        case CHANGE_UNIT:
            return state.set('unit', action.payload.unit);
        default:
            return state;
    }
}

export default unitsProviderReducer;
