// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const InputField = styled.div`
    display: flex;
    height: ${(props) => props.height || 40}px;
    width: ${(props) => `${props.width}px` || "100%"};
    background-color: ${({ backgroundColor, disabled }) =>
        disabled ? '#ddd' : backgroundColor || colors.white};
    ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : '')} justify-content: center;
    align-items: center;
    padding-left: ${({customStyle}) => customStyle ? customStyle['paddingLeft'] || '17px' : '17px'};
    padding-right: ${({customStyle}) => customStyle ? customStyle['paddingRight'] || '12px' : '12px'};
    border: 1px solid ${({ borderColor }) => borderColor};
    min-height: 100%;
`;
// border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};
InputField.TextInput = styled.input.attrs(({ disabled }) => {
    disabled;
    // add a global color property.
})`
    ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : '')} 
    font-family: ${({customStyle}) => customStyle ? customStyle['fontFamily'] || 'OpenSans': 'OpenSans'};
    font-size: ${({customStyle}) => customStyle ? customStyle['fontSize'] || 'inherit' : 'inherit'};
    font-weight: ${({customStyle}) => customStyle ? customStyle['fontWeight'] || 'inherit': 'inherit'};
    width: 100%;
    border: none;
    background: none;
    text-align: ${({center}) => center ? "center" : "default"};
    color: ${(props) => props.color};
    &:focus {
        outline: none;
    }

    ::placeholder {
        color: ${(props) => props.color || colors.black};
        opacity: 1;
    }
`;

InputField.Image = styled.img`
    height: 20px;
    width: 20px;
`;

export const MultiLineInputField = styled.div`
    display: flex;
    height: 130px;
    background-color: ${colors.white};
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    border: 1px solid ${colors.grayDD};
`;

MultiLineInputField.Input = styled.textarea`
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    resize: none;
    font-family: OpenSans;
    font-size: 16px;
    &::placeholder {
        color: ${colors.black};
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: OpenSans;
        font-size: 16px;
        color: ${colors.black};
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
