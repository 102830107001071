// @flow

// IMPORTS
import { createSelector } from 'reselect';

// BASE SELECTOR
/**
 * Direct selector to the organization state domain
 */
const selectVehicleState = () => (state) => state.get('vehicle');

// SELECTORS
/**
 *
 */
export const selectVehicles = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehicles'));

export const selectTemperatureVehicles = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('temperatureVehicles'));

/**
 *
 */
export const selectEventTypes = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('eventTypes'));

/**
 *
 */
export const selectHistoryId = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('selectedHistoryId'));

/**
 *
 */
export const selectVehicleHistory = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('equipmentHistory'));

/**
 *
 */
export const selectVehicleSort = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('sort'));

/**
 *
 */
export const selectVehicleOrder = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('order'));

/**
 *
 */
export const selectEquipmentHistory = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('equipmentHistory'));

/**
 *
 */
export const selectMapEquipmentPopout = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehiclePopout'));

/**
 *
 */
export const selectFilterEvents = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('filterEvents'));

/**
 *
 */
export const selectFilteredVehicles = () =>
    createSelector(
        [selectVehicleState(), selectFilterEvents(), selectEventTypes()],
        (substate, filters, eventTypes) => {
            if (filters && filters.length > 0) {
                return substate.get('vehicles').filter((vehicle) => {
                    const eventTypeId = vehicle.getIn(['status', 'eventTypeId']);
                    const eventType = eventTypes.get(`${eventTypeId}`);
                    if (eventTypeId && eventType) {
                        return filters.indexOf(eventType.get('description')) > -1;
                    }
                    return '';
                });
            }
            return substate.get('vehicles');
        }
    );

/**
 *
 */
export const selectAnimatedVehicles = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehiclesAnimate'));

/**
 *
 */
export const selectMapEquipmentPopoutCoordinate = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehiclePopoutCoordinate'));

/**
 *
 */
export const selectMapEquipmentPopupIndex = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehiclePopoutIndex'));

/**
 *
 */
export const selectVehicleError = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehicleError'));

/**
 *
 */
export const selectMapEmptyHistory = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('mapEmptyHistory'));

/**
 *
 */
export const selectVehiclesLoading = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('loading'));

/**
 *
 */
export const selectVehicleLoading = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('loadingVehicle'));

/**
 *
 */
export const selectVehicleHistoryLoading = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('loadingVehicleHistory'));

/**
 *
 */
export const selectEquipmentLoading = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('loadingEquipmentPopup'));

/**
 *
 */
export const selectHistoryPopout = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('historyPopout'));

/**
 *
 */
export const selectEventGroups = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('eventGroups'));

/**
 *
 */
export const selectMaskedPoints = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('isMaskedPoints'));

/*
*
*/
export const selectRouteMatching = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('isRouteMatching'));

export const selectVehicleWebHooks = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehicle_ext_web_hook'));

export const selectControlColors = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('controlColors'));

export const selectGarageMode = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('garageMode'));

export const selectVehicleChangeWebHksState = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('changeWebHookStateLoading'));

export const selectVehicleHistoryDetail = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('vehicleHistoryDetail'));

export const selectFleetEquipment = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('fleetEquipment'));

export const selectFleetEquipmentNextPage = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('fleetEquipmentNextPage'));

export const selectHistoryHasNextPage = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('historyHasNextPage'));

export const selectIsVehicleHistoryDetail = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('isVehicleHistoryDetail'));

export const selectIsFleetEquipment = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('isFleetEquipment'));

export const selectIsLoadedVehicleHistory = () =>
    createSelector(selectVehicleState(), (substate) => substate.get('isLoadedVehicleHistory'));
