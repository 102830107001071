// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const InputFileComponentStyles = styled.div`
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon{
        color:${colors.green63};
    }
`;

export default InputFileComponentStyles;
