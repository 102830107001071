// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Content } from 'styles/common';
import { Title, Text } from './styles.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { replace } from 'react-router-redux';
import { selectLocale } from 'services/Language/selectors';

const NotFoundPage = (props) => (
    <Container>
        <FormattedMessage id={'views.404.Title'}>
            {(title) => (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            )}
        </FormattedMessage>

        <Content>
            <Title>
                <FormattedMessage id={'views.404.Title'} />
            </Title>
            <Text>
                <FormattedMessage id={'views.404.Text'} />
            </Text>

            <Link to={`/${props.locale}`}>
                <FormattedMessage id={'views.404.Return'} />
            </Link>
        </Content>
    </Container>
);

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
});

const mapDispatch = { replace };

export default connect(
    mapStateToProps,
    mapDispatch
)(injectIntl(NotFoundPage));
