// @flow

const screen = {
    xsmall: '0px',
    small: '576px',
    medium: '768px',
    large: '992px',
    xlarge: '1200px',
};

export default screen;
