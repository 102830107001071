

export function getGroupName(intl, id)  {
    if(intl && intl.messages && intl.messages[id]) {
        let name = intl.formatMessage({ id: id, defaultMessage: id });
        return name;
    }
    return id;
};

