import styled from 'styled-components';
import colors from '../../../styles/colors';

const AlarmConfigStyle = styled.div`
    display: flex;

    .fa-question-circle {
        font-size: 23px;
        background: ${colors.white};
        color: ${colors.green57};
        border-radius: 20px;
        margin-left: 10px;
        cursor: pointer;
    }

`;
AlarmConfigStyle.Input = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 1% 0;
    align-items: center;

    input {
        width: 52%;
        height: 70%;
        margin-top: 1px;
    }

`;
AlarmConfigStyle.InputLabel = styled.div`
    align-self: center;
    display: flex;
    flex-direction: row;
    width: 40%;
`;
AlarmConfigStyle.Tooltip = styled.div`
    width: 150px;


`;

export default AlarmConfigStyle;
