//@flow
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';
import CalendarIcon from '../../assets/calendar-icon.svg';
import {Date} from './styles'
import {Icon} from "../FormInputDate/styles";
import {InputLabel, DrivingReportGenerator} from '../../styles/reports'
import Error from '../Error/index'


const ReportsInputDate = (props) => {

    return (

        <Date>
            <DrivingReportGenerator.Column>

                <InputLabel margin={'0 0 15px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.From'}/>
                </InputLabel>
                <Date.Container width={props.width || '230px'}>
                    <Date.InputDate
                        type={props.monthDateType && props.monthDateType === true ? 'month' : 'date'}
                        placeholder="YYYY/MM/DD"
                        value={props.startDate}
                        onChange={props.onChangeStartDate}
                        error={props.errorsStartDate}
                    />

                    <Icon src={CalendarIcon} width={23} height={24}/>
                </Date.Container>
                {(props.dateLimit) &&
                    (props.dateLimitPositionLeft) && (
                        <DrivingReportGenerator.DateError>
                            <Error
                                text={props.intl.formatMessage({
                                    id: 'containers.Reports.Generators.Driving.DateLimitError',
                                })}
                            />
                        </DrivingReportGenerator.DateError>
                    )}
            </DrivingReportGenerator.Column>

            <DrivingReportGenerator.Column>


                <InputLabel margin={'0 0 15px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.To'}/>
                </InputLabel>

                <Date.Container width={props.width || '230px'}>
                    <Date.InputDate
                        type={props.monthDateType && props.monthDateType === true ? 'month' : 'date'}
                        value={props.endDate}
                        onChange={props.onChangeEndDate}
                        error={props.errorEndDate}
                    />

                    <Icon src={CalendarIcon} width={23} height={24}/>
                </Date.Container>
                {(props.dateLimit) &&
                    (!props.dateLimitPositionLeft) && (
                        <DrivingReportGenerator.DateError>
                            <Error
                                text={props.intl.formatMessage({
                                    id: 'containers.Reports.Generators.Driving.DateLimitError',
                                })}
                            />
                        </DrivingReportGenerator.DateError>
                    )}
            </DrivingReportGenerator.Column>
        </Date>


    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch,
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(ReportsInputDate)),
);
