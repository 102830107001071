// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const Error = styled.div`
    font-family: OpenSans;
    font-size: 12px;
    color: ${colors.status.red};
`;
