// @flow

export const USER_LOADING = 'app/services/ADMIN/USER_LOADING';
export const USER_ROLES = 'app/services/ADMIN/USER_ROLES';
export const LOADING = 'app/services/ADMIN/LOADING';
export const DISMISS_LOADING = 'app/services/ADMIN/DISMISS_LOADING';
export const GET_USERS_SUCCESS = 'app/services/ADMIN/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'app/services/ADMIN/GET_USERS_ERROR';
export const GET_NEXT_USERS_PAGE_SUCCESS = 'app/services/ADMIN/GET_NEXT_USERS_PAGE_SUCCESS';
export const SUCCESS = 'app/services/ADMIN/SUCCESS';
export const ERROR = 'app/services/ADMIN/ERROR';
export const PASSWORD_FORM_VISIBLE = 'app/services/ADMIN/PASSWORD_FORM_VISIBLE';
export const CREATE_USER_SUCCESS = 'app/services/ADMIN/CREATE_USER_SUCCESS';
export const GET_USER_SUCCESS = 'app/services/ADMIN/GET_USER_SUCCESS';
export const INPUT_LOCKED = 'app/services/ADMIN/INPUT_LOCKED';
export const receivedUsersSuccess = (users): GenericActionType => ({
    type: GET_USERS_SUCCESS,
    users,
});

export const receivedUsersError = (): GenericActionType => ({
    type: GET_USERS_ERROR,
});

export const startLoading = (): GenericActionType => ({
    type: LOADING,
});

export const dismissLoading = (): GenericActionType => ({
    type: DISMISS_LOADING,
});

export const receivedNextUsersPageSuccess = (users): GenericActionType => ({
    type: GET_NEXT_USERS_PAGE_SUCCESS,
    users,
});

export const userLoading = (): GenericActionType => ({
    type: USER_LOADING,
});

export const success = (): GenericActionType => ({
    type: SUCCESS,
});

export const passwordFormVisible = (): GenericActionType => ({
    type: PASSWORD_FORM_VISIBLE,
});

export const Error = (): GenericActionType => ({
    type: ERROR,
});

export const receivedUserRoles = (roles): GenericActionType => ({
    type: USER_ROLES,
    roles,
});

export const receivedUserSuccess = (user): GenericActionType => ({
    type: GET_USER_SUCCESS,
    user,
});

export const createUserSuccess = (userId): GenericActionType => ({
    type: CREATE_USER_SUCCESS,
    userId,
});

export const inputLocked = (locked): GenericActionType => ({
    type: INPUT_LOCKED,
    locked,
});
