// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'antd/es/modal';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import { createStructuredSelector } from 'reselect';
import VehicleDetailManagement from '../VehicleDetail/styles';
import Icon from '@material-ui/core/Icon';
import {
    selectMaintenanceVehHistoryState,
    selectReinitializeAlarms,
    selectVehicleState,
} from '../../../services/Maintenance/selectors';

import moment from 'moment';
import VehicleDetail from '../VehicleDetail';
import { sortByAlert } from '../helpers';
import colors from '../../../styles/colors';
import {
    getMaintenanceVeh, patchMaintenanceVehicle,
    removeMaintenanceAlarmVehicle,
} from '../../../services/Maintenance/thunks';
import ParticularMaintenance from '../ParticularMaintenance';
import AlarmVehicleListStyle from './styles';
import { changeUnit, i18nDistanceSymbol, setTextunit } from '../../../services/Units/thunks';
import { selectUnit } from '../../../services/Units/selectors';
import EdiTableAlarme from '../EditTableAlarme';

const AlarmVehicleList = (props) => {
        const [vehicleAlarms, setVehicleAlarms] = useState([]);
        const [backToVehicle, setBackToVehicle] = useState(true);
        const [isReanitialize, setIsReanitialize] = useState(false);
        const [alarmSelected, setAlarmSelected] = useState();
        const [dateReinitialize, setDateReinitialize] = useState(new moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        const [isModalReinitializeVisible, setIsModaReinitializelVisible] = useState(false);
        const [isModalMaintenanceOpen, setIsModalMaintenanceOpen] = useState(false);
        const [maintId,setMaintId]=useState(null)

        useEffect(
            () => {
                props.selectedAlarms.forEach((item) => {

                    let color = '';
                   setMaintId(item.maintId)
                    if (item.alertRaised) {
                        color = colors.redA;
                    } else if (item.reminderRaised) {
                        color = colors.yellowA;
                    } else {
                        color = colors.white;
                    }
                    let dateSince = '';
                    if (item.datetimeRaised !== null) {
                        dateSince = getDifferenceInDays(new Date(item.datetimeRaised), new Date());
                    } else {
                        dateSince = '';
                    }
                    Object.assign(item, { 'color': color, 'dateSince': parseInt(dateSince) });
                });
                const sortedAlarmsList = sortByAlert(props.selectedAlarms);
                setVehicleAlarms(sortedAlarmsList);            

            },
            [props.selectedVehicle, props.selectedAlarms, isReanitialize, setDateReinitialize],
        );
       
        const getDifferenceInDays = (date1, date2) => {
            const diffInMs = Math.abs(date2 - date1);
            return diffInMs / (1000 * 60 * 60 * 24);
        };

        const updateVehicles = (newdatas) => {
            const sortedAlarmsList = sortByAlert(newdatas);
            setVehicleAlarms(sortedAlarmsList)
         };
        const handleBack = (event) => {
            setVehicleAlarms([]);
            setBackToVehicle(false);
        };
        const handleRemove = (value) => (event) => {
            setAlarmSelected(value);
            setIsModaReinitializelVisible(true);
        };


        const handleReinitialize = (value) => (event) => {
            setAlarmSelected(value);
            setIsReanitialize(true);

        };
        const handleOnChangeDate = (date: moment, dateString: string) => {
            if (date !== null) {
                const dateValue = moment(date._d).format('YYYY-MM-DD HH:mm:ss');
                setDateReinitialize(dateValue);
            }

        };

        const handleConfirm = (value) => (event) => {
       
            let data = {
                last_alert: dateReinitialize,
                maint_id: value.maintId,
            };
            props.patchMaintenanceVehicle(value.vehId, data)
                .then(() => {
                    props.getMaintenanceVeh(value.vehId);
                });

            setIsReanitialize(false);
            setDateReinitialize(new moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        };

        const handleCancel = () => {
            setIsReanitialize(false);
        };

        const handleCancelModal = () => {
            setIsModaReinitializelVisible(false);
        };
        const handleOk = () => {
            props.removeMaintenanceAlarmVehicle(JSON.stringify([alarmSelected]));
            const newAlarmList = vehicleAlarms.filter((item) => item !== alarmSelected);
            setVehicleAlarms(newAlarmList);
            setIsModaReinitializelVisible(false);
        };
        const addParticularMaintenance = (event) => {
            setIsModalMaintenanceOpen(true);
        };
        const handleCancelMaintenance = () => {
            setIsModalMaintenanceOpen(false);
        };
        const columns = [
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfNameOfAlarm' }),
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                render: (value) => {
                    return (
                        <div> {value}</div>
                    );
                },

            },
            {
                title: props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfKmCumulated' }) : props.intl.formatMessage({ id: 'containers.AlarmVehicleList.VehicleDetails.MilesCum' }),
                dataIndex: 'cumulDistance',
                key: 'cumulDistance',
                editable: true,
                sorter: (a, b) => a.cumulDistance - b.cumulDistance,
                render: (value,record) => {
                    return (
                        <div>
                               <VehicleDetailManagement.IconEdit
                                                key={record.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                }}>
                        <div style={{ width: '45%', textAlign: 'left' }}>
                            
                            {`${props.setTextunit(
                                (value / 1000),
                                'odometer',
                            )}` }
                             
                                    </div>
                                    <div>
                                        <Icon className='fa fa-edit' style={{fontSize:'15px'}} />
                                    </div>
                            </VehicleDetailManagement.IconEdit>
                        </div>
                    );
                },

            },
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfHrsCumulated' }),
                dataIndex: 'cumulHrs',
                key: 'cumulHrs',
                editable: true,
                sorter: (a, b) => a.cumulHrs - b.cumulHrs,
                render: (value ,record) => {
                    return (
                        <div>
                                 <VehicleDetailManagement.IconEdit
                                                key={record.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                }}>
                            <div style={{ width: '45%', textAlign: 'left' }}>
                            {(Math.floor((value / 60 / 60) * 100) / 100).toFixed(2)}
                            </div>
                                    <div>
                                        <Icon className='fa fa-edit'  style={{fontSize:'15px'}}/>
                                    </div>
                            </VehicleDetailManagement.IconEdit>
                            </div>
                    );
                },
            },
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.AlarmVehicle.Changes' }),
                dataIndex: 'lastAlert',
                key: 'lastAlert',
                width: '200px',
                sorter: (a, b) => new Date(a.lastAlert) - new Date(b.lastAlert),
                render: (value, record) => {
                    if (alarmSelected === record && isReanitialize) {
                        return (
                            <DatePicker
                                defaultValue={moment(
                                    new Date(),
                                )}
                                value={moment(dateReinitialize)}
                                format={'YYYY-MM-DD'}
                                onChange={handleOnChangeDate}

                            />
                        );
                    } else {
                        return (
                            <div>{moment(value).format('YYYY-MM-DD')}</div>
                        );
                    }

                },

            },
            {
                key: 'action',
                render: (value, record, index) => {
                    if (alarmSelected === value && isReanitialize) {

                        return (
                            <AlarmVehicleListStyle.TableBtn>
                                <Button type='primary' onClick={handleConfirm(value)}>
                                    <FormattedMessage id={'containers.Maintenance.Modal.Confirm'} />
                                </Button>
                                <Button type='primary' className={'redBtn'} onClick={handleCancel}>
                                    <FormattedMessage id={'containers.Maintenance.Modal.Cancel'} />
                                </Button>
                            </AlarmVehicleListStyle.TableBtn>
                        )
                            ;

                    } else {
                        return (
                            <AlarmVehicleListStyle.TableBtn>
                                <Button type='primary' onClick={handleReinitialize(value)}>
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.Reinitiliaze'} />
                                </Button>

                                <Button type='primary' className={'redBtn'} onClick={handleRemove(value)}
                                >
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.Unlink'} />
                                </Button>

                            </AlarmVehicleListStyle.TableBtn>
                        );
                    }

                },

            },
            {
                key: 'action',
                render: (value, index) => {
                    return (
                        <AlarmVehicleListStyle.Date>
                            <span>{!isNaN(value.dateSince) ? '+' + (parseInt(value.dateSince) === 0 ? (1 + props.intl.formatMessage({ id: 'containers.Maintenance.AlarmVehicle.DaysAlert' }))
                                : (parseInt(value.dateSince)) + props.intl.formatMessage({ id: 'containers.Maintenance.AlarmVehicle.DaysAlert' }))
                                : ''}
                            </span>
                            <i className='fa fa-bell'
                               style={{ color: (index.color) }}> </i>
                        </AlarmVehicleListStyle.Date>
                    );

                },

            },


        ];

        return (
            backToVehicle ?
                <AlarmVehicleListStyle>
                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.Management'} />&nbsp;
                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.VehAlarm'} />&nbsp;
                    <span style={{color: colors.green57}} className={'title'}>{props.selectedVehicle['name']}</span>
                    <hr />
                    <EdiTableAlarme
                        columns={columns}
                        dataSource={vehicleAlarms}
                        rowKey={record => record.name}
                        pagination={false}
                        maintId={maintId}
                        EditAlarmType="2"
                        updateVehicles={updateVehicles}
                    />
                    <AlarmVehicleListStyle.TableFooter>
                        < Button onClick={addParticularMaintenance}>
                            <FormattedMessage id={'containers.Maintenance.AlarmVehicle.AddMaintenance'} />
                        </Button>

                        <Button onClick={handleBack}>
                            <FormattedMessage id={'containers.Maintenance.AlarmVehicle.BackToVeh'} />
                        </Button>
                    </AlarmVehicleListStyle.TableFooter>

                    <ParticularMaintenance selectedVehicle={props.selectedVehicle}
                                           isModalMaintenanceOpen={isModalMaintenanceOpen}
                                           handleCancelMaintenance={handleCancelMaintenance} />

                    <Modal
                        title={props.intl.formatMessage({ id: 'containers.Maintenance.AlarmVehicle.ModalDelete' })}
                        visible={isModalReinitializeVisible}
                        onCancel={handleCancelModal}
                        footer={[
                            <AlarmVehicleListStyle.ModalDelete key={'modalDelete'}>
                                <Button
                                    key='no'
                                    className={'redBtn'}
                                    type='primary'
                                    onClick={handleCancelModal}
                                >
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalNo'} />
                                </Button>
                                <Button
                                    key='yes'
                                    className={'greenBtn'}
                                    type='primary'
                                    onClick={handleOk}
                                >
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalYes'} />
                                </Button>
                            </AlarmVehicleListStyle.ModalDelete>,

                        ]}>

                        <p>{alarmSelected !== undefined ? alarmSelected.name : ''}</p>

                    </Modal>

                </AlarmVehicleListStyle>
                :
                <div>
                    <VehicleDetail />
                </div>

        );
    }
;
const mapStateToProps = createStructuredSelector({
    selectedVehicle: selectVehicleState(),
    selectedAlarms: selectMaintenanceVehHistoryState(),
    selectedReinitializeAlarms: selectReinitializeAlarms(),
    unit: selectUnit(),
});
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            removeMaintenanceAlarmVehicle,
            getMaintenanceVeh,
            patchMaintenanceVehicle,
            setTextunit,
            changeUnit,
            i18nDistanceSymbol,
        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(AlarmVehicleList));
