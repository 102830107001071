import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '@mui/material/Modal';
import { Grid, Box, Chip } from '@mui/material';
import './index.scss';
import { connect } from 'react-redux';
import { closeDrivingReportModal } from 'services/Report/thunks';
import { selectDrivingInfo } from 'services/Report/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { selectLocale } from '../../../services/Language/selectors';
import DriverChip from '../../Chip';
import { isImperial } from 'services/Units/thunks';
import DrivingReportMap from '../../../components/DrivingReportMap';

function DrivingReportModal(props) {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        props.closeDrivingReportModal();
        setOpen(false);
    };
    const distanceUnit = props.isImperial() ? 'mi' : 'km';
    const speedUnit = props.isImperial() ? 'mph' : 'km/h';
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={'containerBox'}>
                <Grid container className={'headerModalDep'} mb={'10px'} mt={-5} spacing={2}>
                    <Grid item>
                        <div className={'vehiculeNumbModalDep'}>
                            {props.drivingInfo.vehiculeNumb}
                        </div>
                    </Grid>
                    <Grid item>
                        {props.drivingInfo.driverName ? (
                            <Box>
                                <DriverChip type="driver" color={props.drivingInfo.driverChipColor}>
                                    <i className="far fa-id-badge" />
                                    &nbsp;
                                    {props.drivingInfo.driverName}
                                </DriverChip>
                            </Box>
                        ) : (
                            <empty />
                        )}
                    </Grid>
                </Grid>
                <Grid container className="containerInfo">
                    <Grid item className="depInformation">
                        <div div className="DepCardInfo">
                            <div>
                                <span className="depDate">{props.drivingInfo.date}</span>{' '}
                            </div>
                            <div>
                                <span className="depTime">{props.drivingInfo.time}</span>
                            </div>
                            {props.drivingInfo.geoName ? (
                                
                                    <div div className="DepCardInfo">
                                        <div>
                                            {props.intl.formatMessage({
                                                id: 'Component.Report.Overspeed.Geofence',
                                            })}
                                        </div>
                                        <div>
                                            <span className="depGeoname">
                                                {props.drivingInfo.geoName}
                                            </span>
                                        </div>
                                    </div>
                            ) : (
                                    <div div className="DepCardInfo">
                                        <div>
                                            {props.intl.formatMessage({
                                                id: 'Component.Report.Overspeed.Geofence',
                                            })}
                                        </div>
                                        <div>
                                            <span className="depGeoname">
                                                {props.intl.formatMessage({
                                                    id:
                                                        'Component.Report.Overspeed.DefaultGeofence',
                                                })}
                                            </span>
                                        </div>
                                    </div>
                            )}
                        </div>
                    </Grid>
                    <Grid item className="depInformation">
                        <div div className="DepCardInfo">
                            <div>
                                <span className="valeurkm">{props.drivingInfo.distance}</span>{' '}
                                <span>{distanceUnit}</span>
                            </div>
                            <div>
                                {props.intl.formatMessage({
                                    id: 'Component.Report.Overspeed.Distance',
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item className="depInformation">
                        <div div className="DepCardInfo">
                            <div>
                                <span className="valeurkm">{props.drivingInfo.speed}</span>{' '}
                                <span>{speedUnit}</span>
                            </div>
                            <div>
                                {props.intl.formatMessage({
                                    id: 'Component.Report.Overspeed.SpeedEntry',
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item className="depInformation">
                        <div div className="DepCardInfo">
                            <div>
                                <span className="valeurkm">{props.drivingInfo.maximumSpeed}</span>{' '}
                                <span>{speedUnit}</span>
                            </div>
                            <div>
                                {props.intl.formatMessage({
                                    id: 'Component.Report.Overspeed.SpeedMax',
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item className="depInformation">
                        <div div className="DepCardInfo">
                            <div>
                                <span className="valeurkmMax">{props.drivingInfo.speedLimit}</span>{' '}
                                <span>{speedUnit}</span>
                            </div>
                            <div>
                                {props.intl.formatMessage({
                                    id: 'Component.Report.Overspeed.SpeedLimit',
                                })}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Box>
                    <DrivingReportMap />
                </Box>
            </Box>
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
    drivingInfo: selectDrivingInfo(),
});
const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            closeDrivingReportModal,
            isImperial,
        },
        dispatch
    );
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DrivingReportModal))
);
