// @flow

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import Organization from './styles';
import dateFns from 'date-fns';
import moment from 'moment';
import Chip from '../Chip';

import TestOrganizationImage from 'assets/test-organization.png';

import type { ColumnType } from 'types';

type Props = {
    rowSpan: ?number,
    data: Object<any>,
    columns: Array<ColumnType>,
    showStopTime: *,
};

class OrganizationListItem extends React.PureComponent<Props> {
    dateFormat = (datestart, dateend) => {
        const text = this.props.intl.formatMessage({ id: 'containers.Reports.Generators.move.To' });

        if (dateFns.format(datestart, 'YYYY/MM/DD') === dateFns.format(dateend, 'YYYY/MM/DD')) {
            return `${dateFns.format(datestart, 'YYYY/MM/DD')} ${dateFns.format(
                datestart,
                'h:mm A'
            )}
            ${text} ${dateFns.format(dateend, 'h:mm A')}`;
        }
        return `${moment(datestart).format('YYYY/MM/DD - h:mm A')} ${text} ${moment(dateend).format(
            'YYYY/MM/DD - h:mm A'
        )}`;
    };

    hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    intToARGB = (i) => {
        let hex =
            ((i >> 24) & 0xff).toString(16) +
            ((i >> 16) & 0xff).toString(16) +
            ((i >> 8) & 0xff).toString(16) +
            (i & 0xff).toString(16);
        hex += '000000';
        return hex.substring(0, 6);
    };

    handleConvertUTC = (date) => {
        const convertedDate = moment(date)
            .local()
            .format('YYYY-MM-DD HH:mm:ss A');
        return convertedDate;
    };

    convertStringToColor = (str) => {
        if (!str) return '';
        str = str.replace(/\s+/g, '');
        return `#${this.intToARGB(this.hashCode(str))}`;
    };

    secondsToHms = (d) => {
        if (d == 0) return 0;
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);

        const hDisplay = h > 0 ? `${h} h, ` : '';
        const mDisplay = m > 0 ? `${m} m, ` : '';
        const sDisplay = s > 0 ? `${s} s` : '';
        return hDisplay + mDisplay + sDisplay;
    };

    hexToHSL = (H) => {
        // Convert hex to RGB first
        let r = 0;
        let g = 0;
        let b = 0;
        if (H.length == 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length == 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 100;
        g /= 280;
        b /= 100;
        const cmin = Math.min(r, g, b);
        const cmax = Math.max(r, g, b);
        const delta = cmax - cmin;
        let h = 0;
        if (delta == 0) h = 0;
        else if (cmax == r) h = ((g - b) / delta) % 6;
        else if (cmax == g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;
        h = Math.round(h * 60);
        if (h < 0) h += 360;

        return `hsl(${h},${80}%,${60}%)`;
    };

    render() {
        let rowSpan = 4;
        let showExtraRows = false;
        if (this.props.data.get('transitTime') != -1) {
            rowSpan = 5;
            showExtraRows = true;
        }
        let element = null;
        const dd = this.props.data.filter((data) => {
            if (data.size) {
                element = data;
                return true;
            }
            return false;
        });
        const items = dd
            .get(0)
            .get('history')
            .map((h, idx) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Organization.Griditem key={`${h.get('driver_name')}_${idx}`} rowSpan={rowSpan}>
                        <Organization.empty key="empty" />
                        <Organization.entranceDate key="entranceDate">
                            {h && this.handleConvertUTC(h.get('date_entrance'))}
                        </Organization.entranceDate>
                        <Organization.dateSortie key="dateSortie">
                            {h.get('date_exit') ? this.handleConvertUTC(h.get('date_exit')) : '-->'}
                        </Organization.dateSortie>
                        <Organization.driverName key="driverName">
                            {h.get('driver_name') || h.get('vehicle_numb')}
                        </Organization.driverName>
                        <Organization.timeSpent key="timeSpent">
                            {this.secondsToHms(h.get('time_spent'))}
                        </Organization.timeSpent>
                    </Organization.Griditem>
                );
            });
        return (
            <Organization>
                <Organization.Griditem rowSpan={rowSpan}>
                    <Organization.geoName key="geoName">
                        {(element && element.get('geo_name')) || element.get('vehicle_number')}
                    </Organization.geoName>
                    <Organization.empty key="empty" />
                    <Organization.empty key="empty" />
                    <Organization.empty key="empty" />
                </Organization.Griditem>
                {items}
                <Organization.Griditem rowSpan={rowSpan}>
                    <Organization.geoName key="Total">{'Total'}</Organization.geoName>
                    <Organization.empty key="empty" />
                    <Organization.empty key="empty" />
                    <Organization.empty key="empty" />
                    <Organization.timeSpent key="timeSpent">
                        <Chip type="totalTimeSpent">
                            {element && this.secondsToHms(element.get('timeInGeofence'))}
                        </Chip>
                    </Organization.timeSpent>{' '}
                </Organization.Griditem>
            </Organization>
        );
    }
}

export default injectIntl(OrganizationListItem);
