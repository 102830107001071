// @flow

import React from 'react';
import { Error } from './styles';

type Props = {
    text: string,
};

function ErrorMessage(props: Props) {
    return <Error>{props.text}</Error>;
}

export default ErrorMessage;
