// @flow

import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import MaintenanceStyle from './styles';
import { getVehicles } from '../../services/Vehicle/thunks';


import { selectFilteredVehicles, selectVehicles } from '../../services/Vehicle/selectors';
import VehicleDetail from './VehicleDetail';
import { injectIntl } from 'react-intl';

export const Maintenance = (props) => {
    const [isDataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (!isDataLoaded) {
            props.getVehicles()
                .then(() => {
                    setDataLoaded(true);
                });
        }
    }, []);

    return (

        <MaintenanceStyle>
            <VehicleDetail vehicles={props.selectedVehicles} isDataLoaded={isDataLoaded} />
        </MaintenanceStyle>
    );
};

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector({
    selectedVehicles: selectVehicles(),
    vehicles: selectFilteredVehicles(),
});

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getVehicles,
        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(Maintenance));
