import {
    updateBatchGeofence,
    retrieveGeofencesByBranch,
    updateGeofenceGeoJSON,
    updateGeofence,
    addGeofenceGeoJSON,
    deleteGeofence,
} from './resources';
import {
    startLoading,
    dismissLoading,
    receivedGeofencesSuccess,
    receivedGeofencesError,
    receivedDrawMode,
    receivedToggleShowGeofences,
    receivedCurrentGeofence,
    receivedClickedGeofenceData,
    receivedCreatingGeofenceMode,
    receivedSetGeofenceEditable,
    receivedUpdatedGeofenceData,
} from './actions';
import { ImmutableMap } from 'types';
import { parseJWT } from 'helpers';
import { changeCurrentRightSideMenu } from 'services/SideMenu/thunks';
import { pointsWithinPolygon, points } from '@turf/turf';

/**
 * Fetch Geofences by branch
 */
export const getGeofencesByBranch = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return retrieveGeofencesByBranch(token, branchId)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedGeofencesError());
            }
            dispatch(receivedGeofencesSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedGeofencesError());
            console.log(error);
        });
};

export const uploadGeofences = (data: *, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return updateBatchGeofence(token, branchId, data)
        .then((response) => {
            dispatch(getGeofencesByBranch());
            dispatch(dismissLoading());
            return true;
        })
        .catch((error) => {
            dispatch(dismissLoading());
            return false;
        });
};

export const updateGeofenceGeoJSONThunk = (data: *, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    //dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];
    const geofenceId = data.properties.element.get("geofenceId");
    return updateGeofenceGeoJSON(token, branchId, geofenceId, data)
        .then((response) => {
            //dispatch(dismissLoading());
            return true;
        })
        .catch((error) => {
            //dispatch(dismissLoading());
            return false;
        });
};

export const updateGeofenceData = (data: *, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];
    const geofenceId = data.geofenceId;

    return updateGeofence(token, branchId, geofenceId, data)
        .then((response) => {
            dispatch(getGeofencesByBranch());
            return true;
        })
        .catch((error) => {
            if (error.length > 0) {
                return error;
            } else {
                return false;
            }
        });
};

export const addGeofenceGeoJSONThunk = (data: *, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    //dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];
    return addGeofenceGeoJSON(token, branchId, data)
        .then((response: Object) => {
            if (response) {
                dispatch(getGeofencesByBranch()).then(()=> {
                    const geofenceId = response.data[0];
                    const geofences = getState().getIn(['geofence', 'geofences']);
                    let geofence = geofences;
                    if (geofences.get('name') === undefined) {
                        geofence = geofences.find((x)=> x.get('geofenceId') === geofenceId);
                    }
                    dispatch(receivedCurrentGeofence(geofence));
                });
            }
        //dispatch(receivedGeofencesSuccess(response.data));
    })
    .catch((error) => {
        console.log(error);
    });
};


export const deleteGeofenceThunk = (geofenceId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return deleteGeofence(token, branchId,geofenceId)
        .then((response) => {
            //dispatch(dismissLoading());
            dispatch(getGeofencesByBranch());
            dispatch(setCurrentGeofence(null));
            return true;
        })
        .catch((error) => {
            //dispatch(dismissLoading());
            return false;
        });
};

export const changeDrawMode = (drawMode) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(receivedDrawMode(drawMode));
};

export const handleToggleShowGeofences = () => (
    dispatch: ReduxDispatch,
    getState: Immutable
) => {
    dispatch(receivedToggleShowGeofences());
};

export const setCurrentGeofence = (geofenceId) => (
    dispatch: ReduxDispatch,
    getState: Immutable,
) => {
    if (geofenceId) {
        let geofence = geofenceId;
        if (typeof geofenceId === 'number') {
            geofence = geofenceId ? getState().getIn(['geofence', 'geofences'])
            .find( x => x.get('geofenceId') === geofenceId) : null;
        }
        dispatch(receivedCurrentGeofence(geofence));
    } else {
        dispatch(receivedCurrentGeofence(null));
    }
};

export const setClickedGeofenceData = (geofence, index, longitude, latitude) => (
    dispatch: ReduxDispatch,
    getState: Immutable,
) => {
    const data = {
        coordinate: [longitude, latitude],
        index: index,
        object: geofence,
    }
    dispatch(receivedClickedGeofenceData(data));
};

export const setCreatingGeofenceMode = (enabled) => (
    dispatch: ReduxDispatch,
    getState: Immutable,
) => {
    dispatch(receivedCreatingGeofenceMode(enabled));
};

export const setIsGeofenceEditable = (editable) => (
    dispatch: ReduxDispatch,
) => {
    dispatch(receivedSetGeofenceEditable(editable));
};

export const setFeatureCollectionData = (geofenceData) => (
    dispatch: ReduxDispatch,
    getState: Immutable
) => {
    dispatch(receivedUpdatedGeofenceData(geofenceData));
}

export const searchInPolygons = (longitude, latitude) => (
    dispatch: ReduxDispatch,
    getState: Immutable,
) => {
    if (!(latitude && longitude)) {
        return [];
    }
    const searchPoints = points([[longitude, latitude]]);
    const geoNames = [];
    const geofences = getState().getIn(['geofence', 'geofenceData', 'features']);
    if (geofences.length > 0) {
        geofences.forEach((geofence) => {
            if (geofence.geometry.coordinates[0].length > 3) {
                if (pointsWithinPolygon(searchPoints, geofence).features.length > 0) {
                    if(geofence.properties.name) {
                        geoNames.push(geofence.properties.name);
                    }
                }
            }
        });
    }

    return geoNames;
};
