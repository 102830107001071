import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import MapIcon from '@mui/icons-material/Map';
import { getMultipleEquipmentHistory, toggleMaskedPoints, toggleRouteMatching } from 'services/Vehicle/thunks';
import { selectMoveInfo } from 'services/Report/selectors';
import {
    selectVehicleHistory,
    selectMaskedPoints,
    selectRouteMatching, selectEventTypes, selectFilteredVehicles
} from 'services/Vehicle/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import mapboxgl from 'mapbox-gl';

import colors from "../../styles/colors";
import color from 'color';
import {
    mapPingData,
    setIsHistoryMode,
    setLineData,
    setRouteData,
    setHistoryMarker,
} from "../../views/MapPage/utils";
import WebMercatorViewport from "viewport-mercator-project";
import { selectLocale } from "../../services/Language/selectors";
import { selectMultipleSelect } from "../../services/SideMenu/selectors";
import 'mapbox-gl/dist/mapbox-gl.css';
import { toGeoJSON as polylineToGeoJSON } from "@mapbox/polyline";
import polyline from '@mapbox/polyline';

function MoveReportMap(props) {
    const MAPSTYLE_SATELLITE = 'mapbox://styles/geothentic/cktd2on1m0y0t17qhzyx546z8?optimize=true';
    const MAPSTYLE_DEFAULT = 'mapbox://styles/geothentic/ck3kbrwa21sd01coat5pliiq6';
    const [mapStyle, setMapStyle] = useState(MAPSTYLE_DEFAULT);
    const {
        vehicleHistory,
        vehicles,
        locale,
        isMaskedPoints,
        isRouteMatching,
        multipleSelect,
        eventTypes,
        moveInfo
    } = props;

    const [viewport, setViewport] = useState({
        latitude: 54.526,
        longitude: -105.2551,
        zoom: 1,
    });
    const mapRef = useRef(null);

    useEffect(() => {
        const info = moveInfo;
        props.getMultipleEquipmentHistory(info.vehicleId, info.periodDateFrom, info.periodDateTo, info.periodTimeFrom, info.periodTimeTo);
        return () => {
        };
    }, [props.moveInfo]);

    const onMapStyleChange = (style) => {
        // if (mapRef.current && mapRef.current.map) {
        //     mapRef.current.map.remove();
        // }
        // const map = new mapboxgl.Map({
        //     container: mapRef.current,
        //     style: style,
        //     center: [-74.69318, 46.15014],
        //     zoom: 13,
        // });
        // mapRef.current.map = map;
        mapRef.current.map.setStyle(style)
        setMapStyle(style);
        handlePolyLine(mapRef.current.map,'styledata');

    }
    const handleDisplayHistoryPoints = (e) => {
        const map = mapRef.current ? mapRef.current.map : null;
        if (map) {
            const layers = ['events'];
            layers.forEach((layer) => {
                if (map.getLayer(layer)) {
                    map.setLayoutProperty(layer, 'visibility', e ? 'visible' : 'none');
                }
            });
        }
    };

    const handleDisplayRouteMatching = (e) => {
        const map = mapRef.current ? mapRef.current.map : null;
        if (map) {
            if (!map || !map.getLayer('route')) return;
            if (!e) {
                map.setLayoutProperty('route', 'visibility', 'visible');
                map.setLayoutProperty('line', 'visibility', 'none');
                map.setLayoutProperty('arrows', 'visibility', 'none');
            } else {
                map.setLayoutProperty('route', 'visibility', 'none');
                map.setLayoutProperty('line', 'visibility', 'visible');
                map.setLayoutProperty('arrows', 'visibility', 'visible');
            }
        }
    };

    useEffect(() => {
        if (isMaskedPoints === false && localStorage.getItem('historyActivePoint') === 'true') {
            toggleMaskedPoints();
        }

        if (isRouteMatching === false && localStorage.getItem('routeActivePoint') === 'true') {
            toggleRouteMatching();
        }

        handleDisplayHistoryPoints(isMaskedPoints);
        handleDisplayRouteMatching(isRouteMatching);
    }, []);

    const ensureSourceExists = (map, sourceId, type, data) => {
        if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
                type: type,
                data: data || {
                    type: 'FeatureCollection',
                    features: [],
                }
            });
        }
    };
    const handlePolyLine = (map, event) => {
        map.on(event, function () {

            map.addSource('line', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                    geometry: {
                        type: 'LineString',
                        coordinates: [],
                    },
                },
            });

            map.addLayer({
                id: 'lineLayer',
                type: 'line',
                source: 'line',
                layout: {},
                paint: {
                    'line-width': 5,
                    'line-color': '#1E90FF',
                },
            });

            if (!map) return;
            ensureSourceExists(map, 'line', 'geojson');
            ensureSourceExists(map, 'route', 'geojson');
            if (map.getSource('line') && map.getSource('route')) {
                let data = {
                    type: 'FeatureCollection',
                    features: [],
                };
                map.addLayer({
                    id: 'arrows',
                    type: 'symbol',
                    source: 'line',
                    layout: {
                        visibility: 'visible',
                        'symbol-placement': 'line',
                        'text-field': '▶',
                        'text-size': 22,
                        'symbol-spacing': 70,
                        'text-keep-upright': false,
                    },
                    paint: {
                        'text-color': `${colors.routeArrow}`,
                        'text-halo-color': `${colors.routeArrowHalo}`,
                        'text-halo-width': 3,
                    },
                });
                let routeData = {
                    type: 'FeatureCollection',
                    features: [],
                };

                const bounds = new mapboxgl.LngLatBounds();

                if (vehicleHistory.size === 0) {
                    return setIsHistoryMode(false, map, 'handleRenderPolyline');
                } else {
                    setIsHistoryMode(true, map, 'handleRenderPolyline');
                }

                const vehicleIds = [];
                let page = 0;
                let lineData = null;
                let rData = null;
                let currLineDataFeature = null;
                let currRDataFeature = null;
                vehicleHistory.map((coordinates, index) => {
                    page = coordinates.get('pageNumber');
                    let startAndEndCoord = [];
                    // if (coordinates.get('waypoints').toJS()[0]) {
                    //     map.setCenter(coordinates.get('waypoints').toJS()[0])
                    // }

                    const item = vehicles.filter(
                        (vehicle) =>
                            vehicle && vehicle.get('id').toString() === coordinates.get('id').toString()
                    );
                    if (coordinates.get('hasNext')) {
                        vehicleIds.push(item.getIn([0, 'id']));
                    }
                    let rawLineString = {
                        coordinates: [],
                        type: 'LineString',
                    };

                    let matchingLineString = {
                        coordinates: [],
                        type: 'LineString',
                    };
                    if (coordinates.get('rawLineString')) {
                        rawLineString = polylineToGeoJSON(coordinates.get('rawLineString'));
                        matchingLineString = polylineToGeoJSON(coordinates.get('matchingLineString'));
                        rawLineString.coordinates = rawLineString.coordinates.map((co) => {
                            return [co[1], co[0]];
                        });
                        matchingLineString.coordinates = matchingLineString.coordinates.map((co) => {
                            return [co[1], co[0]];
                        });
                        startAndEndCoord = [matchingLineString.coordinates[0], matchingLineString.coordinates[matchingLineString.coordinates.length - 1]];
                        setHistoryMarker(map, startAndEndCoord);
                        //fitBounds
                        let minLng = matchingLineString.coordinates[0][0];
                        let maxLng = matchingLineString.coordinates[0][0];
                        let minLat = matchingLineString.coordinates[0][1];
                        let maxLat = matchingLineString.coordinates[0][1];
                        for (var i = 0; i < matchingLineString.coordinates.length; i++) {
                            var lng = matchingLineString.coordinates[i][0];
                            var lat = matchingLineString.coordinates[i][1];

                            minLng = (minLng > lng) ? lng : minLng;  // Update minimum longitude
                            maxLng = (maxLng < lng) ? lng : maxLng;  // Update maximum longitude
                            minLat = (minLat > lat) ? lat : minLat;  // Update minimum latitude
                            maxLat = (maxLat < lat) ? lat : maxLat;  // Update maximum latitude
                        }
                        map.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
                            padding: 100
                        });
                        lineData = map.getSource('line');
                        rData = map.getSource('route');
                        if (!lineData) {
                            return;
                        }
                        currLineDataFeature = lineData._data.features.find(
                            (f) => f.id === item.getIn([0, 'id'])
                        );
                        currRDataFeature = rData._data.features.find(
                            (f) => f.id === item.getIn([0, 'id'])
                        );
                        if (
                            lineData &&
                            lineData._data &&
                            currLineDataFeature &&
                            lineData._data.features.length > 0
                        ) {
                            bounds.extend(
                                lineData._data.features.find((f) => f.id === item.getIn([0, 'id']))
                                    .geometry.coordinates[0]
                            );
                            currLineDataFeature.geometry.coordinates = currLineDataFeature.geometry.coordinates.concat(
                                rawLineString.coordinates
                            );

                            data = lineData._data;
                            currRDataFeature.geometry.coordinates = currRDataFeature.geometry.coordinates.concat(
                                matchingLineString.coordinates
                            );
                            routeData = rData._data;
                        } else {
                            routeData.features.push({
                                type: 'Feature',
                                id: item.getIn([0, 'id']),
                                properties: {
                                    color: `${color(colors.green73, 'hex')
                                        .rotate(index * (360 / vehicleHistory.size))
                                        .hexString()}`,
                                    id: 'matchingLineString',
                                    coordinates: matchingLineString.coordinates,
                                },
                                geometry: rawLineString,
                            });
                            bounds.extend(matchingLineString.coordinates[0]);
                            data.features.push({
                                type: 'Feature',
                                id: item.getIn([0, 'id']),
                                properties: {
                                    color: `${color(colors.green73, 'hex')
                                        .rotate(index * (360 / vehicleHistory.size))
                                        .hexString()}`,
                                    id: 'rawLineString',
                                    coordinates: rawLineString.coordinates,
                                },
                                geometry: matchingLineString,
                            });
                        }
                    }

                    if (data.features.length === 0) {
                        return;
                    }
                    setLineData(data, map);
                    setRouteData(routeData, map);
                    const historyCoordinates = coordinates.get('historyCoordinates').toJS();

                    if (
                        item.getIn([0, 'name']) !== undefined &&
                        historyCoordinates
                    ) {
                        const pings = coordinates.get('historyCoordinates');
                        mapPingData(
                            pings,
                            eventTypes,
                            coordinates.get('id'),
                            map,
                            item.getIn([0, 'name']) || '',
                            locale
                        );
                    }

                });
                // // for travels that don't have matchingStrings
                // if (startAndEndCoord.length == 0) {
                //     console.log('TYO');
                //     if (vehicleHistory.getIn([0, 'historyCoordinates']).size !== 0) {
                //         vehicleHistory.getIn([0, 'historyCoordinates']).map((coord, index, arr) => {
                //             const originalCoordinate = coord.get('originalCoordinate'); // Use real positions for markers
                //             const longitude = originalCoordinate.get(0);
                //             const latitude = originalCoordinate.get(1);
                //             if (index === 0) {
                //                 coordinates.push([longitude, latitude]);
                //             }

                //             if (index + 1 === arr.size) {
                //                 coordinates.push([longitude, latitude]);
                //             }
                //         });
                //         setHistoryMarker(map, coordinates);
                //     }
                // }
                if (lineData && currLineDataFeature) {
                    currLineDataFeature.geometry.coordinates.forEach((coord) => {
                        bounds.extend(coord);
                    });
                }
                if (rData && currRDataFeature) {
                    currRDataFeature.geometry.coordinates.forEach((coord) => {
                        bounds.extend(coord);
                    });
                }

                handleDisplayHistoryPoints(isMaskedPoints);
                handleDisplayRouteMatching(isRouteMatching);

                let newViewport = {
                    ...viewport,
                };

                try {
                    if (
                        bounds &&
                        bounds.toArray()[0][0] === bounds.toArray()[1][0] &&
                        bounds.toArray()[0][1] === bounds.toArray()[1][1]
                    ) {
                        newViewport = {
                            ...viewport,
                            longitude: bounds.toArray()[0][0],
                            latitude: bounds.toArray()[0][1],
                            zoom: viewport.zoom,
                        };
                    } else {
                        const rightValue = 350;
                        const wmv = new WebMercatorViewport(viewport);
                        const { longitude, latitude, zoom } = wmv.fitBounds(bounds.toArray(), {
                            padding: {
                                top: 100,
                                bottom: 100,
                                left: 100,
                                right: rightValue,
                            },
                        });
                        newViewport = {
                            ...viewport,
                            longitude,
                            latitude,
                            zoom,
                        };
                    }
                    setViewport(newViewport);
                } catch (e) {
                    console.log('ERROR - handleRenderPolyline - try/catch');
                    console.log(e);
                    // @TODO: No valid histories
                }
            } else {
                console.log('Sources not available');
            }
        });
    }
    useEffect(() => {
        if (vehicleHistory.toJS().length == 0) return;
        if (!mapRef.current) return;
        const map = new mapboxgl.Map({
            container: mapRef.current,
            style: MAPSTYLE_DEFAULT,
            center: [-74.69318, 46.15014],
            zoom: 13,
        });
        console.log('map in start', map);
        mapRef.current.map = map;
        handlePolyLine(map, 'load');
        return () => {
            if (mapRef.current && mapRef.current.map) {
                mapRef.current.map.remove();
            }
        };
    }, [vehicleHistory]);


    return <div>
        <div ref={mapRef} style={{ height: '60vh', width: '73vw', borderRadius: '10px', borderColor: '#64748B' }} />
        {(mapStyle === MAPSTYLE_DEFAULT)?<IconButton className="icon-button" aria-label="satellite view" onClick={() => { onMapStyleChange(MAPSTYLE_SATELLITE) }} sx={{ position: 'absolute', bottom: '10%', right: '5%' }}>
            <SatelliteAltIcon />
        </IconButton>:
        <IconButton aria-label="normal view" className="icon-button" onClick={() => { onMapStyleChange(MAPSTYLE_DEFAULT) }} sx={{ position: 'absolute', bottom: '10%', right: '5%' }}>
        <MapIcon />
    </IconButton>
        }
    </div>

}

const mapStateToProps = createStructuredSelector({
    moveInfo: selectMoveInfo(),
    vehicleHistory: selectVehicleHistory(),
    locale: selectLocale(),
    isMaskedPoints: selectMaskedPoints(),
    isRouteMatching: selectRouteMatching(),
    multipleSelect: selectMultipleSelect(),
    eventTypes: selectEventTypes(),
    vehicles: selectFilteredVehicles(),
});
const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            getMultipleEquipmentHistory,
            toggleMaskedPoints,
            toggleRouteMatching,

        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(MoveReportMap)))
