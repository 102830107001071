// @flow

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';
import Fuse from 'fuse.js';

import CheckInput from 'components/CheckInput';
import SearchInput from 'components/SearchInput';
import Loader from 'components/Loader';

import { Container, VehicleList, VehicleItem, VehicleList1 } from './styles';
import colors from 'styles/colors';

import { getEquipment } from 'services/Equipment/thunks';

import { getVehicles } from 'services/Vehicle/thunks';
import {
    selectVehiclesLoading,
    selectTemperatureVehicles,
    selectVehicles,
} from 'services/Vehicle/selectors';

import {
    selectEquipmentPagination,
    selectEquipmentSearchResults,
} from 'services/Equipment/selectors';

import type { SearchResultsType, VehicleListType } from 'types';

type Props = {
    onCheckVehicles: (id: number) => void,
    checkedInputs: Array<number>,
    loading: boolean,
    equipment: VehicleListType,
};

type State = {
    vehicleSearchQuery: string,
    vehicleSearchResults: ImmutableList<any>,
    selectAll: boolean,
};

class ReportVehicleSearchComponent extends React.Component<Props, State> {
    state = {
        vehicleSearchQuery: '',
        vehicleSearchResults: fromJS([]),
        selectAll: false,
    };

    componentDidMount() {
        if (!this.props.equipment || this.props.equipment.size === 0) {
            const page =
                this.props.paginated && this.props.paginated.get('currentPage')
                    ? this.props.paginated.get('currentPage')
                    : 0;
            this.props.getVehicles('orca');
        }
    }

    handleSearchResults = (searchResults: SearchResultsType) => {
        this.setState({
            vehicleSearchQuery: searchResults.query,
            vehicleSearchResults: searchResults.results,
        });

    };

    handleOnChangeCheckInput = (id: number) => () => {
        this.props.onCheckVehicles(id);
    };

    handleOnClickToggleAll = () => {
        this.setState(
            (prevState) => ({
                selectAll: !prevState.selectAll,
            }),
            () =>
                this.state.selectAll
                    ? this.props.onSelectAllVehicles(this.state.vehicleSearchResults)
                    : this.props.onUnselectAllVehicles()
        );
    };

    renderEquipment = (equipment) => {
        if (equipment.size === 0 && this.state.vehicleSearchQuery === '') {
            return <Loader loading />;
        }

        const equipmentItems = equipment
            .sort((a, b) => {
                const nameA = a.get('name');
                const nameB = b.get('name');
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            })
            .map((e, index) => (
                <VehicleItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${e.get('id')}-${index}`}
                    onClick={this.handleOnChangeCheckInput(e.get('id'))}
                >
                    {this.props.isTempReports === undefined ? (
                        <CheckInput
                            value={this.props.checkedInputs.indexOf(e.get('id')) >= 0}
                            borderColor={colors.green63}
                        />
                    ) : (
                        <input
                            id={''}
                            name={'vehicule'}
                            type={'radio'}
                            checked={e.get('id') === this.props.selectedIdVehicle}
                            // onChange={onChange(record, index)}
                            // value={selectedAlarm}l
                            // defaultChecked={index === 0}
                        />
                    )}

                    <VehicleItem.Name>{e.get('name')}</VehicleItem.Name>
                </VehicleItem>
            ));

        const selectAll = (
            <VehicleItem key={-1} onClick={this.handleOnClickToggleAll}>
                <CheckInput value={this.state.selectAll} borderColor={colors.green63} />
                <VehicleItem.Name>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.SelectAll'} />
                </VehicleItem.Name>
            </VehicleItem>
        );
        return this.props.isTempReports === undefined
            ? equipmentItems.size > 0 ?  [selectAll, ...equipmentItems] : <FormattedMessage id={'components.VehicleList.NoResults'} />
            : equipmentItems.size > 0 ? [...equipmentItems] : <FormattedMessage id={'components.VehicleList.NoResults'} />;
    };

    render() {

        let equipment = this.props.equipment
        if( this.state.vehicleSearchQuery && this.state.vehicleSearchResults.size > 0){
            equipment = this.state.vehicleSearchResults
        }else if(this.state.vehicleSearchQuery !== ''){
            equipment = this.state.vehicleSearchResults
        }

        return (
            <Container>
                <SearchInput
                    onSearchResults={this.handleSearchResults}
                    searchKeys={['name']}
                    data={this.props.equipment}
                    threshold={0.1}
                />
                {this.props.isTempReports === undefined ? (
                    <VehicleList>{this.renderEquipment(equipment)}</VehicleList>
                ) : (
                    <VehicleList1>{this.renderEquipment(equipment)}</VehicleList1>
                )}
            </Container>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    paginated: selectEquipmentPagination(),
    equipmentSearchResults: selectEquipmentSearchResults(),
    loading: selectVehiclesLoading(),
    temperatureVehicles: selectTemperatureVehicles(),
    vehicle: selectVehicles(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getEquipment,
            getVehicles,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ReportVehicleSearchComponent));
