/* eslint-disable flowtype/require-parameter-type,react/jsx-no-bind */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import Star from '@material-ui/icons/Star';

import React, { useCallback, useEffect, useState } from 'react';
import useStyles from '../../styles/GroupList.styles';
import TransitionsModal from '../../utils/TransitionsModal';
import GroupCreation from '../creation/GroupCreation';
import useAgent from '../../../../hooks/useAgent';
import groupsAgent from '../../agents/groupAgent';
import List from '@material-ui/core/List';
import { Message } from '../../utils/Message';
import { Loading } from '../../utils/Loading';
import AlertDialog from '../../utils/AlertDialog';
import SearchField from '../../utils/SearchField';
import { formatString } from '../../utils/String';
import { useIntlContext } from '../../context/IntlContext';
import { getGroupName } from '../../helpers/GroupHelpers';

const GroupList = ({ isLoading, items, onItemClick, onDeleteGroup, onEditedGroup }) => {
    const classes = useStyles();
    const { intl } = useIntlContext();
    const [groups, setGroups] = useState(null);
    const [groupCount, setGroupCount] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteGroup, setDeleteGroup] = useState(null);
    const [isDefaultGroupExists, setIsDefaultGroupExists] = useState(false);
    const [editGroup, editedGroup, isEditingGroups] = useAgent(groupsAgent.editGroup);
    const [searchGroupValue, setSearchGroupValue] = useState(null);

    const handleClick = useCallback(
        (item) => {
            setSelectedItem(item);
            onItemClick(item);
        },
        [onItemClick]
    );

    const handleDeleteGroup = useCallback((groupId) => {
        setDeleteGroup(null);
        setSelectedItem(null);
        onDeleteGroup(groupId);
    });

    const handleEditGroup = useCallback(
        (group, id) => {
            if (group && !isEditingGroups) {
                editGroup(group, id);
            }
        },
        [isEditingGroups]
    );

    const handleCancelDelete = useCallback(() => setDeleteGroup(false));

    const handleSearchChange = useCallback((event) => setSearchGroupValue(event.target.value));

    useEffect(
        () => {
            if (editedGroup) {
                setIsDefaultGroupExists(false);
                onEditedGroup();
            }
        },
        [editedGroup]
    );
    useEffect(
        () => {
            if (items) {
                setSelectedItem(items[0]);
            }
        },
        [items, setSelectedItem]
    );

    useEffect(
        () => {
            if (items) {
                if (!searchGroupValue) setGroups(items);
                if (searchGroupValue) {
                    setGroups(items.filter(({ name }) => formatString(getGroupName(intl,name)).includes(formatString(searchGroupValue))));
                }
            }
        },
        [searchGroupValue, items]
    );

    useEffect(
        () => {
            if (groups) {
                setGroupCount(groups.filter(({ isDefault }) => !isDefault).length);
            }
        },
        [groups]
    );

    if (items && !items.length && !isLoading) {
        return (
            <div className={classes.list}>
                <Message id={'views.Groups.NoGroup'} />
            </div>
        );
    }

    const buttonEdit = useCallback(
        ({ openModal }) => (
            <IconButton edge="end" onClick={openModal}>
                <Edit />
            </IconButton>
        ),
        []
    );

    if (isLoading)
        return (
            <div className={classes.list}>
                <Loading />
            </div>
        );

    const DeleteDialog = () =>
        deleteGroup && (
            <AlertDialog
                onCancel={handleCancelDelete}
                onAgree={() => handleDeleteGroup(deleteGroup.groupId)}
                open={deleteGroup}
                title={deleteGroup.name}
                message={'views.Groups.DeleteMessage'}
                cancelText={'views.Groups.Cancel'}
                agreeText={'views.Groups.Yes'}
            />
        );

    const DefaultGroup = ({ item }) => {
        if (item.isDefault) {
            setIsDefaultGroupExists(true);
        }
        return item.isDefault ? (
            <ListItemText
                className={classes.groupListTitle}
                classes={{ primary: classes.listItemText }}
                primary={`${intl.formatMessage({ id: 'views.Groups.Groups' })} (${groupCount})`}
            />
        ) : (
            <React.Fragment />
        );
    };

    const GroupItem = ({ item }) => (
        <React.Fragment>
            <ListItem
                key={item.groupId}
                onClick={() => handleClick(item.groupId)}
                className={classes.item}
                button
                selected={item.groupId === selectedItem}>
                {item.isDefault && (
                    <ListItemIcon>
                        <Star color="primary" fontSize="small" />
                    </ListItemIcon>
                )}
                <ListItemText primary={getGroupName(intl, item.name)} classes={{ primary: item.isDefault && classes.default }} />
                <ListItemSecondaryAction>
                    <TransitionsModal button={buttonEdit}>
                        {({ closeModal }) => (
                            <GroupCreation
                                onSubmit={(group) => handleEditGroup(group, item.groupId)}
                                closeModal={closeModal}
                                title="views.Groups.EditGroup"
                                textButton="components.Modals.Save"
                                selectedGroup={item}
                            />
                        )}
                    </TransitionsModal>
                    { !item.isDefault ? (
                        <IconButton edge="end" onClick={() => setDeleteGroup(item)}>
                            <DeleteForever />
                        </IconButton>
                        ) : null
                    }
                </ListItemSecondaryAction>
            </ListItem>
            <DefaultGroup item={item} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <SearchField onChange={handleSearchChange} />
            <List className={classes.list} dense>
                {groups &&
                    groups
                        .sort((a, b) => {
                            const a_name = getGroupName(intl, a.name)
                            const b_name = getGroupName(intl, b.name)
                            return a_name.localeCompare(b_name)
                        })
                        .sort((a, b) => b.isDefault - a.isDefault)
                        .map((item) => <GroupItem key={item.groupId} item={item} />)}
            </List>
            <DeleteDialog />
        </React.Fragment>
    );
};

export default GroupList;
