// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import { Link } from 'react-router-dom';
export const Equipment = styled.div`
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
`;

Equipment.Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columnSpan || '4'}, 1fr);
    height: 100%;
`;

Equipment.Item = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;

Equipment.Options = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

Equipment.Link = styled(Link)`
    text-decoration: none;
    color: ${colors.black};
`;
