// @flow

export const colors = {
    // Grayscale
    black: '#000000',
    gray10: '#1a1a1a',
    gray20: '#333333',
    gray30: '#4d4d4d',
    gray40: '#666666',
    gray50: '#7f7f7f',
    gray60: '#999999',
    gray70: '#b3b3b3',
    gray80: '#cccccc',
    grayDD: '#dddddd',
    gray90: '#e5e5e5',
    gray95: '#fefefe',
    white: '#ffffff',
    bleu10: '#4499b8',
    bleu1F: '#1f1994',
    blueE7: '#E7EAF6',

    // applicaiton colors
    green73: '#73D071',
    green77: '#77DD77', // not used
    green40: '#40AE49',
    green63: '#63ac37',
    green82: '#82bd5f',
    green34: '#345b1d',
    green4b: '#4b722e',
    green62: '#62ab36',
    green61: '#61aa35',
    green57: '#579730',
    green52: '#528F2D',
    green5F: '#5fa833',
    green5d: '#5da233',
    greenB4: '#B4E1B3',
    gray46: '#46575F',
    grayF8: '#F8F9FA',
    grayE6: '#E6E9EC',
    grayE3: '#e3e6e8',
    grayC8: '#c8cfd2',
    gray88: '#888888',
    gray6C: '#6c757d',
    gray87: '#878787',
    gray75: '#757575',
    grayE4: '#e4e7e9',
    grayFE: '#5e5e5e',
    grayF7: '#f7f8f9',
    grayE0: '#e0e0e0',
    grayED: '#ededed',
    orange: '#d86c00',
    yellow: '#FFC107',
    yellowFFE: '#FFE58F',
    yellowFFF: '#FFFBE6',
    yellowDE: '#deb10d',
    yellowFF: '#ffc108',
    yellowA: '#FFC670',
    primaryGreen: '#93D385',
    sideMenuGreen: '#62ab36',
    headerBlue: '#152A35',
    sideMenuSplit: '#68b46e',
    sideMenuFooter: '#0e2a36',
    sideMenuBorder: '#e6e9eb',
    loginPane: '#10252F',
    loginPaneSplit: '#20353E',
    offwhite: '#F8F9F9',
    whiteF7: '#F7F8FA',
    red: '#D60000',
    redA: '#EC0909',
    redE: '#f04d37',
    redT: '#ee7564',
    redCB: '#CB1E60',
    redRgba: 'rgba(255, 30, 0,0.50)',
    redDF: '#df789f',
    redFA: '#FAE5E5',
    redInacControl: '#FA7575',
    greenActControl: '#A2D883',
    transparentBlack4: 'rgba(0, 0, 0, 0.4)',
    transparentBlack58: 'rgba(0, 0, 0, 0.58)',
    transparent: 'transparent',
    pink: '#d1088b',

    routeArrow: '#504136',
    routeArrowHalo: '#FFFFFF',

    // sidemenu split
    splitWhite0: 'rgba(255, 255, 255, 0)',
    splitWhite1: 'rgba(255, 255, 255, 1)',

    splitBlack0: 'rgba(0, 0, 0, 0)',
    splitBlack1: 'rgba(0, 0, 0, 1)',

    splitGrayReports: '#0D2935',

    status: {
        green: '#75A94A',
        red: '#C7291C',
        orange: '#F45D2B',
        plum: '#DDA0DD',
    },
};

export default colors;
