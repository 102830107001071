import styled from 'styled-components';
import colors from 'styles/colors';

const VehicleDetailManagement = styled.div`
    button, button:focus, button:hover {
        background: ${colors.green57};
        color: ${colors.whiteF7};
        border-color: ${colors.green57};
        height: 40px;
        width: 20%;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #28a745;
        border-color: #28a745;
    }

    .ant-pagination-next > button {
        width: 30px;
        height: 30px;
    }

    .ant-pagination-prev > button {
        width: 30px;
        height: 30px;
    }
    .ant-pagination-item-active{
        border-color: #28a745
    }
    .ant-pagination-item:focus , .ant-pagination-item:hover  {
        border-color: #28a745
    }
`;
VehicleDetailManagement.SearchContainer = styled.div`
    display: flex;

    div {
        width: 100%;
        padding: 0 12px 0 17px;

        input {
            font-size: 14px;
        }
    }

`;
VehicleDetailManagement.Columns = styled.div`
    .fa {
        font-size: 15px;
        margin-left: 5px;
        cursor: pointer;
    }

    td:nth-child(2), td:nth-child(3) {
        > div > div {
            display: flex;
            justify-content: space-between;
            width: 60%;
        }
    }
`;

VehicleDetailManagement.ShowAlarm = styled.div`
    a {
        color: ${colors.green57};
    }

    .fa-bell {
        font-size: 20px;
        padding-left: 20px;
    }
`;

VehicleDetailManagement.IconEdit = styled.div`
    .fa-edit {
        color: ${colors.green57};
    }

`;
VehicleDetailManagement.Garage = styled.div`
    display: flex;

`;
VehicleDetailManagement.Checkbox = styled.div`
    width: 150px;
    .ant-checkbox{
        display: none;
    }
    .ant-checkbox + span {
        color: ${colors.green57};
    }
    .ant-checkbox + span :hover {
        cursor:pointer;
        color:${colors.green57};
        text-decoration:underline;
    }

`;
export default VehicleDetailManagement;
