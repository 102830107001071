// @flow

// TYPES
import type { Coordinates, NewVehicleType } from 'types';
import { ControlColors } from '../../types';

// REDUX TYPES
export const GET_VEHICLES_SUCCESS = 'app/services/Vehicle/GET_VEHICLES_SUCCESS';
export const GET_VEHICLES_ERROR = 'app/services/Vehicle/GET_VEHICLES_ERROR';
export const GET_VEHICLES_EVENT_TYPES = 'app/services/Vehicle/GET_VEHICLES_EVENT_TYPES';
export const GET_VEHICLE_HISTORY_SUCCESS = 'app/services/Vehicle/GET_VEHICLE_HISTORY_SUCCESS';
export const TOGGLE_VEHICLE_EQUIPMENT = 'app/services/Vehicle/TOGGLE_VEHICLE_EQUIPMENT';
export const MAP_SELECTED_EQUIPMENT = 'app/services/Vehicle/MAP_SELECTED_EQUIPMENT';
export const CLEAR_MAP_SELECTED_EQUIPMENT = 'app/services/Vehicle/CLEAR_MAP_SELECTED_EQUIPMENT';
export const SET_VEHICLE_FILTER = 'app/serives/Vehicle/SET_VEHICLE_FILTER';
export const GET_VEHICLE_HISTORY_ERROR = 'app/services/Vehicle/GET_VEHICLE_HISTORY_ERROR';
export const GET_EMPTY_HISTORY = 'app/services/Vehicle/GET_EMPTY_HISTORY';
export const GET_VEHICLE_DATA_ERROR = 'app/services/Vehicles/GET_VEHICLE_DATA_ERROR';
export const GET_FLEET_EQUIPMENT_ERROR = 'app/services/Vehicle/GET_FLEET_EQUIPMENT_ERROR';
export const LOADING_VEHICLES = 'app/services/Vehicles/LOADING_VEHICLES';
export const LOADING_VEHICLE = 'app/services/Vehicles/LOADING_VEHICLE';
export const LOADING_VEHICLE_HISTORY = 'app/services/Vehicles/LOADING_VEHICLE_HISTORY';
export const LOADING_EQUIPMENT_POPUP = 'app/services/Vehicles/LOADING_EQUIPMENT_POPUP';
export const GET_VEHICLE_REQUEST = 'app/services/Vehicles/GET_VEHICLE_REQUEST';
export const DISMISS_MAP_ERROR = 'app/services/Vehicle/DISMISS_MAP_ERROR';
export const DISMISS_MAP_EMPTY_HISTORY = 'app/services/Vehicle/DISMISS_MAP_EMPTY_HISTORY';
export const GET_EVENT_GROUPS_SUCCESS = 'app/services/Vehicle/GET_EVENT_GROUPS_SUCCESS';
export const CLEAR_VEHICLE_HISTORY = 'app/services/Vehicle/CLEAR_VEHICLE_HISTORY';
export const CLEAR_VEHICLES = 'app/services/Vehicles/CLEAR_VEHICLES';
export const TOGGLE_MASKED_POINTS = 'app/services/Vehicle/TOGGLE_MASKED_POINTS';
export const TOGGLE_ROUTE_MATCHING = 'app/services/Vehicle/TOGGLE_ROUTE_MATCHING';
export const CHANGE_VEHICLES_SORT_ORDER = 'app/services/Vehicle/CHANGE_VEHICLES_SORT_ORDER';
export const GET_VEHICLE_EXT_WEB_H = 'app/services/Vehicle/GET_VEHICLE_EXT_WEB_H';
export const CHANGE_WEB_HOOK_STATE_SUCCESS = 'app/services/Vehicle/CHANGE_WEB_HOOK_SUCESS';
export const CHANGE_WEB_HOOK_STATE_LOADING = 'app/services/Vehicle/CHANGE_WEB_HOOK_LOADING';
export const CHANGE_WEB_HOOK_STATE_ERROR = 'app/services/Vehicle/CHANGE_WEB_HOOK_ERROR';
export const GET_VEHICLE_HISTORY_DETAIL_SUCCESS =
    'app/services/Vehicle/GET_VEHICLE_HISTORY_DETAIL_SUCCESS';
export const GET_FLEET_EQUIPMENT_SUCCESS = 'app/services/vehicles/GET_FLEET_EQUIPMENT_SUCCESS';
export const CLEAR_VEHICLE_HISTORY_DETAIL = 'app/services/vehicles/CLEAR_VEHICLE_HISTORY_DETAIL';
export const CLEAR_FLEET_EQUIPMENT = 'app/services/vehicles/CLEAR_FLEET_EQUIPMENT';
export const TOGGLE_LOADED_VEHICLE_HISTORY = 'app/services/vehicles/TOGGLE_LOADED_VEHICLE_HISTORY';
export const REALTIME_DATA_RECEIVED = 'app/services/vehicles/REALTIME_DATA_RECEIVED';
export const STOP_LOADING = 'app/services/Vehicles/STOP_LOADING';
export const GET_CONTROL_COLORS = 'app/services/Vehicles/GET_CONTROL_COLORS';
export const GET_GARAGE_MODE = 'app/services/Vehicles/GET_GARAGE_MODE';
// MAIN ACTIONS
/**
 *
 */
export const receivedClearVehicles = () => ({
    type: CLEAR_VEHICLES,
});

export const receivedControlColors = (controlColors) => ({
    type: GET_CONTROL_COLORS,
    controlColors,
});

export const receivedGarageMode = (garageMode) => ({
    type: GET_GARAGE_MODE,
    garageMode,
});

/**
 *
 */
export const receivedClearVehicleHistory = () => ({
    type: CLEAR_VEHICLE_HISTORY,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedVehiclesSuccess = (vehicles, internalResponse): GenericActionType => ({
    type: GET_VEHICLES_SUCCESS,
    vehicles,
    internalResponse,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedVehiclesEventTypes = (eventTypes): GenericActionType => ({
    type: GET_VEHICLES_EVENT_TYPES,
    eventTypes,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedVehicleHistorySuccess = (
    waypoints: Coordinates,
    equipmentId: number,
    historyCoordinates: Coordinates,
    matchingLineString,
    rawLineString,
    pageNumber,
    hasNext
): GenericActionType => ({
    type: GET_VEHICLE_HISTORY_SUCCESS,
    waypoints,
    equipmentId,
    historyCoordinates,
    matchingLineString,
    rawLineString,
    pageNumber,
    hasNext,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedToggleShowVehicleHistory = (equipmentId: number): GenerateActionType => ({
    type: TOGGLE_VEHICLE_EQUIPMENT,
    equipmentId,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedMapPopupEquipment = (
    vehicles: ?Array<VehicleType>,
    coordinate: ?Coordinates,
    history: ?boolean,
    index: ?number
): GenericActionType => ({
    type: MAP_SELECTED_EQUIPMENT,
    vehicles,
    coordinate,
    history,
    index,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedClearMapPopupEquipment = (): GenericActionType => ({
    type: CLEAR_MAP_SELECTED_EQUIPMENT,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedSetFilter = (filterEvents: Array<string>): GenericActionType => ({
    type: SET_VEHICLE_FILTER,
    filterEvents,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedEquipmentHistoryError = (): GenericActionType => ({
    type: GET_VEHICLE_HISTORY_ERROR,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedEmptyHistory = (): GenericActionType => ({
    type: GET_EMPTY_HISTORY,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedDissmissedMapError = (): GenericActionType => ({
    type: DISMISS_MAP_ERROR,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedDissmissedMapEmptyHistory = (): GenericActionType => ({
    type: DISMISS_MAP_EMPTY_HISTORY,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedVehicleDataError = (): GenericActionType => ({
    type: GET_VEHICLE_DATA_ERROR,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedVehiclesError = (error): GenericActionType => ({
    type: GET_VEHICLES_ERROR,
    error,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedFleetEquipmentError = (error): GenericActionType => ({
    type: GET_FLEET_EQUIPMENT_ERROR,
    error,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedVehicleRequest = (): GenericActionType => ({
    type: GET_VEHICLE_REQUEST,
});

/**
 *
 * @param {*} vehicles
 */
export const startLoading = (): GenericActionType => ({
    type: LOADING_VEHICLES,
});

/**
 *
 * @param {*} vehicles
 */
export const startLoadingVehicleHistory = (): GenericActionType => ({
    type: LOADING_VEHICLE_HISTORY,
});

export const stopLoading = (): GenericActionType => ({
    type: STOP_LOADING,
});

/**
 *
 * @param {*} vehicles
 */
export const startLoadingVehicle = (): GenericActionType => ({
    type: LOADING_VEHICLE,
});

/**
 *
 * @param {*} vehicles
 */
export const startLoadingEquipmentPopup = (): GenericActionType => ({
    type: LOADING_EQUIPMENT_POPUP,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedEventGroupsSuccess = (eventGroups: EventGroupsType): GenericActionType => ({
    type: GET_EVENT_GROUPS_SUCCESS,
    payload: eventGroups,
});

/**
 *
 * @param {*} vehicles
 */
export const receivedMaskedPoints = (): GenericActionType => ({
    type: TOGGLE_MASKED_POINTS,
});

export const receivedRouteMatching = (): GenericActionType => ({
    type: TOGGLE_ROUTE_MATCHING,
});

export const receivedVehiclesSortOrder = (sort: string, order: string) => ({
    type: CHANGE_VEHICLES_SORT_ORDER,
    sort,
    order,
});

export const receivedVehicleExternalWebHookSuccess = (vehicleTypes: NewVehicleType) => ({
    type: GET_VEHICLE_EXT_WEB_H,
    vehicleTypes,
});

export const receivedChangeWebHkSuccess = (response: string) => ({
    type: CHANGE_WEB_HOOK_STATE_SUCCESS,
    response,
});

export const receivedChangeWebHkError = (error: string) => ({
    type: CHANGE_WEB_HOOK_STATE_ERROR,
    error,
});

export const receivedChangeWebHkLoading = () => ({
    type: CHANGE_WEB_HOOK_STATE_LOADING,
});

export const receivedVehicleHistoryDetailSuccess = (vehicleHistory: vehicle) => ({
    type: GET_VEHICLE_HISTORY_DETAIL_SUCCESS,
    vehicleHistory,
});

export const receivedFleetEquipmentSuccess = (
    fleetEquipment: vehicle,
    nextPage: number,
    isTempReport: boolean
) => ({
    type: GET_FLEET_EQUIPMENT_SUCCESS,
    fleetEquipment,
    nextPage,
    isTempReport,
});

export const receivedClearVehicleHistoryDetail = (vehicleHistory: vehicle) => ({
    type: CLEAR_VEHICLE_HISTORY_DETAIL,
    vehicleHistory,
});

export const receivedClearFleetEquipment = (fleetEquipment: vehicle) => ({
    type: CLEAR_FLEET_EQUIPMENT,
    fleetEquipment,
});

export const receivedToggleVehicleHistory = () => ({
    type: TOGGLE_LOADED_VEHICLE_HISTORY,
});

export const realtimeDataReceived = (data): GenericActionType => ({
    type: REALTIME_DATA_RECEIVED,
    data,
});
