// @flow

import { fromJS } from 'immutable';
import {
    TOGGLE_SIDEMENU,
    SHOW_SIDEMENU,
    HIDE_SIDEMENU,
    TOGGLE_RESIZE_MAP,
    DO_RESIZE_MAP,
    TOGGLE_ACTIVEBAR,
    SHOW_ACTIVEBAR,
    HIDE_ACTIVEBAR,
    SHOW_FLEET_OVERVIEW,
    HIDE_FLEET_OVERVIEW,
    SET_MULTIPLE_SELECT,
    SET_SELECTED_VEHICLES,
    SET_SELECTED_VEHICLES_DATA,
    TOGGLE_RIGHT_SIDEMENU,
    SET_LANG_UNIT,
} from './actions';
import screens from 'styles/screen';

import type { GenericActionType } from 'types';

const smallScreen = screens.small.replace('px', '');

const initialState = fromJS({
    isActiveBarOpen: false,
    isSideMenuOpen: !(window.innerWidth < smallScreen),
    shouldResizeMap: true,
    isFleetOverviewOpen: true,
    multipleSelect: false,
    isLangUnitConfigured:false,
    selectedVehicles: {},
    selectedVehiclesData: [],
    currentRightSideMenu: 'closed',
});

function sideMenuServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case TOGGLE_SIDEMENU: {
            const shouldResizeMap = state.get('shouldResizeMap');

            return state
                .set('isSideMenuOpen', !state.get('isSideMenuOpen'))
                .set('shouldResizeMap', !shouldResizeMap);
        }
        case SHOW_SIDEMENU: {
            return state.set('isSideMenuOpen', true).set('shouldResizeMap', true);
        }
        case HIDE_SIDEMENU: {
            return state.set('isSideMenuOpen', false).set('shouldResizeMap', true);
        }
        case TOGGLE_RESIZE_MAP: {
            return state.set('shouldResizeMap', !state.get('shouldResizeMap'));
        }
        case DO_RESIZE_MAP: {
            return state.set('shouldResizeMap', true);
        }
        case TOGGLE_ACTIVEBAR: {
            return state.set('isActiveBarOpen', !state.get('isActiveBarOpen'));
        }
        case SHOW_ACTIVEBAR: {
            return state.set('isActiveBarOpen', true);
        }
        case HIDE_ACTIVEBAR: {
            return state.set('isActiveBarOpen', false);
        }
        case SHOW_FLEET_OVERVIEW: {
            return state.set('isFleetOverviewOpen', true);
        }
        case HIDE_FLEET_OVERVIEW: {
            return state.set('isFleetOverviewOpen', false);
        }
        case SET_MULTIPLE_SELECT: {
            return state.set('multipleSelect', action.payload);
        }
        case SET_SELECTED_VEHICLES: {
            return state.set('selectedVehicles', fromJS(action.payload));
        }
        case SET_SELECTED_VEHICLES_DATA: {
            return state.set('selectedVehiclesData', fromJS(action.payload));
        }
        case TOGGLE_RIGHT_SIDEMENU: {
            return state.set('currentRightSideMenu', action.currentRightSideMenu);
        }
        case SET_LANG_UNIT: {
            return state.set('isLangUnitConfigured', true);
        }
        default:
            return state;
    }
}

export default sideMenuServiceReducer;
