import styled from 'styled-components';
import colors from 'styles/colors';

const AlarmManagementStyles = styled.div`
    width: 90%;
    margin: auto;

    .title {
        font-weight: 500;
        margin-bottom: 15px;
    }

`;
AlarmManagementStyles.SearchContainer = styled.div`
    display: flex;

    div {
        width: 100%;
        padding: 0 12px 0 17px;

        input {
            font-size: 14px;
        }
    }

    Button {
        margin-left: 10px;
    }
`;
AlarmManagementStyles.CheckBox = styled.div`

    input[type='radio']:checked:after {
        width: 12px;
        height: 12px;
        border-radius: 15px;
        bottom: 4px;
        position: relative;
        background-color: ${colors.green57};
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid ${colors.green57};
    }

    input[type='radio'] {
        margin-right: 8px;
    }

    input[type='text'] {
        width: 250px;

    }
`;

AlarmManagementStyles.Btn = styled.div`
    display: flex;
    justify-content: space-evenly;

    Button:nth-child(2) {
        background-color: ${colors.redA};
        border-color: ${colors.redA};
    }


`;

export default AlarmManagementStyles;
