// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectUser } from 'services/Authentication/selectors';
import { selectLocale } from 'services/Language/selectors';

import { deleteUser, createUser, editUser } from 'services/User/thunks';

import {
    getBranchData,
    editBranch,
    deleteBranch,
    deleteBranchEquipment,
} from 'services/Branch/thunks';

import { createDivision } from 'services/Division/thunks';

import { selectCurrentBranch } from 'services/Branch/selectors';

import { DELETE_BRANCH_USER } from 'services/Branch/actions';

import {
    toggleOrganizationModal,
    toggleUserModal,
    toggleEquipmentModal,
} from 'services/Modal/thunks';
import { selectModalType, selectModalAction } from 'services/Modal/selectors';
import {
    MODAL_TYPE,
    CREATE_DIVISION,
    CREATE_BRANCH_USER,
    EDIT_BRANCH,
    EDIT_BRANCH_USER,
    CREATE_BRANCH_EQUIPMENT,
    EDIT_BRANCH_EQUIPMENT,
    ASSOCIATE_EQUIPMENT,
} from 'services/Modal/actions';

import {
    createEquipment,
    associateBranchEquipment,
    editBranchEquipment,
    deleteEquipment,
} from 'services/Equipment/thunks';

import DashboardDataTable from 'components/DashboardDataTable';
import TextWithBadge from 'components/TextWithBadge';
import SelectField from 'components/SelectField';

import DetailHeader from 'components/DetailHeader';
import Modal from 'components/Modals';
import OrganizationModal from 'components/Modals/CreateOrganizationModal';
import UserModal from 'components/Modals/CreateUserModal';
import EquipmentModal from 'components/Modals/EquipmentModal';
import UserDashboardItem from 'components/DashboardDataUserItem';

import EquipmentDashboardItem from 'components/DashboardEquipmentItem';

import DeletedMessage from 'components/DeletedMessage';

import { SubOrganization } from 'styles/organization';
import colors from 'styles/colors';
import { Split } from 'styles/common';

import type { OrganizationType } from 'types';

type Props = {
    branch: OrganizationType,
    toggleOrganizationModal: (action: string) => void,
    toggleUserModal: (action: string, userId: ?number) => void,
};

export class BranchPageComponent extends React.Component<Props> {
    componentDidMount() {
        // fetch branch data if it does not exist yet.
        let token;
        if (this.props.user) {
            token = this.props.user.get('token');
        } else if (sessionStorage.getItem('api_token')) {
            token = sessionStorage.getItem('api_token');
        }

        if (token) {
            this.props.getBranchData(
                this.props.match.params.organizationId,
                this.props.match.params.branchId,
                token,
                this.props.locale
            );
        }
    }

    handleOnClickViewMoreUsers = () => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const usersURL = `/${
            this.props.intl.locale
        }/interne/orgs/${organizationId}/branch/${branchId}/users`;
        this.props.history.push(usersURL);
    };

    handleOnClickDivision = (divisionId: number) => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionUrl = `/${
            this.props.intl.locale
        }/interne/orgs/${organizationId}/branch/${branchId}/division/${divisionId}`;

        this.props.history.push(divisionUrl);
    };

    handleOnChangeAction = (value: string) => {
        if (value === CREATE_DIVISION) {
            this.props.toggleOrganizationModal(value);
        }

        if (value === CREATE_BRANCH_USER) {
            this.props.toggleUserModal(value);
        }

        if (value === CREATE_BRANCH_EQUIPMENT) {
            this.props.toggleEquipmentModal(value);
        }

        if (value === ASSOCIATE_EQUIPMENT) {
            this.props.toggleEquipmentModal(value);
        }
    };

    handleEditBranch = () => {
        this.props.toggleOrganizationModal(EDIT_BRANCH);
    };

    handleOnClickEditUser = (userId: number) => {
        this.props.toggleUserModal(EDIT_BRANCH_USER, userId);
    };

    handleOnClickDeleteUser = (userId: number) => {
        this.props.deleteUser(userId, DELETE_BRANCH_USER);
    };

    handleOnClickEditEquipment = (equipmentId: number) => {
        this.props.toggleEquipmentModal(EDIT_BRANCH_EQUIPMENT, equipmentId);
    };

    handleOnClickDeleteEquipment = (equipmentId: number) => {
        this.props
            .deleteEquipment(equipmentId, this.props.match.params.organizationId)
            .then(() => this.props.deleteBranchEquipment(equipmentId));
    };

    handleOnSubmitOrganizationModal = (organization: OrganizationType) => {
        if (this.props.modalAction === EDIT_BRANCH) {
            this.props.editBranch(
                organization,
                this.props.branch.get('parentId'),
                this.props.branch.get('id')
            );
        }

        if (this.props.modalAction === CREATE_DIVISION) {
            this.props.createDivision(
                organization,
                this.props.branch.get('parentId'),
                this.props.branch.get('id')
            );
        }
    };

    handleOnSubmitUserModal = (user: UserType, userId: number) => {
        if (this.props.modalAction === CREATE_BRANCH_USER) {
            this.props.createUser(
                user,
                this.props.modalAction,
                this.props.branch.get('parentId'),
                this.props.branch.get('id')
            );
        }

        if (this.props.modalAction === EDIT_BRANCH_USER) {
            this.props.editUser(
                userId,
                user,
                this.props.modalAction,
                this.props.branch.get('parentId'),
                this.props.branch.get('id')
            );
        }
    };

    handleOnSubmitEquipmentModal = (equipment: EquipmentType, equipmentId: ?number) => {
        console.log('handleOnSubmitEquipmentModal')
        if (this.props.modalAction === CREATE_BRANCH_EQUIPMENT) {
            this.props.createEquipment(equipment).then((equipmentResponse: EquipmentType) => {
                const organizaitonId =
                    this.props.branch.get('parentId') || this.props.match.params.organizationId;
                const branchId = this.props.branch.get('id') || this.props.match.params.branchId;
                if (organizaitonId && branchId) {
                    this.props.associateBranchEquipment(
                        organizaitonId,
                        branchId,
                        equipmentResponse.equipmentType.id
                    );
                }
            });
        }

        if (this.props.modalAction === EDIT_BRANCH_EQUIPMENT && equipmentId) {
            this.props.editBranchEquipment(equipment, equipmentId);
        }

        if (this.props.modalAction === ASSOCIATE_EQUIPMENT && equipmentId) {
            this.props.associateBranchEquipment(
                this.props.branch.get('parentId'),
                this.props.branch.get('branchId'),
                equipmentId
            );
        }
    };

    handleDeleteBranch = () => {
        this.props.deleteBranch(
            this.props.branch.get('branchId'),
            this.props.branch.get('parentId')
        );
    };

    renderModal = () => {
        if (!this.props.modalType) {
            return false;
        }

        let organizationModalHeader = 'components.Modals.Edit.Branch';

        if (this.props.modalAction === CREATE_DIVISION) {
            organizationModalHeader = 'components.Modals.Edit.Division';
        }

        let userModalHeader = 'components.Modal.Create.User';

        if (this.props.modalAction === EDIT_BRANCH_USER) {
            userModalHeader = 'components.Modal.Edit.User';
        }

        let equipmentModalHeader = 'components.Modals.Create.Equipment';
        if (this.props.modalAction === EDIT_BRANCH_EQUIPMENT) {
            equipmentModalHeader = 'components.Modals.Edit.Equipment';
        }
        if (this.props.modalAction === ASSOCIATE_EQUIPMENT) {
            equipmentModalHeader = 'components.Modals.Associate.Equipment';
        }

        if (this.props.modalType === MODAL_TYPE.USER) {
            return (
                <UserModal
                    header={userModalHeader}
                    data={this.props.branch.get('users')}
                    onSubmit={this.handleOnSubmitUserModal}
                />
            );
        }

        if (this.props.modalType === MODAL_TYPE.ORGANIZATION) {
            return (
                <OrganizationModal
                    header={organizationModalHeader}
                    data={this.props.branch}
                    onSubmit={this.handleOnSubmitOrganizationModal}
                />
            );
        }

        if (this.props.modalType === MODAL_TYPE.EQUIPMENT) {
            return (
                <EquipmentModal
                    header={equipmentModalHeader}
                    data={this.props.branch.get('equipment')}
                    onSubmit={this.handleOnSubmitEquipmentModal}
                />
            );
        }
    };

    render() {
        if (!this.props.branch) {
            return false;
        }

        const divisionActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.Division.Create',
            }),
            update: this.props.intl.formatMessage({
                id: 'components.Action.Branch.Update',
            }),
            delete: this.props.intl.formatMessage({
                id: 'components.Action.Branch.Delete',
            }),
        };

        const equipmentActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.Equipment.Create',
            }),
            associate: this.props.intl.formatMessage({
                id: 'components.Action.Equipment.Associate',
            }),
        };

        const userActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.User.Create',
            }),
            update: this.props.intl.formatMessage({
                id: 'components.Action.User.Update',
            }),
            delete: this.props.intl.formatMessage({
                id: 'components.Action.User.Delete',
            }),
        };

        let organizationModalHeader = 'components.Modals.Create.Division';
        if (this.props.modalAction === EDIT_BRANCH) {
            organizationModalHeader = 'components.Modals.Edit.Branch';
        }

        let userModalHeader = 'components.Modals.Create.User';
        if (this.props.modalAction === EDIT_BRANCH_USER) {
            userModalHeader = 'components.Modals.Edit.User';
        }

        const address =
            this.props.branch &&
            `${this.props.branch.getIn(['address', 'address']) || ''} - ${this.props.branch.getIn([
                'address',
                'city',
            ]) || ''} ${this.props.branch.getIn(['address', 'province']) ||
                ''}, ${this.props.branch.getIn(['address', 'postalCode']) ||
                ''} ${this.props.branch.getIn(['address', 'country']) || ''}`;

        const divisionColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'branchName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Location',
                }),
                key: 'province',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.ActiveUsers',
                }),
                key: 'usersCount',
            },
        ];

        const equipmentColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'name',
            },
        ];

        const userColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'firstName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.LastLogin',
                }),
                key: 'lastLogin',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.UserGroups',
                }),
                key: 'groups',
            },
        ];

        return (
            <SubOrganization>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({ id: 'containers.HomePage.helmetTitle' })}
                    </title>
                    <meta name="description" content={'views.BranchPage.BranchPage'} />
                </Helmet>
                {this.props.branch.get('deleted') && (
                    <DeletedMessage
                        text={this.props.intl.formatMessage({ id: 'views.BranchPage.Deleted' })}
                    />
                )}
                <DetailHeader
                    title={this.props.intl.formatMessage({ id: 'views.BranchPage.Branch' })}
                    name={this.props.branch.get('name')}
                    address={address}
                    onClickEdit={this.handleEditBranch}
                    onClickDelete={this.handleDeleteBranch}
                />
                <Split color={colors.grayDD} />
                <SubOrganization.ManagementContainer>
                    <SubOrganization.DataHeader>
                        <TextWithBadge
                            text={this.props.intl.formatMessage({
                                id: 'views.BranchPage.Divisions',
                            })}
                            badge={this.props.branch.get('divisionsCount')}
                        />
                    </SubOrganization.DataHeader>
                    <SubOrganization.DataSelect>
                        <SelectField
                            // options={[
                            //     { label: divisionActionLabels.create, value: CREATE_DIVISION },
                            // ]}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.SelectField.Manage.Divisions',
                            })}
                            padding={'2px 0 0 0'}
                            onChange={this.handleOnChangeAction}
                            maxWidth={230}
                            center
                        />
                    </SubOrganization.DataSelect>
                </SubOrganization.ManagementContainer>
                <DashboardDataTable
                    columns={divisionColumns}
                    name={['branchName']}
                    tableData={this.props.branch.get('divisions')}
                    onClickDataItem={this.handleOnClickDivision}
                    id={'branchId'}
                />
                <SubOrganization.ManagementContainer>
                    <SubOrganization.DataHeader>
                        <TextWithBadge
                            text={this.props.intl.formatMessage({
                                id: 'views.BranchPage.Equipment',
                            })}
                            badge={
                                this.props.branch.get('equipment') &&
                                this.props.branch.get('equipment').size
                            }
                        />
                    </SubOrganization.DataHeader>
                    <SubOrganization.DataSelect>
                        <SelectField
                            options={[
                                // {
                                //     label: equipmentActionLabels.create,
                                //     value: CREATE_BRANCH_EQUIPMENT,
                                // },
                                {
                                    label: equipmentActionLabels.associate,
                                    value: ASSOCIATE_EQUIPMENT,
                                },
                            ]}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.SelectField.Manage.Equipment',
                            })}
                            padding={'2px 0 0 0'}
                            onChange={this.handleOnChangeAction}
                            maxWidth={230}
                            center
                        />
                    </SubOrganization.DataSelect>
                </SubOrganization.ManagementContainer>
                <DashboardDataTable
                    columns={equipmentColumns}
                    tableData={this.props.branch.get('equipment')}
                    component={EquipmentDashboardItem}
                    onClickEditEquipment={this.handleOnClickEditEquipment}
                    onClickDeleteEquipment={this.handleOnClickDeleteEquipment}
                />
                <SubOrganization.ManagementContainer>
                    <SubOrganization.DataHeader>
                        <TextWithBadge
                            text={this.props.intl.formatMessage({ id: 'views.BranchPage.Users' })}
                            badge={this.props.branch.get('usersCount')}
                        />
                    </SubOrganization.DataHeader>
                    <SubOrganization.DataSelect>
                        <SelectField
                            options={[
                                { label: userActionLabels.create, value: CREATE_BRANCH_USER },
                            ]}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.SelectField.Manage.Users',
                            })}
                            padding={'2px 0 0 0'}
                            onChange={this.handleOnChangeAction}
                            maxWidth={230}
                            center
                        />
                    </SubOrganization.DataSelect>
                </SubOrganization.ManagementContainer>
                <DashboardDataTable
                    columns={userColumns}
                    name={['firstName', 'lastName']}
                    imageSource={'imgSource'}
                    tableData={this.props.branch.get('users')}
                    id={'branchId'}
                    onClickViewMore={this.handleOnClickViewMoreUsers}
                    onClickEditUser={this.handleOnClickEditUser}
                    onClickDeleteUser={this.handleOnClickDeleteUser}
                    component={UserDashboardItem}
                    users
                />
                <Modal>{this.renderModal()}</Modal>
            </SubOrganization>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    user: selectUser(),
    locale: selectLocale(),
    branch: selectCurrentBranch(),
    modalType: selectModalType(),
    modalAction: selectModalAction(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getBranchData,
            deleteBranch,
            toggleUserModal,
            toggleOrganizationModal,
            toggleEquipmentModal,
            deleteUser,
            editBranch,
            createDivision,
            createUser,
            editUser,
            createEquipment,
            associateBranchEquipment,
            editBranchEquipment,
            deleteEquipment,
            deleteBranchEquipment,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(BranchPageComponent))
);
