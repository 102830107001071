// @flow

import React, { useEffect, useState } from 'react';
import { getIcon } from '../IconConfigBox/utils';
import { hasStatus } from '../../views/MapPage/utils';
import SvgColor from '../SvgColor/SvgColor';
import { createStructuredSelector } from "reselect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getVehicles, stopAntiTheftAlarm } from "../../services/Vehicle/thunks";
import { Tooltip } from 'antd';
import { styled } from '@mui/material/styles';
import ThieftEventTransition from 'assets/status/theft-alert-white-new-after.svg'
import { selectEventTypes, selectFilteredVehicles } from "../../services/Vehicle/selectors";
import { fromJS } from "immutable";
import { getStatus } from "../../helpers";

const VehicleIconDisplay = (props) => {
    const [svgIcon, setSvgIcon] = useState(props.statusIcon);
    const [statusIson, setStatusIson] = useState(props.statusIcon);
    const [isHovering, setIsHovering] = useState(false);
    const [vehicleIdTheft, setVehicleIdTheft] = useState(null);
    const [isvehicleIdTheftLoading, setIsvehicleIdTheftLoading] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [icon, setIcon] = useState(null);
    const [tooltipText, setTooltipText] = useState('');
    useEffect(() => {
        setSvgIcon(props.statusIcon);
        setStatusIson(props.status)

    }, [props.statusIcon]);

    useEffect(
        () => {
            if (vehicleIdTheft) {
                props.stopAntiTheftAlarm(vehicleIdTheft)
                    .then(() => {
                        props.getVehicles();
                        setVehicleIdTheft(null)
                        setTimeout(() => setIsvehicleIdTheftLoading(false), 500);
                    })
                    .catch(() => {
                        setTimeout(() => setIsvehicleIdTheftLoading(false), 500);
                    });
            }

        },
        [vehicleIdTheft],
    );

    const doesVehicleHasStatus = (vehicle) => {
        return fromJS(vehicle).get('status')
            ? getStatus(
                fromJS(vehicle),
                fromJS(vehicle).getIn(['status', 'eventTypeId']),
                props.eventTypes,
                true,
            )
            : null;
    };

    useEffect(() => {

        let clickedVehicle = null
        if (selectedVehicle) {
            clickedVehicle = props.filteredVehicles.toJS().filter((vehicle) => vehicle.id === selectedVehicle.id);
        }

        if (clickedVehicle && clicked && props.status.eventType === 'STOLEN' && doesVehicleHasStatus(clickedVehicle[0]) && doesVehicleHasStatus(clickedVehicle[0]).eventType === 'STOLEN') {
            setIcon(ThieftEventTransition)

        } else {
            setIcon(null)
        }
        if (props.vehicle.status) {
            const lastEventTypeId = props.vehicle.status.eventTypeId;
            switch (lastEventTypeId) {
                case 1:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.TheftAlarmTooltip'
                    }));
                    break;
                case 3:
                case 4:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.TheftAlarmEndTooltip'
                    }));
                    break;
                case 6:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.InactiveVehicleTooltip'
                    }));
                    break;
                case 8:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.MotorOffToolTip'
                    }));
                    break;
                case 13:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.MinSpeedTHresholdTooltip'
                    }));
                case 14:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.MaxSpeedTHresholdTooltip'
                    }));
                case 20:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.IdleTooltip'
                    }));

                case 29:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.FastAccTooltip'
                    }));
                case 30:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.FastDecelTooltip'
                    }));
                default:
                    setTooltipText(props.intl.formatMessage({
                        id: 'components.SurvolComponent.ActiveVehicleTooltip'
                    }));
                    break;
            }
        }
    }, [props.vehicle, selectedVehicle, props.status, clicked, props.filteredVehicles]);

    const handleOnClickIcon = (e, vehicle) => {
        if (vehicle.status.description === "STOLEN") {
            setClicked(true)
            e.stopPropagation()
            if (!isvehicleIdTheftLoading) {
                setSelectedVehicle(vehicle)
                setIsvehicleIdTheftLoading(true)
                setVehicleIdTheft(vehicle.id)
            }

        }
    }
    const handleMouseEnter = (vehicle) => {
        if (vehicle.status.description === "STOLEN") {
            setIsHovering(true);
        }
    };

    const handleMouseLeave = (vehicle) => {
        if (vehicle.status.description === "STOLEN") {
            setIsHovering(false);
        }
    };

    if (props.statusIcon === undefined && props.customIcon && getIcon(props.customIcon.id) === '') return '';

    return (
        props.vehicle && props.vehicle.additionalData && props.vehicle.additionalData.icons && props.vehicle.additionalData.icons.vehicle && props.vehicle.additionalData.icons.vehicle.color
        && hasStatus(statusIson) || ((!statusIson || JSON.stringify(statusIson) === '{}') && props.customIcon) ? (
            <Tooltip title={tooltipText}>
                <div
                    onMouseEnter={() => handleMouseEnter(props.vehicle)}
                    onMouseLeave={() => handleMouseLeave(props.vehicle)}
                    onClick={(e) => handleOnClickIcon(e, props.vehicle)}
                    id={props.idVehicle ? props.idVehicle + '-' + getIcon(props.customIcon.id) : getIcon(props.customIcon.id)}>
                    <SvgColor
                        width={30}
                        svg={props.vehicle.additionalData.icons.vehicle.img}
                        colors={[
                            props.vehicle.additionalData.icons.vehicle.color.primary,
                            props.vehicle.additionalData.icons.vehicle.color.secondary,
                        ]}
                    />

                </div>
            </Tooltip>

        ) : (
            svgIcon
                ?
                <div
                    onMouseEnter={() => handleMouseEnter(props.vehicle)}
                    onMouseLeave={() => handleMouseLeave(props.vehicle)}
                    onClick={(e) => handleOnClickIcon(e, props.vehicle)} id={props.idVehicle ? props.idVehicle + '-' + svgIcon : svgIcon}>
                    <Tooltip title={tooltipText}>
                        <img
                            width={30}
                            src={icon ? icon : svgIcon}
                            alt='Status'
                        />
                    </Tooltip>
                </div> :
                <div style={{ width: '30px' }}>

                </div>

        )
    );
};
const mapStateToProps = createStructuredSelector({
    filteredVehicles: selectFilteredVehicles(),
    eventTypes: selectEventTypes(),
});
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            stopAntiTheftAlarm,
            getVehicles,
        },
        dispatch,
    );
// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(VehicleIconDisplay));


