// @flow

import request from 'services/request';

import type { Organization, UserDataType, AssociateUserType } from 'types';

export function retrieveBranchDivisions(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivision(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivisionEquipment(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/equipment`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivisionUsers(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/users`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivisionOperators(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/operators`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function createDivisionRequest(
    data: Organization,
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions`,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function editDivisionRequest(
    organizationId: number,
    branchId: number,
    divisionId: number,
    data: Organization,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}`,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}
