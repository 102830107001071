// @flow

import styled from 'styled-components';

const HomePage = styled.div`
    width: 100%;
`;

HomePage.Title = styled.h2`
    margin: 0;
    font-weight: 400;
    font-size: 30px;
    text-decoration: underline;
    letter-spacing: 0.8px;
`;

HomePage.Description = styled.p`

`;

HomePage.List = styled.ul`
    line-height: 30px;
    list-style-type: square;
`;

HomePage.ListItem = styled.li`

`;

HomePage.Code = styled.code`
    font-weight: bold;
    font-size: 14px;
    background: lightgrey;
    padding: 5px;
    border-radius: 5px;
`;

export default HomePage;
