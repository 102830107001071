// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

export const HeaderText = styled.div`
    font-size: 18px;
    font-weight: 400;
    font-family: OpenSans-SemiBold;
    color: ${colors.green63};
    margin-bottom: 28px;
`;

export const SelectContainer = styled.div`
    width: 100%;
    height: 40px;
    margin-bottom: 30px;

    > div {
        width: 100%;
        max-width: 100%;
    }

    @media (min-width: ${screen.large}) {
        width: 200px;

        & > div {
            max-width: 200px;
            min-width: 230px;
        }
    }
`;

export const TableWrapper = styled.div`
    display: block;
    width: 100%;
`;

export const Table = styled.div`
    font-size: 15px;
    margin-bottom: 40px;
`;

export const Button = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;

Button.Container = styled.div`
    & > ul {
        padding: 0;
    }

    & .pagination {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        margin-bottom: 25px;
        list-style: none;
        padding-bottom:5em;
    }

    & .pagination li {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${colors.green63};
        width: 40px;
        height: 40px;
        margin-right: 10px;
        cursor: pointer;
    }

    & .pagination .active {
        background-color: ${colors.green63};
        color: ${colors.white};
    }

    & .pagination .disabled {
        background-color: ${colors.grayED};
        color: ${colors.gray46};
        border-color: ${colors.black};
        cursor: default;
    }

    & .pagination li a {
        text-decoration: none;
        color: inherit;
        pointer-events: none;
    }
`;
