/* eslint-disable react/prop-types */
// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { parseJWT } from 'helpers';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { SideMenu, Header } from 'styles/sidemenu';
import { Split } from 'styles/common';
import { toggleSideMenu, hideSideMenu, compareUrlSideMenu } from 'services/SideMenu/thunks';
import { withRouter } from 'react-router-dom';
import CloseButton from 'components/CloseButton';
import colors from 'styles/colors';
import React from 'react';
import SideMenuItem from 'components/SideMenuItem';

import UsersIconBlack from 'assets/users.svg';

import AdminSideMenu from './styles';

type Props = {};
type State = {};

class AdminSideMenuComponent extends React.Component<Props, State> {
    state = {};

    handleToggleSideMenu = () => {
        this.props.toggleSideMenu();
    };

    handleOnClick = (url: string) => (event: Event) => {
        event.stopPropagation();
        event.preventDefault();
        this.props.hideSideMenu();
        if (this.props.location.pathname.includes(url)) {
            this.props.compareUrlSideMenu();
        }
    };

    render() {
        const jwt = parseJWT(this.props.user.get('token'));
        const isGeoAdmin = jwt.permissions.includes('access:admin');
        const isOwner = jwt.permissions.includes('own:branch');
        const isAdmin = jwt.permissions.indexOf('write:branch-settings');
        const sideMenuItems = [
            {
                translationId: 'views.Admin.SideMenu.users',
                url: '/admin/users',
            },
        ];

        if (isGeoAdmin) {
            sideMenuItems.push({
                translationId: 'views.Admin.SideMenu.Branch',
                url: '/admin/organization',
            });
        }

        if (isGeoAdmin || isOwner || isAdmin) {
            sideMenuItems.push(
                {
                    translationId: 'views.Admin.SideMenu.DriverID',
                    url: '/admin/drivers',
                },
                {
                    translationId: 'views.Admin.SideMenu.Groups',
                    url: '/admin/groups',
                },
                {
                    translationId: 'views.Admin.SideMenu.Category',
                    url: '/admin/categories',
                }
            );
        }

        return (
            <SideMenu isOpen={this.props.isOpen} color={colors.whiteF7} noOrca>
                <AdminSideMenu.Container isOpen={this.props.isFleetOverviewOpen}>
                    <AdminSideMenu.Header>
                        <Header>
                            <AdminSideMenu.Text color={colors.white}>
                                <FormattedMessage id={'views.Admin.SideMenu.Admin'} />
                            </AdminSideMenu.Text>
                        </Header>
                    </AdminSideMenu.Header>
                    <AdminSideMenu.ListContainer>
                        {sideMenuItems.map((item) => {
                            let textColor = colors.green63;
                            let icon = UsersIconBlack;
                            if (
                                this.props.location.pathname &&
                                item.url &&
                                this.props.location.pathname.includes(item.url)
                            ) {
                                textColor = colors.black;
                                icon = UsersIconBlack;
                            }
                            if (!item.url) {
                                textColor = colors.grayDD;
                                icon = UsersIconBlack;
                            }
                            const menuItem = (
                                <React.Fragment>
                                    {
                                        <SideMenuItem
                                            source={icon}
                                            translationId={item.translationId}
                                            textStyle={{
                                                fontSize: 17,
                                                fontWeight: 400,
                                                fontFamily: 'OpenSans-SemiBold',
                                                color: textColor,
                                                padding: '0 0 0 22px',
                                            }}
                                            image={{
                                                width: 26,
                                                height: 26,
                                            }}
                                            split={false}
                                            height={85}
                                        />
                                    }
                                    <Split color={colors.splitGrayReports} opacity={0.2} />
                                </React.Fragment>
                            );
                            if (item.url) {
                                return (
                                    <div
                                        key={item.translationId}
                                        role="link"
                                        onClick={this.handleOnClick(item.url)}
                                        tabIndex={0}
                                    >
                                        <SideMenu.NavItem
                                            to={`/${this.props.intl.locale}${item.url}`}
                                        >
                                            {menuItem}
                                        </SideMenu.NavItem>
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </AdminSideMenu.ListContainer>
                    <CloseButton
                        isOpen={this.props.isOpen}
                        onClick={this.handleToggleSideMenu}
                        top={24}
                        size={30}
                        zIndex={5}
                        backgroundColor={colors.sideMenuGreen}
                    />
                </AdminSideMenu.Container>
            </SideMenu>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isOpen: selectIsSideMenuOpen(),
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            toggleSideMenu,
            hideSideMenu,
            compareUrlSideMenu,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(AdminSideMenuComponent))
);
