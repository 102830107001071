// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

import { Container as ButtonContainer } from 'components/OutlineButton/styles';

export const Header = styled.div`
    width: 100%;
    // height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    white-space: nowrap;
    padding-right: 20x;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const HeaderText = styled.div`
    font-family: OpenSans-SemiBold;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 10px;
    color: ${colors.black};
    text-overflow: ellipsis;
    overflow: hidden;

    @media (min-width: ${screen.large}) {
        margin-bottom: 0px;
    }
`;

export const Buttons = styled.div`
    // display: flex;
    width: 100%;

    & > ${ButtonContainer} {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;

        > div {
            width: 100%;
        }
    }

    @media (min-width: ${screen.large}) {
        display: flex;
        width: auto;
        margin-top:1em;
        & > ${ButtonContainer} {
            margin-right: 9px;
            margin-bottom: 0px;

            &:last-child {
                margin-right: 0px;
            }

            > div {
                width: 150px;
            }
        }
    }
`;
