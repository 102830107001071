// @flow

export const API_URL = 'NGINX_REPLACE_API_URL';
export const MAPBOX_GL_TOKEN = 'NGINX_REPLACE_MAPBOX_GL_TOKEN';
export const ZENDESK_KEY = 'NGINX_REPLACE_ZENDESK_KEY';
export const GA_CODE = 'NGINX_REPLACE_GA_CODE';
export const AUTH0_DOMAIN = 'NGINX_REPLACE_AUTH0_DOMAIN';
export const AUTH0_CLIENTID = 'NGINX_REPLACE_AUTH0_CLIENTID';
export const ADMIN_API_URL = 'NGINX_REPLACE_ADMIN_API_URL';
export const DRIVER_API_URL = 'NGINX_REPLACE_DRIVER_API_URL';
export const SISENSE_URL = `NGINX_REPLACE_SISENSE_URL`;
export const ISO_API_URL = 'NGINX_REPLACE_ISO_API_URL';
export const WS_URL = 'NGINX_REPLACE_WS_URL';