// @flow

import type { Dispatch } from 'redux';

import type { List, Map } from 'immutable';

export type ImmutableMap<X, Y> = Map<X, Y>;
export type ImmutableList<X> = List<X>;

export type ReduxDispatch = Dispatch<*>;

export type InputEvent = Event & { currentTarget: HTMLInputElement } & {
    target: HTMLInputElement,
};

// We redefine a custom error object with only data & status
export type ResponseErrorType = {|
    data: Object,
    status: number,
|};

export type GenericActionType = {
    type: string,
    payload?: Object,
    meta?: Object,
};

export type SearchResultsType = {
    results: ImmutableList<string>,
    query: string,
};

export type MapViewPortType = {
    width: number,
    height: number,
    longitude: number,
    latitude: number,
    zoom: number,
    bearing: number,
    pitch: number,
    altitude: number,
};

export type UserType = {
    token: string,
};

export type GeoPoint = [number, number];

export type Coordinates = Array<GeoPoint>;

export type UserDataType = {
    id: ?number,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    comments: string,
    lang: string,
    units: string,
    branchId: number,
};

export type AssociateUserType = {
    branchId: number,
    userId: number,
};

export type EquipmentType = {
    id: ?number,
    equipmentTypeId: number,
    extVehicleId: number,
    name: string,
};

export type NewVehicleType = {
    id: ?number,
    name: string,
    vin: string,
    color: string,
    licensePlate: string,
    manufacturer: string,
    model: string,
    year: number,
    defaultDriverId: any,
    defaultDriverName: any,
    equipmentTypeId: number,
    equipmentType: string,
    odometer: number,
    horometer: number,
    operational: boolean,
    branchId: number,
    externalWebhooks: any,
};

export type EventRootProps = {
    className?: string,
    classes: ClassNames,
    style?: React.CSSProperties,
    cellIndex: number,
    rangeIndex: number,
    isActive: boolean,
    disabled?: boolean,
    handleDelete(): void,
};

export type EquipmentTypes = ImmutableList<EquipmentType>;

type Vehicle = {
    id: number,
    name: string,
    address: string,
    status: string,
    lastUpdate: Date,
};

export type VehicleType = ImmutableMap<string, Vehicle>;
export type VehicleListType = ImmutableList<string, Vehicle>;

type E = {
    id: ?number,
    equipmentTypeId: number,
    name: string,
};

export type Equipment = ImmutableMap<string, E>;
export type EquipmentListType = ImmutableList<E>;

export type ColumnType = {
    label: string,
    key: string,
    sortMethod: ?SortFunction,
};

export type PaginationData<T> = {
    currentPage: number,
    data: T,
    firstPageUrl: string,
    from: number,
    lastPage: number,
    lastPageUrl: string,
    nextPageUrl: string,
    path: string,
    perPage: string,
    prevPageUrl: string,
    to: number,
    total: number,
};

export type PaginatedType = {
    page: number,
    lastPage: number,
};

export type Organization = {
    branch_id: number,
    parent_id: number,
    branch_name: string,
    contact: string,
    address: string,
    city: string,
    province: string,
    postal_code: string,
    country: string,
    fax: string,
    cell_phone: string,
    other_phone: string,
    email: string,
    is_orca: true,
    units: string,
    timezone: string,
    comments: string,
    is_system_branch: boolean,
    deleted_at: ?Date,
    created_at: ?Date,
    updated_at: ?Date,
};

export type OrganizationType = ImmutableMap<string, Organization>;
export type OrganizationListType = ImmutableList<string, Organization>;

export type DrivingReportGeneratorType = {
    type: string,
    format: string,
    customPeriod: {
        to: string,
        from: string,
    },
    period: string,
    gasPrice: string,
    equipmentIds: Array<number>,
};

export type MoveReportGeneratorType = {
    type: string,
    format: string,
    customPeriod: {
        to: string,
        from: string,
    },
    period: string,
    equipmentIds: Array<number>,
    address: string,
};

export type EventGroup = {
    id: number,
    name: string,
    displayOrder: number,
    eventTypes: Array<string>,
};

export type RoutificGeneratorType = {
    routificdate: string,
    routificIds: Array<string>,
};

export type EventGroupsType = Array<EventGroup>;

export type Driver = {
    driverCard: number,
    driverName: string,
    endDate: ?Date,
    startDate: ?Date,
    transmitted: boolean,
    vehicleName: ?string,
};

export type SpeedDetection = {
    isSpeedThresholdActivated: boolean,
    minimumSpeed: number,
    maximumSpeed: number,
    isPreSpeedAlertActivated: boolean,
    preAlertMinimumSpeed: number,
    preAlertMaximumSpeed: number,
    isGeofencingEnabled: boolean,
};

export type DriverType = ImmutableMap<string, Driver>;
export type DriverListType = ImmutableList<string, Driver>;
