// @flow

import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';
import { Image } from 'styles/sidemenu';

const ActionSideMenu = styled.div`
    background: transparent;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    z-index: 3;
    transition: all 250ms ease-in-out 0s;
    padding-left: 60px;
    ${(props) => (props.isOpen ? `width: 100vw` : `width: 0px;`)}
    @media (min-width: ${screen.small}) {
        ${(props) => (props.isOpen ? `width: ${screen.small};` : `width: 0px;`)}
    }
`;

ActionSideMenu.ActionBar = styled.div`
    ${(props) => (props.isOpen ? `width: 100vw;` : `width: 60px;`)} overflow: hidden;
    padding-top: 10px;
    transition: all 250ms ease-in-out 0s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    ${(props) =>
        `background: ${props.color || colors.sideMenuGreen};
        background: linear-gradient(to right, ${props.color ||
            colors.sideMenuGreen} 0%, ${props.color || colors.green52} 100%);`} z-index: 5;

    @media (min-width: ${screen.small}) {
        ${(props) => (props.isOpen ? `width: 320px;` : `width: 61px;`)};
    }
`;

ActionSideMenu.Top = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

ActionSideMenu.Bottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;

const ActionSideMenuKeyframesFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ActionSideMenuKeyframesFadeOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

ActionSideMenu.MenuDropDown = styled.div`
.ant-popover-title {
    border: none !important;
}
.ant-popover-inner {
    background: linear-gradient(to right, #62ab36 0%, #528f2d 100%);
    border-radius: 8px !important;
}
.ant-popover-arrow-content::before{
    background-color: #62ab36 !important;
}
.ant-popover-content p {
    color: white !important;
    font-size: 16px;    padding-bottom: 18px;
}
.ant-popover-title a {
    position: absolute;
    opacity: 0.7;
    right: 0.7em;
    color: white !important;
    transition: transform 0.25s, opacity 0.25s;
}
.ant-popover-title:hover a {
    transform: rotate(90deg);
    opacity: 1;
}
.ant-popover-title {
    text-align: end;
}

`;

ActionSideMenu.MenuDropDownContent = styled.div`
    display: block;
    width: 100%;
    transition: all 250ms ease-in-out 0s;
    ${(props) =>
        props.isExpanded
            ? `height: ${props.options * 60}px; overflow: visible; opacity: 1;`
            : 'height: 0px; overflow: hidden; opacity: 0;'} position: relative;

    > div {
        background: rgba(0, 0, 0, 0.2);

        > div {
            &:hover {
                background: rgba(0, 0, 0, 0.3) !important;
            }
        }
    }
`;
ActionSideMenu.Help= styled.div`
border-bottom: 1px solid rgba(255, 255, 255, 0.2);padding-bottom: 10px;padding-top:10px;
height:60px;
&:hover {
    background: rgba(255, 255, 255, 0.2);
}
`

ActionSideMenu.Menu = styled.div`
    // display: flex;
    // flex: 1 100%;
    width: 320px;
    ${(props) =>
        props.menuType === 'logo' && props.isOpen ?`background: #000;padding: 1.9em;display: flex;align-items: center;justify-content: center;margin: -0.6em 0em 2em 0em;box-shadow: 0 2px 1px #040404;`:``}
    ${(props) =>
        props.menuType === 'logo'
            ? `height: 50px; cursor: pointer;`
            : 'height: 60px; cursor: pointer;'}

    ${(props) =>
        props.menuType === 'logo'
            ? ` 
            .full-logo {
                height: 72px;
                width: 100%;
                display:flex;
      
            }
           
            .icon-logo {
                padding: 0.6em;
                //background: white; 
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -0.6em;
            }
            span {
                float: left;
                width: 60px;
                height: 70px;
                margin-bottom: 10px;
                position: relative;
                svg {
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: block;
                }
            }

            span:first-child {
                svg {
                    position: absolute;
                    left: -35px;
                    padding:0;
                }
            }

            span:last-child {
                width: calc(320px - 60px);
                svg {
                    height: 22px;
                    margin: auto 0;
                }
            }`
            : null}

    ${(props) =>
        props.menuType === 'menu'
            ? ` width: 60px;
            transition: all 250ms ease-in-out 0s;
            ${props.isOpen ? `opacity: 0; left: -60px; display: none;` : ``}
            > div {
                cursor: pointer;
                background: rgba(0, 0, 0, 0.8);
                display: block;
                width: 61px;
                height: 60px;
                position: absolute;
                right: auto;
                transition: all 250ms ease-in-out 0s;

                &:hover {
                    background: rgba(0, 0, 0, 1);
                }

                svg {
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 28px;
                    path {
                        fill: #fff;
                    }
                }
            }`
            : ``}

    ${(props) =>
        props.classType === 'resize' && !props.isOpen
            ? `width: 60px;
        > div {
            cursor: pointer;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            display: block;
            width: 60px;
            height: 60px;
            position: absolute;
            right: auto;
            transition: background 250ms ease-in-out 0s;
            animation: ${ActionSideMenuKeyframesFadeOut} 250ms ease-in-out 0s;
            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }

            svg {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                ${props.menuType === 'overview' ? `width: 28px;` : ``}

                ${props.menuType === 'reports' ? `width: 22px;` : ``}

                ${props.menuType === 'admin' ? `width: 22px;` : ``}

                ${props.menuType === 'map' ? `width: 28px;` : ``}

                ${props.menuType === 'usernetwork' ? `width: 28px;` : ``}

                ${props.menuType === 'truck' ? `width: 28px;` : ``}

                ${props.menuType === 'checkmark' ? `width: 28px;` : ``}
                path, rect {
                    fill: #fff;
                }
            }

            span {
                color: #fff;
                display: none;
                position: absolute;
                width: 260px;
                height: 60px;
                left: 60px;
                line-height: 60px;
                font-family: OpenSans;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                transition: all 250ms ease-in-out 250ms;
            }
        }`
            : ``}

    ${(props) =>
        props.classType === 'resize' && props.isOpen
            ? `
            flex: none;
            width: calc(50% - 15px);
            height: 120px;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.1);
            float: left;
            position: relative;
            margin: 0 5px 5px 0;
            transition: background 250ms ease-in-out 0s;
            animation: ${ActionSideMenuKeyframesFadeIn} 250ms ease-in-out 0s;
            &:nth-child(odd) {
                margin-left: 12px;
            }
            &:nth-child(event) {
                margin-right: 12px;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.2);
                > div {
                    svg {
                        transform: scale(1.2);
                    }
                }
            }
        > div {
            display: block;
            width: 100%;
            height: 120px;
            position: absolute;
            right: auto;
            transition: all 250ms ease-in-out 0s;

            svg {
                transition: all 250ms ease-in-out 0s;
                display: block;
                position: absolute;
                top: -10px;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                ${props.menuType === 'overview' ? `width: 36px;` : ``}

                ${props.menuType === 'reports' ? `width: 28px;` : ``}

                ${props.menuType === 'admin' ? `width: 22px;` : ``}

                ${props.menuType === 'map' ? `width: 36px;` : ``}

                ${props.menuType === 'usernetwork' ? `width: 36px;` : ``}

                ${props.menuType === 'truck' ? `width: 36px;` : ``}

                ${props.menuType === 'checkmark' ? `width: 36px;` : ``}

                ${props.menuType === 'maintenance' ? `width: 36px;` : ``}

                path, rect {
                    fill: #fff;
                }
            }

            span {
                opacity: 1;
                color: #fff;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 50px;
                text-align: center;
                line-height: 50px;
                font-family: OpenSans;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                transition: all 250ms ease-in-out 0s;
            }
        }`
            : ``}

    ${(props) =>
        props.classType === 'dropdown'
            ? `width: 100%;
            height: 60px;
            padding: 12px;
            `
            : ``}

    ${(props) =>
        props.classType === 'longer'
            ? `width: 100%;
        > div {
            cursor: pointer;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            display: block;
            width: 100%;
            height: 60px;
            position: absolute;
            right: auto;
            transition: all 250ms ease-in-out 0s;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }

            svg {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto 0 auto 16px;

            ${props.menuType === 'search' ? `height: 28px;` : ``}

            ${props.menuType === 'notification' ? `height: 28px;` : ``}

            ${props.menuType === 'user' ? `height: 28px;` : ``}

            ${props.menuType === 'world' ? `height: 28px;` : ``}

                path {
                    fill: #fff;
                }
            }

            span {
                color: #fff;
                display: block;
                position: absolute;
                width: 260px;
                height: 60px;
                left: 60px;
                line-height: 60px;
                font-family: OpenSans;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
            }

            i {
                display: block;
                position: absolute;
                background: #000;
                color: #fff;
                border-radius: 20px;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                font-style: normal;
                font-size: 12px;
                font-weight: bold;
                top: -10px;
                bottom: 0;
                left: 30px;
                margin: auto 0;
            }
        }`
            : ``}
`;

ActionSideMenu.ActionBarClose = styled.div`
    cursor: pointer;
    position: fixed;
    top: ${(props) => (props.loggedInAsUser ? '47px' : '0')};
    z-index: 5;
    width: 60px;
    height: 60px;
    background: #000;
    ${(props) =>
        !props.isOpen
            ? `opacity: 0; right: -100%;;
            `
            : `right: 0px; transition: all 250ms ease-in-out 100ms;`} svg {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 20px;

        path {
            fill: #fff;
        }
    }

    @media (min-width: ${screen.small}) {
        z-index: 2;
        top: 89px;
        ${(props) =>
            !props.isOpen
                ? `opacity: 0; left: -100%;;
                `
                : `left: 320px; transition: all 250ms ease-in-out 100ms;`};
    }
`;

ActionSideMenu.SideMenuContainer = styled.div`
    transition: all 250ms ease-in-out 0s;
    width: 100vw;
    display: block;

    @media (min-width: ${screen.small}) {
        width: 350px;
    }
`;

ActionSideMenu.AnimationContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    ${(props) =>
        props.active
            ? `
                transform: translateX(0);
                overflow: visible;
                `
            : `
                transform: translateX(-100%);
                transition: transform 250ms ease-in-out 0s;
                `};
`;

ActionSideMenu.Animation = styled.div`
    flex: 1;
`;

export default ActionSideMenu;
