// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { fromJS } from 'immutable';

import { selectUser } from 'services/Authentication/selectors';
import { selectLocale } from 'services/Language/selectors';
import { selectOrganization, selectOrganizationsLoading } from 'services/Organization/selectors';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';

import { selectCurrentBranch } from 'services/Branch/selectors';

import { selectCurrentDivision } from 'services/Division/selectors';

import { getOrganizationData } from 'services/Organization/thunks';

import { getBranchData } from 'services/Branch/thunks';

import { getDivisionData } from 'services/Division/thunks';

import { selectModalType, selectModalAction } from 'services/Modal/selectors';

import { toggleUserModal, toggleConfirmationModal, closeModal } from 'services/Modal/thunks';

import Loader from 'components/Loader';

import {
    CREATE_ORGANIZATION_USER,
    CREATE_BRANCH_USER,
    CREATE_DIVISION_USER,
    EDIT_ORGANIZATION_USER,
    EDIT_BRANCH_USER,
    EDIT_DIVISION_USER,
    MODAL_TYPE,
} from 'services/Modal/actions';

import { DELETE_ORGANIZATION_USER } from 'services/Organization/actions';
import { DELETE_BRANCH_USER } from 'services/Branch/actions';
import { DELETE_DIVISION_USER } from 'services/Division/actions';

import { createUser, editUser, deleteUser } from 'services/User/thunks';

import SearchInput from 'components/SearchInput';
import ConfirmationModal from 'components/Modals/Confirmation';

import Modal from 'components/Modals';
import UserModal from 'components/Modals/CreateUserModal';

import UserDashboardItem from 'components/DashboardDataUserItem';

import DashboardDataTable from 'components/DashboardDataTable';

import DataListHeaderContainer from 'containers/DataListHeaderContainer';

import UserListPage from 'styles/listPage';
import colors from 'styles/colors';
import { Split } from 'styles/common';

import type { ImmutableList, ImmutableMap, SearchResultsType } from 'types';

type Props = {
    organization: OrganizationListType,
    division: OrganizationListType,
    branch: OrganizationListType,
};

type State = {
    searchResults: ImmutableList<string, ImmutableMap>,
    query: string,
};

export class UserListView extends React.Component<Props, State> {
    deletedUserId: number;

    state = {
        searchResults: fromJS([]),
        query: '',
    };

    componentDidMount() {
        // fetch branch data if it does not exist yet.
        let token;
        if (this.props.user) {
            token = this.props.user.get('token');
        } else if (sessionStorage.getItem('api_token')) {
            token = sessionStorage.getItem('api_token');
        }

        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;

        if (organizationId && token) {
            if (branchId && divisionId && this.props.division.size === 0) {
                const r = this.props.getDivisionData(
                    organizationId,
                    branchId,
                    divisionId,
                    token,
                    this.props.locale
                );
                console.log(r);

                return r;
            }

            if (branchId && this.props.branch.size === 0) {
                const r = this.props.getBranchData(
                    organizationId,
                    branchId,
                    token,
                    this.props.locale
                );
                console.log(r);
                return r;
            }

            return this.props.getOrganizationData(organizationId);
        }
    }

    deletedUserId = -1;

    handleOnClickAllLocations = () => {
        console.log('clicked All Locations');
    };

    handleOnChangeSearchInput = (event: Event) => {
        console.log('searching ...');
    };

    handleOnClickViewMoreUsers = () => {
        console.log('handle on click view more users');
    };

    handleOnClickEditUser = (userId: number) => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;

        if (organizationId) {
            let value = EDIT_ORGANIZATION_USER;
            if (branchId) {
                value = EDIT_BRANCH_USER;
            }

            if (branchId && divisionId) {
                value = EDIT_DIVISION_USER;
            }
            this.props.toggleUserModal(value, userId);
        }
    };

    handleOnClickDeleteUser = (userId: number) => {
        this.props.toggleConfirmationModal({ userId });
        this.deletedUserId = userId;
    }

    handleConfirmDeleteUser = () => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;

        if (organizationId) {
            let value = DELETE_ORGANIZATION_USER;
            if (branchId) {
                value = DELETE_BRANCH_USER;
            }

            if (branchId && divisionId) {
                value = DELETE_DIVISION_USER;
            }
            this.props.deleteUser(this.deletedUserId, value).then(
                () => {
                    this.props.getOrganizationData(organizationId).then(
                        () => this.handleSearchResults({query: ''})
                    );
                    this.props.closeModal();
                }
            );
        }
    };

    handleSearchResults = (searchResults: SearchResultsType) => {
        this.setState({
            searchResults: searchResults.results,
            query: searchResults.query,
        });
    };

    handleOnSubmitUserModal = (userData: UserDataType, userId: number) => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;

        if (this.props.modalAction.includes('CREATE')) {
            return this.props.createUser(
                userData,
                this.props.modalAction,
                organizationId,
                branchId,
                divisionId
            );
        }

        if (this.props.modalAction.includes('EDIT') && userId) {
            return this.props.editUser(
                userId,
                userData,
                this.props.modalAction,
                organizationId,
                branchId,
                divisionId
            );
        }
    };

    toggleCreateUserModal = () => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;

        if (organizationId) {
            let value = CREATE_ORGANIZATION_USER;
            if (branchId) {
                value = CREATE_BRANCH_USER;
            }

            if (branchId && divisionId) {
                value = CREATE_DIVISION_USER;
            }
            this.props.toggleUserModal(value);
        }
    };

    renderModal = () => {
        if (!this.props.modalType) {
            return false
        }

        let userModalHeader = 'components.Modals.Create.User';
        let userModalAction = 'CREATE';
        if (
            this.props.modalAction === EDIT_ORGANIZATION_USER ||
            this.props.modalAction === EDIT_BRANCH_USER ||
            this.props.modalAction === EDIT_DIVISION_USER
        ) {
            userModalHeader = 'components.Modals.Edit.User';
            userModalAction = 'EDIT';
        }

        if (this.props.modalType === MODAL_TYPE.USER) {
            return (
                <Modal>
                    <UserModal
                        header={userModalHeader}
                        data={this.props.organization.get('users')}
                        onSubmit={this.handleOnSubmitUserModal}
                        userAction={userModalAction}
                    />
                </Modal>
            );
        }
        if (this.props.modalType === MODAL_TYPE.CONFIRMATION) {
            return (
                <ConfirmationModal
                    headerTextId={'components.Confirmation.DeleteUser.Header'}
                    messageTextId={'components.Confirmation.DeleteUser.Message'}
                    confirmationCallback={this.handleConfirmDeleteUser}
                />
            );
        }
    };

    render() {
        const data = this.props.organization;

        if (!data) {
            return false;
        }

        const tableData =
            this.state.query && this.state.searchResults && this.state.searchResults.size >= 0
                ? this.state.searchResults
                : data.get('users');

        const userColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'firstName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.LastLogin',
                }),
                key: 'lastLogin',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.UserGroups',
                }),
                key: 'groups',
            },
        ];

        return (
            <UserListPage isOpen={this.props.isOpen}>
                <Helmet>
                    <title>
                         Service Client - Admin
                    </title>
                </Helmet>
                <DataListHeaderContainer
                    title={this.props.intl.formatMessage({ id: 'views.UserListPage.Users' })}
                    name={`${data.get('name')}`}
                    actionButtonText={'containers.Dashboard.CreateNew'}
                    onClickActionButton={this.toggleCreateUserModal}
                />
                <Split color={colors.grayDD} />
                <UserListPage.FilterContainer>
                    <UserListPage.SearchContainer>
                        <SearchInput
                            onSearchResults={this.handleSearchResults}
                            searchKeys={['firstName', 'lastName']}
                            borderColor={colors.grayDD}
                            data={data.get('users')}
                        />
                    </UserListPage.SearchContainer>
                </UserListPage.FilterContainer>
                <Loader loading={this.props.loading} />
                <DashboardDataTable
                    columns={userColumns}
                    tableData={tableData}
                    id={'id'}
                    onClickViewMore={this.handleOnClickViewMoreUsers}
                    onClickEditUser={this.handleOnClickEditUser}
                    onClickDeleteUser={this.handleOnClickDeleteUser}
                    component={UserDashboardItem}
                    users
                    sliceUsers={false}
                />
                {this.renderModal()}
            </UserListPage>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    user: selectUser(),
    locale: selectLocale(),
    branch: selectCurrentBranch(),
    organization: selectOrganization(),
    division: selectCurrentDivision(),
    modalType: selectModalType(),
    modalAction: selectModalAction(),
    isOpen: selectIsSideMenuOpen(),
    loading: selectOrganizationsLoading(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getBranchData,
            getOrganizationData,
            getDivisionData,
            editUser,
            createUser,
            deleteUser,
            toggleUserModal,
            toggleConfirmationModal,
            closeModal,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(UserListView))
);
