// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;

    border: 1px solid ${({ borderColor }) => borderColor || colors.grayDD};
    padding: 0 11px 0 13px;
    position: relative;
`;

export const InputDate = styled.input.attrs({
    type: 'date',
})`
    font-family: OpenSans;
    outline: none;
    border: none;
    flex: 1;
    position: relative;
    z-index: 2;
    background: transparent;

    &::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    &::-webkit-clear-button,
        display: none;
    }
`;

export const Icon = styled.img`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    justify-self: flex-end;
    position: absolute;
    right: 5px;
    z-index: 1;
`;
