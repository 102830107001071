import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import MuiTable from "mui-virtualized-table";

const useVirtualizedTableStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            padding: '14px',

            'background-color': '#fff',
            color: 'black',
        },

        "& .topLeftGrid, & .topRightGrid": {
            border: "none",
            "border-bottom": "2px solid black",
            "background-color" : 'white'

        },
        "& .bottomLeftGrid": {
            border: "none"
        },

        "& [class*=MuiTable-cellHeader]": {
            'font-weight': '600',
            'font-size': '14px',

            "& [class*=MuiTable-cellContents]": {
                'white-space': 'break-spaces',
            }
        },

    },

});


const cellStyleContect = (column, row, data) => {

    if (data.indexOf(row) > 0 && data.indexOf(row) % 2 !== 0) {
        //ACCELERATION BRUSQUE

        if (column.name === 'acceleration_brusque' && row.acceleration_brusque <= 75 && row.acceleration_brusque >= 60) {
            return {
                style: {
                    "color": "#eb8b15",
                    "background-color": "#E6E9EC"
                }
            };
        } else if (column.name === 'acceleration_brusque' && row.acceleration_brusque <= 59 && row.acceleration_brusque >= 0) {
            return {
                style: {
                    "color": "#eb4034",
                    "background-color": "#E6E9EC"
                }
            };
        }
        //FREINAGE BRUSQUE
        if (column.name === 'freinage_brusque' && row.freinage_brusque <= 75 && row.freinage_brusque >= 60) {
            return {
                style: {
                    "color": "#eb8b15",
                    "background-color": "#E6E9EC"
                }
            };
        } else if (column.name === 'freinage_brusque' && row.freinage_brusque <= 59 && row.freinage_brusque >= 0) {
            return {
                style: {
                    "color": "#eb4034",
                    "background-color": "#E6E9EC"
                }
            };
        }

        //VIRAGE BRUSQUE
        if (column.name === 'virage_brusque' && row.virage_brusque <= 75 && row.virage_brusque >= 60) {
            return {
                style: {
                    "color": "#eb8b15",
                    "background-color": "#E6E9EC"
                }
            };
        } else if (column.name === 'virage_brusque' && row.virage_brusque <= 59 && row.virage_brusque >= 0) {
            return {
                style: {
                    "color": "#eb4034",
                    "background-color": "#E6E9EC"
                }
            };
        }
        //IMPACT
        if (column.name === 'impact' && row.impact <= 75 && row.impact >= 60) {
            return {
                style: {
                    "color": "#eb8b15",
                    "background-color": "#E6E9EC"
                }
            };
        } else if (column.name === 'impact' && row.impact <= 59 && row.impact >= 0) {
            return {
                style: {
                    "color": "#eb4034",
                    "background-color": "#E6E9EC"
                }
            };
        }
        //DEPASSEMENT DE VITESSE
        if (column.name === 'depassement_vitesse_nb' && row.depassement_vitesse_nb <= 75 && row.depassement_vitesse_nb >= 60) {
            return {
                style: {
                    "color": "#eb8b15",
                    "background-color": "#E6E9EC"
                }
            };
        } else if (column.name === 'depassement_vitesse_nb' && row.depassement_vitesse_nb <= 59 && row.depassement_vitesse_nb >= 0) {
            return {
                style: {
                    "color": "#eb4034",
                    "background-color": "#E6E9EC"
                }
            };
        }

        //TEMPS RALENTIT
        if (column.name === 'idleTime' && row.idleTime <= 75 && row.idleTime >= 60) {
            return {
                style: {
                    "color": "#eb8b15",
                    "background-color": "#E6E9EC"
                }
            };
        } else if (column.name === 'idleTime' && row.idleTime <= 59 && row.idleTime >= 0) {
            return {
                style: {
                    "color": "#eb4034",
                    "background-color": "#E6E9EC"
                }
            };
        }
        //SCORE FINAL
        if (column.name === 'scoreFinal' && row.scoreFinal <= 75 && row.scoreFinal >= 60) {
            return {
                style: {
                    "color": "#eb8b15",
                    "background-color": "#E6E9EC"
                }
            };
        } else if (column.name === 'scoreFinal' && row.scoreFinal <= 59 && row.scoreFinal >= 0) {
            return {
                style: {
                    "color": "#eb4034",
                    "background-color": "#E6E9EC"
                }
            };
        }
        return {
            style: {
                "background-color": "#E6E9EC"
            }
        };
    }

    //ACCELERATION BRUSQUE
    if (column.name === 'acceleration_brusque' && row.acceleration_brusque <= 75 && row.acceleration_brusque >= 60) {

        return {
            style: {
                "color": "#eb8b15",

            }
        };
    } else if (column.name === 'acceleration_brusque' && row.acceleration_brusque <= 59 && row.acceleration_brusque >= 0) {
        return {
            style: {
                "color": "#eb4034",

            }
        };
    }
    //FREINAGE BRUSQUE
    if (column.name === 'freinage_brusque' && row.freinage_brusque <= 75 && row.freinage_brusque >= 60) {

        return {
            style: {
                "color": "#eb8b15",

            }
        };
    } else if (column.name === 'freinage_brusque' && row.freinage_brusque <= 59 && row.freinage_brusque >= 0) {
        return {
            style: {
                "color": "#eb4034",

            }
        };
    }

    //VIRAGE BRUSQUE
    if (column.name === 'virage_brusque' && row.virage_brusque <= 75 && row.virage_brusque >= 60) {

        return {
            style: {
                "color": "#eb8b15",

            }
        };
    } else if (column.name === 'virage_brusque' && row.virage_brusque <= 59 && row.virage_brusque >= 0) {
        return {
            style: {
                "color": "#eb4034",

            }
        };
    }

    //IMPACT
    if (column.name === 'impact' && row.impact <= 75 && row.impact >= 60) {

        return {
            style: {
                "color": "#eb8b15",

            }
        };
    } else if (column.name === 'impact' && row.impact <= 59 && row.impact >= 0) {
        return {
            style: {
                "color": "#eb4034",

            }
        };
    }
    //VITESSE
    if (column.name === 'depassement_vitesse_nb' && row.depassement_vitesse_nb <= 75 && row.depassement_vitesse_nb >= 60) {

        return {
            style: {
                "color": "#eb8b15",

            }
        };
    } else if (column.name === 'depassement_vitesse_nb' && row.depassement_vitesse_nb <= 59 && row.depassement_vitesse_nb >= 0) {
        return {
            style: {
                "color": "#eb4034",

            }
        };
    }

    //RALENTIT
    if (column.name === 'idleTime' && row.idleTime <= 75 && row.idleTime >= 60) {

        return {
            style: {
                "color": "#eb8b15",

            }
        };
    } else if (column.name === 'idleTime' && row.idleTime <= 59 && row.idleTime >= 0) {
        return {
            style: {
                "color": "#eb4034",

            }
        };
    }
    //SCORE FINAL
    if (column.name === 'scoreFinal' && row.scoreFinal <= 75 && row.scoreFinal >= 60) {

        return {
            style: {
                "color": "#eb8b15",

            }
        };
    } else if (column.name === 'scoreFinal' && row.scoreFinal <= 59 && row.scoreFinal >= 0) {
        return {
            style: {
                "color": "#eb4034",

            }
        };
    }

}
const VirtualizedTable = (props) => {
    const {data = [], columns = [], order = null, orderBy = null} = props;
    const classes = useVirtualizedTableStyles();
    return (
        <div className={classes.root} style={{height: "600px", width: '100%',}}>
            <AutoSizer>
                {({width, height}) => (
                    <MuiTable
                        height={height}
                        data={data}
                        columns={columns}
                        orderBy={"scoreFinal"}
                        orderDirection={"desc"}
                        includeHeaders={true}
                        cellProps={(column, row) => {
                           return cellStyleContect(column,row,data)
                        }}
                        width={width}
                        fixedRowCount={1}
                        maxWidth={width}
                        style={{tableLayout: "fixed", backgroundColor: "white"}}
                    />
                )}
            </AutoSizer>
        </div>
    );
};

export default VirtualizedTable;
