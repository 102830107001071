// @flow

import { WebAuth } from 'auth0-js';
import { AUTH0_CLIENTID, AUTH0_DOMAIN } from 'env';

class Auth {
    accessToken: ?string;

    idToken: ?string;

    expiresAt: number;

    auth0 = new WebAuth({
        domain: AUTH0_DOMAIN,
        clientID: AUTH0_CLIENTID,
        responseType: 'token id_token',
        scope: 'offline_access',
    });

    frenchDict = {
        forgotPasswordAction: 'Changer ou récupérer votre mot de passe?',
        description: 'Numérisation et gestion de flotte de véhicules.',
        email: 'Entrez votre courriel',
        password: 'Entrez votre mot de passe'
    };

    engDict = {
        forgotPasswordAction: 'Forgot your password?',
        description: 'Control vehicles',
        email: 'Enter your email',
        password: 'Enter your password'
    };

    login = (isAdmin: boolean, returnTo: string) => {
        this.expiresAt = 7200 * 1000 + new Date().getTime();
        const language = localStorage.getItem('locale') || 'fr';
        this.auth0.authorize({
            audience: 'http://api.geothentic.com/v1',
            redirectUri: `${window.location.origin}/callback?isAdmin=${isAdmin}${
                returnTo ? `&returnTo=${returnTo}` : ''
            }`,
            language,
            languageDictionary: language === 'fr' ? this.frenchDict : this.engDict,
        });
    };

    handleAuthentication = () => {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.auth0.client.userInfo(authResult.accessToken, (errr, res) => {});
                // this.setSession(authResult);
            } else if (err) {
                // history.replace('/home');
                console.log(err);
                // alert(`Error: ${err.error}. Check the console for further details.`);
            }
        });
    };

    getAccessToken = () => {
        return this.accessToken;
    };

    getIdToken = () => {
        return this.idToken;
    };

    getTokenExpiresAt = () => {
        return this.expiresAt;
    };

    setSession = (authResult: Object) => {
        // Set isLoggedIn flag in sessionStorage
        sessionStorage.setItem('isLoggedIn', 'true');

        // Set the time that the Access Token will expire at
        this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
        sessionStorage.setItem('api_token', this.accessToken);
        sessionStorage.setItem('expires_at', this.expiresAt);
    };

    renewSession = (successCallback) => {
        this.auth0.checkSession(
            {
                audience: 'http://api.geothentic.com/v1',
                redirectUri: `${window.location.origin}/callback?isAdmin=false`,
            },
            (err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.setSession(authResult);
                    if (successCallback) {
                        successCallback(authResult);
                    }
                } else if (err) {
                    console.log(err);
                }
            }
        );
    };

    logout = (isAdmin=false) => {
        // Remove tokens and expiry time
        this.accessToken = null;
        this.idToken = null;
        this.expiresAt = 0;

        // Remove isLoggedIn flag from sessionStorage
        sessionStorage.removeItem('isLoggedIn');
        if(isAdmin) {
            sessionStorage.setItem('isAdmin', true)
        }
        this.auth0.logout({
            returnTo: `${window.location.origin}?logout=true`,
        });

        // navigate to the home route
        // history.replace('/home');
    };

    isAuthenticated = () => {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = this.expiresAt;
        return new Date().getTime() < expiresAt;
    };

    // expire dans 15 min
    willExpireSoon = (expires_at) => {
        return new Date().getTime() > expires_at - 900 * 1000;
    };
}
const exp = new Auth();
export default exp;
