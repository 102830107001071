/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */
/* eslint-disable require-await */

import { ADMIN_API_URL } from 'env';
import axios from 'axios';

const groupUrl = `${ADMIN_API_URL}groups`;

const createGroup = async (newGroup) => axios.post(groupUrl, newGroup);

const deleteGroup = async (groupId) => axios.delete(`${groupUrl}/${groupId}`);

const deleteItemsInGroup = async (groupId, groupType, ids) =>
    axios.delete(`${groupUrl}/${groupId}/${groupType}`, { data: { ids } });

const editGroup = async (group, id) => axios.put(`${groupUrl}/${id}`, group);

const fetchGroup = async (id) => axios.get(`${groupUrl}/${id}`);

const fetchGroups = async () => axios.get(groupUrl);

const fetchUsersByGroupId = async (groupId) => axios.get(`${groupUrl}/${groupId}/users`);

const updateGroup = async ({ groupId, groupType, ids }) => axios.post(`${groupUrl}/${groupId}/${groupType}`, { ids });

export default {
    createGroup,
    deleteGroup,
    deleteItemsInGroup,
    editGroup,
    fetchGroup,
    fetchGroups,
    fetchUsersByGroupId,
    updateGroup,
};
