// @flow

import React from 'react';

import { Loader } from './styles';

type Props = {
    loading: boolean,
};

function DotLoaderComponent(props: Props) {
    if (props.loading) {
        return (
            <Loader>
                <Loader.Dots>
                    <Loader.Dot />
                    <Loader.Dot />
                    <Loader.Dot />
                </Loader.Dots>
            </Loader>
        );
    }
    return null;
}

export default DotLoaderComponent;
