// @flow

import {
    receivedGetConfigByEquipementIdSuccess,
    receivedConfigError,
    receivedModuleError,
    receivedGetSettingsByEquipementIdSuccess,
    receivedGetModuleByEquipementIdSuccess,
    startLoading,
    dismissConfigLoading,
    startLoadingEmails,
    receivedGetEmailNotifConfigByEquipementId,
    receivedGetEmailsByBranchId,
    receivedUnauthorizedMoveConfigByEquipementId,
    receivedDissmissedConfigError,
    receivedEngineConfigGlobalActivation,
    updatedEngineConfigGlobalActivation,
    receivedEngineConfigConditions,
    putConditionInBox,
    putNewConditionInBox,
    resetGlobalActivationAction,
    receivedEngineConfigDelays,
} from './actions';
import {
    retrieveConfigByEquipementId,
    retrieveModuleByEquipementId,
    retrieveSettingsByEquipementId,
    retrieveEmailNotifConfigByEquipementId,
    retrieveEmailsByBranchId,
    retrieveUnauthorizedMoveConfigByEquipementId,
    editSpeedDetectionByEquipementId,
    editGarageModeByEquipementId,
    editEngineCutoffByEquipementId,
    editAdditionalDataByEquipementId,
    editEquipementNameByEquipementId,
    editCapteursTempByEquipementId,
    editAuxiliaireByEquipementId,
    editAccelerometreDetectionByEquipementId,
    editNotifCourrielByEquipementId,
    editCardReaderConfigByEquipementId,
    editEngineDetectionByEquipementId,
    editLowPowerModeByEquipementId,
    editConnexionByEquipementId,
    editDepNonAutoriseByEquipementId,
    editEngineEventsByEquipementId,
    editFardierConfigByEquipementId,
    updateZohoVehicle,
    addEmailsByBranchId,
    deleteEmailsByBranchId,
    updateEmailsByBranchId,
    retrieveEngineConfigGlobalActivation,
    updateEngineConfigGlobalActivation,
    retrieveEngineConfigConditions,
    updateEngineConfigCondition,
    deleteEngineConfigCondition,
    createEngineConfigCondition,
    retrieveEngineConfigDelays,
    updateEngineConfigDelays,
} from './resources';

import { setClearMapEquipmentPopout } from 'services/Vehicle/thunks';
import { closeModal } from 'services/Modal/thunks';
import 'antd/dist/antd.css';
import notification from 'antd/es/notification';
import type { ImmutableMap, SpeedDetection } from 'types';
import { receivedControlColors, receivedGarageMode } from '../Vehicle/actions';

/**
 * Fetch configuration data for current vehicle.
 */
export const getConfigByEquipementId = (equipementId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(equipementId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveConfigByEquipementId(token, equipementId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedConfigError());
            }
            dispatch(receivedGetConfigByEquipementIdSuccess(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

/**
 * Fetch unauthorized move schedule settings
 */
export const getUnauthorizedMoveConfigByEquipementId = (equipementId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveUnauthorizedMoveConfigByEquipementId(token, equipementId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedConfigError());
            }
            dispatch(receivedUnauthorizedMoveConfigByEquipementId(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

/**
 * Edit SpeedDetection Config Equipment.
 */
export const editSpeedDetection = (equipementId: number, requestData: SpeedDetection, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editSpeedDetectionByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit SpeedDetection Config Equipment.
 */
export const editGarageMode = (equipementId: number, requestData: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editGarageModeByEquipementId(token, equipementId, requestData)
        .then((response) => {
            dispatch(dismissConfigLoading());
            dispatch(receivedGarageMode(requestData));
            return true;
        })
        .catch((error) => {
            dispatch(dismissConfigLoading());
            return false;
        });
};

/**
 * Edit SpeedDetection Config Equipment.
 */
export const editEngineCutoff = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editEngineCutoffByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit editAdditionalData Config Equipment.
 */
export const editAdditionalData = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editAdditionalDataByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit editAdditionalData Config Equipment.
 */
export const editEquipementName = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    console.log(requestData);
    const data = {
        Name: requestData,
        Code_Interne: equipementId,
        Carburant: null,
        Ann_e: null,
        ORCA: null,
        Mobile_Stationnaire: null,
        Marque: null,
        Nomenclature_client: null,
        Compte: null,
        Mod_le: null,
        Actif: null,
    };
    const token = getState().getIn(['auth', 'user', 'token']);
    return editEquipementNameByEquipementId(token, equipementId, requestData)
        .then((response) => {
            console.log('test');
            return true;
        })
        .catch((error) => {
            console.log(error);
            console.log('ici');
            return false;
        });
};

/**
 * Edit CapteursTemp Config Equipment.
 */
export const editCapteursTemp = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editCapteursTempByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit Auxiliaire Config Equipment.
 */
export const editAuxiliaire = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editAuxiliaireByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit editLowPowerMode Config Equipment.
 */
export const editLowPowerMode = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editLowPowerModeByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit editFardierConfig Config Equipment.
 */
export const editFardierConfig = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editFardierConfigByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit editConnexion Config Equipment.
 */
export const editConnexion = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editConnexionByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit editDepNonAutorise Config Equipment.
 */
export const editDepNonAutorise = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editDepNonAutoriseByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit editDepNonAutorise Config Equipment.
 */
export const editEngineEvents = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editEngineEventsByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit AccelerometreDetection Config Equipment.
 */
export const editAccelerometreDetection = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editAccelerometreDetectionByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit AccelerometreDetection Config Equipment.
 */
export const editNotifCourriel = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editNotifCourrielByEquipementId(token, equipementId, JSON.stringify(requestData))
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit CardReader Config Equipment.
 */
export const editCardReaderConfig = (equipementId: number, requestData: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editCardReaderConfigByEquipementId(token, equipementId, requestData)
        .then((response) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

/**
 * Edit Engine Detection Config Equipment.
 */
export const editEngineDetectionConfig = (equipementId: number, requestData: object, props) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return editEngineDetectionByEquipementId(token, equipementId, requestData)
        .then((response) => {
            dispatch(dismissConfigLoading());
            response.status == 204
                ? setTimeout(() => {
                      notification.success({
                          key: 'updatable',
                          message: props.intl.formatMessage({
                              id: 'components.Modals.Form.Config.DetecMoteur',
                          }),
                          description: props.intl.formatMessage({
                              id: 'components.Modals.Form.Config.succes',
                          }),
                      });
                  }, 1000)
                : null;
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

/**
 * Fetch email notification data for current vehicle.
 */
export const getEmailNotifConfigByEquipementId = (equipementId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    /* dispatch(startLoading());*/
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveEmailNotifConfigByEquipementId(token, equipementId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedConfigError());
            }
            dispatch(receivedGetEmailNotifConfigByEquipementId(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

/**
 * Fetch emails for current branch.
 */
export const getEmailsByBranchId = (branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoadingEmails());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveEmailsByBranchId(token, branchId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedConfigError());
            }
            dispatch(receivedGetEmailsByBranchId(response.data));
            return response.data;
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

/**
 * Fetch settings data for current vehicle.
 */
export const getSettingsByEquipementId = (equipementId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(equipementId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveSettingsByEquipementId(token, equipementId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedConfigError());
            }
            dispatch(receivedGetSettingsByEquipementIdSuccess(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

export const getModuleByEquipementId = (equipementId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(equipementId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveModuleByEquipementId(token, equipementId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            dispatch(receivedGetModuleByEquipementIdSuccess(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};
/**
 *
 * @param {*} branchId
 * @param {*} data
 */
export const insertBranchEmail = (branchId: number, data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return addEmailsByBranchId(token, branchId, data)
        .then((response: Object) => {})
        .catch((error) => {
            if (error) return dispatch(receivedConfigError());
        });
};

/**
 *
 * @param {*} branchId
 * @param {*} emailId
 */
export const removeBranchEmail = (branchId: number, emailId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return deleteEmailsByBranchId(token, branchId, emailId)
        .then((response: Object) => {})
        .catch((error) => {
            if (error) return dispatch(receivedConfigError());
        });
};

/**
 *
 * @param {*} branchId
 * @param {*} emailId
 * @param {*} data
 */
export const modifyEmailsByBranchId = (branchId: number, emailId: number, data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return updateEmailsByBranchId(token, branchId, emailId, data)
        .then((response: Object) => {})
        .catch((error) => {
            if (error) return dispatch(receivedConfigError());
        });
};

export const dispatchConfigRequestError = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedConfigError());

export const dismissConfigError = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedDissmissedConfigError());

export const getEngineConfigGlobalActivation = (vehicleId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(vehicleId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveEngineConfigGlobalActivation(token, vehicleId)
        .then((response: Object) => {
            if (response.data) {
                if (response.data.status && typeof response.data.status === 'string') {
                    dispatch(receivedModuleError());
                }
                dispatch(receivedEngineConfigGlobalActivation(response.data));
            }
        })
        .catch((error) => {
            console.log('error', error);
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

export const setEngineConfigGlobalActivation = (
    vehicleId: number,
    activation: Boolean,
    idleTime: number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    if (!Number.isInteger(vehicleId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return updateEngineConfigGlobalActivation(token, vehicleId, activation, idleTime)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            dispatch(updatedEngineConfigGlobalActivation());
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

export const getEngineConfigConditions = (vehicleId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(vehicleId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveEngineConfigConditions(token, vehicleId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            dispatch(receivedEngineConfigConditions(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};
export const setConditionToEdit = (conditions: string, delay: Number, index: Number) => (
    dispatch: ReduxDispatch
) => {
    dispatch(startLoading());
    if (delay) {
        conditions.delay = delay;
    }
    if (index) {
        conditions.index = index;
    }
    dispatch(putConditionInBox(conditions));
};
export const editEngineConfigCondition = (vehicleId: number, data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(data.vehicleId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return updateEngineConfigCondition(token, vehicleId, JSON.stringify(data))
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            return retrieveEngineConfigConditions(token, vehicleId).then((response: Object) => {
                if (response.data.status && typeof response.data.status === 'string') {
                    dispatch(receivedModuleError());
                }
                dispatch(receivedEngineConfigConditions(response.data));
            });
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

export const removeEngineConfigCondition = (vehicleId: number, conditionId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(vehicleId)) {
        return;
    }
    if (!Number.isInteger(conditionId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = {
        vehicleId,
        algoId: conditionId,
    };

    return deleteEngineConfigCondition(token, vehicleId, JSON.stringify(data))
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            return retrieveEngineConfigConditions(token, vehicleId).then((response: Object) => {
                if (response.data.status && typeof response.data.status === 'string') {
                    dispatch(receivedModuleError());
                }
                dispatch(receivedEngineConfigConditions(response.data));
            });
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};
export const setNewCondition = () => (dispatch: ReduxDispatch) => {
    dispatch(startLoading());
    dispatch(putNewConditionInBox());
};

export const insertEngineConfigCondition = (vehicleId: number, data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(vehicleId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return createEngineConfigCondition(token, vehicleId, JSON.stringify(data))
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            return retrieveEngineConfigConditions(token, vehicleId).then((response: Object) => {
                if (response.data.status && typeof response.data.status === 'string') {
                    dispatch(receivedModuleError());
                }
                dispatch(receivedEngineConfigConditions(response.data));
            });
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

export const resetGlobalActivation = () => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(resetGlobalActivationAction());
};

export const getEngineConfigDelays = (vehicleId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(vehicleId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveEngineConfigDelays(token, vehicleId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            dispatch(receivedEngineConfigDelays(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};

export const setEngineConfigDelay = (vehicleId: number, delays: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (!Number.isInteger(vehicleId)) {
        return;
    }
    dispatch(startLoading());
    const token = getState().getIn(['auth', 'user', 'token']);
    let data = delays;
    data.vehicleId = vehicleId;
    return updateEngineConfigDelays(token, vehicleId, JSON.stringify(data))
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedModuleError());
            }
            return retrieveEngineConfigDelays(token, vehicleId).then((response: Object) => {
                if (response.data.status && typeof response.data.status === 'string') {
                    dispatch(receivedModuleError());
                }
                dispatch(receivedEngineConfigDelays(response.data));
            });
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedConfigError());
        });
};
