/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useCallback, useEffect, useState } from 'react';
import groupsAgent from '../agents/groupAgent';
import useAgent from '../../../hooks/useAgent';
import { FormattedMessage } from 'react-intl';
import TransitionsModal from '../utils/TransitionsModal';
import useStyles from '../styles/Group.styles';
import GroupList from './list/GroupList';
import GroupCreation from './creation/GroupCreation';
import Group from './details/Group';
import { ToggleButton } from '../utils/CustomButton';

const user = () => {
    const [fetchGroups, groups, isFetchingGroups] = useAgent(groupsAgent.fetchGroups);
    const [createGroup, createdGroup, isCreatingGroups] = useAgent(groupsAgent.createGroup);
    const [deleteGroup, deletedGroup, isDeletingGroup] = useAgent(groupsAgent.deleteGroup);

    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isDefaultDisabled, setIsDefaultDisabled] = useState(false);

    const classes = useStyles();

    const handleGroupSubmit = useCallback((newGroup) => {
        if (newGroup && !isCreatingGroups) {
            createGroup(newGroup);
        }
    });

    const handleGroupClick = useCallback((id) => setSelectedGroupId(id));

    const handleDeleteGroup = useCallback(
        (groupId) => {
            if (groupId && !isDeletingGroup) {
                deleteGroup(groupId);
                setSelectedGroupId(null);
            }
        },
        [deleteGroup, isDeletingGroup]
    );

    const handleEditGroup = useCallback(
        () => {
            fetchGroups();
        },
        [fetchGroups]
    );

    useEffect(
        () => {
            if (deletedGroup) {
                fetchGroups();
            }
        },
        [deletedGroup]
    );

    useEffect(
        () => {
            if (createdGroup && !isFetchingGroups) {
                fetchGroups();
            }
        },
        [createdGroup]
    );

    useEffect(
        () => {
            if (!groups && !isFetchingGroups) {
                fetchGroups();
            }
        },
        [fetchGroups, groups]
    );

    useEffect(
        () => {
            if (groups) {
                setIsDefaultDisabled(groups.some(({ isDefault }) => isDefault));
            }
        },
        [groups]
    );

    const toggleButtonCreate = useCallback(
        ({ openModal }) => (
            <ToggleButton onClick={openModal}>
                <FormattedMessage id={'views.Groups.CreateGroup'} />
            </ToggleButton>
        ),
        []
    );

    return (
        <div className={classes.container}>
            <div className={classes.groups}>
                <h3 className={classes.title}>
                    <FormattedMessage id={'views.Groups.AllGroups'} />
                </h3>

                <GroupList
                    isLoading={isFetchingGroups}
                    items={groups}
                    onItemClick={handleGroupClick}
                    onDeleteGroup={handleDeleteGroup}
                    onEditedGroup={handleEditGroup}
                />

                <div className={classes.groupsControl}>
                    <TransitionsModal button={toggleButtonCreate}>
                        {({ closeModal }) => (
                            <GroupCreation
                                onSubmit={handleGroupSubmit}
                                closeModal={closeModal}
                                title="views.Groups.CreateGroup"
                                textButton="views.Groups.Create"
                                disabled={isDefaultDisabled}
                            />
                        )}
                    </TransitionsModal>
                </div>
            </div>

            <Group groupId={selectedGroupId} />
        </div>
    );
};

export default user;
