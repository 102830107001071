// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Button = styled.div`
    display: flex;
    height: ${(props) => props.height || '40px'};
    max-width: 150px;
    width: 100px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => props.color};
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 0;
`;

Button.Text = styled.div`
    color: ${(props) => props.textColor || `${colors.black}`};
    overflow: hidden;
    text-overflow: ellipsis;
`;
export default Button;
