import React from 'react';
import ModalChangePasswordStyles from './styles';

const ModalChangePassword = ({
    title,
    change,
    old = {},
    newer = {},
    confirm = {},
    submit = {},
    cancel = {},
    loading,
    error,
}) => (
    <ModalChangePasswordStyles>
        <ModalChangePasswordStyles.Modal>
            <ModalChangePasswordStyles.ModalForm onSubmit={submit.action && submit.action}>
                <ModalChangePasswordStyles.ButtonClose
                    disabled={loading}
                    onClick={cancel.action && cancel.action}
                    loading={loading}
                >
                    &times;
                </ModalChangePasswordStyles.ButtonClose>
                <ModalChangePasswordStyles.Header>{title}</ModalChangePasswordStyles.Header>
                <ModalChangePasswordStyles.Body>
                    <ModalChangePasswordStyles.InputField
                        loading={loading}
                        error={
                            error &&
                            error.errors &&
                            error.errors.old_password &&
                            error.errors.old_password
                        }
                    >
                        <input
                            value={old.value}
                            onChange={change}
                            disabled={loading}
                            required="required"
                            type="password"
                            name="old_password"
                            placeholder={old.placeholder}
                        />
                    </ModalChangePasswordStyles.InputField>
                    <ModalChangePasswordStyles.InputField
                        loading={loading}
                        error={
                            error &&
                            error.errors &&
                            error.errors.new_password &&
                            error.errors.new_password
                        }
                    >
                        <input
                            value={newer.value}
                            onChange={change}
                            disabled={loading}
                            required="required"
                            type="password"
                            name="new_password"
                            placeholder={newer.placeholder}
                        />
                    </ModalChangePasswordStyles.InputField>
                    <ModalChangePasswordStyles.InputField loading={loading}>
                        <input
                            value={confirm.value}
                            onChange={change}
                            disabled={loading}
                            required="required"
                            type="password"
                            name="confirm_password"
                            placeholder={confirm.placeholder}
                        />
                    </ModalChangePasswordStyles.InputField>
                    {error &&
                        error.message && (
                            <ModalChangePasswordStyles.Errors>
                                {error.message}
                            </ModalChangePasswordStyles.Errors>
                        )}
                </ModalChangePasswordStyles.Body>
                <ModalChangePasswordStyles.Footer>
                    <ModalChangePasswordStyles.ButtonConfirm disabled={loading} loading={loading}>
                        {submit.label}
                    </ModalChangePasswordStyles.ButtonConfirm>
                    <ModalChangePasswordStyles.ButtonCancel
                        disabled={loading}
                        onClick={cancel.action && cancel.action}
                        loading={loading}
                    >
                        {cancel.label}
                    </ModalChangePasswordStyles.ButtonCancel>
                </ModalChangePasswordStyles.Footer>
            </ModalChangePasswordStyles.ModalForm>
        </ModalChangePasswordStyles.Modal>
    </ModalChangePasswordStyles>
);

export default ModalChangePassword;
