import React, { useState, useContext, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Table, Input, Form } from 'antd';
import { bindActionCreators } from 'redux';
import {
    changeUnit,
    i18nDistanceSymbol,
    setTextunit,
    isImperial,
} from '../../../services/Units/thunks';
import { getVehicleById } from '../../../services/Vehicle/thunks';
import { patchMaintenanceVehicle } from '../../../services/Maintenance/thunks';
import moment from 'moment';
const EdiTableAlarme = (props) => {
    const EditableContext = React.createContext(null);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(
            () => {
                if (editing) {
                    inputRef.current.focus();
                }
            },
            [editing]
        );

        const toggleEdit = () => {
            setEditing(!editing);
            if (dataIndex === 'cumulDistance') {
                let cumulDistance = props.isImperial()
                    ? Math.round(record[dataIndex] / 1.609).toFixed(1) / 1000
                    : record[dataIndex] / 1000;
                form.setFieldsValue({
                    [dataIndex]: cumulDistance,
                });
            }

            if (dataIndex === 'cumulHrs') {
                let hrsCumul = record[dataIndex] / 60 / 60;
                form.setFieldsValue({
                    [dataIndex]: hrsCumul,
                });
            }
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        if (editable) {
            children = editing ? (
                <Form.Item name={dataIndex}>
                    <Input
                        type={'number'}
                        step={'0.01'}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        style={{ width: '60%' }}
                    />
                </Form.Item>
            ) : (
                <div onClick={toggleEdit}>{children}</div>
            );
        }

        return <td {...restProps}>{children}</td>;
    };

    const handleSave = (row) => {
        const newData = [...props.dataSource];
        //const index = newData.findIndex((item) => row.id === item.id );

        let index = '';
        if (props.EditAlarmType === '1') {
            index = newData.findIndex((item) => row.id === item.id);
        } else if (props.EditAlarmType === '2') {
            index = newData.findIndex((item) => row.maintId === item.maintId);
        }

        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });

        let cumul_distance = newData[index].cumulDistance;
        let cumul_hrs = newData[index].cumulHrs;
        if (props.dataSource[index].cumulDistance !== newData[index].cumulDistance) {
            cumul_distance = props.isImperial()
                ? Math.round(cumul_distance * 1.609).toFixed(1) * 1000
                : cumul_distance * 1000;
            newData[index].cumulDistance = cumul_distance;
        }
        if (props.dataSource[index].cumulHrs !== newData[index].cumulHrs) {
            cumul_hrs = cumul_hrs * 60 * 60;
            newData[index].cumulHrs = cumul_hrs;
        }
        props.getVehicleById(newData[index].id).then((response) => {
            const vehicleDatas = {
                cumul_distance: parseInt(cumul_distance),
                cumul_hrs: parseInt(cumul_hrs),
                last_alert: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                maint_id: props.EditAlarmType === '2' ? newData[index].maintId : props.maintId,
            };
            let vehId = newData[index].id;
            props.EditAlarmType === '1'
                ? (vehId = newData[index].id)
                : (vehId = newData[index].vehId);
            props.updateVehicles(newData);
            if (vehId) {
                props.patchMaintenanceVehicle(vehId, vehicleDatas);
            }
        });
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns2 = props.columns.map((col) => {
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });
    return (
        <div>
            <Table
                rowSelection={props.rowSelection}
                components={components}
                rowClassName={() => 'editable-row'}
                dataSource={props.dataSource}
                columns={columns2}
                rowKey="id"
                pagination={false}
                scroll={{ y: 400 }}
                size={"middle"}
            />
            {/*<Loader loading={!props.isDataLoaded && props.dataSource.length === 0} />*/}
        </div>
    );
};
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            patchMaintenanceVehicle,
            getVehicleById,
            setTextunit,
            changeUnit,
            i18nDistanceSymbol,
            isImperial,
        },
        dispatch
    );
// EXPORTS
export default connect(
    null,
    mapDispatchToProps
)(injectIntl(EdiTableAlarme));
