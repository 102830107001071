// @flow

import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {bindActionCreators} from 'redux';
import {fromJS} from 'immutable';
import {withRouter} from 'react-router-dom';

import {toggleSideMenu, hideSideMenu, compareUrlSideMenu} from 'services/SideMenu/thunks';

import {FormattedMessage, injectIntl} from 'react-intl';
import {SideMenu, Header, Footer, Image} from 'styles/sidemenu';

import SideMenuItem from 'components/SideMenuItem';
import SideMenuItemSisense from 'components/SideMenuItemSisense';
import CloseButton from 'components/CloseButton';

import {selectIsSideMenuOpen} from 'services/SideMenu/selectors';
import {REPORT} from 'services/Report/constants';

import colors from 'styles/colors';
import {Split} from 'styles/common';

import ReportsSideMenu from './styles';

import ReportIconBlack from 'assets/report-pen.svg';
import ReportIconGreen from 'assets/report-pen-green.svg';

import {parseJWT} from 'helpers';

import {selectDrivingBehaviourReport, selectIsLoading} from 'services/Report/selectors';
import {selectUser} from 'services/Authentication/selectors';
import {getConfigByBranchId} from '../../services/Branch/thunks';
import {getBranchInfo} from '../../services/Organization/thunks';

class ReportsSideMenuComponent extends React.Component<Props, State> {
    constructor (props) {
        super(props);
        this.state = {
            hasTemp: false,
            data: REPORT.sideMenuItems,
        };
    }

    async componentDidMount () {
        const jwt = parseJWT(this.props.user.get('token'));
        const branchId = jwt['https://geothentic.com/branch_id'];

        if (jwt.permissions && Array.isArray(jwt.permissions) &&
            (jwt.permissions.indexOf('write:branch-settings') !== -1 || jwt.permissions.indexOf('access:admin') !== -1)) {
            const tab = {
                translationId: 'containers.ReportComportement.DrivingBehavior',
                url: '/reports/driving-behaviours',
            };
            this.setState((prevState) => ({
                data: [...prevState.data, tab]
            }));
        }
        // Apply filters based on branch configuration
        this.props.getConfigByBranchId(branchId).then((response) => {
            let newData = this.state.data;
            if (!response.rs232Sensors) {
                newData = newData.filter(
                    (item) => item.url !== '/reports/temp-graph'
                );
                if (!response.temperatureSensors) {
                    newData = newData.filter(
                        (item) => item.url !== '/reports/temp-monitoring'
                    );
                }
            } else {
                this.setState({hasTemp: true});
            }
        });
    }

    handleToggleSideMenu = () => {
        this.props.toggleSideMenu();
    };

    handleOnClick = (url: string) => (event: Event) => {
        event.stopPropagation();
        event.preventDefault();
        this.props.hideSideMenu();
        if (this.props.location.pathname.includes(url)) {
            this.props.compareUrlSideMenu();
        }
    };

    render () {
        const jwt = parseJWT(this.props.user.get('token'));
        return (
            <SideMenu isOpen={this.props.isOpen} color={colors.whiteF7} noOrca>
                <ReportsSideMenu.Container isOpen={this.props.isFleetOverviewOpen}>
                    <ReportsSideMenu.Header>
                        <Header>
                            <ReportsSideMenu.Text color={colors.white}>
                                <FormattedMessage id={'views.Reports.SideMenu.Reports'}/>
                            </ReportsSideMenu.Text>
                        </Header>
                    </ReportsSideMenu.Header>
                    <ReportsSideMenu.ListContainer>
                        {this.state.data.length > 0 &&
                            this.state.data.map((item) => {
                                let textColor = colors.green63;
                                let icon = ReportIconGreen;
                                if (
                                    this.props.location.pathname &&
                                    item.url &&
                                    this.props.location.pathname.includes(item.url)
                                    &&
                                    item.url.charAt(item.url.length - 1) === this.props.location.pathname.charAt(this.props.location.pathname.length - 1)
                                ) {
                                    textColor = colors.black;
                                    icon = ReportIconBlack;
                                }
                                if (!item.url) {
                                    textColor = colors.grayDD;
                                    icon = ReportIconBlack;
                                }
                                const menuItem = (
                                    <React.Fragment key={item.translationId}>
                                        <SideMenuItem
                                            source={icon}
                                            translationId={item.translationId}
                                            textStyle={{
                                                fontSize: 17,
                                                fontWeight: 400,
                                                fontFamily: 'OpenSans-SemiBold',
                                                color: textColor,
                                                padding: '0 0 0 22px',
                                            }}
                                            image={{
                                                width: 26,
                                                height: 26,
                                            }}
                                            split={false}
                                            height={85}
                                        />
                                        <Split color={colors.splitGrayReports} opacity={0.2}/>
                                    </React.Fragment>
                                );
                                if (item.url) {
                                    return (
                                        <div
                                            role="link"
                                            key={item.translationId}
                                            onClick={this.handleOnClick(item.url)}
                                            tabIndex={0}
                                        >
                                            <SideMenu.NavItem
                                                to={`/${this.props.intl.locale}${item.url}`}
                                            >
                                                {menuItem}
                                            </SideMenu.NavItem>
                                        </div>
                                    );
                                }

                                return null; /* <React.Fragment key={item.translationId}>{menuItem}</React.Fragment>; */
                            })}
                    </ReportsSideMenu.ListContainer>
                    <CloseButton
                        isOpen={this.props.isOpen}
                        onClick={this.handleToggleSideMenu}
                        top={24}
                        size={30}
                        zIndex={5}
                        backgroundColor={colors.sideMenuGreen}
                    />
                </ReportsSideMenu.Container>
            </SideMenu>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isOpen: selectIsSideMenuOpen(),
    loading: selectIsLoading(),
    selectDrivingBehaviourReport: selectDrivingBehaviourReport(),
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            toggleSideMenu,
            hideSideMenu,
            compareUrlSideMenu,
            getConfigByBranchId,
            getBranchInfo,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportsSideMenuComponent))
);
