// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Dashboard } from 'styles/dashboard';

import type {
    ImmutableList,
    ImmutableMap,
    UserType,
    ColumnType,
    PaginatedType,
} from 'types';

import OrganizationListItem from 'components/OrganizationListItemGeofencingHistory';
import DashboardDataComponent from 'components/DashboardDataComponent';
import GroupButton from 'components/GroupButton';
import OutlineButton from 'components/OutlineButton';

import { selectLocale } from 'services/Language/selectors';

import CaretDown from 'assets/caret-down.png';
import CaretUp from 'assets/caret-up.png';
import Chip from 'components/Chip';
import colors from 'styles/colors';

type SortFunction = ?(a: string | number, b: string | number) => void;

type Props = {
    columns: Array<ColumnType>,
    id: ?string,
    dataKeys: ?Array<string>,
    caretSource: ?string,
    tableData: ?ImmutableList<string>,
    totalItemsCount: ?number,
    tableFooter: ?ImmutableList<string>,
    users: ?boolean,
    list?: boolean,
    onClickDataItem: ?(number) => void,
    onClickViewMore: ?() => void,
    onClickEditUser: ?(user: UserType) => void,
    onClickDeleteUser: ?(user: UserType) => void,
    onClickHeaderItem: ?(key: string, sortMethod: SortFunction) => void,
    locale: string,
    component: ?ReactComponent,
    paginated: ?PaginatedType,
    loading: ?boolean,
    itemsDisplayed?: number,
    showHeaderCaret?: boolean,
    report?: boolean,
    reportFilterText: *,
};

type State = {
    showViewMore: boolean,
    showUserOptions: ?number,
    showEquipmentOptions: ?number,
    sortKey: string,
    // sortedData: ?ImmutableList<string>,
};

class DashboardComponent extends React.PureComponent<Props, State> {
    static defaultProps = {
        list: false,
        paginated: null,
        sliceUsers: true,
        itemsDisplayed: 10,
        showHeaderCaret: true,
        report: false,
    };

    state = {
        showViewMore: false,
        showUserOptions: -1,
        showEquipmentOptions: -1,
        sortKey: '',
        // sortedData: null,
    };

    handleOnClickDataItem = (id: number) => () => {
        this.props.onClickDataItem(id);
    };

    handleShowUserOptions = (user: UserType) => {
        if (this.state.showUserOptions === user.get('userId')) {
            return this.setState({
                showUserOptions: -1,
            });
        }

        this.setState({
            showUserOptions: user.get('userId'),
        });
    };

    handleShowEquipmentOptions = (equipment: EquipmentType) => {
        if (this.state.showEquipmentOptions === equipment.get('id')) {
            return this.setState({
                showEquipmentOptions: -1,
            });
        }

        this.setState({
            showEquipmentOptions: equipment.get('id'),
        });
    };

    getHeaderCaret = (key: string) => (this.state.sortKey === key ? CaretUp : CaretDown);

    secondsToHms = (d) => {
        if (d == 0) return 0;
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);

        const hDisplay = h > 0 ? `${h} h, ` : '';
        const mDisplay = m > 0 ? `${m} m, ` : '';
        const sDisplay = s > 0 ? `${s} s` : '';
        return hDisplay + mDisplay + sDisplay;
    };

    /**
     * Render that shows
     * @param {number} columnSize Decides on how many columns
     * @param {boolean} showExtraColumns show or not total stop time
     * @param {boolean} data get totalVehicleUtilisationTime && totalVehicleDistance
     * @return {Component} rendered component
     */
    renderFooterItem = (columnSize, data) => {
        return (
            <Dashboard.FooterContainer columns={columnSize}>
                <Dashboard.FooterItem key={this.props.columns[0].key}>
                    <Dashboard.FooterText>{'Total'}</Dashboard.FooterText>
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                    <Dashboard.FooterText />
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                    <Dashboard.FooterText />
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                    <Dashboard.FooterText />
                </Dashboard.FooterItem>
                <Dashboard.FooterItem>
                    <Chip type="totalTimeSpent">{this.secondsToHms(data)}</Chip>
                </Dashboard.FooterItem>
            </Dashboard.FooterContainer>
        );
    };

    renderTableData = (showStopTime) => {
        let dataItems = this.props.tableData;
        if (!this.props.tableData) {
            return false;
        }

        if (this.state.sortedData && this.state.sortedData.size > 0) {
            dataItems = this.state.sortedData;
        }

        if (
            typeof this.props.onClickViewMore === 'function' &&
            !this.props.list &&
            dataItems &&
            dataItems.size > this.props.itemsDisplayed
        ) {
            // show the first 10 items.
            dataItems = dataItems.take(this.props.itemsDisplayed);
            this.setState({
                showViewMore: true,
            });
        }

        if (this.props.report) {
            dataItems = dataItems.take(this.props.itemsDisplayed);
        }

        if (
            this.props.paginated &&
            this.props.paginated.get('currentPage') !== this.props.paginated.get('lastPage')
        ) {
            this.setState({
                showViewMore: true,
            });
        }

        const listItems = dataItems
            .filter((data) => {
                if (data.size) {
                    return true;
                }
                return false;
            })
            .map((data: ImmutableMap, index: number) => {
                let componentProps = {
                    rowSpan: this.props.columns.length,
                    data,
                };

                if (this.props.component) {
                    componentProps = {
                        rowSpan: this.props.columns.length,
                        data,
                        ...this.props,
                        showUserOptions: this.state.showUserOptions,
                        showEquipmentOptions: this.state.showEquipmentOptions,
                        handleShowUserOptions: this.handleShowUserOptions,
                        handleShowEquipmentOptions: this.handleShowEquipmentOptions,
                    };
                }

                if (!data) {
                    return false;
                }

                return (
                    <Dashboard.DataItemCustom
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${data.get(this.props.id)}-${index}`}
                        columns={this.props.columns.length}
                        onClick={
                            this.props.onClickDataItem
                                ? this.handleOnClickDataItem(data.get(this.props.id))
                                : null
                        }
                    >
                        {this.props.component ? (
                            <DashboardDataComponent
                                {...componentProps}
                                component={this.props.component}
                            />
                        ) : (
                            <OrganizationListItem
                                columns={this.props.columns}
                                data={data}
                                showStopTime={!showStopTime}
                                rowSpan={this.props.columns.length}
                            />
                        )}
                    </Dashboard.DataItemCustom>
                );
            });
        return (
            <Dashboard.GridContainerCustom columns={this.props.columns.length}>
                {listItems}
            </Dashboard.GridContainerCustom>
        );
    };

    renderShowMoreUsers = () => {
        return this.props.tableData.size > 0 ? (
            <Dashboard.ViewMore onClick={this.props.onClickViewMore}>
                <OutlineButton outlineColor={colors.green73} hoverTextColor={colors.white}>
                    {this.props.loading ? (
                        <FormattedMessage id={'components.Loading'} />
                    ) : (
                        <FormattedMessage id={'views.BranchPage.ViewMore'} />
                    )}
                </OutlineButton>
            </Dashboard.ViewMore>
        ) : null;
    };

    render() {
        const columnSize = 5;
        const name = 0;
        const entryDate = 1;
        const exitDate = 2;
        const spentTime = 3;
        const driverName = 4;
        return (
            <Dashboard loading={this.props.loading}>
                <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
                {this.renderFooterItem(columnSize, this.props.tableFooter)}

                <Dashboard.HeaderContainer columns={columnSize}>
                    <Dashboard.HeaderItem
                        key={this.props.columns[name].key}
                        onClick={
                            this.props.onClickHeaderItem &&
                            this.props.onClickHeaderItem(this.props.columns[name].key, null)
                        }
                    >
                        <Dashboard.HeaderText>
                            {this.props.columns[name].label}{' '}
                        </Dashboard.HeaderText>
                        {this.props.showHeaderCaret && (
                            <Dashboard.HeaderCaret
                                src={this.getHeaderCaret(this.props.columns[name].key)}
                            />
                        )}
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[entryDate].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[exitDate].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[spentTime].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                    <Dashboard.HeaderItem>
                        <Dashboard.HeaderText>
                            {this.props.columns[driverName].label}
                        </Dashboard.HeaderText>
                    </Dashboard.HeaderItem>
                </Dashboard.HeaderContainer>
                <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
                {this.renderTableData(false)}
                <Dashboard.Split columns={columnSize} color={colors.black} height={'3px'} />
            </Dashboard>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    locale: selectLocale(),
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(DashboardComponent)
);
