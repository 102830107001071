import colors from '../../../styles/colors';

export const sortArray = (array) => {
    array.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    });
    return array;
};

export const sortArrayByEmail = (array) => {
    array.sort((a, b) => {
        if (a.emailAddress < b.emailAddress) {
            return -1;
        }
        if (a.emailAddress > b.emailAddress) {
            return 1;
        }
        return 0;
    });
    return array;
};
export const sortByCheckedUsers = (array) => {

    const checked = sortArrayByEmail(array.filter(item => item.hasAlert === true));
    const notChecked = sortArrayByEmail(array.filter(item => item.hasAlert !== true));
    array = [...checked, ...notChecked];

    return array;
};

export const sortByChecked = (array) => {

    const checked = sortArray(array.filter(item => item.hasAlert === true));
    const notChecked = sortArray(array.filter(item => item.hasAlert !== true));
    array = [...checked, ...notChecked];

    return array;
};

export const sortByAlert = (array) => {
    const yellowAlert = sortArray(array.filter(item => item.color === colors.yellowA));
    const redAlert = sortArray(array.filter(item => item.color === colors.redA));
    const noAlert = sortArray(array.filter(item => item.color !== colors.yellowA && item.color !== colors.redA));
    array = [...redAlert, ...yellowAlert, ...noAlert];

    return array;
};

export const handleHotometerFormat = (seconds: string) => {
    if (seconds) {
        const hours = Math.floor(seconds / 3600);
        seconds = seconds - hours * 3600;
        const min = Math.floor(seconds / 60);
        const horometer = parseFloat(`${hours}.${Math.floor((min / 60) * 100)}`);
        return horometer.toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: false,
        });
    }
    return '0';
};

export const metersToKilometers = (meters: string) => {
    if (meters) {
        return (parseFloat(meters) * 0.001).toFixed(2);
    }
    return '0';
};

export const kmToMeters = (km: string) => {
    if (km) {
        return (parseFloat(km) * 1000);
    }
    return '0';
};
