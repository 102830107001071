// @flow

import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';

export const Loader = styled.div`
    position: absolute;
    top: ${(props) => props.top || '50%'};
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
`;

const animation = keyframes`
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
`;

Loader.Animation = styled.div`
    width: ${(props) => props.width || '100px'};
    height: ${(props) => props.height || '100px'};
    border: 5px solid rgb(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 5px solid ${colors.green63};
    animation: ${animation} 2s linear infinite;
`;
