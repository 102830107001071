// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from './styles';

type Props = {
    color: ?string,
    width: ?string,
    height: ?string,
    onClick: ?Function,
};

function GroupButton(props: Props) {
    return (
        <Button
            width={props.width}
            height={props.height}
            color={props.color}
            onClick={props.onClick}
        >
            <Button.Text>{props.children}</Button.Text>
        </Button>
    );
}

export default GroupButton;
