// @flow

import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import Input from './styles';
import type { ReduxDispatch } from 'types';

type Props = {
    disabled: ?boolean,
    margin: ?string,
    placeholderId: string,
    color: ?string,
    type: string,
    name: string,
    underline: ?string,
    underlinecolor: ?string,
    setInnerRef: ?(component: *) => void,
};

class InputField extends React.PureComponent<Props> {
    render() {
        const placeholder = this.props.intl.formatMessage({ id: this.props.placeholderId });

        return (
            <Input
                disabled={this.props.disabled}
                innerRef={this.props.setInnerRef}
                underline={this.props.underline}
                underlinecolor={this.props.underlinecolor}
                error={this.props.error}
                margin={this.props.margin}
                placeholder={placeholder}
                name={this.props.name}
                type={this.props.type}
                color={this.props.color}
                onChange={this.props.onChange}
            />
        );
    }
}

export default injectIntl(InputField);
