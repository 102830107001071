// @flow

export const LOADING = 'app/services/Geofence/LOADING';
export const DISMISS_LOADING = 'app/services/Geofence/DISMISS_LOADING';
export const GET_GEOFENCES_SUCCESS = 'app/services/Vehicle/GET_GEOFENCES_SUCCESS';
export const GET_GEOFENCES_ERROR = 'app/services/Vehicle/GET_GEOFENCES_ERROR';
export const SET_DRAW_MODE = 'app/services/Geofence/SET_DRAW_MODE';
export const TOGGLE_SHOW_GEOFENCES = 'app/services/Geofence/TOGGLE_SHOW_GEOFENCES'
export const SET_SELECTED_GEOFENCE = 'app/services/Geofence/SET_SELECTED_GEOFENCE';
export const SET_CLICKED_GEOFENCE_DATA = 'app/services/Geofence/SET_CLICKED_GEOFENCE_DATA';
export const SET_CREATING_GEOFENCE_MODE = 'app/services/Geofence/SET_CREATING_GEOFENCE_MODE';
export const SET_IS_GEOFENCE_EDITABLE = 'app/services/Geofence/SET_IS_GEOFENCE_EDITABLE';
export const SET_GEOFENCE_DATA = 'app/services/Geofence/SET_GEOFENCE_DATA';

export const receivedGeofencesSuccess = (geofences): GenericActionType => ({
    type: GET_GEOFENCES_SUCCESS,
    geofences,
});

export const receivedGeofencesError = (): GenericActionType => ({
    type: GET_GEOFENCES_ERROR,
});

export const receivedGetConfigByEquipementIdSuccess = (ConfigRecords): GenericActionType => ({
    type: GET_CONFIG_BY_EQUIPEMENT_SUCCESS,
    ConfigRecords,
});

export const startLoading = (): GenericActionType => ({
    type: LOADING,
});

export const dismissLoading = (): GenericActionType => ({
    type: DISMISS_LOADING,
});

export const receivedDrawMode = (drawMode): GenericActionType => ({
    type: SET_DRAW_MODE,
    drawMode,
});

export const receivedToggleShowGeofences = (): GenericActionType => ({
    type: TOGGLE_SHOW_GEOFENCES,
});

export const receivedCurrentGeofence = (geofence): GenericActionType => ({
    type: SET_SELECTED_GEOFENCE,
    geofence,
});

export const receivedClickedGeofenceData = (geofenceData): GenericActionType => ({
    type: SET_CLICKED_GEOFENCE_DATA,
    geofenceData,
});

export const receivedCreatingGeofenceMode = (enabled): GenericActionType => ({
    type: SET_CREATING_GEOFENCE_MODE,
    enabled,
});

export const receivedSetGeofenceEditable = (editable): GenericActionType => ({
    type: SET_IS_GEOFENCE_EDITABLE,
    editable,
});

export const receivedUpdatedGeofenceData = (geofenceData): GenericActionType => ({
    type: SET_GEOFENCE_DATA,
    geofenceData,
});