/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    item: {
        textTransform: 'capitalize',
    },
    list: {
        overflowX: 'scroll',
        height: '100%',
        padding: '10px',
        textTransform: 'capitalize',
    },
    message: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        color: theme.palette.grey[600],
    },
}));

export default useStyles;
