// @flow

import adminRequest from 'services/adminRequest';
import centroid from '@turf/centroid';
import { point , distance} from '@turf/turf';

/**
 *
 * @param {string} token
 * @param {number} branchId
 * @param {*} data
 */
export function retrieveGeofencesByBranch(token: string, branchId: number): Promise<> {
    const url = `branches/${branchId}/geofences`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token,
    );
}

/**
 *
 * @param {string} token
 * @param {number} branchId
 * @param {*} data
 */
export function updateBatchGeofence(token: string, branchId: number, data: *): Promise<> {
    const url = `branches/${branchId}/geofences`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        false,
        token,
    );
}




/**
 *
 * @param {string} token
 * @param {number} branchId
 * @param {*} data
 */
export function updateGeofenceGeoJSON(token: string, branchId: number, geofenceId: number, data): Promise<> {
    const geofence = convertGeofenceGeoJSON(data);
    return updateGeofence(token, branchId, geofenceId, geofence);

}


export function addGeofenceGeoJSON(token: string, branchId: number, data): Promise<> {
    const geofence = convertGeofenceGeoJSON(data);
    return addGeofence(token, branchId, geofence);

}

export function convertGeofenceGeoJSON(data) {
    if (!data || !data.properties) {
        return false;
    }
    let geofence = {};
    if(data.properties.element) {
        geofence = data.properties.element.toJS();
        geofence.maxSpeedThreshold = geofence.maxSpeedThreshold || 0;
        geofence.minSpeedThreshold = geofence.minSpeedThreshold || 0;
        geofence.overspeedQualifyTime = geofence.overspeedQualifyTime || 0;
        geofence.preMaxSpeedThreshold = geofence.preMaxSpeedThreshold || 0;
        geofence.preMinSpeedThreshold = geofence.preMinSpeedThreshold || 0;
        geofence.speedLimitValue = geofence.speedLimitValue || 0;
    }
    else {
        geofence.sendAlarmExit = false;
        geofence.sendAlarm = false;
        geofence.name = "New Geofence";
        geofence.minSpeedThreshold = 0;
        geofence.maxSpeedThreshold = 0;
        geofence.preMinSpeedThreshold = 0;
        geofence.preMaxSpeedThreshold = 0;
        geofence.overspeedQualifyTime = 0;
        geofence.speedLimitValue = 0;
        geofence.vehiclesIds = [];
    }

    const options = {
        units: 'kilometers',
    };

    if (!ciEquals(data.properties.shape, 'circle')) {
        geofence.polygonPoints = toArrayLatLng(data.geometry.coordinates);
        geofence.range = 0;
        geofence.latitude = 0;
        geofence.longitude = 0;
    } else {
        if(data.properties.updatedRadius) {
            geofence.range = parseInt(data.properties.updatedRadius);
        }
        else {
            const center = centroid(data);
            geofence.latitude = center.geometry.coordinates[1];
            geofence.longitude = center.geometry.coordinates[0];

            const firstCoord = data.geometry.coordinates[0];
            const pointOnDiameter = point(firstCoord[0], firstCoord[1]);
            geofence.range = Math.ceil(distance(center, pointOnDiameter, options) * 1000);
        }
        geofence.polygonPoints = [];
        if (geofence.range <= 0) {
            geofence.range = 1;
        } else if (geofence.range > 32767) { // Max int value for radius
            geofence.range = 32767;
        }
    }
    return geofence;
}

function toArrayLatLng(elements) {
    const coordinates = [];
    elements = elements.flat(getArrayDepth(elements) - 2);
    elements.forEach(element => {
        const point = { 'latitude': element[1], 'longitude': element[0] };
        coordinates.push(point);

    });
    return coordinates;
}

function getArrayDepth(value) {
    return Array.isArray(value) ?
        1 + Math.max(...value.map(getArrayDepth)) :
        0;
}


function ciEquals(a, b) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
        : a === b;
}


/**
 *
 * @param {string} token
 * @param {number} branchId
 * @param {*} data
 */
export function updateGeofence(token: string, branchId: number, geofenceId: number, data: *): Promise<> {
    const url = `branches/${branchId}/geofences/${geofenceId}`;

    return adminRequest(
        url,
        {
            method: 'PUT',
            data,
        },
        false,
        token,
    );
}

/**
 *
 * @param {string} token
 * @param {number} branchId
 * @param {*} data
 */
export function addGeofence(token: string, branchId: number, data: *): Promise<> {
    const url = `branches/${branchId}/geofences`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data: '[' + JSON.stringify(data) + ']',
        },
        false,
        token,
    );
}

/**
 *
 * @param {string} token
 * @param {number} branchId
 * @param {*} data
 */
export function deleteGeofence(token: string, branchId: number, geofenceId:number): Promise<> {
    const url = `branches/${branchId}/geofences/${geofenceId}`;
    return adminRequest(
        url,
        {
            method: 'DELETE'
        },
        false,
        token,
    );
}
