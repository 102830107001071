// @flow

import type { GenericActionType } from 'types';

export const USER_AUTHENTICATION_REQUEST =
    'app/services/Authentication/USER_AUTHENTICATION_REQUEST';

export const USER_AUTHENTICATION_SUCCESS =
    'app/services/Authentication/USER_AUTHENTICATION_SUCCESS';
export const USER_AUTHENTICATION_FAIL = 'app/services/Authentication/USER_AUTHENTICATION_FAIL';

export const WHOAMI_SUCCESS = 'app/services/Authentication/WHOAMI_SUCCESS';
export const WHOAMI_FAIL = 'app/services/Authentication/WHOAMI_FAIL';

export const LOGOUT_SUCCESS = 'app/services/Authentication/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'app/services/Authentication/LOGOUT_FAIL';

export const USER_REGISTRATION_SUCCESS = 'app/services/Authentication/USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAIL = 'app/services/Authentication/USER_REGISTRATION_FAIL';

export const PASSWORD_RESET_REQUEST_SUCCESS =
    'app/services/Authentication/PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_FAIL =
    'app/services/Authentication/PASSWORD_RESET_REQUEST_FAIL';

export const PASSWORD_RESET_SUCCESS = 'app/services/Authentication/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'app/services/Authentication/PASSWORD_RESET_FAIL';
export const LOCAL_STORAGE_USER_TOKEN = 'app/services/Authentication/LOCAL_STORAGE_USER_TOKEN';

export const USER_FORGOT_PASSWORD_SUCCESS =
    'app/services/Authentication/USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAIL = 'app/services/Authentication/USER_FORGOT_PASSWORD_FAIL';

export const ADMIN_LOGIN_AS_USER = 'app/services/Authentication/ADMIN_LOGIN_AS_USER';
export const ADMIN_LOGOUT_AS_USER = 'app/services/Authentication/ADMIN_LOGOUT_AS_USER';
export const ADMIN_SPOOF_USER = 'app/services/Authentication/ADMIN_SPOOF_USER';

export const USER_RESET_PASSWORD_SUCCESS =
    'app/services/Authentication/USER_RESET_PASSWORD_SUCCESS';

export const PASSWORD_RESET_ERROR = 'app/services/Authentication/PASSWORD_RESET_ERROR';

export const PASSWORD_CREATE_ERROR = 'app/services/Authentication/PASSWORD_CREATE_ERROR';

export const LOGIN_AS_USER_DELETE = 'app/services/Authentication/LOGIN_AS_USER_DELETE';

export const USER_RENEW_SESSION_SUCCESS = 'app/services/Authentication/USER_RENEW_SESSION_SUCCESS';
export const requestAuthentication = (): GenericActionType => ({
    type: USER_AUTHENTICATION_REQUEST,
});

export const receivedAuthenticationSuccess = (
    token: string,
    expires_at: number
): GenericActionType => ({
    type: USER_AUTHENTICATION_SUCCESS,
    payload: token,
    expiresAt: expires_at,
});

export const receivedAuthenticationFail = (errors: Object): GenericActionType => ({
    type: USER_AUTHENTICATION_FAIL,
    payload: { errors },
});

export const receivedWhoamiSuccess = (user: Object): GenericActionType => ({
    type: WHOAMI_SUCCESS,
    payload: { user },
});

export const receivedWhoamiFail = (errors: Object): GenericActionType => ({
    type: WHOAMI_FAIL,
    payload: { errors },
});

export const receivedLogoutSuccess = (): GenericActionType => ({
    type: LOGOUT_SUCCESS,
});

export const receivedLocalUserToken = (userToken: string): GenericActionType => ({
    type: LOCAL_STORAGE_USER_TOKEN,
    payload: { userToken },
});

export const receivedForgotPasswordSuccess = (): GenericActionType => ({
    type: USER_FORGOT_PASSWORD_SUCCESS,
});

export const receivedForgotPasswordFail = (): GenericActionType => ({
    type: USER_FORGOT_PASSWORD_FAIL,
});

export const receivedResetPasswordSuccess = (): GenericActionType => ({
    type: USER_RESET_PASSWORD_SUCCESS,
});

export const receivedLoginAsUser = (token: string, user: UserType): GenericActionType => ({
    type: ADMIN_LOGIN_AS_USER,
    payload: {
        token,
        user,
    },
});

export const receivedLogoutAsUser = (token: UserType): GenericActionType => ({
    type: ADMIN_LOGOUT_AS_USER,
    payload: {
        token,
    },
});

export const receivedLocalSpoofedUserToken = (user: UserType): GenericActionType => ({
    type: ADMIN_SPOOF_USER,
    payload: user,
});

export const receivedResetPasswordError = (error: string): GenericActionType => ({
    type: PASSWORD_RESET_ERROR,
    error,
});

export const receivedCreatePasswordError = (error: string): GenericActionType => ({
    type: PASSWORD_CREATE_ERROR,
    error,
});

export const receivedAdminLoginAsUserDelete = () => ({
    type: LOGIN_AS_USER_DELETE,
});

export const receivedRenewSessionUser = (token: string, expires_at): GenericActionType => ({
    type: USER_RENEW_SESSION_SUCCESS,
    payload: token,
    expiresAt: expires_at,
});
