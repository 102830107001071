// @flow

import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import {
    GET_ROUTIFIC_SUCCESS,
    GET_ROUTIFIC_ERROR,
    GET_ROUTIFICS_SUCCESS,
    GET_ROUTIFIC_STATUS_SUCCESS,
    LOADING_ROUTIFICS,
} from './actions';

import type { RoutificRecords } from 'types';

const initialState = fromJS({
    // vehicles: JSON.parse(JSON.stringify(mock)),
    routificRecords: {},
    routificsRecords: {},
    routificError: false,
    loading: false,
    routificStatus: false,
});

function routificerviceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case GET_ROUTIFIC_SUCCESS: {
            return state
                .set('routificRecords', fromJS(action.RoutificRecords))
                .set('loading', false);
        }
        case GET_ROUTIFIC_STATUS_SUCCESS: {
            return state.set('routificStatus', fromJS(action.routificStatus));
        }
        case GET_ROUTIFICS_SUCCESS: {
            return state
                .set('routificsRecords', fromJS(action.RoutificsRecords))
                .set('loading', false);
        }
        case GET_ROUTIFIC_ERROR: {
            return state.set('routificError', true).set('loading', false);
        }
        case LOADING_ROUTIFICS:
            return state.set('loading', true);
        default:
            return state;
    }
}

export default routificerviceReducer;
