// @flow

export const DRIVING_REPORT_VALIDATION_ERRORS =
    'app/services/Report/DRIVING_REPORT_VALIDATION_ERRORS';

export const ECONOMIE_REPORT_VALIDATION_ERRORS =
    'app/services/Report/ECONOMIE_REPORT_VALIDATION_ERRORS';

export const MOVE_REPORT_VALIDATION_ERRORS = 'app/services/Report/MOVE_REPORT_VALIDATION_ERRORS';

export const GET_DRIVING_REPORT_SUCCESS = 'app/services/Report/GET_DRIVING_REPORT_SUCCESS';

export const GET_DRIVING_REPORT_SUCCESS_PDF = 'app/services/Report/GET_DRIVING_REPORT_SUCCESS_PDF';

export const GET_DRIVING_REPORT_SUCCESS_XLS = 'app/services/Report/GET_DRIVING_REPORT_SUCCESS_XLS';

export const GET_DRIVING_REPORT_SUCCESS_CSV = 'app/services/Report/GET_DRIVING_REPORT_SUCCESS_CSV';

export const GET_ECONOMIE_REPORT_SUCCESS = 'app/services/Report/GET_ECONOMIE_REPORT_SUCCESS';

export const GET_DRIVING_REPORT_FAIL = 'app/services/Report/GET_DRIVING_REPORT_FAIL';

export const GET_MOVE_REPORT_FAIL = 'app/services/Report/GET_MOVE_REPORT_FAIL';

export const GET_MOVEV1_REPORT_FAIL = 'app/services/Report/GET_MOVEV1_REPORT_FAIL';

export const START_LOADING_REPORT = 'app/services/Report/START_LOADING_REPORT';

export const REMOVE_REPORT_GENERATION_ERROR = 'app/services/Report/REMOVE_REPORT_GENERATION_ERROR';

export const GET_MOVE_REPORT_SUCCESS = 'app/services/Report/GET_MOVE_REPORT_SUCCESS';

export const GET_MOVEV1_REPORT_SUCCESS = 'app/services/Report/GET_MOVEV1_REPORT_SUCCESS';

export const GET_DRIVING_REPORT_BEHAVIOUR_SUCCESS =
    'app/services/Report/GET_DRIVING_REPORT_BEHAVIOUR_SUCCESS';

export const DRIVING_REPORT_BEHAVIOUR_VALIDATION_ERRORS =
    'app/services/Report/DRIVING_REPORT_BEHAVIOUR_VALIDATION_ERRORS';

export const GET_DASHBOARD_REPORT_SUMMARY_SUCCESS =
    'app/services/Report/GET_DASHBOARD_REPORT_SUMMARY_SUCCESS';

export const GET_DASHBOARD_REALTIMES_SUMMARY_SUCCESS =
    'app/services/Report/GET_DASHBOARD_REALTIMES_SUMMARY_SUCCESS';

export const GET_DASHBOARD_GEOFENCE_SUMMARY_SUCCESS =
    'app/services/Report/GET_DASHBOARD_GEOFENCE_SUMMARY_SUCCESS';

export const START_LOADING_REPORT_DASHBOARD_GEOFENCE_SUMMARY =
    'app/services/Report/START_LOADING_REPORT_DASHBOARD_GEOFENCE_SUMMARY';

export const GET_DASHBOARD_INSTANT_SUMMARY_SUCCESS =
    'app/services/Report/GET_DASHBOARD_INSTANT_SUMMARY_SUCCESS';

export const START_LOADING_REPORT_DASHBOARD_INSTANT_SUMMARY =
    'app/services/Report/START_LOADING_REPORT_DASHBOARD_INSTANT_SUMMARY';

export const START_LOADING_REPORT_DASHBOARD_REPORT_SUMMARY =
    'app/services/Report/START_LOADING_REPORT_DASHBOARD_REPORT_SUMMARY';

export const START_LOADING_REPORT_DASHBOARD_REALTIMES_SUMMARY =
    'app/services/Report/START_LOADING_REPORT_DASHBOARD_REALTIMES_SUMMARY';

export const DASHBOARD_INSTANT_VALIDATION_ERRORS =
    'app/services/Report/DASHBOARD_INSTANT_VALIDATION_ERRORS';

export const DASHBOARD_GEOFENCE_VALIDATION_ERRORS =
    'app/services/Report/DASHBOARD_GEOFENCE_VALIDATION_ERRORS';

export const DASHBOARD_REALTIMES_VALIDATION_ERRORS =
    'app/services/Report/DASHBOARD_REALTIMES_VALIDATION_ERRORS';

export const DASHBOARD_REPORT_VALIDATION_ERRORS =
    'app/services/Report/DASHBOARD_REPORT_VALIDATION_ERRORS';

export const GET_GEOFENCING_HISTORY_REPORT_SUCCESS =
    'app/services/Report/GET_GEOFENCING_HISTORY_REPORT_SUCCESS';

export const GET_GEOFENCING_HISTORY_REPORT_FAIL =
    'app/services/Report/GET_GEOFENCING_HISTORY_REPORT_FAIL';

export const CLEAR_MOVE_REPORT = 'app/services/Report/CLEAR_MOVE_REPORT';

export const CLEAR_MOVEV1_REPORT = 'app/services/Report/CLEAR_MOVEV1_REPORT';

export const CLEAR_SPEED_REPORT = 'app/services/Report/CLEAR_SPEED_REPORT';

export const CLEAR_ECONOMY_REPORT = 'app/services/Report/CLEAR_ECONOMY_REPORT';

export const CLEAR_MULTIPLE_HISTORY_REPORT = 'app/services/Report/CLEAR_MULTIPLE_HISTORY_REPORT';

export const GET_MULTIPLE_HISTORY_REPORT_SUCCESS =
    'app/services/Report/GET_MULTIPLE_HISTORY_REPORT_SUCCESS';
export const OPEN_MOVE_REPORT_MODAL = 'app/service/Report/OPEN_MOVE_REPORT_MODAL';
export const CLOSE_MOVE_REPORT_MODAL = 'app/service/Report/CLOSE_MOVE_REPORT_MODAL';
export const SET_MOVE_INFO = 'app/service/Report/SET_MOVE_INFO';
export const SET_MOVE_TYPE = 'app/service/Report/SET_MOVE_TYPE';
export const OPEN_DRIVING_REPORT_MODAL = 'app/service/Report/OPEN_DRIVING_REPORT_MODAL';
export const CLOSE_DRIVING_REPORT_MODAL = 'app/service/Report/CLOSE_DRIVING_REPORT_MODAL';
export const SET_DRIVING_INFO = 'app/service/Report/SET_DRIVING_INFO';

export const clearEcoReport = () => ({
    type: CLEAR_ECONOMY_REPORT,
});
export const clearSpeedReport = () => ({
    type: CLEAR_SPEED_REPORT,
});
export const clearMultipleHistory = () => ({
    type: CLEAR_MULTIPLE_HISTORY_REPORT,
});
export const clearMoveReport = () => ({
    type: CLEAR_MOVE_REPORT,
});
export const clearMoveV1Report = () => ({
    type: CLEAR_MOVEV1_REPORT,
});
export const receivedDrivingReportGenerateValidationError = (errors: *) => ({
    type: DRIVING_REPORT_VALIDATION_ERRORS,
    errors,
});

export const receivedEconomieReportGenerateValidationError = (errors: *) => ({
    type: ECONOMIE_REPORT_VALIDATION_ERRORS,
    errors,
});

export const receivedMoveReportGenerateValidationError = (errors: *) => ({
    type: MOVE_REPORT_VALIDATION_ERRORS,
    errors,
});

export const receivedGenerateDrivingReportSuccess = (data: ReportDataType) => ({
    type: GET_DRIVING_REPORT_SUCCESS,
    payload: data,
});

export const receivedGenerateEconomieReportSuccess = (data: ReportDataType) => ({
    type: GET_ECONOMIE_REPORT_SUCCESS,
    payload: data,
});

export const receivedGenerateMoveReportSuccess = (data: ReportDataType) => ({
    type: GET_MOVE_REPORT_SUCCESS,
    payload: data,
});
export const receivedGenerateMoveV1ReportSuccess = (data: ReportDataType) => ({
    type: GET_MOVEV1_REPORT_SUCCESS,
    payload: data,
});
export const receivedGenerateGeofencingHistoryReportSuccess = (data: ReportDataType) => ({
    type: GET_GEOFENCING_HISTORY_REPORT_SUCCESS,
    payload: data,
});

export const receivedRemoteMoveReportError = (key: string) => ({
    type: REMOVE_REPORT_GENERATION_ERROR,
    stateKey: 'moveReportGenerationErrors',
    key,
});

export const receivedGenerateDrivingReportSuccessPDF = () => ({
    type: GET_DRIVING_REPORT_SUCCESS_PDF,
});

export const receivedGenerateDrivingReportSuccessXLS = () => ({
    type: GET_DRIVING_REPORT_SUCCESS_XLS,
});

export const receivedGenerateDrivingReportSuccessCSV = () => ({
    type: GET_DRIVING_REPORT_SUCCESS_CSV,
});

export const receivedGenerateDrivingReportError = (errors: *) => ({
    type: GET_DRIVING_REPORT_FAIL,
    errors,
});

export const receivedGenerateMoveReportError = (errors: *) => ({
    type: GET_MOVE_REPORT_FAIL,
    errors,
});

export const receivedGenerateMoveV1ReportError = (errors: *) => ({
    type: GET_MOVEV1_REPORT_FAIL,
    errors,
});

export const receivedGenerateGeofencingHistoryReportError = (errors: *) => ({
    type: GET_GEOFENCING_HISTORY_REPORT_FAIL,
    errors,
});

export const receivedGenerateMultipleHistoryReportSuccess = (data: ReportDataType) => ({
    type: GET_MULTIPLE_HISTORY_REPORT_SUCCESS,
    payload: data,
});

export const startLoadingReport = () => ({
    type: START_LOADING_REPORT,
});

export const receivedRemoteDrivingReportError = (key: string) => ({
    type: REMOVE_REPORT_GENERATION_ERROR,
    stateKey: 'drivingReportGenerationErrors',
    key,
});

export const removeEconomieReportError = (key: string) => ({
    type: REMOVE_REPORT_GENERATION_ERROR,
    stateKey: 'economieReportGenerationErrors',
    key,
});

export const receivedGenerateDrivingBehaviourReportSuccess = (data: *) => ({
    type: GET_DRIVING_REPORT_BEHAVIOUR_SUCCESS,
    payload: data,
});

export const receivedGenerateDrivingBehaviourReportError = (errors: *) => ({
    type: DRIVING_REPORT_BEHAVIOUR_VALIDATION_ERRORS,
    errors,
});

export const receivedGenerateDashboardReportSummarySuccess = (data: *) => ({
    type: GET_DASHBOARD_REPORT_SUMMARY_SUCCESS,
    payload: data,
});

export const receivedGenerateDashboardRealtimesSummarySuccess = (data: *) => ({
    type: GET_DASHBOARD_REALTIMES_SUMMARY_SUCCESS,
    payload: data,
});

export const startLoadingDashboardReportSummary = () => ({
    type: START_LOADING_REPORT_DASHBOARD_REPORT_SUMMARY,
});

export const startLoadingDashboardRealtimesSummary = () => ({
    type: START_LOADING_REPORT_DASHBOARD_REALTIMES_SUMMARY,
});

export const receivedGenerateDashboardGeofenceSummarySuccess = (data: *) => ({
    type: GET_DASHBOARD_GEOFENCE_SUMMARY_SUCCESS,
    payload: data,
});

export const startLoadingDashboardGeofenceSummary = () => ({
    type: START_LOADING_REPORT_DASHBOARD_GEOFENCE_SUMMARY,
});

export const receivedGenerateDashboardInstantSummarySuccess = (data: *) => ({
    type: GET_DASHBOARD_INSTANT_SUMMARY_SUCCESS,
    payload: data,
});

export const startLoadingDashboardInstantSummary = () => ({
    type: START_LOADING_REPORT_DASHBOARD_INSTANT_SUMMARY,
});

export const receivedGenerateDashboardInstantError = (errors: *) => ({
    type: DASHBOARD_INSTANT_VALIDATION_ERRORS,
    errors,
});

export const receivedGenerateDashboardGeofenceError = (errors: *) => ({
    type: DASHBOARD_GEOFENCE_VALIDATION_ERRORS,
    errors,
});

export const receivedGenerateDashboardRealtimesError = (errors: *) => ({
    type: DASHBOARD_REALTIMES_VALIDATION_ERRORS,
    errors,
});

export const receivedGenerateDashboardReportError = (errors: *) => ({
    type: DASHBOARD_REPORT_VALIDATION_ERRORS,
    errors,
});

export const receivedOpenMoveReportModal = () => ({
    type: OPEN_MOVE_REPORT_MODAL,
});
export const receivedCloseMoveReportModal = () => ({
    type: CLOSE_MOVE_REPORT_MODAL,
});
export const receivedMoveInfo = (data) => ({
    type: SET_MOVE_INFO,
    payload: data,
});
export const receivedMoveReportType = (type) => ({
    type: SET_MOVE_TYPE,
    payload: type,
});
export const receivedOpenDrivingReportModal = (type) => ({
    type: OPEN_DRIVING_REPORT_MODAL,
    payload: type,
});
export const receivedCloseDrivingReportModal = (type) => ({
    type: CLOSE_DRIVING_REPORT_MODAL,
    payload: type,
});
export const receivedDrivingInfo = (data) => ({
    type: SET_DRIVING_INFO,
    payload: data,
});
