/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import { styled, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const ToggleButton = styled(withTheme(Button))(({ theme }) => ({
    alignSelf: 'center',
    background: theme.palette.primary.main,
    borderRadius: 0,
    color: theme.palette.primary.contrastText,
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
}));
