// @flow

// IMPORTS
import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import { vehicleSameLocation } from 'helpers';

// ACTIONS
import {
    CONNECT,
    CONNECT_FAIL,
    CONNECT_SUCCESS,
    RECEIVED_DATA_SUCCESS,
    DISCONNECT,
    SOCKET_UPDATE,
} from './actions';

// DEFAULT STATE
const initialState = fromJS({
    status: 'Not connected',
    socketConnected: false,
    realTimeData: {},
    socketInstance: null,
    socketBound: false,
});

// MAIN REDUCER
/**
 *
 * @param {*} state
 * @param {*} action
 */
const socketServiceReducer = (state: Object = initialState, action: GenericActionType) => {
    switch (action.type) {
        case DISCONNECT:
            return state
                .set('status', 'disconnected')
                .set('socketConnected', false)
                .set('socketInstance', action.socket)
                .set('socketBound', false);
        case CONNECT:
            return state.set('status', 'connecting').set('socketInstance', action.socket);

        case CONNECT_FAIL:
            return state
                .set('status', 'connection failed')
                .set('socketConnected', false)
                .set('socketBound', false);

        case CONNECT_SUCCESS:
            console.log('CONNECT_SUCCESS');
            return state
                .set('status', 'connected')
                .set('socketConnected', true)
                .set('socketInstance', action.socket);
        case SOCKET_UPDATE:
            return state.set('socketBound', action.bound);
        case RECEIVED_DATA_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            let data = {};
            if (action.message) {
                data = JSON.parse(action.message);
            }
        // return state.set('status', 'data received').set('realTimeData', data);
        default:
            return state;
    }
};

// EXPORTS
export default socketServiceReducer;
