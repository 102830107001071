// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { fromJS } from 'immutable';

import Input from 'components/InputField';
import ResetPassword from 'components/ResetPassword';

import { changeLocale } from 'services/Language/thunks';
import { selectLocale } from 'services/Language/selectors';

import { forgotPassword, resetPassword } from 'services/Authentication/thunks';
import { LoginPage, SidePane } from '../LoginPage/styles';

import {
    selectPasswordResetSent,
    selectForgotPasswordError,
    selectPasswordResetError,
} from 'services/Authentication/selectors';

import { Content, ForgotPasswordPage } from 'styles/forgotPassword';
import colors from 'styles/colors';

import { LogoAndLogoType } from 'components/Icons';

type Props = {
    intl: Object,
    resetPasswordPage: ?boolean,
};

type State = {
    email: string,
    errors: {
        'recovery-email': boolean,
    },
};

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export class ForgotPasswordPageComponent extends React.Component<Props, State> {
    state = {
        email: '',
        errors: {},
    };

    componentDidMount() {
        if (this.props.match.params.locale) {
            if (this.props.locale !== this.props.match.params.locale) {
                this.props.changeLocale(this.props.match.params.locale);
            }
        }
    }

    handleClickHome = () => {
        this.props.history.push(`/${this.props.locale}/login`);
    };

    handleOnChangeEmail = (e) => {
        this.setState({
            email: e.target.value,
        });
    };

    handleSendEmail = (e: Event) => {
        e.preventDefault();
        if (!this.state.email || !EMAIL_REGEX.test(this.state.email)) {
            return this.setState({
                errors: {
                    'recovery-email': true,
                },
            });
        }
        this.setState({
            errors: {},
        });
        return this.props.forgotPassword(this.state.email);
    };

    handleResetPassword = (email: string, password: string, resetToken: string) => {
        this.props.resetPassword(email, password, resetToken);
    };

    renderForgotPasswordText = () => {
        if (this.props.passwordResetSent) {
            return (
                <React.Fragment>
                    <p>
                        <FormattedMessage id={'views.ForgotPasswordPage.Sent.Text.1'} />
                    </p>
                    <p>
                        <FormattedMessage id={'views.ForgotPasswordPage.Sent.Text.2'} />
                    </p>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <p>
                    <FormattedMessage id={'views.ForgotPasswordPage.Text.1'} />
                </p>
                <p>
                    <FormattedMessage id={'views.ForgotPasswordPage.Text.2'} />
                </p>
            </React.Fragment>
        );
    };

    renderForgotPassword = () => {
        return (
            <ForgotPasswordPage>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.ForgotPasswordPage.helmetTitle',
                        })}
                    </title>
                    <meta name="description" content="Forgot password page" />
                </Helmet>
                <SidePane display={'block'}>
                    <SidePane.Logo onClick={this.handleClickHome}>
                        <LogoAndLogoType />
                        <p>
                            <FormattedMessage id={'components.Login.LogoTitle'} />
                        </p>
                    </SidePane.Logo>
                    <Content.ForgotPasswordText>
                        {this.renderForgotPasswordText()}
                    </Content.ForgotPasswordText>
                    {this.props.passwordResetSent ? null : (
                        <React.Fragment>
                            <Content.Form onSubmit={this.handleSendEmail}>
                                <Input
                                    type={'text'}
                                    name={'recovery-email'}
                                    placeholderId={'views.ForgotPasswordPage.Placeholder.Email'}
                                    underlinecolor={colors.white}
                                    onChange={this.handleOnChangeEmail}
                                    error={fromJS(this.state.errors['recovery-email'])}
                                />

                                <ForgotPasswordPage.ButtonContainer>
                                    <ForgotPasswordPage.LoginButton type={'submit'}>
                                        <FormattedMessage
                                            id={'views.ForgotPasswordPage.SendEmail'}
                                        />
                                    </ForgotPasswordPage.LoginButton>
                                </ForgotPasswordPage.ButtonContainer>
                            </Content.Form>
                        </React.Fragment>
                    )}
                </SidePane>

                {/* BACKGROUND */}
                <LoginPage.Background status={'entered'} />
                {/* end - BACKGROUND */}
            </ForgotPasswordPage>
        );
    };

    render() {
        if (this.props.resetPasswordPage) {
            return (
                <ResetPassword
                    token={this.props.match.params.token}
                    onPasswordReset={this.handleResetPassword}
                    passwordResetError={this.props.passwordResetError}
                />
            );
        }

        return this.renderForgotPassword();
    }
}

const mapStateToProps: Object = createStructuredSelector({
    passwordResetSent: selectPasswordResetSent(),
    fogotPasswordError: selectForgotPasswordError(),
    passwordResetError: selectPasswordResetError(),
    locale: selectLocale(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            forgotPassword,
            resetPassword,
            changeLocale,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ForgotPasswordPageComponent))
);
