// @flow

import React from 'react';
import ImpersonationStyles from './styles';
import { FormattedMessage } from 'react-intl';

type Props = {
    loggedInAsUser: *,
    adminLogoutAsUser: *,
};

const Impersonation = (props) =>
    !props.loggedInAsUser ? null : (
        <ImpersonationStyles>
            <ImpersonationStyles.User>
                <FormattedMessage id={'containers.Impersonation.loggedAs'} />
                <span>{` ${props.loggedInAsUser.get('firstName')} ${props.loggedInAsUser.get(
                    'lastName'
                )}`}</span>
            </ImpersonationStyles.User>
            <ImpersonationStyles.ExitButton onClick={props.adminLogoutAsUser}>
                <FormattedMessage id={'containers.Impersonation.admin'} /> &#x21B2;
            </ImpersonationStyles.ExitButton>
        </ImpersonationStyles>
    );

export default Impersonation;
