// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import screen from 'styles/screen';
import Select from 'react-select';

const StyledSelect = styled(Select)`
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-family: OpenSans;
`;

StyledSelect.Container = styled.div.attrs({
    disabled: ({ disabled }) => disabled || false,
    bordercolor: ({ borderColor }) => borderColor || colors.green73,
    textcolor: ({ textColor }) => textColor || colors.black,
    padding: ({ padding }) => padding || '0 0 0 12px',
    backgroundcolor: ({ backgroundColor }) => backgroundColor || colors.white,
    hoverbackgroundcolor: ({ hoverBackgroundColor }) => hoverBackgroundColor || colors.white,
    arrowcolor: ({ arrowColor }) => arrowColor || '#9b9ba5',
    center: ({ center }) => center,
})`
    opacity: ${(props) => (props.disabled ? '0.3;' : '1;')}
    display: flex;
    flex: 1;
    border-radius: 10px;
    ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`};
    min-width: ${(props) => (props.minWidth ? props.minWidth : '230px')};

    & .react-select__control {
        cursor: pointer;
        height: 100%;
        border-radius: 0;
        border-color: ${({ borderColor }) => borderColor} !important;
        background-color: ${({ backgroundColor }) => backgroundColor};
        box-shadow: none;
        transition: all 250ms ease-in-out 0s;
    }

    &:hover {
        .react-select__control {
            background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
        }
    }

    & .react-select__control--is-focused {
        border-color: ${({ borderColor }) => borderColor} !important;
        box-shadow: none;
    }

    & .react-select__indicator-separator {
        width: 0;
    }

    & .react-select__dropdown-indicator {
        color: ${({ arrowColor }) => arrowColor};
    }

    & .react-select__menu {
        border-radius: 0;
    }

    & .react-select__single-value {
        color: ${({ textColor }) => textColor};
    }

    /* Some hacky stuff to get the value container centered.*/
    ${({ center }) =>
        center
            ? `
        & .react-select__value-container {
            align-items: center;
            justify-content: center;
            left: 15px; /* half of the indicator width */
        }

        & .react-select__option {
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    `
            : ''};

    & .react-select--is-disabled {
        .react-select__single-value {
            color: ${({ textColor }) => colors.grayDD};
        }
        .react-select__dropdown-indicator {
            color: ${({ arrowColor }) => colors.grayDD};
        }
    }
`;

export default StyledSelect;
