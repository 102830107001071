// @flow

// REDUX TYPES
export const TOGGLE_SIDEMENU = 'app/services/SideMenu/TOGGLE_SIDEMENU';
export const SHOW_SIDEMENU = 'app/services/SideMenu/SHOW_SIDEMENU';
export const HIDE_SIDEMENU = 'app/services/SideMenu/HIDE_SIDEMENU';
export const TOGGLE_RESIZE_MAP = 'app/services/SideMenu/TOGGLE_RESIZE_MAP';
export const DO_RESIZE_MAP = 'app/services/SideMenu/DO_RESIZE_MAP';
export const COMPARE_URL_SIDEMENU = 'app/services/SideMenu/COMPARE_URL_SIDEMENU';
export const TOGGLE_ACTIVEBAR = 'app/services/SideMenu/TOGGLE_ACTIVEBAR';
export const SHOW_ACTIVEBAR = 'app/services/SideMenu/SHOW_ACTIVEBAR';
export const HIDE_ACTIVEBAR = 'app/services/SideMenu/HIDE_ACTIVEBAR';
export const SHOW_FLEET_OVERVIEW = 'app/services/SideMenu/SHOW_FLEET_OVERVIEW';
export const HIDE_FLEET_OVERVIEW = 'app/services/SideMenu/HIDE_FLEET_OVERVIEW';
export const SET_MULTIPLE_SELECT = 'app/services/SideMenu/SET_MULTIPLE_SELECT';
export const SET_SELECTED_VEHICLES = 'app/services/SideMenu/SET_SELECTED_VEHICLES';
export const SET_SELECTED_VEHICLES_DATA = 'app/services/SideMenu/SET_SELECTED_VEHICLES_DATA';
export const TOGGLE_RIGHT_SIDEMENU = 'app/services/SideMenu/TOGGLE_RIGHT_SIDEMENU';
export const SET_LANG_UNIT = 'app/services/SideMenu/SET_LANG_UNIT';

// MAIN ACTIONS
/**
 *
 * @param {*} bool
 */
export const receivedIsMultipleSelect = (bool): GenericActionType => ({
    type: SET_MULTIPLE_SELECT,
    payload: bool,
});

/**
 *
 * @param {*} bool
 */
export const receivedSelectedVehicles = (vehicles): GenericActionType => ({
    type: SET_SELECTED_VEHICLES,
    payload: vehicles,
});

/**
 *
 * @param {*} bool
 */
export const receivedSelectedVehiclesData = (vehicles): GenericActionType => ({
    type: SET_SELECTED_VEHICLES_DATA,
    payload: vehicles,
});

/**
 *
 * @param {*} bool
 */
export const receivedToggleSideMenu = (): GenericActionType => ({
    type: TOGGLE_SIDEMENU,
});

/**
 *
 * @param {*} bool
 */
export const receivedShowSideMenu = (): GenericActionType => ({
    type: SHOW_SIDEMENU,
});

/**
 *
 * @param {*} bool
 */
export const receivedHideSideMenu = (): GenericActionType => ({
    type: HIDE_SIDEMENU,
});

/**
 *
 * @param {*} bool
 */
export const receivedToggleResizeMap = (): GenericActionType => ({
    type: TOGGLE_RESIZE_MAP,
});

/**
 *
 * @param {*} bool
 */
export const receivedDoResizeMap = (): GenericActionType => ({
    type: DO_RESIZE_MAP,
});

/**
 *
 * @param {*} bool
 */
export const receivedCompareUrlSideMenu = () => ({
    type: COMPARE_URL_SIDEMENU,
});

/**
 *
 * @param {*} bool
 */
export const receivedToggleActiveBar = (): GenericActionType => ({
    type: TOGGLE_ACTIVEBAR,
});

/**
 *
 * @param {*} bool
 */
export const receivedShowActiveBar = (): GenericActionType => ({
    type: SHOW_ACTIVEBAR,
});

/**
 *
 * @param {*} bool
 */
export const receivedHideActiveBar = (): GenericActionType => ({
    type: HIDE_ACTIVEBAR,
});

/**
 *
 * @param {*} bool
 */
export const receivedShowFleetOverview = (): GenericActionType => ({
    type: SHOW_FLEET_OVERVIEW,
});

/**
 *
 * @param {*} bool
 */
export const receivedHideFleetOverview = (): GenericActionType => ({
    type: HIDE_FLEET_OVERVIEW,
});

/**
 *
 * @param {*} bool
*/
export const receivedToggleRightSideMenu = (currentRightSideMenu: boolean): GenericAction => ({
    type: TOGGLE_RIGHT_SIDEMENU,
    currentRightSideMenu,
});
/**
 *
 * @param {*} bool
 */
 export const receivedLangUnit = (): GenericActionType => ({
    type: SET_LANG_UNIT,
});