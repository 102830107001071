// @flow

import React,{PureComponent, type Node } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import 'react-slidedown/lib/slidedown.css';
import dateFns from 'date-fns';
import { Split } from 'styles/common';
import colors from 'styles/colors';
import {
    ReportGeneratorContainer,
    DrivingReportGenerator,
    InputLabel,
} from 'styles/reports';
import ReportVehicleSearch from 'components/ReportVehicleSearch';
import Loader from 'components/Loader';
import Error from 'components/Error';
import {
    validateDrivingReportGenerator,
    generateDrivingReport,
    removeDrivingReportError,
} from 'services/Report/thunks';
import {
    selecDrivingReportGenerationErrors,
    selectIsLoading,
    selectDrivingReport,
} from 'services/Report/selectors';
import { selectVehicles } from 'services/Vehicle/selectors';
import { OUTPUT_FORMATS, REPORT_TYPES, PRESET_PERIODS } from 'services/Report/constants';
import { selectFleetEquipment, selectFleetEquipmentNextPage } from 'services/Vehicle/selectors';
import {
    getFleetEquipment,
    getVehicle,
    getVehicleHistoryDetail,
} from '../../../services/Vehicle/thunks';
import { selectModuleRecords } from '../../../services/Config/selectors';

import {
    getModuleByEquipementId,
} from '../../../services/Config/thunks';
import { Button, Modal, DatePicker, Input, Checkbox } from 'antd';
import moment from 'moment';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Brush,
    ReferenceLine,
    Label,
} from 'recharts';
import { jsPDF } from 'jspdf';
import RangeDatePicker from 'components/RangeDatePicker';
import { differenceInMinutes } from 'date-fns';
import Logo from '../../../assets/orca-logo-black-min.png';
import domtoimage from 'dom-to-image';

const today = dateFns.format(new Date(), 'YYYY-MM-DD');
type Props = {};
type State = {
    equipmentIds: Array<number>,
    gasPrice: string,
    periodFrom: string,
    period: string,
    reportGeneratedFilterOpen: boolean,
    format: string,
    type: string,
};
class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value, dataKey, xOffset, yOffset } = this.props;

      console.log(value);
      return (
        <text x={x} y={y} dx={xOffset} dy={yOffset} fill={stroke} fontSize={8} textAnchor="middle">
          {dataKey}
        </text>
      );
    }
  }
  
export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    state = {
        equipmentIds: [],
        gasPrice: '',
        periodFrom: '',
        periodTo: '',
        period: PRESET_PERIODS.custom,
        reportGeneratedFilterOpen: false,
        format: OUTPUT_FORMATS.page,
        type: REPORT_TYPES.detailed,
        actualPage: 1,
        selectedIdVehicle: -1,
        tempDatas: [],
        timeInterval: 2,
        currentStartEndex: 0,
        currentEndIndex: 0,
        actualReferenceLineLabel: '',
        actualReferenceLineTimeHour: 0,
        actualReferenceLineTimeMin: 0,
        referencesValue: [],
        isModalShowing: false,
        date: new Date(),
        isModalDeleteEventShowing: false,
        deletedEvents: [],
    };


    componentDidMount = () => {
        this.setState({ periodFrom: today, periodTo: today });
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevState.selectedIdVehicle !== this.state.selectedIdVehicle || prevState.actualPage !== this.state.actualPage) {
            if (this.state.selectedIdVehicle !== -1 && this.state.actualPage > 1) {
                this.handleOnCheckVehicles(this.state.selectedIdVehicle, true);
            }
        }

    }

    handleOnCheckVehicles = (id: number, isDataLoading: boolean) => {
        const selectedId = this.state.selectedIdVehicle;
        if (selectedId !== id || (selectedId === id && isDataLoading !== undefined)) {//check if same vehicle is checked
            this.props.getModuleByEquipementId(id).then(() => {
                if (this.props.moduleRecords.size > 0) {
                    this.props.getFleetEquipment(this.props.moduleRecords.get('serialNumber'), this.state.periodFrom, this.state.periodTo, this.state.actualPage === 0 ? 1 : this.state.actualPage, true)
                        .then(() => {
                            if (selectedId !== id) {
                                this.setState({
                                    tempDatas: this.props.selectedFleetEquipment,
                                    actualPage: this.props.selectFleetEquipmentNextPage,
                                });
                            } else {
                                this.setState({
                                    tempDatas: [...this.state.tempDatas, ...this.props.selectedFleetEquipment],
                                    actualPage: this.props.selectFleetEquipmentNextPage,
                                });
                            }
                        });
                }
            });

            this.setState({ selectedIdVehicle: id });

            const equipmentIds = this.state.equipmentIds.slice();
            if (equipmentIds.indexOf(id) >= 0) {
                equipmentIds.splice(equipmentIds.indexOf(id), 1);
            } else {
                equipmentIds.push(id);
            }
            if (equipmentIds.length > 0 && this.props.errors.get('equipmentIds')) {
                this.props.removeDrivingReportError('equipmentIds');
            }
            this.setState({
                equipmentIds,
            });
        }

    };

    handleGenerateReport = () => {
        if (this.state.selectedIdVehicle !== -1) {
            const graphique = document.getElementById('graphique');
            console.log = function() {
            };
            //graphique
            domtoimage.toPng(graphique).then((canvas) => {
                const selectedVehicle = this.props.equipment.filter((equipment) => equipment.get('id') === this.state.selectedIdVehicle).toArray();
                if (selectedVehicle.length !== 0) {

                    let imgData = new Image();
                    imgData.src = canvas;
                    const pdf = new jsPDF();
                    const generateAt = this.props.intl
                        .formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfGenerated' })
                        .toString();
                    pdf.setFont(undefined, 'bold');
                    pdf.setFontSize(12);
                    pdf.addImage(Logo, 'png', 12, 8);
                    pdf.text('Températures du véhicule : ' + selectedVehicle[0].get('name'), 70, 27);
                    pdf.setFontSize(8);
                    pdf.text(generateAt + moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 150, 10);
                    pdf.addImage(imgData, 'png', 0, 40, 200, 120);
                    pdf.save('RS232.pdf');
                }
            });
        }
    };

    handleShowModal = () => {
        //Remettre le Brush en fontion de l
        if (this.state.timeInterval === 2) {
            this.setState({
                currentStartEndex: 0,
                currentEndIndex: 0,
            });
        }

        this.setState({ isModalShowing: true });
    };

    handleCloseModal = () => {
        this.setState({ isModalShowing: false, isModalDeleteEventShowing: false, deletedEvents: [] });
    };


    isNameDisplayed = (datas, index) => {
        let filteredDatas = datas.filter((data, i) => datas[index].name === data.name && i < index);
        return filteredDatas.length !== 0;
    };

    getDatas = (timeInterval) => {
        const datas = [];
        let tab = [];
        this.state.tempDatas.map((item, index) => {
            if (item.rs232[0].valueCelsius !== null && item.rs232[1].valueCelsius !== null && item.rs232[2].valueCelsius !== null && item.rs232[3].valueCelsius !== null) {
                datas.push({
                    //name: new Date(item.datetime),
                    name: new Date(item.datetime).getHours() + 'h ' + new Date(item.datetime).getMinutes() + 'm',
                    T1: item.rs232[0].valueCelsius > -45 && item.rs232[0].valueCelsius < 45 ? item.rs232[0].valueCelsius : null,
                    T2: item.rs232[1].valueCelsius > -45 && item.rs232[1].valueCelsius < 45 ? item.rs232[1].valueCelsius : null,
                    T3: item.rs232[2].valueCelsius > -45 && item.rs232[2].valueCelsius < 45 ? item.rs232[2].valueCelsius : null,
                    T4: item.rs232[3].valueCelsius > -45 && item.rs232[3].valueCelsius < 45 ? item.rs232[3].valueCelsius : null,
                    date: item.datetime,
                });

            }

        });
        if (datas.length > 0) {
            let dateTemp = new Date(datas[0].date);
            let differenceMin;
            tab = [];
            datas.map((item, index) => {
                differenceMin = differenceInMinutes(
                    new Date(item.date),
                    new Date(dateTemp),
                );

                if (differenceMin >= timeInterval || index === 0) {
                    tab.push({
                        name: item.name,
                        T1: item.T1,
                        T2: item.T2,
                        T3: item.T3,
                        T4: item.T4,
                        date: item.date,
                    });

                    dateTemp = item.date;

                }
            });
        }

        return tab;
    };

    handleSetEvent = (event) => {
        if (event !== null) {
            const date = new Date(event._d);
            this.setState({
                actualReferenceLineTimeHour: date.getHours(),
                actualReferenceLineTimeMin: date.getMinutes(),
                date: date,
            });
        }
    };

    handleConfirmDelete = () => {
        if (this.state.deletedEvents.length !== 0) {
            const newReferences = this.state.referencesValue.filter((reference) => {
                const deletedEvents = this.state.deletedEvents.filter((date) => new Date(date).getTime() === new Date(reference.date).getTime());
                if (deletedEvents.length === 0) {
                    return reference;
                }
            });
            this.setState({
                referencesValue: newReferences,
                deletedEvents: [],
                isModalDeleteEventShowing: false,
            });
        }
    };

    handleSetReferenceLabel = (label) => {
        this.setState({
            actualReferenceLineLabel: label,
        });
    };

    isTimeDisplayed = () => {
        let datasFiltered = this.state.referencesValue.filter((data) => data.name === (this.state.actualReferenceLineTimeHour + 'h ' + this.state.actualReferenceLineTimeMin + 'm'));
        return datasFiltered.length !== 0;
    };

    addNewReference = () => {
        //verifie d'abord si l'evenement existe deja
        const existingReferences = this.state.referencesValue.filter((ref) => new Date(ref.date).getTime() === new Date(this.state.date).getTime());
        if (existingReferences.length === 0) {
            //ajouter le reference Time au tableau au cas ou ils existent pas
            const datas = this.getDatas(this.state.timeInterval);
            //verifier si la date entre est dans les bonne plage
            if (new Date(this.state.date).getTime() < new Date(datas [0].date).getTime() || new Date(this.state.date).getTime() > new Date(datas [datas.length - 1].date).getTime()) {
                this.setState({
                    actualReferenceLineLabel: '',
                    actualReferenceLineTimeHour: 0,
                    actualReferenceLineTimeMin: 0,
                    isModalShowing: false,
                });
            } else {
                // sinon ajoute dans la plage
                let referenceBefore = null;
                let referenceAfter = null;
                datas.map((element, index) => {
                    if (new Date(element.date) < this.state.date) {
                        referenceBefore = element;
                    } else {
                        if (referenceAfter === null) {
                            referenceAfter = element;
                        }
                    }
                });
                if (referenceBefore !== null) {
                    const existingDatas = datas.filter((data) => moment(data.date).format('YYYY-MM-DD HH:mm') === moment(this.state.date).format('YYYY-MM-DD HH:mm'));
                    if (existingDatas.length !== 0) {
                        this.setState({
                            referencesValue: [...this.state.referencesValue,
                                {
                                    name: this.state.actualReferenceLineTimeHour + 'h ' + this.state.actualReferenceLineTimeMin + 'm',
                                    isExistingReference: true,
                                    label: this.state.actualReferenceLineLabel,
                                    date: this.state.date,
                                }],
                            isModalShowing: false,
                            actualReferenceLineLabel: '',
                            actualReferenceLineTimeHour: 0,
                            actualReferenceLineTimeMin: 0,
                        });
                    } else {
                        this.setState({
                            referencesValue: [...this.state.referencesValue,
                                {
                                    name: this.state.actualReferenceLineTimeHour + 'h ' + this.state.actualReferenceLineTimeMin + 'm' + (this.isTimeDisplayed() ? ' ' : ''),
                                    T1: (referenceBefore.T1 + referenceAfter.T1) / 2,
                                    T2: (referenceBefore.T2 + referenceAfter.T2) / 2,
                                    T3: (referenceBefore.T3 + referenceAfter.T3) / 2,
                                    T4: (referenceBefore.T4 + referenceAfter.T4) / 2,
                                    isEventNewReference: true,
                                    label: this.state.actualReferenceLineLabel,
                                    date: this.state.date,
                                }],
                            isModalShowing: false,
                            actualReferenceLineLabel: '',
                            actualReferenceLineTimeHour: 0,
                            actualReferenceLineTimeMin: 0,
                        });
                    }

                }
            }

        } else {
            this.setState({
                actualReferenceLineLabel: '',
                actualReferenceLineTimeHour: 0,
                actualReferenceLineTimeMin: 0,
                isModalShowing: false,
            });
        }


    };

    injectReferencesData = () => {
        let datas = this.getDatas(this.state.timeInterval);
        let tab = [];

        //Ajouter les references Lines
        if (this.state.referencesValue.length !== 0) {
            const references = [];
            this.state.referencesValue.forEach((reference) => {
                const presentData = datas.filter((data, index) => {
                    if (moment(reference.date).format('YYYY-MM-DD HH:mm') === moment(data.date).format('YYYY-MM-DD HH:mm')) {
                        datas[index].isExistingReference = true;
                        datas[index].label = reference.label;
                        return data;
                    }
                });
                if (presentData.length === 0) {
                    references.push(reference);
                }
            });
            if (references.length !== 0) {
                datas.push(...references);
                datas.sort((a, b) => {
                    //A refractorer en manipulant date
                    let dateA = new Date(a.date);
                    let dateB = new Date(b.date);
                    if (dateA < dateB) {
                        return -1;
                    } else if (dateA > dateB) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
        }
        if (datas.length !== 0) {
            tab = datas.map((data, index) => {
                if (this.isNameDisplayed(datas, index)) {
                    data.name += ' ';
                }
                return data;
            });
            datas = tab;
        }
        return datas;
    };

    CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let referenceState = payload[0].payload.isEventNewReference;
            if (referenceState !== undefined) {
                return (
                    <div className='custom-tooltip'>
                        <div className='label'>{label}</div>
                        <p className='desc'>{payload[0].payload.label}</p>
                    </div>
                );
            } else {
                referenceState = payload[0].payload.isExistingReference;
                return (
                    <div className='custom-tooltip'>
                        <div>{label}</div>
                        {payload.map((element, index) => {
                            return (
                                <div key={index}>
                                    <div style={{ color: element.stroke }}>{element.name} : {element.value}</div>
                                </div>
                            );
                        })}
                        {referenceState !== undefined ? <p className='desc'>{payload[0].payload.label}</p> : ''}
                    </div>
                );
            }
        }
        return null;
    };

    onCalendarChange = (value) => {
        if (value.length === 2) {
            this.setState({
                selectedIdVehicle: -1,
                tempDatas: [],
                periodFrom: moment(value[0]._d, 'YYYY-MM-DDTHH:mm').utc().format(),
                periodTo: moment(value[1]._d, 'YYYY-MM-DDTHH:mm').utc().format(),
            });
        }
    };

    handleModalEvent = () => {
        this.setState({ isModalDeleteEventShowing: true });
    };

    handleDeleteEvent = (event, actuDate) => {
        if (this.state.referencesValue.length !== 0) {
            const deletedEvent = this.state.deletedEvents.filter(date => new Date(date).getTime() === new Date(actuDate).getTime());
            if (deletedEvent.length === 0) {
                this.setState({
                    deletedEvents: [...this.state.deletedEvents, actuDate],
                });
            } else {
                this.setState({
                    deletedEvents: this.state.deletedEvents.filter((date => new Date(date).getTime() !== new Date(actuDate).getTime())),
                });
            }
        }
    };

    renderGenerator = () => {
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }


        const datas = this.injectReferencesData();
        return (
            <div>
                <Modal
                    title={this.props.intl.formatMessage({
                        id: 'containers.Reports.Temperature.AddEvent',
                    })}
                    visible={this.state.isModalShowing}
                    onCancel={this.handleCloseModal}
                    footer={[
                        <Button
                            key='cancel'
                            type='primary'
                            onClick={this.handleCloseModal}
                            style={{
                                backgroundColor: colors.redA,
                                borderColor: colors.redA,
                            }}
                        >
                            <FormattedMessage id={'containers.Maintenance.Modal.Cancel'} />
                        </Button>,
                        <Button
                            key='confirmer'
                            type='primary'
                            onClick={this.addNewReference}
                            style={{
                                backgroundColor: colors.green57,
                                borderColor: colors.green57,
                            }}
                        >
                            <FormattedMessage id={'containers.Maintenance.Modal.Confirm'} />
                        </Button>,

                    ]}>
                    <div>
                        <div>
                            <div style={{ marginBottom: '10px' }}>
                                <DatePicker
                                    format={'YYYY-MM-DD HH:mm'}
                                    showTime={{ format: 'HH:mm' }}
                                    onChange={(event) => this.handleSetEvent(event)}
                                    defaultValue={moment(
                                        this.state.periodFrom,
                                    )}
                                />
                            </div>

                            <Input
                                value={this.state.actualReferenceLineLabel}
                                onChange={(event) => this.handleSetReferenceLabel(event.target.value)}
                            />
                        </div>

                    </div>
                </Modal>

                <DrivingReportGenerator>
                    <RangeDatePicker onCalendarChange={this.onCalendarChange} />
                </DrivingReportGenerator>

                <div style={{ display: 'flex' }}>
                    <InputLabel margin={'30px 0 20px 0'}>
                        <FormattedMessage id={'containers.Reports.Generators.Driving.Vehicles'} />
                    </InputLabel>
                </div>

                {this.props.errors.get('equipmentIds') && (
                    <DrivingReportGenerator.Error>
                        <Error
                            text={this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.Driving.EquipmentIdsError',
                            })}
                        />
                    </DrivingReportGenerator.Error>
                )}

                <div style={{ display: 'flex' }}>
                    <div>
                        <ReportVehicleSearch
                            equipment={this.props.equipment}
                            onCheckVehicles={this.handleOnCheckVehicles}
                            selectedIdVehicle={this.state.selectedIdVehicle}
                            checkedInputs={this.state.equipmentIds}
                            isTempReports={true}
                        />
                    </div>


                    <div style={{ width: '75%' }} id={'graphique'}>
                        <Loader loading={this.state.selectedIdVehicle !== -1 && this.state.actualPage !== 0} />
                        <ResponsiveContainer>
                            <LineChart
                                data={this.state.selectedIdVehicle !== -1 && this.state.actualPage !== 0 ? [] : datas}
                                margin={{
                                    top: 15,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >

                                <CartesianGrid strokeDasharray='6 6' />
                                <XAxis dataKey='name'
                                />
                                <YAxis />
                                <Tooltip content={this.CustomTooltip} />
                                <Legend />
                                <Line type='monotone' dataKey='T1' stroke='orange' strokeWidth={2}  label = {<CustomizedLabel stroke='orange' dataKey='T1' xOffset={7} yOffset={-8}/>}/>
                                <Line type='monotone' dataKey='T2' stroke='red'  strokeWidth={2}  label = {<CustomizedLabel stroke='red' dataKey='T2' xOffset={7} yOffset={8}/>}/>
                                <Line type='monotone' dataKey='T3' stroke='#a417b5b0' strokeWidth={2}   label = {<CustomizedLabel stroke='#a417b5b0' dataKey='T3' xOffset={-7} yOffset={-8}/>}/>
                                <Line type='monotone' dataKey='T4' stroke='green' strokeWidth={2}  s label = {<CustomizedLabel stroke='green' dataKey='T4' xOffset={-7} yOffset={8}/>}/>
                                {
                                    this.state.referencesValue.map((reference, index) => {
                                        return (
                                            <ReferenceLine key={index} x={reference.name} stroke='black'

                                            >
                                                <Label value={reference.label} offset={0} position='top' />
                                            </ReferenceLine>
                                        );
                                    })
                                }
                                <Brush
                                    dataKey='name'
                                    // onChange={this.onChangeBrush(datas)}
                                    //  startIndex={this.state.currentStartEndex}
                                    //  endIndex={this.state.currentEndIndex === 0 && datas.length !== 0 ? datas.length - 1 : this.state.currentEndIndex}
                                      startIndex={0}
                                      endIndex={datas.length >= 10 ? 10 : datas.length - 1}
                                    stroke='#63ac37'
                                    height={30}
                                />

                            </LineChart>

                        </ResponsiveContainer>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            type='primary'
                            style={this.state.tempDatas.length === 0 ? {
                                backgroundColor: '#62ab36', opacity: 0.6, color: 'white',
                            } : { backgroundColor: '#62ab36', color: 'white' }}
                            onClick={this.handleShowModal}
                            disabled={this.state.tempDatas.length === 0}
                        >
                            <FormattedMessage id={'containers.Reports.Temperature.AddEvent'} />

                        </Button>
                        <Button
                            style={this.state.referencesValue.length === 0 ? {
                                backgroundColor: 'red', opacity: 0.6, color: 'white',
                            } : { backgroundColor: 'red', color: 'white' }}
                            onClick={this.handleModalEvent}
                            disabled={this.state.referencesValue.length === 0}
                        >
                            <FormattedMessage id={'containers.Reports.Temperature.DeleteEvent'} />

                        </Button>
                        <Button
                            type='primary'
                            style={this.state.tempDatas.length === 0 ? {
                                backgroundColor: '#62ab36', opacity: 0.6, color: 'white', marginTop: '10px',
                            } : { backgroundColor: '#62ab36', color: 'white', marginTop: '10px' }}
                            onClick={this.handleGenerateReport}
                            disabled={this.state.selectedIdVehicle === -1 && this.state.tempDatas.length === 0}
                        >
                            <FormattedMessage id={'containers.Reports.Temperature.PdfExtract'} />
                        </Button>


                        <Modal
                            title={this.props.intl.formatMessage({
                                id: 'containers.Reports.Temperature.DeleteEvent',
                            })}
                            visible={this.state.isModalDeleteEventShowing}
                            onCancel={this.handleCloseModal}
                            footer={[
                                <Button
                                    key='cancel'
                                    type='primary'
                                    onClick={this.handleCloseModal}
                                    style={{
                                        backgroundColor: colors.redA,
                                        borderColor: colors.redA,
                                    }}
                                >
                                    <FormattedMessage id={'containers.Maintenance.Modal.Cancel'} />
                                </Button>,
                                <Button
                                    key='confirmer'
                                    type='primary'
                                    onClick={this.handleConfirmDelete}
                                    style={{
                                        backgroundColor: colors.green57,
                                        borderColor: colors.green57,
                                    }}
                                >
                                    <FormattedMessage id={'containers.Maintenance.Modal.Confirm'} />
                                </Button>,

                            ]}>
                            <div>
                                <div>
                                    {this.state.referencesValue.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Checkbox
                                                    onChange={(event) => this.handleDeleteEvent(event, item.date)}
                                                > {item.label} {' '} {moment(item.date).format('DD/MM/YYYY HH:mm')}</Checkbox>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                        </Modal>
                    </div>

                </div>


                <Split color={colors.splitGrayReports} opacity={0.2} />


            </div>
        );
    };

    render() {
        return (
            <ReportGeneratorContainer>
                <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} />
                {this.props.report && this.props.report.size > 0
                    ? this.renderReport()
                    : this.renderGenerator()}
            </ReportGeneratorContainer>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    errors: selecDrivingReportGenerationErrors(),
    loading: selectIsLoading(),
    report: selectDrivingReport(),
    equipment: selectVehicles(),
    selectedFleetEquipment: selectFleetEquipment(),
    selectFleetEquipmentNextPage: selectFleetEquipmentNextPage(),
    moduleRecords: selectModuleRecords(),

});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            validateDrivingReportGenerator,
            generateDrivingReport,
            removeDrivingReportError,
            getFleetEquipment,
            getVehicle,
            getVehicleHistoryDetail,
            getModuleByEquipementId,

        },
        dispatch,
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(ReportPageComponent)),
);
