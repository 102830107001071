// @flow

import styled from 'styled-components';
import colors from './colors';
import screen from './screen';

const AppWrapper = styled.div`
    width: 100%;
    background-color: ${colors.gray90};
    margin: 0 auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const Container = styled.div`
    color: ${colors.gray10};
    width: ${screen.large};
    padding: 80px 20px;
    flex: 1;
    max-width: 100%;
    margin: auto;
    display: flex;
`;

Container.NoHeader = styled.div`
    color: ${colors.gray10};
    flex: 1;
    max-width: 100%;
    display: flex;
`;

Container.Dashboard = styled.div`
    flex: 1;
    display: flex;
    color: ${colors.gray10};
    width: ${screen.large};
    height: 100%; //calc(100vh - 60px);
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
    background-color: ${colors.white};
    position: relative;
    overflow: auto;
`;

Container.Map = styled.div`
    flex: 1;
    display: flex;
    // min-height: 100vh; // DO NOT USE THIS FOR iOS SAFARI
    // height: 100%; // DO NOT USE vh FOR iOS SAFARI
    position: relative;
    overflow: hidden;
    right: 0;
`;

Container.OverFlow = styled.div`
    display: block;
    content: '';
    height: ${(props) => props.margin || '50px'};
`;

const Content = styled.div`
    padding: 50px 35px;

    h1:first-child {
        margin-top: 0;
    }
`;

const NavigationWrapper = styled.div`
    display: flex;
    flex: 1;
    background-color: white;
    ${(props) =>
        props.map &&
        `
        @media (max-width: ${screen.small}) {
            overflow: hidden;
        }
    `};
`;

const Split = styled.div`
    height: ${(props) => props.height || '1px'};
    background-color: ${(props) => props.color || `${colors.black}`};
    ${({ opacity }) => opacity && `opacity: ${opacity}`};
    ${(props) => props.margin && `margin: ${props.margin};`};
`;

export { AppWrapper, Container, Content, NavigationWrapper, Split };
