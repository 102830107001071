import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import './styles.css';
import axios from 'axios';
import { MAPBOX_GL_TOKEN } from 'env';

class AutocompletePlace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: this.props.placeholder,
            results: [],
            isLoading: false,
            
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(e) {
        this.setState({
            search: e.target.value,
            isLoading: true,
        });
        if(e.target.value === ''){
        	this.props.onClear();
        }

        // Stop the previous setTimeout if there is one in progress
        clearTimeout(this.timeoutId);

        // Launch a new request in 100ms
        this.timeoutId = setTimeout(() => {
            this.performSearch();
        }, 100);
    }

    performSearch() {
        if (this.state.search === '') {
            this.setState({
                results: [],
                isLoading: false,
            });
            return;
        }
        axios
            .get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${
                    this.state.search
                }.json?access_token=${MAPBOX_GL_TOKEN}`
            )
            .then((response) => {
                this.setState({
                    results: response.data.features,
                    isLoading: false,
                });
            });
    }

    handleItemClicked(place) {
        this.setState({
            search: place.place_name,
            results: [],
        });
        this.props.onSelect(place);
    }
    render() {
        return (
            <div className="AutocompletePlace">
                <input
                    className="AutocompletePlace-input"
                    type="text"
                    value={this.state.search}
                    onChange={this.handleSearchChange}
                    placeholder= {this.props.intl.formatMessage({
                        id: 'components.AutoComplete.text.placeholder',
                    })}
                />
                <ul className="AutocompletePlace-results">
                    {this.state.results.map((place) => (
                        <li
                            key={place.id}
                            className="AutocompletePlace-items"
                            onClick={() => this.handleItemClicked(place)}
                        >
                            {place.place_name}
                        </li>
                    ))}
                    {this.state.isLoading && (
                        <li className="AutocompletePlace-items"><FormattedMessage id={'components.AutoComplete.loading'} /></li>
                    )}
                </ul>
            </div>
        );
    }
}

export default injectIntl(AutocompletePlace);
