/* eslint-disable no-console */
/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable no-undefined */
/* eslint-disable flowtype/require-valid-file-annotation */
import { useCallback, useState } from 'react';

const getErrorMessages = (errorData) => {
    if (errorData && typeof errorData === 'string') return [errorData];

    if (errorData && errorData.errors) {
        return Object.values(errorData.errors).reduce((errors, error) => errors.concat(error), []);
    }

    return ['An error occurred'];
};

const useAgent = (agent) => {
    const [result, setResult] = useState();
    const [isPending, setIsPending] = useState(false);

    const dispatchError = useCallback((error) => {
        if (error && error.response) {
            const errorMessages = getErrorMessages(error.response.data);
            errorMessages.forEach((errorMessage) => console.error(errorMessage));
        } else {
            console.error('An error occurred');
        }
    }, []);

    const call = useCallback(
        async (...params) => {
            setIsPending(true);

            try {
                const response = await agent(...params);
                const data = response && response.data ? response.data : response;
                setResult(data);
            } catch (error) {
                dispatchError(error);
                setResult(undefined);
            } finally {
                setIsPending(false);
            }
        },
        [agent, dispatchError]
    );

    return [call, result, isPending];
};

export default useAgent;
