/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        overflowX: 'scroll',
        height: '80%',
        padding: '0 10px 10px 0',
        boxShadow: 'inset 0 -3px 3px -3px #000000',
    },
    listItemSecondaryAction: {
        visibility: 'hidden',
    },
    itemText: {
        fondWeight: 'bold',
    },
    groupListTitle: {
        margin: '10px',
    },
    listItemText: {
        fontSize: '0.7em',
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    },
    default: {
        fontWeight: 'bold',
    },
}));

export default useStyles;
