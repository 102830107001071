import styled from 'styled-components';
import colors from 'styles/colors';

export const ErrorContainer = styled.div`
    width: 80%;
    margin-left: 20px;
    border: ${({ error }) => error ? '1px solid #C7291C' : 'none'};
`;

export const ErrorText = styled.div`
    font-family: OpenSans;
    font-size: 12px;
    color: #C7291C;
    margin-top: -10px;
    margin-left: 20%;
`;