// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import { SearchInput, Input } from 'components/SearchInput/styles';
const ListPage = styled.div`
    width: 100%;
    overflow-y: auto;
    padding: ${(props) => (!props.isOpen ? '30px 50px 0 110px;' : '30px 50px 0 50px;')};
    :after {
        content: '';
        display: block;
        height: 50px;
        width: 100%;
    }
`;

ListPage.SearchContainer = styled.div`
    margin-right: 10px;
    flex: 1;
    height: 100%;

    > ${SearchInput} > ${Input} {
        width: 100%;
    }
`;
ListPage.FilterContainer = styled.div`
    width: 25%;
    margin-top: 25px;
    margin-bottom: 50px;
    padding-right: 23px;
`;

export default ListPage;
