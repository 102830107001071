// @flow

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import { selectIsSideMenuOpen, selectIsActiveBarOpen } from 'services/SideMenu/selectors';
import { toggleSideMenu, toggleActiveBar } from 'services/SideMenu/thunks';

import { SideMenu } from 'styles/sidemenu';

import { ActionSideMenuContainer } from './styles';

import CloseButton from 'components/CloseButton';
import SideMenuComponent from 'components/SideMenu';

import type { ReduxDispatch, InputEvent } from 'types';

type Props = {
    isOpen: boolean,
    offset: ?number,
    mapPage: ?boolean,
};

class SideMenuContainer extends React.PureComponent<Props> {
    handleToggleSideMenu = () => {
        this.props.toggleSideMenu();
    };

    renderSideMenu = () => {
        return this.renderSideMenuOpen();
    };

    renderSideMenuOpen = () => <ActionSideMenuContainer>{this.props.children}</ActionSideMenuContainer>;

    renderSideMenuClosed = () => (
        <React.Fragment>
            <SideMenu.ClosedBorder mapPage={this.props.mapPage} />
            <CloseButton
                isOpen={this.props.isOpen}
                onClick={this.handleToggleSideMenu}
                offset={this.props.offset}
                mapPage={this.props.mapPage}
            />
        </React.Fragment>
    );

    render() {
        return this.renderSideMenu();
    }
}

const mapStateToProps = createStructuredSelector({
    isOpen: selectIsSideMenuOpen(),
    isActiveBarOpen: selectIsActiveBarOpen(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            toggleSideMenu,
            toggleActiveBar,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideMenuContainer);
