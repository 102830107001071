// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectOrganizationState = () => (state) => state.get('organization');

/**
 * Select the auth state
 */

const selectOrganizations = () =>
    createSelector(selectOrganizationState(), (substate) => substate.get('organizations'));

const selectOrganization = () =>
    createSelector(selectOrganizationState(), (substate) => substate.get('selectedOrganization'));

const selectOrganizationNotFormating = () =>
    createSelector(selectOrganizationState(), (substate) =>
        substate.get('selectedOrganizationNotFormat')
    );

const selectOrganizationUsers = () =>
    createSelector(selectOrganizationState(), (substate) =>
        substate.getIn(['selectedOrganization', 'users'])
    );

const selectOrganizationsPagination = () =>
    createSelector(selectOrganizationState(), (substate) => substate.get('paginated'));

const selectOrganizationsLoading = () =>
    createSelector(selectOrganizationState(), (substate) => substate.get('loading'));

const selectOrganizationBranches = () =>
    createSelector(selectOrganizationState(), (substate) =>
        substate.getIn(['selectedOrganization', 'children'])
    );

const selectOrganizationError = () =>
    createSelector(selectOrganizationState(), (substate) => substate.getIn(['error']));

export {
    selectOrganizations,
    selectOrganization,
    selectOrganizationNotFormating,
    selectOrganizationUsers,
    selectOrganizationsPagination,
    selectOrganizationsLoading,
    selectOrganizationBranches,
    selectOrganizationError,
};
