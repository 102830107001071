// @flow

import { push } from 'react-router-redux';

import {
    receivedBranchDataSuccess,
    receivedCreateBranchSuccess,
    receivedEditBranchSuccess,
    receivedDeleteBranchSuccess,
    receivedDeleteBranchEquipment,
} from './actions';

import {
    retrieveBranch,
    retrieveBranchUsers,
    retrieveBranchDivisions,
    retrieveBranchEquipment,
    createBranchRequest,
    editBranchRequest,
    retrieveConfigVehByBranchId,
    fetchGroups,
} from './resources';

import { deleteOrganizationRequest } from 'services/Organization/resources';

import type {
    ReduxDispatch,
    ResponseErrorType,
    ImmutableMap,
    UserDataType,
    Organization,
} from 'types';

export const getBranchData = (organizationId: number, branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return Promise.all([
        retrieveBranch(organizationId, branchId, token, locale),
        retrieveBranchUsers(organizationId, branchId, token, locale),
        // retrieveBranchDivisions(organizationId, branchId, token, locale),
        // retrieveBranchEquipment(organizationId, branchId, token, locale),
    ])
        .then((responses) => {
            const branch = {
                branch: responses[0].data.branches[0],
                users: responses[1].data.users,
                // divisions: responses[2].data.data,
                // equipment: responses[3].data,
            };

            dispatch(receivedBranchDataSuccess(branch));
        })
        .catch((error) => console.log('error', error));
};

export const createBranch = (data: Organization, organizaitonId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    const requestData = {
        parentId: organizaitonId,
        ...data,
    };

    return createBranchRequest(requestData, organizaitonId, token, locale).then((response) => {
        return dispatch(receivedCreateBranchSuccess(response.data));
    });
};

export const editBranch = (data: Organization, organizationId: number, branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return editBranchRequest(organizationId, branchId, data, token, locale).then((response) =>
        dispatch(receivedEditBranchSuccess(response.data))
    );
};

export const deleteBranch = (organizationId: number, parentId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    const url = `${locale}/organizations/${parentId}/branches/${organizationId}`;

    return deleteOrganizationRequest(url, token).then((response) =>
        dispatch(receivedDeleteBranchSuccess())
    );
};

export const deleteBranchEquipment = (equipmentId: number) => (dispatch: ReduxDispatch) => {
    return dispatch(receivedDeleteBranchEquipment(equipmentId));
};

export const getConfigByBranchId = (branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return retrieveConfigVehByBranchId(branchId, token, locale).then((response: Object) => {
        return response.data;
    });
};

export const fetchBranchGroup = (branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return fetchGroups(token, branchId).then((response) => {
        return response.data;
    });
};
