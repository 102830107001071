// @flow

import { fromJS } from 'immutable';
import { isMobile } from 'react-device-detect';
import {
    DISMISS_LOADING,
    LOADING, GET_GEOFENCES_SUCCESS,
    GET_GEOFENCES_ERROR,
    SET_DRAW_MODE,
    TOGGLE_SHOW_GEOFENCES,
    SET_SELECTED_GEOFENCE,
    SET_CLICKED_GEOFENCE_DATA,
    SET_CREATING_GEOFENCE_MODE,
    SET_IS_GEOFENCE_EDITABLE,
    SET_GEOFENCE_DATA,
} from './actions';
import { ViewMode } from 'nebula.gl';
import { featureCollection } from '@turf/turf';
import type { RoutificRecords } from 'types';

const initialState = fromJS({
    geofences: {},
    geofenceData: featureCollection([]),
    selectedGeofence: null,
    geofencesError: false,
    buttonLoading: false,
    loading: false,
    drawMode: () => ViewMode,
    showGeofences: JSON.parse(localStorage.getItem('geothentic:geofences:display')) || false,
    clickedGeofenceData: null,
    creatingGeofenceMode: false,
    isGeofenceEditable: isMobile ? false : JSON.parse(localStorage.getItem('geothentic:geofences:editable')) || false,
});

function GeofenceServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case LOADING:
            return state.set('buttonLoading', true);
        case DISMISS_LOADING: {
            return state.set('buttonLoading', false);
        }
        case GET_GEOFENCES_SUCCESS: {
            const geofences = fromJS(action.geofences);
            return state.set('geofences', geofences).set('loading', false);
        }
        case GET_GEOFENCES_ERROR: {
            return state.set('loading', false);
        }
        case SET_DRAW_MODE: {
            return state.set('drawMode', action.drawMode);
        }
        case TOGGLE_SHOW_GEOFENCES: {
            return state.set('showGeofences', !state.get('showGeofences'));
        }
        case SET_SELECTED_GEOFENCE: {
            return state.set('selectedGeofence', action.geofence);
        }
        case SET_CLICKED_GEOFENCE_DATA: {
            return state.set('clickedGeofenceData', action.geofenceData);
        }
        case SET_CREATING_GEOFENCE_MODE: {
            return state.set('creatingGeofenceMode', action.enabled);
        }
        case SET_IS_GEOFENCE_EDITABLE: {
            return state.set('isGeofenceEditable', action.editable);
        }
        case SET_GEOFENCE_DATA: {
            return state.set('geofenceData', action.geofenceData);
        }
        default:
            return state;
    }
}

export default GeofenceServiceReducer;
