// @flow

export const CONNECT = 'app/services/socket/CONNECT';
export const CONNECT_SUCCESS = 'app/services/socket/CONNECT_SUCCESS';
export const CONNECT_FAIL = 'app/services/socket/CONNECT_FAIL';
export const DISCONNECT = 'app/services/socket/DISCONNECT';
export const RECEIVED_DATA_FAIL = 'app/services/socket/RECEIVED_DATA_FAIL';
export const RECEIVED_DATA_SUCCESS = 'app/services/socket/RECEIVED_DATA_SUCCESS';
export const SOCKET_UPDATE = 'app/services/socket/SOCKET_UPDATE';
// MAIN ACTIONS
/**
 *
 */

export const receivedDataSuccess = (msg) => ({
    type: RECEIVED_DATA_SUCCESS,
    message: msg,
});

export const receivedDataFail = () => ({
    type: RECEIVED_DATA_FAIL,
});

export const receivedDisconnect = () => ({
    type: DISCONNECT,
});

export const socketBoundUpdate = (bound) => ({
    type: SOCKET_UPDATE,
    bound,
});
