// @flow

import ChipItem from './styles';
import Tag from 'antd/es/tag';
import  Tooltip  from 'antd/es/tooltip';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTextunit, changeUnit } from 'services/Units/thunks';

import personalButtonIcon from '../../assets/custom/auxIcons/personalButton.svg';
import professionalButtonIcon from '../../assets/custom/auxIcons/professionalButton.svg';
import regenwhite from '../../assets/regenwhite.svg'
import regenblack from '../../assets/regenblack.svg'
import personalJourneyToggle from "../../assets/personalJourneyToggle.svg";
import professionalJourneyToggle from "../../assets/professionalJourneyToggle.svg";

type Props = {};
type State = {};

class Chip extends React.Component<Props, State> {
    state = {};

    render() {
       const renderSensorTags =() => {
            const { sensor } = this.props;
            const sensorInfo = {};
            sensor.forEach(item => {
                if (item.type && item.type === 'temperature' && item.label && item.label.includes('T')) {
                    let itemValue = (item.value >= -75 && item.value <= 75) ? item.value : 'DF';
                    const tempValue = (itemValue !== 'DF') ? this.props.setTextunit(itemValue, 'temperature') : 'DF';
                    const label = item.label;
                    sensorInfo[label] = tempValue;
                }
            });

            const sensorTags = Object.entries(sensorInfo)
                .map(([label, value]) => `${label} : ${value}`)
                .join(' | ');

            return (
                sensorTags ?
                <Tag color="geekblue" style={{width: 'auto'}}>
                    <i className='fas fa-thermometer-three-quarters' /> &nbsp;
                    {sensorTags}
                </Tag> : null
            );
        }

        let displayChip;
        if (this.props.type == 'totalTimeSpent') {
            displayChip = (
                <ChipItem>
                    <ChipItem.totalTimeSpent>
                        {this.props.children}
                        {this.props.metric ? this.props.metric : null}
                    </ChipItem.totalTimeSpent>
                </ChipItem>
            );
        } else if (this.props.type == 'distance') {
            displayChip = (
                <ChipItem>
                    <ChipItem.distance>
                        {this.props.children}
                        {this.props.metric ? this.props.metric : null}
                    </ChipItem.distance>
                </ChipItem>
            );
        } else if (this.props.type == 'driver') {
            displayChip = (
                <ChipItem>
                    <ChipItem.driver color={this.props.color}>
                        {this.props.children}
                    </ChipItem.driver>
                </ChipItem>
            );
        } else if (this.props.type == 'personal') {
            displayChip = (
                <ChipItem>
                    <ChipItem.mode>
                        <img
                            height="25px"
                            width="25px"
                            style={{ paddingBottom: '0.2em' }}
                            src={personalJourneyToggle}
                        />{' '}
                        <FormattedMessage id={'views.Reports.Move.personal'} />
                        {this.props.children ? `: ${this.props.children}` : null}
                    </ChipItem.mode>
                </ChipItem>
            );
        } else if (this.props.type == 'work') {
            displayChip = (
                <ChipItem>
                    <ChipItem.mode>
                        <img
                            height="25px"
                            width="25px"
                            style={{ paddingBottom: '0.2em' }}
                            src={professionalJourneyToggle}
                        />{' '}
                        <FormattedMessage id={'views.Reports.Move.work'} />
                        {this.props.children ? `: ${this.props.children}` : null}
                    </ChipItem.mode>
                </ChipItem>
            );
        } else if (this.props.type == 'sensor') {
            const sensorStatus = this.props.sensor;
            const auxColor = 'geekblue';
            const tempColor = 'green';
            const idleColor = 'red';
            const renderedTags = renderSensorTags();
            displayChip =(
                <div>
                    {renderedTags}

                    {sensorStatus && sensorStatus.map((item, index) => {
                        if (item.type == 'idle') {
                            return (
                                <div style={{marginBottom: '5px'}}>
                                    <Tag color={idleColor}>{item.value}</Tag>
                                </div>
                            );
                        }
                        if (item.type === 'regeneration') {
                            return (
                                <div>
                                    <Tag color={'green'} style={{width: '110px'}}>
                                        <img
                                            height={'20px'}
                                            width={'20px'}
                                            style={{paddingBottom: '0.2em'}}
                                            src={regenwhite}
                                        />{' '}
                                        <FormattedMessage id={'components.HistoryDetail.Regeneration'}/>
                                    </Tag>
                                </div>
                            )
                        }
                        if (item.type == 'temperature') {
                            let currentTemp = item.value;
                            if (currentTemp < -25 || currentTemp > 55) {
                                currentTemp = 'N/A';
                            }
                            let tempErr;
                            item.sensorName.includes('RS232') && (tempErr = ((item.value == -95 || item.value == -100) ? 'DF' : ((item.value == -128) ? 'NC' : ''))
                            );
                            return item.sensorName.length > 9 ? (
                                <Tooltip title={item.sensorName}>
                                    <Tag color={auxColor}>
                                        <i className='fas fa-thermometer-three-quarters'/> &nbsp;
                                        {item.sensorName.substr(0, 9)} TOTO
                                        ...
                                        : {currentTemp != 'N/A' ? this.props.setTextunit(currentTemp, 'temperature') : 'N/A'}
                                    </Tag>
                                </Tooltip>
                            ) : (

                                item.label === 'CO2' && (
                                    <Tag color={auxColor} style={{width: 'fit-content'}}>
                                        <i className='fas fa-thermometer-three-quarters'/> &nbsp;
                                        {item.label} :{' '}
                                        {(tempErr == '') ? ((item.value == 'DF' || item.value == 'NC') ? item.value : item.value + ' ' + 'ppm') : tempErr}
                                    </Tag>
                                )

                                ||
                                item.label === 'HUM' && (
                                    <Tag color={auxColor} style={{width: '100px'}}>
                                        <i className='fas fa-thermometer-three-quarters'/> &nbsp;
                                        {item.label} :{' '}
                                        {(tempErr == '') ? ((item.value == 'DF' || item.value == 'NC') ? item.value : item.value + ' ' + '%') : tempErr}
                                    </Tag>
                                )
                                ||
                                item.sensorName.includes('Aux') && (
                                    <Tag color={auxColor}>
                                        <i className='fas fa-thermometer-three-quarters' /> &nbsp;
                                        {item.sensorName} :{' '}
                                        {item.value >= -75 && item.value <= 75 ? this.props.setTextunit(item.value, 'temperature') : 'DF'}
                                    </Tag>
                                )
                            );
                        } else {
                            return item.value.length > 9 ? (
                                <Tooltip title={item.value}>
                                    {' '}
                                    <Tag color={tempColor}>
                                        {item.value.substr(0, 9)}
                                        ...
                                    </Tag>
                                </Tooltip>
                            ) : (
                                <Tag color={tempColor}>{item.value}</Tag>
                            );

                        }
                    })}
                </div>)
                ;
        } else if (this.props.type == 'battery') {
            displayChip = (
                <ChipItem>
                    <ChipItem.battery color={this.props.color}>
                        <i className='fas fa-car-battery' /> {this.props.children} volts
                    </ChipItem.battery>
                </ChipItem>
            );
        } else if (this.props.type == 'key') {
            displayChip = (
                <ChipItem>
                    <ChipItem.key color={this.props.color} isKey={this.props.isKey}>
                        <i className="fas fa-key" /> {this.props.isKey ? 'On' : 'Off'}
                    </ChipItem.key>
                </ChipItem>
            );
        } else if (this.props.type == 'speed') {
            displayChip = (
                <ChipItem>
                    <ChipItem.speed color={this.props.color}>
                        <i className="fas fa-tachometer-alt" /> {this.props.children}{' '}
                        {this.props.metric ? this.props.metric : null}
                    </ChipItem.speed>
                </ChipItem>
            );
        } else if (this.props.type == 'address') {
            displayChip = (
                <ChipItem>
                    <ChipItem.basic color={this.props.color}>
                        <i className="fas fa-compass" /> {this.props.children}
                    </ChipItem.basic>
                </ChipItem>
            );
        } else if (this.props.type == 'date') {
            displayChip = (
                <ChipItem>
                    <ChipItem.basic color={this.props.color}>
                        <i className="far fa-calendar-alt" /> {this.props.children}
                    </ChipItem.basic>
                </ChipItem>
            );
        } else if (this.props.type == 'dateCheck') {
            displayChip = (
                <ChipItem>
                    <ChipItem.dateCheck color={this.props.color} isLate={this.props.isLate}>
                        <i className="far fa-calendar-alt" /> {this.props.children}
                    </ChipItem.dateCheck>
                </ChipItem>
            );
        } else {
            displayChip = (
                <ChipItem>
                    <ChipItem.basic color={this.props.color}>{this.props.children}</ChipItem.basic>
                </ChipItem>
            );
        }

        return displayChip;
    }
}

// MAP STATE/DISPATCH
const mapStateToProps = createStructuredSelector({});

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setTextunit,
            changeUnit,
        },
        dispatch
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Chip));
