import styled from 'styled-components';
import colors from '../../../styles/colors';


const SelectVehAlarm = styled.div`

    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: ${colors.white};
    }

    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background: ${colors.white};
    }

    .ant-pagination-next > button {
        width: 30px;
        height: 30px;
    }

    .ant-pagination-prev > button {
        width: 30px;
        height: 30px;
    }
    .ant-pagination-item-active{
       border-color: #28a745
    }
    .ant-pagination-item:focus , .ant-pagination-item:hover  {
        border-color: #28a745
    }
`;


export default SelectVehAlarm;
