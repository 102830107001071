// @flow

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';
import {FormattedMessage, injectIntl} from 'react-intl';
import './styles.scss';
import {selectUser} from "../../services/Authentication/selectors";
import {parseJWT} from "../../helpers";
import {getBranchData} from "../../services/Organization/thunks";
import {selectOrganizationNotFormating} from "../../services/Organization/selectors";
import {toggleSideMenu} from "../../services/SideMenu/thunks";
import {selectIsSideMenuOpen} from "../../services/SideMenu/selectors";
import {ApSidebarReservationIcon} from "../Icons";
import ApSectorsContainer from "../../containers/ApSectorsContainer"
import ApHomeReservation from "../../containers/ApHomeReservation";

export const ApSidebar = (props) => {
    const [showReservations, setShowReservations] = useState(false);
    const [showSectors, setShowSectors] = useState(false);
    const [branchName, setBranchName] = useState('');

    const jwt = parseJWT(props.user.get('token'));

    useEffect(() => {
        props.getBranchData()
            .then(() => {
                setBranchName(props.organizations.name)
            })
    }, [props.getBranchData]);

    useEffect(() => {
        if(jwt.permissions.includes('admin:carshare')){
            setShowSectors(true)
            setShowReservations(false)
        }else if (jwt.permissions.includes('access:carshare')){
            setShowReservations(true)
            setShowSectors(false)
        }
    }, []);

    const handleToggleMenu = () => {
        props.toggleSideMenu();
    };
    const onClickReservations = () => {
        setShowReservations(true);
        setShowSectors(false);
        props.toggleSideMenu();
    };
    const onClickSectors = () => {
        setShowSectors(true);
        setShowReservations(false)
        props.toggleSideMenu();
    };
    
    return (
        <div className={'sidebar-container'}>
            <div style={{display: props.isOpen ? 'block' : 'none', width: '25%'}}>
                <aside className="sidebar">
                    <div className="sidebar-header">
                        <div>{branchName}</div>
                        <button className="close-button" onClick={handleToggleMenu}>&times;</button>
                    </div>

                    <nav className="sidebar-nav">
                        {jwt.permissions.includes('admin:carshare') &&
                            <div onClick={onClickSectors} className="nav-item active"><i
                                className="icon fa fa-map"></i>
                                <FormattedMessage id={'components.ApSideMenu.Sectors'}/>
                            </div>}
                        {jwt.permissions.includes('access:carshare') &&
                            <div onClick={onClickReservations} className="nav-item active">
                                <div className="reservation-icon"><ApSidebarReservationIcon/></div>
                                <FormattedMessage id={'components.ApSideMenu.Reservations'}/>
                            </div>}
                    </nav>
                </aside>
            </div>
            <div className={'reservations'}>
                {showReservations && (
                     <ApHomeReservation/>
                )}
                {showSectors && (
                     <ApSectorsContainer/>
                )}
            </div>

        </div>

    );
};

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector({
    isOpen: selectIsSideMenuOpen(),
    user: selectUser(),
    organizations: selectOrganizationNotFormating(),
});

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getBranchData,
            toggleSideMenu
        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(ApSidebar));
