import styled from 'styled-components';
import colors from 'styles/colors';

const AlarmVehicleListStyle = styled.div`

`;
AlarmVehicleListStyle.TableBtn = styled.div`
    display: flex;
    justify-content: space-around;

    .ant-btn, .ant-btn :focus, .ant-btn :hover {
        width: 100px;
    }

    .redBtn, .redBtn :focus, .redBtn :hover {
        border-color: ${colors.redA};
        background-color: ${colors.redA};

`;

AlarmVehicleListStyle.Date = styled.div`
    display: flex;
    justify-content: space-evenly;

    .fa-bell {
        font-size: 20px;
        padding-left: 5px;
    }

    span {
        color: ${colors.redA};
    }
`;

AlarmVehicleListStyle.Header = styled.div`
    text-align: center;
    font-weight: 500;

    .title {
        color: ${colors.green57};
        padding-left: 5px;
    }
`;

AlarmVehicleListStyle.TableFooter = styled.div`
    display: flex;
    justify-content: space-between;

    .ant-btn, .ant-btn :focus, .ant-btn :hover {
        width: 20%;
        background-color: ${colors.green57};
        color: ${colors.white};
        height: 42px;
        margin-top: 5px;
    }
`;
AlarmVehicleListStyle.ModalDelete = styled.div`
    .redBtn, .redBtn :focus, .redBtn :hover {
        border-color: ${colors.redA};
        background-color: ${colors.redA};
    }

    .greenBtn, .greenBtn :focus, .greenBtn :hover {
        background-color: ${colors.green57};
        color: ${colors.white};
    }
`;

export default AlarmVehicleListStyle;
