import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import { ApArrowRightIcon, ApArrowLeftIcon } from '../Icons';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { injectIntl } from 'react-intl';
import './styles.scss';

function ApReservationTime(props) {
  const [date, setDate] = useState(dayjs());
  useEffect(() => {
  if(props.editReservationDate)
  {   
    let initDate= dayjs(props.editReservationDate , 'YYYY-MM-DD', true)
      setDate(initDate) 
  }
    },[])
  useEffect(() => {
    //date Formating and sending to parent component (stepper)
    const formattedDate = `${String(date.$y)}-${String(date.$M + 1).padStart(2,'0')}-${String(date.$D).padStart(2,'0')}`;
    props.sendReservationDate(formattedDate);
  },[date])
  return (
       
      <LocalizationProvider  dateAdapter={AdapterDayjs} adapterLocale={props.intl.locale}>
        <DateCalendar className={'ap_calendar_container'}
        slots={{
          rightArrowIcon:ApArrowRightIcon,
          leftArrowIcon:ApArrowLeftIcon,
        }}
        value={date}
        onChange={(newValue) => setDate(newValue)}
        views={['day']}
        shouldDisableDate={(day) => {
          if((day.$y === dayjs().$y) && (day.$M === dayjs().$M) && (day.$D === dayjs().$D))
          return false;
          return (day < dayjs())
        }}

/>
      </LocalizationProvider>
  )
}
export default injectIntl(ApReservationTime)