// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import HomePage from './styles';

type Props = {
    intl: Object,
};

// eslint-disable-next-line react/prefer-stateless-function
export class HomePageComponent extends React.Component<Props> {
    render() {
        return (
            <HomePage>
                <Helmet>
                    <title>
                        Service Client - Admin
                    </title>
                    <meta name="description" content="Home page" />
                </Helmet>
            </HomePage>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default withRouter(connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(HomePageComponent))
);
