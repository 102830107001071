// @flow

import request from 'services/adminRequest';

// Ap Reservations
//* ******************************************//

/**
 * Request all reservations for user
 * @param {string} token
 */
export function retrieveReservations(token: string): Promise<> {
    const url = `reservations`;
    return request(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Make reservation
 *
 * @param {string} token
 * @param data
 */
export function makeReservation(
    token: string,
    data
): Promise<> {
    const url = `reservations`;
    return request(
        url,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Cancel Reservation by reservationId.
 *
 * @param {string} token
 * @param {number} reservationId
 * @param data
 */
export function cancelReservation(
    token: string,
    reservationId: number
): Promise<> {
    const url = `reservations/${reservationId}`;
    return request(
        url,
        {
            method: 'PATCH',
        },
        false,
        token
    );
}

/**
 * Edit reservation by reservationId.
 *
 * @param {string} token
 * @param {number} reservationId
 * @param data
 */
export function editReservation(
    token: string,
    reservationId: number,
    data
): Promise<> {
    const url = `reservations/${reservationId}`;
    return request(
        url,
        {
            method: 'PUT',
            data
        },
        false,
        token
    );
}

/**
 * retrieve avalilibity array for date by reservationId.
 *
 * @param {string} token
 * @param data
 */
export function retrieveDateAvailabity(
    token: string,
    data
): Promise<> {
    const url = `reservationsAll`;
    return request(
        url,
        {
            method: 'POST',
            data : {
                ...data
            }
        },
        false,
        token
    );
    }

/**
 * retrieve reservation by reservationId.
 *
 * @param {string} token
 * @param data
 */
export function retrieveReservationById(
    token: string,
    reservationId: number,
): Promise<> {
    const url = `reservationPut/${reservationId}`;
    return request(
        url,
        {
            method: 'GET'
        },
        false,
        token
    );
    }

// Ap Sectors
//* ******************************************//

/**
 * Request all sectors for user
 * @param {string} token
 */
export function retrieveSectors(token: string): Promise<> {
    const url = `sector`;
    return request(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Delete sectors by sector id
 * @param {string} token
 * @param {number} sectorId
 */
export function removeSectors(token: string, sectorId:number): Promise<> {
    const url = `sector/${sectorId}`;
    return request(
        url,
        {
            method: 'DELETE',
        },
        false,
        token
    );
}

/**
 * Add or remove vehicle ids by sector id
 * @param {string} token
 * @param {number} sectorId
 * @param data
 */
export function updateVehiclesinSectorsBySectorId(token: string, sectorId:number, data): Promise<> {
    const url = `sector/${sectorId}`;
    return request(
        url,
        {
            method: 'PATCH',
            data: {
                ...data
            }
        },
        false,
        token
    );
}

/**
 * Add or remove sectors
 * @param {string} token
 * @param data
 */
export function updateSectors(token: string, data): Promise<> {
    const url = `sector/`;
    return request(
        url,
        {
            method: 'POST',
            data: {
                ...data
            }
        },
        false,
        token
    );
}
