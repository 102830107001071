/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useCallback, useEffect, useState } from 'react';
import useStyles from '../../styles/AddGroup.styles';
import TextField from '@material-ui/core/TextField';
import SubmitButton from '../inputs/SubmitButton';
import { getGroupName } from '../../helpers/GroupHelpers';
import { FormattedMessage } from 'react-intl';
import { useIntlContext } from '../../context/IntlContext';

const GroupCreation = ({ onSubmit, closeModal, title, textButton, selectedGroup, }) => {
    const [name, setName] = useState(null);
    const { intl } = useIntlContext();
    const [isDefault, setIsDefault] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const classes = useStyles();

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            const newGroup = {
                name: name || selectedGroup.name,
                isDefault,
            };
            onSubmit(newGroup);
            closeModal();
        },
        [name, isDefault, onSubmit, closeModal]
    );

    const handleDefaultChange = useCallback((event) => {
        setIsDefault(event.target.checked);
        setIsButtonDisabled(false);
    }, []);

    const handleNameChange = useCallback((event) => {
        setName(event.target.value);
        setIsButtonDisabled(false);
    }, []);

    useEffect(
        () => {
            if (selectedGroup) {
                setIsDefault(selectedGroup.isDefault);
            }
        },
        [selectedGroup]
    );

    return (
        <div className={classes.paper}>
            {/* https://github.com/mui-org/material-ui/issues/15903 */}
            <div>
                <h4 className={classes.title}>
                    <FormattedMessage id={title} />
                </h4>
                <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                    <TextField
                        id="standard-basic"
                        autoFocus
                        name="name"
                        label="Name"
                        onInput={handleNameChange}
                        defaultValue={selectedGroup && getGroupName(intl, selectedGroup.name)}
                    />
                    <div className={classes.button}>
                        <SubmitButton type="submit" disabled={isButtonDisabled}>
                            <FormattedMessage id={textButton} />
                        </SubmitButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default GroupCreation;
