// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Table, Button, message, Input as InputAntd, Modal } from 'antd';
import VehicleDetailManagement from '../VehicleDetail/styles';
import { Input, SearchInput } from '../../../components/SearchInput/styles';
import { getMaintenance, getMaintenanceEmail } from '../../../services/Maintenance/thunks';
import SelectVehAlarm from '../SelectVehiclesAlarms/styles';
import { selectEmailsRecords } from '../../../services/Config/selectors';
import {
    getEmailsByBranchId,
    insertBranchEmail, modifyEmailsByBranchId, removeBranchEmail, updateEmailsByBranchId,
} from '../../../services/Config/thunks';
import { sortByCheckedUsers } from '../helpers';
import { selectUser } from '../../../services/Authentication/selectors';
import AlarmManagementStyles from '../AlarmManagement/styles';
import AlarmVehicleListStyle from '../AlarmVehicleList/styles';
import {parseJWT} from "../../../helpers";

const SelectUsersAlarms = (props) => {

        const [usersDatas, setUsersDatas] = useState([]);
        const [usersAlarms, setUsersAlarms] = useState([]);
        const [selectedUsers, setSelectedUsers] = useState([]);
        const [searchInput, setSearchInput] = useState('');
        const [isBtnDisabled, setIsBtnDisabled] = useState(true);
        const [userEmail, setUserEmail] = useState('');
        const [indexUserModify, setIndexUserModify] = useState(-1);
        const [updatedUserEmail, setUpdatedUserEmail] = useState('');
        const [isModalDelete, setIsModalDelete] = useState(false);
        const [userToDelete, setUserToDelete] = useState(null);
        const emailRegExp = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        const jwt = parseJWT(props.user.get('token'));
        const branchId = jwt['https://geothentic.com/branch_id'];
        useEffect(
            () => {
            if(props.alarmsDatas){
                if(props.alarmsDatas.length > 0){
                    if (props.alarmsDatas && props.alarmsDatas.branchId !== undefined) {
                        props.getEmailsByBranchId(props.alarmsDatas.branchId);
                    }
                }
            }
            },
            [props.alarmsDatas],
        );
        useEffect(
            () => {
                props.getEmailsByBranchId(branchId)
                    .then((res) => {
                        setUsersDatas(res);
                    });

            },
            [],
        );

        useEffect(
            () => {
                if(props.emails.size > 0){
                    const newUsers = Array.from(props.emails, val => Object.fromEntries(val));
                    let users = newUsers.map((item) => {
                        return {
                            id: item.id,
                            branchId: item.branchId,
                            countryCode: item.countryCode,
                            emailAddress: item.emailAddress,
                            languageCode: item.languageCode,
                            hasAlert: false,
                        };
                    });

                    if (props.alarmsDatas && props.alarmsDatas.maintId !== undefined) {
                        props.getMaintenanceEmail(props.alarmsDatas.maintId)
                            .then((response) => {
                                response.map((item) => {
                                    users.map((user) => {
                                        if (user.id === item.emailId) {
                                            user.hasAlert = true;
                                        }
                                    });
                                });
                                users = sortByCheckedUsers(users);

                                if (searchInput === '') {
                                    setUsersDatas(users);
                                } else {
                                    let searchResults = users.filter(item =>
                                        item.emailAddress.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchInput.toLowerCase()),
                                    );

                                    if (searchResults.length !== users.length) {
                                        setUsersDatas(sortByCheckedUsers(searchResults));
                                    } else {
                                        setUsersDatas(users);
                                    }
                                }
                                setUsersAlarms(response);
                            });
                    }
                }

            },
            [props.emails, props.alarmsDatas, searchInput, setUsersDatas]);


        useEffect(() => {
            if (usersAlarms !== undefined) {
                const userId = usersAlarms.map((item) => {
                    return item.emailId;
                });
                setSelectedUsers(userId);
            }

        }, [usersAlarms]);
        useEffect(() => {

            props.handleSelectedUsers(selectedUsers);

        }, [selectedUsers]);


        const isContainAlarmName = (emailAddress) => {
            return props.emails.some((ele) => {
                return ele.toJS().emailAddress === emailAddress;
            });
        };

        const failureNotif = (msg) => {
            message.warning(msg, 2);
        };
        const successNotif = (msg) => {
            message.success(msg, 2);
        };
        const handleAdd = () => {
            if (!emailRegExp.test(userEmail)) {
                failureNotif(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.Notif.Email.User',
                    }),
                );
            } else {
                if (isContainAlarmName(userEmail)) {
                    failureNotif(
                        props.intl.formatMessage({
                            id: 'containers.Maintenance.Notif.ErrorContains.User',
                        }),
                    );
                }

                if (userEmail.trim().length > 0 && !isContainAlarmName(userEmail)) {

                    props.insertBranchEmail(branchId, {
                        id: 1,
                        emailAddress: userEmail,
                        countryCode: 124,
                        languageCode: 'fr',
                    })
                        .then(() => {
                            successNotif(
                                props.intl.formatMessage({
                                    id: 'containers.Maintenance.Notif.AddAlarm.User',
                                }),
                            );
                            setUserEmail('');
                            setSearchInput('');
                            props.getEmailsByBranchId(branchId)
                                .then((res) => {
                                    setUsersDatas(res);
                                });
                        })

                        .catch(() =>
                            failureNotif(
                                props.intl.formatMessage({
                                    id: 'containers.Maintenance.Notif.Error',
                                }),
                            ),
                        );


                }
            }

        };

        const handleOnSearch = (event) => {
            setSearchInput(event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

            setIsBtnDisabled(event.target.value.trim() === '');
            setUserEmail(event.target.value.trim());
        };

        const handleDelete = (selectedUser) => (event) => {
            setUserToDelete(selectedUser)
            setIsModalDelete(true);

        };
        const handleOkModal = () => {
            props.removeBranchEmail(branchId, userToDelete.id)
                .then(() => {
                    successNotif(
                        props.intl.formatMessage({
                            id: 'containers.Maintenance.Notif.RemoveUser',
                        }),
                    );
                    props.getEmailsByBranchId(branchId)
                        .then((res) => {
                            setUsersDatas(res);
                        });
                })
                .catch(() => {
                    failureNotif(
                        props.intl.formatMessage({
                            id: '  containers.Maintenance.Notif.Error',
                        }),
                    );
                });
            setIsModalDelete(false);

        };
        const handleModifyAlarm = (index) => (event) => {
            setIndexUserModify(index);
        };

        const handleUpdatedUserEmail = (event) => {
            setUpdatedUserEmail(event.target.value.replace(/\s/g, ''));
        };
        const handleCancel = (event) => {
            setIndexUserModify(-1);
        };
        const successMsg = (msg) => {
            message.info(msg);
        };
        const errorMsg = (msg) => {
            message.info(msg);
        };
        const handleSave = (selectedUser) => async (event) => {
            const datas = {
                emailAddress: selectedUser.emailAddress,
                countryCode: selectedUser.countryCode,
                languageCode: selectedUser.languageCode,
            };

            if (!emailRegExp.test(updatedUserEmail) && updatedUserEmail !== '') {
                failureNotif(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.Notif.Email.User',
                    }),
                );
            } else {
                if (isContainAlarmName(updatedUserEmail.trim())) {
                    failureNotif(
                        props.intl.formatMessage({
                            id: 'containers.Maintenance.Notif.ErrorContains',
                        }),
                    );
                } else if (updatedUserEmail.trim() !== '') {
                    datas.emailAddress = updatedUserEmail.trim();
                    selectedUser.emailAddress = datas.emailAddress; // update original reference
                    await props
                        .modifyEmailsByBranchId(selectedUser.branchId, selectedUser.id, JSON.stringify(datas))
                        .then(() => {
                            successMsg(
                                props.intl.formatMessage({
                                    id: 'containers.Maintenance.Notif.applyChanges',
                                }),
                            );
                        })
                        .catch(() => {
                            errorMsg(
                                props.intl.formatMessage({
                                    id: 'containers.Maintenance.Notif.Error',
                                }),
                            );
                        });
                    setUpdatedUserEmail('');
                    setIndexUserModify(-1);
                }else{
                    failureNotif(
                        props.intl.formatMessage({
                            id: 'containers.Maintenance.Notif.NotModified',
                        }),
                    );
                }


            }

        };

        const columns = [
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.SelectUserAlarm.Email' }),
                dataIndex: 'emailAddress',
                key: 'emailAddress',
                sorter: (a, b) => a.emailAddress.toLowerCase().localeCompare(b.emailAddress.toLowerCase()),
                render: (value, record, index) => {
                    return (
                        <AlarmManagementStyles.CheckBox>
                            {indexUserModify === index ?
                                (<InputAntd defaultValue={value}
                                            onChange={handleUpdatedUserEmail} />)
                                : (<div>{value}</div>)}
                        </AlarmManagementStyles.CheckBox>


                    );
                },
            },
            {

                key: 'action',
                render: (value, record, index) => {
                    return (
                        <AlarmManagementStyles.Btn>
                            <Button type='primary'
                                    onClick={indexUserModify === index ? handleSave(record) : handleModifyAlarm(index)}
                            >
                                <FormattedMessage
                                    id={indexUserModify === index ? 'containers.Maintenance.AlarmManagement.BtnSave' : 'containers.Maintenance.AlarmManagement.BtnModify'} />
                            </Button>

                            <Button type='primary'
                                    onClick={indexUserModify === index ? handleCancel : handleDelete(record)}
                            >
                                <FormattedMessage
                                    id={indexUserModify === index ? 'containers.Maintenance.Modal.Cancel' : 'containers.Maintenance.AlarmVehicle.Retirer'} />
                            </Button>
                        </AlarmManagementStyles.Btn>);
                },

            },
        ];

        const rowSelection = {
            onChange: selectedRowKeys => {

                setSelectedUsers([...selectedRowKeys]);
            },
        };
        const handleCancelModal = () => {
            setIsModalDelete(false);
        };

        return (

            <div>
                <div className={'title'}>
                    <FormattedMessage id={'containers.Maintenance.SelectUserAlarm.Title'} />
                </div>
                <SelectVehAlarm>
                    <VehicleDetailManagement.SearchContainer style={{ marginBottom: '15px' }}>
                        <SearchInput>
                            <Input
                                placeholder={
                                    props.intl.formatMessage({ id: 'containers.Maintenance.SelectUserAlarm.SearchPlaceholder' })
                                }
                                name={userEmail}
                                value={userEmail}
                                onChange={handleOnSearch}
                            />
                        </SearchInput>
                        <Button
                            disabled={isBtnDisabled}
                            onClick={handleAdd}
                            type='primary'
                            style={{ marginLeft: '10px' }}
                        >
                            <FormattedMessage id={'containers.Maintenance.SelectUsersAlarms.Add'} />
                        </Button>
                    </VehicleDetailManagement.SearchContainer>

                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedUsers,
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={usersDatas}
                        rowKey={record => record.id}
                        pagination={false}
                        scroll={{ y: 500 }}
                        width={400}
                    />
                    <Modal
                        title={props.intl.formatMessage({ id: 'containers.Maintenance.SelectUserAlarm.DeleteEmail' })}
                        visible={isModalDelete}
                        onCancel={handleCancelModal}
                        footer={[
                            <AlarmVehicleListStyle.ModalDelete key={'modalDelete'}>
                                <Button
                                    key='no'
                                    className={'redBtn'}
                                    type='primary'
                                    onClick={handleCancelModal}
                                >
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalNo'} />
                                </Button>
                                <Button
                                    key='yes'
                                    className={'greenBtn'}
                                    type='primary'
                                    onClick={handleOkModal}
                                >
                                    <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalYes'} />
                                </Button>
                            </AlarmVehicleListStyle.ModalDelete>,

                        ]}
                    >
                    <p>{userToDelete ? userToDelete.emailAddress : null}</p>
                    </Modal>
                </SelectVehAlarm>

            </div>
        );
    }
;

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector(
    {
        emails: selectEmailsRecords(),
        user: selectUser(),
    });

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getMaintenanceEmail,
            getEmailsByBranchId,
            insertBranchEmail,
            getMaintenance,
            removeBranchEmail,
            modifyEmailsByBranchId,
        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(SelectUsersAlarms));
