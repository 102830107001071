// @flow

import type { EquipmentType, EquipmentTypes, EquipmentListType, PaginatedData, VehicleType } from 'types';

export const GET_EQUIPMENT_TYPES_SUCCESS = 'app/services/Equipment/GET_EQUIPMENT_TYPES_SUCCESS';
export const CREATE_EQUIPMENT_SUCCESS = 'app/services/Equipment/CREATE_EQUIPMENT_SUCCESS';
export const CREATE_EQUIPMENT_ERROR = 'app/services/Equipment/CREATE_EQUIPMENT_ERROR';

export const ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS =
    'app/services/Equipment/ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS';
export const ASSOCIATE_EQUIPMENT_BRANCH_ERROR =
    'app/services/Equipment/ASSOCIATE_EQUIPMENT_BRANCH_ERROR';

export const ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS =
    'app/services/Equipment/ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS';
export const ASSOCIATE_EQUIPMENT_ORGANIZATION_ERROR =
    'app/services/Equipment/ASSOCIATE_EQUIPMENT_ORGANIZATION_ERROR';

export const ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS =
    'app/services/Equipment/ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS';
export const ASSOCIATE_EQUIPMENT_DIVISION_ERROR =
    'app/services/Equipment/ASSOCIATE_EQUIPMENT_DIVISION_ERROR';

export const EDIT_EQUIPMENT_ORGANIZATION_SUCCESS =
    'app/services/EDIT_EQUIPMENT_ORGANIZATION_SUCCESS';
export const EDIT_EQUIPMENT_ORGANIZATION_ERROR = 'app/services/EDIT_EQUIPMENT_ORGANIZATION_ERROR';
export const EDIT_EQUIPMENT_BRANCH_SUCCESS = 'app/services/EDIT_EQUIPMENT_BRANCH_SUCCESS';
export const EDIT_EQUIPMENT_BRANCH_ERROR = 'app/services/EDIT_EQUIPMENT_BRANCH_ERROR';
export const EDIT_EQUIPMENT_DIVISION_SUCCESS = 'app/services/EDIT_EQUIPMENT_DIVISION_SUCCESS';
export const EDIT_EQUIPMENT_DIVISION_ERROR = 'app/services/EDIT_EQUIPMENT_DIVISION_ERROR';

export const DELETE_EQUIPMENT_ERROR = 'app/services/Equipment/DELETE_EQUIPMENT_ERROR';
export const DELETE_EQUIPMENT_SUCCESS = 'app/services/Equipment/DELETE_EQUIPMENT_SUCCESS';

export const GET_EQUIPMENT = 'app/services/Equipment/GET_EQUIPMENT';
export const GET_EQUIPMENT_FAIL = 'app/services/Equipment/GET_EQUIPMENT_FAIL';

export const GET_EQUIPMENT_SEARCH_RESULTS_SUCCESS =
    'app/services/Equipment/GET_EQUIPMENT_SEARCH_RESULTS_SUCCESS';

export const GET_FUEL_LEVEL_SUCCESS = 'app/services/Equipment/GET_FUEL_LEVEL_SUCCESS';
export const CLEAR_FUEL_LEVEL = 'app/services/Equipment/CLEAR_FUEL_LEVEL';
export const GRAPH_LOADING = 'app/services/Equipment/GRAPH_LOADING';


export const receivedEquipmentTypesSuccess = (equipmentTypes: EquipmentTypes) => ({
    type: GET_EQUIPMENT_TYPES_SUCCESS,
    equipmentTypes,
});

export const receivedCreateEquipmentSuccess = (equipmentType: EquipmentType) => ({
    type: CREATE_EQUIPMENT_SUCCESS,
    equipmentType,
});

export const receivedCreateEquipmentError = (error: string) => ({
    type: CREATE_EQUIPMENT_ERROR,
    payload: { error },
});

export const receivedAssociateDivisionEquipmentSuccess = (equipmentType: EquipmentType) => ({
    type: ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS,
    equipmentType,
});

export const receivedAssosciateDivisionEquipmentError = (error: string) => ({
    type: ASSOCIATE_EQUIPMENT_DIVISION_ERROR,
    error,
});

export const receivedAssociateBranchEquipmentSuccess = (equipment: EquipmentType) => ({
    type: ASSOCIATE_EQUIPMENT_BRANCH_SUCCESS,
    equipment,
});

export const receivedAssociateBranchEquipmentError = (error: string) => ({
    type: ASSOCIATE_EQUIPMENT_BRANCH_ERROR,
    error,
});

export const receivedAssociateOrganizationEquipmentSuccess = (equipment: EquipmentType) => ({
    type: ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS,
    equipment,
});

export const receivedAssociateOrganizationEquipmentError = (error: string) => ({
    type: ASSOCIATE_EQUIPMENT_ORGANIZATION_ERROR,
    error,
});

export const receivedEditOrganizationEquipmentSuccess = (equipment: EquipmentType) => ({
    type: EDIT_EQUIPMENT_ORGANIZATION_SUCCESS,
    equipment,
});

export const receivedEditOrganizationEquipmentError = (error: string) => ({
    type: EDIT_EQUIPMENT_ORGANIZATION_ERROR,
    error,
});

export const receivedEditBranchEquipmentSuccess = (equipment: EquipmentType) => ({
    type: EDIT_EQUIPMENT_BRANCH_SUCCESS,
    equipment,
});

export const receivedEditBranchEquipmentError = (error: string) => ({
    type: EDIT_EQUIPMENT_BRANCH_ERROR,
    error,
});

export const receivedEditDivisionEquipmentSuccess = (equipment: EquipmentType) => ({
    type: EDIT_EQUIPMENT_DIVISION_SUCCESS,
    equipment,
});

export const receivedEditDivisionEquipmentError = (error: string) => ({
    type: EDIT_EQUIPMENT_DIVISION_ERROR,
    error,
});

export const receivedDeleteEquipmentError = (error: string) => ({
    type: DELETE_EQUIPMENT_ERROR,
    error,
});

export const receivedDeleteEquipmentSuccess = () => ({
    type: DELETE_EQUIPMENT_SUCCESS,
});

export const receivedEquipmentResponseSuccess = (response: PaginatedData<EquipmentListType>) => ({
    type: GET_EQUIPMENT,
    response,
});

export const receivedEquipmentResponseError = (error: string) => ({
    type: GET_EQUIPMENT_FAIL,
    error,
});

export const receivedEquipmentSearchResultsSuccess = (
    response: PaginatedData<EquipmentListType>,
    searchQuery: string
) => ({
    type: GET_EQUIPMENT_SEARCH_RESULTS_SUCCESS,
    response,
    searchQuery,
});

export const receivedFuelLevelData = (fuelLevelData: *) => ({
    type: GET_FUEL_LEVEL_SUCCESS,
    fuelLevelData,
});

export const receivedClearFuelLevel = () => ({
    type: CLEAR_FUEL_LEVEL,
});

export const loading = () => ({
    type: GRAPH_LOADING,
});
