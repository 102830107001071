// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Dashboard } from 'styles/dashboard';

import dateFns from 'date-fns';

import type {
    ReduxDispatch,
    ImmutableList,
    ImmutableMap,
    UserType,
    ColumnType,
    PaginatedType,
} from 'types';

import OrganizationListItem from 'components/OrganizationListItem';
import DashboardDataComponent from 'components/DashboardDataComponent';
import GroupButton from 'components/GroupButton';
import OutlineButton from 'components/OutlineButton';

import { selectLocale } from 'services/Language/selectors';

import CaretDown from 'assets/caret-down.png';
import CaretUp from 'assets/caret-up.png';

import colors from 'styles/colors';

type SortFunction = ?(a: string | number, b: string | number) => void;

type Props = {
    columns: Array<ColumnType>,
    id: ?string,
    dataKeys: ?Array<string>,
    caretSource: ?string,
    tableData: ?ImmutableList<string>,
    users: ?boolean,
    list?: boolean,
    onClickDataItem: ?(number) => void,
    onClickViewMore: ?() => void,
    onClickEditUser: ?(user: UserType) => void,
    onClickDeleteUser: ?(user: UserType) => void,
    onClickHeaderItem: ?(key: string, sortMethod: SortFunction) => void,
    locale: string,
    component: ?ReactComponent,
    paginated: ?PaginatedType,
    loading: ?boolean,
    itemsDisplayed?: number,
    showHeaderCaret?: boolean,
    report?: boolean,
};

type State = {
    showViewMore: boolean,
    showUserOptions: ?number,
    showEquipmentOptions: ?number,
    sortKey: string,
    // sortedData: ?ImmutableList<string>,
};

class DashboardComponent extends React.PureComponent<Props, State> {
    static defaultProps = {
        list: false,
        paginated: null,
        sliceUsers: true,
        itemsDisplayed: 10,
        showHeaderCaret: true,
        report: false,
    };

    state = {
        showViewMore: false,
        showUserOptions: -1,
        showEquipmentOptions: -1,
        sortKey: '',
        // sortedData: null,
    };

    handleOnClickDataItem = (id: number) => () => {
        this.props.onClickDataItem(id);
    };

    handleShowUserOptions = (user: UserType) => {
        if (this.state.showUserOptions === user.get('id')) {
            return this.setState({
                showUserOptions: -1,
            });
        }

        this.setState({
            showUserOptions: user.get('id'),
        });
    };

    handleShowEquipmentOptions = (equipment: EquipmentType) => {
        if (this.state.showEquipmentOptions === equipment.get('id')) {
            return this.setState({
                showEquipmentOptions: -1,
            });
        }

        this.setState({
            showEquipmentOptions: equipment.get('id'),
        });
    };

    formatLastLogin = (user) => {
        if (!user.get('lastLogin')) {
            return null;
        }

        return dateFns.format(user.get('lastLogin'), 'YYYY/MM/DD - h:mm A');
    };

    getHeaderCaret = (key: string) => (this.state.sortKey === key ? CaretUp : CaretDown);

    renderHeader = () => {
        return this.props.columns.map((column, index) => {
            return (
                <Dashboard.HeaderItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClick={
                        this.props.onClickHeaderItem &&
                        this.props.onClickHeaderItem(column.key, column.sortMethod)
                    }
                >
                    <Dashboard.HeaderText>{column.label}</Dashboard.HeaderText>
                    {this.props.showHeaderCaret && (
                        <Dashboard.HeaderCaret src={this.getHeaderCaret(column.key)} />
                    )}
                </Dashboard.HeaderItem>
            );
        });
    };

    renderTableData = () => {
        let dataItems = this.props.tableData;
        if (!this.props.tableData) {
            return false;
        }

        if (this.state.sortedData && this.state.sortedData.size > 0) {
            dataItems = this.state.sortedData;
        }

        if (
            typeof this.props.onClickViewMore === 'function' &&
            !this.props.list &&
            dataItems &&
            dataItems.size > this.props.itemsDisplayed
        ) {
            // show the first 10 items.
            dataItems = dataItems.take(this.props.itemsDisplayed);
            this.setState({
                showViewMore: true,
            });
        }

        if (this.props.report) {
            dataItems = dataItems.take(this.props.itemsDisplayed);
        }

        if (
            this.props.paginated &&
            this.props.paginated.get('currentPage') !== this.props.paginated.get('lastPage')
        ) {
            this.setState({
                showViewMore: true,
            });
        }

        const listItems = dataItems.map((data: ImmutableMap, index: number) => {
            let componentProps = {
                rowSpan: this.props.columns.length,
                data,
            };

            if (this.props.component) {
                componentProps = {
                    rowSpan: this.props.columns.length,
                    data,
                    ...this.props,
                    showUserOptions: this.state.showUserOptions,
                    showEquipmentOptions: this.state.showEquipmentOptions,
                    handleShowUserOptions: this.handleShowUserOptions,
                    handleShowEquipmentOptions: this.handleShowEquipmentOptions,
                };
            }

            if (!data) {
                return false;
            }
            return (
                <Dashboard.DataItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${data.get(this.props.id)}-${index}`}
                    columns={this.props.columns.length}
                    onClick={
                        this.props.onClickDataItem
                            ? this.handleOnClickDataItem(data.get('id'))
                            : null
                    }
                >
                    {this.props.component ? (
                        <DashboardDataComponent
                            {...componentProps}
                            component={this.props.component}
                        />
                    ) : (
                        <OrganizationListItem
                            columns={this.props.columns}
                            data={data}
                            rowSpan={this.props.columns.length}
                        />
                    )}
                </Dashboard.DataItem>
            );
        });

        return (
            <Dashboard.GridContainer columns={this.props.columns.length}>
                {listItems}
            </Dashboard.GridContainer>
        );
    };

    renderShowMoreUsers = () => {
        return this.props.tableData.size > 0 ? (
            <Dashboard.ViewMore onClick={this.props.onClickViewMore}>
                <OutlineButton outlineColor={colors.green73} hoverTextColor={colors.white}>
                    {this.props.loading ? (
                        <FormattedMessage id={'components.Loading'} />
                    ) : (
                        <FormattedMessage id={'views.BranchPage.ViewMore'} />
                    )}
                </OutlineButton>
            </Dashboard.ViewMore>
        ) : null;
    };

    render() {
        return (
            <Dashboard loading={this.props.loading}>
                <Dashboard.HeaderContainer columns={this.props.columns.length}>
                    {this.renderHeader()}
                </Dashboard.HeaderContainer>
                <Dashboard.Split
                    columns={this.props.columns.length}
                    color={colors.black}
                    height={'3px'}
                />
                {this.renderTableData()}
                {this.state.showViewMore ? this.renderShowMoreUsers() : null}
            </Dashboard>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    locale: selectLocale(),
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(DashboardComponent)
);
