// @flow

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectShowModal } from 'services/Modal/selectors';
import { closeModal } from 'services/Modal/thunks';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';
import { toggleSideMenu } from 'services/SideMenu/thunks';

import type { VehicleType } from 'types';

type Props = {
    closeModal: () => void,
    showModal: boolean,
    size: ?{
        width: string | number,
        height: string | number,
    },
};

import colors from 'styles/colors';
import { Modal } from 'styles/modal';

import Close from 'assets/close-x.svg';

class ModalComponent extends React.PureComponent<Props> {
    componentDidUpdate(prevProps) {
        if (!prevProps.showModal && this.props.showModal) {
            // scroll modal into view and disable scrolling.
            this.modal.scrollIntoView();
        }

        if (!this.props.showModal) {
            // re-enable scrolling
        }
    }

    handleCloseModal = () => {
        this.props.closeModal();

        if (!this.props.isSideMenuOpen) {
            this.props.toggleSideMenu();
        }
    };

    setModalRef = (component) => {
        this.modal = component;
    };

    render() {
        if (!this.props.showModal) {
            return false;
        }

        return (
            <Modal innerRef={this.setModalRef} onScroll={this.props.onScroll}>
                <Modal.Container size={this.props.size}>
                    {this.props.children}
                    <Modal.Close onClick={this.handleCloseModal}>
                        <Modal.Image src={Close} width={'10px'} height={'40px'} />
                    </Modal.Close>
                </Modal.Container>
            </Modal>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    showModal: selectShowModal(),
    isSideMenuOpen: selectIsSideMenuOpen(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            closeModal,
            toggleSideMenu,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalComponent);
