// @flow

import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';
import { Image } from 'styles/sidemenu';

const ActionRightSideMenu = styled.div`
    position: ${({isMobile}) => (isMobile ? 'absolute' : 'relative')};
    display: flex;
    height: 100vh;
    right: 0;
    z-index: 1;
    width: ${({isMobile, menuOpen}) => (isMobile ? menuOpen ? '100%' : '60px': 'inherit')};
`;

const ActionSideMenuKeyframesFadeOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

ActionRightSideMenu.ActionBar = styled.div`
    width: 60px;
    height: ${({isMobile}) => (isMobile ? '60px' : '100%')};
    position: ${({isMobile}) => (isMobile ? 'absolute' : 'relative')};
    right: 0;
    display: ${({menuOpen}) => (menuOpen ? 'none': 'initial')};
    overflow: hidden;
    background:${colors.sideMenuGreen};
    z-index: 1;
    @media (min-width: ${screen.small}) {
       width: 60px;
    }
`;

ActionRightSideMenu.IconContainer = styled.div`
    pointer-events: all;
    background-color: ${colors.sideMenuGreen};
    padding: 2px;
    box-sizing: border-box;
    height: 60px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    overflow: hidden;
    color: ${colors.white};
    font-size: 0.85em;
    transition: background 250ms ease-in-out 0s;
    animation: ${ActionSideMenuKeyframesFadeOut} 250ms ease-in-out 0s;
    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
`;

ActionRightSideMenu.MenuIcon = styled.img`
    height: 100%;
    width: 28px;
    object-fit: contain;
`;

ActionRightSideMenu.MenuIconClick = styled.div`
    pointer-events: all;
    position: absolute;
    width: 60px;
    height: 60px;
`;

ActionRightSideMenu.SideMenu = styled.div`
    width: ${({ open, isPdr, isMobile }) => (open ? (isPdr ? '700px' : (isMobile ? '100%' : '')) : 0)};
    transition: all 0.1s ease-in-out;
`;

export default ActionRightSideMenu;
