// @flow

import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';

export const Loader = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
`;

const animationOdd = keyframes`
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
`;

const animationEven = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;

Loader.Dots = styled.div`
    display: flex;
    width: 30px;
    height: 6px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
`;

Loader.Dot = styled.span`
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: ${colors.white};
    animation: ${animationOdd} 1s linear infinite;

    &:nth-child(2) {
        animation: ${animationEven} 1s linear infinite;
    }
`;

// Loader.Animation = styled.div`
//     width: 100px;
//     height: 100px;
//     border: 5px solid rgb(255, 255, 255, 0.3);
//     border-radius: 50%;
//     border-top: 5px solid ${colors.green63};
//     animation: ${animation} 2s linear infinite;
// `;
