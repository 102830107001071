import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import Header from 'components/Modals/Header';
import FormGroup from 'components/Modals/FormGroup';
import FormInputText from 'components/FormInputText';
import OutlineButton from 'components/OutlineButton';
import SelectField from 'components/SelectField';
import Error from 'components/Error';
import colors from 'styles/colors';
import { withRouter } from 'react-router-dom';
import { deleteUserError } from 'services/User/thunks';

import { getEquipment, equipmentError } from 'services/Equipment/thunks';
import {
    selectEquipmentTypes,
    selectEquipment,
    selectEquipmentPagination,
    selectEquipmentSearchResults,
    selectEquipmentAssociationErrors,
    selectEquipmentError,
} from 'services/Equipment/selectors';

import { selectModalAction, selectEquipmentId } from 'services/Modal/selectors';
import { ASSOCIATE_EQUIPMENT } from 'services/Modal/actions';

import { Modal, Organization, Split, Equipment } from 'styles/modal';

import type { UserDataType, EquipmentTypes, ImmutableMap, EquipmentListType } from 'types';
import get from 'lodash.get';

type State = {
    name: string,
    equipmentTypeId: number,
    associateEquipmentId: number,
    associateEquipmentSearchQuery: string,
};

type Props = {
    header: string,
    onSubmit: (organization: State) => void,
    equipmentTypes: EquipmentTypes,
};

class EquipmentModalComponent extends React.PureComponent<Props, State> {
    requiredFields = ['name'];

    state = {
        name: undefined,
        vin: undefined,
        color: undefined,
        licensePlate: undefined,
        manufacturer: undefined,
        model: undefined,
        year: undefined,
        // defaultDriverId: undefined,
        equipmentTypeId: 1,
        odometer: undefined,
        horometer: undefined,
        operational: true,
        branchId: undefined,
    };

    componentDidMount() {
        /* if (!this.props.equipment || this.props.equipment.size === 0) {
            const page =
                this.props.paginated && this.props.paginated.get('currentPage')
                    ? this.props.paginated.get('currentPage')
                    : 0;
            this.props.getEquipment(page);
        }*/

        if (this.props.data && this.props.data.size > 0) {
            const equipment = this.findEquipment(this.props.data, this.props.equipmentId);

            if (equipment) {
                this.hydrateState(equipment);
            }
        }
    }

    componentWillUnmount() {
        this.props.equipmentError({});
    }

    verifyMandatoryFields = () => {
        const errors = {};
        this.requiredFields.forEach((field) => {
            const value = get(this.state, field);
            if (!value || value.length === 0) {
                errors[field] = 'Field is required';
            }
        });

        return this.props.equipmentError(errors);
    };

    findEquipment = (data: ImmutableList<EquipmentType>, equipmentId: number) =>
        data.find((equipment: EquipmentType) => equipment.get('id') === equipmentId);

    hydrateState = (data: UserDataType) => {
        const newState = {};
        Object.keys(this.state).forEach((key: string) => {
            newState[key] = data.get(key);
        });

        this.setState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };

    handleOnChangeText = (key: string) => (event: Event) => {
        const value = event.target.value;
        this.setState({
            [key]: value,
        });
    };

    filterUndefined = (obj) => {
        if (!obj) return obj;
        return Object.keys(obj).reduce((prev, curr) => {
            if (obj[curr] !== undefined && obj[curr] !== null) prev[curr] = obj[curr];
            return prev;
        }, {});
    };

    handleSubmit = () => {
        if (!this.verifyMandatoryFields()) return;
        let equipmentId = this.props.equipmentId;

        if (this.props.action === ASSOCIATE_EQUIPMENT) {
            equipmentId = this.state.associateEquipmentId;
        }
        const formState = this.filterUndefined(this.state);
        ['equipmentTypeId', 'branchId', 'year', 'odometer', 'horometer'].forEach((field) => {
            if (field in formState) {
                formState[field] = Number(formState[field]);
            }
        });
        formState.branchId = Number(this.props.match.params.organizationId);

        this.props.onSubmit(formState, equipmentId);
    };

    handleOnChangeSelect = (key: string) => (value: any) => {
        this.setState({ [key]: value });
    };

    handleOnChangeCheck = (key: string) => () => {
        this.setState((prev) => ({
            [key]: !prev[key],
        }));
    };

    handleScrollEquipment = (e: Event) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            // load more results.
            const page =
                this.props.paginated && this.props.paginated.get('currentPage')
                    ? this.props.paginated.get('currentPage')
                    : 0;
            const searchInput = this.state.associateEquipmentSearchQuery;
            this.props.getEquipment(page + 1, searchInput);
        }
    };

    handleSearchInputChange = (input: string) => {
        const page =
            this.props.paginated && this.props.paginated.get('currentPage')
                ? this.props.paginated.get('currentPage')
                : 0;
        this.props.getEquipment(page, input).then(() => {
            this.setState({
                associateEquipmentSearchQuery: input,
            });
        });
    };

    getEquipmentTypeOptions = () => {
        if (this.props.equipmentTypes && this.props.equipmentTypes.size > 0) {
            return this.props.equipmentTypes
                .map((equipment) => ({
                    value: equipment.get('id'),
                    label: equipment.get('name'),
                }))
                .toArray();
        }
    };

    getAssociateEquipmentOptions = (equipment: EquipmentListType) => {
        return equipment
            .map((e) => ({
                label: e.get('name'),
                value: e.get('id'),
            }))
            .toJS();
    };

    renderAssociateModal = () => {
        const equipment =
            this.state.associateEquipmentSearchQuery &&
            this.props.associateEquipmentSearchResults.size > 0
                ? this.props.associateEquipmentSearchResults
                : this.props.equipment;
        return (
            <div>
                {this.props.associationErrors && <Error text={this.props.associationErrors} />}
                <FormGroup columns={1}>
                    <Modal.SelectOverride>
                        <SelectField
                            value={this.state.associateEquipmentId}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.Modals.Form.Equipment.Equipment',
                            })}
                            options={this.getAssociateEquipmentOptions(equipment)}
                            borderColor={colors.grayDD}
                            textColor={colors.black}
                            padding={'2px 0 0 12px'}
                            onChange={this.handleOnChangeSelect('associateEquipmentId')}
                            onScroll={this.handleScrollEquipment}
                            onInputChange={this.handleSearchInputChange}
                            searchable
                        />
                    </Modal.SelectOverride>
                </FormGroup>
            </div>
        );
    };

    renderModalContent = () => {
        const equipmentTypeOptions = [
            {
                value: 1,
                label: this.props.intl.formatMessage({
                    id: 'components.Modals.Form.Equipment.EquipmentType.test',
                }),
            },
        ];
        if (this.props.action === ASSOCIATE_EQUIPMENT) {
            return this.renderAssociateModal();
        } else {
            return (
                <React.Fragment>
                    <FormGroup columns={1}>
                        <FormInputText
                            value={this.state.name}
                            placeholderId={'components.Modals.Form.Equipment.Name'}
                            onChange={this.handleOnChangeText('name')}
                            error={this.props.error.name}
                            required
                        />
                    </FormGroup>
                    <FormGroup columns={2}>
                        <FormInputText
                            value={this.state.manufacturer}
                            placeholderId={'components.Modals.Form.Equipment.Manufacturer'}
                            onChange={this.handleOnChangeText('manufacturer')}
                            error={this.props.error.manufacturer}
                        />
                        <FormInputText
                            value={this.state.model}
                            placeholderId={'components.Modals.Form.Equipment.Model'}
                            onChange={this.handleOnChangeText('model')}
                            error={this.props.error.model}
                        />
                    </FormGroup>
                    <FormGroup columns={2}>
                        <FormInputText
                            value={this.state.licensePlate}
                            placeholderId={'components.Modals.Form.Equipment.LicesePlate'}
                            onChange={this.handleOnChangeText('licensePlate')}
                            error={this.props.error.licensePlate}
                        />
                        <FormInputText
                            value={this.state.vin}
                            placeholderId={'components.Modals.Form.Equipment.Vin'}
                            onChange={this.handleOnChangeText('vin')}
                            error={this.props.error.vin}
                        />
                    </FormGroup>
                    <FormGroup columns={2}>
                        <FormInputText
                            value={this.state.year}
                            placeholderId={'components.Modals.Form.Equipment.Year'}
                            onChange={this.handleOnChangeText('year')}
                            error={this.props.error.year}
                        />
                        <FormInputText
                            value={this.state.color}
                            placeholderId={'components.Modals.Form.Equipment.Color'}
                            onChange={this.handleOnChangeText('color')}
                            error={this.props.error.color}
                        />
                    </FormGroup>
                    <FormGroup columns={2}>
                        <FormInputText
                            value={this.state.odometer}
                            placeholderId={'components.Modals.Form.Equipment.Odometer'}
                            onChange={this.handleOnChangeText('odometer')}
                            error={this.props.error.odometer}
                            disabled={Boolean(this.props.equipmentId)}
                        />
                        <FormInputText
                            value={this.state.horometer}
                            placeholderId={'components.Modals.Form.Equipment.Horometer'}
                            onChange={this.handleOnChangeText('horometer')}
                            error={this.props.error.horometer}
                            disabled={Boolean(this.props.equipmentId)}
                        />
                    </FormGroup>
                    {/* <FormGroup columns={2}>
                        <Modal.SelectOverride>
                            <SelectField
                                placeholder={this.props.intl.formatMessage({
                                    id: 'components.Modals.Form.Equipment.EquipmentType',
                                })}
                                options={equipmentTypeOptions}
                                borderColor={colors.grayDD}
                                textColor={colors.black}
                                onChange={this.handleOnChangeSelect('equipmentTypeId')}
                                value={this.state.equipmentTypeId}
                            />
                        </Modal.SelectOverride>
                        <CheckInput
                            value={this.state.operational}
                            placeholderId={'components.Modals.Form.Equipment.Operational'}
                            onChange={this.handleOnChangeCheck('operational')}
                        />
                    </FormGroup> */}
                </React.Fragment>
            );
        }
    };

    render() {
        return (
            <Organization>
                <Header headerTextId={this.props.header} />
                <Equipment.Container>{this.renderModalContent()}</Equipment.Container>
                <div style={{ marginTop: 20 }} />
                <OutlineButton
                    outlineColor={colors.green73}
                    backgroundColor={colors.green73}
                    hoverBackgroundColor={colors.white}
                    hoverTextColor={colors.green73}
                    textColor={colors.white}
                    onClick={this.handleSubmit}
                >
                    <FormattedMessage id={'components.Modals.Send'} />
                </OutlineButton>
            </Organization>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    action: selectModalAction(),
    equipmentTypes: selectEquipmentTypes(),
    equipmentId: selectEquipmentId(),
    equipment: selectEquipment(),
    paginated: selectEquipmentPagination(),
    associateEquipmentSearchResults: selectEquipmentSearchResults(),
    associationErrors: selectEquipmentAssociationErrors(),
    error: selectEquipmentError(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            equipmentError,
            deleteUserError,
            getEquipment,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(withRouter(EquipmentModalComponent)));
