// @flow

import type { UserDataType, PaginationData, OrganizationListType } from 'types';

// organization
export const GET_ORGANIZATIONS_SUCCESS = 'app/services/Organization/GET_ORGANIZATIONS_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'app/services/Organization/CREATE_ORGANIZATION_ERROR';
export const GET_ORGANIZATION_DATA_SUCCESS =
    'app/services/Organization/GET_ORGANIZATIONS_DATA_SUCCESS';
export const GET_ORGANIZATION_DATA_SUCCESS_NOT_FORMAT =
    'app/services/Organization/GET_ORGANIZATIONS_DATA_SUCCESS_NOT_FROMAT';
export const GET_ORGANIZATION_SUCCESS = 'app/services/Organization/GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_EXTRA_USERS_SUCCESS = 'app/services/Organization/GET_ORGANIZATION_EXTRA_USERS_SUCCESS';
export const GET_ORGANIZATION_EXTRA_USERS_ERROR = 'app/services/Organization/GET_ORGANIZATION_EXTRA_USERS_ERROR';
export const EDIT_ORGANIZATION_SUCCESS = 'app/services/Organization/EDIT_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_USER_SUCCESS =
    'app/services/Organization/GET_ORGANIZATION_USER_SUCCESS';
export const DELETE_ORGANIZATION_USER_SUCCESS =
    'app/services/Organization/DELETE_ORGANIZATION_USER_SUCCESS';
export const DELETE_ORGANIZATION_USER = 'app/services/Organization/DELETE_ORGANIZATION_USER';
export const DELETE_ORGANIZATION_SUCCESS = 'app/services/Organizaiton/DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_EQUIPMENT =
    'app/services/Organization/DELETE_ORGANIZATION_EQUIPMENT';
export const START_LOADING_ORGANIZATIONS = 'app/services/Organization/START_LOADING_ORGANIZATIONS';

export const receivedOrganizationError = (error: any) => ({
    type: CREATE_ORGANIZATION_ERROR,
    payload: { error },
});

export const receivedOrganizationsSuccess = (
    response: PaginationData<OrganizationListType>
): GenericActionType => ({
    type: GET_ORGANIZATIONS_SUCCESS,
    response,
});

export const receivedOrganizationDataSuccess = (organization): GenericActionType => ({
    type: GET_ORGANIZATION_DATA_SUCCESS,
    organization,
});
export const receivedOrganizationExtraUsersSuccess = (users): GenericActionType => ({
    type: GET_ORGANIZATION_EXTRA_USERS_SUCCESS,
    users,
})
export const receivedOrganizationExtraUsersError = (error): GenericActionType => ({
    type: GET_ORGANIZATION_EXTRA_USERS_ERROR,
    error,
})
export const receivedOrganizationDataSuccessNotFormat = (organization): GenericActionType => ({
    type: GET_ORGANIZATION_DATA_SUCCESS_NOT_FORMAT,
    organization,
});

export const receivedOrganizationSuccess = (organization: Organization) => ({
    type: GET_ORGANIZATION_SUCCESS,
    organization,
});

export const receivedEditOrganizationSuccess = (organization: Organization) => ({
    type: EDIT_ORGANIZATION_SUCCESS,
    organization,
});

export const receivedDeleteOrganizationSuccess = () => ({
    type: DELETE_ORGANIZATION_SUCCESS,
});

export const receivedDeleteOrganizationUser = (userId: number) => ({
    type: DELETE_ORGANIZATION_USER_SUCCESS,
    userId,
});

export const receivedDeleteOrganizationEquipment = (equipmentId: number) => ({
    type: DELETE_ORGANIZATION_EQUIPMENT,
    equipmentId,
});

export const startLoading = () => ({
    type: START_LOADING_ORGANIZATIONS,
});
