// @flow

export const TOGGLE_SHOW_ORGANIZATION_MODAL = 'app/services/Modal/TOGGLE_SHOW_ORGANIZATION_MODAL';
export const TOGGLE_SHOW_USER_MODAL = 'app/services/Modal/TOGGLE_SHOW_USER_MODAL';
export const TOGGLE_SHOW_EQUIPMENT_MODAL = 'app/services/Modal/TOGGLE_SHOW_EQUIPMENT_MODAL';
export const TOGGLE_SHOW_EQUIPMENT_CONFIG_MODAL =
    'app/services/Modal/TOGGLE_SHOW_EQUIPMENT_CONFIG_MODAL';
export const TOGGLE_SHOW_CHANGEPASSWORD_MODAL =
    'app/services/Modal/TOGGLE_SHOW_CHANGEPASSWORD_MODAL';
export const CHANGEPASSWORD_LOADING = 'app/services/Modal/CHANGEPASSWORD_LOADING';
export const CHANGEPASSWORD_SUCCESS = 'app/services/Modal/CHANGEPASSWORD_SUCCESS';
export const CHANGEPASSWORD_FAILURE = 'app/services/Modal/CHANGEPASSWORD_FAILURE';

export const CLOSE_MODAL = 'app/services/Modal/CLOSE_MODAL';

// organizations
export const CREATE_BRANCH = 'app/services/Modal/CREATE_BRANCH';
export const CREATE_ORGANIZAITON = 'app/services/Modal/CREATE_ORGANIZATION';
export const CREATE_DIVISION = 'app/services/Modal/CREATE_DIVISION';

export const EDIT_BRANCH = 'app/services/Modal/EDIT_BRANCH';
export const EDIT_ORGANIZAITON = 'app/services/Modal/EDIT_ORGANIZATION';
export const EDIT_DIVISION = 'app/services/Modal/EDIT_DIVISION';

// users
export const CREATE_ORGANIZATION_USER = 'app/services/Modal/CREATE_ORGANIZATION_USER';
export const CREATE_BRANCH_USER = 'app/services/Modal/CREATE_BRANCH_USER';
export const CREATE_DIVISION_USER = 'app/services/Modal/CREATE_DIVISION_USER';

export const EDIT_ORGANIZATION_USER = 'app/services/Modal/EDIT_ORGANIZATION_USER';
export const EDIT_BRANCH_USER = 'app/services/Modal/EDIT_BRANCH_USER';
export const EDIT_DIVISION_USER = 'app/services/Modal/EDIT_DIVISION_USER';

// equipment
export const CREATE_DIVISION_EQUIPMENT = 'app/services/Modal/CREATE_DIVISION_EQUIPMENT';
export const CREATE_BRANCH_EQUIPMENT = 'app/services/Modal/CREATE_BRANCH_EQUIPMENT';
export const CREATE_ORGANIZATION_EQUIPMENT = 'app/services/Modal/CREATE_ORGANIZATION_EQUIPMENT';

export const EDIT_EQUIPMENT_CONFIG = 'app/services/Modal/EDIT_BRANCH_EQUIPMENT_CONFIG';

export const EDIT_ORGANIZATION_EQUIPMENT = 'app/services/Modal/EDIT_ORGANIZATION_EQUIPMENT';
export const EDIT_BRANCH_EQUIPMENT = 'app/services/Modal/EDIT_BRANCH_EQUIPMENT';
export const EDIT_DIVISION_EQUIPMENT = 'app/services/Modal/EDIT_DIVISION_EQUIPMENT';

export const ASSOCIATE_ORGANIZATION_EQUIPMENT =
    'app/services/Modal/ASSOCIATE_ORGANIZATION_EQUIPMENT';
export const ASSOCIATE_BRANCH_EQUIPMENT = 'app/services/Modal/ASSOCIATE_BRANCH_EQUIPMENT';
export const ASSOCIATE_DIVISION_EQUIPMENT = 'app/services/Modal/ASSOCIATE_DIVISION_EQUIPMENT';
export const ASSOCIATE_EQUIPMENT = 'app/services/Modal/ASSOCIATE_EQUIPMENT';

export const TOGGLE_CONFIRMATION_MODAL = 'app/services/Modal/TOGGLE_CONFIRMATION_MODAL';

export const MODAL_TYPE = {
    USER: 'MODAL_TYPE_USER',
    ORGANIZATION: 'MODAL_TYPE_ORGANIZATION',
    EQUIPMENT: 'MODAL_TYPE_EQUIPMENT',
    CONFIRMATION: 'MODAL_TYPE_CONFIRMATION',
    CHANGEPASSWORD: 'MODAL_TYPE_CHANGEPASSWORD',
};

export const receivedToggleOrganizationModal = (modalAction: ?string): GenericActionType => ({
    type: TOGGLE_SHOW_ORGANIZATION_MODAL,
    modalType: MODAL_TYPE.ORGANIZATION,
    modalAction,
});

export const receivedToggleUserModal = (
    modalAction: ?string,
    userId: number
): GenericActionType => ({
    type: TOGGLE_SHOW_USER_MODAL,
    modalType: MODAL_TYPE.USER,
    modalAction,
    userId,
});

export const receivedToggleEquipmentModal = (
    modalAction: ?string,
    equipmentId: ?number
): GenericActionType => ({
    type: TOGGLE_SHOW_EQUIPMENT_MODAL,
    modalType: MODAL_TYPE.EQUIPMENT,
    modalAction,
    equipmentId,
});

export const receivedToggleEquipmentConfigModal = (
    modalAction: ?string,
    equipmentId: ?number
): GenericActionType => ({
    type: TOGGLE_SHOW_EQUIPMENT_CONFIG_MODAL,
    modalType: MODAL_TYPE.EQUIPMENT,
    modalAction,
    equipmentId,
});

export const receivedToggleChangePasswordModal = (): GenericActionType => ({
    type: TOGGLE_SHOW_CHANGEPASSWORD_MODAL,
    modalType: MODAL_TYPE.CHANGEPASSWORD,
});

export const receivedChangePasswordLoading = (): GenericActionType => ({
    type: CHANGEPASSWORD_LOADING,
});

export const receivedChangePasswordSuccess = (): GenericActionType => ({
    type: CHANGEPASSWORD_SUCCESS,
});

export const receivedChangePasswordFailure = (data: *): GenericActionType => {
    return {
        type: CHANGEPASSWORD_FAILURE,
        data: data.data || data.status,
    };
};

export const receivedCloseModal = (): GenericActionType => ({
    type: CLOSE_MODAL,
});

export const receivedToggleConfirmationModal = (data: *): GenericActionType => ({
    type: TOGGLE_CONFIRMATION_MODAL,
    modalType: MODAL_TYPE.CONFIRMATION,
    data,
});
