// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import DummyImage from 'assets/dummy-logo.png';

import { FormGroup } from 'styles/modal';
type Props = {
    columns: ?number,
    size: ?number,
    layout: ?(number[]),
    children: any,
};

function FormGroupComponent(props: Props) {
    return (
        <FormGroup columns={props.columns} size={props.size} layout={props.layout}>
            {props.children}
        </FormGroup>
    );
}

export default FormGroupComponent;
