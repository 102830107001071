// @flow

import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { handleHotometerFormat, kmToMeters, metersToKilometers, sortByChecked } from '../helpers';
import { Table, ConfigProvider } from 'antd';
import Icon from '@material-ui/core/Icon';
import VehicleDetailManagement from '../VehicleDetail/styles';
import {
    getAllMaintenances,
    getMaintenanceVeh,
    getMaintenanceVehByMaintId, insertMaintenanceVehicle,
    patchMaintenanceVehicle,
} from '../../../services/Maintenance/thunks';
import { Input, SearchInput } from '../../../components/SearchInput/styles';
import SelectVehAlarm from './styles';
import { changeUnit, i18nDistanceSymbol, isImperial, setTextunit } from '../../../services/Units/thunks';
import { selectUnit } from '../../../services/Units/selectors';
import moment from 'moment';
import { Input as InputChange } from 'antd';
import fr_FR from 'antd/es/locale-provider/fr_FR';
import en_US from 'antd/es/locale-provider/en_US';
import es_ES from 'antd/es/locale-provider/es_ES';
import { selectLocale } from '../../../services/Language/selectors';
import EditTableAlarme from '../EditTableAlarme';
const SelectVehiclesAlarms = (props) => {
        const [vehiclesAlarms, setvehiclesAlarms] = useState([]);
        const [selectedVehicles, setSelectedVehicles] = useState([]);
        const [selectedVeh, setSelectedVeh] = useState([])
        const [selectedCumule, setSelectedCumule] = useState([]);
        const [searchInput, setSearchInput] = useState('');
        const [vehiclesDatas, setVehiclesDatas] = useState([]);
        const [newVeh, setNewVeh] = useState([]);
        const [kmCumul, setKmCumul] = useState(0);
        const [hrsCumul, setHrsCumul] = useState(0);
        const [indexKmModify, setIndexKmModify] = useState(-1);
        const [indexHrsModify, setIndexAHrsModify] = useState(-1);
        const [localLang, setLocalLang] = useState(en_US);
        const [selectedVehiclesInfo, setSelectedVehiclesInfo] = useState([]);
        const [selectedVehiclesId, setSelectedVehiclesId] = useState([]);

        function updateTabBasedOnSelection(tab, test) {
            const newTab = new Map(test.map(item => [item.id, item]));
            return tab.map(item => {
                if (newTab.has(item.id)) {
                    const newItem = newTab.get(item.id);
                    return {
                        ...item,
                        cumulDistance: newItem.cumulDistance,
                        cumulHrs: newItem.cumulHrs
                    };
                }
                return item;
            });
        }


    useEffect(() => {

            let vehicules = props.vehicleList.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    odometer: item.odometer,
                    horometer: item.horometer,
                    cumulDistance: 0,
                    cumulHrs: 0,
                    lastAlert: undefined,
                    hasAlert: false,
                    selected :false
                };
            });
            setSelectedVeh(vehicules);
            if (props.alarmsDatas && props.alarmsDatas.maintId !== undefined) {
                props.getMaintenanceVehByMaintId(props.alarmsDatas.maintId)
                    .then((response) => {
                        response.map((item) => {
                            vehicules.map((veh) => {
                                if (veh.id === item.vehId) {
                                    veh.cumulDistance = item.cumulDistance;
                                    veh.cumulHrs = item.cumulHrs;
                                    veh.lastAlert = item.lastAlert;
                                    veh.hasAlert = true;
                                    veh.selected = true;
                                }
                            });
                        });
                        vehicules = sortByChecked(vehicules);
                        if (searchInput === '') {
                            const updatedTab = updateTabBasedOnSelection(vehicules, newVeh);
                            setVehiclesDatas(updatedTab);
                        } else {
                            let searchResults = vehicules.filter(item =>
                                item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchInput.toLowerCase()),
                            );
                            searchResults = sortByChecked(searchResults);
                            if (searchResults.length !== vehicules.length) {
                                const updatedTab = updateTabBasedOnSelection(searchResults, newVeh);
                                setVehiclesDatas(updatedTab);
                            } else {
                                setVehiclesDatas(vehicules);
                            }
                        }

                        setvehiclesAlarms(response);
                    });
            }

        }, [props.alarmsDatas, searchInput, props.vehicleList]);
        useEffect(() => {
            setNewVeh([]);
            setSelectedVehicles([]);
            setSelectedVehiclesInfo([]);
            setSelectedVehiclesId([])
            setSelectedCumule([])
        }, [props.alarmsDatas!== undefined ? props.alarmsDatas.mainId: null]);
        useEffect(() => {
            if (selectedVehiclesInfo.length > 0) {
                setNewVeh(selectedVehiclesInfo);
                setSelectedVehicles(selectedVehiclesId);
                setSelectedCumule(selectedVehiclesId);
            } else if (vehiclesAlarms !== undefined) {
                setNewVeh(vehiclesAlarms);
                const vehId = vehiclesAlarms.map((item) => {
                    return item.vehId;
                });
                setSelectedVehicles(vehId);
                setSelectedCumule(vehId);
            }
        }, [vehiclesAlarms]);

        useEffect(() => {
            const list =[]
                    if(searchInput && selectedCumule != selectedVehicles)
                    {
                        const arrList =[...selectedCumule,...selectedVehicles]
                        arrList.forEach(el=>{
                            selectedVeh.forEach(l=>{
                                if( l.id === el ){
                                    l.selected =true;
                                    list.push(l)
                                }
                                });
                            })
                    }
                    else{
                        selectedVehicles.forEach(el=>{
                            vehiclesDatas.forEach(l=>{if( l.id === el ){
                                l.selected =true;
                                list.push(l)}});})
                    }
                    list.forEach(el=>{
                        vehiclesDatas.forEach(l=>{
                            if(l.id === el.id && l.selected){
                                el.cumulDistance =l.cumulDistance;
                                el.cumulHrs=l.cumulHrs;
                            }
                        })
                    })
            props.handleSelectedVehicles(list);
        }, [vehiclesDatas,selectedVehicles]);

        useEffect(() => {
            switch (props.locale) {
                case 'en':
                    moment.locale('en-ca');
                    setLocalLang(en_US);
                    break;
                case 'fr':
                    moment.locale('fr');
                    setLocalLang(fr_FR);
                    break;
                case 'es':
                    moment.locale('es');
                    setLocalLang(es_ES);
                    break;
                default:
                    moment.locale('en-ca');
            }
        });
        const updateVehicles = (newdatas) => {

            setVehiclesDatas(newdatas);

        };
        const handleOnSearch = (event) => {
            setSearchInput(event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        };
        const handleInputCumul = (event, record) => {
            if (event.target.id === 'cumulDistance') {
                setKmCumul( event.target.value);
            }
            if (event.target.id === 'cumulHrs') {
                setHrsCumul(event.target.value.includes('.') ? event.target.value.slice(0, event.target.value.length - 2) : event.target.value);
            }
        };

        const handleModifyCum = (event, index, inputId) => {
            if (inputId === 'cumulDistance') {
                setIndexKmModify(index);
            }
            if (inputId === 'cumulHrs') {
                setIndexAHrsModify(index);
            }

        };
        const insertCumul = (event, item, inputId) => {

            vehiclesDatas.map((veh) => {
                if (veh.id === item.id) {

                    if (inputId === 'cumulDistance') {
                        veh.cumulDistance = kmCumul;
                        setKmCumul(0);
                    }
                    if (inputId === 'cumulHrs') {
                        veh.cumulHrs = hrsCumul;
                        setHrsCumul(0);
                    }
                    let data = {
                        cumul_distance: props.isImperial() ? (Math.round((veh.cumulDistance * 1.609)).toFixed(1))* 1000  :  veh.cumulDistance * 1000,
                        cumul_hrs: veh.cumulHrs * 60 * 60,
                        last_alert: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                        maint_id: props.alarmsDatas.maintId,
                    };

                    props.handleCumulCycle(veh.id, data);
                }
            });
            if (inputId === 'cumulDistance') {
                setIndexKmModify(-1);
            }
            if (inputId === 'cumulHrs') {
                setIndexAHrsModify(-1);
            }
        };
        const handleOnBlurCumul = (event, item, inputId) => {
            insertCumul(event, item, inputId);
        };
        const handleKeyDownCumul = (event, item, inputId) => {
            if (event.key === 'Enter') {
                insertCumul(event, item, inputId);
            }
        };
    const renderCumul = (value, record, indexModify, inputId) => {
        const formatValue = (value, type) => {
            if (type === 'cumulDistance') {
                const conversionFactor = props.isImperial() ? 1609 : 1000;
                return (Math.floor((value / conversionFactor) * 100) / 100).toFixed(2);
            } else { // 'cumulHrs'
                return (Math.floor((value / 60 / 60) * 100) / 100).toFixed(2);
            }
        };

        const renderEditableInput = (item) => (
            <InputChange
                onBlur={(event) => handleOnBlurCumul(event, item, inputId)}
                id={inputId}
                value={inputId === 'cumulDistance' ? kmCumul : hrsCumul}
                onChange={(event) => handleInputCumul(event, record)}
                onKeyDown={(event) => handleKeyDownCumul(event, item, inputId)}
            />
        );

        return (
            <div>
                {record.hasAlert || selectedVehicles.includes(record.id) ? (
                    <VehicleDetailManagement.IconEdit style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
                        {indexModify === -1 ? (
                            <span>{formatValue(value, inputId)}</span>
                        ) : (
                            newVeh.map((item, index) => {
                                if (record.id === item.id) {
                                    return indexModify !== index ? (
                                        <span key={record.id}>{formatValue(value, inputId)}</span>
                                    ) : (
                                        renderEditableInput(item)
                                    );
                                }
                            })
                        )}
                    </VehicleDetailManagement.IconEdit>
                ) : (
                    <span>{formatValue(value, inputId)}</span>
                )}
            </div>
        );
    };

    const columns = [
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeName' }),
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),

            },
            {
                title: props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeOdometer' }) : props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetails.Miles' }),
                dataIndex: 'odometer',
                key: 'odometer',
                sorter: (a, b) => a.odometer - b.odometer,
                render: (value, record) => {
                    return (
                        <div>
                            {`${props.setTextunit(
                                metersToKilometers(value),
                                'odometer',
                            )}`}
                        </div>
                    );
                },
            },
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeHorometer' }),
                dataIndex: 'horometer',
                key: 'horometer',
                sorter: (a, b) => a.horometer - b.horometer,
                render: (value, record, index) => {
                    return (
                        <div>
                            {handleHotometerFormat(value)}
                        </div>
                    );
                },

            },
            {
                title: props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfKmCumulated' }) : props.intl.formatMessage({ id: 'containers.AlarmVehicleList.VehicleDetails.MilesCum' }),
                dataIndex: 'cumulDistance',
                key: 'cumulDistance',
                editable: true,
                sorter: (a, b) => a.cumulDistance - b.cumulDistance,
                render: (value, record) => {
                    return (
                        <div>
                            { record.hasAlert || selectedVehicles.includes(record.id)?

                                <VehicleDetailManagement.IconEdit style={{
                                    display:' flex',
                                    alignItems:' center',
                                }}>
                                    <div style={{ width: '45%', textAlign: 'left' }}>
                                      {renderCumul(value, record, indexKmModify, 'cumulDistance')}
                                    </div>

                                        <Icon className='fa fa-edit' style={{fontSize:'15px'}}/>

                                </VehicleDetailManagement.IconEdit>
                             : renderCumul(value, record, indexKmModify, 'cumulDistance')}

                        </div>);

                },

            },

            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfHrsCumulated' }),
                dataIndex: 'cumulHrs',
                key: 'cumulHrs',
                editable: true,
                sorter: (a, b) => a.cumulHrs - b.cumulHrs,
                render: (value, record) => {
                    return (
                        <div>
                           { record.hasAlert || selectedVehicles.includes(record.id)?
                                <VehicleDetailManagement.IconEdit style={{
                                    display:' flex',
                                    alignItems:' center',
                                    }}>
                                    <div style={{ width: '45%', textAlign: 'left' }}>
                                         {renderCumul((value), record, indexHrsModify, 'cumulHrs')}
                                    </div>

                                        <Icon className='fa fa-edit' style={{fontSize:'15px'}}/>

                                </VehicleDetailManagement.IconEdit>
                            : renderCumul((value), record, indexHrsModify, 'cumulHrs')  }
                        </div>
                    );

                },
            },
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.AlarmVehicle.Changes' }),
                dataIndex: 'lastAlert',
                key: 'lastAlert',
                width: '200px',
                sorter: (a, b) => new Date(a.lastAlert) - new Date(b.lastAlert),
                render: (value, record) => {
                    return (
                        <div>{value === undefined ? '' : moment(value).format('YYYY-MM-DD')}</div>
                    );
                },
            },


        ];

        const rowSelection = {
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
                setNewVeh(selectedRows);
                setSelectedVehicles([...selectedRowKeys]);
                setSelectedVehiclesInfo(selectedRows);
                setSelectedVehiclesId(selectedRowKeys)
                setSelectedCumule(selectedRowKeys)



            },
            // selections: [
            //     Table.SELECTION_ALL,
            //     {
            //         key: 'clear',
            //         text: props.intl.formatMessage({ id: 'containers.Maintenance.SelectVehAlarm.Clear' }),
            //         onSelect: changableRowKeys => {
            //             setNewVeh([]);
            //             setSelectedVehicles([]);
            //         },
            //     },
            // ],

        };
        return (

            <div>
                <ConfigProvider locale={localLang}>
                    <div className={'title'}>
                        <FormattedMessage id={'containers.Maintenance.SelectVehAlarm.Title'} />
                    </div>
                    <SelectVehAlarm>
                        <VehicleDetailManagement.SearchContainer style={{ marginBottom: '15px' }}>
                            <SearchInput>
                                <Input
                                    placeholder={
                                        props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehicleSearch' })
                                    }
                                    onChange={handleOnSearch}
                                />
                            </SearchInput>
                        </VehicleDetailManagement.SearchContainer>
                        {props.alarmsDatas ?
                            <EditTableAlarme
                                rowSelection={{
                                    type: 'checkbox',
                                    selectedRowKeys: selectedVehicles,
                                    ...rowSelection,

                                }}
                                EditAlarmType="1"
                                columns={columns}
                                dataSource={vehiclesDatas}
                                updateVehicles={updateVehicles}
                                maintId={props.alarmsDatas.maintId}
                                isDataLoaded={props.isDataLoaded}
                                pagination={true}
                                width={400}/>
                            :
                            <EditTableAlarme
                                rowSelection={{
                                    type: 'checkbox',
                                    selectedRowKeys: selectedVehicles,
                                    ...rowSelection,

                                }}

                                columns={columns}
                                dataSource={vehiclesDatas}
                                updateVehicles={updateVehicles}

                                isDataLoaded={props.isDataLoaded}
                                pagination={true}
                                width={400}/>
                        }
                    </SelectVehAlarm>
                </ConfigProvider>
            </div>
        );
    }
;

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector(
    {
        unit: selectUnit(),
        locale: selectLocale(),
    },
);

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getMaintenanceVehByMaintId,
            setTextunit,
            changeUnit,
            i18nDistanceSymbol,
            patchMaintenanceVehicle,
            getMaintenanceVeh,
            getAllMaintenances,
            insertMaintenanceVehicle,
            isImperial
        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(SelectVehiclesAlarms));
