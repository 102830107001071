import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

const BranchList = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: ${(props) => (!props.isOpen ? '30px 50px 0 110px;' : '30px 50px 0 50px;')};
`;

BranchList.Item = styled.div`
    grid-column: span 4;
    cursor: pointer;
`;

BranchList.Container = styled.div``;

BranchList.GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 60px);
    ${BranchList.Item}:nth-child(odd) {
        background-color: ${colors.grayF8};
    }
`;

BranchList.Split = styled.div`
    height: ${(props) => props.height || '1px'};
    background-color: ${(props) => props.color || `${colors.black}`};
`;

BranchList.HeaderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 35px);
`;

BranchList.HeaderCaret = styled.img`
    width: 14px;
    height: 9px;
`;

BranchList.HeaderText = styled.div`
    margin-right: 14px;
    font-weight 600;
    float: left;
`;

BranchList.Header = styled.div`
    height: 35px;
`;

BranchList.HeaderItem = styled.div`
    cursor: pointer;
`;

BranchList.FilterContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 50px;

    > div {
        width: 100%;
        margin-right: 0;
    }

    @media (min-width: ${screen.large}) {
        padding-right: 23px;
        width: 50%;

        > div {
            width: auto;
            margin-right: 10px;
        }
    }
`;
BranchList.SearchContainer = styled.div`
    margin-right: 10px;
`;

BranchList.ControllerContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

BranchList.ControllerHeader = styled.div`
    display: felx;
    align-items: center;
    flex: 1;
    font-weight: 600;
    font-size: 22px;
`;

BranchList.ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;
BranchList.ActionButtonContainer = styled.div`
    flex: 1;
    margin-left: 10px;
    ${(props) => (props.shrink ? 'flex-grow: 0.2' : null)};
`;

export default BranchList;
