//@flow
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';
import {selectCategories} from "../../services/Categorie/selectors";
import {
    getCategoriesByBranch,
} from "../../services/Categorie/thunks";
import Fuse from "fuse.js";
import {fromJS} from "immutable";
import { tuple } from 'antd/lib/_util/type';
import {CategoryFilterStyle} from "./styles";


const CategoryFilter = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [fuse, setFuse] = useState();
    const [mixMode,setMixMode] = useState(false);
    const [categoryBox, setCategoryBox] = useState(false);
    const [isCategorySelected,setIsCategorySelected] = useState(false);
    const [isMouseInCategoryBox,setIsMouseInCategoryBox] = useState(false);
    const [data,setData] = useState([]);

    let fuseOptions = {
        keys: props.searchKeys,
        useExtendedSearch: true,
        findAllMatches: true,
    }

    useEffect(() => {

        if(mixMode){
            categoriesRender()
        }

    }, [props.searchQuery,mixMode]);

    useEffect(() => {
        props.getCategoriesByBranch();
    }, []);

    useEffect(() => {
        let box = document.getElementById('filterBox');
        if(box.style.display === "none" && categoryBox){
            box.style.display = "block";
            box.style.opacity = 1;
        }else{
            box.style.display = "none";
            box.style.opacity = 0;
        }
    }, [categoryBox]);

    useEffect(() => {
        if (props.data && props.data.size > 0) {
            setFuse(new Fuse(props.data.toJS(), fuseOptions));
        }
    }, [props.data.size]);

    useEffect(() => {
        handleSelectAllChangeLabel(isCategorySelected)
    }, [isCategorySelected]);

    useEffect(() => {
        categoriesRender()
    }, [data,props.searchQuery]);

    useEffect(() => {
        if(props.data.size >0){
            handleCreateCategorySearchQuery()
        }
    }, [props.data]);

    const handleOnClickFilterButton = () => {
        setCategoryBox(!categoryBox)
    }

    const categoriesRender = () =>{
        const fuseOption = {
            keys: ['name'],
            threshold: 0.2,
            tokenize: true,
            matchAllTokens: true,
        }
        const dataFuse = new Fuse(data, fuseOption)
        let vehicleList = data;
        if(props.searchQuery.length>0){
            vehicleList = handleConvertResult(dataFuse.search(props.searchQuery));
        }
        props.onSearchResults({
            query: props.searchQuery,
            results:fromJS(vehicleList),
            fetchNextPage: true,
            fuse: dataFuse,
            isCategorySelected : isCategorySelected
        });
    }

    const hanldeOnBlurFilterBox = () => {
        if(!isMouseInCategoryBox){
            setCategoryBox(false)
        }
    }

    const handleSelectAllChangeLabel =  (boolean) => {
        let btn = document.getElementById('selectAllBtn');
        if(!boolean){
            btn.innerHTML =  props.intl.formatMessage({ id: 'components.SideMenu.FleetOverviewSelectAll' })
        }else{
            btn.innerHTML =  props.intl.formatMessage({ id: 'components.SideMenu.FleetOverviewUnSelectAll' })
        }
    }

    const handleSelectAll = () => {
        let checkedBoxes = document.querySelectorAll('input[name=filterCheckboxes]');
        setIsCategorySelected(!isCategorySelected);
        handleSelectAllChangeLabel(isCategorySelected)
        checkedBoxes.forEach((checkbox) => {
            checkbox.checked = !isCategorySelected;
        })
        handleCreateCategorySearchQuery()
    }

    const handleConvertResult = (results) => {
        let list = []
        results.forEach((r) => {
            list.push(r.item);
        })
        return list;
    }

    const handleCreateCategorySearchQuery = () => {
        let checkedBoxes = document.querySelectorAll('input[name=filterCheckboxes]:checked');
        let searchQueryCat = "";
        checkedBoxes.forEach((checkbox) => {
            searchQueryCat += "'"+checkbox.value+" | ";
        })
        setSearchQuery(searchQueryCat)
        const options = {
            includeScore: true,
            useExtendedSearch: true,
            keys: ['categorie.categorieName']
        }
        const fuse = new Fuse(props.data.toJS(), options)
        const vehicleList = fuse.search(searchQueryCat)
        setData(handleConvertResult(vehicleList))

        if(searchQueryCat !== ''){
            setIsCategorySelected(true);
            setMixMode(true)
        }else{
            setIsCategorySelected(false);
            setMixMode(false)
        }
        categoriesRender()
        return searchQueryCat;
    }

    const handleMouseEnter = () => {
        setIsMouseInCategoryBox(true)
    }
    const handleMouseOut = () => {
        setIsMouseInCategoryBox(false)
    }

    const handleOnClick = () => {
        handleCreateCategorySearchQuery();
        categoriesRender()
    };

    return (

        <CategoryFilterStyle onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut} onBlur={hanldeOnBlurFilterBox}>
            <button className={'buttonCatFilter'} onClick={handleOnClickFilterButton}>
                {isCategorySelected ?
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ color: 'blue'}} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                    </svg>}
                {props.intl.formatMessage({ id: 'components.SideMenu.FleetOverviewFilter' })}
            </button>

            <div className={'checkboxCategory'}  id="filterBox">
                <div  className={'checkboxContainer'} >
                    <button type="button" onClick={handleSelectAll} id="selectAllBtn">
                        {props.intl.formatMessage({
                            id: 'components.SideMenu.FleetOverviewSelectAll',
                        })}
                    </button>
                    <div>
                        {props.categoryList.size > 0 ?
                            props.categoryList.toJS().map((category) => {
                                return (
                                    <div key={category.categorieId}>
                                        <label style={{cursor: 'pointer',display:'flex', whiteSpace:'nowrap'}}>
                                            <input type="checkbox" value={category.categorieName} onChange={handleOnClick}
                                                   name="filterCheckboxes"/>
                                            <span style={{width:'80px',overflow: 'hidden', textOverflow:'ellipsis', paddingRight:'0.5em'}}>
                                                {category.categorieName === 'Others' ? props.intl.formatMessage({id: 'components.SideMenu.FleetOverviewOthers'})
                                                : category.categorieName}
                                            </span>
                                        </label>
                                    </div>
                                )
                            })
                            :
                            null}
                    </div>
                </div>
            </div>
        </CategoryFilterStyle>

    );
};

const mapStateToProps = createStructuredSelector({
    categoryList: selectCategories(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCategoriesByBranch,
        },
        dispatch,
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(CategoryFilter)),
);
