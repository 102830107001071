// @flow

import request from 'services/adminRequest';
import adminRequest from 'services/adminRequest';
import type { Organization, UserDataType, AssociateUserType } from 'types';

export function retrieveBranch(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `branches?branchId=${branchId}`,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function retrieveBranchUsers(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `users?branchId=${branchId}`,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function retrieveBranchDivisions(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveBranchEquipment(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/equipment`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} branchId
 */
export function retrieveConfigVehByBranchId(
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    const url = `branches/${branchId}/vehicles/config`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}
export function createBranchRequest(
    data: Organization,
    organizationId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches`,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function editBranchRequest(
    organizationId: number,
    branchId: number,
    data: Organization,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}`,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function fetchGroups(token: string, branchId: number): Promise<> {
    return request(
        `branches/${branchId}/groups`,
        {
            method: 'GET',
        },
        true,
        token
    );
}
