/* eslint-disable flowtype/require-parameter-type,react/jsx-no-bind */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import useStyles from '../../styles/Group.styles';
import TransitionsModal from '../../utils/TransitionsModal';
import GroupEdition from '../edition/GroupEdition';
import GroupTypeToggle, { groupTypesEnum } from '../GroupTypeToggle';
import VehicleList from './VehicleList';
import UserList from './UserList';
import useAgent from '../../../../hooks/useAgent';
import groupsAgent from '../../agents/groupAgent';
import { FormattedMessage } from 'react-intl';
import { ToggleButton } from '../../utils/CustomButton';
import { useVehicles } from '../../hooks/useVehicles';
import { Loading } from '../../utils/Loading';

const Group = ({ groupId }) => {
    const classes = useStyles();

    const [groupType, setGroupType] = useState(groupTypesEnum.vehicle);
    const [existingIds, setExistingIds] = useState({ vehicleExistingIds: [], userExistingIds: [] });
    const [fetchGroup, group, isFetchingGroup] = useAgent(groupsAgent.fetchGroup);
    const [updateGroup, updatedGroup, isUpdatingGroups] = useAgent(groupsAgent.updateGroup);
    const [deleteItems, deletedItems, isDeletingItems] = useAgent(groupsAgent.deleteItemsInGroup);
    const { fetchVehiclesByGroupId, vehicles, isFetchingVehicles } = useVehicles();
    const [fetchUsersByGroupId, users, isFetchingUsers] = useAgent(groupsAgent.fetchUsersByGroupId);

    // Filter vichles ASC
    const sortedVichles = useMemo(
        () =>
            (vehicles || []).sort((a, b) => {
                if (!a || !a.name) {
                    return 1;
                }
                if (!b || !b.name) {
                    return -1;
                } else {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                }
            }),
        [vehicles]
    );

    useEffect(
        () => {
            if (groupId && !isFetchingGroup && (!group || group.groupId !== groupId)) {
                fetchGroup(groupId);
            }
        },
        [fetchGroup, groupId, isFetchingGroup, group]
    );

    useEffect(
        () => {
            if (!isFetchingVehicles && groupId && (!group || group.groupId !== groupId)) {
                fetchVehiclesByGroupId(groupId);
            }
        },
        [fetchVehiclesByGroupId, groupId, isFetchingVehicles]
    );

    useEffect(
        () => {
            if (!isFetchingUsers && groupId && (!group || group.groupId !== groupId)) {
                fetchUsersByGroupId(groupId);
            }
        },
        [fetchUsersByGroupId, groupId, isFetchingUsers]
    );

    useEffect(
        () => {
            if (vehicles && vehicles.length) {
                setExistingIds({
                    ...existingIds,
                    vehicleExistingIds: vehicles.map((vehicle) => vehicle.id),
                });
            }
        },
        [vehicles]
    );

    useEffect(
        () => {
            if (users && users.length) {
                setExistingIds({ ...existingIds, userExistingIds: users.map(({ id }) => id) });
            }
        },
        [users]
    );

    useEffect(
        () => {
            if (deletedItems || updatedGroup) {
                fetchVehiclesByGroupId(groupId);
                fetchUsersByGroupId(groupId);
            }
        },
        [deletedItems, updatedGroup]
    );

    const handleDeleteItems = useCallback((id, type) => {
        if (!isDeletingItems) {
            deleteItems(groupId, type, [id]);
        }
    });

    const handleGroupTypeChange = useCallback((type) => {
        if (type) {
            setGroupType(type);
        }
    }, []);

    const handleGroupSubmit = useCallback(
        (values) => {
            if (values && !isUpdatingGroups) {
                updateGroup(values);
            }
        },
        [updateGroup, isUpdatingGroups]
    );

    const toggleButtonCreate = useCallback(
        ({ openModal }) => (
            <ToggleButton onClick={openModal}>
                <FormattedMessage id={'views.Groups.AddToGroup'} />
            </ToggleButton>
        ),
        []
    );

    if (!group || !groupId) {
        return (
            <div className={classes.addItems}>
                <div className={classes.message}>
                    <FormattedMessage id={'views.Groups.SelectAGroup'} />
                </div>
            </div>
        );
    }

    const ItemsList = () => (
        <React.Fragment>
            {groupType === groupTypesEnum.vehicle && (
                <VehicleList
                    vehicles={sortedVichles}
                    isLoading={isFetchingUsers || !vehicles}
                    onDeletedVehicle={(id) => handleDeleteItems(id, groupTypesEnum.vehicle)}
                    isDefaultGroup={group.isDefault}
                />
            )}
            {groupType === groupTypesEnum.user && (
                <UserList
                    users={users}
                    isLoading={isFetchingUsers || !users}
                    onDeletedUser={(id) => handleDeleteItems(id, groupTypesEnum.user)}
                />
            )}
        </React.Fragment>
    );
    return (
        <div className={classes.addItems}>
            <h3 className={classes.title}>{group.name}</h3>
            <GroupTypeToggle value={groupType} onChange={handleGroupTypeChange} />
            {isFetchingGroup ? <Loading /> : <ItemsList />}

            <div className={classes.groupsControl}>
                <TransitionsModal button={toggleButtonCreate}>
                    {({ closeModal }) => (
                        <GroupEdition
                            selectedGroupType={groupType}
                            existingIds={existingIds}
                            groupId={group.groupId}
                            onSubmit={handleGroupSubmit}
                            closeModal={closeModal}
                            title="views.Groups.AddToGroup"
                            textButton="views.Groups.AddAll"
                            width={'50%'}
                        />
                    )}
                </TransitionsModal>
            </div>
        </div>
    );
};

export default React.memo(Group);
