// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Organization = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${(props) => props.color};
`;

Organization.Griditem = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.rowSpan}, 1fr);
    height: 100%;
    grid-column-gap: 15px;
    grid-template-rows: ;
`;

Organization.Item = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.empty = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.span = styled.span`
    display: inline;
    padding: 0.3em 0.6em 0.3em;
    font-size: 90%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: ${colors.green61};
    max-height: 25px;
`;

Organization.gasoline = styled.span`
    display: inline;
    padding: 0.3em 0.6em 0.3em;
    font-size: 90%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: ${colors.green61};
    max-height: 25px;
`;

Organization.diesel = styled.span`
    display: inline;
    padding: 0.3em 0.6em 0.3em;
    font-size: 90%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: ${colors.yellow};
    max-height: 25px;
`;

Organization.Image = styled.img`
    margin-left: 10px;
    margin-right: 15px;
    width: ${(props) => props.width || '41px'};
    height: ${(props) => props.width || '41px'};
`;

Organization.Toggle = styled.div`
    transition: height 0.2s ease-in-out;
    height: ${({ open, height }) => (open ? '100%' : 0)}px;
    overflow: hidden;
`;

export default Organization;
