// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const CategoryFilterStyle = styled.div`



`;


export { CategoryFilterStyle };
