// @flow

import { injectGlobal } from 'styled-components';

// Font files

// OpenSans
import OpenSansBold from 'assets/fonts/OpenSans-Bold.ttf';
import OpenSansSemiBold from 'assets/fonts/OpenSans-Semibold.ttf';
import OpenSans from 'assets/fonts/OpenSans-Regular.ttf';

// Gotham
import GothamMedium from 'assets/fonts/Gotham-medium.ttf';

// Bold
/* eslint-disable flowtype/no-unused-expressions, no-unused-expressions */
injectGlobal`
    @font-face {
        font-family: OpenSans;
        font-stretch: normal;
        font-weight: bold;
        src: url('${OpenSansBold}') format('truetype');
    }
`;
// Semi Bold
injectGlobal`
    @font-face {
        font-family: OpenSans;
        font-stretch: normal;
        font-weight: 600;
        src: url('${OpenSansSemiBold}') format('truetype');
    }
`;

injectGlobal`
    @font-face {
        font-family: OpenSans;
        font-stretch: normal;
        font-weight: 400;
        src: url('${OpenSansSemiBold}') format('truetype');
    }
`;

// Regular
injectGlobal`
    @font-face {
        font-family: OpenSans;
        font-stretch: normal;
        font-weight: normal;
        src: url('${OpenSans}') format('truetype');
    }
`;

// Gotham
injectGlobal`
    @font-face {
        font-family: Gotham-Medium;
        src: url('${GothamMedium}') format('truetype');
    }
`;

injectGlobal`
    @font-face {
        font-family: OpenSans-SemiBold;
        font-stretch: normal;
        font-weight: normal;
        src: url('${OpenSansSemiBold}') format('truetype');
    }
`;
