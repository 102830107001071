export const maintenanceInputs = [
    {
        type: 'seizeDate',
        InputType: 'Date',

    },
    {
        type: 'enterDate',
        InputType: 'Date',

    },
    {
        type: 'exitDate',
        InputType: 'Date',

    },
    {
        type: 'department',
        InputType: 'text',

    },
    {
        type: 'sector',
        InputType: 'text',

    },
    {
        type: 'garage',
        InputType: 'text',
    },
    {
        type: 'odometer',
        InputType: 'number',

    },
    {
        type: 'horometer',
        InputType: 'number',

    },
    {
        type: 'typeOfVehicle',
        InputType: 'Select',
        values: ['', 'Light', 'Rail-route'],
    },
    {
        type: 'cost',
        InputType: 'number',

    },
    {
        type: 'receiptNumber',
        InputType: 'text',

    },
    {
        type: 'typeOfRepair',
        InputType: 'Select',
        values: ['', 'Prevent', 'Repare', 'Tires', 'SystemeRailRoute', 'SystemHydroAux', 'Motor', 'Transmission', 'Abus', 'Towing'],
    },
    {
        type: 'comment',
        InputType: 'textArea',

    },

];
