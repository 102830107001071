import React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'querystring';
import { connect } from 'react-redux';
import { receivedLogoutSuccess } from '../../services/Authentication/actions';

class LogoutHandler extends React.Component {
    componentDidMount() {
        const search = qs.parse(this.props.location.search.substr(1));
        if (search.logout) {
            this.props.finishLogout();
        }
    }

    render() {
        const storedLocale = localStorage.getItem('locale') || 'fr';
        const storedIsAdmin = sessionStorage.getItem('isAdmin') || false;
        sessionStorage.removeItem('isAdmin');
        if(storedIsAdmin) {
            return <Redirect to={`${storedLocale}/admin/login`} />;
        }
        return <Redirect to={`${storedLocale}/login`} />;
    }
}
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    finishLogout: () => dispatch(receivedLogoutSuccess()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogoutHandler);
