// @flow

import React from 'react';

import Error from 'components/Error';

import { DeletedOrganization } from 'styles/organization';

type Props = {
    text: string,
};

function DeletedMessage(props: Props) {
    return (
        <DeletedOrganization>
            <Error text={props.text} />
        </DeletedOrganization>
    );
}

export default DeletedMessage;
