// @flow

import React from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import DataListHeader from './styles';

import colors from 'styles/colors';

import OutlineButton from 'components/OutlineButton';
import SelectField from 'components/SelectField';

import type { ReduxDispatch, ImmutableList, ImmutableMap } from 'types';
import type { ControllerActionList } from './types';

type Props = {
    title: ?string,
    name: ?string,
    // controllerActionOptions: ?ControllerActionList,
    controllerButtonText: ?string,
    actionButtonText: ?string,
    onClickActionButton: ?() => void,
};

type State = {};

class DataListHeaderContainer extends React.PureComponent<Props, State> {
    static defaultProps = {};

    state = {};

    render() {
        return (
            <DataListHeader>
                {this.props.title}
                <DataListHeader.ControllerContainer>
                    <DataListHeader.ControllerHeader>
                        {this.props.name}
                    </DataListHeader.ControllerHeader>
                    <DataListHeader.ActionContainer>
                        {this.props.actionButtonText && (
                            <DataListHeader.ActionButtonContainer
                                onClick={this.props.onClickActionButton}
                            >
                                <OutlineButton
                                    outlineColor={colors.green73}
                                    padding={'0 15px 0 15px'}
                                >
                                    <FormattedMessage id={this.props.actionButtonText} />
                                </OutlineButton>
                            </DataListHeader.ActionButtonContainer>
                        )}
                        {this.props.controllerButtonText && (
                            <DataListHeader.ActionButtonContainer>
                                <DataListHeader.ActionSelect>
                                    <SelectField
                                        placeholder={this.props.controllerButtonText}
                                        padding={'2px 0 0 0'}
                                        center
                                    />
                                </DataListHeader.ActionSelect>
                            </DataListHeader.ActionButtonContainer>
                        )}
                        {/* <DataListHeader.ExtraButton>
                            <OutlineButton outlineColor={colors.green73} width={'40px'}>
                                {' ...'}
                            </OutlineButton>
                        </DataListHeader.ExtraButton> */}
                    </DataListHeader.ActionContainer>
                </DataListHeader.ControllerContainer>
            </DataListHeader>
        );
    }
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataListHeaderContainer);
