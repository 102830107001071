/* eslint-disable prefer-arrow-callback */
// @flow

// IMPORTS
import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import { toGeoJSON as polylineToGeoJSON } from '@mapbox/polyline';

// ACTIONS
import {
    GET_MAINTENANCE_VEHICLE_HISTORY_SUCCESS,
    GET_MAINTENANCE_SUCCESS,
    GET_MAINTENANCE_ERROR,
    GET_SELECTED_VEHICLE_SUCCESS,
    GET_CONFIG_MAINTENANCE_SUCCESS,
    GET_SELECTED_VEHICLE_ALARMS,
    GET_MAINTENANCE_VEHICLE_BY_MAINT_ID,
    GET_REINITIALIZE_ALARMS,
    GET_MAINTENANCE_EMAILS,
    GET_MAINTENANCE_MESSAGE,
} from './action';

// TYPES
import type { GenericActionType, VehicleListType } from 'types';

// DEFAULT STATE
const initialState = fromJS({
    selectedVehicleAlarms: [],
    selectedAlarms: [],
    listOfAlarms: [],
    configInformation: [],
    configurationAlarms: [],
    configurationEmails: [],
    selectedVehicle: {},
    reinitializeAlarms: [],
    maintenanceError: false,
    selectedVehicleMaintMsg: '',
});

// MAIN REDUCER
/**
 *
 * @param {*} state
 * @param {*} action
 */
const maintenanceServiceReducer = (state: Object = initialState, action: GenericActionType) => {
    switch (action.type) {
        case GET_MAINTENANCE_SUCCESS: {
            return state.set('listOfAlarms', action.alarms);
        }
        case GET_MAINTENANCE_VEHICLE_HISTORY_SUCCESS: {
            return state.set('selectedAlarms', action.vehAlarms);
        }
        case GET_SELECTED_VEHICLE_SUCCESS: {
            return state.set('selectedVehicle', action.vehicle);
        }
        case GET_CONFIG_MAINTENANCE_SUCCESS: {
            return state.set('configInformation', action.configMaintenance);
        }
        case GET_MAINTENANCE_VEHICLE_BY_MAINT_ID: {
            return state.set('configurationAlarms', action.alarms);
        }
        case GET_SELECTED_VEHICLE_ALARMS: {
            return state.set('selectedVehicleAlarms', action.vehicleAlarm);
        }
        case GET_REINITIALIZE_ALARMS: {
            return state.set('reinitializeAlarms', action.alarms);
        }
        case GET_MAINTENANCE_EMAILS: {
            return state.set('configurationEmails', action.emails);
        }
        case GET_MAINTENANCE_ERROR: {
            return state.set('maintenanceError', true);
        }
        case GET_MAINTENANCE_MESSAGE: {
            return state.set('selectedVehicleMaintMsg', action.msg.message_maint);
        }
        default:
            return state;
    }
};

// EXPORTS
export default maintenanceServiceReducer;
