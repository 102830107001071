// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const VehicleList = styled.div`
    overflow-y: auto;
    height: 235px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
`;
export const VehicleList1 = styled.div`
    overflow-y: auto;
    height: 450px;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
`;
export const VehicleItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
`;

VehicleItem.Name = styled.div`
    margin-left: 18px;
`;

export const SelectAll = styled.div`
    background-color: ${colors.green63};
    width: auto;
    height: 40px;
    margin-top: -50px;
    margin-bottom: 15px;
    margin-left: auto;
    font-family: OpenSans;
    cursor: pointer;
    color: ${colors.white};
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
`;

export const UnselectAll = styled.div`
    background-color: ${colors.green63};
    width: auto;
    height: 40px;
    margin-top: -55px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: 170px;
    font-family: OpenSans;
    cursor: pointer;
    color: ${colors.white};
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
`;
