// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import CloseButton from 'components/CloseButton';
import { GiFamilyTree } from '@react-icons/all-files/gi/GiFamilyTree';
import { selectOrganization } from 'services/Organization/selectors';

import { selectCurrentBranch } from 'services/Branch/selectors';

import { selectCurrentDivision } from 'services/Division/selectors';

import { toggleSideMenu, showSideMenu, hideSideMenu } from 'services/SideMenu/thunks';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';

import { FormattedMessage, injectIntl } from 'react-intl';
import { AppWrapper } from 'styles/common';
import { SideMenu, Header, Footer, Image, SideMenuOrganization } from 'styles/sidemenu';

import { ArrowLeft } from 'components/Icons';

import type { ReduxDispatch } from 'types';

// import FavouritesImage from 'assets/favourites-white.svg';
// import AdminImage from 'assets/gear-white.svg';
import DummyLogo from 'assets/dummy-logo.png';
import UsersIcon from 'assets/user-black.svg';
// import CloseArrow from 'assets/close-arrow.svg';
import EquipmentImage from 'assets/equipment-sidemenu-black.svg';

import colors from 'styles/colors';

type Props = {
    organization: OrganizationListType,
    division: OrganizationListType,
    branch: OrganizationListType,
};

class SideMenuOrganizationComponent extends React.PureComponent<Props> {
    componentDidMount() {
        this.props.showSideMenu();
    }

    handleToggleSideMenu = () => {
        this.props.toggleSideMenu();
    };

    handleOnClickCloseButton = () => this.props.toggleSideMenu();

    getBackText = () => {
        const organizationId = this.props.match.params.organizationId;
        const userRoute = this.props.location.pathname.includes('/user');
        const equipmentRoute = this.props.location.pathname.includes('/equipment');
        const branchRoute = this.props.location.pathname.includes('/branches');
        const parentId = this.props.organization.get('parentId');
        if (organizationId) {
            if (userRoute || equipmentRoute || branchRoute) {
                if (parentId) {
                    return this.props.intl.formatMessage({
                        id: 'components.SideMenuOrganization.BackToBranch',
                    });
                }
                return this.props.intl.formatMessage({
                    id: 'components.SideMenuOrganization.BackToOrganization',
                });
            }
            if (parentId) {
                return this.props.intl.formatMessage({
                    id: 'components.SideMenuOrganization.BackToParent',
                });
            } else {
                return this.props.intl.formatMessage({
                    id: 'components.SideMenuOrganization.BackToOrganizations',
                });
            }
        }
    };

    getDataFromRoute = () => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;
        const userRoute = this.props.location.pathname.includes('/user');
        const equipmentRoute = this.props.location.pathname.includes('/equipment');

        if (organizationId) {
            if (branchId && divisionId) {
                return this.props.division;
            }

            if (branchId) {
                return this.props.branch;
            }
            return this.props.organization;
        }
    };

    renderBack = () => {
        const returnText = this.getBackText();

        let returnUrl = `/${this.props.match.params.locale}/interne/orgs`;

        const organizationId = this.props.match.params.organizationId;
        const userRoute = this.props.location.pathname.includes('/user');
        const equipmentRoute = this.props.location.pathname.includes('/equipment');
        const branchRoute = this.props.location.pathname.includes('/branches');
        const parentId = this.props.organization.get('parentId');
        if (organizationId) {
            if (userRoute || equipmentRoute || branchRoute) {
                returnUrl += `/${organizationId}`;
            } else {
                if (parentId) {
                    returnUrl += `/${parentId}`;
                } else {
                    returnUrl += `/`;
                }
            }
        }

        return (
            <SideMenu.Item>
                <SideMenuOrganization.BackLink>
                    <div>
                        <SideMenu.NavItem to={returnUrl} color={colors.black}>
                            <ArrowLeft />
                            {returnText}
                        </SideMenu.NavItem>
                    </div>
                </SideMenuOrganization.BackLink>
            </SideMenu.Item>
        );
    };

    /**
     * Deepending on the page we're on, we should navigate to the appropriate users list page.
     */
    renderUserLink = () => {
        let url;

        if (this.props.match.params.organizationId) {
            url = `/${this.props.match.params.locale}/interne/orgs/${
                this.props.match.params.organizationId
            }`;
            if (this.props.match.params.branchId) {
                url = `${url}/branch/${this.props.match.params.branchId}`;
                if (this.props.match.params.divisionId) {
                    url = `${url}/division/${this.props.match.params.divisionId}`;
                }
            }
            url = `${url}/users`;
        }

        if (url) {
            return (
                <div>
                    <SideMenu.NavItem to={url} color={colors.black}>
                        <FormattedMessage id="components.SideMenuOrganization.Users" />
                    </SideMenu.NavItem>
                </div>
            );
        }
    };

    renderEquipmentLink = () => {
        let url;
        if (this.props.match.params.organizationId) {
            url = `/${this.props.match.params.locale}/interne/orgs/${
                this.props.match.params.organizationId
            }`;
            if (this.props.match.params.branchId) {
                url = `${url}/branch/${this.props.match.params.branchId}`;
                if (this.props.match.params.divisionId) {
                    url = `${url}/division/${this.props.match.params.divisionId}`;
                }
            }
        }

        if (url) {
            return (
                <SideMenu.NavItem to={`${url}/equipment`} color={colors.black}>
                    <FormattedMessage id="components.SideMenuOrganization.Equipment" />
                </SideMenu.NavItem>
            );
        }

        return <FormattedMessage id="components.SideMenuOrganization.Equipment" />;
    };

    render() {
        const data = this.getDataFromRoute();

        if (!data) {
            return false;
        }

        return (
            <SideMenu isOpen={this.props.isOpen}>
                <SideMenuOrganization.Container>
                    {this.renderBack()}
                    <SideMenuOrganization>
                        <SideMenuOrganization.Logo>
                            <Image.Container>
                                <Image src={DummyLogo} height={'50px'} width={'50px'} />
                            </Image.Container>
                            <SideMenu.ItemText>{data.get('name')}</SideMenu.ItemText>
                        </SideMenuOrganization.Logo>
                    </SideMenuOrganization>
                    <SideMenu.Split color={colors.splitGrayReports} opacity={0.2} />
                    <SideMenuOrganization.LinksContainer>
                        <SideMenu.Item>
                            <Image.Container>
                                <Image src={UsersIcon} width={24} height={33} />
                            </Image.Container>
                            <SideMenu.ItemText>{this.renderUserLink()}</SideMenu.ItemText>
                        </SideMenu.Item>
                        <SideMenu.Split />
                        <SideMenu.Item>
                            <Image.Container>
                                <Image src={EquipmentImage} width={31} height={30} />
                            </Image.Container>
                            <SideMenu.ItemText>{this.renderEquipmentLink()}</SideMenu.ItemText>
                        </SideMenu.Item>
                        <SideMenu.Split />
                        <SideMenu.Item>
                            <Image.Container>
                                {/* TODO => Needs icon */}
                                <GiFamilyTree style={{ fontSize: 32 }} />
                            </Image.Container>
                            <SideMenu.ItemText>
                                <SideMenu.NavItem
                                    to={`/${
                                        this.props.match.params.locale
                                    }/interne/orgs/${
                                        this.props.match.params.organizationId
                                    }/branches`}
                                    color={colors.black}
                                >
                                    Branches
                                </SideMenu.NavItem>
                            </SideMenu.ItemText>
                        </SideMenu.Item>
                        <SideMenu.Split />
                        {/* <SideMenu.Item>
                            <Image.Container>
                                <Image src={AdminImage} width={31} height={30} />
                            </Image.Container>
                            <SideMenu.ItemText>
                                <FormattedMessage id="components.SideMenuOrganization.Permissions" />
                            </SideMenu.ItemText>
                        </SideMenu.Item>
                        <SideMenu.Split /> */}
                    </SideMenuOrganization.LinksContainer>
                    <CloseButton
                        isOpen={this.props.isOpen}
                        onClick={this.handleToggleSideMenu}
                        top={24}
                        size={30}
                        zIndex={5}
                        backgroundColor={colors.sideMenuGreen}
                    />
                </SideMenuOrganization.Container>
                {/* <CloseButton
                    isOpen={this.props.isOpen}
                    onClick={this.handleOnClickCloseButton}
                    offset={this.props.offset}
                    zIndex={1}
                /> */}
                {/* <Footer>
                    <Image.Container>
                        <Image src={FavouritesImage} width={25} height={22} />
                    </Image.Container>
                    <span>
                        <FormattedMessage id="components.SideMenu.Favourites" />
                    </span>
                </Footer> */}
            </SideMenu>
        );
    }
}

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            toggleSideMenu,
            hideSideMenu,
            showSideMenu,
        },
        dispatch
    );

const mapStateToProps = createStructuredSelector({
    organization: selectOrganization(),
    branch: selectCurrentBranch(),
    division: selectCurrentDivision(),
    isOpen: selectIsSideMenuOpen(),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(SideMenuOrganizationComponent))
);
