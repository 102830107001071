// @flow

// IMPORTS
import dateFns from 'date-fns';

// THUNKS

import type { ReduxDispatch, ResponseErrorType } from 'types';

// ACTIONS
import {
    CONNECT,
    CONNECT_FAIL,
    CONNECT_SUCCESS,
    receivedDataSuccess,
    receivedDataFail,
    RECEIVED_DATA_SUCCESS,
    RECEIVED_DATA_FAIL,
    DISCONNECT,
    socketBoundUpdate,
    receivedDisconnect,
} from './actions';

// SERVICES
import { realtimeDataReceived } from 'services/Vehicle/actions';

export const socketConnect = (token) => {
    console.log('socketConnect');
    /* return {
        type: 'socket',
        types: [CONNECT, CONNECT_SUCCESS, CONNECT_FAIL],
        promise: (socket) => socket.connect(),
    };*/
    return {
        type: 'socket',
        types: [CONNECT, CONNECT_SUCCESS, CONNECT_FAIL],
        promise: (socket) => socket.connect(token),
    };
};

export const socketListen = () => {
    console.log('socketListen');
    /* return {
        type: 'socket',
        types: [CONNECT, CONNECT_SUCCESS, CONNECT_FAIL],
        promise: (socket) => socket.connect(),
    };*/
    return {
        type: 'socket',
        types: [CONNECT, CONNECT_SUCCESS, CONNECT_FAIL],
        promise: (socket) => socket.on('data', listener),
    };
};

export const socketListener = (socket) => (dispatch: ReduxDispatch) => {
    socket.on('data', (msg) => {
        //console.log(`MSG: ${  msg}`);
        dispatch(realtimeDataReceived(msg));
    });
    socket.on('disconnect', (msg) => {
        console.log(`websocketServer disconnect... ${msg}`);
        dispatch(receivedDisconnect(msg));
    });
};
export const listener = (...args) => {
    // console.log(args);
};

export const socketReconnect = (token) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(socketConnect(token));
};

export const updateSocket = (bound) => (dispatch: ReduxDispatch) => {
    dispatch(socketBoundUpdate(bound));
};
