// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getVehicles } from '../../../services/Vehicle/thunks';
import { editGarageMode } from '../../../services/Config/thunks';
import { createStructuredSelector } from 'reselect';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { selectControlColors, selectFilteredVehicles } from '../../../services/Vehicle/selectors';
import AlarmVehicleList from '../AlarmVehicleList';
import { Input, SearchInput } from '../../../components/SearchInput/styles';
import { Button, Dropdown, Menu } from 'antd';
import Icon from '@material-ui/core/Icon';
import EdiTable from '../EditTable';
import {
    getAllMaintenances,
    getMaintenance,
    getMaintenanceVeh,
    SetSelectedVehicle,
} from '../../../services/Maintenance/thunks';
import { selectMaintenanceAlarmState, selectMaintenanceVehHistoryState } from '../../../services/Maintenance/selectors';
import { selectCurrentBranch } from '../../../services/Branch/selectors';
import Logo from '../../../assets/orca-logo-black-min.png';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import * as Excel from "exceljs";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import VehicleDetailManagement from './styles';
import { handleHotometerFormat, metersToKilometers, sortByAlert } from '../helpers';
import colors from '../../../styles/colors';
import { Item } from '../../../components/VehicleListItem/styles';
import AlarmManagementContainer from '../AlarmManagement';
import { changeUnit, i18nDistanceSymbol, setTextunit } from '../../../services/Units/thunks';
import { selectUnit } from '../../../services/Units/selectors';
import Loader from '../../../components/Loader';
import { parseJWT } from '../../../helpers';
import { selectUser } from '../../../services/Authentication/selectors';
import { isEqual } from 'lodash';

const VehicleDetail = (props) => {
        const [vehicleList, setVehicleList] = useState([]);
        const [garageMode, setGarageMode] = useState([]);
        const [isAlarmClicked, setIsAlarmClicked] = useState(false);
        const [searchInput, setSearchInput] = useState('');
        const [alarmList, setAlarmList] = useState([]);
        const [isAlarmManageClicked, setIsAlarmManageClicked] = useState(false);
        const [isVehicleLoaded, setIsVehicleLoaded] = useState(true);
        const [vehiculeListtoProps, setVehiculeListtoProps] = useState([]);
        const jwt = parseJWT(props.user.get('token'));
        useEffect(() => {
            setTimeout(() => {
                setIsVehicleLoaded(false);
            }, 2000);
        });
        useEffect(
            () => {
                if(props.vehicles.size > 0){
                    let orcaVehicle = props.vehicles.filter(items => items.toJS().source !== 'bewhere');
                    //convertir la liste de Map en tableau d'object simple
                    orcaVehicle = Array.from(orcaVehicle, val => Object.fromEntries(val));

                    orcaVehicle.map((item) => {
                        if (item.configuration) {
                            item.configuration = {
                                ['garageModeAct']: item.configuration.toJS().garageModeAct,
                                'garageModeChanged': item.configuration.toJS().garageModeChanged,
                            };

                        }

                    });

                    if (orcaVehicle[0] !== undefined) { //attendre que les donnees se laod
                        props.getMaintenance(orcaVehicle[0].branchId);
                        props.getAllMaintenances(orcaVehicle[0].branchId)
                            .then((response) => {
                                orcaVehicle.forEach((item, index) => {
                                    orcaVehicle[index].color = getVehicleAlert(response, item.id);
                                    orcaVehicle[index].hasAlarm = checkMaintenancePresence(response, item.id);
                                });
                                orcaVehicle = sortByAlert(orcaVehicle);
                                setVehiculeListtoProps(orcaVehicle);
                                let searchResults = orcaVehicle.filter(item =>
                                    item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchInput.toLowerCase()),
                                );
                                searchResults = sortByAlert(searchResults);
                                if (searchResults.length !== orcaVehicle.length) {
                                    setVehicleList(searchResults);
                                } else {
                                    setVehicleList(orcaVehicle);
                                }

                                setAlarmList(response);
                            });
                    }
                }
            },
            [setVehicleList, props.vehicles, setGarageMode, searchInput],
        );
    const updateVehicles = (newdatas) => {
            setVehicleList(newdatas);
        };

        const checkMaintenancePresence = (maintenances, vehId) => {
            let hasAlarm = false;

            const vehMaintenances = maintenances.filter((item) => item.vehId === vehId);

            vehMaintenances.forEach((item) => {
                if (item.alertRaised !== null) {
                    hasAlarm = true;
                } else if (item.reminderRaised !== null) {
                    hasAlarm = true;
                }else{
                    hasAlarm = true
                }
            });
            if (hasAlarm) {
                return true;
            } else {
                return false
            }
        }

        const getVehicleAlert = (maintenances, vehId) => {

            let vehAlertRaised = false;
            let vehReminderRaised = false;

            const vehMaintenances = maintenances.filter((item) => item.vehId === vehId);

            vehMaintenances.forEach((item) => {
                if (item.alertRaised !== null) {
                    vehAlertRaised = true;
                } else if (item.reminderRaised !== null) {
                    vehReminderRaised = true;
                }else{
                    item.hasAlarm = true
                }
            });
            if (vehAlertRaised) {
                return colors.redA;
            } else if (vehReminderRaised) {
                return colors.yellowA;
            } else {
                return colors.white;
            }

        };


        const handleGarageMode = (event, value, index) => {
            props.editGarageMode(event.target.id, { garageModeAct: event.target.checked })
                .then((response) => {
                    vehicleList.map((item) => {
                        if (item.id === event.target.id) {
                            item.configuration.garageModeAct = event.target.checked;
                            item.configuration.garageModeChanged = true;
                            setVehicleList([...vehicleList]);
                        }
                    });

                });
        };

        const handleSeeAlarms = (event, value, index) => {
            event.preventDefault();
            props.getMaintenanceVeh(value.id);
            props.SetSelectedVehicle(value);
            setIsAlarmClicked(true);
        };

        const handleOnSearch = (event) => {
            setSearchInput(event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        };

    const handleConvertToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const expiredVehicles = vehicleList.filter(vehicle => vehicle.color === colors.redA);
        const reminderRaised = vehicleList.filter(vehicle => vehicle.color === colors.yellowA);

        const createSheetData = (vehicles, alarmsKey) => {
            const data = [];
            vehicles.forEach(vehicle => {
                const result = alarmList.filter(alarm => alarm.vehId === vehicle.id && alarm[alarmsKey] !== null);
                result.forEach(item => {
                    const row = {
                        [props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeName' })]: vehicle.name,
                        [props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfNameOfAlarm' })]: item.name,
                        [props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfKmCumulated' }) : props.intl.formatMessage({ id: 'containers.AlarmVehicleList.VehicleDetails.MilesCum' })]: `${props.setTextunit((item.cumulDistance / 1000), 'odometer')}`,
                        [props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfHrsCumulated' })]: (Math.floor((item.cumulHrs / 60 / 60) * 100) / 100).toFixed(2),
                        [props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfLastChanges' })]: new moment(item.lastAlert).format('YYYY-MM-DD HH:mm:ss'),
                    };
                    data.push(row);
                });
            });
            return data;
        };

        const createSheet = (data, sheetName) => {
            const sheet = workbook.addWorksheet(sheetName);
            const columns = Object.keys(data[0]).map(key => ({ header: key, key: key, width: 20 }));

            sheet.columns = columns;
            data.forEach(row => {
                sheet.addRow(row);
            });

            // Apply bold style to headers
            sheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true };
            });
        };

        const expiredData = createSheetData(expiredVehicles, 'alertRaised');
        const reminderData = createSheetData(reminderRaised, 'reminderRaised');

        createSheet(expiredData, props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfName' }));
        createSheet(reminderData, props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.UpcomingMaintenance' }));

        const buffer = await workbook.xlsx.writeBuffer();
        const fileName = `${props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfName' })}.xlsx`;
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(blob, fileName);
    };

    const handleConvertToPDF = () => {
        const doc = new jsPDF({ putOnlyUsedFonts: true });

        const title = props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.MaintenancePlanning' }).toString();
        const generateAt = props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfGenerated' }).toString();

        // doc header
        doc.setFont(undefined, 'bold');
        doc.text(props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfOverdueMaintenance' }), 70, 27);
        doc.setFontSize(8);
        doc.text(generateAt + new moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 150, 10);
        doc.setFontSize(12);
        doc.addImage(Logo, 'png', 12, 8);

        // tables columns
        const columns = [
            { header: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfNameOfAlarm' }), dataKey: 'name' },
            { header: props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfKmCumulated' }) : props.intl.formatMessage({ id: 'containers.AlarmVehicleList.VehicleDetails.MilesCum' }), dataKey: 'cumulDistance' },
            { header: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfHrsCumulated' }), dataKey: 'cumulHrs' },
            { header: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfLastChanges' }), dataKey: 'lastAlert' },
        ];

        // handle expired vehicles
        const expiredVehicles = vehicleList.filter(vehicle => vehicle.color === colors.redA);
        const reminderRaised = vehicleList.filter(vehicle => vehicle.color === colors.yellowA);

        let startY = 45;

        expiredVehicles.forEach(expiredVehicle => {
            const result = alarmList.filter(alarm => alarm.vehId === expiredVehicle.id && alarm.alertRaised !== null);
            const data = result.map(item => ({
                name: item.name,
                cumulDistance: `${props.setTextunit((item.cumulDistance / 1000), 'odometer')}`,
                cumulHrs: (Math.floor((item.cumulHrs / 60 / 60) * 100) / 100).toFixed(2),
                lastAlert: new moment(item.lastAlert).format('YYYY-MM-DD HH:mm:ss'),
            }));

            if (startY + data.length * 10 > doc.internal.pageSize.height - 20) {
                doc.addPage();
                startY = 25;
            }

            doc.text(expiredVehicle.name, 14, startY);
            doc.autoTable({
                theme: 'grid',
                startY: startY + 2,
                margin: { top: 20 },
                headStyles: { textColor: 'white', fillColor: '#63AC37' },
                bodyStyles: { textColor: 'black', fillColor: 'white' },
                body: data,
                columns,
            });

            startY = doc.autoTable.previous.finalY + 10;
        });

        if (startY + 20 > doc.internal.pageSize.height - 20) {
            doc.addPage();
            startY = 25;
        }

        // Ajout du titre pour les véhicules avec rappels
        doc.setFont(undefined, 'bold');
        doc.setFontSize(12);
        doc.text(props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.UpcomingMaintenanceReminders' }), 70, startY);

        startY += 10;

        reminderRaised.forEach(reminderVehicle => {
            const result = alarmList.filter(alarm => alarm.vehId === reminderVehicle.id && alarm.reminderRaised !== null);
            const data = result.map(item => ({
                name: item.name,
                cumulDistance: `${props.setTextunit((item.cumulDistance / 1000), 'odometer')}`,
                cumulHrs: (Math.floor((item.cumulHrs / 60 / 60) * 100) / 100).toFixed(2),
                lastAlert: new moment(item.lastAlert).format('YYYY-MM-DD HH:mm:ss'),
            }));

            if (startY + data.length * 10 > doc.internal.pageSize.height - 20) {
                doc.addPage();
                startY = 25;
            }

            doc.text(reminderVehicle.name, 14, startY);
            doc.autoTable({
                theme: 'grid',
                startY: startY + 2,
                margin: { top: 20 },
                headStyles: { textColor: 'white', fillColor: '#63AC37' },
                bodyStyles: { textColor: 'black', fillColor: 'white' },
                body: data,
                columns,
            });

            startY = doc.autoTable.previous.finalY + 10;
        });

        // will save the file in the current working directory
        doc.save(`${props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.PdfName' })}.pdf`);
    };





    const handleManageAlarms = (isCancel) => {
            setIsAlarmManageClicked(isCancel);
        };

        const getModuleType = (vehId) => {

            let additionalData = null;
            vehicleList.forEach((vehicle) => {
                if (vehicle.id === vehId) {
                    additionalData = vehicle.additionalData ? vehicle.additionalData.toJS() : null;
                }
            });

            let moduleType = '';
            let cardNumber = null;
            vehicleList.forEach((vehicle) => {
                if (vehicle.id === vehId) {
                    cardNumber = vehicle.cardNumber ? vehicle.cardNumber : null;
                }
            });

            if (cardNumber) {
                if (
                    cardNumber.startsWith('R2') ||
                    cardNumber.startsWith('R3') ||
                    cardNumber.startsWith('R4') ||
                    cardNumber.startsWith('G3') ||
                    cardNumber.startsWith('OR') ||
                    cardNumber.startsWith('AP') ||
                    cardNumber.startsWith('LM') ||
                    cardNumber.startsWith('HS') ||
                    cardNumber.startsWith('MN')
                ) {
                    moduleType = 'ORCA';
                } else if (cardNumber.startsWith('87') || cardNumber.startsWith('88') || cardNumber.startsWith('68')) {
                    moduleType = 'GEOMETRIS';
                } else if (cardNumber.startsWith('0-')) {
                    moduleType = 'GLOBALSTAR';
                } else {
                    return '';
                }
            }
            if (!moduleType) {
                moduleType = additionalData && additionalData.moduleType ? additionalData.moduleType : '';
            }
            return moduleType.toUpperCase();
        };

        const columns = [
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeName' }),
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                render: (value, record) => {
                    return (
                        <div>
                            {value}
                        </div>
                    );
                },

            },
            {
                title: props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeOdometer' }) : props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetails.Miles' }),
                dataIndex: 'odometer',
                key: 'odometer',
                editable: true,
                sorter: (a, b) => a.odometer - b.odometer,
                render: (value, record) => {
                    return (
                        <VehicleDetailManagement.IconEdit>
                            <div style={{ width: '70%', textAlign: 'right' }}>
                                {`${props.setTextunit(
                                    metersToKilometers(value),
                                    'odometer',
                                )}`}
                            </div>
                            <div>
                                <Icon className='fa fa-edit' />
                            </div>
                        </VehicleDetailManagement.IconEdit>
                    );
                },

            },
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeHorometer' }),
                dataIndex: 'horometer',
                key: 'horometer',
                editable: true,
                sorter: (a, b) => a.horometer - b.horometer,
                render: (value, record, index) => {
                    return (
                        <VehicleDetailManagement.IconEdit>
                            <div style={{ width: '45%', textAlign: 'right' }}>
                                {handleHotometerFormat(value)}
                            </div>
                            <div>
                                <Icon className='fa fa-edit' />
                            </div>
                        </VehicleDetailManagement.IconEdit>
                    );
                },

            },
            {
                title: props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehiculeGarageMode' }),
                dataIndex: 'configuration',
                key: 'configuration',
                // sorter: (a, b) => a.configuration[0].garageModeAct - b.configuration[0].garageModeAct,
                render: (value, index, text) => {
                    const additionalData = index.additionalData ? index.additionalData.toJS() : null;
                    const controlVehColors = additionalData && additionalData.garageModeIcons ? additionalData.garageModeIcons : null;

                    if (value && getModuleType(index.id) === 'ORCA') {
                            return (
                                <VehicleDetailManagement.Garage>
                                    <VehicleDetailManagement.Checkbox>
                                        {jwt.permissions &&
                                        Array.isArray(jwt.permissions) &&
                                        jwt.permissions.indexOf('write:vehicle-control') !== -1 ? <Checkbox
                                                checked={value.garageModeAct}
                                                onChange={(event) => handleGarageMode(event, value, text)}
                                                name={index.name}
                                                id={index.id}
                                                iconStyle={{ fill: colors.green57 }}
                                            >

                                                {value.garageModeAct ?
                                                    props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.Activate' }) :
                                                    props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.Deactivate' })}
                                            </Checkbox> :
                                            value.garageModeAct ?
                                                props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.Activate' }) :
                                                props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.Deactivate' })}

                                    </VehicleDetailManagement.Checkbox>


                                    {value.garageModeChanged ? (
                                        <Item.OptionsContainer
                                            color={value.garageModeAct ?
                                                props.controlColors.inactif_pending ?
                                                    props.controlColors.inactif_pending : controlVehColors ?
                                                        controlVehColors.inactif_pending : colors.redInacControl
                                                : props.controlColors.actif_pending ?
                                                    props.controlColors.actif_pending : controlVehColors ?
                                                        controlVehColors.actif_pending : colors.greenActControl}

                                            title={`${props.intl.formatMessage({
                                                id: value.garageModeAct
                                                    ? 'components.MapPopup.garageMode.pending.activation'
                                                    : 'components.MapPopup.garageMode.pending.unactivation',
                                            })}`}
                                        >
                                            <Item.Option>
                                                <i style={{ cursor: 'default' }}
                                                   className={value.garageModeAct ? 'fa fa-unlock' : 'fa fa-lock'} />
                                            </Item.Option>
                                        </Item.OptionsContainer>
                                    ) : value.garageModeAct ? (
                                        <Item.OptionsContainer
                                            color={props.controlColors.inactif ? props.controlColors.inactif : controlVehColors ? controlVehColors.inactif : colors.redA}
                                            title={`${props.intl.formatMessage({
                                                id: 'components.MapPopup.garageMode.activated',
                                            })}`}
                                        >
                                            <Item.Option>
                                                <i style={{ cursor: 'default' }} className='fa fa-unlock' />
                                            </Item.Option>
                                        </Item.OptionsContainer>
                                    ) : <Item.OptionsContainer
                                        color={props.controlColors.actif ? props.controlColors.actif : controlVehColors ? controlVehColors.actif : colors.sideMenuGreen}
                                        title={`${props.intl.formatMessage({
                                            id: 'components.MapPopup.garageMode.unactivated',
                                        })}`}
                                    >
                                        <Item.Option>
                                            <i style={{ cursor: 'default' }} className='fa fa-lock' />
                                        </Item.Option>
                                    </Item.OptionsContainer>}

                                </VehicleDetailManagement.Garage>
                            );

                        }

                },

            },

            {
                key: 'action',
                render: (value, index, text) => {
                   // console.log(value)
                    return (
                        <VehicleDetailManagement.ShowAlarm>

                            { value.hasAlarm === true && //(index.color !== colors.white) ||
                                <a href='#' onClick={(event) => handleSeeAlarms(event, value, text)}>
                                    {props.intl.formatMessage({id: 'containers.Maintenance.VehicleDetail.ShowAlarms'})}
                                    <i className='fa fa-bell' style={{color: (index.color)}}> </i>
                                </a>
                            }

                        </VehicleDetailManagement.ShowAlarm>
                    );

                },

            },
        ];

        const dropdownButtons = [
            {
                key: '1',
                label: 'Excel',
            },
            {
                key: '2',
                label: 'PDF',
            },
        ];
        const handleMenuClick = (e) => {
            if (e.key === '1') {
                handleConvertToExcel();
            } else if (e.key === '2') {
                handleConvertToPDF();
            }
        };
        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key="1">Excel</Menu.Item>
                <Menu.Item key="2">PDF</Menu.Item>
            </Menu>
        );
        return (
            isAlarmManageClicked ? (
                    <AlarmManagementContainer vehicleList={vehiculeListtoProps} handleManageAlarms={handleManageAlarms} />
                ) :
                isAlarmClicked ? (
                    <VehicleDetailManagement>
                        <AlarmVehicleList selectedAlarms={props.selectedAlarms} />
                    </VehicleDetailManagement>
                ) : (
                    <VehicleDetailManagement>
                        <FormattedMessage id={'containers.Maintenance.VehicleDetail.VehiculeDetails'} />
                        <hr />
                        <Loader loading={isVehicleLoaded} />
                        <VehicleDetailManagement.SearchContainer>
                            <SearchInput>
                                <Input
                                    placeholder={
                                        props.intl.formatMessage({ id: 'containers.Maintenance.VehicleDetail.VehicleSearch' })
                                    }
                                    name={searchInput}
                                    onChange={handleOnSearch}
                                />
                            </SearchInput>
                            <Button disabled={isVehicleLoaded} style={{ marginLeft: '10px' }}
                                    onClick={handleManageAlarms}><FormattedMessage
                                id={'containers.Maintenance.VehicleDetail.ManageAlarms'} />
                            </Button>
                        </VehicleDetailManagement.SearchContainer>

                        <hr />
                        <VehicleDetailManagement.Columns>
                            <EdiTable columns={columns}
                                      dataSource={isVehicleLoaded ? [] : vehicleList} updateVehicles={updateVehicles}
                                      isDataLoaded={props.isDataLoaded} />
                        </VehicleDetailManagement.Columns>
                        <Dropdown overlay={menu}>
                            <Button disabled={isVehicleLoaded} style={{ marginTop: '5px', }}>
                                <FormattedMessage id={'containers.Maintenance.VehicleDetail.OverdueMaintenance'} />
                            </Button>
                        </Dropdown>
                    </VehicleDetailManagement>
                )


        );
    }
;
function arePropsEqual(prevProps, nextProps) {
    return isEqual(prevProps, nextProps);
}
const mapStateToProps = createStructuredSelector({
    vehicles: selectFilteredVehicles(),
    selectedAlarms: selectMaintenanceVehHistoryState(),
    branch: selectCurrentBranch(),
    selectedAlarmList: selectMaintenanceAlarmState(),
    unit: selectUnit(),
    controlColors: selectControlColors(),
    user: selectUser(),
});
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            editGarageMode,
            getVehicles,
            getMaintenanceVeh,
            SetSelectedVehicle,
            getAllMaintenances,
            getMaintenance,
            setTextunit,
            changeUnit,
            i18nDistanceSymbol,

        },
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(React.memo(VehicleDetail, arePropsEqual)));

