// @flow

import styled from 'styled-components';
import colors from '../../styles/colors';

const BadgeItem = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
`;

const generalStyle = `
display: inline;
padding: 0.3em 0.6em 0.3em;
font-size: 90%;
font-weight: 700;
line-height: 1;
color: #fff;
text-align: center;
align-items: center;
white-space: nowrap;
vertical-align: baseline;
border-radius: 0.25em;
max-height: 25px;
`;

BadgeItem.basic = styled.span`
    ${generalStyle}
    background-color: ${colors.green61};
`;

BadgeItem.dateCheck = styled.span`
    ${generalStyle}
    background-color: ${(props) => (props.isLate ? '#96d372' : colors.green61)};
    border-style: solid;
    border-color: ${colors.green61};
`;

BadgeItem.distance = styled.span`
    ${generalStyle}
    background-color: ${colors.green61};
`;

BadgeItem.totalTimeSpent = styled.span`
    ${generalStyle}
    background-color: ${colors.green61}; 
`;

BadgeItem.grandTotalTimeSpent = styled.span`
    ${generalStyle}
    background-color: ${colors.pink}; 
`;
BadgeItem.mode = styled.span`
    display: inline;
    padding: 0.3em 0.6em 0.3em;
    font-size: 90%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    max-height: 25px;
    color: ${colors.black};
    border: 1px solid ${colors.black};
`;

BadgeItem.driver = styled.span`
    ${generalStyle}
    background-color: ${(props) => props.color};
`;

BadgeItem.sensorContainer = styled.div`
    border-radius: 0.25em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

BadgeItem.sensorItem = styled.span`
    ${generalStyle}
    background-color: ${colors.green61};
    margin: 1%;
    flex: 0 0 25%
`;

BadgeItem.battery = styled.span`
    ${generalStyle}
    background-color: ${colors.gray50};
`;

BadgeItem.key = styled.span`
    ${generalStyle}
    background-color: ${(props) => (props.isKey ? colors.green61 : colors.orange)}; 
`;

BadgeItem.speed = styled.span`
    ${generalStyle}
    background-color: ${colors.bleu10}; 
`;

BadgeItem.engine = styled.span`
    ${generalStyle}
    background-color: ${colors.bleu10}; 
`;

export default BadgeItem;
