// @flow

import React from 'react';

import { FormattedMessage } from 'react-intl';

import { SideMenu, Header, Footer, Image } from 'styles/sidemenu';

type Props = {
    width: ?number,
    height: ?number,
    source: ?string,
    translationId: ?string,
    onClick: ?() => void,
    textStyle: ?{
        fontWeight?: number,
        fontSize: ?number,
        color: ?string,
        padding: ?string,
        fontFamily: ?string,
    },
    splitGradient: ?{
        start: string,
        end: string,
    },
    image: ?{
        width: ?number,
        height: ?number,
    },
    split?: boolean,
    disabled?: boolean,
};

function SideMenuItemComponent(props: Props) {
    return (
        <React.Fragment>
            <SideMenu.Item height={props.height} onClick={props.onClick}>
                {props.source && (
                    <Image.Container>
                        <Image
                            src={props.source}
                            width={props.image && props.image.width}
                            height={props.image && props.image.height}
                            disabled={props.disabled}
                        />
                    </Image.Container>
                )}
                <SideMenu.ItemText
                    color={props.textStyle && props.textStyle.color}
                    fontsize={props.textStyle && props.textStyle.fontSize}
                    fontweight={props.textStyle && props.textStyle.fontWeight}
                    fontfamily={props.textStyle && props.textStyle.fontFamily}
                    padding={
                        props.textStyle && props.textStyle.padding
                            ? props.textStyle.padding
                            : '0 0 0 20px'
                    }
                    disabled={props.disabled}
                >
                    <FormattedMessage id={props.translationId} />
                </SideMenu.ItemText>
            </SideMenu.Item>
            {props.split && (
                <SideMenu.Split
                    colorstart={props.splitGradient && props.splitGradient.start}
                    colorend={props.splitGradient && props.splitGradient.end}
                />
            )}
        </React.Fragment>
    );
}

SideMenuItemComponent.defaultProps = {
    split: true,
    disabled: false,
};

export default SideMenuItemComponent;
