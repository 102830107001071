// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const UsersManagement = styled.div`
    border-radius: .25rem;
    padding: .75rem 1.25rem;
    border: 1px solid ${colors.green61};
    background: rgba(0, 0, 0, 0.05);
`;

UsersManagement.TitleHeader = styled.div`
    font-size: 1rem;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid #57535340;
    margin-bottom: 10px;
`;

UsersManagement.Table = styled.div`
    border-top: 1px solid #57535340;
    padding-top: 10px;
    margin-top: 10px;
`;

export default UsersManagement;
