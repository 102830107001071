// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Container } from './styles';
import colors from 'styles/colors';

type Props = {
    outlineColor?: string,
    hoverBackgroundColor: ?string,
    hoverOutlineColor: ?string,
    hoverTextColor: ?string,
    textColor: ?string,
    width: ?number,
    height?: number,
    selected: ?boolean,
    backgroundColor: ?string,
    padding: ?string,
    disabled: ?boolean,
};

function OutlineButton(props: Props) {
    return (
        <Container
            backgroundcolor={props.backgroundColor}
            hoverBackgroundColor={props.hoverBackgroundColor}
            hoverOutlineColor={props.hoverOutlineColor}
            hoverTextColor={props.hoverTextColor}
            outlinecolor={props.outlineColor}
            onClick={!props.disabled ? props.onClick : ''}
            selected={props.selected}
            padding={props.padding}
            stopAnimation={props.backgroundColor === props.hoverBackgroundColor}
            height={props.height}
            width={props.width}
            marginTop={props.marginTop}
            disabled={props.disabled}
        >
            <Button width={props.width}>
                <Button.Text color={props.selected ? colors.white : props.textColor}>
                    {props.children}
                </Button.Text>
            </Button>
        </Container>
    );
}
OutlineButton.defaultProps = {
    height: 40,
    outlineColor: colors.black,
};

export default OutlineButton;
