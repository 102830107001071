// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const UsersList = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: ${(props) => (!props.isOpen ? '30px 50px 0 110px;' : '30px 50px 0 50px;')};
`;

UsersList.Item = styled.div`
    grid-column: span 3;
    cursor: pointer;
`;

UsersList.Container = styled.div``;

UsersList.GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 60px);
    ${UsersList.Item}:nth-child(odd) {
        background-color: ${colors.grayF8};
    }
`;

UsersList.Split = styled.div`
    height: ${(props) => props.height || '1px'};
    background-color: ${(props) => props.color || `${colors.black}`};
`;

UsersList.HeaderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 35px);
`;

UsersList.HeaderCaret = styled.img`
    width: 14px;
    height: 9px;
`;

UsersList.HeaderText = styled.div`
    font-weight ${(props) => props.fontWeight};
    font-size: ${(props) => props.fontSize || '18px'};
    margin-top: ${(props) => props.marginTop};
    float: left;
`;

UsersList.Header = styled.div`
    height: 35px;
`;

UsersList.HeaderItem = styled.div`
    cursor: pointer;
`;

UsersList.FilterContainer = styled.div`
    width: 50%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 50px;
    padding-right: 23px;
    & > div {
        margin-right: 10px;
    }
`;
UsersList.SearchContainer = styled.div`
    margin-right: 10px;
`;

UsersList.ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;
UsersList.ActionButtonContainer = styled.div`
    flex: 1;
    margin-left: 10px;
    ${(props) => (props.shrink ? 'flex-grow: 0.2' : null)};
`;

UsersList.DataHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 50px;
`;

UsersList.ViewMore = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
`;

UsersList.GroupButtons = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

UsersList.ParentInfo = styled.div`
    padding: 12px 6px;
`;

const SearchInput = styled.div`
    display: flex;
    height: 100%;
    background-color: ${colors.grayF8};
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => padding || '0 12px 0 17px'};
    border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};
    position: relative;
    padding-right: 36px;

    svg {
        display: block;
        position: absolute;
        height: 18px;
        right: 14px;
        top: 0;
        bottom: 0;
        margin: auto 0;

        path {
            fill: ${colors.grayDD};
        }
    }
`;

SearchInput.Icon = styled.img`
    width: ${(props) => props.width || '22px'};
    height: ${(props) => props.height || '22px'};
`;

const Input = styled.input`
    height: 100%;
    width: 100%;
    border: none;
    background: none;
    &:focus {
        outline: none;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: OpenSans;
    ${(props) =>
        props.bold &&
        `
        font-weight: 400;
        font-family: OpenSans-SemiBold;
    `};
`;

export { UsersList, SearchInput, Input };