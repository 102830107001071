// @flow

import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SearchInput, Input } from './styles';

import { Search } from 'components/Icons';

import { fromJS } from 'immutable';
import Fuse from 'fuse.js';

import type { ImmutableList, ImmutableMap } from 'types';

type Props = {
    onSearchResults: Function,
    searchKeys: Array<string>,
    placeholder: ?string,
    borderColor: ?string,
    data: ?ImmutableList<ImmutableMap>,
    padding: ?string,
    bold: ?boolean,
    threshold: ?number,
    inputLocked: ?boolean,
    fleetOverviewInput: ?boolean
};

type State = {
    searchQuery: ?string,
    data: ?ImmutableList<ImmutableMap>,
};

export class SearchInputComponent extends React.PureComponent<Props, State> {
    fuse: Object;

    fuseOptions: Object;

    constructor(props) {
        super(props);

        this.fuseOptions = {
            threshold: props.threshold || 0.2,
            tokenize: true,
            matchAllTokens: true,
            keys: this.props.searchKeys,
        };
        if (props.data && props.data.size > 0) {
            this.fuse = new Fuse(props.data.toJS(), this.fuseOptions);
        }
    }


    state = {
        searchQuery: '',
        data: fromJS([]),
        initilized: false,
        value: null
    };

    // componentDidMount() {
    //     if (this.props.data && this.props.data.size > 0) {
    //         this.setState({initilized: true})
    //         this.fuse = new Fuse(this.props.data.toJS(), this.fuseOptions);
    //
    //         const newData = this.fuse.search(this.state.searchQuery);
    //         // heavy action required, as data is handled as ImmutableList, FuseJS uses regular array.
    //         this.props.onSearchResults({
    //             query: this.state.searchQuery,
    //             results: fromJS(this.handleConvertResult(newData)),
    //         });
    //     }
    // }

     componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>) {
         // if(prevProps.data !== this.state.data && this.state.value){
         //     this.fuse = new Fuse(prevProps.data.toJS(), this.fuseOptions);
         //     const newData = this.fuse.search(this.state.value);
         //     this.setState({ data: prevProps.data });
         //     this.props.onSearchResults({
         //         query: this.state.value,
         //         results: fromJS(this.handleConvertResult(newData)),
         //         fetchNextPage: true,
         //         fuse: this.fuse,
         //     });
         // }
         this.fuseOptions = {
            threshold: this.props.threshold || 0.2,
            tokenize: true,
            matchAllTokens: true,
            keys: this.props.searchKeys,
        };
     }

    handleConvertResult = (array) => {
        let list = []
        array.forEach((r) => {
            list.push(r.item);
        })
        return list;
    }

    // handleOnUpdate = (q) => {
    //     // TODO: add debounce.
    //     if (this.props.data) {
    //         this.fuse = new Fuse(this.props.data.toJS(), this.fuseOptions);
    //     }
    //     const searchQuery = q;
    //     this.setState({ searchQuery, data: this.props.data });
    //     const newData = this.fuse.search(searchQuery);
    //     // heavy action required, as data is handled as ImmutableList, FuseJS uses regular array.
    //     this.props.onSearchResults({
    //         query: searchQuery,
    //         results: fromJS(this.handleConvertResult(newData)),
    //         fetchNextPage: true,
    //         fuse: this.fuse,
    //     });
    // };



    handleOnChange = (e: Event) => {
        // TODO: add debounce.
        if (this.props.data) {
            this.fuse = new Fuse(this.props.data.toJS(), this.fuseOptions);
        }
        this.setState({value : e.target.value.trim()})
        const myQuery = e.target.value.trim();
        this.setState({ myQuery, data: this.props.data });
        const newData = this.fuse.search(myQuery);
        this.props.onSearchResults({
            query: myQuery,
            results: fromJS(this.handleConvertResult(newData)),
            fetchNextPage: true,
            fuse: this.fuse,
        });
    };

    handleKeyUp = (e: Event) => {
        if (e.key === 'Escape') {
            e.target.value = '';
            const searchQuery = '';
            const newData = this.fuse.search(searchQuery);

            this.props.onSearchResults({
                query: searchQuery,
                results: fromJS(this.handleConvertResult(newData)),
                fetchNextPage: true,
            });
        }
    };

    render() {
        return (
            <SearchInput className={'searchInput'} borderColor={this.props.borderColor} padding={this.props.padding}>
                <Input
                    placeholder={
                        this.props.placeholder ||
                        this.props.intl.formatMessage({ id: 'components.SearchInput.Placeholder' })
                    }
                    onKeyUp={this.handleKeyUp}
                    onChange={this.handleOnChange}
                    bold={this.props.bold}
                    fleetOverviewInput = {this.props.fleetOverviewInput}
                    disabled={this.props.locked}
                />
                <Search />
            </SearchInput>
        );
    }
}
export default injectIntl(SearchInputComponent);
