//@flow
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import {bindActionCreators} from 'redux';
import {createStructuredSelector} from 'reselect';

import {getVehicles} from '../../../services/Vehicle/thunks';
import {selectFilteredVehicles, selectVehicles} from '../../../services/Vehicle/selectors';
import {Modal, Table} from 'antd';
import {selectCategories, selectCategoriesLoading} from '../../../services/Categorie/selectors';
import {
    createCategorie,
    editCategorieName, editCategorieVehicles, getCategorieById,
    getCategoriesByBranch,
    removeCategorie, removeVehiclesFromCategorie,
} from '../../../services/Categorie/thunks';
import {Button, Input as InputAntd, message, Space} from 'antd';
import SearchInput from '../../../components/SearchInput';
import CatogeriesStyle from './styles';
import {useVT} from 'virtualizedtableforantd4';
import SelectFilter from "../../../components/SelectFilter";
import {editEquipementName} from "../../../services/Config/thunks";
import CategoryFilter from "../../../components/CategoryFilter";
import {fromJS} from "immutable";
import type {SearchResultsTypes} from 'types';
import AutoComplete from "../../../components/SelectFilter/styles";
import AlarmVehicleListStyle from "../../Maintenance/AlarmVehicleList/styles";

const Category = (props) => {
    const [categoriesList, setCategoriesList] = useState([]);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);
    const [indexCatModify, setIndexCatModify] = useState(-1);
    const [updateCategory, setUpdateCategory] = useState('');
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [hasCategory, setHasCategory] = useState(false);
    const [selectedVehiclesInfo, setSelectedVehiclesInfo] = useState([]);
    const [vehicleName, setVehicleName] = useState('');
    const [vehicleSelected, setVehicleSelected] = useState('');
    const [categorysearchResults, setCategorysearchResults] = useState(fromJS([]));
    const [searchQuery, setSearchQuery] = useState('');
    const [categoryQuery, setCategoryQuery] = useState('');
    const [searchResults, setSearchResults] = useState(fromJS([]));
    const [catToDelete, setCatToDelete] = useState(null);
    const [isCategorySelected, setIsCategorySelected] = useState(false);

    const [vt] = useVT(() => ({scroll: {y: 500}}), []);

    useEffect(() => {
        props.getCategoriesByBranch();
        props.getVehicles()
            .then(() => {
                setDataLoaded(true);
            })
        ;
    }, [isDataLoaded]);

    useEffect(() => {
        if (props.vehicles && props.vehicles.size > 0 && props.vehicles.toJS().length > 0) {
            setVehicleList(props.vehicles.toJS());

            if(isCategorySelected === false){
                if(categorysearchResults && categorysearchResults.size>0){
                    setVehicleList(categorysearchResults.toJS());
                }else if (categoryQuery !== '') {
                    setVehicleList(searchResults.toJS());
                }
            }
            if(isCategorySelected === true){
                if(categorysearchResults && categorysearchResults.size>0){
                    setVehicleList(categorysearchResults.toJS());
                }else if (categoryQuery !== '') {
                    setVehicleList(categorysearchResults.toJS());
                }
            }

        }
    }, [props.vehicles, categoryQuery, categorysearchResults, searchResults,isCategorySelected]);


    useEffect(() => {
        if (props.categoryList && Object.keys(props.categoryList).length > 0 && props.categoryList.toJS().length > 0) {
            const tabCategory = [];
            let brandId
            props.categoryList.toJS().map((cat) => {
                brandId = cat.branchId
                tabCategory.push({
                    branchId: cat.branchId,
                    id: cat.categorieId,
                    name: cat.categorieName,
                    size: cat.vehicles.length
                });
            });

            tabCategory.push({brandId: brandId, id: -1, name:  props.intl.formatMessage({
                    id: 'containers.AdminContainers.Category.None',
                })})
            setCategoriesList(tabCategory);

        } else {
            setCategoriesList([]);
        }
    }, [props.categoryList]);

    useEffect(() => {
        let isCategoryFound = false
        if (selectedVehiclesInfo.length > 0) {
            selectedVehiclesInfo.map((veh) => {
                if (veh.categorie.categorieId) {
                    isCategoryFound = true
                }
            })
        }
        setHasCategory(isCategoryFound)
    }, [selectedVehiclesInfo]);


    const handleModifyCategory = (event, vehicleSelected, index) => {
        setIndexCatModify(index);
        setVehicleSelected(vehicleSelected)
        setSelectedVehiclesInfo([vehicleSelected])
    };

    const isContainVehName = (vehName) => {
        if (!vehicleList || vehicleList.length === 0) {
            return false;
        }
        return vehicleList.some((veh) => {
            return veh.name === vehName;
        });
    };

    const insertVehiclesId = (otherVehId, isSelectedVehicles) => {
        let vehId = []
        if (props.categoryList.size > 0) {
            props.categoryList.toJS().map((list) => {
                if (list.categorieName === updateCategory.name) {
                    if (list.vehicles.length > 0) {
                        list.vehicles.map((vehicle) => {
                            vehId.push(vehicle.id
                            )
                        })
                    }
                }
            })
        }
        if (isSelectedVehicles) {
            vehId = vehId.concat(selectedVehicles)
        } else {
            vehId.push(otherVehId)
        }

        return vehId
    }

    const handleSave = async (event, category, index) => {

        //Changer le nom du véhicule
        if (isContainVehName(vehicleName.trim())) {
            failureNotif(props.intl.formatMessage({
                id: 'containers.AdminContainers.Category.VehicleList.NameExist',
            }));
        } else if (vehicleName.trim() !== '') {
            await props.editEquipementName(vehicleSelected.id, vehicleName.trim())
                .then(async () => {
                    await props.getVehicles()
                    setIndexCatModify(-1);
                    setVehicleSelected('')
                    setVehicleName('')
                    setSelectedVehiclesInfo([])
                    setHasCategory(false)
                    successMsg(
                        props.intl.formatMessage({
                            id: 'containers.AdminContainers.Category.ModifSuccess',
                        }));
                }).catch(() => {
                    errorMsg(
                        props.intl.formatMessage({
                            id: 'containers.Maintenance.Notif.Error',
                        }),
                    );
                });
        }

        //Associer et dessassocier une categorie
        if (updateCategory.name.trim().length > 0) {
            const isFound = categoriesList.some(element => {
                return element.name === updateCategory.name;
            });

            //Ajouter une nouvelle categorie et l'associer
            if (!isFound) {
                await props.createCategorie(updateCategory.name)
                    .then(async (response) => {
                        await props.getCategoriesByBranch();
                        const vehicleId = []
                        vehicleId.push(vehicleSelected.id)

                        if (selectedVehicles.length > 0) {
                            await props.editCategorieVehicles(response.categorieId, selectedVehicles)
                                .then(async () => {
                                    await props.getVehicles()
                                    setIndexCatModify(-1);
                                    setVehicleSelected('')
                                    setVehicleName('')
                                    setHasCategory(false)
                                    setSelectedVehiclesInfo([])
                                    successMsg(
                                        props.intl.formatMessage({
                                            id: 'containers.AdminContainers.Category.ModifSuccess',
                                        }));

                                });
                            setIndexCatModify(-1);
                            setVehicleSelected('')
                            setVehicleName('')
                            setHasCategory(false)
                            setSelectedVehiclesInfo([])
                        } else {
                            await props.editCategorieVehicles(response.categorieId, vehicleId)
                                .then(async () => {
                                    await props.getVehicles()
                                    setIndexCatModify(-1);
                                    setVehicleSelected('')
                                    setVehicleName('')
                                    setHasCategory(false)
                                    setSelectedVehiclesInfo([])
                                    successMsg(
                                        props.intl.formatMessage({
                                            id: 'containers.AdminContainers.Category.AddSuccess',
                                        }));

                                });
                            setIndexCatModify(-1);
                            setVehicleSelected('')
                            setVehicleName('')
                            setHasCategory(false)
                            setSelectedVehiclesInfo([])
                        }
                    });
            } else {

                //Changer de categorie a plusieurs vehicules
                if (selectedVehicles.length > 0) {
                    await props.editCategorieVehicles(updateCategory.id, insertVehiclesId(selectedVehicles, true))
                        .then(async () => {
                            await props.getVehicles()
                            setIndexCatModify(-1);
                            setVehicleSelected('')
                            setVehicleName('')
                            setHasCategory(false)
                            setSelectedVehiclesInfo([])
                            successMsg(
                                props.intl.formatMessage({
                                    id: 'containers.AdminContainers.Category.ModifSuccess',
                                }));

                        });
                    setIndexCatModify(-1);
                    setVehicleSelected('')
                    setVehicleName('')
                    setHasCategory(false)
                    setSelectedVehiclesInfo([])
                } else {
                    //Dessassocier une categorie a un vehicule
                    const vehicleId = []
                    vehicleId.push(vehicleSelected.id)
                    if (updateCategory.id === -1) {

                        await props.removeVehiclesFromCategorie(vehicleSelected.categorie.categorieId, vehicleId)
                            .then(async () => {
                                await props.getCategoriesByBranch();
                                await props.getVehicles()
                                setIndexCatModify(-1);
                                setVehicleSelected('')
                                setVehicleName('')
                                setHasCategory(false)
                                setSelectedVehiclesInfo([])

                                successMsg(
                                    props.intl.formatMessage({
                                        id: 'containers.AdminContainers.Category.ModifSuccess',
                                    }));

                            });
                    } else {
                        //Changer de categorie a un vehicule
                        await props.editCategorieVehicles(updateCategory.id, insertVehiclesId(vehicleSelected.id, false))
                            .then(async () => {
                                await props.getCategoriesByBranch();
                                await props.getVehicles()
                                setIndexCatModify(-1);
                                setVehicleSelected('')
                                setVehicleName('')
                                setHasCategory(false)
                                setSelectedVehiclesInfo([])
                                successMsg(
                                    props.intl.formatMessage({
                                        id: 'containers.AdminContainers.Category.ModifSuccess',
                                    }));

                            });
                        setIndexCatModify(-1);
                        setVehicleSelected('')
                        setVehicleName('')
                        setHasCategory(false)
                        setSelectedVehiclesInfo([])
                    }

                }

            }

        }

    };
    const handleCancel = (event) => {
        setIndexCatModify(-1);
        setVehicleName('')
        setVehicleSelected('')
        setUpdateCategory('');
        setHasCategory(false)
        setSelectedVehiclesInfo([])
        setSelectedVehicles([])
    };

    const failureNotif = (msg) => {
        message.warning(msg, 2);
    };
    const successMsg = (msg) => {
        message.info(msg);
    };
    const errorMsg = (msg) => {
        message.info(msg);
    };

    const onChangeVehicleName = (event, value, text) => {
        setVehicleName(event.target.value)
    }
    const selectedCategory = (value, newValue) => {
        setUpdateCategory(value);
    }

    const handlecatToDelete = (category) => {
        console.log(category)
        setCatToDelete(category)
    }

    const columns = [
        {
            title: props.intl.formatMessage({
                id: 'components.SideMenu.Vehicles',
            }),
            dataIndex: 'categorie',
            key: 'name',
            width: '70%',
            sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            render: (value, index, text) => {
                return (
                    value ?
                        text === indexCatModify ?
                            <div style={{display: 'flex'}}>
                                <SelectFilter isDisabled={false} selectedCategory={selectedCategory}
                                              categoriesList={categoriesList}
                                              categoryName={value && value.categorieName ? value.categorieName : ''}
                                              handlecatToDelete={handlecatToDelete}/>
                                <div style={{padding: '0 5px 0 5px', fontWeight: 'bold'}}>::</div>
                                <AutoComplete.TextField color={vehicleName && vehicleName.length > 50 ? 'warning' : ''}
                                                        defaultValue={value.vehName}
                                                        onChange={onChangeVehicleName}
                                                        label={(vehicleName && vehicleName.length > 50) ?
                                                            props.intl.formatMessage({
                                                                id: 'containers.AdminContainers.Category.SelectFilter.MaxVehCrt'
                                                            }) : props.intl.formatMessage({
                                                                id: 'containers.AdminContainers.Category.VehicleAdd'
                                                            })}

                                                        size="small"/>
                            </div> :
                            value.categorieName !== 'Others' ?
                                <div style={{display: 'flex'}}>
                                    <div style={{fontWeight: 'bold'}}>{value.categorieName}</div>
                                    <div style={{padding: '0 5px 0 5px', fontWeight: 'bold'}}>::</div>

                                    <div>{value.vehName} </div>
                                </div> : <div>{value.vehName}</div> :
                        text === indexCatModify ?
                            <AutoComplete.TextField color={vehicleName && vehicleName.length > 50 ? 'warning' : ''}
                                                    defaultValue={value.vehName}
                                                    onChange={onChangeVehicleName}
                                                    label={(vehicleName && vehicleName.length > 50) ?
                                                        props.intl.formatMessage({
                                                            id: 'containers.AdminContainers.Category.SelectFilter.MaxVehCrt'
                                                        }) : props.intl.formatMessage({
                                                            id: 'containers.AdminContainers.Category.VehicleAdd'
                                                        })}

                                                    size="small"/>
                            : <div>{value.vehName}</div>

                );

            },

        },

        {
            key: 'action',
            render: (value, index, text) => {
                return (
                    indexCatModify === text ?
                        <div>
                            <Button
                                style={{marginRight: '10px', backgroundColor: '#72a13e', border: '2px solid #72a13e'}}
                                type='primary' className={'modifySaveBtn'} size={'small'}
                                onClick={(event) => handleSave(event, value)}>
                                {props.intl.formatMessage({id: 'containers.AdminContainers.Category.CategorySaveBtn'})}
                            </Button>
                            <Button style={{backgroundColor: '#EC0909', border: '2px solid #EC0909'}} type='primary'
                                    className={'modifySaveBtn'} size={'small'}
                                    onClick={(event) => handleCancel(event, value, text)}>
                                {props.intl.formatMessage({id: 'containers.AdminContainers.Category.CategoryCancelBtn'})}
                            </Button>
                        </div> :
                        <div>
                            <Button style={{backgroundColor: '#72a13e', border: '2px solid #72a13e'}} type='primary'
                                    className={'modifySaveBtn'} size={'small'}
                                    onClick={(event) => indexCatModify === text ? handleSave(event, value) : handleModifyCategory(event, value, text)}>
                                {props.intl.formatMessage({id: 'containers.AdminContainers.Category.CategoryModifBtn'})}
                            </Button>
                        </div>


                );

            },

        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedVehicles([...selectedRowKeys]);
            setSelectedVehiclesInfo([...selectedRows]);
        },
    }

    const handleVehicleSearchResults = (results: SearchResultsTypes) => {
        setSearchResults(results.results)
        setSearchQuery(results.query)
        setCategoryQuery(categoryQuery)

    };
    const handleCategoryFilterResult = (results: SearchResultsTypes) => {
        setCategorysearchResults(results.results)
        setSearchQuery(searchQuery)
        setCategoryQuery(results.query)
        setIsCategorySelected(results.isCategorySelected)
    };

    const handleCancelModal = () => {
        setCatToDelete(null)
    }

    const handleOkModal = () => {

        props.removeCategorie(catToDelete.id)
            .then(async() => {
                successMsg(
                    props.intl.formatMessage({
                        id: 'containers.AdminContainers.Category.ModifSuccess',
                    }));
                await props.getCategoriesByBranch();
                await props.getVehicles()
                setIndexCatModify(-1);
                setVehicleSelected('')
                setVehicleName('')
                setHasCategory(false)
                setSelectedVehiclesInfo([])
                setCatToDelete(null)
            })

    }
    return (
        <CatogeriesStyle>
            <FormattedMessage id={'containers.AdminContainers.Category.Management'}/>
            <hr/>
            
            <Modal
                title={ props.intl.formatMessage({id: 'containers.AdminContainers.Category.DeteleCategory',})}
                visible={catToDelete}
                onCancel={handleCancelModal}

                footer={[
                    <AlarmVehicleListStyle.ModalDelete key={'modalDelete'}>
                        <Button
                            key='no'
                            className={'redBtn'}
                            type='primary'
                            onClick={handleCancelModal}
                        >
                            <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalNo'}/>
                        </Button>
                        <Button
                            key='yes'
                            className={'greenBtn'}
                            type='primary'
                            onClick={handleOkModal}
                        >
                            <FormattedMessage id={'containers.Maintenance.AlarmVehicle.ModalYes'}/>
                        </Button>
                    </AlarmVehicleListStyle.ModalDelete>,

                ]}>

                <p>{catToDelete ? catToDelete.name : ''}</p>
            </Modal>
            <CatogeriesStyle.VehicleList style={{width: '60%'}}>
                <div style={{display: 'flex', paddingBottom: '10px'}}>
                    <SearchInput
                        style={{width: '100%', height: '35px'}}
                        onSearchResults={handleVehicleSearchResults}
                        searchKeys={['name']}
                        data={fromJS(props.vehicles)}
                        padding={'0 8px 0 10px'}
                        fleetOverviewInput={true}
                    />
                    <div className={'categoryFilter'}>
                        <CategoryFilter
                            onSearchResults={handleCategoryFilterResult}
                            searchQuery={searchQuery}
                            searchKeys={['categorie.categorieName']}
                            data={props.vehicles}
                        />
                    </div>

                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={vehicleList.length > 0 ? vehicleList : null}
                        rowKey={record => record.id}
                        pagination={false}
                        scroll={{y: 400}}
                        size='small'
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedVehicles,
                            ...rowSelection,

                        }}
                        components={vt}
                        footer={() =>
                            <div>
                                <div style={{display: 'flex'}}>
                                    <SelectFilter isDisabled={selectedVehicles.length === 0}
                                                  selectedCategory={selectedCategory}
                                                  categoriesList={categoriesList}
                                                  categoryName={''}
                                                  handlecatToDelete={handlecatToDelete}
                                    />

                                    <Button
                                        style={{marginLeft:'20px'}}
                                        disabled={selectedVehicles.length === 0}
                                        onClick={handleSave}
                                        type='primary'
                                        className={'addBtn'}
                                    >
                                        {props.intl.formatMessage({id: 'containers.AdminContainers.Category.CategoryModifBtn'})}

                                    </Button>
                                    <Button
                                        disabled={selectedVehicles.length === 0}
                                        onClick={handleCancel}
                                        type='primary'
                                        style={{marginLeft: '10px'}}
                                        className={'cancelBtn'}
                                    >
                                        {props.intl.formatMessage({id: 'containers.AdminContainers.Category.CategoryCancelBtn'})}

                                    </Button>
                                </div>

                                <Space style={{marginBottom: 16}}>
                                    {hasCategory ?
                                        <div style={{display: 'flex'}}>
                                            <i className='fa fa-exclamation-triangle'
                                               aria-hidden='true'
                                               style={{
                                                   color: '#ffb100',
                                                   paddingRight: '15px',
                                                   marginLeft: '8px',
                                               }}/>
                                            <div style={{fontSize: '14px'}}>
                                                {props.intl.formatMessage({id: 'containers.AdminContainers.Category.VehicleList.CatChange'})}
                                            </div>
                                        </div> : null}
                                </Space>
                            </div>
                        }
                    />
                </div>
            </CatogeriesStyle.VehicleList>
        </CatogeriesStyle>

    );
};

const mapStateToProps = createStructuredSelector({
    selectedVehicles: selectVehicles(),
    vehicles: selectFilteredVehicles(),
    categoryList: selectCategories(),
    areCatLoaded: selectCategoriesLoading(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getVehicles,
            getCategoriesByBranch,
            createCategorie,
            removeCategorie,
            editCategorieName,
            getCategorieById,
            editCategorieVehicles,
            editEquipementName,
            removeVehiclesFromCategorie
        },
        dispatch,
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(Category)),
);
