// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectBranchState = () => (state) => state.get('branch');

const selectCurrentBranch = () =>
    createSelector(selectBranchState(), (substate) => substate.get('selectedBranch'));

const selectBranchUsers = () =>
    createSelector(selectBranchState(), (substate) => substate.getIn(['selectedBranch', 'users']));

export { selectCurrentBranch, selectBranchUsers };
