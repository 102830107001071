/* eslint-disable react/prop-types,flowtype/require-parameter-type */
// @flow

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    info: {
        fontSize: '0.7em',
        fontWeight: 'bold',
        color: theme.palette.grey[700],
        marginBottom: '10px',
    },
}));

const formatString = (value) => {
    const accentedStrings = /[\u0300-\u036f]/g;
    return value
        .normalize('NFD')
        .replace(accentedStrings, '')
        .toLowerCase();
};

const InfoText = ({ value }) => {
    const classes = useStyles();
    return <div className={classes.info}>{value}</div>;
};

export { formatString, InfoText };
