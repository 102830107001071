
import React from 'react';
import { Title } from './styles.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { replace } from 'react-router-redux';
import { selectLocale } from 'services/Language/selectors';



const ErrorPage = (props) => (
  <div style={{
    display: 'flex',             
    justifyContent: 'center',     
    alignItems: 'center',        
    height: '100vh',              
    width: '100vw',               
    textAlign: 'center',          
    backgroundColor: '#f8f9fa',   
    flexDirection: 'column'       
  }}>


    {/* Logo */}
    <img src={props.Logo} alt="Erreur 404" width="20%" />

    {/* Message d'erreur */}
    <Title>
      <FormattedMessage id={'views.ErrorPage.oups'} />
    </Title>

    {/* Lien de retour */}
    <a href="/fr/map" style={{
  display: 'inline-block',     
  padding: '10px 20px',       
  backgroundColor: '#28a745',  
  color: 'white',              
  textDecoration: 'none',      
  borderRadius: '5px',         
  fontSize: '16px',            
  fontWeight: 'bold',          
  textAlign: 'center',         
  transition: 'background-color 0.3s', 
}} >
  <FormattedMessage id={'views.403.Return'} />
</a>
  </div>
);



const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
});

const mapDispatch = { replace };

export default connect(
    mapStateToProps,
    mapDispatch
)(injectIntl(ErrorPage));
