import styled from 'styled-components';
import colors from 'styles/colors';
import HexToRGB from 'styles/HexToRGB';
import screen from 'styles/screen';

const ImpersonationStyles = styled.div`
    display: flex;
    background: ${colors.black};
    line-height: 47px;
    padding: 0;

    color: ${colors.white};
    font-size: 14px;
    font-weight: 800;
    font-family: OpenSans;

    text-transform: uppercase;
    justify-content: space-between;

    span {
        color: ${colors.green62};
    }
`;

ImpersonationStyles.User = styled.div`
    display: block;
    width: calc(70%);
    padding: 0 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

ImpersonationStyles.ExitButton = styled.div`
    cursor: pointer;
    color: ${colors.white};
    font-size: 1.6em;
    display: inline-block;
    font-weight: bold;
    width: calc(30% - 1px);
    padding: 0 15px;
    border: 1px solid rgba(${HexToRGB(colors.white, true)}, 0.3);
    border-right: none;
    border-top: none;
    border-bottom: none;
    font-size: 14px;
    font-weight: 800;
    font-family: OpenSans;
    transition: all 250ms ease-in-out 0s;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;

    @media (min-width: ${screen.medium}) {
        max-width: 150px;
        &:hover {
            background-color: ${colors.green62};
        }
    }
`;

export default ImpersonationStyles;
