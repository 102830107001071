// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the auth state domain
 */
const selectReservationsState = () => (state) => state.get('apReservations');

/**
 * Select Reservations
 */
const selectReservations = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('reservations'));
/**
 * Select the modal state
 */
const selectShowApModal = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('showApModal'));
/**
 * Select the reservation modal state
 */
const selectReservationModal = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('reservationModal'));
/**
 * Select the selected rows
 */
const selectRows = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('selectedRows'));

const selectReservationDate = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('reservationDate'));

const selectReservationHours = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('reservationHours'));

const selectReservation = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('reservation'));

const selectDateAvailabity = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('dateAvailabity'));

const selectReservationSuccess = () =>
    createSelector(selectReservationsState(), (substate) => substate.get('reservationSuccess'));

export {
    selectShowApModal,
    selectReservations,
    selectReservationModal,
    selectReservationDate,
    selectReservationHours,
    selectDateAvailabity,
    selectRows,
    selectReservationSuccess,
    selectReservation,
};
