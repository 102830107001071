import React, {useEffect, useState} from 'react';
import {createFilterOptions} from '@mui/material/Autocomplete';
import Autocomplete from './styles'
import AutoComplete from "./styles";
import {injectIntl, FormattedMessage} from 'react-intl';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {bindActionCreators} from "redux";
import DeleteForever from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';

const filter = createFilterOptions();

const SelectFilter = (props) => {
    const [value, setValue] = React.useState(props.categoryName ? props.categoryName : '');
    const [inputEvent, setInputEvent] = React.useState('');
    const [listOptions, setListOptions] = React.useState('');
    useEffect(() => {
        if (value) {
            props.selectedCategory(value);
        } else {
            props.selectedCategory({name: ''});
        }
    }, [value]);

    useEffect(() => {

    }, []);

    const changeOrAddCaegory = (event, newValue) => {
        if (typeof newValue === 'string') {
            setValue({
                name: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
                name: newValue.inputValue,
            });
        } else {
            setValue(newValue);
        }

    }
    const filteringCategories = (options, params) => {
        const filtered = filter(options, params);
        const {inputValue} = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting && (inputEvent && inputEvent.length < 13)) {
            filtered.push({
                inputValue,
                name: props.intl.formatMessage({
                    id: 'containers.AdminContainers.Category.CategoryAddBtn'
                }) + ' ' + inputValue,
            });
        }
        return filtered.reverse();
    }
    const inputValueOnChange = (event, newValue) => {
        if (event) {
            setInputEvent(event.target.value)
        } else {
            setInputEvent(newValue)
        }
    }

    const handleDeleteSelectedCategory = (option) => {
        props.handlecatToDelete(option)
    }

    const noneVariable = props.intl.formatMessage({
        id: 'containers.AdminContainers.Category.None'
    })

    return (
        <Autocomplete
            value={value === 'Others' ? '' : value}
            onChange={(event, newValue) => changeOrAddCaegory(event, newValue)}
            onInputChange={(event, newValue) => inputValueOnChange(event, newValue)}
            filterOptions={(options, params) => filteringCategories(options, params)}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id={props.intl.formatMessage({
                id: 'containers.AdminContainers.Category.CategorySearch'
            })}
            disabled={props.isDisabled}
            options={props.categoriesList}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(props, option) => (

                <li {...props}>
                    <div style={{fontStyle: option.name === noneVariable ? 'italic' : 'normal'}}>
                        {option.size > 0 ? option.name + ' (' + option.size + ')' : option.name}
                        {option.name !== noneVariable ?
                            <IconButton size={'small'} edge="end"
                                        onClick={() => handleDeleteSelectedCategory(option)}>
                                <DeleteForever/>
                            </IconButton>
                            : ''}

                    </div>
                </li>
            )}

            sx={{width: 250}}
            freeSolo
            renderInput={(params) => (
                <AutoComplete.TextField color={inputEvent && inputEvent.length > 12 ? 'warning' : ''}
                                        {...params}
                                        label={(inputEvent && inputEvent.length > 12) ?
                                            props.intl.formatMessage({
                                                id: 'containers.AdminContainers.Category.SelectFilter.MaxCatCrt'
                                            }) : props.intl.formatMessage({
                                                id: 'containers.AdminContainers.Category.CategorySearch'
                                            })}
                                        size="small"/>
            )}
        />
    );
}
const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch,
    );
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(SelectFilter)),
);
