import moment from 'moment-timezone';

export const timezoneConverter = (date: string ) => {
    const originalTimeZone = moment(date).tz("America/Montreal", true).format();
    const parsedDate = Date.parse(originalTimeZone)
    const newDate = new Date(parsedDate)
    return moment(newDate).format('YYYY-MM-DD HH:mm:ss')
}
export const handleConvertUTC = (date) => {
    const userTimeZone = moment.tz.guess();
    return moment(date).tz(userTimeZone).format('YYYY-MM-DD HH:mm:ss A');
};
// export const timezoneConverter = (dateString) => {
//     // Parse the date string using moment to ensure correct interpretation
//     const dateMoment = moment(dateString);
//
//     // Convert to 'America/Montreal' timezone
//     const convertedDate = dateMoment.tz('America/Montreal');
//
//     // Format the date in the desired format
//     return convertedDate.format('YYYY-MM-DD HH:mm:ss');
// }


// export const timezoneConverter = (dateString) => {
//     // Interpret the original date as UTC
//     const dateInUTC = moment.utc(dateString);
//
//     // Convert to 'America/Montreal' timezone
//     const convertedDate = dateInUTC.tz('America/Montreal');
//
//     // Format and return the date
//     return convertedDate.format('YYYY-MM-DD HH:mm:ss');
// }
