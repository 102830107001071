// @flow

import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

import BackgroundImage from 'assets/background.png';
import Orca from 'assets/orca-sidemenu-bg.png';

const LoginPage = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
`;

LoginPage.Background = styled.div`
    width: 100%;
    background-image: url(${BackgroundImage});
    background-size: cover;
    transition: opacity 1000ms ease-out 0ms, transform 1000ms ease-out 0ms;
    ${(props) =>
        props.status === 'entered'
            ? `opacity: 1; transform: scale(1.0);`
            : `opacity: 0; transform: scale(1.5);`};

    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.2);
    }
`;

LoginPage.Input = styled.input`
    margin-top: 10px;
    width: 100%;
    font-family: OpenSans;

    .react-select__control {
        border-radius: 5px;
    }
    color: white;
    &:focus {
        outline: none;
    }
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${colors.offwhite};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${colors.offwhite};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${colors.offwhite};
    }
`;
LoginPage.ButtonContainer = styled.div`
    display: flex;
    align-items: center;

    .row {
        margin-right: 0px;
        margin-left: 0px;
        padding-top: 10px;
    }

`;

LoginPage.LoginButton = styled.button`
    opacity: ${(props) => (props.disabled ? '0.3;' : '1;')}
    transition: opacity 250ms ease-in-out 0s;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-size: 18px;
    color: ${colors.white};
    background-color: #67b552;
    width: 100%;
    height: 38px;
    cursor: pointer;
`;

LoginPage.InputContainer = styled.div`
    margin-top: 10px;
    width: 100%;
    font-family: OpenSans;

    .react-select__control {
        border-radius: 5px;
    }
`;

LoginPage.ForgotPassword = styled.button`
    font-size: 14px;
    font-family: OpenSans;
    color: ${colors.green4b};
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;


    &:hover {
        text-decoration: underline;
    }
`;

LoginPage.ForgotPasswordText = styled.div`
    margin-left: 5px;
`;

LoginPage.Icon = styled.img`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

LoginPage.ErrorMessage = styled.div`
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    color: ${colors.status.red};
    max-height: 50px;
    overflow: hidden;
`;
const AnimationPane = styled.div`
    position: absolute;
    z-index: 4;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const rotateVehicle = keyframes` 
  0% {transform: rotate(0deg);}
  25% {transform: rotate(90deg);}
  50% {transform: rotate(180deg);}
  75% {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
`;

AnimationPane.ImageContainer = styled.div`
    width: 35%;
    margin-right: 17%;
    position: relative;
    top: 0;
    left: 0;

    .radar {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .vehicle {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        animation: ${rotateVehicle} 8000ms ease-in-out 0s;
        animation-iteration-count: 10000;
    }

    .triangle1 {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }
`;

const SidePane = styled.div`
    display: ${(props) => props.display || 'flex'};
    flex-direction: column;
    justify-content: center;
    width: 33%;
    z-index: 100;
    margin-left: 6%;
    padding-right: 6%;
    position: absolute;
    top: 0;
    bottom: 0;
    @media (min-width: ${screen.small}) {
        width: 31%;
    }
`;
SidePane.ImageContainer = styled.div`
    padding-top: 3%;
    position: absolute;
    z-index: 5;
    margin-left: 6%;
    padding-right: 6%;
`

SidePane.Admin = styled.div`
    font-family: OpenSans;
    margin-bottom: 38px;
    font-size: 16px;
    color: ${colors.white};
`;

SidePane.DescriptionText = styled.div`
    color: ${colors.white};
    font-size: 34px;
    font-weight: 600;
    font-family: OpenSans;
    font-weight: normal;
`;

SidePane.Split = styled.div`
    height: 1px;
    background-color: ${colors.green82};
`;

SidePane.Text = styled.div`
    color: ${colors.green73};
    font-size: ${(props) => props.fontSize || '14px'};
`;

SidePane.InformationContainer = styled.div`
    height: 200px;
    display: flex;
`;

const Form = styled.form`
    flex: 2;
    margin-bottom: 20px;
`;

export { SidePane, LoginPage, Form, AnimationPane };
