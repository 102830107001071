// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

import StyledSelect from 'components/SelectField/styles';

export const ReportGeneratorContainer = styled.div`
    display: flex;
    margin-top: 10px;
    flex-direction: column;
`;

export const InputLabel = styled.div`
    font-size: 15px;
    font-weight: 600;
    font-family: OpenSans;
    margin: ${(props) => props.margin || '0'};
`;

export const GeofencingHistoryGenerator = styled.div`
    display: flex;
    flex-wrap: wrap;
    .geofenceReport{
        display: flex;
    }

    @media (min-width: ${screen.large}) {
        > div {
            margin-right: 30px;
             width: 100%;
            &:nth-child(4) {
                margin-right: 0;
                width: 230px;
            }
        }
    }
`;

GeofencingHistoryGenerator.Column = styled.div`
    display: block;
    width: 100%;

    > div {
        margin-bottom: 10px;
        > div {
            width: 100%;
        }
    }

    @media (min-width: ${screen.large}) {
        &:nth-child(1) {
            width: 260px;
        }
        &:nth-child(2) {
            width: 260px;
        }
        &:nth-child(3) {
            width: 260px;
        }

        > div {
            > div {
                width: inherit;
            }
        }
    }
`;

GeofencingHistoryGenerator.ColumnRep = styled.div`
    display: block;


    > div {
        margin-bottom: 10px;
        > div {
            width: 100%;
        }
    }

    @media (min-width: ${screen.large}) {
        &:nth-child(1) {
            width: 260px;
        }

        &:nth-child(3) {
            width: 260px;
        }
        &:nth-child(6) {
            width: 260px;
        }
        &:nth-child(7) {
            width: 260px;
        }

        > div {
            > div {
                width: inherit;
            }
        }
    }
`;

GeofencingHistoryGenerator.InputContainer = styled.div`
    margin-bottom: 10px;
    > div {
        width: calc(50% - 15px);
        float: left;

        &:nth-child(even) {
            margin-right: 0px;
        }
    }

    @media (min-width: ${screen.large}) {
        > div {
            margin-right: 30px;
        }
    }
`;

GeofencingHistoryGenerator.Grid = styled.div`
    display: block;
    grid-template-columns: ${(props) => props.col};
    grid-column-gap: ${(props) => props.gap}px;
    margin-bottom: 28px;

    @media (min-width: ${screen.small}) {
        display: grid;
    }
`;

GeofencingHistoryGenerator.GridInput = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.col};
    grid-column-gap: ${(props) => props.gap}px;
    margin: ${(props) => props.margin || '0'};
`;

GeofencingHistoryGenerator.ReportType = styled.div`
    display: block;

    @media (min-width: ${screen.large}) {
        display: flex;
    }
`;

GeofencingHistoryGenerator.Input = styled.div`
    width: 230px;
`;

GeofencingHistoryGenerator.SelectContainer = styled.div`
    ${StyledSelect} {
        --styled-select-input__padding: 0;
    }

    @media (min-width: ${screen.large}) {
        width: 230px;
    }
`;

GeofencingHistoryGenerator.ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    flex: 1;


    > div {
        margin-bottom: 10px;
    }

    @media (max-width: ${screen.large}) {
        > div {
            display: inline-block;
            width: auto;
            height: 40px;
            margin-right: 0;
        }
    }
`;

GeofencingHistoryGenerator.DateButton = styled.div`
    background-color: ${colors.green63};
    border-radius: 0;
    color: ${colors.white};
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // margin-right: 6px;
    padding: 11px 15px 11px 15px;
    cursor: pointer;
    font-size: 13px;

    @media (min-width: ${screen.large}) {
        display: inline-block;
        margin-right: 6px;
        height: 40px;
    }
`;

GeofencingHistoryGenerator.SubmitButton = styled.div`
    background-color: ${colors.green63};
    width: 150px !important;
    height: 40px;
    align-self: flex-end;
    margin-left: auto;
    font-family: OpenSans;
    cursor: pointer;
    color: ${colors.white};
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${screen.large}) {
        width: 150px;
    }
`;

GeofencingHistoryGenerator.Error = styled.div`
    font-weight: 500 !important;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DrivingReportGenerator = styled.div`
  .economieReport{
      display: flex;
  }
    .MoveV1Report{
        display: flex;
        width: 30%;
        div + div{
            margin-right: 30px;
        }
    }
    @media (min-width: ${screen.large}) {
        > div {

            &:nth-child(3) {
                margin-right: 0;
            }
        }
    }
`;

DrivingReportGenerator.Column = styled.div`
    display: block;

    > div {
        margin-bottom: 10px;
        > div {
            width: 100%;
        }
    }

    @media (min-width: ${screen.large}) {


        > div {
            > div {
                width: inherit;
            }
        }
    }
`;

DrivingReportGenerator.ColumnRep = styled.div`
    display: block;

    > div {
        margin-bottom: 10px;
        > div {
            width: 100%;
        }
    }

    @media (min-width: ${screen.large}) {

        &:nth-child(3) {
            width: 260px;
        }
        &:nth-child(5) {
            width: 260px;
        }
        &:nth-child(6) {
            width: 260px;
        }

        > div {
            > div {
                width: inherit;
            }
        }
    }
`;

DrivingReportGenerator.InputContainer = styled.div`
    margin-bottom: 10px;
    > div {
        width: calc(50% - 15px);
        float: left;

        &:nth-child(even) {
            margin-right: 0px;
        }
    }

    @media (min-width: ${screen.large}) {
        > div {
            margin-right: 30px;
        }
    }
`;

DrivingReportGenerator.Grid = styled.div`
    display: block;
    grid-template-columns: ${(props) => props.col};
    grid-column-gap: ${(props) => props.gap}px;
    margin-bottom: 28px;

    @media (min-width: ${screen.small}) {
        display: grid;
    }
`;

DrivingReportGenerator.GridInput = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.col};
    grid-column-gap: ${(props) => props.gap}px;
    margin: ${(props) => props.margin || '0'};
`;

DrivingReportGenerator.ReportType = styled.div`
    display: block;

    @media (min-width: ${screen.large}) {
        display: flex;
    }
`;

DrivingReportGenerator.Input = styled.div`
    width: 230px;
`;

DrivingReportGenerator.SelectContainer = styled.div`
    ${StyledSelect} {
        --styled-select-input__padding: 0;
    }

    @media (min-width: ${screen.large}) {
        width: 230px;
    }
`;

DrivingReportGenerator.ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    flex: 1;
    flex-wrap: wrap;

    > div {
        margin-bottom: 10px;
    }

    @media (max-width: ${screen.large}) {
        > div {
            display: inline-block;
            width: auto;
            height: 40px;
            margin-right: 0;
        }
    }
`;

DrivingReportGenerator.DateButton = styled.div`
    background-color: ${colors.green63};
    border-radius: 0;
    color: ${colors.white};
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // margin-right: 6px;
    padding: 11px 15px 11px 15px;
    cursor: pointer;
    font-size: 13px;

    @media (min-width: ${screen.large}) {
        display: inline-block;
        margin-right: 6px;
        height: 40px;
    }
`;

DrivingReportGenerator.SubmitButton = styled.div`
    background-color: ${colors.green63};
    width: 150px !important;
    height: 40px;
    align-self: flex-end;
    margin-left: auto;
    font-family: OpenSans;
    cursor: pointer;
    color: ${colors.white};
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${screen.large}) {
        width: 150px;
    }
`;

DrivingReportGenerator.Error = styled.div`
    font-weight: 500 !important;
    margin-top: 15px;
    margin-bottom: 15px;
`;
DrivingReportGenerator.DateError = styled.div`
    display: block;
    width: 100%;
    text-align:center;
    font-weight: 500 !important;
    margin-top: 10px;
    margin-bottom: 10px;

`;
export const Report = styled.div``;

Report.TextContainer = styled.div`
    display: block;
    align-items: center;
    align-self: center;
    margin-right: 35px;
    font-size: 13px;
    font-family: OpenSans;
    font-weight: 400;
    width: 100%;
    margin-bottom: 10px;

    @media (min-width: ${screen.large}) {
        display: flex;
        height: 30px;
        margin-bottom: 0px;
        width: 33.33%;
    }
`;

Report.Icon = styled.img`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    margin: ${(props) => props.margin || '0'};
`;

Report.ModifyReport = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${colors.green63};
    cursor: pointer;
    margin-left: auto;
    color: ${colors.white};

    & > img {
        position: absolute;
    }

    & > p {
        flex: 1;
        display: block;
        text-align: center;
    }

    @media (min-width: ${screen.large}) {
        width: 310px;

        & > img {
            position: static;
        }

        & > p {
            flex: 1;
            margin-left: 45px;
            display: flex;
            text-align: start;
        }
    }
`;

Report.Header = styled.div``;

Report.AnimationContainer = styled.div`
    height: 100%;

    & .generator-appear {
        height: 0;
    }

    & .generator-appear.generator-appear-active {
        height: 100%;
        transition: transform 500ms ease;
    }

    & .generator-enter {
        height: 0;
    }

    & .generator-enter.generator-enter-active {
        height: 100%;
        transition:height transition: transform 500ms ease;
    }

    & .generator-leave {
    height: 100%;
    }

    & .generator-leave.generator-leave-active {
        height: 0;
        transition:height 300ms ease-out;
    }
`;
