// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Header, Split } from 'styles/modal';

type Props = {
    headerTextId: ?string,
    customText: ?string,
};

function ModalHeader(props: Props) {
    return (
        <Header>
            <Header.Content>
                <FormattedMessage id={props.headerTextId} /> {props.customText}
            </Header.Content>
        </Header>
    );
}

export default ModalHeader;
