// @flow

import {
    receivedDriversSuccess,
    receivedDriversError,
    startLoading,
    dismissLoading,
    receivedDissmissedDriverError,
} from './actions';
import {
    retrieveDriversByVehicle,
    updateAuthorizationsByVehicleName,
    resetDriverCards,
    addDriver,
    retrieveDriversScoreCard,
    retrieveDrivers,
    deleteDriver,
    authorizeAll,
    retrieveAllDrivers,
    authorizeAllDrivers,
} from './resources';
import { closeModal } from 'services/Modal/thunks';
import { setClearMapEquipmentPopout } from 'services/Vehicle/thunks';
import type { ImmutableMap } from 'types';
import {parseJWT} from "../../helpers";

/**
 * Fetch Drivers from vehicle.
 */
export const getDriversByVehicle = (moduleNumber: string, token: string, page: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return retrieveDriversByVehicle(moduleNumber, token, page)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedDriversError());
            }
            dispatch(receivedDriversSuccess(response.data));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedDriversError());
        });
};

/**
 * Update driver authorizations by vehicle name.
 */
export const setAuthorizationsByVehicleName = (data: Driver, token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return updateAuthorizationsByVehicleName(data, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                /* dispatch(receivedDriversError());*/
                return false;
            }
            dispatch(receivedDissmissedDriverError());
            return true;
        })
        .catch((error) => {
            /* dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedDriversError());*/
            return false;
        });
};

/**
 * reset Drivers
 */
export const resetDrivers = (moduleNumber: string, token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return resetDriverCards(moduleNumber, token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedDriversError());
            }
            dispatch(dismissLoading());
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(setClearMapEquipmentPopout());
            dispatch(receivedDriversError());
        });
};

export const dismissDriverError = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedDissmissedDriverError());

/**
 * Add Drives.
 */
export const addDrivers = (data: any, token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return addDriver(data, token)
        .then((response: Object) => {
            dispatch(receivedDissmissedDriverError());
            return response;
        })
        .catch((error) => {
            return error;
        });
};

/**
 * Fetch All Drivers.
 */
export const getAllDrivers = (token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return retrieveAllDrivers(token)
        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedDriversError());
            }
            return response.data
        })
        .catch((error) => {
            return false
        });
};


/**
 * Fetch One Drivers.
 */
export const removeDriver = (data,token ) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return deleteDriver(data,token)

        .then((response: Object) => {
            if (response.status && typeof response.status === 'string') {
                dispatch(receivedDriversError());
            }
            return true
        })
        .catch((error) => {
            return false
        });
};

/**
 *
 * @param {*} data
 */
export const getDriverScoreCard = (data: *) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    const jwt = parseJWT(token);
    const branchId = jwt['https://geothentic.com/branch_id'];

    return retrieveDriversScoreCard(token, locale, branchId, data)
        .then((response: Object) => {
            return response;
        })
        .catch((error) => {
            return false
        });
};

/**
 * Add driver authorization to all vehicle .
 */
export const authorizeDriver = (data: any, token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return authorizeAll(data, token)
        .then((response: Object) => {
            dispatch(receivedDissmissedDriverError());
            return response;
        })
        .catch((error) => {
            return error;
        });
};
/**
 * Add driver authorization to all vehicle .
 */
export const authorizeDriverToAllVehicles = (data: any, token: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    dispatch(startLoading());
    return authorizeAllDrivers(data, token)
        .then((response: Object) => {
            dispatch(receivedDissmissedDriverError());
            return response;
        })
        .catch((error) => {
            return error;
        });
};
