// @flow

/**
 * HOC component for DashBoardData component.
 * This component will wrap a list item to the span of tables columns and render the specified component.
 */

import React from 'react';

import { Dashboard } from 'styles/dashboard';

type Props = {
    rowSpan: number,
    data: *,
    component: *,
};

type State = {};

class DashboardDataComponent extends React.Component<Props, State> {
    render() {
        const { rowSpan, component, ...componentProps } = this.props;
        return (
            <Dashboard.GridItem columnSpan={rowSpan}>
                {React.createElement(component, componentProps)}
            </Dashboard.GridItem>
        );
    }
}

export default DashboardDataComponent;
