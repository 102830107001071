// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const User = styled.div``;

User.Item = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;

User.GroupButtons = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
`;

User.UserOptions = styled.div`
    position: initial;
`;

User.GroupButtonSpan = styled.span`
    font-size: 12px;
    margin: 5px;
    white-space: nowrap;
`;

User.UserOptionsDropdown = styled.div`
    position: absolute;
    color: black;
    display: flex;
    z-index: 2;
    top: 48px;
    right: 0;
    background-color: ${colors.green62};
    flex-direction: column;
    min-width: 150px;
    padding: 0 20px;
`;

User.DropdownItem = styled.div`
    flex: 1;
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0 10px;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 400;
    border-bottom: ${colors.white};
    color: ${colors.white};
    text-transform: uppercase;
    border-bottom: 1px solid ${colors.white};
    cursor: pointer;

    &:hover {
        background-color: ${({ backgroundColorHovered }) =>
            backgroundColorHovered || colors.green62};
        ${({ backgroundColorHovered }) =>
            backgroundColorHovered ? `border: 1px solid ${backgroundColorHovered};` : ''}

        color: ${({ textColorHovered }) =>
            textColorHovered || colors.white};
        ${({ textColorHovered }) =>
            textColorHovered ? `font-weight: 600;` : ''}
    }

    &:last-child {
        border: none;
    }
`;
