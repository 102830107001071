// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';
import { Link } from 'react-router-dom';

const Close = styled.div.attrs({
    offset: (props) => (props.offset ? `calc(${props.offset}px + 350px)` : '350px'),
    position: ({ mapPage }) => (mapPage ? 'top: 65px' : 'bottom: 50%'),
})`
    display: flex;
    cursor: pointer;
    width: 60px;
    height: 80px;
    background-color: ${(props) => props.backgroundColor || colors.black};
    position: absolute;
    top: 0px;
    transition: all 250ms ease-in-out 0s;
    ${({ position }) => position};
    left: ${(props) => props.leftOffset || 'auto'};
    right: ${(props) => props.rightOffset || '0'};
    z-index: ${(props) => props.zIndex || 3};

    svg {
        width: 10px;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;

        path {
            fill: #fff;
        }
    }

    &:hover {
        opacity: 1;
    }

    @media (min-width: ${screen.small}) {
        opacity: 0.8;
        width: ${(props) => props.size || 60}px;
        height: ${(props) => props.size || 60}px;
        top: ${(props) => props.top || 80}px;
        left: ${(props) => props.leftOffset || '100%'};
        right: ${(props) => props.rightOffset || 'auto'};
    }
`;

const Open = styled.div.attrs({
    offset: (props) => (props.offset ? `calc(${props.offset}px + 350px)` : '350px'),
    position: ({ mapPage }) => (mapPage ? 'top: 65px' : 'bottom: 50%'),
})`
    display: flex;
    cursor: pointer;
    width: ${(props) => props.size || 60}px;
    height: ${(props) => props.size || 60}px;
    background-color: ${(props) => props.backgroundColor || colors.black};
    opacity: 0.8;
    position: absolute;
    top: ${(props) => props.top || 80}px;
    right: ${(props) => props.rightOffset || '0'};
    left: ${(props) => props.leftOffset || '100%'};
    transition: all 250ms ease-in-out 0s;
    ${({ position }) => position};
    z-index: ${(props) => props.zIndex || 3};
    svg {
        width: 10px;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;

        path {
            fill: #fff;
        }
    }

    &:hover {
        opacity: 1;
    }
`;

export { Close, Open };
