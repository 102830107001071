// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { fromJS } from 'immutable';

import Fuse from 'fuse.js';

import { selectUser } from 'services/Authentication/selectors';
import { selectLocale } from 'services/Language/selectors';
import {
    selectOrganizations,
    selectOrganizationsPagination,
    selectOrganizationsLoading,
    selectOrganizationBranches,
    selectOrganization,
} from 'services/Organization/selectors';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';

import {
    fetchOrganizations,
    getOrganizationData,
    createOrganization,
} from 'services/Organization/thunks';
import { toggleOrganizationModal, toggleUserModal } from 'services/Modal/thunks';

import OrganizationListItem from 'components/OrganizationListItem';
import SearchInput from 'components/SearchInput';
import OutlineButton from 'components/OutlineButton';
import DashboardDataTable from 'components/DashboardDataTable';
import SelectField from 'components/SelectField';
import Modal from 'components/Modals';
import CreateModal from 'components/Modals/CreateOrganizationModal';
import Loader from 'components/Loader';

import DataListHeaderContainer from 'containers/DataListHeaderContainer';

import BranchList from './styles';
import { TableWrapper } from '../../styles/dashboard';
import colors from 'styles/colors';

import CaretDownImage from 'assets/caret-down.png';
import CaretUpImage from 'assets/caret-up.png';

import { CREATE_ORGANIZAITON, MODAL_TYPE } from 'services/Modal/actions';

import type {
    ImmutableList,
    SearchResultsType,
    OrganizationListType,
    OrganizationType,
} from 'types';

type Props = {
    organizations: ?OrganizationListType,
    isOpen: boolean,
};
type State = {
    filteredOrganizations: ImmutableList<string>,
    query: string,
};

export class OrganizationListComponent extends React.Component<Props, State> {
    state = {
        filteredOrganizations: fromJS([]),
        query: '',
    };

    componentDidMount() {
        if (
            !this.props.organization.get('branches') ||
            this.props.organization.get('branches').length === 0
        ) {
            this.props.getOrganizationData(
                this.props.match.params.organizationId,
                this.props.user.get('token') || localStorage.getItem('api_token')
            );
        }
    }

    timeout = null;

    /**
     * Accepts a sortOrder string used to determin which colum we will order by.
     * On order, we remove existing order conditions and apply the currently selected one.
     */
    handleOrderOrganizations = (sortColumn: string) => () => {
        let ascOrder = sortColumn;

        if (this.state.ascOrder === sortColumn) {
            ascOrder = '';
        }

        this.setState({
            ascOrder,
        });
    };

    handleOnClickAllLocations = () => {
        console.log('clicked All Locations');
    };

    handleSearchResults = (results: SearchResultsType) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.fetchOrganizations(1, results.query);
        }, 250);
    };

    handleOnClickOrganization = (id: number) => {
        this.props.getOrganizationData(id);
        this.props.history.push(`/${this.props.intl.locale}/interne/orgs/${id}`);
    };

    handleOnClickCreateOrganization = () => {
        if (!this.props.showModal) {
            this.props.toggleOrganizationModal(MODAL_TYPE.ORGANIZATION, CREATE_ORGANIZAITON);
        }
    };

    handleOnSubmitOrganizationModal = (organization: OrganizationType) => {
        this.props.createOrganization({
            ...organization,
            parentId: Number(this.props.match.params.organizationId),
        });
    };

    handleViewMoreOrganizations = () => {
        const page = this.props.organizationsPagination.get('currentPage') || 0;
        this.props.fetchOrganizations(page + 1);
    };

    handleScrollOrganizationsListPage = (e: Event) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        if (bottom) {
            const page = this.props.organizationsPagination.get('currentPage') + 1;
            this.props.fetchOrganizations(page);
        }
    };

    getCaretSource = (column: string) => {
        if (this.state.ascOrder === column) {
            return CaretUpImage;
        }

        return CaretDownImage;
    };

    render() {
        let tableData = this.props.organization.get('branches');
        if (this.state.query && this.state.filteredOrganizations.size >= 0) {
            tableData = this.state.filteredOrganizations;
        }

        const organizationsHeaders = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'name',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Location',
                }),
                key: 'address.province+address.city',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.ActiveEquipment',
                }),
                key: 'equipmentCount',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.ActiveUsers',
                }),
                key: 'usersCount',
            },
        ];

        return (
            <BranchList
                isOpen={this.props.isOpen}
                onScroll={this.handleScrollOrganizationsListPage}
            >
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.HomePage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.BranchListPage.Head.Content',
                        })}
                    />
                </Helmet>
                <DataListHeaderContainer
                    title={'Branches'}
                    name={this.props.organization.get('name')}
                    actionButtonText={'containers.Dashboard.CreateNew'}
                    onClickActionButton={this.handleOnClickCreateOrganization}
                />
                <BranchList.Split height={'1px'} color={colors.grayDD} />

                <BranchList.FilterContainer>
                    <BranchList.SearchContainer>
                        <SearchInput
                            onSearchResults={this.handleSearchResults}
                            searchKeys={['branchName', 'contact']}
                            onChange={this.handleOnChangeSearchInput}
                            borderColor={colors.grayDD}
                            data={this.props.organizations}
                        />
                    </BranchList.SearchContainer>
                </BranchList.FilterContainer>
                <Loader loading={this.props.loading} />
                <TableWrapper>
                    <DashboardDataTable
                        id={'id'}
                        columns={organizationsHeaders}
                        tableData={tableData}
                        onClickDataItem={this.handleOnClickOrganization}
                        onClickViewMore={this.handleViewMoreOrganizations}
                        paginated={this.props.organizationsPagination}
                        loading={this.props.loading}
                        list
                    />
                </TableWrapper>
                <Modal
                    onScroll={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <CreateModal
                        data={this.props.organizations}
                        header={'components.Modals.Create.Branch'}
                        onSubmit={this.handleOnSubmitOrganizationModal}
                    />
                </Modal>
            </BranchList>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    user: selectUser(),
    locale: selectLocale(),
    organization: selectOrganization(),
    organizationsPagination: selectOrganizationsPagination(),
    branches: selectOrganizationBranches(),
    loading: selectOrganizationsLoading(),
    isOpen: selectIsSideMenuOpen(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            fetchOrganizations,
            getOrganizationData,
            toggleOrganizationModal,
            createOrganization,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(OrganizationListComponent))
);
