// @flow

export const LOADING = 'app/services/PredefinedRoutes/LOADING';
export const DISMISS_LOADING = 'app/services/PredefinedRoutes/DISMISS_LOADING';
export const GET_PREDEFINED_ROUTES_SUCCESS = 'app/services/PredefinedRoutes/GET_PREDEFINED_ROUTES_SUCCESS';
export const GET_PREDEFINED_ROUTES_ERROR = 'app/services/PredefinedRoutes/GET_PREDEFINED_ROUTES_ERROR';
export const SET_SELECTED_PDR_SUCCESS = 'app/services/PredefinedRoutes/SET_SELECTED_PDR_SUCCESS';
export const SET_PDR_ADD_MODE_SUCCESS = 'app/services/PredefinedRoutes/SET_PDR_ADD_MODE_SUCCESS';
export const SET_IS_ROUTE_MATCHING_SUCCESS = 'app/services/PredefinedRoutes/SET_IS_ROUTE_MATCHING_SUCCESS';
export const GET_PREDEFINED_ROUTES_MATCHING_SUCCESS = 'app/services/PredefinedRoutes/GET_PREDEFINED_ROUTES_MATCHING_SUCCESS';
export const GET_PREDEFINED_ROUTES_MATCHING_ERROR = 'app/services/PredefinedRoutes/GET_PREDEFINED_ROUTES_MATCHING_ERROR';
export const TOGGLE_SHOW_PDR = 'app/services/PredefinedRoutes/TOGGLE_SHOW_PDR'

export const receivedPredefinedroutesSuccess = (predefinedroutes): GenericActionType => ({
    type: GET_PREDEFINED_ROUTES_SUCCESS,
    predefinedroutes,
});

export const receivedSelectedPDRArraySuccess = (selectedPDR): GenericActionType => ({
    type: SET_SELECTED_PDR_SUCCESS,
    selectedPDR,
});

export const receivedSetIsAddPDRModeSuccess = (isAddPDR): GenericActionType => ({
    type: SET_PDR_ADD_MODE_SUCCESS,
    isAddPDR,
});

export const receivedSetIsRouteMatchingSuccess = (): GenericActionType => ({
    type: SET_IS_ROUTE_MATCHING_SUCCESS,
});

export const receivedPredefinedroutesError = (): GenericActionType => ({
    type: GET_PREDEFINED_ROUTES_ERROR,
});

export const receivedPredefinedroutesMatchingError = (): GenericActionType => ({
    type: GET_PREDEFINED_ROUTES_MATCHING_ERROR,
});

export const receivedPredefinedroutesMatchingSuccess = (matching): GenericActionType => ({
    type: GET_PREDEFINED_ROUTES_MATCHING_SUCCESS,
    matching,
});

export const startLoading = (): GenericActionType => ({
    type: LOADING,
});

export const dismissLoading = (): GenericActionType => ({
    type: DISMISS_LOADING,
});

export const receivedToggleShowPDR = (): GenericActionType => ({
    type: TOGGLE_SHOW_PDR,
});