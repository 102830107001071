// @flow

import type { DriverType } from 'types';

export const GET_DRIVERS_SUCCESS = 'app/services/Vehicle/GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_ERROR = 'app/services/Vehicle/GET_DRIVERS_ERROR';
export const LOADING_DRIVERS = 'app/services/Vehicles/LOADING_DRIVERS';
export const DISMISS_DRIVER_ERROR = 'app/services/Config/DISMISS_CONFIG_ERROR';
export const DISMISS_DRIVER_LOADING = 'app/services/Config/DISMISS_DRIVER_LOADING';

export const receivedDriversSuccess = (drivers): GenericActionType => ({
    type: GET_DRIVERS_SUCCESS,
    drivers,
});

export const receivedDriversError = (): GenericActionType => ({
    type: GET_DRIVERS_ERROR,
});

export const startLoading = (): GenericActionType => ({
    type: LOADING_DRIVERS,
});

export const dismissLoading = (): GenericActionType => ({
    type: DISMISS_DRIVER_LOADING,
});

export const receivedDissmissedDriverError = (): GenericActionType => ({
    type: DISMISS_DRIVER_ERROR,
});
