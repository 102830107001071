// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */

const selectMaintenanceState = () => (state) => state.get('maintenance');

const selectMaintenanceAlarmState = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('listOfAlarms'));

const selectMaintenanceVehHistoryState = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('selectedAlarms'));

const selectMaintenanceErrorState = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('maintenanceError'));

const selectVehicleState = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('selectedVehicle'));

const selectConfigMaintenanceState = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('configInformation'));

const selectVehicleAlarms = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('selectedVehicleAlarms'));

const selectConfigAlarms = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('configurationAlarms'));

const selectReinitializeAlarms = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('reinitializeAlarms'));

const selectConfigurationEmails = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('configurationEmails'));

const selectVehicleMaintMsg = () =>
    createSelector(selectMaintenanceState(), (substate) => substate.get('selectedVehicleMaintMsg'));

export {
    selectMaintenanceAlarmState,
    selectMaintenanceVehHistoryState,
    selectMaintenanceErrorState,
    selectVehicleState,
    selectConfigMaintenanceState,
    selectVehicleAlarms,
    selectConfigAlarms,
    selectReinitializeAlarms,
    selectConfigurationEmails,
    selectVehicleMaintMsg,
};
