// @flow

import { injectGlobal } from 'styled-components';
import './fonts';

/* eslint-disable flowtype/no-unused-expressions, no-unused-expressions */
injectGlobal`
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        letter-spacing: initial;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        overflow: hidden;
    }

    body.fontLoaded {
        font-family: 'Ubuntu', sans-serif;
    }

    .ant-tooltip-inner {
        width: max-content;
        white-space: pre-line;
    }

    #app {
        min-height: 100%;
        min-width: 100%;
        display: flex;
        flex: 1;
    }
`;
