import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DriverItem from './styles';
import {
    Input,
    Popconfirm,
    Table,
    Modal,
    Form,
    Space,
    Radio,
    notification,
    DatePicker,
    Divider,
} from 'antd';
import { SearchInputComponent } from '../../../components/SearchInput';
import {
    selectOrganization,
    selectOrganizationNotFormating,
} from '../../../services/Organization/selectors';
import {
    addDrivers,
    getAllDrivers,
    removeDriver,
    authorizeDriver,
    authorizeDriverToAllVehicles
} from '../../../services/Driver/thunks';
import moment from 'moment';
import { useVT } from 'virtualizedtableforantd4';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { fromJS } from 'immutable';
import type { SearchResultsTypes } from '../../../types';
import { getBranchData } from '../../../services/Organization/thunks';
import { ExcelRenderer } from 'react-excel-renderer';
import InputFileComponent from '../../../components/InputFileComponent';
import * as XLSX from 'xlsx';
import {selectDrivers} from "../../../services/Driver/selectors";
const Driver = (props) => {
    const [driversList, setDriversList] = useState([]);
    const [vt] = useVT(() => ({ debug: false, scroll: { y: 500 } }), []);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedDates, setSelectedDates] = useState([moment(), moment()]);
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [deployperiodModal, setdeployperiodModal] = useState(false);
    const [selectedDriverCard, setSelectedDriverCard] = useState([]);
    const [searchResults, setSearchResults] = useState(fromJS([]));
    const [driverData, setDriverData] = useState(fromJS([]));
    const [searchQuery, setSearchQuery] = useState('');
    const [newDriverName, setNewDriverName] = useState(null);
    const [newDriverCard, setNewDriverCard] = useState(null);
    const [api_token, setApi_token] = useState(null);
    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchType, setsearchType] = useState('name');
    const [excelDrivers, setExcelDrivers] = useState([]);
    const { RangePicker } = DatePicker;
    const templateDrivers = [['Étienne Gascon', '175-45482']];

    const { getAllDrivers, removeDriver, addDrivers, getBranchData, authorizeDriver,authorizeDriverToAllVehicles } = props;
    const [addDriverModalVisible, setAddDriverModalVisible] = useState(false);
    const [dates, setDates] = useState([moment(), ""]);
    const [value, setValue] = useState(null);
    const [periodStart, setPeriodStart] = useState(moment(dates[0]).format('YYYY-MM-DD'));
    const [periodEnd, setPeriodEnd] = useState('');
    const [searchPlaceholder, setSearchPlaceholder] = useState(
        props.intl.formatMessage({
            id: 'components.Driver.searchDriverName',
        })
    );
    const [api, contextHolder] = notification.useNotification();

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, ""]);
        }
        if (dates) {
            if(dates[0] && dates[1] === ""){
                setPeriodStart(moment(dates[0]).format('YYYY-MM-DD'));
                setPeriodEnd("");

            }else {
                setPeriodStart(moment(dates[0]).format('YYYY-MM-DD'));
                setPeriodEnd(moment(dates[1]).format('YYYY-MM-DD'));
            }

        }
    };

    useEffect(
        () => {
            if (api_token) {

                getAllDrivers(api_token).then((response) => {
                    const tab = response.map((driver, index) => ({
                        data: { driverName: driver.driver_name, driverCard: driver.driver_card },
                        index: index,
                        // vehicles: driver.authorizations,
                        id: driver.driver_card,
                        name: driver.driver_name,
                    }));
                    setDriversList(tab);
                    setDriverData(tab);
                    if (searchQuery.trim() !== '') {
                        setDriversList(searchResults.toJS());
                    }
                });
            }
        },
        [searchQuery, searchResults, api_token, getAllDrivers]
    );

    useEffect(
        () => {
            getBranchData().then((res) => {
                setApi_token(props.organizations.driverApiKey);
            });
        },
        [props.organizations.driverApiKey, getBranchData]
    );

    useEffect(
        () => {
            if (searchQuery.trim() !== '') {
                setDriversList(searchResults.toJS());
            }
        },
        [searchQuery, searchResults]
    );

    const driverNotif = (placement, response, status) => {
        if (status == 200) {
            api.success({
                message: `Success`,
                description: response,
                placement,
            });
        } else {
            api.warning({
                message: `!Oops`,
                description: response,
                placement,
            });
        }
    };
    const onChangeSearchType = (e: RadioChangeEvent) => {
        setsearchType(e.target.value);

        e.target.value === 'name'
            ? setSearchPlaceholder(
                props.intl.formatMessage({
                    id: 'components.Driver.searchDriverName',
                })
            )
            : setSearchPlaceholder(
                props.intl.formatMessage({
                    id: 'components.Driver.searchDriverCard',
                })
            );
    };

    const showAddDriverModal = () => {
        setAddDriverModalVisible(true);
    };
    const deployDriver = () => {
        if (selectedDrivers) {
            const data = {
                driver_card: selectedDriverCard,
                start_date: periodStart,
                end_date: periodEnd,
            };
            authorizeDriverToAllVehicles(data, api_token).then((response) => {
                driverNotif('top', response.data.message, response.status);
            });
            setdeployperiodModal(false);
        }
    };


    const handleAddDriverModalOk = () => {
        handlAddDriver();

    };
    const handledeployCancel = () => {
        setdeployperiodModal(false);
    };
    const handledeployOK = () => {
        setdeployperiodModal(true);
    };
    const handleAddDriverModalCancel = () => {
        setAddDriverModalVisible(false);
    };
    const handlDeleteDriver = (id) => {
        removeDriver(id, api_token).then((response) => {
            const newData = driversList.filter((item) => item.id !== id);
            setDriversList(newData);
            api.success({
                message:  props.intl.formatMessage({
                    id: 'components.AP.Success.Header',
                }),
                description: response,
                placement: 'top',
            });
        });
    };

    const onChangeDriverNameInput = (event) => {
        setNewDriverName(event.target.value);
    };

    const onChangeDriverCardInput = (event) => {
        setNewDriverCard(event.target.value);
    };
    const onSelectChange = (dates) => {
        setSelectedDates(dates);
    };


    const handlAddDriver = () => {

        if (newDriverName && newDriverCard) {
            const driverExists = driversList.some(driver => driver.data.driverCard === newDriverCard);
            if (driverExists) {
                notification.warning({
                    message: props.intl.formatMessage({
                        id: 'components.Driver.Existing Driver',
                    }) ,
                    placement: 'top',
                    duration: 3,
                });
            }
            else{
                const newDriver = [{ driver_name: newDriverName, driver_card: newDriverCard }];
                addDrivers({ drivers: newDriver }, api_token).then((response) => {
                    const updatedDriversList = [
                        ...driversList,
                        {
                            data: {
                                driverName: newDriver.driverName,
                                driverCard: newDriver.driverCard,
                            },
                            index: driversList.length,
                            //vehicles: response.data.authorizations,
                            id: response.data.driver_card,
                            name: response.data.driver_name,
                        },
                    ];
                    setDriversList(updatedDriversList);
                    setNewDriverName(null);
                    setNewDriverCard(null);
                    driverNotif('top', response.data.message, response.status);
                    setAddDriverModalVisible(false);
                });
            }

        }
    };

    const handleSaveInput = () => {
        if (excelDrivers.length > 0) {
            addDrivers({ drivers: excelDrivers }, api_token).then(() => {
                getAllDrivers(api_token).then((response) => {
                    const tab = response.data.map((driver, index) => ({
                        data: {driverName: driver.driver_name, driverCard: driver.driver_card },
                        index: index,
                        // vehicles: driver.authorizations,
                        id: driver.driver_card,
                        name: driver.driver_name,
                    }));
                    setDriversList(tab);
                    setDriverData(tab);
                });
            });
            setExcelDrivers([]);
            handleRemove();
        }
    };
    const handleSearchDriverList = (results: SearchResultsTypes) => {
        setSearchResults(results.results);
        setSearchQuery(results.query);
    };

    const columns = [
        {
            title: props.intl.formatMessage({
                id: 'components.Driver.DriverName',
            }),
            dataIndex: 'data',
            key: 'driverName',
            render: (value) => {
                return value.driverName;
            },
        },
        {
            title: props.intl.formatMessage({
                id: 'components.Driver.DriverCard',
            }),
            dataIndex: 'data',
            key: 'driverCard',
            render: (value) => {
                return value.driverCard;
            },
        },
        {
            key: 'action',
            width: '40px',
            render: (value, record, index) => {
                return (
                    <div>
                        <Popconfirm
                            placement="bottom"
                            title={props.intl.formatMessage({ id: 'components.Driver.DeleteDriver' })}
                            onConfirm={() => handlDeleteDriver(record.id)}
                            onCancel={handlDeleteDriver}
                            okText={props.intl.formatMessage({ id: 'components.ap.delete.Confirmation.Yes' })}
                            cancelText={props.intl.formatMessage({ id: 'components.ap.delete.Confirmation.No' })}
                        >
                            <a>
                                <i className="far fa-trash-alt" aria-hidden="true" />
                            </a>
                        </Popconfirm>
                    </div>
                )
            },
        },
    ];
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 13,
        },
    }));
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const tab = []
            const driverCards = selectedRows.map((driverCard)=>{
                tab.push(driverCard.id)
            })
            setSelectedDriverCard(tab)
            setSelectedDrivers([...selectedRows]);
        },
    };
    const fileHandler = (event) => {
        const fileObj = event.target.files[0];

        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                const newCols = resp.cols;
                const newRows = resp.rows.slice(1);

                const newExcelDrivers = newRows.map((row) => ({
                    driverName: row[0],
                    driverCard: row[1],
                }));
                setCols(newCols);
                setRows(newRows);
                setExcelDrivers(newExcelDrivers);
            }
        });
    };
    const createTemplateXLSXFile = (file) => {
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Driver ID Template',
            Subject: 'Template',
            Author: 'Geothentic Inc.',
            CreatedDate: new Date(),
        };
        wb.SheetNames.push('Template Sheet');
        const ws_data = [
            [
                props.intl.formatMessage({
                    id: 'components.Driver.name',
                }),
                props.intl.formatMessage({
                    id: 'components.Driver.id',
                }),
            ],
            ...file,
        ];
        wb.Sheets['Template Sheet'] = XLSX.utils.aoa_to_sheet(ws_data);
        return XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };
    const handleDownloadButton = () => {
        const FileSaver = require('file-saver');
        FileSaver.saveAs(
            new Blob([s2ab(createTemplateXLSXFile(templateDrivers))], {
                type: 'application/octet-stream',
            }),
            `${props.intl.formatMessage({
                id: 'components.Driver.Template',
            })}.xlsx`
        );
    };

    const handleRemove = () => {
        setExcelDrivers([]);
    };
    return (
        <DriverItem>
            <DriverItem.Header>
                {props.intl.formatMessage({
                    id: 'components.Driver.Title',
                })}
            </DriverItem.Header>

            <hr />
            <div className="centerAligned">
                <div className="tableWrapper">
                    {contextHolder}
                    <div className="searchContainer">
                        <div className="search_bar">
                            <SearchInputComponent
                                className="searchInput"
                                onSearchResults={handleSearchDriverList}
                                searchKeys={[searchType]}
                                data={fromJS(driverData)}
                                padding={'0 8px 0 10px'}
                                fleetOverviewInput={false}
                                placeholder={searchPlaceholder}
                            />
                            <Radio.Group onChange={onChangeSearchType} value={searchType}>
                                <Radio value={'name'}>
                                    {props.intl.formatMessage({
                                        id: 'components.Driver.DriverName',
                                    })}
                                </Radio>
                                <Radio value={'id'}>
                                    {props.intl.formatMessage({
                                        id: 'components.Driver.DriverCard',
                                    })}
                                </Radio>
                            </Radio.Group>
                        </div>

                        <div className="actions">
                            <Button
                                className="deployButton"
                                disabled={selectedDrivers.length === 0}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="outlined"
                                onClick={handledeployOK}
                            >
                                {props.intl.formatMessage({
                                    id: 'components.Driver.Deploy',
                                })}
                            </Button>

                            <Button
                                className="deployButton"
                                onClick={showAddDriverModal}
                                id="basic-button"
                                variant="outlined"
                            >
                                {props.intl.formatMessage({
                                    id: 'components.Driver.AddDriver',
                                })}
                            </Button>
                        </div>
                    </div>
                    <Modal
                        className="deployModal"
                        title={props.intl.formatMessage({
                            id: 'components.Driver.periodActivation',
                        })}
                        open={deployperiodModal}
                        onOk={deployDriver}
                        onCancel={handledeployCancel}
                    >
                        <RangePicker
                            value={dates || value}
                            onCalendarChange={(val) => {
                                setDates(val);
                            }}
                            onChange={(val) => {
                                setValue(val);
                            }}
                            onOpenChange={onOpenChange}
                            // changeOnBlur
                            allowEmpty={[false,true]}
                        />
                    </Modal>
                    <Divider />
                    <Table
                        columns={columns}
                        dataSource={driversList}
                        pagination={false}
                        scroll={{ y: 400 }}
                        size="small"
                        rowKey={(record) => record.index}
                        components={vt}
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                    />
                </div>
            </div>
            <hr />
            <div className="inputFileWrapper">
                <div onChange={fileHandler}>
                    <InputFileComponent onRemove={handleRemove} onSave={handleSaveInput} />
                </div>

                <Button type="Link" className="downloadButton" onClick={handleDownloadButton}>
                    <FormattedMessage id={'components.Driver.fileTemplateButton'} />
                </Button>
            </div>
            <Modal
                title={props.intl.formatMessage({
                    id: 'components.Driver.AddDriverTitle',
                })}
                open={addDriverModalVisible}
                onOk={handleAddDriverModalOk}
                onCancel={handleAddDriverModalCancel}
                cancelText={props.intl.formatMessage({
                    id: 'components.Driver.Cancel',
                })}
            >
                <Form layout="vertical">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Form.Item
                            label={props.intl.formatMessage({
                                id: 'components.Driver.Add.DriverName',
                            })}
                        >
                            <Input
                                value={newDriverName}
                                onChange={onChangeDriverNameInput}
                                placeholder={props.intl.formatMessage({
                                    id: 'components.Driver.Add.placeholder.DriverName',
                                })}
                            />
                        </Form.Item>
                        <Form.Item
                            label={props.intl.formatMessage({
                                id: 'components.Driver.Add.DriverCard',
                            })}
                        >
                            <Input
                                value={newDriverCard}
                                onChange={onChangeDriverCardInput}
                                placeholder={props.intl.formatMessage({
                                    id: 'components.Driver.Add.placeholder.DriverCard',
                                })}
                            />
                        </Form.Item>
                    </Space>
                </Form>
            </Modal>
        </DriverItem>
    );
};

const mapStateToProps: Object = createStructuredSelector({
    selectedbranch: selectOrganization(),
    organizations: selectOrganizationNotFormating(),
    drivers: selectDrivers(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getAllDrivers,
            removeDriver,
            addDrivers,
            getBranchData,
            authorizeDriver,
            authorizeDriverToAllVehicles
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Driver))
);
