// @flow

import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS,toJS } from 'immutable';
import {
    GET_ORGANIZATIONS_SUCCESS,
    GET_ORGANIZATION_DATA_SUCCESS,
    GET_ORGANIZATION_DATA_SUCCESS_NOT_FORMAT,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_EXTRA_USERS_SUCCESS,
    GET_ORGANIZATION_EXTRA_USERS_ERROR,
    GET_ORGANIZATION_USER_SUCCESS,
    EDIT_ORGANIZATION_SUCCESS,
    DELETE_ORGANIZATION_SUCCESS,
    DELETE_ORGANIZATION_USER_SUCCESS,
    DELETE_ORGANIZATION_EQUIPMENT,
    START_LOADING_ORGANIZATIONS,
    CREATE_ORGANIZATION_ERROR,
} from './actions';

import { GET_BRANCH_SUCCESS } from 'services/Branch/actions';

import {
    ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS,
    EDIT_EQUIPMENT_ORGANIZATION_SUCCESS,
} from 'services/Equipment/actions';

import { EDIT_USER_SUCCESS } from 'services/User/actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    organizations: [],
    selectedOrganization: {},
    selectedOrganizationNotFormat: {},
    userErrors: {},
    paginated: {},
    loading: false,
    error: {},
});

function organizationServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case CREATE_ORGANIZATION_ERROR:
            return state.set('error', action.payload.error);
        case GET_ORGANIZATIONS_SUCCESS: {
            const organizations =
                action.response.meta.currentPage > 1
                    ? state.get('organizations').concat(fromJS(action.response.branches))
                    : fromJS(action.response.branches);
            return state
                .set('organizations', organizations)
                .set(
                    'paginated',
                    fromJS({
                        currentPage: action.response.meta.currentPage,
                        lastPage: action.response.meta.lastPage,
                    })
                )
                .set('loading', false);
        }
        case GET_ORGANIZATION_DATA_SUCCESS: {
            const { organization, ...data } = action.organization;

            if (data.driverApiKey) {
                sessionStorage.setItem('driverApiKey', data.driverApiKey);
            }

            if (data.additionalMapData) {
                sessionStorage.setItem('additionalMapData', JSON.stringify(data.additionalMapData));
            }
            return state
                .set('selectedOrganization', fromJS({ ...organization, ...data }))
                .set('loading', false);
        }
        //Get another 50 extra users
        case GET_ORGANIZATION_EXTRA_USERS_SUCCESS:{
            const currentUsersList = state.getIn(['selectedOrganization','users']);
            const updatedUsersList = currentUsersList.concat(fromJS(action.users));
            return state
            .setIn(
                ['selectedOrganization', 'users'],
                updatedUsersList)
            .set('loading', false)

        }
        case GET_ORGANIZATION_EXTRA_USERS_ERROR:{
            return state.setIn('error', action.error)
        }
        case GET_ORGANIZATION_DATA_SUCCESS_NOT_FORMAT: {
            return state
                .set('selectedOrganizationNotFormat', { ...action.organization })
                .set('loading', false);
        }

        case GET_ORGANIZATION_SUCCESS: {
            return state.set(
                'organizations',
                state
                    .get('organizations')
                    .push(fromJS(action.organization))
                    .set('loading', false)
            );
        }
        case GET_BRANCH_SUCCESS: {
            const organization = state.get('selectedOrganization');
            const updatedBranches = organization.set(
                'branches',
                organization.get('branches').push(fromJS(action.branch))
            );

            return state.set('selectedOrganization', updatedBranches).set('loading', false);
        }
        case GET_ORGANIZATION_USER_SUCCESS: {
            return state
                .setIn(
                    ['selectedOrganization', 'users'],
                    state.getIn(['selectedOrganization', 'users']).push(fromJS(action.user))
                )
                .set('loading', false);
        }
        case EDIT_ORGANIZATION_SUCCESS: {
            return state
                .set(
                    'selectedOrganization',
                    state.get('selectedOrganization').merge(action.organization)
                )
                .set('loading', false);
        }
        case DELETE_ORGANIZATION_SUCCESS: {
            return state.setIn(['selectedOrganization', 'deleted'], true).set('loading', false);
        }
        case LOCATION_CHANGE: {
            return state.set('userErrors', fromJS({})).set('selectedOrganization', fromJS({}));
        }
        case DELETE_ORGANIZATION_USER_SUCCESS: {
            const userIndex = state
                .getIn(['selectedOrganization', 'users'])
                .findIndex((value) => value.get('userId') === action.userId);

            return state
                .setIn(
                    ['selectedOrganization', 'users'],
                    state.getIn(['selectedOrganization', 'users']).delete(userIndex)
                )
                .set('loading', false);
        }
        case ASSOCIATE_EQUIPMENT_ORGANIZATION_SUCCESS: {
            return state
                .setIn(
                    ['selectedOrganization', 'equipment'],
                    state
                        .getIn(['selectedOrganization', 'equipment'])
                        .push(fromJS(action.equipment))
                )
                .set('loading', false);
        }
        case EDIT_EQUIPMENT_ORGANIZATION_SUCCESS: {
            const equipmentIndex = state
                .getIn(['selectedOrganization', 'equipment'])
                .findIndex((equipment) => equipment.get('id') === action.equipment.id);
            return state
                .setIn(
                    ['selectedOrganization', 'equipment'],
                    state
                        .getIn(['selectedOrganization', 'equipment'])
                        .update(equipmentIndex, () => fromJS(action.equipment))
                )
                .set('loading', false);
        }
        case DELETE_ORGANIZATION_EQUIPMENT: {
            const equipmentIndex = state
                .getIn(['selectedOrganization', 'equipment'])
                .findIndex((equipment) => equipment.get('id') === action.equipmentId);
            return state
                .setIn(
                    ['selectedOrganization', 'equipment'],
                    state.getIn(['selectedOrganization', 'equipment']).delete(equipmentIndex)
                )
                .set('loading', false);
        }
        case START_LOADING_ORGANIZATIONS: {
            return state.set('loading', true);
        }
        case EDIT_USER_SUCCESS: {
            const userIndex = state
                .getIn(['selectedOrganization', 'users'])
                .findIndex((user) => user.get('id') === action.user.id);
            return state
                .setIn(
                    ['selectedOrganization', 'users'],
                    state
                        .getIn(['selectedOrganization', 'users'])
                        .update(userIndex, () => fromJS(action.user))
                )
                .set('loading', false);
        }
        default:
            return state;
    }
}

export default organizationServiceReducer;
