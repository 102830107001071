import React,{useEffect, useMemo} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import { selectedRows } from '../../services/AutoPartage/thunks';
import { selectRows } from '../../services/AutoPartage/selectors';
import { createStructuredSelector } from 'reselect';
import './styles.scss';
import {Status_icon_progress,Status_icon_ended,Status_icon_canceled,Status_icon_waiting, Ap_sorting_icon} from '../Icons'
import { visuallyHidden } from '@mui/utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

function descendingComparator(a, b, orderBy) {
    if(orderBy === 'date'){
      const dateA = new Date(a.dateReservationDebut);
      const dateB = new Date(b.dateReservationDebut);
      if(dateB > dateA){
        return -1
      }
      if( dateA > dateB){
        return 1;
      }
      return 0;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

function ApReservationsTable(props) {
    const headCells = [
        {
            id: 'id',
            disablePadding: true,
            label: props.intl.formatMessage({
                id:
                    'components.AP.ReservationTable.Headers.reservation',
            })
    ,
        },
        {
            id: 'date',
            disablePadding: false,
            label: props.intl.formatMessage({
                id:
                    'components.AP.ReservationTable.Headers.date',
            }),
        },
        {
            id: 'status',
            disablePadding: false,
            label: props.intl.formatMessage({
                id:
                    'components.AP.ReservationTable.Headers.status',
            }),
        },
        {
            id: 'secteur',
            disablePadding: false,
            label: props.intl.formatMessage({
                id:
                    'components.AP.ReservationTable.Headers.sector',
            }),
        },
    ];
    function formatDate(dateString) {
        const monthsTranslations = {
            en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            es: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            fr: ["Janv", "Fév", "Mars", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
        };
        const months = monthsTranslations[props.intl.locale];
        const date = new Date(dateString);
        const year = date.getFullYear().toString().padStart(4, '0');
        const monthIndex = date.getMonth();
        const month = months[monthIndex];
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return {date:`${month} ${day},${year}`,
                time: `${hours}:${minutes}`}
      }
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('date');
    const [selected, setSelected] = React.useState([]);

  const sortedRows = useMemo(
    () =>
      stableSort(props.rows, getComparator(order, orderBy)),
    [order, orderBy, props.rows],
  );
  useEffect(() => {
   if(!props.selectedRow) {
    setSelected([]);
   }
  }, [props.selectedRow])
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = props.rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        if (props.selectedRow)
        {
            setSelected(newSelected);
        }else{
            setSelected([]);
        }

        props.selectedRows(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer className={'ap_table_container'}>
                <Table aria-labelledby="tableTitle" size={'medium'} className="ap_table" stickyHeader={true}>
                    <TableHead className="ap_table_head">
                        <TableRow>

                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        className="ap_head_text"
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc'
                                                    ? 'sorted descending'
                                                    : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell>{''}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedRows.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            const formatedDate = formatDate(row.dateReservationDebut);
                            let statusClass = '';
                            let iconStatus = null;
                            let chipLabel = '';
                            switch (row.statusReservation) {
                                case 'À venir':
                                    statusClass = 'status_waiting';
                                    iconStatus=<Status_icon_waiting/>;
                                    chipLabel= props.intl.formatMessage({
                                        id:
                                            'components.AP.ReservationTable.status.waiting',
                                    })
                                    break;
                                case 'En cours':
                                    statusClass = 'status_in_progress';
                                    iconStatus=<Status_icon_progress/>;
                                    chipLabel= props.intl.formatMessage({
                                        id:
                                            'components.AP.ReservationTable.status.inProgress',
                                    })
                                    break;
                                case 'Terminée':
                                    statusClass = 'status_ended';
                                    iconStatus=<Status_icon_ended/>;
                                    chipLabel= props.intl.formatMessage({
                                        id:
                                            'components.AP.ReservationTable.status.ended',
                                    })
                                    break;
                                    case 'Annulée':
                                        statusClass = 'status_canceled';
                                        iconStatus=<Status_icon_canceled/>;
                                        chipLabel= props.intl.formatMessage({
                                            id:
                                                'components.AP.ReservationTable.status.canceled',
                                        })
                                        break;
                                default:
                                    statusClass = 'status_waiting';
                                    iconStatus=<Status_icon_waiting/>;
                                    chipLabel= props.intl.formatMessage({
                                        id:
                                            'components.AP.ReservationTable.status.waiting',
                                    });
                                    break;
                            }
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row.id)}
                                    // role="checkbox"
                                   // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    // selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {/*<TableCell padding="checkbox">*/}
                                    {/*    <Checkbox*/}
                                    {/*        color="primary"*/}
                                    {/*        checked={isItemSelected}*/}
                                    {/*        inputProps={{*/}
                                    {/*            'aria-labelledby': labelId,*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</TableCell>*/}
                                    <TableCell width="180" className={'reservation_id_span'}># {row.id}</TableCell>
                                    <TableCell width="300">{formatedDate.date}-{formatedDate.time}</TableCell>
                                    <TableCell width="300" ><Chip label= {chipLabel} icon={iconStatus} className={statusClass} /></TableCell>
                                     <TableCell width="300">{row.geoName}</TableCell>
                                    {/*<TableCell width="300">Mont-wright</TableCell>*/}
                                    {row.edit ? (
                                        <TableCell width="580"  align="right">
                                            {row.edit}
                                            {row.delete}
                                        </TableCell>
                                    ) : (
                                        <TableCell width="580" />
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
const mapStateToProps = createStructuredSelector({
    selectedRow: selectRows(),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectedRows,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ApReservationsTable))
);

