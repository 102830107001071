// @flow

import { fromJS } from 'immutable';
import {
    DISMISS_LOADING,
    LOADING,
    GET_PREDEFINED_ROUTES_SUCCESS,
    GET_PREDEFINED_ROUTES_ERROR,
    SET_SELECTED_PDR_SUCCESS,
    SET_PDR_ADD_MODE_SUCCESS,
    SET_IS_ROUTE_MATCHING_SUCCESS,
    GET_PREDEFINED_ROUTES_MATCHING_SUCCESS,
    GET_PREDEFINED_ROUTES_MATCHING_ERROR,
    TOGGLE_SHOW_PDR,
} from './actions';

const initialState = fromJS({
    predefinedroutes: [],
    matching:[],
    predefinedroutesError: false,
    buttonLoading: false,
    selectedPDR: [],
    isAddPDR: false,
    isRouteMatching: false,
    showPDR: JSON.parse(localStorage.getItem('geothentic:predefinedRoutes:display')) || false,
});

function PredefinedRoutesServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case LOADING:
            return state.set('buttonLoading', true);
        case DISMISS_LOADING: {
            return state.set('buttonLoading', false);
        }
        case GET_PREDEFINED_ROUTES_SUCCESS: {
            const predefinedroutes = fromJS(action.predefinedroutes);
            return state.set('predefinedroutes', [...predefinedroutes.sort((a, b) => {
                const nameA = a.get('predefinedRouteName').toLowerCase().replace(/\s/g, '');
                const nameB = b.get('predefinedRouteName').toLowerCase().replace(/\s/g, '');
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            }).map(
                (row) =>
                    row.get('children').size > 0
                        ? {
                            key: row.get('predefinedRouteId'),
                            name: row.get('predefinedRouteName'),
                            id: row.get('predefinedRouteId'),
                            color: row.get('color'),
                            coordinates: row.get('predefinedRouteCoords').toJS(),
                            metadata: row.get('metaData').toJS(),
                            dateInsertion: row.get('dateInsertion'),
                            lastUpdated: row.get('lastUpdated'),
                            parentId: 0,
                            children: [
                                ...row.get('children').sort((a, b) => {
                                    const nameA = a.get('predefinedRouteName').toLowerCase().replace(/\s/g, '');
                                    const nameB = b.get('predefinedRouteName').toLowerCase().replace(/\s/g, '');
                                    if (nameA < nameB) {
                                        return -1;
                                    }
                                    if (nameA > nameB) {
                                        return 1;
                                    }
                                    // names must be equal
                                    return 0;
                                }).map((childRow) => ({
                                    key: childRow.get('predefinedRouteId'),
                                    name: childRow.get('predefinedRouteName'),
                                    id: childRow.get('predefinedRouteId'),
                                    color: childRow.get('color'),
                                    coordinates: childRow.get('predefinedRouteCoords').toJS(),
                                    metadata: childRow.get('metaData').toJS(),
                                    dateInsertion: childRow.get('dateInsertion'),
                                    lastUpdated: childRow.get('lastUpdated'),
                                    parentId: row.get('predefinedRouteId')
                                })),
                            ],
                        }
                        : {
                            key: row.get('predefinedRouteId'),
                            name: row.get('predefinedRouteName'),
                            id: row.get('predefinedRouteId'),
                            color: row.get('color'),
                            coordinates: row.get('predefinedRouteCoords').toJS(),
                            metadata: row.get('metaData').toJS(),
                            dateInsertion: row.get('dateInsertion'),
                            lastUpdated: row.get('lastUpdated'),
                            parentId: 0,
                        }
            )]).set('buttonLoading', false);
        }
        case GET_PREDEFINED_ROUTES_ERROR: {
            return state.set('buttonLoading', false);
        }
        case SET_SELECTED_PDR_SUCCESS: {
            return state.set('selectedPDR', action.selectedPDR);
        }
        case SET_IS_ROUTE_MATCHING_SUCCESS: {
            return state.set('isRouteMatching', !state.get('isRouteMatching'));
        }
        case GET_PREDEFINED_ROUTES_MATCHING_SUCCESS: {
            const matching = fromJS(action.matching);
            return state.set('matching', matching).set('buttonLoading', false);
        }
        case GET_PREDEFINED_ROUTES_MATCHING_ERROR: {
            return state.set('buttonLoading', false);
        }
        case SET_PDR_ADD_MODE_SUCCESS: {
            const isAddPDR = fromJS(action.isAddPDR);
            return state.set('isAddPDR', isAddPDR);
        }
        case TOGGLE_SHOW_PDR: {
            return state.set('showPDR', !state.get('showPDR'));
        }
        default:
            return state;
    }
}

export default PredefinedRoutesServiceReducer;
