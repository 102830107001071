import React, { useState } from 'react'
import { Container } from '@material-ui/core'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { ApSuccessIcon, ApCloseIconSuccess } from '../../components/Icons';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectReservationHours, selectReservationDate } from '../../services/AutoPartage/selectors';
import './styles.scss';
function ApSuccessComponent(props) {

    return (
        <Container className={"ap_success_container"}>
            <Grid container direction="column">
                <Grid item sx={{ marginBottom: "10px" }} alignItems="flex-start">
                    <ApSuccessIcon />
                    <span className={"ap_success_header"}><FormattedMessage id={'components.AP.Success.Header'} /></span>
                    <IconButton className={"ap_success_close_icon"} onClick={props.onClose}><ApCloseIconSuccess /></IconButton>
                </Grid>
                <Grid item sx={{ marginLeft: "30px" }} >
                    <span className={"ap_success_body"}>
                        <FormattedMessage id={'components.AP.Success.Message'} />
                        {props.reservationDate}
                        <FormattedMessage id={'components.AP.Success.From'} />
                        {props.reservationHours.split('-')[0]}
                        <FormattedMessage id={'components.AP.Success.To'} />
                        {props.reservationHours.split('-')[1]} .
                    </span>
                </Grid>
                <Grid item sx={{ marginTop: "15px" }}>
                    <Button className={"ap_success_close_button"} onClick={props.onClose}>
                        <FormattedMessage id={'components.AP.Success.close'} />
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}


const mapStateToProps: Object = createStructuredSelector({
    reservationDate: selectReservationDate(),
    reservationHours: selectReservationHours(),
});
export default withRouter(
    connect(
        mapStateToProps, {})(injectIntl(ApSuccessComponent))
);