import { fromJS } from 'immutable';
import {
    GET_RESERVATIONS_SUCCESS,
    GET_RESERVATIONS_ERROR,
    ADD_RESERVATION_ERROR,
    GET_RESERVATIONS_ROWS,
    TOGGLE_SHOW_DRIVER_CODE_MODAL,
    CLOSE_MODAL,
    OPEN_RESERVATION_MODAL,
    CLOSE_RESERVATION_MODAL,
    SAVE_RESERVATION_DATE,
    GET_DATE_AVAILABILITY_SUCCESS,
    GET_DATE_AVAILABILITY_FAIL,
    SAVE_RESERVATION_HOURS,
    ADD_RESERVATION_SUCCESS,
    SHOW_RESERVATION_SUCCESS_MESSAGE,
    CLOSE_RESERVATION_SUCCESS_MESSAGE,
    GET_RESERVATIONBYID,
    EMPTY_SELECTED_RESERVATIONS,
} from './actions';
const initialState = fromJS({
    reservations: [],
    reservation: null,
    selectedRows: null,
    reservationsError: false,
    createReservationError:false,
    showApModal: true,
    reservationModal : false,
    reservationDate:'',
    reservationHours:'',
    dateAvailabity:[],
    reservationSuccess:false,
});
function apReservationsServiceReducer(state = initialState, action: GenericActionType) {
    switch (action.type) {
        case GET_RESERVATIONS_SUCCESS: {
            return state.set('reservations', action.reservations);
        }
        case GET_RESERVATIONS_ERROR: {
            return state.set('reservationsError', true).set('reservations', []);
        }
        case TOGGLE_SHOW_DRIVER_CODE_MODAL: {
            return state.set('showApModal', true);
        }
        case CLOSE_MODAL: {
            return state.set('showApModal', false);
        }
        case GET_RESERVATIONS_ROWS: {
            return state.set('selectedRows', action.rows[0]);
        }
        case OPEN_RESERVATION_MODAL: {
            return state.set('reservationModal', true);
        }
        case CLOSE_RESERVATION_MODAL:{
            return state.set('reservationModal', false);
        }
        case SAVE_RESERVATION_DATE:{
            return state.set('reservationDate', action.date);
        }
        case SAVE_RESERVATION_HOURS:{
            return state.set('reservationHours', action.hours)
        }
        case ADD_RESERVATION_SUCCESS:{
            const newReservationsTable = [...state.get('reservations'), action.reservation];
            return state.set('reservations',newReservationsTable )
        }
        case ADD_RESERVATION_ERROR:{
            return state.set('createReservationError',true )
        }
        case GET_DATE_AVAILABILITY_SUCCESS:{
            return state.set('dateAvailabity', action.hoursArray);
        }
        case SHOW_RESERVATION_SUCCESS_MESSAGE: {
            return state.set('reservationSuccess',true);
        }
        case CLOSE_RESERVATION_SUCCESS_MESSAGE: {
            return state.set('reservationSuccess',false);
        }
        case GET_RESERVATIONBYID:{
            return state.set('reservation', action.reservation);
        }
        case EMPTY_SELECTED_RESERVATIONS:{
            return state.set('selectedRows',null);
        }
        default:
            return state;
    }
}
export default apReservationsServiceReducer;
