import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '/app/styles/scss/global.scss';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectShowApModal ,selectReservationModal} from '../../services/AutoPartage/selectors';
export const ApModal = (props) => {

    return (
        <div>
            {props.showApModal && (
                <Modal
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/*<Box className={'Box test'}>{props.children}</Box>*/}
                    {props.children}
                </Modal>
            )}
                {props.reservationModal && (
                <Modal
                    open={true}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/*<Box className={'Box test'}>{props.children}</Box>*/}
                    {props.children}
                </Modal>
            )}
        </div>
    );
};
const mapStateToProps: Object = createStructuredSelector({
    showApModal: selectShowApModal(),
    reservationModal :selectReservationModal()
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(injectIntl(ApModal))
);
