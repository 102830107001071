// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectCategorieState = () => (state) => state.get('categorie');

const selectCategories = () =>
    createSelector(selectCategorieState(), (substate) => substate.get('categories'));

const selectCategoriesLoading = () =>
    createSelector(selectCategorieState(), (substate) => substate.get('loading'));

export {
    selectCategories,
    selectCategoriesLoading,
};
