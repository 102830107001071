// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';

import { fromJS } from 'immutable';

import Input from 'components/InputField';
import Error from 'components/Error';

import { Content, ForgotPasswordPage } from 'styles/forgotPassword';
import colors from 'styles/colors';

import Logo from 'assets/logo.png';

type Props = {
    intl: Object,
    token: string,
    onPasswordCreate: (email: string, password: string, resetToken: string) => void,
    passwordCreateError: string,
};

type State = {
    email: string,
    password: string,
    passwordConfirmation: string,
    errors: {
        password: boolean,
        confirmation: boolean,
    },
};

export class CreatePasswordPageComponent extends React.Component<Props, State> {
    state = {
        email: '',
        password: '',
        passwordConfirmation: '',
        errors: {
            email: false,
            password: false,
            confirmation: false,
        },
    };

    handleOnChangeText = (key: string) => (event: Event) => {
        const { errors } = this.state;

        if (errors[key]) {
            errors[key] = false;
        }

        this.setState({
            [key]: event.target.value,
            errors,
        });
    };

    handleConfirm = () => {
        const { errors } = this.state;
        if (this.state.password === this.state.passwordConfirmation) {
            if (this.state.errors.password || this.state.errors.confirmation) {
                errors.password = false;
                errors.confirmation = false;
            }

            return this.setState({
                errors,
            });
        }

        this.setState({
            errors: {
                password: true,
                confirmation: true,
            },
        });

        this.props.onPasswordCreate(this.state.email, this.state.password, this.props.token);
    };

    render() {
        return (
            <ForgotPasswordPage>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.CreatePasswordPage.helmetTitle',
                        })}
                    </title>
                    <meta name="description" content="Create password page" />
                </Helmet>
                <Content>
                    <Content.Logo>
                        <Content.LogoImage src={Logo} />
                    </Content.Logo>
                    <ForgotPasswordPage.Split />
                    <Content.ForgotPasswordText>
                        <p>
                            <FormattedMessage id={'views.PasswordCreatePage.Text'} />
                        </p>
                    </Content.ForgotPasswordText>
                    {this.props.passwordCreateError && (
                        <Error text={this.props.passwordCreateError} />
                    )}
                    <Content.Form>
                        <Input
                            type={'text'}
                            name={'email'}
                            placeholderId={'views.ForgotPasswordPage.Placeholder.Email'}
                            underlinecolor={colors.white}
                            onChange={this.handleOnChangeText('email')}
                            error={fromJS(this.state.errors.password)}
                        />
                        <Input
                            type={'password'}
                            name={'forgot-password'}
                            placeholderId={'views.PasswordCreatePage.Placeholder.Password'}
                            underlinecolor={colors.white}
                            onChange={this.handleOnChangeText('password')}
                            error={fromJS(this.state.errors.password)}
                        />
                        <Input
                            type={'password'}
                            name={'forgot-password-confirmation'}
                            placeholderId={'views.PasswordCreatePage.Placeholder.ConfirmPassword'}
                            underlinecolor={colors.white}
                            onChange={this.handleOnChangeText('confirm')}
                            error={fromJS(this.state.errors.confirmation)}
                        />
                    </Content.Form>
                    <ForgotPasswordPage.ButtonContainer>
                        <ForgotPasswordPage.LoginButton onClick={this.handleConfirm}>
                            <FormattedMessage id={'views.PasswordCreatePage.Confirm'} />
                        </ForgotPasswordPage.LoginButton>
                    </ForgotPasswordPage.ButtonContainer>
                </Content>
                <ForgotPasswordPage.Background />
            </ForgotPasswordPage>
        );
    }
}

export default injectIntl(CreatePasswordPageComponent);
