// @flow

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import CreatePassword from 'components/CreatePassword';

import { createPassword } from 'services/Authentication/thunks';

import { changeLocale } from 'services/Language/thunks';
import { selectLocale } from 'services/Language/selectors';

import { selectPasswordCreateError } from 'services/Authentication/selectors';

export class CreatePasswordPageComponent extends React.Component<Props, State> {
    componentDidMount() {
        if (this.props.match.params.locale) {
            if (this.props.locale !== this.props.match.params.locale) {
                this.props.changeLocale(this.props.match.params.locale);
            }
        }
    }

    handleCreatePassword = (email: string, password: string, resetToken: string) => {
        this.props.createPassword(email, password, resetToken);
    };

    render() {
        return (
            <CreatePassword
                token={this.props.match.params.token}
                onPasswordCreate={this.handleCreatePassword}
                passwordCreateError={this.props.passwordCreateError}
            />
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    passwordCreateError: selectPasswordCreateError(),
    locale: selectLocale(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            createPassword,
            changeLocale,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(CreatePasswordPageComponent))
);
