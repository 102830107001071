/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import DriveEta from '@material-ui/icons/DriveEta';
import Person from '@material-ui/icons/Person';
import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export const groupTypesEnum = {
    vehicle: 'vehicles',
    user: 'users',
};

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);

const GroupTypeToggle = ({ value, onChange, disabled }) => {
    const handleChange = useCallback((_, v) => onChange(v), [onChange]);

    return (
        <StyledToggleButtonGroup
            size="small"
            value={value}
            exclusive
            onChange={handleChange}
            aria-label="text alignment">
            <ToggleButton disabled={disabled} value={groupTypesEnum.vehicle} label="Vehicle">
                <DriveEta />
                <FormattedMessage id={'views.Groups.Vehicles'} />
            </ToggleButton>
            <ToggleButton disabled={disabled} value={groupTypesEnum.user}>
                <Person />
                <FormattedMessage id={'views.Groups.Users'} />
            </ToggleButton>
        </StyledToggleButtonGroup>
    );
};

GroupTypeToggle.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

GroupTypeToggle.defaultProps = {
    value: groupTypesEnum.vehicle,
};

export default GroupTypeToggle;
