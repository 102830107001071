// @flow

// Main dependencies
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Configurations
import { ZENDESK_KEY, GA_CODE } from '../../env.js';

// Components and routes
import Routes from 'routes';

import { selectLoggedInAsUser } from 'services/Authentication/selectors';
import {
    receivedLocalUserToken,
    receivedLocalSpoofedUserToken,
    receivedRenewSessionUser,
} from 'services/Authentication/actions';
import { selectLocale } from 'services/Language/selectors';
import {
    logoutUser,
    loadedUserFromsessionStorage,
    renewUserSession,
} from 'services/Authentication/thunks';
import { AppWrapper } from 'styles/common';

import type { ReduxDispatch } from 'types';
import { changeLocale } from 'services/Language/thunks';
import { loadLocaleAndUnits } from 'services/Admin/thunks';
import ImpersonationContainer from 'containers/Impersonation';
import { isMobile } from 'react-device-detect';

type Props = {
    history: Object,
    logoutUser: () => void,
    // user: ?UserType,
};

class App extends React.PureComponent<Props> {
    constructor(props) {
        super(props);
        if (sessionStorage.getItem('api_token')) {
            this.props.receivedLocalUserToken(sessionStorage.getItem('api_token'));
            if (sessionStorage.getItem('expires_at')) {
                this.props.receivedRenewSessionUser(
                    sessionStorage.getItem('api_token'),
                    sessionStorage.getItem('expires_at')
                );
            }
        }
    }

    componentDidMount() {
        // Possible fix for IE10, doesn't seem to work 100% though
        if (!window.crypto) {
            window.crypto = window.crypto ||
                window.msCrypto || { getRandomValues: require('polyfill-crypto.getrandomvalues') };
        }
        //this.props.loadLocaleAndUnits();
        this.props.loadedUserFromsessionStorage();
        const GATagManagerScript = document.createElement('script');
        GATagManagerScript.setAttribute('async', 'async defer');
        GATagManagerScript.setAttribute(
            'src',
            `https://www.googletagmanager.com/gtag/js?id=${GA_CODE}`
        );

        const GAScript = document.createElement('script');
        GAScript.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GA_CODE}');`;

        const fontawesome = document.createElement('link');
        fontawesome.setAttribute('rel', 'stylesheet');
        fontawesome.setAttribute('href', `https://use.fontawesome.com/releases/v5.8.1/css/all.css`);
        fontawesome.setAttribute('rel', 'stylesheet');
        fontawesome.setAttribute(
            'integrity',
            'sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf'
        );
        fontawesome.setAttribute('crossorigin', 'anonymous');

        const zohochat = document.createElement('script');
        const zohoscript = document.createElement('script');
        const zohoDiv = document.createElement('div');

        if (!isMobile) {
            zohochat.innerHTML = `var $zoho=$zoho || {};
            $zoho.salesiq = $zoho.salesiq ||{widgetcode:"02159a22a68597a2baea017ad4762a87f8f5b0f609fdcdfcda2158d8def6b1ff", values:{},ready:function(){}};`;
            zohoscript.setAttribute('src', `https://salesiq.zoho.com/widget`);
            zohoscript.setAttribute('id', 'zsiqscript');
            zohoscript.setAttribute('type', 'text/javascript');
            zohoscript.setAttribute('async', 'async defer');
            zohoDiv.setAttribute('id', 'zsiqwidget');
        }

        window.onload = () => {
            document.body.appendChild(GATagManagerScript);
            document.body.appendChild(GAScript);
            // document.body.appendChild(zohoscript);
            // document.body.appendChild(zohochat);
            // document.body.appendChild(zohoDiv);
            document.body.appendChild(fontawesome);
        };
    }


    render() {
        return (
            <AppWrapper>
                <Helmet titleTemplate="Geothentic - %s">
                    <meta name="description" content="Orca management software" />
                </Helmet>
                <ImpersonationContainer />
                <Routes />
            </AppWrapper>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
    loggedInAsUser: selectLoggedInAsUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            changeLocale,
            logoutUser,
            receivedLocalUserToken,
            receivedLocalSpoofedUserToken,
            loadLocaleAndUnits,
            loadedUserFromsessionStorage,
            receivedRenewSessionUser,
            renewUserSession,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
