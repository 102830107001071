// @flow

import { fromJS } from 'immutable';
import {
    GET_CATEGORIE_ERROR,
    LOADING_CATEGORIE,
    DISMISS_LOADING,
    GET_CATEGORIES_SUCCESS,
} from './actions';

const initialState = fromJS({
    categories: {},
    loading: false,
    categorieError: false,
});

function categorieServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case GET_CATEGORIES_SUCCESS: {
            return state.set('categories', fromJS(action.categories)).set('loading', false);
        }
        case LOADING_CATEGORIE: {
            return state.set('loading', true);
        }
        case DISMISS_LOADING: {
            return state.set('loading', false);
        }
        case GET_CATEGORIE_ERROR: {
            return state.set('categorieError', true).set('loading', false).set('categories', {});
        }
        default:
            return state;
    }
}

export default categorieServiceReducer;
