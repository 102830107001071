// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Loader from 'components/Loader';
import colors from 'styles/colors';
import { i18nDistanceSymbol } from 'services/Units/thunks';
import GeoExportExcel from 'components/GeoExportExcel';
import { Resizable, ResizableBox } from 'react-resizable';


class GeoPieChartComponent extends React.Component<Props, State> {
    render() {
        const { data, title, description, loading, dataKey, type, i18nDistanceSymbol, dataExcel, excelKey, bar, dateRange } = this.props;
        const RADIAN = Math.PI / 180;

        const secondsToHms = (d) => {
            if (d == 0) return 0;
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor((d % 3600) / 60);
            var s = Math.floor((d % 3600) % 60);

            var hDisplay = h > 0 ? h + ' h, ' : '';
            var mDisplay = m > 0 ? m + ' m, ' : '';
            var sDisplay = s > 0 ? s + ' s' : '';
            return hDisplay + mDisplay + sDisplay;
        };

        const CustomTooltip = ({ active, payload, label, type }) => {
            if (active) {
                return (
                    <div className="custom-tooltip">
                        {payload &&
                            payload.map((key) => {
                                return (
                                    <span
                                        style={{
                                            color: key.fill,
                                        }}
                                        className="desc"
                                    >{`${key.name} : ${type == 'time'
                                        ? secondsToHms(parseInt(key.value))
                                        : type == 'distance'
                                            ? `${parseInt(key.value)} ${i18nDistanceSymbol()}`
                                            : parseInt(key.value)
                                        }`}</span>
                                );
                            })}
                    </div>
                );
            }
            return null;
        };

        const renderCustomizedLabel = ({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            percent,
            index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 1.6;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text
                    style={{ fontSize: '15px', color: colors.black }}
                    x={x}
                    y={y}
                    fill="black"
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                >
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };

        const renderLegend = (data,type) => {
            return (
                <div style={{ borderColor: colors.green61 }}>
                    <ul>
                        {data.map((entry, index) => (
                            <li
                                key={`item-${index}`}
                                color={entry.fill}
                                style={{ color: entry.fill, fontSize: '12px' }}
                            >
                                {entry.name} : {entry.value}{' '}
                                {type && type == 'distance' ? i18nDistanceSymbol() : null}
                            </li>
                        ))}
                        {data && data.length > 1 && excelKey != 'geo_name' ? <li
                            key={`item-total`}
                            color={colors.black}
                            style={{ color: colors.black, fontSize: '12px' }}
                        >
                            <FormattedMessage id={'containers.Reports.Generators.total.algo'} /> : {data.map((entry, index) => entry.value).reduce((a, b) => Number(a + b))}{' '}
                            {type && type == 'distance' ? i18nDistanceSymbol() : null}
                        </li> : null}
                    </ul>
                </div>
            );
        };

        return (
            <ResizableBox
                className={'element'}
                minConstraints={[400, 500]}
                transformScale={0.75}
                width={400}
                height={500}
                axis={"x"}
                style={{ borderColor: colors.green61 }}
                handle={(h) => <span className={`custom-handle custom-handle-${h}`} />}>
                <span
                    className="d-inline-block"
                    style={{
                        textAlign: 'center',
                        color: colors.green61,
                        textTransform: 'uppercase',
                        textDecorationLine: 'underline',
                    }}
                >
                    {title}{' '}
                </span>
                <hr />
                {loading ? (
                    <Loader loading />
                ) : (
                    [
                        description ? (
                            <span
                                style={{
                                    textAlign: 'left',
                                }}
                            >
                                {description}{' '}
                            </span>
                        ) : null,
                        [dataExcel ?
                            <div className={'export'}>
                                <GeoExportExcel
                                    title={title}
                                    dateRange={dateRange}
                                    bar={bar}
                                    data={dataExcel}
                                    dataKey={excelKey == 'geo_name' ? 'geo_name' : 'vehicle_numb'}
                                />
                            </div> : null,
                        <ResponsiveContainer>
                            <PieChart
                                margin={{
                                    top: 20,
                                    right: 20,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <Pie
                                    dataKey={dataKey}
                                    isAnimationActive={false}
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    innerRadius={60}
                                    fill="#fff"
                                    label={renderCustomizedLabel}
                                />
                                <Legend
                                    verticalAlign="bottom"
                                    align="left"
                                    wrapperStyle={{ borderColor: colors.green61 }}
                                    content={renderLegend(data,i18nDistanceSymbol(),type)}
                                />
                                {type ? (
                                    <Tooltip content={<CustomTooltip type={type} />} />
                                ) : (
                                    <Tooltip />
                                )}
                            </PieChart>
                        </ResponsiveContainer>,
                        ],
                    ]
                )}
            </ResizableBox>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            i18nDistanceSymbol,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(GeoPieChartComponent))
);
