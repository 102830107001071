// @flow

import React from 'react';

import { injectIntl } from 'react-intl';

import Error from 'components/Error';

import { InputField, MultiLineInputField, Container } from './styles';
import colors from 'styles/colors';

type Props = {
    placeholderId: string,
    type: ?string,
    image: ?any,
    value: ?string,
    defaultValue?: ?string,
    onChange: ?() => void,
    onKeyUp: ?() => void,
    multiline: ?boolean,
    error: ?(boolean | string),
    required: ?boolean,
    height: ?number,
    backgroundColor: ?string,
    readOnly: ?boolean,
};

class FormInputText extends React.PureComponent<Props> {
    render() {
        const placeholder = this.props.placeholderId ? this.props.intl.formatMessage({ id: this.props.placeholderId })
        : "";

        if (this.props.multiline) {
            return (
                <Container>
                    <MultiLineInputField>
                        <MultiLineInputField.Input
                            value={this.props.value}
                            defaultValue={this.props.defaultValue}
                            placeholder={placeholder}
                            onChange={this.props.onChange}
                            onKeyUp={this.props.onKeyUp}
                            readOnly={this.props.readOnly}
                        />
                    </MultiLineInputField>
                    <React.Fragment>
                        {this.props.error && <Error text={this.props.error} />}
                    </React.Fragment>
                </Container>
            );
        }

        return (
            <Container>
                <InputField
                    id={this.props.id}
                    borderColor={
                        this.props.error || this.props.error === ''
                            ? colors.status.red
                            : colors.grayDD
                    }
                    disabled={this.props.disabled}
                    height={this.props.height}
                    width={this.props.width}
                    customStyle={this.props.customStyle}
                    backgroundColor={this.props.backgroundColor}
                    paddingRight={this.props.paddingRight !== undefined ? this.props.paddingRight : true}
                >
                    <InputField.TextInput
                        type={this.props.type}
                        min={this.props.min}
                        max={this.props.max}
                        onBlur={this.props.onBlur}
                        value={this.props.value}
                        customStyle={this.props.customStyle}
                        defaultValue={this.props.defaultValue}
                        placeholder={placeholder}
                        center={this.props.center}
                        onChange={this.props.onChange}
                        onKeyUp={this.props.onKeyUp}
                        disabled={this.props.disabled}
                        required={this.props.required}
                        readOnly={this.props.readOnly}                  
                    />
                    {this.props.image ? <InputField.Image src={this.props.image} /> : null}
                </InputField>
                <React.Fragment>
                    {this.props.error && <Error text={this.props.error} />}
                </React.Fragment>
            </Container>
        );
    }
}

FormInputText.defaultProps = {
    readOnly: false,
};

export default injectIntl(FormInputText);
