// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { fromJS } from 'immutable';

import Fuse from 'fuse.js';

import { selectUser } from 'services/Authentication/selectors';
import { selectLocale } from 'services/Language/selectors';
import {
    selectOrganizations,
    selectOrganizationsPagination,
    selectOrganizationsLoading,
} from 'services/Organization/selectors';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';

import {
    fetchOrganizations,
    getOrganizationData,
    createOrganization,
} from 'services/Organization/thunks';
import { toggleOrganizationModal, toggleUserModal } from 'services/Modal/thunks';

import OrganizationListItem from 'components/OrganizationListItem';
import SearchInput from 'components/SearchInput';
import OutlineButton from 'components/OutlineButton';
import DashboardDataTable from 'components/DashboardDataTable';
import SelectField from 'components/SelectField';
import Modal from 'components/Modals';
import CreateModal from 'components/Modals/CreateOrganizationModal';
import Loader from 'components/Loader';

import DataListHeaderContainer from 'containers/DataListHeaderContainer';

import OrganizationList from './styles';
import { TableWrapper } from '../../styles/dashboard';
import colors from 'styles/colors';

import CaretDownImage from 'assets/caret-down.png';
import CaretUpImage from 'assets/caret-up.png';

import { CREATE_ORGANIZAITON, MODAL_TYPE } from 'services/Modal/actions';

import type {
    ImmutableList,
    SearchResultsType,
    OrganizationListType,
    OrganizationType,
} from 'types';

type Props = {
    organizations: OrganizationListType,
    isOpen: boolean,
};
type State = {
    filteredOrganizations: ImmutableList<string>,
    query: string,
};

export class OrganizationListComponent extends React.Component<Props, State> {
    state = {
        filteredOrganizations: fromJS([]),
        query: '',
    };

    componentDidMount() {
        // Fetch organizations if there is not current place in the paginated calls.
        const page = this.props.organizationsPagination.get('currentPage');
        if (!page) {
            this.props.fetchOrganizations(1);
        }
    }

    timeout = null;

    /**
     * Accepts a sortOrder string used to determin which colum we will order by.
     * On order, we remove existing order conditions and apply the currently selected one.
     */
    handleOrderOrganizations = (sortColumn: string) => () => {
        let ascOrder = sortColumn;

        if (this.state.ascOrder === sortColumn) {
            ascOrder = '';
        }

        this.setState({
            ascOrder,
        });
    };

    handleOnClickAllLocations = () => {
        console.log('clicked All Locations');
    };

    handleSearchResults = (results: SearchResultsType) => {
        this.setState({ query: results.query });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.fetchOrganizations(1, this.state.query);
        }, 250);
    };

    handleOnClickOrganization = (id: number) => {
        this.props.getOrganizationData(id);
        this.props.history.push(`/${this.props.intl.locale}/interne/orgs/${id}`);
    };

    handleOnClickCreateOrganization = () => {
        if (!this.props.showModal) {
            this.props.toggleOrganizationModal(MODAL_TYPE.ORGANIZATION, CREATE_ORGANIZAITON);
        }
    };

    handleOnSubmitOrganizationModal = (organization: OrganizationType) => {
        this.props.createOrganization(organization);
    };

    handleViewMoreOrganizations = () => {
        const page = this.props.organizationsPagination.get('currentPage') || 0;
        this.props.fetchOrganizations(page + 1, this.state.query);
    };

    handleScrollOrganizationsListPage = (e: Event) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        if (bottom) {
            const page = this.props.organizationsPagination.get('currentPage') + 1;
            this.props.fetchOrganizations(page, this.state.query);
        }
    };

    getCaretSource = (column: string) => {
        if (this.state.ascOrder === column) {
            return CaretUpImage;
        }

        return CaretDownImage;
    };

    handleNewOrganizationClick = () => {
        this.props.toggleOrganizationModal();
    };

    render() {
        let tableData = this.props.organizations;

        // if (this.state.query && this.state.filteredOrganizations.size >= 0) {
        //     tableData = this.state.filteredOrganizations;
        // }

        const organizationsHeaders = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'name',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Location',
                }),
                key: 'address.city+address.province',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.ActiveEquipment',
                }),
                key: 'equipmentCount',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.ActiveUsers',
                }),
                key: 'usersCount',
            },
        ];

        return (
            <OrganizationList
                isOpen={this.props.isOpen}
                onScroll={this.handleScrollOrganizationsListPage}
            >
                <Helmet>
                    <title>
                        Service Client - Admin
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.OrganizationListPage.Head.Content',
                        })}
                    />
                </Helmet>
                <DataListHeaderContainer
                    title={this.props.intl.formatMessage({
                        id: 'views.OrganizationListPage.Title',
                    })}
                    name={this.props.intl.formatMessage({ id: 'views.OrganizationListPage.Name' })}
                    // actionButtonText={'containers.Dashboard.CreateNew'}
                    // onClickActionButton={this.handleOnClickCreateOrganization}
                />
                <OrganizationList.Split height={'1px'} color={colors.grayDD} />

                <OrganizationList.FilterContainer>
                    <OrganizationList.SearchContainer>
                        <SearchInput
                            onSearchResults={this.handleSearchResults}
                            searchKeys={['name', 'contact', 'address.city', 'address.province']}
                            onChange={this.handleOnChangeSearchInput}
                            borderColor={colors.grayDD}
                            data={this.props.organizations}
                        />
                    </OrganizationList.SearchContainer>
                    {/* <div style={{ width: '200px', display: 'flex', flex: 1 }}>
                        <SelectField
                            options={[
                                { label: 'option1', value: '1' },
                                { label: 'option2', value: '2' },
                                { label: 'option3', value: '3' },
                            ]}
                            placeholder={this.props.intl.formatMessage({
                                id: 'containers.Dashboard.Organizations.Locations',
                            })}
                            // padding={'2px 20px 0 0'}
                            maxWidth={230}
                            center
                        />
                    </div> */}
                    <OrganizationList.NewOrgContainer>
                        <OrganizationList.LoginButton onClick={this.handleNewOrganizationClick}>
                            <FormattedMessage
                                id={'views.dashboard.organizations.CreateOrgButton'}
                            />
                        </OrganizationList.LoginButton>
                    </OrganizationList.NewOrgContainer>
                </OrganizationList.FilterContainer>

                <Loader loading={this.props.loading} />

                <TableWrapper>
                    <DashboardDataTable
                        id={'branchId'}
                        columns={organizationsHeaders}
                        tableData={tableData}
                        onClickDataItem={this.handleOnClickOrganization}
                        onClickViewMore={this.handleViewMoreOrganizations}
                        paginated={this.props.organizationsPagination}
                        loading={this.props.loading}
                        list
                    />
                </TableWrapper>

                <Modal showModal onScroll={(e) => e.stopPropagation()}>
                    <CreateModal
                        data={this.props.organizations}
                        header={'components.Modals.Create.Organization'}
                        onSubmit={this.handleOnSubmitOrganizationModal}
                    />
                </Modal>
            </OrganizationList>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    user: selectUser(),
    locale: selectLocale(),
    organizations: selectOrganizations(),
    organizationsPagination: selectOrganizationsPagination(),
    loading: selectOrganizationsLoading(),
    isOpen: selectIsSideMenuOpen(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            fetchOrganizations,
            getOrganizationData,
            toggleOrganizationModal,
            createOrganization,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(OrganizationListComponent))
);
