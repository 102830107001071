import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import '/app/styles/scss/global.scss';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import { Apalert } from '../Icons';
import { closeDriveCodeModal } from '../../services/AutoPartage/thunks';
import { selectShowApModal } from '../../services/AutoPartage/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
export const ApDriverCode =(props)=> {

   const handleOnClickSaveDriverCode = () => {
            sessionStorage.setItem('Code_de_conduite', true);
            props.closeDriveCodeModal();        
    };
    return (
        <Container maxWidth="sm" className={'driver_code'}>
            <Apalert />
            <Stack spacing={2} className={'driver_code_inner'}>
                <h1>
                    {' '}
                    <FormattedMessage id={'components.ap.welcome.title'} />
                </h1>
                <span>
                    <FormattedMessage id={'components.ap.welcome.terme1'} />
                </span>
                <div className={'Terms'}>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme2'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme3'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme4'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme5'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme6'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme7'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme8'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme9'} />
                    </p>
                    <p>
                        <FormattedMessage id={'components.ap.welcome.terme10'} />
                    </p>
                </div>
                <span>
                    <FormattedMessage id={'components.ap.welcome.terme11'} />
                </span>
                <span>
                    <FormattedMessage id={'components.ap.welcome.terme12'} />
                </span>

                <Button className={'ap_btn_secondary'} onClick={handleOnClickSaveDriverCode}>
                    <FormattedMessage id={'components.ap.welcome.btnAcceptCondition'} />
                </Button>
            </Stack>
        </Container>
    );
}
const mapStateToProps: Object = createStructuredSelector({
    showModal: selectShowApModal(),
});

const mapDispatchToProps: Object = (dispatch) =>
bindActionCreators(
    {
          closeDriveCodeModal,
    },
    dispatch
);

export default withRouter(
connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ApDriverCode))
);