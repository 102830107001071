// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
const Input = styled.input.attrs({
    // add a global color property.
    disabled: (props) => props.disabled,
    color: (props) => props.color || `${colors.offwhite}`,
    underlinecolor: (props) => props.underlinecolor || `${colors.green73}`,
    underline: (props) => (props.error ? `red` : `${props.underlinecolor}`),
})`
    opacity: ${(props) => (props.disabled ? '0.3;' : '1;')}
    font-family: Gotham-Medium;
    line-height: 30px;
    border: none;
    background: none;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.underline};
    margin: ${(props) => `${props.margin};` || '30px 0 0 0;'}
    caret-color: ${(props) => props.color};
    color: ${(props) => props.color};
    &:focus {
        outline: none;
    }
    ::placeholder {
        color: ${(props) => props.color};
        opacity: 1;
    }
`;

export default Input;
