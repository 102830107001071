// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import OutlineButton from 'components/OutlineButton';

import { Header, HeaderText, Buttons } from './styles';

import colors from 'styles/colors';

type Props = {
    report: ImmutableMap<any>,
    title: string,
    onExportPDF: () => void,
    onExportXLS: () => void,
};

type State = {};

class GenerateReportHeader extends React.Component<Props, State> {
    render() {
        return (
            <Header>
                <HeaderText>{this.props.title}</HeaderText>
                <Buttons>
                    {this.props.report &&
                        this.props.report.size > 0 &&
                        typeof this.props.onExportPDF === 'function' && (
                            <OutlineButton
                                width={'150px'}
                                onClick={this.props.onExportPDF}
                                outlineColor={colors.green63}
                                textColor={colors.green63}
                                hoverTextColor={colors.white}
                            >
                                <FormattedMessage id={'containers.Reports.Generators.Export.Pdf'} />
                            </OutlineButton>
                        )}
                    {this.props.report &&
                        this.props.report.size > 0 &&
                        typeof this.props.onExportXLS === 'function' && (
                            <OutlineButton
                                width={'150px'}
                                onClick={this.props.onExportXLS}
                                outlineColor={colors.green63}
                                textColor={colors.green63}
                                hoverTextColor={colors.white}
                            >
                                <FormattedMessage id={'containers.Reports.Generators.Export.Xls'} />
                            </OutlineButton>
                        )}
                </Buttons>
            </Header>
        );
    }
}

export default GenerateReportHeader;
