// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import { Card } from 'antd';
import { Button, Table } from 'antd';

const DriverItem = styled.div`
    border-radius: 5px;
    border: 1px solid ${colors.green61};
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    height: 95%;
    margin: auto;

    .addBtn {
        background-color: ${colors.green57};
        color: ${colors.white};
        border-color: ${colors.green57};
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: ${colors.white};
    }

    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background: ${colors.white};
    }

    .confirmSelection,
    .confirmSelection :focus,
    .confirmSelection :hover {
        background-color: ${colors.green57};
        color: ${colors.white};
        border-color: ${colors.green57};
        margin-bottom: 10px;
        margin-top: 10px;
    }

    hr {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .centerAligned {
        display: flex;
        justify-content: center;
    }

    .searchContainer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        display: flex;
        align-items: start;
        .search_bar {
            padding-right: 1em;
            width: 100%;
        }
    }

    .deployModal .ant-modal-body {
        display: flex;
        justify-content: center;
    }
    .searchInput {
        width: 100%;
        height: 35px;
        margin-right: 20px;
        margin-bottom: 5px;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #4caf50;
    }
    .ant-radio-inner::after {
        background-color: #4caf50;
    }
    ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #4caf50;
    }
    .deployButton {
        margin-right: 20px;
        background: #60a735;
        color: white !important;
        border: none !important;
        &:hover {
            border: none;
            background: #8bc34a;
        }
    }
    .deployButton.Mui-disabled {
        background: #d7d7d7;
    }

    .addDriverIcon {
        font-size: 20px;
        color: #fff;
        padding-right: 0.3em;
    }

    .tableWrapper {
        width: 60%;
        margin: auto;
    }

    .inputFileWrapper {
        margin: auto;
        width: 60%;
    }

    .downloadButton {
        margin-top: 10px;
        width: 100%;
        text-decoration: underline;
        color: #3f51b5;
        text-align: center;
    }
    .actions {
        display: flex;
    }
`;

DriverItem.DeleteButton = styled(Button)`
    display: flex;
    justify-content: space-between;

    .ant-btn styles__DeleteButton-jAeqRr dCvczY {
        background: ${colors.red};
        color: ${colors.white};
    }

    width: 100%;
`;

DriverItem.CardWrapper = styled.div`
    margin: 1.5%;
    .ant-card-bordered {
        background: ${(props) =>
            props.selected ? (props.isError ? colors.yellowFFF : colors.greenB4) : colors.white};
        border: 2px solid ${(props) => (props.isError ? colors.yellowFFE : colors.green73)};
        -webkit-transition: background 500ms linear;
        -ms-transition: background 500ms linear;
        transition: background 500ms linear;
    }
`;

DriverItem.CardItem = styled(Card)`
    position: relative;
    height: 125px;
    width: 125px;
    word-wrap: break-word;

    &:hover {
        opacity: 0.7;
    }

    &:hover ${DriverItem.ButtonItems} {
        display: block;
    }
`;

DriverItem.Header = styled.div`
    font-family: OpenSans-SemiBold;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 10px;
    color: #000000;
`;
export default DriverItem;
