// @flow

import { fromJS, toJS } from 'immutable';
import {
    USER_LOADING,
    DISMISS_LOADING,
    LOADING,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    GET_NEXT_USERS_PAGE_SUCCESS,
    ERROR,
    SUCCESS,
    USER_ROLES,
    GET_USER_SUCCESS,
    CREATE_USER_SUCCESS,
    INPUT_LOCKED,
} from './actions';

const initialState = fromJS({
    users: {},
    usersError: false,
    loading: false,
    userLoading: false,
    showError: false,
    userRoles: {},
    createdUserId: null,
    inputLocked: false,
});

function AdminServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case USER_LOADING:
            return state.set('userLoading', true);
        case LOADING:
            return state.set('loading', true);
        case DISMISS_LOADING: {
            return state.set('loading', false);
        }
        case GET_USERS_SUCCESS: {
            const users = fromJS(action.users);
            return state.set('users', users).set('loading', false);
        }
        case GET_USERS_ERROR: {
            return state.set('loading', false);
        }
        case GET_NEXT_USERS_PAGE_SUCCESS: {
            action.users.users = action.users.users.concat(
                state
                    .get('users')
                    .get('users')
                    .toJS()
            );
            const nextUsersPage = fromJS(action.users);

            return state.set('users', nextUsersPage).set('loading', false);
        }
        case ERROR: {
            return state.set('userLoading', false).set('showError', true);
        }
        case SUCCESS: {
            return state.set('userLoading', false).set('showError', false);
        }
        case USER_ROLES: {
            return state.set('userRoles', action.roles);
        }
        case GET_USER_SUCCESS: {
            /* todo
            const tmp = state
                .get('users')
                .get('users')
                .toJS();
            console.log(tmp);
            const tmp2 = action.user;
            console.log(tmp2);
            const tmp1 = tmp.push(tmp2);

            const updatedUsers = fromJS(tmp1);
            */

            return state.set('showError', false);
        }
        case CREATE_USER_SUCCESS: {
            return state.set('createdUserId', action.userId.userId).set('showError', false);
        }
        case INPUT_LOCKED: {
            return state.set('inputLocked', action.locked);
        }
        default:
            return state;
    }
}

export default AdminServiceReducer;
