import colors from 'styles/colors';
import styled from 'styled-components';

export const HeaderText = styled.div`
    font-size: 18px;
    font-weight: 400;
    font-family: OpenSans-SemiBold;
    color: ${colors.green63};
    margin-bottom: 28px;
`;
