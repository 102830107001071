// @flow

import React from 'react';

import { injectIntl } from 'react-intl';

import CheckInput from './styles';
import colors from 'styles/colors';

import Checked from 'assets/checked.svg';

type Props = {
    placeholderId: ?string,
    value: boolean,
    onChange: ?() => void,
    borderColor?: string,
};

class CheckInputComponent extends React.PureComponent<Props> {
    static defaultProps = {
        borderColor: colors.grayDD,
    };

    render() {
        const placeholder =
            this.props.placeholderId &&
            this.props.intl.formatMessage({ id: this.props.placeholderId });

        return (
            <CheckInput>
                {placeholder && <CheckInput.Label>{placeholder}</CheckInput.Label>}
                <CheckInput.Input
                    borderColor={this.props.borderColor}
                    onClick={this.props.onChange}
                >
                    {this.props.value ? (
                        <CheckInput.Image src={Checked} width={'12px'} height={'10px'} />
                    ) : null}
                </CheckInput.Input>
            </CheckInput>
        );
    }
}

export default injectIntl(CheckInputComponent);
