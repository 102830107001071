// @flow

import axios from 'axios';
import { ISO_API_URL } from 'env';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { push } from 'react-router-redux';
import { parseJWT } from 'helpers';
import auth from 'services/Authentication/auth0';
/**
 * Requests a URL, returning a promise.
 *
 * Axios by default will reject a promise if it is not between status codes 200-300
 * (This can be modified by implementing the validateStatus method)
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {humps} [optional] Set to false to not camelize/decamelize keys
 * @param {token} [optional] User token for authenticated routes
 * @param {apiUrl} [optional] Set to false to not use the backend api in the url
 * @return {object}           An object containing either "data" or "err"
 */

export default function request(
    url: string,
    options?: { method?: ?string, data?: ?Object } = {
        method: 'GET',
        data: {},
    },
    humps?: boolean = true,
    token?: string = '',
    apiUrl?: boolean = true,
    setHeaders?: object = {},
    setResponseType?: string = ''
) {
    const headers =
        setHeaders && Object.keys(setHeaders).length > 0
            ? setHeaders
            : {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
              };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const requestUrl = `${apiUrl ? ISO_API_URL : ''}${url}`;
    const data = humps ? decamelizeKeys(options.data) : options.data;
    const config = {
        url: requestUrl,
        method: options.method ? options.method : 'GET',
        data,
        timeout: 600000,
        headers,
        responseType: setResponseType && setResponseType.length > 0 ? setResponseType : 'json',
    };
    return axios(config)
        .then((response: Object) => {
            // if returning a file, just return the response
            if (config.responseType !== 'json') {
                return Promise.resolve(response);
            }
            return Promise.resolve(humps ? camelizeKeys(response) : response);
        })
        .catch((error: Object) => {
            // Re-auth if expired
            if (error.response && error.response.status === 401) {
                auth.login(
                    window.location.pathname.indexOf('dashboard') > -1,
                    window.location.pathname
                );
                return Promise.reject(null);
            }
            /**
             * Maps the returned errors to an object in the form of
             * {
             *    [formField: string]: errorMessage
             * }
             */
            if (error.response && error.response.data.errors) {
                const errorMessage = error.response.data.errors.reduce((prev, curr) => {
                    prev[curr.path] = curr.message.charAt(0).toUpperCase() + curr.message.slice(1);
                    return prev;
                }, {});
                return Promise.reject(errorMessage);
            }
            return Promise.reject(error.message);
        });
}
