/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import useStyles from '../../styles/MultiSelectField.styles';
import { FormattedMessage } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { useIntlContext } from '../../context/IntlContext';
import { formatString } from '../../utils/String';

const NoOptionsMessage = (props) => (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
        {props.children}
    </Typography>
);

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = (props) => {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
};

const Placeholder = (props) => {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
};

const ValueContainer = (props) => <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;

const MultiValue = (props) => {
    const {
        children,
        selectProps: { classes },
        removeProps,
        isFocused,
    } = props;
    return (
        <Chip
            tabIndex={-1}
            size="small"
            label={children}
            className={clsx(classes.chip, {
                [classes.chipFocused]: isFocused,
            })}
            onDelete={removeProps.onClick}
            deleteIcon={<CancelIcon {...removeProps} />}
        />
    );
};

const Menu = (props) => {
    const { intl } = useIntlContext();

    const handleAddAllClick = useCallback(() => {
        props.selectProps.onChange(props.options);
    });
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            <MenuItem dense component="div" onClick={handleAddAllClick}>
                {`${intl.formatMessage({ id: 'views.Groups.AddAll' })}  (${props.options.length})`}
            </MenuItem>
            {props.children}
        </Paper>
    );
};

const Option = (props) => {
    const {
        innerRef,
        innerProps,
        isFocused,
        children,
        selectProps: { classes },
    } = props;

    return (
        <MenuItem ref={innerRef} dense selected={isFocused} component="div" className={classes.options} {...innerProps}>
            {children}
        </MenuItem>
    );
};

const components = {
    Control,
    Menu,
    Option,
    MultiValue,
    NoOptionsMessage,
    Placeholder,
    ValueContainer,
};

const MultiSelectField = ({ options, onChange, label }) => {
    const classes = useStyles();
    const [multi, setMulti] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);

    const handleChangeMulti = useCallback((values) => {
        onChange(values.map(({ value }) => value));
        setMulti(values);
    });

    const handleInputChange = useCallback((query, { action }) => {
        if (action !== 'set-value') {
            setInputValue(query);
        }
    });

    useEffect(
        () => {
            if (options) {
                setFilteredOptions(
                    options.filter(({ label: option }) => formatString(option).includes(formatString(inputValue)))
                );
            }
        },
        [options, inputValue]
    );

    return (
        <div className={classes.root}>
            <Select
                closeMenuOnSelect={false}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                className={classes.select}
                classes={classes}
                inputId="react-select-multiple"
                TextFieldProps={ label ? {
                        label: <FormattedMessage id={label} />,
                        InputLabelProps: {
                            htmlFor: 'react-select-multiple',
                            shrink: true,
                        },
                    }
                : {
                    InputLabelProps: {
                        htmlFor: 'react-select-multiple',
                        shrink: true,
                    },
                  }
                }
                placeholder={<FormattedMessage id={'views.Groups.AddToGroup'} />}
                options={filteredOptions}
                components={components}
                value={multi}
                onChange={handleChangeMulti}
                isMulti
            />
        </div>
    );
};

export default MultiSelectField;
