// @flow

// Reservations Actions
export const GET_RESERVATIONS_SUCCESS = 'app/services/AutoPartage/GET_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_ERROR = 'app/services/AutoPartage/GET_RESERVATIONS_ERROR';
//Creating Reservation
export const SAVE_RESERVATION_DATE = 'app/services/AutoPartage/SAVE_RESERVATION_DATE';
export const SAVE_RESERVATION_HOURS =  'app/services/AutoPartage/SAVE_RESERVATION_HOURS';
export const ADD_RESERVATION_SUCCESS =  'app/services/AutoPartage/ADD_RESERVATION_SUCCESS';
export const ADD_RESERVATION_ERROR =  'app/services/AutoPartage/ADD_RESERVATION_ERROR';

export const GET_RESERVATIONS_ROWS = 'app/services/AutoPartage/GET_RESERVATIONS_ROWS';
// Driver Code Modal
export const TOGGLE_SHOW_DRIVER_CODE_MODAL = 'app/services/Modal/TOGGLE_SHOW_DRIVER_CODE_MODAL';
export const CLOSE_MODAL = 'app/services/Modal/CLOSE_MODAL';
// Reservation Modal
export const OPEN_RESERVATION_MODAL ='app/service/AutoPartage/OPEN_RESERVATION_MODAL';
export const CLOSE_RESERVATION_MODAL ='app/service/AutoPartage/CLOSE_RESERVATION_MODAL';
//Reservation availibity
export const GET_DATE_AVAILABILITY_SUCCESS  ='app/service/AutoPartage/GET_DATE_AVAILABILITY_SUCCESS';
export const GET_DATE_AVAILABILITY_ERROR  ='app/service/AutoPartage/GET_DATE_AVAILABILITY_ERROR';
//get Reservation
export const GET_RESERVATIONBYID  ='app/service/AutoPartage/GET_RESERVATIONBYID';
//Reservation Success Message
export const SHOW_RESERVATION_SUCCESS_MESSAGE = 'app/service/AutoPartage/SHOW_RESERVATION_SUCCESS_MESSAGE';
export const CLOSE_RESERVATION_SUCCESS_MESSAGE = 'app/service/AutoPartage/CLOSE_RESERVATION_SUCCESS_MESSAGE';
// Cancel Reservation
export const EMPTY_SELECTED_RESERVATIONS = 'app/service/AutoPartage/EMPTY_SELECTED_RESERVATIONS';

//Sectors Actions
export const GET_SECTORS_SUCCESS = 'app/services/AutoPartage/GET_SECTORS_SUCCESS';
export const GET_SECTORS_ERROR = 'app/services/AutoPartage/GET_SECTORS_ERROR';

export const MODAL_TYPE = {
    DRIVERCODE: 'MODAL_TYPE_DRIVER_CODE',
    REGISTRATIONFORM: 'MODAL_TYPE_REGISTRATION_FORM',
    EDITACTION: 'MODAL_TYPE_EDIT_ACTION',
    DELETEACTION: 'MODAL_TYPE_DELETE_ACTION',
    COMFIRMATIONFORM: 'MODAL_TYPE_COMFIRMATION_FORM',
};
export const receivedReservationsSuccess = (reservations): GenericActionType => ({
    type: GET_RESERVATIONS_SUCCESS,
    reservations
});
export const receivedReservationsError = (): GenericActionType => ({
    type: GET_RESERVATIONS_ERROR,
});
export const receivedReservationsRows = (rows): GenericActionType => ({
    type: GET_RESERVATIONS_ROWS,
    rows
});
export const receivedReservationById = (reservation): GenericActionType => ({
    type: GET_RESERVATIONBYID,
    reservation
});
export const receivedToggleDriveCodeModal = (modalAction: ?string): GenericActionType => ({
    type: TOGGLE_SHOW_DRIVER_CODE_MODAL,
    modalType: MODAL_TYPE.DRIVERCODE,
    modalAction,
});

export const receivedCloseDriveCodeModal = (): GenericActionType => ({
    type: CLOSE_MODAL,
});
export const receivedOpenReservationModal = (): GenericActionType => ({
    type: OPEN_RESERVATION_MODAL,
})
export const receivedCloseReservationModal = (): GenericActionType => ({
    type: CLOSE_RESERVATION_MODAL,
})
export const receivedReservationDate = (date:?string): GenericActionType => ( {
    type:SAVE_RESERVATION_DATE,
    date
})
export const receivedDateAvailabitySuccess = (hoursArray:? Array) : GenericActionType => ( {
    type: GET_DATE_AVAILABILITY_SUCCESS,
    hoursArray,
}
)
export const receivedDateAvailabityError = () : GenericActionType => ( {
    type: GET_DATE_AVAILABILITY_ERROR,
}
)
export const receivedReservationHours = (hours:?string) : GenericActionType => ( {
    type: SAVE_RESERVATION_HOURS,
    hours

})
export const addReservationSuccess = (reservation:?Object) : GenericActionType => ({
    type: ADD_RESERVATION_SUCCESS,
    reservation
}
)
export const addReservationError = () : GenericActionType => ( {
    type: ADD_RESERVATION_ERROR,
}
)
export const showReservationSuccessMessage = () : GenericActionType => ({
    type : SHOW_RESERVATION_SUCCESS_MESSAGE,
})
export const closeReservationSuccessMessage = () : GenericActionType => ({
    type : CLOSE_RESERVATION_SUCCESS_MESSAGE,
})
export const emptyReservationSelection = () : GenericActionType  => ( {
    type: EMPTY_SELECTED_RESERVATIONS,
})

//Sectors
export const receivedSectorsError = (): GenericActionType => ({
    type: GET_SECTORS_ERROR,
});
export const receivedSectorsSuccess = (reservations): GenericActionType => ({
    type: GET_SECTORS_SUCCESS,
    reservations
});
