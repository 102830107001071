/* eslint-disable react/prop-types */
/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useContext } from 'react';

const Context = React.createContext({});
const IntlContextProvider = (props) => (
    <Context.Provider value={{ intl: props.intl }}>{props.children}</Context.Provider>
);

IntlContextProvider.propTypes = {};
IntlContextProvider.defaultProps = {};
export default React.memo(IntlContextProvider);
export const useIntlContext = () => useContext(Context);
