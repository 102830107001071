/* eslint-disable prefer-arrow-callback */
// @flow

// IMPORTS
import { LOCATION_CHANGE } from 'react-router-redux';
import  keyBy  from 'lodash/keyBy';
import { fromJS } from 'immutable';
import { vehicleSameLocation, TravelLines, parseJWT } from 'helpers';
import { v4 as uuidv4 } from 'uuid';
import { toGeoJSON as polylineToGeoJSON } from '@mapbox/polyline';

// ACTIONS
import {
    GET_VEHICLES_SUCCESS,
    GET_VEHICLES_ERROR,
    GET_VEHICLES_EVENT_TYPES,
    GET_VEHICLE_HISTORY_SUCCESS,
    TOGGLE_VEHICLE_EQUIPMENT,
    MAP_SELECTED_EQUIPMENT,
    SET_VEHICLE_FILTER,
    GET_VEHICLE_HISTORY_ERROR,
    GET_EMPTY_HISTORY,
    GET_VEHICLE_DATA_ERROR,
    DISMISS_MAP_ERROR,
    DISMISS_MAP_EMPTY_HISTORY,
    LOADING_VEHICLES,
    LOADING_VEHICLE,
    LOADING_VEHICLE_HISTORY,
    LOADING_EQUIPMENT_POPUP,
    GET_EVENT_GROUPS_SUCCESS,
    GET_VEHICLE_REQUEST,
    CLEAR_MAP_SELECTED_EQUIPMENT,
    CLEAR_VEHICLE_HISTORY,
    CLEAR_VEHICLES,
    TOGGLE_MASKED_POINTS,
    TOGGLE_ROUTE_MATCHING,
    GET_VEHICLE_EXT_WEB_H,
    CHANGE_WEB_HOOK_STATE_SUCCESS,
    CHANGE_WEB_HOOK_STATE_LOADING,
    CHANGE_WEB_HOOK_STATE_ERROR,
    CHANGE_VEHICLES_SORT_ORDER,
    GET_VEHICLE_HISTORY_DETAIL_SUCCESS,
    GET_FLEET_EQUIPMENT_SUCCESS,
    GET_FLEET_EQUIPMENT_ERROR,
    CLEAR_VEHICLE_HISTORY_DETAIL,
    CLEAR_FLEET_EQUIPMENT,
    TOGGLE_LOADED_VEHICLE_HISTORY,
    REALTIME_DATA_RECEIVED,
    STOP_LOADING,
    GET_CONTROL_COLORS,
    GET_GARAGE_MODE,
} from './actions';

// TYPES
import type { GenericActionType, VehicleListType } from 'types';
import { lineString, length as lineLength } from '@turf/turf';

// DEFAULT STATE
const initialState = fromJS({
    // vehicles: JSON.parse(JSON.stringify(mock)),
    vehicles: {},
    temperatureVehicles: {},
    eventTypes: {},
    vehiclePopout: null,
    vehiclePopoutIndex: 0,
    vehiclePopoutCoordinate: null,
    vehicleHistoryDetail: [],
    fleetEquipment: [],
    fleetEquipmentNextPage: 0,
    equipmentHistory: [],
    historyHasNextPage: false,
    selectedHistoryId: -1,
    filterEvents: [],
    vehicleError: false,
    loading: true,
    loadingVehicleHistory: false,
    loadingVehicle: false,
    loadingEquipmentPopup: false,
    historyPopout: false,
    eventGroups: [],
    isMaskedPoints: false,
    isRouteMatching: true,
    isLoadedVehicleHistory: false,
    sort: 'since',
    order: 'desc',
    controlColors: {
        actif: null,
        actif_pending: null,
        inactif: null,
        inactif_pending: null,
    },
    garageMode: {
        garageModeAct: null,
        garageModeChanged: null,
    },
});

// MAIN REDUCER
/**
 * @param {*} state
 * @param {*} action
 */

const addTimezone = (date) => {
    if (typeof date === 'string') {
        if (!date.includes('+')) {
            date = date + '+0';
        }
    }
    return date;
};

const vehicleServiceReducer = (state: Object = initialState, action: GenericActionType) => {
    switch (action.type) {
        case GET_VEHICLE_EXT_WEB_H: {
            return state.set('vehicle_ext_web_hook', action.vehicleTypes.data);
        }
        case GET_CONTROL_COLORS: {
            return state.set('controlColors', action.controlColors);
        }
        case GET_GARAGE_MODE: {
            return state.set('garageMode', action.garageMode);
        }
        case CHANGE_WEB_HOOK_STATE_SUCCESS: {
            return state.set('change_web_hook_success', true);
        }
        case CHANGE_WEB_HOOK_STATE_ERROR: {
            return state.set('change_web_hook_error', action.error);
        }
        case CHANGE_WEB_HOOK_STATE_LOADING: {
            return state.set('changeWebHookStateLoading', true);
        }
        case CHANGE_VEHICLES_SORT_ORDER: {
            return state.set('sort', action.sort).set('order', action.order);
        }
        case CLEAR_VEHICLES: {
            return state
                .set('loading', true)
                .set('vehicleMap', new Map())
                .set('vehicles', fromJS({}));
        }
        case GET_VEHICLE_REQUEST: {
            return state.set('loading', true);
        }
        case GET_VEHICLES_SUCCESS: {
            let map = state.get('vehicleMap');
            if (!map) {
                map = new Map();
            }
            const jwt = parseJWT(sessionStorage.getItem('api_token'));
            const branchId = jwt['https://geothentic.com/branch_id'];
            action.vehicles.forEach((veh) => {
                if (branchId == veh.branchId) {
                    map.set(veh.id, veh);
                }
            });
            const arr = Array.from(map, (item) => {
                return item[1];
            });

            // sort by since
            const sort = state.get('sort').trim();
            const order = state.get('order').trim();

            const sortFunc = function(a, b) {
                if (sort === 'since') {
                    const x = addTimezone(a.since ? a.since : 0);
                    const y = addTimezone(b.since ? b.since : 0);

                    return new Date(x) - new Date(y);
                }
                if (sort === 'name') {
                    return ('' + b.name).localeCompare(a.name);
                }
            };
            arr.sort(sortFunc);
            if (order === 'desc') {
                arr.reverse();
            }

            let vehicles = vehicleSameLocation(fromJS(arr), state.get('vehicles'));
            let temperatureVehs = state.get('temperatureVehicles');
            if (action.internalResponse === false) {
                temperatureVehs = fromJS(
                    arr.filter(
                        (e) =>
                            (e.configuration && e.configuration.rs232Active === true) ||
                            (e.configuration && e.configuration.aux2TempActive === true) ||
                            (e.configuration && e.configuration.aux3TempActive === true) ||
                            (e.configuration && e.configuration.aux4TempActive === true)
                    )
                );
            }
            return state
                .set('vehicleMap', map)
                .set('vehicles', vehicles)
                .set('temperatureVehicles', temperatureVehs)
                .set('loading', false);
        }
        case GET_VEHICLES_EVENT_TYPES: {
            const eventTypes = keyBy(action.eventTypes.data, 'eventTypeId');
            return state.set('eventTypes', fromJS(eventTypes));
        }
        case GET_VEHICLE_HISTORY_SUCCESS: {
            const equipmentHistory = state.get('equipmentHistory');
            const equipmentIndex = equipmentHistory.findIndex(
                (equipment) => equipment.get('id').toString() === action.equipmentId.toString()
            );

            if (equipmentIndex >= 0) {
                return state
                    .set(
                        'equipmentHistory',
                        equipmentHistory.update(equipmentIndex, (equipment) =>
                            fromJS({
                                id: action.equipmentId,
                                waypoints: action.waypoints,
                                historyCoordinates: action.historyCoordinates,
                                matchingLineString: action.matchingLineString,
                                rawLineString: action.rawLineString,
                                pageNumber: action.pageNumber,
                                hasNext: action.hasNext,
                            })
                        )
                    )
                    .set('loadingEquipmentPopup', false)
                    .set('historyHasNextPage', action.hasNext)
                    .set('loadingVehicleHistory', false)
                    .set('loading', false);
            }

            return state
                .set(
                    'equipmentHistory',
                    equipmentHistory.update((equipment) =>
                        equipmentHistory.push(
                            fromJS({
                                id: action.equipmentId,
                                waypoints: action.waypoints,
                                historyCoordinates: action.historyCoordinates,
                                matchingLineString: action.matchingLineString,
                                rawLineString: action.rawLineString,
                                pageNumber: action.pageNumber,
                                hasNext: action.hasNext,
                            })
                        )
                    )
                )
                .set('vehicleError', false)
                .set('loadingEquipmentPopup', false)
                .set('historyHasNextPage', action.hasNext)
                .set('loadingVehicleHistory', false)
                .set('loading', false);
        }
        case TOGGLE_VEHICLE_EQUIPMENT: {
            let id = action.equipmentId;
            let newState = state;
            if (state.get('selectedHistoryId') === action.equipmentId) {
                id = -1;
                newState = state.set('equimentHistory', fromJS([]));
            }

            return newState
                .set('selectedHistoryId', id)
                .set('vehiclePopout', null)
                .set('historyPopout', false);
        }
        case MAP_SELECTED_EQUIPMENT: {
            const popout = fromJS(action.vehicles) || null;
            const coordinates = fromJS(action.coordinate) || null;
            const isHistoryPopout = action.history || false;
            const index = action.index || 0;

            if (state.get('selectedHistoryId') > 0 && !isHistoryPopout && popout) {
                // we're opening a new vehicle details pop out.
                return state
                    .set('selectedHistoryId', -1)
                    .set('vehiclePopout', popout)
                    .set('vehiclePopoutIndex', 0)
                    .set('vehiclePopoutCoordinate', coordinates)
                    .set('historyPopout', false)
                    .set('loadingVehicle', false)
                    .set('loadingEquipmentPopup', false);
            }

            return state
                .set('vehiclePopout', popout)
                .set('vehiclePopoutIndex', index)
                .set('vehiclePopoutCoordinate', coordinates)
                .set('historyPopout', isHistoryPopout)
                .set('loadingVehicle', false)
                .set('loadingEquipmentPopup', false);
            // .set('equipmentHistory', fromJS([])); // @TODO: remove
        }
        case CLEAR_MAP_SELECTED_EQUIPMENT: {
            return state
                .set('vehiclePopout', null)
                .set('vehiclePopoutIndex', 0)
                .set('historyPopout', false)
                .set('loadingVehicle', false)
                .set('loadingEquipmentPopup', false);
        }
        case SET_VEHICLE_FILTER: {
            return state.set('filterEvents', action.filterEvents);
        }
        case LOCATION_CHANGE: {
            return state
                .set('selectedHistoryId', -1)
                .set('vehiclePopout', null)
                .set('vehicleError', false)
                .set('historyPopout', false);
        }
        case GET_VEHICLES_ERROR: {
            // HERE
            return state
                .set('vehicleError', action.error || true)
                .set('loading', false)
                .set('loadingVehicle', false)
                .set('loadingVehicleHistory', false)
                .set('loadingEquipmentPopup', false);
        }
        case GET_VEHICLE_HISTORY_ERROR: {
            return state
                .set('vehicleError', action.error || true)
                .set('loadingEquipmentPopup', false);
        }
        case GET_VEHICLE_DATA_ERROR: {
            return state.set('vehicleError', true).set('loadingEquipmentPopup', false);
        }
        case GET_EMPTY_HISTORY: {
            return state.set('mapEmptyHistory', true).set('loadingEquipmentPopup', false);
        }
        case DISMISS_MAP_ERROR:
            return state.set('vehicleError', false);
        case DISMISS_MAP_EMPTY_HISTORY:
            return state.set('mapEmptyHistory', false);
        case LOADING_VEHICLES:
            // return state.set('selectedHistoryId', -1)
            //     .set('vehiclePopout', null)
            //     .set('vehicleError', false)
            //     .set('historyPopout', false)
            //     .set('loading', true);
            return state.set('vehicleError', false).set('loading', true);
        case LOADING_VEHICLE:
            return state.set('vehicleError', false).set('loadingVehicle', true);
        case LOADING_VEHICLE_HISTORY:
            return state.set('vehicleError', false).set('loadingVehicleHistory', true);
        case STOP_LOADING:
            return state.set('vehicleError', false).set('loadingVehicleHistory', false);
        case LOADING_EQUIPMENT_POPUP:
            return state.set('vehicleError', false).set('loadingEquipmentPopup', true);
        case GET_FLEET_EQUIPMENT_ERROR:
            return state
                .set('vehicleError', false)
                .set('fleetEquipment', [])
                .set('fleetEquipmentNextPage', 0);
        case CLEAR_VEHICLE_HISTORY:
            return state
                .set('vehicleError', false)
                .set('equipmentHistory', fromJS([]))
                .set('historyHasNextPage', false);
        case GET_EVENT_GROUPS_SUCCESS:
            return state.set('eventGroups', fromJS(keyBy(action.payload.data, 'id')));
        case TOGGLE_MASKED_POINTS:
            return state.set('isMaskedPoints', !state.get('isMaskedPoints'));
        case TOGGLE_ROUTE_MATCHING:
            return state.set('isRouteMatching', !state.get('isRouteMatching'));
        case GET_VEHICLE_HISTORY_DETAIL_SUCCESS:
            return state
                .set('vehicleHistoryDetail', [
                    ...state.get('vehicleHistoryDetail'),
                    ...action.vehicleHistory,
                ])
                .set('loadingVehicle', false);
        case GET_FLEET_EQUIPMENT_SUCCESS:
            if (action.isTempReport !== undefined) {
                return state
                    .set('fleetEquipment', [...action.fleetEquipment])
                    .set('fleetEquipmentNextPage', action.nextPage)
                    .set('loadingVehicle', false);
            } else {
                return state
                    .set('fleetEquipment', [
                        ...state.get('fleetEquipment'),
                        ...action.fleetEquipment,
                    ])
                    .set('fleetEquipmentNextPage', action.nextPage)
                    .set('loadingVehicle', false);
            }
        case CLEAR_VEHICLE_HISTORY_DETAIL:
            return state.set('vehicleHistoryDetail', []);
        case CLEAR_FLEET_EQUIPMENT:
            return state.set('fleetEquipment', []).set('fleetEquipmentNextPage', 0);
        case TOGGLE_LOADED_VEHICLE_HISTORY:
            return state.set('isLoadedVehicleHistory', !state.get('isLoadedVehicleHistory'));
        case REALTIME_DATA_RECEIVED:
            let vehicles = state.get('vehiclesAnimate');

            const data = JSON.parse(action.data);

            if (!vehicles) {
                vehicles = new Map();
            }

            const now = Date.now() / 1000;
            let lastUpdate = now - 1;

            let lastTargetArrival = lastUpdate;

            let vehicle = vehicles.get(data.id);

            if (!vehicle) {
                vehicle = {};
                vehicle.id = data.id;
                vehicle.animation = {};
                vehicle.received = now;
                vehicle.animation.lastUpdated = lastUpdate;
                vehicle.animation.lastFrame = now;

                vehicle.targetLocation = {};
                vehicle.targetLocation.longitudeWgs84 = data.longitude;
                vehicle.targetLocation.latitudeWgs84 = data.latitude;

                vehicle.latestRealTime = {};
                vehicle.latestRealTime.longitudeWgs84 = data.longitude;
                vehicle.latestRealTime.latitudeWgs84 = data.latitude;
            }

            let travelLines = vehicle.travelLines;
            if (!travelLines) {
                travelLines = new TravelLines(100);
                vehicle.travelLines = travelLines;
            }

            const lastLine = travelLines.peek();

            // console.log("lastLine.lastUpdate: " + lastLine.lastUpdate);
            if (lastLine && lastLine.lastUpdate) {
                lastUpdate = lastLine.lastUpdate;
                lastTargetArrival = lastLine.targetArrivalTime;
            } else if (vehicle.animation.lastUpdated) {
                lastUpdate = vehicle.animation.lastUpdated;
            }

            const travelLineData = {};

            const uuid = uuidv4().toString();
            travelLineData.uuid = uuid;

            const travelTime = now - vehicle.received;
            vehicle.received = now;
            travelLineData.targetArrivalTime = now + travelTime;
            //            console.log("now: " + now +  "travelLineData.targetArrivalTime :" +  travelLineData.targetArrivalTime + " " + (travelLineData.targetArrivalTime - now) );
            const options = { units: 'kilometers' };
            if (data.polyline6) {
                const line = polylineToGeoJSON(data.polyline6, 6);
                const length = lineLength(line, options);
                const kilometersPerSecond = length / travelTime;

                travelLineData.line = line;
                travelLineData.kilometersPerSecond = kilometersPerSecond;
                travelLineData.length = length;
            }
            travelLineData.longitudeWgs84 = data.longitude;
            travelLineData.latitudeWgs84 = data.latitude;

            travelLines.push(travelLineData);

            // New Update,  restart "stop" flag
            vehicle.stop = false;
            vehicle.animation.lastUpdated = lastUpdate;
            vehicle.targetLocation.longitudeWgs84 = data.longitude;
            vehicle.targetLocation.latitudeWgs84 = data.latitude;
            vehicle.realtime = true;

            vehicles.set(data.id, vehicle);
            if (!state.get('vehiclesAnimate')) {
                vehicles.set(data.id, vehicle);
                return state.set('vehiclesAnimate', vehicles);
            } else {
                state.get('vehiclesAnimate').set(data.id, vehicle);
                return state.set('vehiclesAnimate', vehicles); //.set('vehicles', vehicles);
            }
        default:
            return state;
    }
};

// EXPORTS
export default vehicleServiceReducer;
