/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype-errors/show-errors */
// @flow

import io from 'socket.io-client';
import { listener } from 'services/socket/thunks';
import { WS_URL } from 'env';

// Example conf. You can move this to your config file.
const wshost = WS_URL; // 'wss://ws.staging.geothenticdev.live';
class SocketClient {
    socket;

    connect(token: string) {
        try {
            this.socket.disconnect();
        }
        catch(e) {

        }
        this.socket = io.connect(
            wshost,
            {
                transports: ['websocket'],
                reconnection: false,
            }
        );
        console.log(this.socket);
        return new Promise((resolve, reject) => {
            const tmpSocket = this.socket;
            this.socket.on('connect', () => {
                tmpSocket.emit('authentication', {
                    token,
                });
                // console.log(dataReceived);
                // resolve();
            });
            this.socket.on('connect_error', (error) => reject(error));
        });
    }

    disconnect() {
        return new Promise((resolve) => {
            this.socket.disconnect(() => {
                this.socket = null;
                resolve();
            });
        });
    }

    emit(event, data) {
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('No socket connection.');

            return this.socket.emit(event, data, (response) => {
                // Response is the optional callback that you can use with socket.io in every request. See 1 above.
                if (response.error) {
                    console.error(response.error);
                    return reject(response.error);
                }

                return resolve();
            });
        });
    }

    on(event, fun) {
        // No promise is needed here, but we're expecting one in the middleware.
        return new Promise((resolve, reject) => {
            if (!this.socket) return; // reject('No socket connection.');

            this.socket.on(event, fun);
            resolve();
        });
    }
}

const instance = new SocketClient();
export default instance;
