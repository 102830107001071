// @flow

import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS, toJS } from 'immutable';
import {
    USER_AUTHENTICATION_REQUEST,
    USER_AUTHENTICATION_SUCCESS,
    USER_AUTHENTICATION_FAIL,
    WHOAMI_SUCCESS,
    WHOAMI_FAIL,
    LOGOUT_SUCCESS,
    LOCAL_STORAGE_USER_TOKEN,
    USER_FORGOT_PASSWORD_FAIL,
    USER_FORGOT_PASSWORD_SUCCESS,
    ADMIN_LOGIN_AS_USER,
    ADMIN_SPOOF_USER,
    USER_RESET_PASSWORD_SUCCESS,
    PASSWORD_RESET_ERROR,
    PASSWORD_CREATE_ERROR,
    LOGIN_AS_USER_DELETE,
    ADMIN_LOGOUT_AS_USER,
    USER_RENEW_SESSION_SUCCESS,
} from './actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    authenticationRequest: false,
    user: { user: null, expiresAt: new Date() },
    whoamiComplete: false,
    errors: {},
    loggedInAsUser: null,
    showModal: false,
    passwordResetSent: false,
    forgotPasswordError: true,
    passwordResetError: '',
    passwordCreateError: '',
});

function authenticationServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case USER_AUTHENTICATION_REQUEST:
            return state.set('errors', fromJS({})).set('authenticationRequest', true);
        case USER_AUTHENTICATION_SUCCESS: {
            const user = {
                token: action.payload,
                expiresAt: action.expiresAt,
            };
            return state
                .set('user', fromJS(user))
                .set('errors', fromJS({}))
                .set('authenticationRequest', false)
                .set('whoamiComplete', true);
        }
        case USER_AUTHENTICATION_FAIL: {
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('authenticationRequest', false);
        }
        case WHOAMI_SUCCESS:
            return state
                .set('user', fromJS(action.payload.user))
                .set('errors', fromJS({}))
                .set('whoamiComplete', true);
        case WHOAMI_FAIL:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('whoamiComplete', true)
                .set('user', null);
        case LOGOUT_SUCCESS:
            return state.set('user', null).set('errors', fromJS({}));
        case LOCATION_CHANGE:
            if (state.get('errors') && state.get('errors').size !== 0) {
                return state.set('errors', fromJS({}));
            }
            return state
                .set('showModal', false)
                .set('passwordResetSent', false)
                .set('passwordResetErrors', '');
        case LOCAL_STORAGE_USER_TOKEN: {
            const user = {
                token: action.payload.userToken,
            };
            return state.set('user', fromJS(user));
        }
        case ADMIN_LOGIN_AS_USER: {
            return state
                .set('loggedInAsUser', fromJS(action.payload.user))
                .set('user', fromJS({ token: action.payload.token }));
        }
        case ADMIN_LOGOUT_AS_USER: {
            return state.set('loggedInAsUser', null).set('user', fromJS(action.payload));
        }
        case ADMIN_SPOOF_USER: {
            return state.set('loggedInAsUser', fromJS(action.payload));
        }
        case USER_RESET_PASSWORD_SUCCESS: {
            return state;
        }
        case USER_FORGOT_PASSWORD_FAIL:
        case USER_FORGOT_PASSWORD_SUCCESS: {
            return state.set('passwordResetSent', true);
        }
        case PASSWORD_RESET_ERROR: {
            return state.set('resetPasswordError', action.error);
        }
        case PASSWORD_CREATE_ERROR: {
            return state.set('createPasswordError', action.error);
        }
        case LOGIN_AS_USER_DELETE: {
            return state.set('loggedInAsUser', fromJS(action.payload));
        }
        case USER_RENEW_SESSION_SUCCESS: {
            const user = {
                token: action.payload,
                expiresAt: action.expiresAt,
            };
            return state.set('user', fromJS(user));
        }
        default:
            return state;
    }
}

export default authenticationServiceReducer;
