// @flow

import { createSelector } from 'reselect';

const selectReportState = () => (state) => state.get('report');

export const selecDrivingReportGenerationErrors = () =>
    createSelector(selectReportState(), (substate) =>
        substate.get('drivingReportGenerationErrors')
    );

export const selectMoveReportGenerationErrors = () =>
    createSelector(selectReportState(), (substate) => substate.get('moveReportGenerationErrors'));

export const selectEconomieReportGenerationErrors = () =>
    createSelector(selectReportState(), (substate) =>
        substate.get('economieReportGenerationErrors')
    );

export const selectIsLoading = () =>
    createSelector(selectReportState(), (substate) => substate.get('loading'));

export const selectDrivingReport = () =>
    createSelector(selectReportState(), (substate) => substate.get('drivingReportData'));

export const selectMoveReport = () =>
    createSelector(selectReportState(), (substate) => substate.get('moveReportData'));

export const selectMoveV1Report = () =>
    createSelector(selectReportState(), (substate) => substate.get('moveV1ReportData'));

export const selectMoveModal = () =>
    createSelector(selectReportState(), (substate) => substate.get('moveReportModal'));

export const selectDrivingReportModal = () =>
    createSelector(selectReportState(), (substate) => substate.get('drivingReportModal'));

export const selectGeofencingHistoryReport = () =>
    createSelector(selectReportState(), (substate) => substate.get('geofencingHistoryData'));

export const selectEconomieReport = () =>
    createSelector(selectReportState(), (substate) => substate.get('economieReportData'));

export const selectEconomieReportPagination = () =>
    createSelector(selectReportState(), (substate) => substate.get('economieReportpaginated'));

export const selectDrivingBehaviourReport = () =>
    createSelector(selectReportState(), (substate) => substate.get('drivingBehaviourReportData'));

export const selectDashboardReportSummary = () =>
    createSelector(selectReportState(), (substate) => substate.get('dashboardReportSummary'));

export const selectIsLoadingDashboardReportSummary = () =>
    createSelector(selectReportState(), (substate) =>
        substate.get('loadingDashboardReportSummary')
    );

export const selectDashboardRealtimesSummary = () =>
    createSelector(selectReportState(), (substate) => substate.get('dashboardRealtimesSummary'));

export const selectIsLoadingDashboardRealtimesSummary = () =>
    createSelector(selectReportState(), (substate) =>
        substate.get('loadingDashboardRealtimesSummary')
    );

export const selectDashboardGeofenceSummary = () =>
    createSelector(selectReportState(), (substate) => substate.get('dashboardGeofenceSummary'));

export const selectIsLoadingDashboardGeofenceSummary = () =>
    createSelector(selectReportState(), (substate) =>
        substate.get('loadingDashboardGeofenceSummary')
    );

export const selectDashboardInstantSummary = () =>
    createSelector(selectReportState(), (substate) => substate.get('dashboardInstantSummary'));

export const selectIsLoadingDashboardInstantSummary = () =>
    createSelector(selectReportState(), (substate) =>
        substate.get('loadingDashboardInstantSummary')
    );

export const selectMultipleHistoryRecords = () =>
    createSelector(selectReportState(), (substate) => substate.get('multipleHistoryData'));

export const selectMoveInfo = () =>
    createSelector(selectReportState(), (substate) => substate.get('moveInfo'));

export const selectMoveType = () =>
    createSelector(selectReportState(), (substate) => substate.get('moveReportSummary'));

export const selectDrivingInfo = () =>
    createSelector(selectReportState(), (substate) => substate.get('drivingInfo'));