// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

const OrganizationList = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: ${(props) => (!props.isOpen ? '30px 50px 0 110px;' : '30px 50px 0 50px;')};
`;

OrganizationList.Item = styled.div`
    grid-column: span 4;
    cursor: pointer;
`;

OrganizationList.Container = styled.div``;

OrganizationList.GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 60px);
    ${OrganizationList.Item}:nth-child(odd) {
        background-color: ${colors.grayF8};
    }
`;

OrganizationList.Split = styled.div`
    height: ${(props) => props.height || '1px'};
    background-color: ${(props) => props.color || `${colors.black}`};
`;

OrganizationList.HeaderGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 35px);
`;

OrganizationList.HeaderCaret = styled.img`
    width: 14px;
    height: 9px;
`;

OrganizationList.HeaderText = styled.div`
    margin-right: 14px;
    font-weight 600;
    float: left;
`;

OrganizationList.Header = styled.div`
    height: 35px;
`;

OrganizationList.HeaderItem = styled.div`
    cursor: pointer;
`;

OrganizationList.FilterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 50px;

    > div {
        width: 100%;
        margin-right: 0;
    }

    @media (min-width: ${screen.large}) {
        padding-right: 23px;

        > div {
            width: auto;
            margin-right: 10px;
        }
    }
`;
OrganizationList.SearchContainer = styled.div`
    margin-right: 10px;
`;

OrganizationList.ControllerContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

OrganizationList.ControllerHeader = styled.div`
    display: felx;
    align-items: center;
    flex: 1;
    font-weight: 600;
    font-size: 22px;
`;

OrganizationList.ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;
OrganizationList.ActionButtonContainer = styled.div`
    flex: 1;
    margin-left: 10px;
    ${(props) => (props.shrink ? 'flex-grow: 0.2' : null)};
`;

OrganizationList.NewOrgContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

OrganizationList.LoginButton = styled.button`
    display: flex;
    font-family: Gotham-Medium;
    color: ${colors.white};
    background-color: ${colors.sideMenuGreen};
    border: none;
    width: 100%;
    height: 45px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 16px;
    @media screen and (min-width: ${screen.large}) {
        margin-top: 0;
        width: 180px;
    }
`;

export default OrganizationList;
