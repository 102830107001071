import styled from 'styled-components';
import colors from 'styles/colors';
import { Input, Select } from 'antd';

const CatogeriesStyle = styled.div`
    border-radius: 5px;
    border: 1px solid ${colors.green61};
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    height: 95%;
    margin: auto;

    .addBtn {
        background-color: ${colors.green57};
        color: ${colors.white};
        border-color: ${colors.green57};
    }
`;

CatogeriesStyle.VehicleList = styled.div`
    width: 80%;
    margin: auto;

    .searchInput{
        width: 100%;
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: ${colors.white};
    }

    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background: ${colors.white};
    }

    .confirmSelection, .confirmSelection :focus, .confirmSelection :hover {
        background-color: ${colors.green57};
        color: ${colors.white};
        border-color: ${colors.green57};
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .categoryFilter{
        width: 15%;
        .buttonCatFilter{
            width: 100%;
            height: 36px;
            background: #62ab36;
            cursor: pointer;
            color: white;
            font-size: 13px;
            font-weight: 600;
            font-family: OpenSans;
            border: 1px;

            svg{
                width: 23px;
                height: 15px ;
            }
        }
        .checkboxCategory{
            transition: display 0, opacity 1s linear;
            width: 30% ;
            position: absolute ;
            z-index: 1;
            padding: 10px;
            display:none;
            opacity:0;
            right: 14%;
        }
        .checkboxContainer {
            background: white;
            padding: 10px ;
            justify-content:start;
            gap: 10px 40px ;
            flex-flow: wrap ;
            margin-top: 10px ;
            border: 1px solid black;

            [type=button]{
                background-color:white ;
                border:1px solid black;
                border-radius:3px;

                +div{
                    display: flex;
                    flex-wrap:wrap ;
                    padding:10px 0 0 2px;
                }
            }
        }
    }
`;


CatogeriesStyle.Select = styled(Select)`
    width: 52%;
    margin-top: 5px;
    margin-bottom: 5px;

`;

export default CatogeriesStyle;
