import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styled from 'styled-components';

const AutoComplete = styled(Autocomplete)`
    & .MuiInputBase-input {
        height: 10px;
        width: 50px;
    }
`;

AutoComplete.TextField = styled(TextField)`
    .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 14px;
    }
    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{

    }
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
        font-size: 14px;
        height: 35px;
    }
    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
        height: 35px;
    }
    .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
        width: 100%;
    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
        height: 35px;
    }
`;

export default AutoComplete;
