import React from 'react';
import { connect } from 'react-redux';
import { authenticateUser } from 'services/Authentication/thunks';
import { Wrapper } from './style';
import { push } from 'react-router-redux';
import auth from 'services/Authentication/auth0';
import qs from 'querystring';

class Callback extends React.Component {
    componentDidMount() {
        if (this.props.parsedHash.access_token) {
            this.props.authenticateUser(
                this.props.parsedHash.access_token,
                this.props.parsedHash.expires_in,
                this.props.parsedSearch.isAdmin !== 'false',
                this.props.parsedSearch.returnTo
            );
        } else {
            this.props.push('/');
        }
    }

    render() {
        return <Wrapper>Loading...</Wrapper>;
    }
}

const mapStateToProps = () => ({});

const mapDispatch = { authenticateUser, push };

const parseURL = (stateProps, dispatchProps, ownProps) => {
    let parsedHash = {};
    if (ownProps.location.hash) {
        parsedHash = ownProps.location.hash
            .substr(1)
            .split('&')
            .reduce((prev, curr) => {
                const [field, val] = curr.split('=');
                prev[field] = val;
                return prev;
            }, {});
    }
    const parsedSearch = qs.parse(ownProps.location.search.substr(1));
    return { ...stateProps, ...dispatchProps, ...ownProps, parsedHash, parsedSearch };
};

export default connect(
    mapStateToProps,
    mapDispatch,
    parseURL
)(Callback);
