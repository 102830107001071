import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import professionalJourneyToggle from '../../assets/professionalJourneyToggle.svg'
import personalJourneyToggle from '../../assets/personalJourneyToggle.svg'

const ToggleProPersoStyle = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url(${personalJourneyToggle})`,
                border: 'thin solid #60A4A6',
                borderRadius: '50%'
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#60A4A6' : '#60A4A6',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#fffbfc' : '#fffbfc',
        width: 31,
        height: 31,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${professionalJourneyToggle})`,
            border: 'thin solid #657070',
            borderRadius: '50%'
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#D79862' : '#D79862',
        borderRadius: 20 / 2,
    },
}));

export default ToggleProPersoStyle;

