// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import TestOrganizationImage from 'assets/test-organization.png';
import StyledSelect from './styles';

type Props = {
    disabled: ?boolean,
    options: ?Array<Object>,
    placeholder: ?string,
    borderColor: ?string,
    textColor: ?string,
    center: ?boolean,
    backgroundColor: ?string,
    hoverBackgroundColor: ?string,
    arrowColor: ?string,
    onChange: ?(data: *) => void,
    value?: string | number,
    searchable: ?boolean,
    maxWidth: ?number,
    onInputChange: ?(input: string) => void,
};

type SelectOption = {
    label: string,
    value: any,
};

class SelectFieldComponent extends React.PureComponent<Props> {
    handleOnChange = (input: SelectOption) => {
        this.props.onChange(input.value);
    };

    handleOnInputChange = (input: SelectOption) => {
        this.props.onInputChange(input);
    };

    getValueFromOptions = (value: any) => {
        return this.props.options && this.props.options.length
            ? this.props.options.find((option: SelectOption) => option.value === value)
            : null;
    };

    render() {
        const value = this.getValueFromOptions(this.props.value);
        return (
            <StyledSelect.Container
                minWidth={this.props.minWidth}
                disabled={this.props.disabled}
                maxWidth={this.props.maxWidth}
                onScroll={this.props.onScroll || null}
                center={(this.props.center || false).toString()}
                borderColor={this.props.borderColor}
                textColor={this.props.textColor}
                arrowColor={this.props.arrowColor}
                backgroundColor={this.props.backgroundColor}
                hoverBackgroundColor={this.props.hoverBackgroundColor}
            >
                <StyledSelect
                    menuPlacement={this.props.menuPlacement || 'bottom'}
                    className={'react-select-container'}
                    classNamePrefix={'react-select'}
                    onChange={this.handleOnChange}
                    onInputChange={
                        this.props.searchable &&
                        this.props.onInputChange &&
                        typeof this.props.onInputChange === 'function'
                            ? this.handleOnInputChange
                            : null
                    }
                    options={this.props.options}
                    isSearchable={this.props.searchable || false}
                    placeholder={this.props.placeholder}
                    value={value || this.props.value}
                    isDisabled={this.props.disabled}
                />
            </StyledSelect.Container>
        );
    }
}

export default SelectFieldComponent;
