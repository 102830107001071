// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectDivisionState = () => (state) => state.get('division');

const selectCurrentDivision = () =>
    createSelector(selectDivisionState(), (substate) => substate.get('selectedDivision'));

const selectDivisionUsers = () =>
    createSelector(selectDivisionState(), (substate) =>
        substate.getIn(['selectedDivision', 'users'])
    );

export { selectCurrentDivision, selectDivisionUsers };
