// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const Dashboard = styled.div`
    ${(props) => (props.loading ? 'opacity: 0.3;' : 'opacity: 1.0;')};
`;

Dashboard.HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    margin-bottom: 20px;
    &:first-child {
        padding-left: 15px;
    }
    grid-column-gap: 15px;
    font-family: OpenSans;
`;
Dashboard.SummaryHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    margin-bottom: 5px;
    &:first-child {
        padding-left: 15px;
    }
    grid-column-gap: 15px;
    font-family: OpenSans;
`;
Dashboard.HeaderItem = styled.div`
    cursor: pointer;
`;
Dashboard.HeaderText = styled.div`
    margin-right: 14px;
    font-weight 600;
    float: left;
`;
Dashboard.HeaderCaret = styled.img`
    width: 14px;
    height: 9px;
`;

Dashboard.DataItem = styled.div`
    grid-column: span ${(props) => props.columns || '3'};
    ${(props) => props.onClick && 'cursor: pointer;'};
    padding-left: 15px;
`;

Dashboard.DataItemCustom = styled.div`
    background: ${(props) => props.color};
    grid-column: span ${(props) => props.columns || '3'};
    ${(props) => props.onClick && 'cursor: pointer;'};
    padding-left: 15px;
    ${Dashboard.DataItemCustom}:nth-child(odd) {
        background-color: ${colors.grayE6};
    }
`;

Dashboard.GridContainerCustom = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns || '3'}, 1fr);
    grid-template-rows: ;
`;

Dashboard.Split = styled.div.attrs({
    columns: (props) => props.columns || '3',
    height: (props) => props.height || '1px',
    color: (props) => props.color || `${colors.black}`,
})`
    height: ${(props) => props.height};
    grid-column: span ${(props) => props.columns};
    background-color: ${(props) => props.color};
`;
Dashboard.SummaryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    padding-top: 10px;
    &:first-child {
        padding-left: 15px;
    }
    grid-column-gap: 15px;
    font-family: OpenSans;
    grid-column: span 8;
    padding-left: 15px;
    background-color: ${colors.grayE6};
`;
Dashboard.GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns || '3'}, 1fr);
    grid-template-rows: repeat(auto-fill, 60px);
    ${Dashboard.DataItem}:nth-child(odd) {
        background-color: ${colors.grayE6};
    }
`;

Dashboard.GridItem = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columnSpan || '3'}, 1fr);
    height: 60px;
    grid-column-gap: 15px;
`;

Dashboard.ViewMore = styled.div`
    width: 100%;
    margin: 50px auto;

    @media (min-width: ${screen.large}) {
        width: 25%;
    }
`;

Dashboard.UserOptions = styled.div`
    position: relative;
`;

Dashboard.GroupButtonSpan = styled.span`
    font-size: 12px;
    white-space: nowrap;
`;

Dashboard.UserOptionsDropdown = styled.div`
    position: absolute;
    background-color: ${colors.transparent};
    color: black;
    display: flex;
    z-index: 2;
    top: 50px;
    right: 0;
`;

Dashboard.DropdownItem = styled.div`
    flex: 1;
    display: flex;
    height: 40px;
    width: 100px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.green73};
    margin-left: 10px;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 400;
    background-color: ${colors.white};
    &:hover {
        background-color: ${({ backgroundColorHovered }) =>
        backgroundColorHovered || colors.green73};
        ${({ backgroundColorHovered }) =>
        backgroundColorHovered ? `border: 1px solid ${backgroundColorHovered};` : ''}
        color: ${colors.white};
    }
`;

Dashboard.FooterContainer = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
    margin-bottom: 20px;
    &:first-child {
        padding-left: 15px;
    }
    grid-column-gap: 15px;
    font-family: OpenSans;
    grid-column: span 8;
    padding-left: 15px;
`;

Dashboard.FooterItem = styled.div`
    cursor: default;
`;
Dashboard.FooterText = styled.div` 
    margin-right: 14px; 
    font-weight 600; 
    float: left; 
`;

Dashboard.spanDriver = styled.span`
    display: inline;
    padding: 0.3em 0.6em 0.3em;
    font-size: 90%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: ${(props) => props.color};
    max-height: 25px;
`;

Dashboard.span = styled.span`
    display: inline;
    padding: 0.3em 0.6em 0.3em;
    font-size: 90%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    background-color: ${colors.green61};
    max-height: 25px;
`;

export const TableWrapper = styled.div`
    display: block;
    width: 100%;
    overflow: scroll;
`;

export const Equipment = styled.div``;
