// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';
import { Link } from 'react-router-dom';

const SideMenu = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.whiteF7};
    width: 100%;
    box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.07);
    position: relative;
    height: 100%; 
    @media (min-width: ${screen.small}) {
        width: 350px;
    }
`;

SideMenu.Container = styled.div`
    flex: 1;
    // padding-right: 40px;
    // padding-left: 40px;
    // padding-top: 40px;
    display: flex;
    flex-direction: column;
`;

SideMenu.Header = styled.div`
    background: ${(props) => props.color || colors.black};
    padding: 15px 25px;
    min-height: 80px;

    > div {
        margin-bottom: 0;
    }

    @media (min-width: ${screen.small}) {
        min-height: auto;
    }
`;

SideMenu.Split = styled.div`
    height: ${(props) => props.height || '1px'};
    background-color: ${(props) => props.color || `${colors.black}`};
    ${({ opacity }) => opacity && `opacity: ${opacity}`};
    ${(props) => props.margin && `margin: ${props.margin};`};
`;

SideMenu.ListContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 5px 25px;
`;

// background-image: linear-gradient(to right, rgba(${(props) => props.color || colors.white}, 1), rgba(${(props) => '255, 255, 255' || colors.black}, 0) 80%);
SideMenu.Item = styled.div`
    font-family: OpenSans;
    display: flex;
    align-items: center;
    height: ${(props) => props.height || 60}px;
    ${(props) => props.onClick && 'cursor: pointer;'};
`;

SideMenu.NavItem = styled(Link)`
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
    color: ${(props) => props.color || colors.black};

    > svg {
        width: 10px;
        height: auto;
        margin-right: 10px;
    }
`;

SideMenu.ItemText = styled.div`
    flex: 1;
    align-self: center;
    font-family: ${(props) => props.fontfamily || 'OpenSans'};
    font-size: ${(props) => props.fontsize || 16}px;
    ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};
    padding: ${(props) => props.padding || '0 0 0 20px'};
    color: ${colors.black};
    ${(props) =>
        props.disabled &&
        `
        opacity: 0.6;
        color: ${colors.grayED}
    `};

    > a {
        color: ${colors.black};
    }
`;

SideMenu.Close = styled.div`
    display: flex;
    cursor: pointer;
    width: 25px;
    height: 90px;
    background-color: ${colors.sideMenuBorder};
    position: absolute;
    bottom: 50%;
    right: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    justify-content: center;
    align-items: center;

    @media (min-width: ${screen.small}) {
    }
`;

SideMenu.ClosedBorder = styled.div`
    min-height: calc(100vh - 60px); // 60px being the height of the header
    height: 100vh; //calc(100vh - 60px);
    background-color: ${colors.sideMenuBorder};
    width: 5px;
    margin-right: ${(props) => (props.mapPage ? '0' : '20px')};
`;

const Header = styled.div`
    display: flex;
    font-family: OpenSans;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 30px;
    align-items: center;
`;

Header.Text = styled.div`
    color: ${(props) => props.color || colors.black};
    // padding-left: 15px;
    padding-top: 5px;
`;

const Footer = styled.div`
    height: 90px;
    display: flex;
    align-items: center;
    color: ${colors.white};
    background-color: ${colors.sideMenuBorder};
    padding-right: 40px;
    padding-left: 40px;

    span {
        font-family: OpenSans;
        font-weight: 600;
        font-size: 15px;
        color: ${colors.black};
        padding-left: 15px;
    }
`;

const Image = styled.img`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    color: ${colors.black};
    ${(props) =>
        props.disabled &&
        `
        opacity: 0.6;
    `};
`;

Image.Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-left: 5px;
`;

const SideMenuOrganization = styled.div`
    font-size: 22px;
    font-weight: 600;
`;

SideMenuOrganization.LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 25px;
`;

SideMenuOrganization.BackLink = styled.div`
    width: 100%;
    height: 60px;
    background: ${colors.black};
    font-size: 16px;
    padding: 15px 25px;
    color: ${(props) => props.color || colors.white};

    > div {
        > a {
            color: ${(props) => props.color || colors.white};
        }
    }
`;

SideMenuOrganization.Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

SideMenuOrganization.Logo = styled.div`
    height: 80px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.bgColor || colors.white};
    color: ${(props) => props.colors || colors.black};

    > div {
        color: ${(props) => props.colors || colors.black};
    }
`;

const Size = {
    normal: '420px',
    action: '480px',
};

export { SideMenu, Header, Footer, Image, Size, SideMenuOrganization };
