import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import MuiTable from 'mui-virtualized-table';

const useVirtualizedTableStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            padding: '14px',

            'background-color': '#fff',
            color: 'black',
        },

        '& .topLeftGrid, & .topRightGrid': {
            border: 'none',
            'border-bottom': '2px solid black',
            'background-color': 'white',
        },
        '& .bottomLeftGrid': {
            border: 'none',
        },

        '& [class*=MuiTable-cellHeader]': {
            'font-weight': '600',
            'font-size': '14px',
            'font-family': 'OpenSans',

            '& [class*=MuiTable-cellContents]': {
                'white-space': 'break-spaces',
            },
        },
    },
});

const cellStyleContect = (column, row, data) => {
    if (data.indexOf(row) > 0 && data.indexOf(row) % 2 !== 0) {
        if (column.name === 'firstName') {
            return {
                style: {
                    'background-color': '#E6E9EC',
                },
            };
        }

        if (column.name === 'lastLogin') {
            return {
                style: {
                    'background-color': '#E6E9EC',
                },
            };
        }

        if (column.name === 'groups') {
            return {
                style: {
                    'background-color': '#E6E9EC',
                    display: 'flex',
                },
            };
        }

        if (column.name === '') {
            return {
                style: {
                    'background-color': '#E6E9EC',
                },
            };
        }
    }
};

const UsersVirtualized = (props) => {
    const { data = [], columns = [], order = null, orderBy = null } = props;
    const classes = useVirtualizedTableStyles();
    return (
        <div className={classes.root} style={{ height: '400px', width: '100%' }}>
            <AutoSizer>
                {({ width, height }) => (
                    <MuiTable
                        height={height}
                        data={data}
                        columns={columns}
                        includeHeaders={true}
                        cellProps={(column, row) => {
                            const cellProps = cellStyleContect(column, row, data);
                            if (cellProps && cellProps.content) {
                                return {
                                    ...cellProps,
                                    children: cellProps.content,
                                };
                            }
                            return cellProps;
                        }}
                        width={width}
                        fixedRowCount={1}
                        maxWidth={width}
                        style={{ tableLayout: 'fixed', backgroundColor: 'white' }}
                    />
                )}
            </AutoSizer>
        </div>
    );
};

export default UsersVirtualized;
