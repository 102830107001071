// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the organization state domain
 */
const selectDriverState = () => (state) => state.get('driver');

const selectDrivers = () =>
    createSelector(selectDriverState(), (substate) => substate.get('drivers'));

const selectDriversLoading = () =>
    createSelector(selectDriverState(), (substate) => substate.get('loadingDriver'));

const selectDriversError = () =>
    createSelector(selectDriverState(), (substate) => substate.get('driversError'));

export { selectDrivers, selectDriversLoading, selectDriversError };
