import React, {useState, useEffect, useRef} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox,
    Paper,
    Typography,
    IconButton,
    Chip,
    Divider,
    TextField
} from '@material-ui/core';
import './styles.scss';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    AddOrRemoveSectors,
    deleteSectorsById,
    editVehiclesinSectorsBySectorId,
    getSectors
} from "../../services/AutoPartage/thunks";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from '@mui/icons-material/Search';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {getEventTypes, getVehicles} from "../../services/Vehicle/thunks";
import {selectEventTypes, selectVehicles} from "../../services/Vehicle/selectors";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FixedSizeList } from 'react-window';
import { Snackbar } from "@mui/material";
import VehicleIconDisplay from "../VehicleIconDisplay";
import {fromJS} from "immutable";
import {getStatus} from "../../helpers";
import mapboxgl from 'mapbox-gl';

const useStylesVehicles = makeStyles((theme) => ({
    vehicleList: {
        height: '70%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.6em'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(98,170,54)',
            borderRadius: '10px',
        },

        '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderRadius: '10px',
        },
    },
    vehicleListItem: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    searchBar: {
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-root': {
            height: '30px',
            alignItems: 'center',
        },
        '& .MuiInputBase-input': {
            height: '20px',
            padding: '10px 14px',
        },
    },
    customCheckbox: {
        color: '#62ab36',
        '&.Mui-checked': {
            color: '#62ab36',
        },
    },

}));

const ApSectorList = (props) => {
    const useStyles = makeStyles((theme) => ({
        list: {
            height: props.isAddingSectorClicked ? '70%' : '55%',
            width: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.5em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgb(98,170,54)',
                borderRadius: '10px',
            },

            '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0,0,0,0.1)',
                borderRadius: '10px',
            },

        },
        listItem: {
            height: '70px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.action.hover
            },
            '&.selected': {
                backgroundColor: '#f4f5f6',
            },
        },
        customCheckbox: {
            color: '#62ab36',
            '&.Mui-checked': {
                color: '#62ab36',
            },
        },
        divider: {},
        searchBar: {
            marginBottom: theme.spacing(2),
            '& .MuiInputBase-root': {
                height: '30px',
                alignItems: 'center',
            },
            '& .MuiInputBase-input': {
                height: '20px',
                padding: '10px 14px',
            },
        },

    }));

    const classes = useStyles();
    const classesVehicles = useStylesVehicles();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermVehicle, setSearchTermVehicle] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [selectedSector, setSelectedSector] = useState(null);
    const [checkedVehicleIds, setCheckedVehicleIds] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSector, setCurrentSector] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [sectorsChecked, setSectorsChecked] = useState([]);
    const [checkedGeofences, setCheckedGeofences] = useState([]);
    const [toAddGeofences, setToAddGeofences] = useState(new Set());
    const [toDeleteSectors, setToDeleteSectors] = useState(new Set());
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
    const [confirmedCheckedVehicleIds, setConfirmedCheckedVehicleIds] = useState([]);
    const [sectorVehicleIds, setSectorVehicleIds] = useState([]);
    const [graySector, setGraySector] = useState([]);
    const mapRef = props.mapRef || useRef(null);
    const [popup, setPopup] = useState(new mapboxgl.Popup({ closeButton: false, closeOnClick: false }));

    // useEffect(() => {
    //     setCheckedGeofences(new Set(props.sectorsList.map(sector => sector.geofenceId)))
    // }, [props.sectorsList,props.isAddingSectorClicked]);

    useEffect(() => {
        if (!props.eventTypes || props.eventTypes.size === 0) {
            props.getEventTypes();
        }
    }, []);

    useEffect(() => {
        setCheckedGeofences(new Set(props.sectorsList.map(sector => sector.geofenceId)))
        if(!props.isAddingSectorClicked ){
            setGraySector([])
           // setCheckedGeofences([])
            setToAddGeofences(new Set())
            setToDeleteSectors(new Set())
            setSearchTerm('')
            popup.remove()
        }
    }, [props.isAddingSectorClicked, props.sectorsList]);
    const handleOpenDeleteDialog = () => {
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setToDeleteSectors(new Set())
    };
    const handleCloseDeleteDialog = () => {
        setToDeleteSectors(new Set())
        setDeleteDialogOpen(false);
    };

    const handleGeofenceCheckboxChange = (geofenceId) => {
        setCheckedGeofences(prev => {
            const newChecked = new Set(prev);

            if (newChecked.has(geofenceId)) {
                // Uncheck the geofence
                newChecked.delete(geofenceId);
                setToAddGeofences(prevAdd => {
                    const newAdd = new Set(prevAdd);
                    newAdd.delete(geofenceId);
                    return newAdd;
                });
                setToDeleteSectors(prevDelete => {
                    if (props.sectorsList.some(sector => sector.geofenceId === geofenceId)) {
                        return new Set(prevDelete).add(geofenceId);
                    }
                    return prevDelete;
                });
            } else {
                // Check the geofence
                newChecked.add(geofenceId);
                setToAddGeofences(prevAdd => {
                    const newAdd = new Set(prevAdd);
                    if (props.sectorsList.some(sector => sector.geofenceId === geofenceId)) {
                        // If the geofence was already part of a sector, remove it from the delete list
                        setToDeleteSectors(prevDelete => {
                            const newDelete = new Set(prevDelete);
                            newDelete.delete(geofenceId);
                            return newDelete;
                        });
                    } else {
                        newAdd.add(geofenceId);
                    }
                    return newAdd;
                });
            }

            return newChecked;
        });
    };

    // Function to handle sector click
    const handleSectorClick = (sector) => {
        if (sector.vehicleId) {
            // Convertir les IDs de véhicule en entiers
            const vehicleIdsInSector = sector.vehicleId.map(id => parseInt(id));
            setSectorVehicleIds(vehicleIdsInSector);
            const newCheckedVehicleIds = vehicles
                .filter((vehicle) => vehicleIdsInSector.includes(vehicle.id))
                .map((vehicle) => vehicle.id);
            setCheckedVehicleIds(newCheckedVehicleIds);
        }
        setSelectedSector(sector.idSector); // Store the selected sector's geofenceId
        setGraySector(sector); // Store the selected sector's geofenceId
        props.getSectors();
        props.getVehicles();

        if (mapRef.current) {
            const geofence = props.geofences.find(g => g.get('geofenceId') === sector.geofenceId);

            if (geofence) {
                const latitude = geofence.get('latitude');
                const longitude = geofence.get('longitude');
                const polygonPoints = geofence.get('polygonPoints');
                const sectorName = geofence.get('name');

                if (polygonPoints && polygonPoints.size > 0) {
                    const lats = polygonPoints.toJS().map(point => point.latitude);
                    const lngs = polygonPoints.toJS().map(point => point.longitude);
                    const north = Math.max(...lats);
                    const south = Math.min(...lats);
                    const east = Math.max(...lngs);
                    const west = Math.min(...lngs);

                    mapRef.current.fitBounds([[west, south], [east, north]], { padding: 20 });

                    const centerLng = (east + west) / 2;
                    const centerLat = (north + south) / 2;
                    popup.setLngLat([centerLng, centerLat])
                        .setHTML(`<strong>${sectorName}</strong>`)
                        .addTo(mapRef.current);
                } else if (latitude && longitude) {
                    const center = [longitude, latitude];
                    mapRef.current.flyTo({ center, zoom: 10 });

                    popup.setLngLat(center)
                        .setHTML(`<strong>${sectorName}</strong>`)
                        .addTo(mapRef.current);
                } else {
                    const center = [0, 0];
                    mapRef.current.flyTo({ center, zoom: 10 });

                    popup.setLngLat(center)
                        .setHTML(`<strong>${sectorName}</strong>`)
                        .addTo(mapRef.current);
                }
            }
        }
    };

    const handleSearchChangeVehicles = (event) => {
        setSearchTermVehicle(event.target.value);
    };

    const filteredVehicles = vehicles.filter((vehicle) =>
        vehicle.name.toLowerCase().includes(searchTermVehicle.toLowerCase())
    );

    const sortedVehicles = filteredVehicles.sort((a, b) => {
        const aConfirmedChecked = confirmedCheckedVehicleIds.includes(a.id);
        const bConfirmedChecked = confirmedCheckedVehicleIds.includes(b.id);

        const aSectorChecked = sectorVehicleIds.includes(a.id);
        const bSectorChecked = sectorVehicleIds.includes(b.id);

        if (aSectorChecked && !bSectorChecked) return -1;
        if (!aSectorChecked && bSectorChecked) return 1;

        if (aConfirmedChecked && !bConfirmedChecked) return -1;
        if (!aConfirmedChecked && bConfirmedChecked) return 1;

        return a.name.localeCompare(b.name);
    });

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredSectors = props.sectorsList.filter((sector) =>
        sector.geofenceName ? sector.geofenceName.toLowerCase().includes(searchTerm.toLowerCase()) : sector.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    let filteresGeofences : [];
    if(props.geofences.size > 0){
        filteresGeofences = props.geofences.toJS().filter((geo)=>
            geo.geofenceName ? geo.geofenceName.toLowerCase().includes(searchTerm.toLowerCase()) : geo.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }

    const sortedSectors = filteredSectors.sort((a, b) => {
        const aName = a.geofenceName ? a.geofenceName.toLowerCase() : a.name.toLowerCase();
        const bName = b.geofenceName ? b.geofenceName.toLowerCase() : b.name.toLowerCase();

        if (a.numberOfVehicles !== b.numberOfVehicles) {
            return b.numberOfVehicles - a.numberOfVehicles;
        }
        return aName.localeCompare(bName);
    });
    useEffect(() => {
        props.getVehicles();
    }, [props.sectorsList]);

    useEffect(() => {
        if (props.vehicles.size > 0) {
            if (props.sectorsList.length > 0) {
                const updatedVehicles = props.vehicles.toJS().map(vehicle => {
                    const sector = props.sectorsList.find(sector => Array.isArray(sector.vehicleId) && sector.vehicleId.map(id => parseInt(id)).includes(vehicle.id));
                    return sector ? { ...vehicle, sectorName: sector.geofenceName } : vehicle;
                });
                setVehicles(updatedVehicles);
            } else {
                setVehicles(props.vehicles.toJS());
            }
        }
    }, [props.vehicles.size, props.sectorsList]);

    const handleClickAction = (event, sector) => {
        event.stopPropagation();
        const array = new Set();
        array.add(sector.idSector);
        setToDeleteSectors(array)
        setAnchorEl(event.currentTarget);
        setCurrentSector(sector);
    };

    const handleVehicleCheckboxChange = (id) => {
        const newChecked = [...checkedVehicleIds];
        if (newChecked.includes(id)) {
            setCheckedVehicleIds(newChecked.filter((checkedId) => checkedId !== id));
        } else {
            newChecked.push(id);
            setCheckedVehicleIds(newChecked);
        }

    };

    const handleConfirmVehiclesSelection = () => {
        props.setIsLoadingAfterConfirmation(true)
        if (selectedSector) {
            props.editVehiclesinSectorsBySectorId(selectedSector, checkedVehicleIds)
                .then((response) => {
                    setConfirmedCheckedVehicleIds(checkedVehicleIds);
                    setVehicles(prevVehicles =>
                        prevVehicles.map(vehicle =>
                            checkedVehicleIds.includes(vehicle.id)
                                ? { ...vehicle, sectorName: props.sectorsList.find(sector => sector.idSector === selectedSector).geofenceName }
                                : vehicle
                        )
                    );
                    props.getSectors().then((updatedSectors) => {
                       // setSelectedSector(null)
                        setGraySector([])
                        popup.remove()
                        props.setSectorsList(updatedSectors);
                        // Revenir à la liste initiale
                        // props.setIsAddingSectorClicked(false);
                        // props.setShowSuccessMessage(true); // Afficher un message de confirmation
                         props.setIsLoadingAfterConfirmation(false); // Arrêter le chargement
                        // props.setShowCancelButton(false)
                    });
                    setShowConfirmationMessage(true);
                })
                .catch((error) => {
                    // Handle error response
                    console.error('Error updating vehicles:', error);
                });
        }
    }
    const handleCloseConfirmationMessage = () => {
        setShowConfirmationMessage(false);
    };

    const handleAddSectors = () => {
        setDeleteDialogOpen(false);
        props.setIsLoadingAfterConfirmation(true); // Démarrer le chargement
        const data = {
            toadd: Array.from(toAddGeofences),
            todelete: Array.from(toDeleteSectors)
        };

        props.AddOrRemoveSectors(data)
            .then((response) => {
                // Mettre à jour la liste des secteurs
                props.getSectors().then((updatedSectors) => {
                    setSelectedSector(null)
                    setGraySector([])
                    props.setSectorsList(updatedSectors);
                    // Revenir à la liste initiale
                    props.setIsAddingSectorClicked(false);
                    props.setShowSuccessMessage(true); // Afficher un message de confirmation
                    props.setIsLoadingAfterConfirmation(false); // Arrêter le chargement
                    props.setShowCancelButton(false)
                    setToDeleteSectors(new Set())
                    setToAddGeofences(new Set())
                    popup.remove()
                });
            });
        props.setShowSuccessMessage(false);
    };

    const handleCloseSuccessMessage = () => {
        props.setShowSuccessMessage(false);
    };

    const handleCancel = () => {
        setSelectedSector(null)
        setGraySector([])
        setCheckedVehicleIds([])
        popup.remove()
    }

    const doesVehicleHasStatus = (vehicle) => {
        return fromJS(vehicle).get('status')
            ? getStatus(
                fromJS(vehicle),
                fromJS(vehicle).getIn(['status', 'eventTypeId']),
                props.eventTypes,
                true,
            )
            : null;
    };

    let sortedGeofences = []
    if(checkedGeofences.size > 0){
        sortedGeofences = Array.isArray(filteresGeofences) ? filteresGeofences.sort((a, b) => {
            const aChecked = checkedGeofences.has(a.geofenceId);
            const bChecked = checkedGeofences.has(b.geofenceId);

            if (aChecked && !bChecked) return -1;
            if (!aChecked && bChecked) return 1;

            const aName = a.geofenceName ? a.geofenceName.toLowerCase() : a.name.toLowerCase();
            const bName = b.geofenceName ? b.geofenceName.toLowerCase() : b.name.toLowerCase();
            return aName.localeCompare(bName);
        }) : [];
    }else if (filteresGeofences && filteresGeofences.length > 0){
        sortedGeofences = filteresGeofences.sort((a, b) => {
            // const aChecked = checkedGeofences.has(a.geofenceId);
            // const bChecked = checkedGeofences.has(b.geofenceId);
            //
            // if (aChecked && !bChecked) return -1;
            // if (!aChecked && bChecked) return 1;

            const aName = a.geofenceName ? a.geofenceName.toLowerCase() : a.name.toLowerCase();
            const bName = b.geofenceName ? b.geofenceName.toLowerCase() : b.name.toLowerCase();
            return aName.localeCompare(bName);
        })
    }

    const Row = ({ index, style, data }) => {
        const {
            geofences,
            classes,
            handleSectorClick,
            selectedSector,
            checkedGeofences,
            handleGeofenceCheckboxChange
        } = data;
        const geofence = geofences[index];

        return (
            <div style={style} key={geofence.geofenceId} className={`${classes.listItem} ${graySector && graySector.geofenceId === geofence.geofenceId ? 'selected' : ''}`}>
                {confirmationMessage && (
                    <div style={{ color: 'green', textAlign: 'center', marginBottom: '10px' }}>
                        {confirmationMessage}
                    </div>
                )}

                <ListItem
                    className={`${classes.listItem} ${graySector && graySector.geofenceId === geofence.geofenceId ? 'selected' : ''}`}
                    onClick={() => handleSectorClick(geofence)}
                    selected={selectedSector && selectedSector.geofenceId === geofence.geofenceId}
                >
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            checked={checkedGeofences.size > 0 ? checkedGeofences.has(geofence.geofenceId) : false}
                            onChange={() => handleGeofenceCheckboxChange(geofence.geofenceId)}
                            classes={{ root: classes.customCheckbox }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography component={'span'} variant="body2" style={{ fontSize: '12px' }}>
                                {geofence.name ? geofence.name : geofence.geofenceName}
                            </Typography>
                        }
                    />
                </ListItem>
                {index < geofences.length - 1 && <Divider className={classes.divider} />}
            </div>
        );
    };

    return (
        <div style={{ height: '100%' }} className={'SectorContainer'}>
            <Snackbar
                style={{ position: 'relative' }}
                open={showConfirmationMessage}
                autoHideDuration={3000}
                onClose={handleCloseConfirmationMessage}
                message={`${props.intl.formatMessage({id: 'containers.AP.Sector.Component.Success.Sector.Added'})}`}
            />
            <div className={props.isAddingSectorClicked ? 'expanded-list' : 'collapsed-list'} style={{ backgroundColor: 'white', borderRadius: '8px', border: '1px solid #c2baba', padding: '10px' }}>
                <div style={{ padding: '0 10px 0 10px' }}>
                    <div style={{ fontSize: '16px', fontWeight: '600', paddingBottom: '10px' }}><FormattedMessage id={'containers.AP.Sector.Component.Title'} /></div>
                    <TextField
                        className={classes.searchBar}
                        variant="outlined"
                        fullWidth
                        placeholder="Rechercher"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <SearchIcon style={{ fontSize: 'larger', color: '#9ea8b7' }} />
                            ),
                        }}
                    />
                </div>
                <Paper elevation={0} style={{ display: 'flex', height: '50px', justifyContent: 'space-between', alignItems: 'center', padding: '8px', width: '90%', margin: 'auto', backgroundColor: '#f6f7f8' }}>
                    <Typography component={'span'} variant="body2" style={{ fontSize: '13px' }}>
                        <FormattedMessage id={'containers.AP.Sector.Component.Name'} />
                    </Typography>
                    {!props.isAddingSectorClicked &&
                        <Typography component={'span'} variant="body2" style={{ fontSize: '13px' }}>
                            <FormattedMessage id={'containers.AP.Sector.Component.Vehicle.Nb'} />
                        </Typography>
                    }

                </Paper>
                {props.isLoadingSectors ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </div>
                    ) :
                    props.isLoadingAfterConfirmation ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </div>
                        ) :
                        !props.isAddingSectorClicked ?
                            <List className={classes.list} style={{ width: '90%', margin: 'auto' }}>
                                {sortedSectors .map((sector, index) => (
                                    <React.Fragment key={sector.geofenceId}>
                                        <ListItem
                                            className={`${classes.listItem} ${graySector && graySector.idSector === sector.idSector ? 'selected' : ''}`}
                                            onClick={() => handleSectorClick(sector)}
                                            selected={selectedSector && selectedSector.idSector === sector.idSector}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography component={'span'} variant="body2" style={{ fontSize: '12px' }}>
                                                        {sector.geofenceName}
                                                    </Typography>
                                                }
                                            />
                                            <Chip
                                                style={{
                                                    height: '25px',
                                                    backgroundColor: sector.numberOfVehicles > 0 ? '#95c479' : '#cac4c4',
                                                    color: 'white'
                                                }}
                                                label={`${sector.numberOfVehicles} ${props.intl.formatMessage({
                                                    id: 'containers.AP.Sector.Component.Vehicle.Tag',
                                                })}`}

                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton size={'small'} edge="end" aria-label="more"
                                                            onClick={(e) => handleClickAction(e, sector)}>
                                                    <MoreVertIcon fontSize={'small'} />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {index < sortedSectors.length - 1 && <Divider className={classes.divider} />}
                                    </React.Fragment>
                                ))}
                            </List>
                            :
                            <div className={classes.list} style={{ width: '90%', margin: 'auto' }}>
                                <FixedSizeList
                                    height={500}
                                    itemCount={sortedGeofences.length}
                                    itemSize={70}
                                    width={'100%'}
                                    itemData={{
                                        geofences:sortedGeofences,
                                        classes,
                                        handleSectorClick,
                                        selectedSector,
                                        checkedGeofences,
                                        handleGeofenceCheckboxChange
                                    }}
                                >
                                    {Row}
                                </FixedSizeList>
                            </div>
                }
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem  onClick={handleOpenDeleteDialog}>{`${props.intl.formatMessage({id: 'containers.AP.Sector.Component.Delete'})}`}</MenuItem>
                </Menu>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`${props.intl.formatMessage({id: 'containers.AP.Sector.Component.Confirm.Delete'})}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`${props.intl.formatMessage({id: 'containers.AP.Sector.Component.Confirm.Delete.Sector'})}`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            <FormattedMessage id={'containers.AP.Sector.Buttons.Cancel'} />
                        </Button>
                        <Button onClick={handleAddSectors} color="primary" autoFocus>
                            <FormattedMessage id={'containers.AP.Sector.Component.Confirm'} />
                        </Button>
                    </DialogActions>
                </Dialog>
                {props.isAddingSectorClicked ?
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button color="primary" onClick={handleAddSectors}>
                            <FormattedMessage id={'containers.AP.Sector.Component.Confirm'} />
                        </Button>
                    </div> : null}
                <Snackbar
                    style={{ position: 'relative', bottom:'50%' }}
                    open={props.showSuccessMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSuccessMessage}
                    message={`${props.intl.formatMessage({id: 'containers.AP.Sector.Component.Success.Sector.Added'})}`}
                />
            </div>

            {!props.isAddingSectorClicked ?
                <div style={{ height: '50%', backgroundColor: 'white', borderRadius: '8px', border: '1px solid #c2baba', padding: '20px' }}>
                    <div style={{ fontSize: '16px', fontWeight: '600', paddingBottom: '10px' }}>{`${props.intl.formatMessage({id: 'containers.AP.Vehicle.Component.Associate'})}`}</div>
                    <TextField
                        className={classesVehicles.searchBar}
                        variant="outlined"
                        fullWidth
                        placeholder={`${props.intl.formatMessage({id: 'containers.AP.Search'})}`}
                        value={searchTermVehicle}
                        onChange={handleSearchChangeVehicles}
                        InputProps={{
                            startAdornment: (
                                <SearchIcon style={{ fontSize: 'larger', color: '#9ea8b7' }} />
                            ),
                        }}
                    />
                    <List className={classesVehicles.vehicleList}>
                        {sortedVehicles.map((vehicle, index) => (

                            <React.Fragment key={vehicle.id}>
                                <ListItem className={classesVehicles.vehicleListItem}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            tabIndex={-1}
                                            disableRipple
                                            disabled={!selectedSector || (vehicle.sectorName && vehicle.sectorName !== props.sectorsList.find(sector => sector.idSector === selectedSector).geofenceName)}
                                            checked={checkedVehicleIds.includes(vehicle.id)}
                                            onChange={() => handleVehicleCheckboxChange(vehicle.id)}
                                            classes={{ root: classes.customCheckbox }}
                                        />
                                    </ListItemIcon>
                                    <ListItemIcon>
                                        <VehicleIconDisplay
                                            vehicle={vehicle}
                                            customIcon={vehicle.customIcon}
                                             statusIcon={doesVehicleHasStatus(vehicle) && doesVehicleHasStatus(vehicle).icon}
                                             status={doesVehicleHasStatus(vehicle)}
                                            idVehicle={vehicle.id}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography component={'span'} variant="body2" style={{ fontSize: '12px' }}>
                                                {vehicle.name}
                                                <div style={{ color: '#04397170' }}>
                                                    {vehicle.sectorName ? vehicle.sectorName : ''}
                                                </div>
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                {index < sortedVehicles.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                    {selectedSector ? (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button color="primary" onClick={handleConfirmVehiclesSelection}>
                                <FormattedMessage id={'containers.AP.Sector.Component.Confirm'} />
                            </Button>
                            <Button color="primary" onClick={handleCancel}>
                                <FormattedMessage id={'containers.AP.Sector.Buttons.Cancel'} />
                            </Button>
                        </div>
                    ) : null}
                </div>
                : null}

        </div>
    );

}

const mapStateToProps = createStructuredSelector({
    vehicles: selectVehicles(),
    eventTypes: selectEventTypes(),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getSectors,
        getVehicles,
        deleteSectorsById,
        editVehiclesinSectorsBySectorId,
        AddOrRemoveSectors,
        getEventTypes
    }, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ApSectorList))
);
