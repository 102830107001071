// @flow

import styled from 'styled-components';
import color from 'styles/colors';

const AdminPage = styled.div``;

AdminPage.Time = styled.div`
    display: flex;
    flex-direction: row;
`;

AdminPage.GenerateContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: 20px 50px;
`;

export default AdminPage;
