// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

const AdminSideMenu = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;

    @media (min-width: ${screen.small}) {
        position: static;
    }
`;

AdminSideMenu.Text = styled.div`
    color: ${(props) => props.color || colors.black};
`;

AdminSideMenu.Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 250ms ease-in-out 0s;
`;

AdminSideMenu.Header = styled.div`
    background: ${colors.black};
    padding: 15px 25px;
    min-height: 80px;

    > div {
        margin-bottom: 0;
    }

    @media (min-width: ${screen.small}) {
        min-height: auto;
    }
`;

AdminSideMenu.ListContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 5px 25px;
    :-moz-any(#content, #appcontent) browser {
        margin-right: -14px !important;
        overflow-y: scroll;
        margin-bottom: -14px !important;
        overflow-x: scroll;
    }

    > ul {
        margin: 0 0 40px 0;

        > li:nth-child(odd) {
            > div {
                > div {
                    background: ${colors.grayED};
                }
            }
        }
    }
`;

export default AdminSideMenu;
