// @flow

import { push } from 'react-router-redux';
import { matchPath } from 'react-router-dom';
import { receivedChangedUnit } from './actions';
import { AUTH0_DOMAIN } from 'env';
import { updateUser } from 'services/Admin/thunks';
import { parseJWT } from 'helpers';

export const changeUnit = (unit: string, updateMetadata: boolean = true) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    if (unit !== getCookie('units')) {
        const split = AUTH0_DOMAIN.split('.');
        const mainDomain = `${split[1]}.${split[2]}`;
        document.cookie = `units=${unit};domain=${mainDomain};path='/';`;
        localStorage.setItem('unit', unit);
    }
    // Always update the auth0 metadata, since there is no way to know if the change is different
    const parsed = parseJWT(sessionStorage.getItem('api_token'));
    if (parsed && updateMetadata) {
        const userId = parsed['https://geothentic.com/user_id'];
        dispatch(updateUser({id: userId, units: unit, updateMetadata: true}));
    }
    dispatch(receivedChangedUnit(unit));
    return true;
};

export const loadUnit = () => (dispatch: ReduxDispatch, getState: any) => {
    let currentUnit = getState().getIn(['units', 'unit']);
    const storedUnit = getCookie('units') || localStorage.getItem('unit');
    if (currentUnit !== storedUnit) {
        currentUnit = storedUnit;
    }
    dispatch(changeUnit(currentUnit || storedUnit || 'metric'));
};

const getCookie = (key) => {
    let b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
};

export const setTextunit = (value: number, type: String) => (dispatch: ReduxDispatch, getState: any) => {
    switch (type) {
        case 'speed':
            return dispatch(i18nSpeedUnit(value)) + ' ' + (dispatch(isImperial()) ? 'mph' : 'km/h');
        case 'distance':
            return dispatch(i18nDistanceUnit(value)) + ' ' + dispatch(i18nDistanceSymbol());
        case 'odometer':
            return dispatch(i18nDistanceUnit(value));
        case 'volume':
            return dispatch(i18nVolumeUnit(value)) + ' ' + (dispatch(isImperial()) ? 'g' : 'L');
        case 'temperature':
            return dispatch(i18nTemperatureUnit(value)) + ' ' + (dispatch(isImperial()) ? '°F' : '°C');
        case 'weight':
            return dispatch(i18nWeightUnit(value)) + ' ' + (dispatch(isImperial()) ? 'lbs' : 'kg');
        default:
            return value;
    }
};

export const isImperial = () => (dispatch: ReduxDispatch, getState: any) => {
    return getState().getIn(['units', 'unit']) === 'imperial';
};

export const i18nSpeedUnit = (speed: number) => (dispatch: ReduxDispatch, getState: any) => {
    const value = dispatch(i18nDistanceUnit(speed));
    return value;
};

export const i18nWeightUnit = (weight: number) => (dispatch: ReduxDispatch, getState: any) => {
    const value = dispatch(isImperial()) ? weight * 2.205 : weight;
    return value % 1 == 0 ? value : parseFloat(value).toFixed(2);
};

export const i18nDistanceUnit = (distance: number) => (dispatch: ReduxDispatch, getState: any) => {
    const value = dispatch(isImperial()) ? distance * (0.6214) : distance;
    return value % 1 == 0 ? value : parseFloat(value).toFixed(2);
};

export const i18nMilesToKm = (distance: number) => (dispatch: ReduxDispatch, getState: any) => {
    const value = dispatch(isImperial()) ? distance * (1.609) : distance;
    return value % 1 == 0 ? value : parseFloat(value).toFixed(2);
};

// Feet to meters
export const i18nDistanceMetersUnit = (distance: number) => (dispatch: ReduxDispatch, getState: any) => {
    const value = dispatch(isImperial()) ? distance * (3.281) : distance;
    return Math.floor(value);
};

// Liters to gallons
export const i18nVolumeUnit = (volume: number) => (dispatch: ReduxDispatch, getState: any) => {
    if (!volume) {
        return undefined;
    }
    const value = dispatch(isImperial()) ? volume / 3.785 : volume;
    return value % 1 == 0 ? value : parseFloat(value).toFixed(2);
};

// Gallons to liters
export const i18nVolumeLitersUnit = (volume: number) => (dispatch: ReduxDispatch, getState: any) => {
    const value = dispatch(isImperial()) ? volume * 3.785 : volume;
    return value % 1 == 0 ? value : parseFloat(value).toFixed(2);
};

export const i18nTemperatureUnit = (temperature: number) => (dispatch: ReduxDispatch, getState: any) => {
    const value = dispatch(isImperial()) ? temperature * 9 / 5 + 32 : temperature;
    return value % 1 == 0 ? value : parseFloat(value).toFixed(2);
};

export const i18nDistanceSymbol = (distance: number) => (dispatch: ReduxDispatch, getState: any) => {
    return (dispatch(isImperial()) ? 'miles' : 'km')
};

