import "./styles.css";
import React from "react";
import { injectIntl } from 'react-intl';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer
} from "recharts";
import * as ExcelJS from "exceljs";
import Loader from 'components/Loader';
import colors from 'styles/colors';
import GeoExportExcel from 'components/GeoExportExcel';
import { Resizable, ResizableBox } from 'react-resizable';
import excel from 'assets/excel_icon.png';

function EngineErrorChart(props) {
    const { loading, data, intl } = props;
    //console.log(data);
    // Function to generate the Excel workbook
    const generateExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");

        // Add headers
        worksheet.addRow(["name", "suspect", "fmi", "occurence"]);

        // Add data to the worksheet
        data.forEach((item) => {
            worksheet.addRow([item.name, item.suspect, item.fmi, item.occurence]);
        });

        // Generate a unique filename
        const filename = `${intl.formatMessage({
            id:
                'views.Reports.SideMenu.Dashboard.engineErrorCode',
        })}.xlsx`;

        // Save the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

            // Create a temporary anchor element to trigger the download
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });
    };
    return (
        <ResizableBox
            className={'element'}
            minConstraints={[400, 500]}
            transformScale={0.75}
            width={400}
            height={500}
            axis={"x"}
            style={{ borderColor: colors.green61 }}
            handle={(h) => <span className={`custom-handle custom-handle-${h}`} />}>
            <span className="d-inline-block" style={{
                textAlign: 'center',
                color: colors.green61,
                textTransform: 'uppercase',
                textDecorationLine: 'underline'
            }} >{intl.formatMessage({
                id:
                    'views.Reports.SideMenu.Dashboard.engineErrorCode',
            })} </span>
            <hr />
            {loading ? (
                <Loader loading />
            ) : (
                [data ? <div className={"export"}>
                    <button className ={'excel_button'} onClick={generateExcel}> <img width={'35px'} height={'35px'} src={excel} />
                    </button>
                </div> : null, <ResponsiveContainer>
                    <ComposedChart
                        width={800}
                        height={450}
                        data={data}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                        <Bar yAxisId="right" dataKey="occurence" barSize={20} fill="#413ea0" />
                        <Tooltip />
                        <Legend />
                        <Scatter yAxisId="left" dataKey="suspect" fill="red" />
                        <Scatter yAxisId="right" dataKey="fmi" fill="green" />

                    </ComposedChart>
                </ResponsiveContainer>]
            )}
        </ResizableBox>
    );
}
export default injectIntl(EngineErrorChart);
