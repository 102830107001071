// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Organization = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: ${(props) => props.color};
`;

Organization.Griditem = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.rowSpan}, 1fr);
    height: 100%;
    grid-column-gap: 15px;
    grid-template-rows: ;
`;

Organization.empty = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.trajet = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.datetime = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.totaldistance = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.utilisationtime = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.idletime = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;
Organization.gasConsumption = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.stoptime = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
`;

Organization.stopaddress = styled.div`
    display: flex;
    align-items: normal;
    min-width: 0;
    align-items: center;
    padding-top: 5px;
`;

Organization.drivername = styled.div`
    display: inline;
    align-items: normal;
    min-width: 0;
    padding: 0.2em 0.6em 0.3em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
`;

Organization.Image = styled.img`
    margin-left: 10px;
    margin-right: 15px;
    width: ${(props) => props.width || '41px'};
    height: ${(props) => props.width || '41px'};
`;


export default Organization;
