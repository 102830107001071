// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Container, Text } from './styles';
import colors from 'styles/colors';

type Props = {
    text: ?string,
    badge: ?number,
};

function TextWithBadge(props: Props) {
    return (
        <Container>
            <Text>{props.text}</Text>
            <Container.Badge>
                <Text.Badge color={colors.green73}>{'('}</Text.Badge>
                <Text.Badge>{props.badge}</Text.Badge>
                <Text.Badge color={colors.green73}>{')'}</Text.Badge>
            </Container.Badge>
        </Container>
    );
}

export default TextWithBadge;
