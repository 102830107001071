// @flow

import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Table from 'antd/es/table';
import { Container, Row, Col } from 'react-bootstrap';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import  { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp';
import Organization from './styles';
import type { ColumnType } from 'types';
import { Split } from 'styles/common';
import colors from 'styles/colors';
import EngineOn from 'assets/status/engine-on.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
    rowSpan: ?number,
    data: Object<any>,
    columns: Array<ColumnType>,
    showStopTime: *,
};

const ToggleItem = ({ open, setOpen, props, data, autokeyon, autokeyoff }) => {
    let startAlgo = [];
    let stopAlgo = [];
    data.get('start') &&
        data.get('start').forEach((key, value) =>
            startAlgo.push({
                key: value,
                name: value,
                count: key,
            })
        );
    data.get('stop') &&
        data.get('stop').forEach((key, value) =>
            stopAlgo.push({
                key: value,
                name: value,
                count: key,
            })
        );

    return (
        <Organization.Toggle
            height={0}
            open={open}
            style={{ paddingTop: 10 }} /*innerRef={details}*/
        >
            <Split color={colors.splitGrayReports} opacity={0.2} />
            <Container style={{ paddingTop: 10 }}>
                <Row>
                    {data.get('start') && data.get('start').size > 0 ? (
                        <Col>
                            <Table
                                columns={[
                                    {
                                        title: props.intl.formatMessage({
                                            id: 'containers.Reports.Generators.AutoKeyon.algo',
                                        }),
                                        dataIndex: 'name',
                                    },
                                    {
                                        title: props.intl.formatMessage({
                                            id: 'containers.Reports.Generators.total.algo',
                                        }),
                                        dataIndex: 'count',
                                    },
                                ]}
                                size={'small'}
                                pagination={{ pageSize: 10 }}
                                scroll={{ y: 200 }}
                                dataSource={startAlgo}
                                bordered
                                summary={() => (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>
                                            <b>
                                                {props.intl.formatMessage({
                                                    id: 'containers.Reports.Generators.total.algo',
                                                })}
                                            </b>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>
                                            <b>{autokeyon}</b>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                )}
                            />
                        </Col>
                    ) : (
                        <empty />
                    )}
                    {data.get('stop') && data.get('stop').size > 0 ? (
                        <Col>
                            <Table
                                columns={[
                                    {
                                        title: props.intl.formatMessage({
                                            id: 'containers.Reports.Generators.AutoKeyoff.algo',
                                        }),
                                        dataIndex: 'name',
                                    },
                                    {
                                        title: props.intl.formatMessage({
                                            id: 'containers.Reports.Generators.total.algo',
                                        }),
                                        dataIndex: 'count',
                                    },
                                ]}
                                size={'small'}
                                pagination={{ pageSize: 10 }}
                                scroll={{ y: 200 }}
                                dataSource={stopAlgo}
                                bordered
                                summary={() => (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>
                                            <b>
                                                {props.intl.formatMessage({
                                                    id: 'containers.Reports.Generators.total.algo',
                                                })}
                                            </b>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>
                                            <b>{autokeyoff}</b>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                )}
                            />
                        </Col>
                    ) : (
                        <empty />
                    )}
                </Row>
            </Container>
        </Organization.Toggle>
    );
};

const OrganizationListItem = (props) => {
    const tmpData = props.data;
    let data = null;
    tmpData.forEach((element, index) => {
        if (index === 1) {
            data = element;
        }
    });
    const [open, setOpen] = useState(false);
    let rowSpan = 7;
    return (
        <Organization onClick={() => (data.get('algoCount').size > 0 ? setOpen(!open) : null)}>
            <Organization.Griditem rowSpan={rowSpan}>
                <Organization.Item key="vehicleNumb">
                    {data.get('algoCount').size > 0 ? (
                        open ? (
                            <span>
                                <FaChevronUp />
                            </span>
                        ) : (
                            <span>
                                <FaChevronDown />
                            </span>
                        )
                    ) : (
                        <empty />
                    )}{' '}
                    <span style={{ paddingLeft: 5 }}>{data.get('vehicleNumb')}</span>{' '}
                    {data.get('isMotorManagment') ? (
                        <span style={{ paddingLeft: 5 }}>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id="tooltip-right">
                                        <FormattedMessage
                                            id={'components.Modals.Form.Config.ConfigMoteur'}
                                        />
                                    </Tooltip>
                                }
                            >
                                <img width={'28px'} height={'28px'} src={EngineOn} alt="Status" />
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <empty />
                    )}
                </Organization.Item>
                <Organization.Item key="utilisationTime">
                    {data.get('utilisationTime')}
                </Organization.Item>
                <Organization.Item key="totalDistance">
                    <Organization.span>{data.get('totalDistance')}</Organization.span>
                </Organization.Item>
                <Organization.Item key="keyOnCount">{data.get('keyOnCount')}</Organization.Item>
                <Organization.Item key="autoKeyOnCount">
                    {data.get('autoKeyOnCount')}
                </Organization.Item>
                <Organization.Item key="coupeMotorEcotime">
                    {data.get('coupeMotorEcotime')}
                </Organization.Item>
                <Organization.Item key="coutEconomise">
                    {data.get('fuel_type') == 'gasoline' ? (
                        <div>
                            <span>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id="tooltip-right">
                                            <FormattedMessage
                                                id={'containers.Reports.Generators.gasprice'}
                                            />
                                        </Tooltip>
                                    }
                                >
                                    <i className="fas fa-gas-pump" style={{ color: colors.red }} />
                                </OverlayTrigger>{' '}
                            </span>
                            <span>{data.get('coutEconomise')}</span>
                        </div>
                    ) : data.get('fuel_type') == 'diesel' ? (
                        <div>
                            <span>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id="tooltip-right">
                                            <FormattedMessage
                                                id={'containers.Reports.Generators.dieselprice'}
                                            />
                                        </Tooltip>
                                    }
                                >
                                    <i
                                        className="fas fa-gas-pump"
                                        style={{ color: colors.yellow }}
                                    />
                                </OverlayTrigger>{' '}
                            </span>
                            <span>{data.get('coutEconomise')}</span>
                        </div>
                    ) : (
                        data.get('coutEconomise')
                    )}
                </Organization.Item>
            </Organization.Griditem>

            <Organization.Griditem rowSpan={rowSpan}>
                <Organization.empty />
                <Organization.Item key="totalTimeIdle">
                    {data.get('totalTimeIdle')}
                </Organization.Item>
                <Organization.empty />
                <Organization.Item key="keyOffCount">{data.get('keyOffCount')}</Organization.Item>
                <Organization.Item key="autoKeyOffCount">
                    {data.get('autoKeyOffCount')}
                </Organization.Item>
                <Organization.empty />
                <Organization.Item key="reductionGes">{data.get('reductionGes')}</Organization.Item>
            </Organization.Griditem>
            {data.get('algoCount').size > 0 ? (
                <ToggleItem
                    open={open}
                    setOpen={setOpen}
                    props={props}
                    data={data.get('algoCount')}
                    autokeyon={data.get('autoKeyOnCount')}
                    autokeyoff={data.get('autoKeyOffCount')}
                />
            ) : (
                <empty />
            )}
        </Organization>
    );
};

export default injectIntl(OrganizationListItem);
