import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import MapIcon from '@mui/icons-material/Map';
import { selectDrivingInfo } from 'services/Report/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { selectLocale } from '../../services/Language/selectors';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { setHistoryMarker } from '../../views/MapPage/utils';
import { fixCoordinates } from '../../services/Report/resources';
function DrivingReportMap(props) {
    const MAPSTYLE_SATELLITE = 'mapbox://styles/geothentic/cktd2on1m0y0t17qhzyx546z8?optimize=true';
    const MAPSTYLE_DEFAULT = 'mapbox://styles/geothentic/ck3kbrwa21sd01coat5pliiq6';
    const [mapStyle, setMapStyle] = useState(MAPSTYLE_DEFAULT);
    const { drivingInfo } = props;

    const mapRef = useRef(null);

    useEffect(
        () => {
            const info = drivingInfo;
            return () => {};
        },
        [props.drivingInfo]
    );
    useEffect(
        () => {
            if (!props.drivingInfo) return;
            if (!mapRef.current) return;
            const map = new mapboxgl.Map({
                container: mapRef.current,
                style: MAPSTYLE_DEFAULT,
                center: [props.drivingInfo.entryLong, props.drivingInfo.entryLat],
                zoom: 13,
            });
            mapRef.current.map = map;
            const bounds = new mapboxgl.LngLatBounds();
            bounds.extend([props.drivingInfo.entryLong, props.drivingInfo.entryLat]);
            bounds.extend([props.drivingInfo.exitLong, props.drivingInfo.exitLat]);

            map.fitBounds(bounds, { padding: 100 });

            const updateHistoryMarker = async () => {
                let fixedCoordinates = await fixCoordinates(
                    [props.drivingInfo.entryLong, props.drivingInfo.entryLat],
                    [props.drivingInfo.exitLong, props.drivingInfo.exitLat]
                );
                if (fixedCoordinates) {
                    setHistoryMarker(map, [
                        [
                            fixedCoordinates.entryCoordinates[0],
                            fixedCoordinates.entryCoordinates[1],
                        ],
                        [fixedCoordinates.exitCoordinates[0], fixedCoordinates.exitCoordinates[1]],
                    ]);
                } else {
                    setHistoryMarker(map, [
                        [props.drivingInfo.entryLong, [props.drivingInfo.entryLat]],
                        [props.drivingInfo.exitLong, props.drivingInfo.exitLat],
                    ]);
                }
            };
            updateHistoryMarker();
            return () => {
                if (mapRef.current && mapRef.current.map) {
                    mapRef.current.map.remove();
                }
            };
        },
        [props.drivingInfo]
    );
    const onMapStyleChange = (style) => {
        // if (mapRef.current && mapRef.current.map) {
        //     mapRef.current.map.remove();
        // }
        // const map = new mapboxgl.Map({
        //     container: mapRef.current,
        //     style: style,
        //     center: [-74.69318, 46.15014],
        //     zoom: 13,
        // });
        // mapRef.current.map = map;
        mapRef.current.map.setStyle(style);
        setMapStyle(style);
    };

    return (
        <div>
            <div
                ref={mapRef}
                style={{
                    height: '63vh',
                    width: '73vw',
                    borderRadius: '10px',
                    borderColor: '#64748B',
                    marginTop: '10px',
                }}
            />
            {mapStyle === MAPSTYLE_DEFAULT ? (
                <IconButton
                    className="icon-button"
                    aria-label="satellite view"
                    onClick={() => {
                        onMapStyleChange(MAPSTYLE_SATELLITE);
                    }}
                    sx={{ position: 'absolute', bottom: '4%', right: '5%' }}
                >
                    <SatelliteAltIcon />
                </IconButton>
            ) : (
                <IconButton
                    aria-label="normal view"
                    className="icon-button"
                    onClick={() => {
                        onMapStyleChange(MAPSTYLE_DEFAULT);
                    }}
                    sx={{ position: 'absolute', bottom: '4%', right: '5%' }}
                >
                    <MapIcon />
                </IconButton>
            )}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    drivingInfo: selectDrivingInfo(),
    locale: selectLocale(),
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DrivingReportMap))
);
