// @flow

// ACTIONS
import {
    receivedToggleSideMenu,
    receivedShowSideMenu,
    receivedHideSideMenu,
    receivedToggleResizeMap,
    receivedDoResizeMap,
    receivedCompareUrlSideMenu,
    receivedToggleActiveBar,
    receivedShowActiveBar,
    receivedHideActiveBar,
    receivedShowFleetOverview,
    receivedHideFleetOverview,
    receivedIsMultipleSelect,
    receivedSelectedVehicles,
    receivedSelectedVehiclesData,
    receivedToggleRightSideMenu,
    receivedLangUnit,
} from './actions';

// MAIN THUNKS
/**
 *
 * @param {*} bool
 */
export const setIsMultipleSelect = (bool) => (dispatch) => {
    dispatch(receivedIsMultipleSelect(bool));
};

/**
 *
 * @param {*} vehicles
 */
export const setSelectedVehicles = (vehicles) => (dispatch) => {
    dispatch(receivedSelectedVehicles(vehicles));
};

/**
 *
 * @param {*} vehicles
 */
export const setSelectedVehiclesData = (vehicles) => (dispatch) => {
    dispatch(receivedSelectedVehiclesData(vehicles));
};

/**
 * Dispatch toggle the side menu.
 */
export const toggleSideMenu = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedToggleSideMenu());
};

/**
 *
 */
export const showSideMenu = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedShowSideMenu());
};
/**
 *
 */
 export const isLangUnitConfigured = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedLangUnit());
};
/**
 *
 */
export const hideSideMenu = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedHideSideMenu());
};

/**
 *
 */
export const toggleShouldResizeMap = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedToggleResizeMap());
};

/**
 *
 */
export const doResizeMap = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedDoResizeMap());
};

/**
 *
 */
export const compareUrlSideMenu = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedCompareUrlSideMenu());
};

/**
 *
 */
export const toggleActiveBar = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedToggleActiveBar());
};

/**
 *
 */
export const showActiveBar = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedShowActiveBar());
};

/**
 *
 */
export const hideActiveBar = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedHideActiveBar());
};

/**
 *
 */
export const showFleetOverview = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedShowFleetOverview());
};

/**
 *
 */
export const hideFleetOverview = () => (dispatch: ReduxDispatch) => {
    dispatch(receivedHideFleetOverview());
};

/**
 *
 */
export const changeCurrentRightSideMenu = (currentRightSideMenu) => (dispatch: ReduxDispatch) => {
    dispatch(receivedToggleRightSideMenu(currentRightSideMenu));
};
