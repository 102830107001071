// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import GenerateReportHeader from 'containers/ReportGenerator/Header';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import SelectField from 'components/SelectField';
import dateFns from 'date-fns';
import { OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { Split } from 'styles/common';
import colors from 'styles/colors';
import {
    ReportGeneratorContainer,
    DrivingReportGenerator,
    InputLabel,
    Report,
} from 'styles/reports';
import { Dashboard } from 'styles/dashboard';
import ReportDataTable from 'containers/ReportDataTableEconomie';
import ReportVehicleSearch from 'components/ReportVehicleSearch';
import FormInputText from 'components/FormInputText';
import FormInputDate from 'components/FormInputDate';
import Loader from 'components/Loader';
import Error from 'components/Error';
import MagnifyingGlass from 'assets/search-icon-white.svg';
import ReportIcon from 'assets/report-pen.svg';
import Calendar from 'assets/calendar-black.svg';

import { isImperial } from 'services/Units/thunks';
import { selectUnit } from 'services/Units/selectors';
import {
    validateEconomieReportGenerator,
    generateEconomieReport,
    removeEconomieReportError,
    clearEconomyReport,
} from 'services/Report/thunks';
import {
    selectEconomieReportGenerationErrors,
    selectIsLoading,
    selectEconomieReport,
    selectEconomieReportPagination,
} from 'services/Report/selectors';
import { selectVehicles } from 'services/Vehicle/selectors';
import { OUTPUT_FORMATS, REPORT_TYPES, PRESET_PERIODS } from 'services/Report/constants';
import ReportsInputDate from "../../../components/ReportsInputDate";

const today = dateFns.format(new Date(), 'YYYY-MM-DD');

type Props = {};

type State = {
    equipmentIds: Array<number>,
    gasPrice: number,
    dieselPrice: number,
    periodFrom: string,
    periodTo: string,
    period: string,
    dateLimit:boolean,
    dateLimitPositionLeft: boolean,
    reportGeneratedFilterOpen: boolean,
    format: string,
    type: string,
};

export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    state = {
        equipmentIds: [],
        gasPrice: 2.00,
        dieselPrice: 2.00,
        periodFrom: '',
        periodTo: '',
        dateLimit: false,
        dateLimitPositionLeft: true,
        period: PRESET_PERIODS.custom,
        reportGeneratedFilterOpen: false,
        format: OUTPUT_FORMATS.page,
        type: REPORT_TYPES.detailed,
    };

    componentDidMount = () => {
        this.props.clearEconomyReport();
        this.setState({ periodFrom: today, periodTo: today });
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.unit && prevProps.unit !== this.props.unit) {
            this.handleGenerateReport();
        }
    };

    handleOnCheckVehicles = (id: number) => {
        const equipmentIds = this.state.equipmentIds.slice();
        if (equipmentIds.indexOf(id) >= 0) {
            equipmentIds.splice(equipmentIds.indexOf(id), 1);
        } else {
            equipmentIds.push(id);
        }
        if (equipmentIds.length > 0 && this.props.errors.get('equipmentIds')) {
            this.props.removeEconomieReportError('equipmentIds');
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnChangePeriod = (key: string) => (event: Event) => {
        this.changePeriod(key, event.target.value);
        this.props.removeEconomieReportError(key);
    };

    handleOnChangeGasPrice = (e: Event) => {
        this.setState({
            gasPrice: e.target.value,
        });
    };

    handleOnChangedieselPrice = (e: Event) => {
        this.setState({
            dieselPrice: e.target.value,
        });
    };

    handleGenerateReport = () => {
        this.props.validateEconomieReportGenerator(this.state).then(() => {
            // submit generation form.
            // Fetch Economie report data if there is not current place in the paginated calls.
            const page = this.props.economieReportPagination.get('currentPage');
            if (!page) {
                const data = { ...this.state };
                data.fileName = this.props.intl.formatMessage({
                    id: 'views.Reports.SideMenu.Economie',
                });
                this.props.generateEconomieReport(data, 1);
            }

            if (this.state.reportGeneratedFilterOpen) {
                this.setState({
                    reportGeneratedFilterOpen: false,
                });
            }
        });
    };

    changePeriod = (key: string, date: string) => {
        if(this.state.dateLimit){
            this.setState({
                dateLimit:false,
            });
        };
        if( (key==="periodFrom")
         && (dateFns.differenceInDays(this.state.periodTo,date) > 366) ){
            const newDateTo= dateFns.addDays(date,366);
            const formattedNewDate= dateFns.format(newDateTo,'YYYY-MM-DD');

        this.setState({
            dateLimit:true,
            periodTo:formattedNewDate,
            dateLimitPositionLeft:true,
        })
         } else if((key==="periodTo")
              && (dateFns.differenceInDays(date, this.state.periodFrom) > 366)){
                const newDateFrom= dateFns.subDays(date,366);
                const formattedNewDateFrom= dateFns.format(newDateFrom,'YYYY-MM-DD');
                this.setState({
                  dateLimit:true,
                  periodFrom:formattedNewDateFrom,
                  dateLimitPositionLeft:false,
               })
          };
        let dateToChange=date
        const dateCompare =
        (key =="periodFrom")
        ? dateFns.compareAsc(date,this.state.periodTo)
        : dateFns.compareAsc(this.state.periodFrom,date);
        const todayCompare = dateFns.compareAsc(date,today);
        if(todayCompare===1){dateToChange=today}
        console.log(dateCompare);
         if (dateCompare === 1){
            this.setState(() => ({
           [(key=="periodFrom")
           ? "periodTo"
           :"periodFrom"
           ]:dateToChange,
            }))
        };
        this.setState((prevState) => ({
            [key]: dateToChange,
            period: PRESET_PERIODS.custom,
        }));
    };

    handleSetPeriod = (presetPeriod: string) => () => {
        const currentDate = new Date();
        const period = {};
        this.setState({
            dateLimit:false,
        });
        switch (presetPeriod) {
            case PRESET_PERIODS.today: {
                period.to = dateFns.startOfToday();
                period.from = currentDate;
                break;
            }
            case PRESET_PERIODS.yesterday: {
                const yesterday = dateFns.subDays(currentDate, 1);
                period.from = dateFns.startOfDay(yesterday);
                period.to = dateFns.endOfDay(yesterday);
                break;
            }
            case PRESET_PERIODS.lastWeek: {
                const lastWeek = dateFns.subWeeks(currentDate, 1);
                period.from = dateFns.startOfWeek(lastWeek);
                period.to = dateFns.endOfWeek(lastWeek);
                break;
            }
            case PRESET_PERIODS.lastMonth: {
                const lastMonth = dateFns.subMonths(currentDate, 1);
                period.from = dateFns.startOfMonth(lastMonth);
                period.to = dateFns.endOfMonth(lastMonth);
                break;
            }
            case PRESET_PERIODS.thisMonth: {
                period.from = dateFns.startOfMonth(currentDate);
                period.to = dateFns.endOfMonth(currentDate);
                if(dateFns.compareAsc(today,period.to) < 0){
                    period.to = today
                 };
                break;
            }
            case PRESET_PERIODS.thisWeek: {
                period.from = dateFns.startOfWeek(currentDate);
                period.to = dateFns.endOfWeek(currentDate);
                if(dateFns.compareAsc(today,period.to) < 0){
                    period.to = today
                 };
                break;
            }
            default: {
                period.from = '';
                period.to = '';
            }
        }
        if (this.props.errors.get('periodFrom')) {
            this.props.removeEconomieReportError('periodFrom');
        }

        if (this.props.errors.get('periodTo')) {
            this.props.removeEconomieReportError('periodTo');
        }

        this.setState({
            periodTo: dateFns.format(period.to, 'YYYY-MM-DD'),
            periodFrom: dateFns.format(period.from, 'YYYY-MM-DD'),
            period: presetPeriod,
        });
    };

    handleOnClickModifyReport = () =>
        this.setState((prevState) => ({
            reportGeneratedFilterOpen: !prevState.reportGeneratedFilterOpen,
        }));

    handleOnExportPDF = () => {
        const data = { ...this.state };
        this.props.validateEconomieReportGenerator(data).then(() => {
            // submit generation form.
            data.format = OUTPUT_FORMATS.pdf;
            data.fileName = this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.Economie',
            });
            this.props.generateEconomieReport(data);
        });
    };

    handleOnExportXLS = () => {
        const data = { ...this.state };
        this.props.validateEconomieReportGenerator(data).then(() => {
            // submit generation form.
            data.format = OUTPUT_FORMATS.xls;
            data.fileName = this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.Economie',
            });
            this.props.generateEconomieReport(data);
        });
    };

    handleOnSelectAllVehicles = (searchedEquipments) => {
        let equipmentIds;
        if(searchedEquipments.toArray().length > 0) {
           equipmentIds = searchedEquipments.map((equipment) => equipment.get('id')).toArray();
        }else{
           equipmentIds = this.props.equipment.filter(eq=>{
            if(eq.toJS().cardNumber){
             return !eq.toJS().cardNumber.startsWith('B')
            }else{
                return true
            }
           }).map((equipment) => equipment.get('id')).toArray();
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnUnselectAllVehicles = () => {
        const equipmentIds = [];
        this.setState({
            equipmentIds,
        });
    };

    onChangeSelect = (key: string) => (value: string) => {
        this.setState({ [key]: value });
    };

    /*handleViewMoreEconomieReport = () => {
        const data = { ...this.state };
        const page = this.props.economieReportPagination.get('currentPage') || 0;
        this.props.generateEconomieReport(data,page + 1);
    };*/

    // To be refactored...
    renderGenerator = () => {
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }
        const volumePrice = this.props.isImperial()
            ? this.props.intl.formatMessage({ id: 'containers.Reports.Generators.gallonprice' })
            : this.props.intl.formatMessage({ id: 'containers.Reports.Generators.litreprice' });
        return (
            <div>
                <DrivingReportGenerator>
                    <div className={"economieReport"}>
                        <ReportsInputDate
                            startDate={this.state.periodFrom}
                            onChangeStartDate={this.handleOnChangePeriod('periodFrom')}
                            errorsStartDate={this.props.errors.get('periodFrom')}
                            endDate={this.state.periodTo}
                            onChangeEndDate={this.handleOnChangePeriod('periodTo')}
                            errorEndDate={this.props.errors.get('periodTo')}
                            dateLimit={this.state.dateLimit}
                            dateLimitPositionLeft={this.state.dateLimitPositionLeft}
                        >
                        </ReportsInputDate>

                        <DrivingReportGenerator.Column>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.OutputFormat'}
                                />
                            </InputLabel>
                            <DrivingReportGenerator.SelectContainer>
                                <SelectField
                                    options={[
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Page',
                                            }),
                                            value: OUTPUT_FORMATS.page,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Pdf',
                                            }),
                                            value: OUTPUT_FORMATS.pdf,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Xls',
                                            }),
                                            value: OUTPUT_FORMATS.xls,
                                        },
                                    ]}
                                    value={this.state.format}
                                    borderColor={colors.grayED}
                                    arrowColor={colors.green63}
                                    onChange={this.onChangeSelect('format')}
                                />
                            </DrivingReportGenerator.SelectContainer>
                        </DrivingReportGenerator.Column>
                    </div>
                    <div>
                        <DrivingReportGenerator.Column>
                            <DrivingReportGenerator.ButtonContainer>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.today)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.Today'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.yesterday)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.Yesterday'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.thisWeek)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.ThisWeek'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.lastWeek)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.LastWeek'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.thisMonth)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.ThisMonth'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.lastMonth)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.LastMonth'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.SubmitButton onClick={this.handleGenerateReport}>
                                    <FormattedMessage id={'containers.Reports.Generators.Driving.Generate'}/>
                                </DrivingReportGenerator.SubmitButton>
                            </DrivingReportGenerator.ButtonContainer>
                        </DrivingReportGenerator.Column>
                        <div>


                            <DrivingReportGenerator.Column>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={<Tooltip id="tooltip-right">{volumePrice}</Tooltip>}
                                >
                                    <InputLabel margin={'0 0 15px 0'} style={{maxWidth: 400}}>
                                        <FormattedMessage id={'containers.Reports.Generators.gasprice'}/>{' '}
                                        <i className="fas fa-info-circle"/>
                                    </InputLabel>
                                </OverlayTrigger>
                                <div style={{maxWidth: 300}}>
                                    <FormInputText
                                        type={'number'}
                                        value={this.state.gasPrice}
                                        onChange={this.handleOnChangeGasPrice}
                                        placeholderId={'containers.Reports.Generators.gasprice'}
                                    />
                                </div>
                            </DrivingReportGenerator.Column>
                            <DrivingReportGenerator.Column>
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={<Tooltip id="tooltip-right">{volumePrice}</Tooltip>}
                                >
                                    <InputLabel margin={'0 0 15px 0'} style={{maxWidth: 400}}>
                                        <FormattedMessage
                                            id={'containers.Reports.Generators.dieselprice'}
                                        />{' '}
                                        <i className="fas fa-info-circle"/>
                                    </InputLabel>
                                </OverlayTrigger>
                                <div style={{maxWidth: 300}}>
                                    <FormInputText
                                        type={'number'}
                                        value={this.state.dieselPrice}
                                        step="0.01"
                                        onChange={this.handleOnChangedieselPrice}
                                        placeholderId={'containers.Reports.Generators.dieselprice'}
                                    />
                                </div>
                            </DrivingReportGenerator.Column>
                        </div>

                    </div>
                </DrivingReportGenerator>

                <Split color={colors.splitGrayReports} opacity={0.2} />

                <InputLabel margin={'30px 0 20px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.Vehicles'} />
                </InputLabel>

                {this.props.errors.get('equipmentIds') && (
                    <DrivingReportGenerator.Error>
                        <Error
                            text={this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.Driving.EquipmentIdsError',
                            })}
                        />
                    </DrivingReportGenerator.Error>
                )}

                <ReportVehicleSearch
                    equipment={this.props.equipment.filter(eq=> {
                        if(eq.toJS().cardNumber){
                         return !eq.toJS().cardNumber.startsWith('B')
                        }else{
                            return true
                        }
                       })}
                    onCheckVehicles={this.handleOnCheckVehicles}
                    checkedInputs={this.state.equipmentIds}
                    onSelectAllVehicles={this.handleOnSelectAllVehicles}
                    onUnselectAllVehicles={this.handleOnUnselectAllVehicles}
                />

                <Split color={colors.splitGrayReports} opacity={0.2} />


            </div>
        );
    };

    /**
     * Render that shows
     * @param {number} columnSize Decides on how many columns
     * @param {boolean} showExtraColumns show or not total stop time
     * @param {boolean} tableFooter get totalVehicleUtilisationTime && totalVehicleDistance
     * @return {Component} rendered component
     */
    renderFooterVehiclesItem = (tableFooter) => {
        const columnSize = 7;
        const { report } = this.props;
        const reportFilters = report.getIn(['meta', 'filters']);
        const reportFrom = dateFns.format(reportFilters.get('datetimeStart'), 'YYYY/MM/DD');
        const reportTo = dateFns.format(reportFilters.get('datetimeEnd'), 'YYYY/MM/DD');
        return (
            <Alert
                style={{
                    color: colors.black,
                    backgroundColor: '#bfbfbf33',
                    borderColor: colors.green63,
                }}
            >
                <Dashboard.FooterContainer columns={columnSize}>
                    <Dashboard.FooterItem>
                        <Dashboard.FooterText>
                            <FormattedMessage
                                id={'containers.Reports.Generators.AllTotal.trajet'}
                            />
                        </Dashboard.FooterText>
                    </Dashboard.FooterItem>
                    <Dashboard.FooterItem>
                        <Dashboard.FooterText>
                            {tableFooter.get('totalVehiclesUtilisationTime')}
                            <br />
                            {tableFooter.get('totalVehiclesTimeIdle')}
                        </Dashboard.FooterText>
                    </Dashboard.FooterItem>
                    <Dashboard.FooterItem>
                        <Dashboard.span>{tableFooter.get('totalVehiclesDistance')}</Dashboard.span>
                    </Dashboard.FooterItem>
                    <Dashboard.FooterItem>
                        <Dashboard.FooterText>
                            {tableFooter.get('totalVehiclesKeyOnCount')}
                            <br />
                            {tableFooter.get('totalVehiclesKeyOffCount')}
                        </Dashboard.FooterText>
                    </Dashboard.FooterItem>
                    <Dashboard.FooterItem>
                        <Dashboard.FooterText>
                            {tableFooter.get('totalVehiclesAutoKeyOnCount')}
                            <br />
                            {tableFooter.get('totalVehiclesAutoKeyOffCount')}
                        </Dashboard.FooterText>
                    </Dashboard.FooterItem>
                    <Dashboard.FooterItem>
                        <Dashboard.FooterText>
                            {tableFooter.get('totalVehiclesCoupeMotorEcotime')}
                        </Dashboard.FooterText>
                    </Dashboard.FooterItem>
                    <Dashboard.FooterItem>
                        <Dashboard.FooterText>
                            {tableFooter.get('totalVehiclesCoutEconomise')}
                            <br />
                            {tableFooter.get('totalVehiclesReductionGes')}
                        </Dashboard.FooterText>
                    </Dashboard.FooterItem>
                </Dashboard.FooterContainer>
            </Alert>
        );
    };

    renderReport = () => {
        const { report, economieReportPagination } = this.props;
        const reportTitle = report.getIn(['meta', 'title']);
        const reportFilters = report.getIn(['meta', 'filters']);
        const periodetimeStart = reportFilters.get('periodetimeStart');
        const periodetimeEnd = reportFilters.get('periodetimeEnd');
        const reportFrom = dateFns.format(reportFilters.get('datetimeStart'), 'YYYY/MM/DD');
        const reportTo = dateFns.format(reportFilters.get('datetimeEnd'), 'YYYY/MM/DD');
        const reportFilterText = [];
        reportFilterText.date = `${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${reportFrom} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${reportTo}`;
        reportFilterText.time =
            periodetimeStart && periodetimeEnd
                ? `${this.props.intl.formatMessage({
                      id: 'containers.Reports.Generators.periode',
                  })} ${periodetimeStart} - ${periodetimeEnd}`
                : '';
        const tables = [];

        report.get('data').forEach((data, index) => {
            const dataTitle = data.get('meta').get('title');
            let entries = Object.entries(data.getIn(['meta', 'headers']).toJS());
            entries = entries.map((entry: [string, string]) => {
                return {
                    key: entry[0],
                    label: entry[1],
                    sortMethod: null,
                };
            });

            tables.push(
                <ReportDataTable
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    title={dataTitle}
                    columns={entries}
                    pagination={economieReportPagination}
                    tableData={data.get('data')}
                />
            );
        });

        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        return (
            <Report.Header>
                <DrivingReportGenerator.ReportType style={{ marginTop: 15, marginBottom: 40 }}>
                    {!this.state.reportGeneratedFilterOpen && (
                        <React.Fragment>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={ReportIcon}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportTitle}
                            </Report.TextContainer>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={Calendar}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportFilterText.date}
                                <br />
                                {reportFilterText.time}
                            </Report.TextContainer>
                        </React.Fragment>
                    )}
                    <Report.ModifyReport onClick={this.handleOnClickModifyReport}>
                        <Report.Icon
                            src={MagnifyingGlass}
                            width={20}
                            height={20}
                            margin={'0 0 0 17px'}
                        />
                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                            <FormattedMessage id={'containers.Reports.Generators.Modify'} />
                        </p>
                    </Report.ModifyReport>
                </DrivingReportGenerator.ReportType>
                <SlideDown className={'generator'}>
                    {this.state.reportGeneratedFilterOpen ? this.renderGenerator() : null}
                </SlideDown>
                {tables}
                {tables.length > 0
                    ? this.renderFooterVehiclesItem(
                          report.getIn(['data', 'economieSummary', 'meta', 'total'])
                      )
                    : null}
            </Report.Header>
        );
    };

    render() {
        return (
            <ReportGeneratorContainer>
                <GenerateReportHeader
                    report={this.props.report}
                    title={this.props.intl.formatMessage({
                        id: 'views.Reports.SideMenu.Economie',
                    })}
                    onExportPDF={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportPDF : null
                    }
                    onExportXLS={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportXLS : null
                    }
                />
                <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} />
                {this.props.report && this.props.report.size > 0
                    ? this.renderReport()
                    : this.renderGenerator()}
            </ReportGeneratorContainer>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    errors: selectEconomieReportGenerationErrors(),
    loading: selectIsLoading(),
    report: selectEconomieReport(),
    equipment: selectVehicles(),
    economieReportPagination: selectEconomieReportPagination(),
    unit: selectUnit(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            validateEconomieReportGenerator,
            generateEconomieReport,
            removeEconomieReportError,
            isImperial,
            clearEconomyReport,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
