// @flow

import type { UserDataType, Organization } from 'types';

// branch
export const GET_BRANCH_DATA_SUCCESS = 'app/services/Branch/GET_BRANCH_DATA_SUCCESS';
export const GET_BRANCH_SUCCESS = 'app/services/Branch/GET_BRANCH_SUCCESS';

export const EDIT_BRANCH_SUCCESS = 'app/services/Branch/EDIT_BRANCH_SUCCESS';

// users
export const GET_BRANCH_USER_SUCCESS = 'app/services/Branch/GET_BRANCH_USER_SUCCESS';
export const CREATE_USER_FAIL = 'app/services/Branch/CREATE_USER_FAIL';
export const DELETE_BRANCH_USER_SUCCESS = 'app/services/Branch/DELETE_BRANCH_USER_SUCCESS';

export const DELETE_USER_ERROR = 'app/services/Branch/DELETE_USER_ERROR';

export const DELETE_BRANCH_SUCCESS = 'app/services/Branch/DELETE_BRANCH_SUCCESS';

// Branch actions
export const DELETE_BRANCH_USER = 'app/services/Branch/DELETE_BRANCH_USER';

// branch division
export const CREATE_BRANCH_DIVISION_SUCCESS = 'app/services/Branch/CREATE_BRANCH_DIVISION_SUCCESS';

export const DELETE_BRANCH_EQUIPMENT = 'app/services/Branch/DELETE_BRANCH_EQUIPMENT';

export const receivedBranchDataSuccess = (branch): GenericActionType => ({
    type: GET_BRANCH_DATA_SUCCESS,
    branch,
});

export const receivedCreateBranchSuccess = (branch: Organization) => ({
    type: GET_BRANCH_SUCCESS,
    branch,
});

export const receivedEditBranchSuccess = (branch: Organization) => ({
    type: EDIT_BRANCH_SUCCESS,
    branch,
});

export const receivedDeleteUserError = (property: string) => ({
    type: DELETE_USER_ERROR,
    property,
});

export const receivedDeleteBranchSuccess = () => ({
    type: DELETE_BRANCH_SUCCESS,
});

export const receivedDeleteBranchUser = (userId: number) => ({
    type: DELETE_BRANCH_USER_SUCCESS,
    userId,
});

export const receivedCreateBranchDivisionSuccess = (division: Organization) => ({
    type: CREATE_BRANCH_DIVISION_SUCCESS,
    division,
});

export const receivedDeleteBranchEquipment = (equipmentId: number) => ({
    type: DELETE_BRANCH_EQUIPMENT,
    equipmentId,
});
