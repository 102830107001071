// @flow

import React from 'react';

import { Loader } from './styles';

type Props = {
    loading: ?boolean,
};

function LoaderComponent(props: Props) {
    if (props.loading) {
        return (
            <Loader top={props.top}>
                <Loader.Animation width={props.width} height={props.height} />
            </Loader>
        );
    }
    return null;
}

export default LoaderComponent;
