import {
    receivedToggleDriveCodeModal,
    receivedCloseDriveCodeModal,
    receivedReservationsError,
    receivedReservationsSuccess,
    receivedOpenReservationModal,
    receivedCloseReservationModal,
    receivedReservationDate,
    receivedDateAvailabitySuccess,
    receivedDateAvailabityError,
    receivedReservationHours,
    addReservationSuccess,
    addReservationError,
    receivedReservationsRows,
    receivedReservationById,
    showReservationSuccessMessage,
    closeReservationSuccessMessage,
    emptyReservationSelection, receivedSectorsError, receivedSectorsSuccess,
} from './actions';
import {
    addReservation,
    retrieveDateAvailabity,
    retrieveReservations,
    makeReservation,
    cancelReservation,
    retrieveReservationById,
    editReservation,
    retrieveSectors, removeSectors, updateVehiclesinSectorsBySectorId, updateSectors
} from './resources';
/**
 * Fetch reservations for current user
 */
export const getReservations = () => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);

    return retrieveReservations(token)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedReservationsError());
            }
            dispatch(receivedReservationsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedReservationsError());
        });
};
/**
 * cancel reservations for current user
 */
export const cancelReservations = (reservationId) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);

    return cancelReservation(token,reservationId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedReservationsError());
            }
            dispatch( getReservations());
            dispatch( emptyReservationSelection());
        })
        .catch((error) => {
            dispatch(receivedReservationsError());
        });
};
/**
 * get reservations by id
 */
export const getReservationById = (reservationId) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveReservationById(token,reservationId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedReservationsError());
            }
            dispatch( receivedReservationById(response.data));
        })
        .catch((error) => {
            dispatch(receivedReservationsError());
        });
};
/**
 * edit reservations by id
 */
export const editReservationById = (reservationId ,fullDate) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return editReservation(token,reservationId ,fullDate)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedReservationsError());
            }
            dispatch( getReservations());
            dispatch(showReservationSuccessMessage());
        })
        .catch((error) => {
            dispatch(receivedReservationsError());
        });
};


export const selectedRows = (rows) => (dispatch: ReduxDispatch) =>
    dispatch(receivedReservationsRows(rows));


export const toggleDriveCodeModal = (action: ?string) => (dispatch: ReduxDispatch) =>
    dispatch(receivedToggleDriveCodeModal(action));

export const closeDriveCodeModal = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedCloseDriveCodeModal());
export const openReservationModal = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedOpenReservationModal());
export const closeReservationModal = () => (dispatch: ReduxDispatch) =>
    dispatch(receivedCloseReservationModal());

export const saveReservationDate = (date: ?string) => (dispatch: ReduxDispatch) => {
    dispatch(receivedReservationDate(date));
}
export const getDateAvailability = (date: ?Object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = { ...date };
    return retrieveDateAvailabity(token, data)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedDateAvailabityError());
            }
            dispatch(receivedDateAvailabitySuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedDateAvailabityError());
        });
}
export const saveReservationHours = (hours: ?string) => (dispatch: ReduxDispatch) => {
    dispatch(receivedReservationHours(hours));
}

export const createReservation = (fullDate:?Object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);

    return makeReservation(token,fullDate)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(addReservationError());
            }
            console.log('success');
            dispatch(addReservationSuccess(response.data));
            dispatch(showReservationSuccessMessage())
        })
        .catch((error) => {
            console.log('error', error);
            dispatch(addReservationError());
        });
};

export const hideReservationSuccessMessage = () => (dispatch: ReduxDispatch) =>
    dispatch(closeReservationSuccessMessage());

/**
 * Fetch all sectors
 */
export const getSectors = () => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return retrieveSectors(token)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedSectorsError());
            }
            dispatch(receivedSectorsSuccess(response.data));
            //console.log(response.data)
            return response.data
            //dispatch(receivedSectorsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedSectorsError());
        });
};


/**
 * Delete all sectors
 */
export const deleteSectorsById = (sectorId) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return removeSectors(token, sectorId)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedSectorsError());
            }
            dispatch(receivedSectorsSuccess(response.data));
            //console.log(response.data)
            return response.data
            //dispatch(receivedSectorsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(receivedSectorsError());
        });
};


/**
 * Add or remove vehicle ids by sector id
 */
export const editVehiclesinSectorsBySectorId = (sectorId, vehicleIds) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const data = {
        vehicleIds
    }

    return updateVehiclesinSectorsBySectorId(token, sectorId,data)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedSectorsError());
            }
           // dispatch(receivedSectorsSuccess(response.data));
            return response.data
        })
        .catch((error) => {
            dispatch(receivedSectorsError());
        });
};



/**
 * Add or remove sectors
 */
export const AddOrRemoveSectors = (data) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);

    return updateSectors(token, data)
        .then((response: Object) => {
            console.log(data)
            if (response.data.status && typeof response.data.status === 'string') {
                dispatch(receivedSectorsError());
            }
            // dispatch(receivedSectorsSuccess(response.data));
            console.log(response)
            return response.data
        })
        .catch((error) => {
            dispatch(receivedSectorsError());
        });
};
