/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    loading: {
        margin: '10px 0',
        height: '100%',
    },
}));

export const Loading = () => {
    const classes = useStyles();
    return (
        <div className={classes.loading}>
            <LinearProgress />
        </div>
    );
};
