// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Organization = styled.div`
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

Organization.Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => props.rowSpan || '4'}, 1fr);
    height: 100%;
    grid-column-gap: 15px;
`;

Organization.Item = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;
Organization.Vehicule = styled.div`
    display: flex;
    align-items: center;
    text-decoration: underline;
    min-width: 0;
    &:hover {
        color: #1c39bb;
        cursor: pointer;
    }
`;
Organization.Image = styled.img`
    margin-left: 10px;
    margin-right: 15px;
    width: ${(props) => props.width || '41px'};
    height: ${(props) => props.width || '41px'};
`;

export default Organization;
