// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

import { selectOrganization, selectOrganizationsLoading } from 'services/Organization/selectors';

import { selectCurrentBranch } from 'services/Branch/selectors';

import { selectCurrentDivision } from 'services/Division/selectors';

import { getOrganizationData } from 'services/Organization/thunks';

import { getBranchData } from 'services/Branch/thunks';

import { getDivisionData } from 'services/Division/thunks';

import EquipmentDashboardItem from 'components/DashboardEquipmentItem';
import SearchInput from 'components/SearchInput';
import DashboardDataTable from 'components/DashboardDataTable';
import Modal from 'components/Modals';
import EquipmentModal from 'components/Modals/EquipmentModal';
import Loader from 'components/Loader';

import DataListHeaderContainer from 'containers/DataListHeaderContainer';

import ListPage from 'styles/listPage';
import colors from 'styles/colors';
import { Split, Container } from 'styles/common';

import { MODAL_TYPE, ASSOCIATE_EQUIPMENT } from 'services/Modal/actions';

import { selectModalAction, selectModalType } from 'services/Modal/selectors';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';

import { toggleEquipmentModal } from 'services/Modal/thunks';

import {
    associateOrganizationEquipment,
    associateDivisionEquipment,
    associateBranchEquipment,
    deleteEquipment,
    editOrganizationEquipment,
    createEquipment,
} from 'services/Equipment/thunks';

import type { ImmutableList, ImmutableMap, SearchResultsType, OrganizationListType } from 'types';

type Props = {
    organization: OrganizationListType,
    division: OrganizationListType,
    branch: OrganizationListType,
    toggleEquipmentModal: (value: string) => void,
};

type State = {
    searchResults: ImmutableList<string, ImmutableMap>,
    query: string,
};

export class EquipmentListPage extends React.Component<Props, State> {
    state = {
        searchResults: fromJS([]),
        query: '',
    };

    componentDidMount() {
        let token;

        if (this.props.user) {
            token = this.props.user.get('token');
        } else if (sessionStorage.getItem('api_token')) {
            token = sessionStorage.getItem('api_token');
        }

        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;

        if (organizationId) {
            if (branchId && divisionId) {
                this.props.getDivisionData(organizationId, branchId, divisionId);
                return;
            }

            if (branchId) {
                this.props.getBranchData(organizationId, branchId);
                return;
            }

            this.props.getOrganizationData(organizationId);
        }
    }

    getDataFromRoute = () => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionId = this.props.match.params.divisionId;

        if (organizationId) {
            if (branchId && divisionId) {
                return this.props.division;
            }

            if (branchId) {
                return this.props.branch;
            }

            return this.props.organization;
        }
    };

    handleSearchResults = (searchResults: SearchResults) => {
        this.setState({
            query: searchResults.query,
            searchResults: searchResults.results,
        });
    };

    handleOnClickAssociateEquipment = () => {
        this.props.toggleEquipmentModal();
    };

    handleOnSubmitEquipmentModal = (equipment: EquipmentType, equipmentId: ?number) => {
        if (equipmentId) {
            this.props.editOrganizationEquipment(equipment, equipmentId);
        } else {
            this.props.createEquipment(equipment);
        }
    };

    handleOnClickEditEquipment = (equipmentId) => {
        this.props.toggleEquipmentModal(MODAL_TYPE.EQUIPMENT, equipmentId);
    };

    handleOnClickDeleteEquipment = (equipmentId) => {
        this.props.deleteEquipment(equipmentId, this.props.match.params.organizationId);
    };

    renderModal = () => {
        if (!this.props.modalType) {
            return false;
        }

        let equipmentModalHeader = 'components.Modals.Create.Equipment';

        if (this.props.modalAction === ASSOCIATE_EQUIPMENT) {
            equipmentModalHeader = 'components.Modals.Associate.Equipment';
        }

        if (this.props.modalType === MODAL_TYPE.EQUIPMENT) {
            return (
                <EquipmentModal
                    header={equipmentModalHeader}
                    data={this.props.organization.get('equipment')}
                    onSubmit={this.handleOnSubmitEquipmentModal}
                />
            );
        }
    };

    render() {
        const data = this.getDataFromRoute();

        const tableData =
            this.state.query && this.state.searchResults && this.state.searchResults.size >= 0
                ? this.state.searchResults
                : data.get('equipment');
        const equipmentColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'name',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Operational',
                }),
                key: 'operational',
            },
        ];

        return (
            <ListPage isOpen={this.props.isOpen}>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({ id: 'containers.HomePage.helmetTitle' })}
                    </title>
                </Helmet>
                <DataListHeaderContainer
                    title={this.props.intl.formatMessage({
                        id: 'views.EquipmentListPage.Equipment',
                    })}
                    name={`${data.get('name')}`}
                    actionButtonText={'components.Action.Equipment.Create'}
                    // actionButtonText={'components.Action.Equipment.Associate'}
                    onClickActionButton={this.handleOnClickAssociateEquipment}
                />
                <Split color={colors.grayDD} />
                <ListPage.FilterContainer>
                    <ListPage.SearchContainer>
                        <SearchInput
                            onSearchResults={this.handleSearchResults}
                            searchKeys={['name']}
                            borderColor={colors.grayDD}
                            data={data.get('equipment')}
                        />
                    </ListPage.SearchContainer>
                </ListPage.FilterContainer>
                <Loader loading={this.props.loading} />
                <DashboardDataTable
                    onClickEditEquipment={this.handleOnClickEditEquipment}
                    onClickDeleteEquipment={this.handleOnClickDeleteEquipment}
                    columns={equipmentColumns}
                    imageSource={'imgSource'}
                    tableData={tableData}
                    id={'branchId'}
                    component={EquipmentDashboardItem}
                    list
                />
                {/* <Container.OverFlow margin={'50px'} /> */}
                <Modal>{this.renderModal()}</Modal>
            </ListPage>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    organization: selectOrganization(),
    branch: selectCurrentBranch(),
    division: selectCurrentDivision(),
    modalType: selectModalType(),
    modalAction: selectModalAction(),
    isOpen: selectIsSideMenuOpen(),
    loading: selectOrganizationsLoading(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getOrganizationData,
            getBranchData,
            getDivisionData,
            toggleEquipmentModal,
            associateOrganizationEquipment,
            associateDivisionEquipment,
            associateBranchEquipment,
            editOrganizationEquipment,
            createEquipment,
            deleteEquipment,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(EquipmentListPage))
);
