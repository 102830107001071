// @flow

/*
 *
 * Protected Route
 *
 * This component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { type Node } from 'react';
import { Route, Redirect } from 'react-router-dom';
import withAuthorization from 'containers/withAuthorization';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { changeLocale } from 'services/Language/thunks';
import { selectLocale } from 'services/Language/selectors';
import { selectUser } from 'services/Authentication/selectors';
import { parseJWT } from 'helpers';

type Props = {
    path: string,
    exact: boolean,
};

class ProtectedRoute extends React.Component<Props> {
    componentDidMount() {
        if (this.props.computedMatch.params.locale) {
            if (this.props.locale !== this.props.computedMatch.params.locale) {
                this.props.changeLocale(this.props.computedMatch.params.locale);
            }
        }
    }

    render() {
        const jwt = parseJWT(this.props.user.get('token'));

        // redirect user to login page if they are not authenticated.
        if (!sessionStorage.getItem('api_token')) {
            return <Redirect to={`/${this.props.locale}/login`} />;
        }

        if (
            jwt.permissions &&
            Array.isArray(jwt.permissions) &&
            ((this.props.admin && jwt.permissions.indexOf('access:admin') < 0) ||
                (this.props.clientAdmin &&
                    jwt.permissions.indexOf('own:branch') < 0 &&
                    jwt.permissions.indexOf('write:branch-settings') < 0))
        ) {
            return <Redirect to={`/${this.props.locale}/403`} />;
        }

        return <Route {...this.props}>{this.props.children}</Route>;
    }
}

const mapStateToProps: Object = createStructuredSelector({
    locale: selectLocale(),
    user: selectUser(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            changeLocale,
        },
        dispatch
    );

export default withAuthorization(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProtectedRoute)
);
