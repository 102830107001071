// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Transition } from 'react-transition-group';
import { Row, Col, Form } from 'react-bootstrap';

import dateFns from 'date-fns';
import SelectField from 'components/SelectField';
import DotLoader from 'components/DotLoader';

import { selectErrors, selectRequest, selectUser } from 'services/Authentication/selectors';
import { authenticateUser } from 'services/Authentication/thunks';
import { changeLocale } from 'services/Language/thunks';
import { selectLocale } from 'services/Language/selectors';
import { changeUnit } from 'services/Units/thunks';
import { selectUnit } from 'services/Units/selectors';
import { parseJWT } from 'helpers';
import { receivedLogoutSuccess } from 'services/Authentication/actions';
import { LoginPage, SidePane, AnimationPane } from './styles';
import colors from 'styles/colors';

import { LogoAndLogoType } from 'components/Icons';
import Image from 'react-bootstrap/Image';
import Radar from 'assets/login-radar.png';
import Loader from 'assets/login-loader.png';
import Pickup from 'assets/login-pickup.png';
import Forklift from 'assets/login-forklift.png';
import Car from 'assets/login-car.png'

import auth from 'services/Authentication/auth0';
import Logo from 'assets/orca-logo-white.png';
import Logo_Black from 'assets/orca-logo-black.png';

type Props = {
    intl: Object,
    adminLogin: ?boolean,
    user: ?Object,
    locale: *,
};

type State = {
    name: string,
    password: string,
    date: Object,
    currentVehicle: Image,
    rotation: Number,
};

const VehicleList = [Car, Pickup, Forklift, Loader];
Array.prototype.cycle = function(str) {
        const i = this.indexOf(str);
        if (i === -1) return undefined;
        return this[(i + 1) % this.length];
    };

export class LoginPageComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: '',
            password: '',
            date: new Date(),
            currentVehicle: Car,
        };
    }

    componentDidMount() {
        // setInterval( () => {
        //     this.changeVehicle();
        // }, 2000);

    }

    changeVehicle = () => {
        const newVeh = VehicleList.cycle(this.state.currentVehicle);
        this.setState({
            currentVehicle: newVeh,
        });
    };

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick = () => {
        this.setState({
            date: new Date(),
        });
    };

    onChangeName = (e) => {
        this.setState({
            name: e.target.value,
        });
    };

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value,
        });
    };

    handleLogin = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        return this.props.authenticateUser(this.state.name, this.state.password, this.props.adminLogin);
    };

    handleOnChangeLanguage = (value: string) => {
        const url = this.props.adminLogin ? `/${value}/admin/login` : `/${value}/login`;
        this.props.history.push(url);
        this.props.changeLocale(value);
    };

    handleOnChangeUnit = (unit) => {
        this.props.changeUnit(unit);
    };

    doOAuth = () => {
        auth.login(this.props.match.path.indexOf('admin') !== -1);
    };

    forgotPassword = () => {
        console.log("redirect to forgot password page")
    };

    setEmailInputReference = (component: *) => {
        this.emailInput = component;
    };

    renderVehicleAnimations = () => {
        return  <AnimationPane>
                    <AnimationPane.ImageContainer>
                        <img className={'radar'} src={Radar}/>
                        <img className={'vehicle'} src={this.state.currentVehicle}/>
                    </AnimationPane.ImageContainer>
                </AnimationPane>
    };

    render() {
        const jwt = parseJWT(this.props.user && this.props.user.get('token')) || null;

        if(jwt) {
            if(this.props.adminLogin) {
                if( jwt.permissions &&
                    Array.isArray(jwt.permissions) &&
                    jwt.permissions.indexOf('access:admin') < 0) {
                        auth.logout(true);
                    }
                else {
                    return <Redirect to={`/${this.props.locale}/interne/orgs`} />;
                }                
            } else {                
                return <Redirect to={`/${this.props.locale}/map`} />;
            }
        } else {
            this.doOAuth();
        }
        return null;
        
        // if (
        //     this.props.adminLogin &&
        //     jwt &&
        //     jwt.permissions &&
        //     Array.isArray(jwt.permissions) &&
        //     jwt.permissions.indexOf('access:admin') < 0
        // ) {
        //     return <Redirect to={`/${this.props.locale}/403`} />;
        // }
        
        return (
            <LoginPage>
                {/* METATAGS */}
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.LoginPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({ id: 'views.Login.Description' })}
                    />
                </Helmet>
                {/* end - METATAGS */}
                <SidePane.ImageContainer>
                    <img src={Logo} style={{width: '28.3%'}}/>
                </SidePane.ImageContainer>
                {/* SIDEPANE */}
                <Transition timeout={0} in={true} appear>
                    {(status) => (
                        <SidePane status={status}>
                            {this.props.adminLogin && (
                                <SidePane.Admin>
                                    <FormattedMessage id={'views.Login.Admin'} />
                                </SidePane.Admin>
                            )}                            
                            <SidePane.DescriptionText>{"Numérisation et gestion de flotte de véhicules."}</SidePane.DescriptionText>
                            <LoginPage.ButtonContainer disabled={this.props.request}>   
                                <LoginPage.InputContainer>
                                    <Row>
                                        <Col style={{paddingRight: '10px', paddingLeft: '0px'}}>
                                            <SelectField
                                                minWidth={'100px'}
                                                disabled={this.props.request}
                                                onChange={this.handleOnChangeLanguage}
                                                placeholder={this.props.intl.formatMessage({
                                                    id: 'views.Login.Language',
                                                })}
                                                options={[
                                                    {
                                                        label: this.props.intl.formatMessage({
                                                            id: 'components.Header.Locale.French',
                                                        }),
                                                        value: 'fr',
                                                    },
                                                    {
                                                        label: this.props.intl.formatMessage({
                                                            id: 'components.Header.Locale.English',
                                                        }),
                                                        value: 'en',
                                                    },
                                                    {
                                                        label: this.props.intl.formatMessage({
                                                            id: 'components.Header.Locale.Spanish',
                                                        }),
                                                        value: 'es',
                                                    },
                                                ]}
                                                borderColor={colors.black}
                                                textColor={colors.black}
                                                padding={'0 0 0 0px'}
                                                backgroundColor={colors.white}
                                                hoverBackgroundColor={'rgba(255, 255, 255, 0.20)'}
                                                arrowColor={colors.black}
                                                value={this.props.locale}
                                            />
                                        </Col>
                                        <Col style={{paddingRight: '0px', paddingLeft: '0px'}}>
                                            <SelectField
                                                minWidth={'100px'}
                                                disabled={this.props.request}
                                                onChange={this.handleOnChangeUnit}
                                                placeholder={this.props.intl.formatMessage({
                                                    id: 'views.Login.Distance',
                                                })}
                                                options={[
                                                    {
                                                        label: this.props.intl.formatMessage({
                                                            id: 'views.Login.Distance.Metric',
                                                        }),
                                                        value: 'metric',
                                                    },
                                                    {
                                                        label: this.props.intl.formatMessage({
                                                            id: 'views.Login.Distance.Imperial',
                                                        }),
                                                        value: 'imperial',
                                                    },
                                                ]}
                                                borderColor={colors.black}
                                                textColor={colors.black}
                                                padding={'0 0 0 0px'}
                                                backgroundColor={colors.white}
                                                hoverBackgroundColor={'rgba(255, 255, 255, 0.20)'}
                                                arrowColor={colors.black}
                                                value={this.props.unit}
                                            />
                                        </Col>
                                    </Row> 
                                    <Row>
                                        <Form.Control
                                            size="35px"
                                            type="email"
                                            placeholder="Email Address"
                                            onChange={async (e) => {
                                                this.onChangeName(e);
                                            }}
                                            value={this.state.name}
                                        />
                                    </Row>
                                    <Row>
                                        <Form.Control
                                            size="35px"
                                            type="password"
                                            placeholder="Password"
                                            onChange={async (e) => {
                                                this.onChangePassword(e);
                                            }}
                                            value={this.state.password}
                                        />
                                    </Row>
                                    <Row>
                                        <LoginPage.LoginButton disabled={this.props.request} onClick={this.doOAuth}>
                                            {this.props.request ? (
                                                <DotLoader loading={true} />
                                            ) : (
                                                <FormattedMessage id={'views.Login.Login'} />
                                            )}
                                        </LoginPage.LoginButton>
                                    </Row>
                                    <Row>
                                        <LoginPage.ForgotPassword
                                            disabled={this.props.request}
                                            onClick={this.forgotPassword} >
                                            {"Forgot password?"}
                                        </LoginPage.ForgotPassword>
                                    </Row>
                                </LoginPage.InputContainer>
                            </LoginPage.ButtonContainer>                              
                        </SidePane>      
                    )}                    
                </Transition>
                {/* end - SIDEPANE */}
                    {this.renderVehicleAnimations()}
                
                {/* BACKGROUND */}
                <Transition timeout={0} in={true} appear>
                    {(status) => <LoginPage.Background status={status} />}
                </Transition>
                {/* end - BACKGROUND */}
            </LoginPage>
        );
    }
}
// Adding redux states to local props
const mapStateToProps: Object = createStructuredSelector({
    request: selectRequest(),
    errors: selectErrors(),
    unit: selectUnit(),
    locale: selectLocale(),
    user: selectUser(),
});

// Adding dispatch to local props
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            authenticateUser,
            changeLocale,
            changeUnit,
            receivedLogoutSuccess,
        },
        dispatch
    );

// Export and wrap component
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(LoginPageComponent))
);
