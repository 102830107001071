// @flow

import type { UserDataType, Organization } from 'types';

// division
export const GET_DIVISION_DATA_SUCCESS = 'app/services/Organization/GET_DIVISION_DATA_SUCCESS';
export const GET_DIVISION_SUCCESS = 'app/services/Organization/GET_DIVISION_SUCCESS';
export const GET_DIVISION_USER_SUCCESS = 'app/services/Organization/GET_DIVISION_USER_SUCCESS';

export const EDIT_DIVISION_SUCCESS = 'app/services/Organization/EDIT_DIVISION_SUCCESS';
export const DELETE_DIVISION_USER_SUCCESS =
    'app/services/Organization/DELETE_DIVISION_USER_SUCCESS';

export const DELETE_DIVISION_USER = 'app/services/Organization/DELETE_DIVISION_USER';

export const DELETE_BRANCH_SUCCESS = 'app/services/Organizaiton/DELETE_BRANCH_SUCCESS';
export const DELETE_DIVISION_SUCCESS = 'app/services/Organizaiton/DELETE_DIVISION_SUCCESS';

export const DELETE_DIVISION_EQUIPMENT = 'app/services/Organizaiton/DELETE_DIVISON_EQUIPMENT';

export const receivedDivisionDataSuccess = (division: Organization) => ({
    type: GET_DIVISION_DATA_SUCCESS,
    division,
});

export const receivedEditDivisionSuccess = (division: Organizaiton) => ({
    type: EDIT_DIVISION_SUCCESS,
    division,
});

export const receivedDeleteDivisionSuccess = () => ({
    type: DELETE_DIVISION_SUCCESS,
});

export const receivedDeleteDivisionUser = (userId: number) => ({
    type: DELETE_DIVISION_USER_SUCCESS,
    userId,
});

export const receivedCreateDivisionSuccess = (division: Organization) => ({
    type: GET_DIVISION_SUCCESS,
    division,
});

export const receivedDeleteDivisionEquipment = (equipmentId: number) => ({
    type: DELETE_DIVISION_EQUIPMENT,
    equipmentId,
});
