import React, { useRef, useEffect, useState } from 'react';
import { MAPBOX_GL_TOKEN } from 'env';
import mapboxgl from 'mapbox-gl';
import './styles.scss';
mapboxgl.accessToken = MAPBOX_GL_TOKEN;

export default function ApSectorComponent() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-71.349442);
  const [lat, setLat] = useState(47.009231);
  const [zoom, setZoom] = useState(12);
  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [lng, lat],
      zoom: zoom
    });
  });
  return (
    <React.Fragment>
      <div ref={mapContainer} className="map-container" />
    </React.Fragment>
  )
}
