// @flow

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import Organization from './styles';
import dateFns from 'date-fns';
import moment from 'moment';
import Chip from '../Chip';

import TestOrganizationImage from 'assets/test-organization.png';

import type { ColumnType } from 'types';

type Props = {
    rowSpan: ?number,
    data: Object<any>,
    columns: Array<ColumnType>,
    showStopTime: *,
};

class OrganizationListItem extends React.PureComponent<Props> {
    dateFormat = (datestart, dateend) => {
        const text = this.props.intl.formatMessage({ id: 'containers.Reports.Generators.move.To' });

        if (dateFns.format(datestart, 'YYYY/MM/DD') === dateFns.format(dateend, 'YYYY/MM/DD')) {
            return `${dateFns.format(datestart, 'YYYY/MM/DD')} ${dateFns.format(
                datestart,
                'h:mm A'
            )} 
            ${text} ${dateFns.format(dateend, 'h:mm A')}`;
        }
        return `${moment(datestart).format('YYYY/MM/DD - h:mm A')} ${text} ${moment(dateend).format(
            'YYYY/MM/DD - h:mm A'
        )}`;
    };

    hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    intToARGB = (i) => {
        let hex =
            ((i >> 24) & 0xff).toString(16) +
            ((i >> 16) & 0xff).toString(16) +
            ((i >> 8) & 0xff).toString(16) +
            (i & 0xff).toString(16);
        hex += '000000';
        return hex.substring(0, 6);
    };

    convertStringToColor = (str) => {
        if (!str) return '';
        str = str.replace(/\s+/g, '');
        return `#${this.intToARGB(this.hashCode(str))}`;
    };

    hexToHSL = (H) => {
        // Convert hex to RGB first
        let r = 0;
        let g = 0;
        let b = 0;
        if (H.length == 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length == 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 100;
        g /= 280;
        b /= 100;
        const cmin = Math.min(r, g, b);
        const cmax = Math.max(r, g, b);
        const delta = cmax - cmin;
        let h = 0;
        if (delta == 0) h = 0;
        else if (cmax == r) h = ((g - b) / delta) % 6;
        else if (cmax == g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;
        h = Math.round(h * 60);
        if (h < 0) h += 360;

        return `hsl(${h},${80}%,${60}%)`;
    };

    render() {
        let rowSpan = 4;
        let showExtraRows = false;
        if (this.props.data.get('transitTime') != -1) {
            rowSpan = 5;
            showExtraRows = true;
        }

        return (
            <Organization>
                <Organization.Griditem rowSpan={rowSpan}>
                    <Organization.datetime key="datetime">
                        {this.dateFormat(
                            this.props.data.get('startTime'),
                            this.props.data.get('endTime')
                        )}
                    </Organization.datetime>
                    <Organization.utilisationtime key="utilisationTime">
                        {this.props.data.get('utilisationTime')}
                    </Organization.utilisationtime>
                    <Organization.idletime key="totalTimeIdle">
                        {this.props.data.get('totalTimeIdle')}
                    </Organization.idletime>
                    <Organization.totaldistance key="totalDistance">
                        <Chip type="distance"> {this.props.data.get('totalDistance')}</Chip>
                        &nbsp;&nbsp;
                        {this.props.data.get('driverName') ? (
                            <Chip
                                type="driver"
                                color={this.hexToHSL(
                                    this.convertStringToColor(this.props.data.get('driverName'))
                                )}
                            >
                                <i className="far fa-id-badge" />
                                &nbsp;
                                {this.props.data.get('driverName')}
                            </Chip>
                        ) : (
                            <empty />
                        )}
                        &nbsp;&nbsp;
                        {this.props.data.get('isPrivateMode')?<Chip type="personal"/>:<empty />}
                    </Organization.totaldistance>
                    {showExtraRows && (
                        <Organization.totaldistance key="transitTime">
                            {this.props.data.get('transitTime')}
                        </Organization.totaldistance>
                    )}
                </Organization.Griditem>
                <Organization.Griditem rowSpan={rowSpan}>
                    <Organization.empty />
                    <Organization.stoptime key="stopTime">
                        {this.props.showStopTime && this.props.data.get('stopTime')}
                    </Organization.stoptime>
                    <Organization.empty />
                    {this.props.data.get('stopAddress') ? (
                        <Organization.stopaddress key="stopAddress">
                            <i className="fas fa-map-marker-alt" />
                            &nbsp;
                            {this.props.data.get('stopAddress')}
                        </Organization.stopaddress>
                    ) : (
                        <empty />
                    )}

                    {showExtraRows && (
                        <Organization.totaldistance key="distanceTarget">
                                <Chip type="distance"> {this.props.data.get('distanceTarget')}</Chip>
                        </Organization.totaldistance>
                    )}
                </Organization.Griditem>
            </Organization>
        );
    }
}

export default injectIntl(OrganizationListItem);
