// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';
import { SubOrganization } from 'styles/organization';

import ReportPage from './styles';

type Props = {};

export class ReportPageComponent extends React.Component<Props> {
    reportComponent: React.Component<Props, State>;

    render() {
        return (
            <SubOrganization isOpen={this.props.isOpen}>
                <Helmet>
                    <title>
                      {this.props.title}        
                    </title>
                    <meta name="description" content={'views.Reports.SideMenu.Reports'} />
                </Helmet>
                <ReportPage>{this.props.children}</ReportPage>
            </SubOrganization>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    isOpen: selectIsSideMenuOpen(),
});

const mapDispatchToProps: Object = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
