// @flow

import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import configureStore from './store';
import { translationMessages } from './i18n';
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import SocketClient from 'services/socket/SocketClient';

// // Global styles
import './styles/global';
import 'assets/mapbox-gl.css';
import ErrorBoundary from './components/ErrorBoundary';

// // Create redux store with history
const initialState = {};
const history = createHistory();
const socketClient = SocketClient;
const store = configureStore(initialState, history, socketClient);
const MOUNT_NODE = document.getElementById('app');

// // TODO: Check if need to do special hot reloading for i18n files (like in react-boilerplate)
const renderApp = (messages) => {
    ReactDOM.render(
        <Provider store={store}>
           
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                <ErrorBoundary>
                    <App />
                    </ErrorBoundary>
                </ConnectedRouter>
            </LanguageProvider>
          
        </Provider>,
        MOUNT_NODE
    );
};

renderApp(translationMessages);

if (module.hot) {
    module.hot.accept('containers/App', () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        renderApp(translationMessages);
    });
}
