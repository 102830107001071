import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import screen from 'styles/screen';

const ModalChangePasswordStyles = styled.div`
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
`;

ModalChangePasswordStyles.Modal = styled.div`
    background: ${colors.white};
    display: block;
    width: calc(100% - 40px);
    height: auto;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid #dddddd;
    border-top: 5px solid #000000;
    padding: 25px;

    @media (min-width: ${screen.medium}) {
        width: 600px;
    }
`;

ModalChangePasswordStyles.ModalForm = styled.form``;

ModalChangePasswordStyles.Header = styled.div`
    display: block;
    text-align: center;
    color: ${colors.green63};
    font-size: 24px;
    font-family: OpenSans, sans-serif;
    font-weight: 600;
    line-height: 33px;
    border-bottom: 1px solid ${colors.black};
    padding-bottom: 20px;
`;

ModalChangePasswordStyles.Body = styled.div`
    overflow: hidden;
`;

ModalChangePasswordStyles.ButtonClose = styled.button.attrs({
    type: 'button',
})`
    background: ${colors.black};
    color: ${colors.white};
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: -5px;
    right: -1px;

    &:focus {
        outline: none;
    }

    @media (min-width: ${screen.medium}) {
        right: -40px;
    }
`;

ModalChangePasswordStyles.InputField = styled.div`
    display: flex;
    height: 45px;
    border: 1px solid ${(props) => (props.error ? colors.status.red : colors.grayDD)};
    margin-top: 20px;
    padding: 0 12px 0 17px;

    background: ${(props) => (props.loading ? colors.grayE0 : colors.white)};
    opacity: ${(props) => (props.loading ? '0.5' : '1.0')};

    > input {
        background: none;
        border: none;
        color: ${colors.gray10};
        font-family: OpenSans, sans-serif;
        font-size: 16px;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
`;

ModalChangePasswordStyles.Errors = styled.div`
    border: 1px solid ${colors.status.red};
    padding: 12px;
    color: ${colors.status.red};
    margin-top: 20px;
`;

ModalChangePasswordStyles.Footer = styled.div`
    margin-top: 20px;
`;

ModalChangePasswordStyles.ButtonCancel = styled.button.attrs({
    type: 'button',
})`
    background-color: ${colors.white};
    border: 1px solid ${colors.green63};
    color: ${colors.black};
    cursor: pointer;
    font-family: OpenSans, sans-serif;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    line-height: 40xp;
    transition: all 250ms ease-in-out 0s;
    padding: 0 20px;
    opacity: ${(props) => (props.loading ? '0.5' : '1.0')};
    width: 100%;

    &:hover {
        background-color: ${(props) => (props.loading ? colors.white : colors.green63)};
        color: ${(props) => (props.loading ? colors.black : colors.white)};
    }

    &:focus {
        outline: none;
    }

    @media (min-width: ${screen.medium}) {
        width: auto;
    }
`;

ModalChangePasswordStyles.ButtonConfirm = styled.button.attrs({
    type: 'submit',
})`
    background-color: ${colors.green63};
    border: 1px solid ${colors.green63};
    color: ${colors.white};
    cursor: pointer;
    font-family: OpenSans, sans-serif;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    line-height: 40xp;
    transition: all 250ms ease-in-out 0s;
    margin-right: 9px;
    padding: 0 20px;
    opacity: ${(props) => (props.loading ? '0.5' : '1.0')};
    width: 100%;
    margin-bottom: 20px;

    &:hover {
        background-color: ${(props) => (props.loading ? colors.green63 : colors.white)};
        color: ${(props) => (props.loading ? colors.white : colors.green63)};
    }

    &:focus {
        outline: none;
    }

    @media (min-width: ${screen.medium}) {
        width: auto;
        margin-bottom: 0px;
    }
`;

export default ModalChangePasswordStyles;
