import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ApEdit } from '../Icons';
import './styles.scss';
import { openReservationModal, getReservationById } from '../../services/AutoPartage/thunks';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectRows, selectReservation } from '../../services/AutoPartage/selectors';

function ApEditReservation(props) {
    const [checked, setCheckeed] = useState(false);
    const handleOnClickReservation = () => {
        setCheckeed(!checked);
    };
    useEffect(
        () => {

            if (props.selectedRows) {
                props.openReservationModal();
                props.getReservationById(props.selectedRows);
            }
        },
        [checked]
    );
    return (
        <React.Fragment>
            <Button className={'edit'} onClick={handleOnClickReservation}>
                <ApEdit title={props.intl.formatMessage({
                    id:
                        'containers.AP.Home.Buttons.edit',
                })} />
            </Button>
        </React.Fragment>
    );
}

const mapStateToProps = createStructuredSelector({
    selectedRows: selectRows(),
    reservation: selectReservation(),
});
const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            openReservationModal,
            getReservationById,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ApEditReservation))
);
