import React, {useState, useEffect, useRef} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {bindActionCreators} from 'redux';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
    IconButton,
    Chip,
    Divider,
    TextField
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getSectors} from "../../services/AutoPartage/thunks";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from '@mui/icons-material/Search';
import './styles.scss';

const SectorListComponent = (props) => {
    const useStyles = makeStyles((theme) => ({
        list: {
            height: '80%',
            width: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.5em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgb(98,170,54)',
                borderRadius: '10px',
            },

            '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0,0,0,0.1)',
                borderRadius: '10px',
            },

        },
        listItem: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.action.hover
            },
            '&.selected': {
                backgroundColor: '#c6c9cb69',
            },
        },
        customCheckbox: {
            color: '#62ab36',
            '&.Mui-checked': {
                color: '#62ab36',
            },
        },
        divider: {},
        searchBar: {
            marginBottom: theme.spacing(2),
            '& .MuiInputBase-root': {
                height: '30px',
                alignItems: 'center',
            },
            '& .MuiInputBase-input': {
                height: '20px',
                padding: '10px 14px',
            },
        },

    }));

    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [selectedSector, setSelectedSector] = useState(null);
    const [graySector, setGraySector] = useState([]);
    const [sectorsList, setSectorsList] = useState([]);

    useEffect(() => {
        const fetchSectors = async () => {
            try {
                const res = await props.getSectors();
                const filteredSectors = res.filter(sector => sector.vehicleId.length > 0);
                setSectorsList(filteredSectors);
            } catch (error) {
                console.error('Error fetching sectors:', error);
            }
        };

        fetchSectors();
    }, [props.getSectors]);

    useEffect(() => {

        setGraySector([])
        setSearchTerm('')

    }, [sectorsList]);


    // Function to handle sector click
    const handleSectorClick = (sector) => {
        setSelectedSector(sector.idSector);
        setGraySector(sector);
        props.getSectors();
        props.setClickedSector(sector)

    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredSectors = sectorsList.filter((sector) =>
        sector.geofenceName ? sector.geofenceName.toLowerCase().includes(searchTerm.toLowerCase()) : sector.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const sortedSectors = filteredSectors.sort((a, b) => {
        const aName = a.geofenceName ? a.geofenceName.toLowerCase() : a.name.toLowerCase();
        const bName = b.geofenceName ? b.geofenceName.toLowerCase() : b.name.toLowerCase();

        if (a.numberOfVehicles !== b.numberOfVehicles) {
            return b.numberOfVehicles - a.numberOfVehicles;
        }
        return aName.localeCompare(bName);
    });


    const handleClickAction = (event, sector) => {
        event.stopPropagation();
        const array = new Set();
        array.add(sector.idSector);
    };


    return (
        <div style={{height: '100%'}} className={'SectorContainer'}>

            <div className={'expanded-list'}>
                <div style={{padding: '0 10px 0 10px'}}>
                    <div style={{fontSize: '16px', fontWeight: '600', paddingBottom: '10px'}}><FormattedMessage
                        id={'containers.AP.Sector.Component.Title'}/></div>
                    <TextField
                        className={classes.searchBar}
                        variant="outlined"
                        fullWidth
                        placeholder="Rechercher"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <SearchIcon style={{fontSize: 'larger', color: '#9ea8b7'}}/>
                            ),
                        }}
                    />
                </div>

                <List className={classes.list} style={{width: '90%', margin: 'auto'}}>
                    {sortedSectors.map((sector, index) => (
                        <React.Fragment key={sector.geofenceId}>
                            <ListItem
                                className={`${classes.listItem} ${graySector && graySector.idSector === sector.idSector ? 'selected' : ''}`}
                                onClick={() => handleSectorClick(sector)}
                                selected={selectedSector && selectedSector.idSector === sector.idSector}
                            >
                                <ListItemText
                                    primary={
                                        <Typography component={'span'} variant="body2"
                                                    style={{fontSize: '12px'}}>
                                            {sector.geofenceName}
                                        </Typography>
                                    }
                                />
                                {/*<Chip*/}
                                {/*    style={{*/}
                                {/*        height: '25px',*/}
                                {/*        backgroundColor: sector.numberOfVehicles > 0 ? '#95c479' : '#cac4c4',*/}
                                {/*        color: 'white'*/}
                                {/*    }}*/}
                                {/*    label={`${sector.numberOfVehicles} ${props.intl.formatMessage({*/}
                                {/*        id: 'containers.AP.Sector.Component.Vehicle.Tag',*/}
                                {/*    })}`}*/}

                                {/*/>*/}
                                <ListItemText>
                                    <IconButton size={'small'} edge="end" aria-label="more"
                                                onClick={(e) => handleClickAction(e, sector)}>
                                        {/*<MoreVertIcon fontSize={'small'}/>*/}
                                    </IconButton>
                                </ListItemText>
                            </ListItem>
                            {index < sortedSectors.length - 1 && <Divider className={classes.divider}/>}
                        </React.Fragment>
                    ))}
                </List>
            </div>
        </div>
    );

}

const mapStateToProps = createStructuredSelector({

});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        getSectors,

    }, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(SectorListComponent))
);
