// @flow

import type { InputEvent } from 'types';

export const CHANGE_LOCALE = 'app/Language/CHANGE_LOCALE';
export const DEFAULT_LOCALE = 'fr';

export const receivedChangedLocale = (locale: string): GenericActionType => ({
    type: CHANGE_LOCALE,
    payload: {
        locale,
    },
});
