/* eslint-disable flowtype/require-parameter-type,react/jsx-no-bind */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useCallback, useEffect, useState } from 'react';
import useStyles from '../../styles/List.styles';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText  from '@material-ui/core/ListItemText';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { Loading } from '../../utils/Loading';
import { Message } from '../../utils/Message';
import AlertDialog from '../../utils/AlertDialog';
import SearchField from '../../utils/SearchField';
import { formatString, InfoText } from '../../utils/String';
import { useIntlContext } from '../../context/IntlContext';

const UserList = ({ users, isLoading, onDeletedUser }) => {
    const classes = useStyles();
    const { intl } = useIntlContext();
    const [deleteUser, setDeleteUser] = useState(null);
    const [searchUsers, setSearchUsers] = useState([]);
    const [searchUsersValue, setSearchUsersValue] = useState(null);

    const handleDeleteUser = useCallback((userId) => {
        onDeletedUser(userId);
        setDeleteUser(null);
    });

    const handleSearchChange = useCallback((event) => setSearchUsersValue(event.target.value));

    useEffect(
        () => {
            if (users) {
                if (!searchUsersValue) setSearchUsers(users);
                if (searchUsersValue) {
                    setSearchUsers(
                        users.filter(({ firstName, lastName }) => {
                            const name = `${firstName} ${lastName}`;
                            return formatString(name).includes(formatString(searchUsersValue));
                        })
                    );
                }
            }
        },
        [setSearchUsers, searchUsersValue, users]
    );

    const DeleteDialog = () =>
        deleteUser && (
            <AlertDialog
                onCancel={() => setDeleteUser(null)}
                onAgree={() => handleDeleteUser(deleteUser.id)}
                open={deleteUser}
                title={`${deleteUser.firstName} ${deleteUser.lastName}`}
                message={'views.Groups.DeleteMessage'}
                cancelText={'views.Groups.Cancel'}
                agreeText={'views.Groups.Yes'}
            />
        );

    if (isLoading) return <Loading />;

    if (users && !users.length) return <Message id={'views.Groups.NoUsers'} />;

    return (
        <React.Fragment>
            <SearchField onChange={handleSearchChange} label={'views.Groups.SearchVehicle'} />
            <List className={classes.list} dense>
                <InfoText
                    value={`${intl.formatMessage({
                        id: 'views.Groups.Users',
                    })} (${searchUsers.length})`}
                />
                {searchUsers
                    .sort((a, b) => a.firstName.localeCompare(b.firstName))
                    .map((user) => (
                        <ListItem key={user.id} className={classes.item} button>
                            <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                            <IconButton size={'small'} edge="end" onClick={() => setDeleteUser(user)}>
                                <DeleteForever />
                            </IconButton>
                        </ListItem>
                    ))
                }
            </List>
            <DeleteDialog />
        </React.Fragment>
    );
};

export default UserList;
