/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useEffect, useMemo } from 'react';
import MultiSelectField from '../inputs/MultiSelectField';
import { useUsers } from '../../hooks/useUsers';
import { Loading } from '../../utils/Loading';

const UserSelectField = ({ existingIds, onChange }) => {
    const { fetchUsers, users, isFetchingUsers } = useUsers();

    useEffect(
        () => {
            if (!isFetchingUsers && !users) {
                fetchUsers();
            }
        },
        [isFetchingUsers, fetchUsers, users]
    );

    const options = useMemo(
        () => {
            if (!users) return [];

            const sortedUsers = users.sort((a, b) => {
                if (!a || !a.firstName) {
                    return 1;
                }
                if (!b || !b.firstName) {
                    return -1;
                } else {
                    return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
                }
            });
            return sortedUsers.reduce((filteredUsers, { id, firstName, lastName }) => {
                if (!existingIds.includes(id)) {
                    filteredUsers.push({
                        label: `${firstName} ${lastName}`,
                        value: id,
                    });
                }
                return filteredUsers;
            }, []);
        },
        [users]
    );

    if (isFetchingUsers) return <Loading />;

    return <MultiSelectField options={options} onChange={onChange} label="views.Groups.Users" />;
};

export default UserSelectField;
