/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import React, { useEffect, useMemo } from 'react';

import { useVehicles } from '../../hooks/useVehicles';
import { Loading } from '../../utils/Loading';
import MultiSelectField from '../inputs/MultiSelectField';

const VehicleSelectField = ({ existingIds, onChange }) => {
    const { fetchVehicles, vehicles, isFetchingVehicles } = useVehicles();

    useEffect(
        () => {
            if (!isFetchingVehicles && !vehicles) {
                fetchVehicles();
            }
        },
        [isFetchingVehicles, fetchVehicles, vehicles]
    );

    const options = useMemo(
        () => {
            if (!vehicles) return [];
            const filteredVehicles = vehicles
                .filter(
                    ({ id, visible, operational }) =>
                        !existingIds.includes(id) && visible && operational
                )
                .sort((a, b) => {
                    if (!a || !a.name) {
                        return 1;
                    }
                    if (!b || !b.name) {
                        return -1;
                    } else {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    }
                });
            return filteredVehicles.map(({ id, name }) => ({ label: name, value: id }));
        },
        [vehicles]
    );

    if (isFetchingVehicles) return <Loading />;

    return <MultiSelectField options={options} onChange={onChange} label="views.Groups.Vehicles" />;
};

export default VehicleSelectField;
