// @flow

/*
 *
 * withAuthorization
 *
 * A higher order component that checks if a user is logged in or not. If the user is logged in
 * then the component will be rendered, otherwise the user will be redirected to the login page.
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { selectWhoamiStatus, selectUser } from 'services/Authentication/selectors';
import { authenticateUser, whoami } from 'services/Authentication/thunks';

import type { ReduxDispatch } from 'types';
import type { UserType } from 'services/Authentication/types';

type Props = {
    user: ?UserType,
};

export default function(ComposedComponent) {
    class AuthorizedUser extends React.Component<Props> {
        render() {
            return (
                <React.Fragment>
                    {this.props.user || sessionStorage.getItem('api_token') ? (
                        <ComposedComponent {...this.props} />
                    ) : (
                        this.props.redirect()
                    )}
                </React.Fragment>
            );
        }
    }
    const mapStateToProps = createStructuredSelector({
        user: selectUser(),
    });

    const mapDispatchToProps = (dispatch: ReduxDispatch) =>
        bindActionCreators(
            {
                redirect: () => push('/fr/login'),
            },
            dispatch
        );

    return withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AuthorizedUser)
    );
}
