// @flow

import request from 'services/adminRequest';
import auth0 from './auth0';
/**
 * Authenticates a user if the credentials are correct
 */

export function authenticate(email: string, password: string, locale: string): Promise<> {
    return request(`${locale}/auth/login`, {
        method: 'POST',
        data: {
            email,
            password,
        },
    });
}

export function authenticateAdministrator(
    email: string,
    password: string,
    locale: string
): Promise<> {
    return request(`${locale}/admin/auth/login`, {
        method: 'POST',
        data: {
            email,
            password,
        },
    });
}

export function me(locale: string, token: string): Promise<> {
    // return auth0.getUser();
}

export function refreshUser(locale, token): Promise<> {
    return auth0.renewSession();
}

export function changePassword(
    old_password: string, // eslint-disable-line
    new_password: string, // eslint-disable-line
    new_password_confirmation: string, // eslint-disable-line
    locale: string,
    token: string
): Promise<> {
    return request(
        `${locale}/auth/password`,
        {
            method: 'PUT',
            data: {
                old_password,
                new_password,
                new_password_confirmation,
            },
        },
        false,
        token
    );
}

/**
 * Checks the user Authentication token and returns the user
 */

export function retrieveAuthenticatedUser(token: string): Promise<> {
    return request(`whoami/${token}`, {
        method: 'GET',
    });
}

export function authorizeForgotPassword(email: string, locale: string): Promise<> {
    return request(
        `${locale}/auth/password/email`,
        {
            method: 'POST',
            data: {
                email,
            },
        },
        true
    );
}

export function authorizeResetPassword(
    email: string,
    password: string,
    locale: string,
    token: string
): Promise<> {
    return request(
        `${locale}/auth/password/reset/${token}`,
        {
            method: 'POST',
            data: {
                email,
                password,
            },
        },
        true
    );
}

export function authorizeCreatePassword(
    email: string,
    password: string,
    locale: string,
    token: string
): Promise<> {
    return request(
        `${locale}/auth/password/create/${token}`,
        {
            method: 'POST',
            data: {
                email,
                password,
            },
        },
        true
    );
}

export function loginAsUser(userId: number, token: string): () => Promise<> {
    return request(
        `users/${userId}/impersonate`,
        {
            method: 'POST',
        },
        true,
        token
    ).then((response) => response.data.accessToken);
}

export function directAccess(branchId: number, token: string): () => Promise<> {
    return request(
        `directAccess/${branchId}`,
        {
            method: 'POST',
        },
        true,
        token
    ).then((response) => response.data.accessToken);
}
