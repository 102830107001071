// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectUser } from 'services/Authentication/selectors';
import { selectLocale } from 'services/Language/selectors';

import { selectCurrentDivision } from 'services/Division/selectors';

import { deleteUser, createUser, editUser } from 'services/User/thunks';

import {
    getDivisionData,
    deleteDivision,
    editDivision,
    deleteDivisionEquipment,
} from 'services/Division/thunks';

import { DELETE_DIVISION_USER } from 'services/Division/actions';

import {
    createEquipment,
    associateDivisionEquipment,
    editDivisionEquipment,
} from 'services/Equipment/thunks';

import {
    toggleOrganizationModal,
    toggleUserModal,
    toggleEquipmentModal,
} from 'services/Modal/thunks';
import { selectModalType, selectModalAction } from 'services/Modal/selectors';
import {
    MODAL_TYPE,
    CREATE_DIVISION_USER,
    EDIT_DIVISION,
    EDIT_DIVISION_USER,
    CREATE_DIVISION_EQUIPMENT,
    EDIT_DIVISION_EQUIPMENT,
    ASSOCIATE_EQUIPMENT,
} from 'services/Modal/actions';

import DashboardDataTable from 'components/DashboardDataTable';
import TextWithBadge from 'components/TextWithBadge';
import SelectField from 'components/SelectField';
import EquipmentDashboardItem from 'components/DashboardEquipmentItem';
import UserDashboardItem from 'components/DashboardDataUserItem';

import DetailHeader from 'components/DetailHeader';
import Modal from 'components/Modals';
import CreateModal from 'components/Modals/CreateOrganizationModal';
import CreateUserModal from 'components/Modals/CreateUserModal';
import CreateEquipmentModal from 'components/Modals/EquipmentModal';

import DeletedMessage from 'components/DeletedMessage';

import { SubOrganization } from 'styles/organization';
import colors from 'styles/colors';
import { Split } from 'styles/common';

import type { OrganizationType } from 'types';

type Props = {};

export class DivisionPageComponent extends React.Component<Props> {
    componentDidMount() {
        // fetch branch data if it does not exist yet.
        let token;
        if (this.props.user) {
            token = this.props.user.get('token');
        } else if (sessionStorage.getItem('api_token')) {
            token = sessionStorage.getItem('api_token');
        }

        if (token) {
            this.props.getDivisionData(
                this.props.match.params.organizationId,
                this.props.match.params.branchId,
                this.props.match.params.divisionId
            );
        }
    }

    handleOnClickViewMoreUsers = () => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const usersURL = `/${
            this.props.intl.locale
        }/interne/orgs/${organizationId}/branch/${branchId}/users`;
        this.props.history.push(usersURL);
    };

    handleOnClickDivision = (divisionId: number) => {
        const organizationId = this.props.match.params.organizationId;
        const branchId = this.props.match.params.branchId;
        const divisionUrl = `/${
            this.props.intl.locale
        }/interne/orgs/${organizationId}/branch/${branchId}/divisions/${divisionId}`;

        this.props.history.push(divisionUrl);
    };

    handleOnChangeAction = (value: string) => {
        if (value === CREATE_DIVISION_USER) {
            this.props.toggleUserModal(value);
        }

        if (value === CREATE_DIVISION_EQUIPMENT) {
            this.props.toggleEquipmentModal(value);
        }

        if (value === ASSOCIATE_EQUIPMENT) {
            this.props.toggleEquipmentModal(value);
        }
    };

    handleEditDivision = () => {
        this.props.toggleOrganizationModal(EDIT_DIVISION);
    };

    handleOnClickEditUser = (userId: number) => {
        this.props.toggleUserModal(EDIT_DIVISION_USER, userId);
    };

    handleOnClickDeleteUser = (userId: number) => {
        this.props.deleteUser(userId, DELETE_DIVISION_USER);
    };

    handleOnClickEditEquipment = (equipmentId: number) => {
        this.props.toggleEquipmentModal(EDIT_DIVISION_EQUIPMENT, equipmentId);
    };

    handleOnClickDeleteEquipment = (equipmentId: number) => {
        this.props
            .deleteEquipment(equipmentId, this.props.match.params.organizationId)
            .then(() => this.props.deleteDivisionEquipment(equipmentId));
    };

    handleOnSubmitOrganizationModal = (organization: OrganizationType) => {
        const organizationId = this.props.division.get('organizationId');
        const branchId = this.props.division.get('parentId');
        const divisionId = this.props.division.get('branchId');

        if (this.props.modalAction === EDIT_DIVISION) {
            this.props.editDivision(organization, organizationId, branchId, divisionId);
        }
    };

    handleOnSubmitUserModal = (user: UserType, userId: number) => {
        if (this.props.modalAction === CREATE_DIVISION_USER) {
            this.props.createUser(
                user,
                this.props.modalAction,
                this.props.division.get('organizationId'),
                this.props.division.get('parentId'),
                this.props.division.get('branchId')
            );
        }

        if (this.props.modalAction === EDIT_DIVISION_USER) {
            this.props.editUser(
                userId,
                user,
                this.props.modalAction,
                this.props.division.get('organizationId'),
                this.props.division.get('parentId'),
                this.props.division.get('branchId')
            );
        }
    };

    handleOnSubmitEquipmentModal = (equipment: EquipmentType, equipmentId: ?number) => {
        if (this.props.modalAction === CREATE_DIVISION_EQUIPMENT) {
            this.props.createEquipment(equipment).then((equipmentResponse: EquipmentType) => {
                const organizaitonId =
                    this.props.division.get('organizationId') ||
                    this.props.match.params.organizationId;
                const branchId =
                    this.props.division.get('parentId') || this.props.match.params.branchId;
                const divisionId =
                    this.props.division.get('branchId') || this.props.match.params.divisionId;
                if (organizaitonId && branchId && divisionId) {
                    this.props.associateDivisionEquipment(
                        organizaitonId,
                        branchId,
                        divisionId,
                        equipmentResponse.equipmentType.id
                    );
                }
            });
        }

        if (this.props.modalAction === EDIT_DIVISION_EQUIPMENT && equipmentId) {
            this.props.editDivisionEquipment(equipment, equipmentId);
        }

        if (this.props.modalAction === ASSOCIATE_EQUIPMENT && equipment) {
            this.props.associateDivisionEquipment(
                this.props.division.get('organizationId'),
                this.props.division.get('parentId'),
                this.props.division.get('branchId'),
                equipmentId
            );
        }
    };

    handleDeleteDivision = () => {
        this.props.deleteDivision(
            this.props.division.get('branchId'),
            this.props.division.get('parentId'),
            this.props.division.get('organizationId')
        );
    };

    /**
     * Get the data size.
     * count property is the object property where the data size may exist.
     * key is the array data property.
     */
    getCount = (key: string, countProperty: string) => {
        let count = 0;

        if (this.props.division.get(countProperty)) {
            count = this.props.division.get(countProperty);
        } else if (this.props.division.get(key)) {
            count = this.props.division.get(key).size;
        }
        return count;
    };

    renderModal = () => {
        if (!this.props.modalType) {
            return false;
        }

        const organizationModalHeader = 'components.Modals.Edit.Division';

        let userModalHeader = 'components.Modals.Create.User';
        if (this.props.modalAction === EDIT_DIVISION_USER) {
            userModalHeader = 'components.Modals.Edit.User';
        }

        let equipmentModalHeader = 'components.Modals.Create.Equipment';
        if (this.props.modalAction === EDIT_DIVISION_EQUIPMENT) {
            equipmentModalHeader = 'components.Modals.Edit.Equipment';
        }
        if (this.props.modalAction === ASSOCIATE_EQUIPMENT) {
            equipmentModalHeader = 'components.Modals.Associate.Equipment';
        }

        if (this.props.modalType === MODAL_TYPE.USER) {
            return (
                <CreateUserModal
                    header={userModalHeader}
                    data={this.props.division.get('users')}
                    onSubmit={this.handleOnSubmitUserModal}
                />
            );
        }

        if (this.props.modalType === MODAL_TYPE.ORGANIZATION) {
            return (
                <CreateModal
                    header={organizationModalHeader}
                    data={this.props.division}
                    onSubmit={this.handleOnSubmitOrganizationModal}
                />
            );
        }

        if (this.props.modalType === MODAL_TYPE.EQUIPMENT) {
            return (
                <CreateEquipmentModal
                    header={equipmentModalHeader}
                    data={this.props.division.get('equipment')}
                    onSubmit={this.handleOnSubmitEquipmentModal}
                />
            );
        }
    };

    render() {
        if (!this.props.division) {
            return false;
        }

        const divisionActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.Division.Create',
            }),
            update: this.props.intl.formatMessage({
                id: 'components.Action.Division.Update',
            }),
            delete: this.props.intl.formatMessage({
                id: 'components.Action.Division.Delete',
            }),
            associate: this.props.intl.formatMessage({
                id: 'components.Action.Equipment.Associate',
            }),
        };

        const userActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.User.Create',
            }),
            update: this.props.intl.formatMessage({
                id: 'components.Action.User.Update',
            }),
            delete: this.props.intl.formatMessage({
                id: 'components.Action.User.Delete',
            }),
        };

        const address = `${this.props.division.get('address')} - ${this.props.division.get(
            'city'
        )} ${this.props.division.get('province')}, ${this.props.division.get(
            'postalCode'
        )} ${this.props.division.get('country')}`;

        const operatorColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'driverName',
            },
        ];

        const equipmentColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'name',
            },
        ];

        const userColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'firstName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.LastLogin',
                }),
                key: 'lastLogin',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.UserGroups',
                }),
                key: 'groups',
            },
        ];

        const userCount = this.getCount('users', 'usersCount');
        const equipmentCount = this.getCount('equipment', 'equipmentCount');
        const operatorCount = this.getCount('operators', 'operatorCount');
        return (
            <SubOrganization>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({ id: 'containers.HomePage.helmetTitle' })}
                    </title>
                    <meta name="description" content={'views.DivisionPage.DivisionPage'} />
                </Helmet>
                {this.props.division.get('deleted') && (
                    <DeletedMessage
                        text={this.props.intl.formatMessage({ id: 'views.DivisionPage.Deleted' })}
                    />
                )}
                <DetailHeader
                    title={this.props.intl.formatMessage({ id: 'views.DivisionPage.Division' })}
                    name={this.props.division.get('branchName')}
                    address={address}
                    onClickEdit={this.handleEditDivision}
                    onClickDelete={this.handleDeleteDivision}
                />
                <Split color={colors.grayDD} />
                <SubOrganization.ManagementContainer>
                    <SubOrganization.DataHeader>
                        <TextWithBadge
                            text={this.props.intl.formatMessage({
                                id: 'components.Equipment',
                            })}
                            badge={equipmentCount}
                        />
                    </SubOrganization.DataHeader>
                    <SubOrganization.DataSelect>
                        <SelectField
                            options={[
                                // {
                                //     label: divisionActionLabels.create,
                                //     value: CREATE_DIVISION_EQUIPMENT,
                                // },
                                {
                                    label: divisionActionLabels.associate,
                                    value: ASSOCIATE_EQUIPMENT,
                                },
                            ]}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.SelectField.Manage.Equipment',
                            })}
                            padding={'2px 0 0 0'}
                            onChange={this.handleOnChangeAction}
                            maxWidth={230}
                            center
                        />
                    </SubOrganization.DataSelect>
                </SubOrganization.ManagementContainer>
                <DashboardDataTable
                    columns={equipmentColumns}
                    tableData={this.props.division.get('equipment')}
                    onClickDataItem={this.handleOnClickDivision}
                    id={'branchId'}
                    component={EquipmentDashboardItem}
                    onClickDeleteEquipment={this.handleOnClickDeleteEquipment}
                    onClickEditEquipment={this.handleOnClickEditEquipment}
                />
                <SubOrganization.ManagementContainer>
                    <SubOrganization.DataHeader>
                        <TextWithBadge
                            text={this.props.intl.formatMessage({ id: 'components.Operators' })}
                            badge={operatorCount}
                        />
                    </SubOrganization.DataHeader>
                    <SubOrganization.DataSelect>
                        <SelectField
                            // options={[
                            //     { label: userActionLabels.create, value: CREATE_DIVISION_USER },
                            // ]}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.SelectField.Manage.Operators',
                            })}
                            padding={'2px 0 0 0'}
                            onChange={this.handleOnChangeUsersAction}
                            maxWidth={230}
                            center
                        />
                    </SubOrganization.DataSelect>
                </SubOrganization.ManagementContainer>
                <DashboardDataTable
                    columns={operatorColumns}
                    tableData={this.props.division.get('operators')}
                    id={'branchId'}
                    onClickViewMore={this.handleOnClickViewMoreUsers}
                    users
                />

                <SubOrganization.ManagementContainer>
                    <SubOrganization.DataHeader>
                        <TextWithBadge
                            text={this.props.intl.formatMessage({ id: 'components.Users' })}
                            badge={userCount}
                        />
                    </SubOrganization.DataHeader>
                    <SubOrganization.DataSelect>
                        <SelectField
                            options={[
                                { label: userActionLabels.create, value: CREATE_DIVISION_USER },
                            ]}
                            placeholder={this.props.intl.formatMessage({
                                id: 'components.SelectField.Manage.Users',
                            })}
                            padding={'2px 0 0 0'}
                            onChange={this.handleOnChangeAction}
                            maxWidth={230}
                            center
                        />
                    </SubOrganization.DataSelect>
                </SubOrganization.ManagementContainer>
                <DashboardDataTable
                    columns={userColumns}
                    tableData={this.props.division.get('users')}
                    id={'branchId'}
                    onClickViewMore={this.handleOnClickViewMoreUsers}
                    onClickEditUser={this.handleOnClickEditUser}
                    onClickDeleteUser={this.handleOnClickDeleteUser}
                    component={UserDashboardItem}
                    users
                />
                <Modal>{this.renderModal()}</Modal>
            </SubOrganization>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    user: selectUser(),
    locale: selectLocale(),
    division: selectCurrentDivision(),
    modalType: selectModalType(),
    modalAction: selectModalAction(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getDivisionData,
            toggleOrganizationModal,
            toggleUserModal,
            toggleEquipmentModal,
            deleteUser,
            editDivision,
            createUser,
            editUser,
            deleteDivision,
            createEquipment,
            associateDivisionEquipment,
            editDivisionEquipment,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(DivisionPageComponent))
);
