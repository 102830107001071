import React from 'react';
import './styles.scss';
import ApDeleteConfirmation from '../ApDeleteConfirmation';
import ApEditReservation from '../ApEditReservation';

export default function ApReservationAction(props) {

    return (
        <React.Fragment>
            {props.type === 'edit' ? <ApDeleteConfirmation /> : <ApEditReservation />}
        </React.Fragment>
    );
}
