// @flow

import { push } from 'react-router-redux';

import {
    receivedEquipmentTypesSuccess,
    receivedAssociateDivisionEquipmentSuccess,
    receivedAssosciateDivisionEquipmentError,
    receivedAssociateBranchEquipmentSuccess,
    receivedAssociateBranchEquipmentError,
    receivedAssociateOrganizationEquipmentSuccess,
    receivedAssociateOrganizationEquipmentError,
    receivedCreateEquipmentSuccess,
    receivedCreateEquipmentError,
    receivedEditOrganizationEquipmentSuccess,
    receivedEditOrganizationEquipmentError,
    receivedEditBranchEquipmentSuccess,
    receivedEditBranchEquipmentError,
    receivedEditDivisionEquipmentSuccess,
    receivedEditDivisionEquipmentError,
    receivedDeleteEquipmentSuccess,
    receivedDeleteEquipmentError,
    receivedEquipmentResponseSuccess,
    receivedEquipmentResponseError,
    receivedEquipmentSearchResultsSuccess,
    receivedFuelLevelData,
    receivedClearFuelLevel,
    loading
} from './actions';

import { receivedCloseModal } from 'services/Modal/actions';

import {
    associateEquipmentRequest,
    createEquipmentRequest,
    editEquipmentRequest,
    deleteEquipmentRequest,
    requestAllEquipment,
    requestAverageFuelLevel
} from './resources';
import { getOrganizationData } from 'services/Organization/thunks';
import type { EquipmentType } from 'types';

export const createEquipment = (equipment: EquipmentType) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return createEquipmentRequest(equipment, token, locale)
        .then((response) => {
            // return dispatch(receivedCreateEquipmentSuccess(equipment));
            dispatch(getOrganizationData(equipment.branchId));
        })
        .catch((error) => {
            dispatch(receivedCreateEquipmentError(error));
        });
};

export const deleteEquipment = (equipmentId: number, branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return deleteEquipmentRequest(equipmentId, token, locale)
        .then((response) => {
            dispatch(getOrganizationData(branchId));
        })
        .catch((error) => {
            dispatch(receivedDeleteEquipmentError(error));
        });
};

export const editOrganizationEquipment = (equipment: EquipmentType, equipmentId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return editEquipmentRequest(equipment, equipmentId, token, locale)
        .then((response) => {
            // dispatch update equipment
            dispatch(receivedCloseModal());
            return dispatch(
                receivedEditOrganizationEquipmentSuccess({ ...equipment, id: equipmentId })
            );
        })
        .catch((error) => dispatch(receivedCreateEquipmentError(error)));
};

export const editBranchEquipment = (equipment: EquipmentType, equipmentId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return editEquipmentRequest(equipment, equipmentId, token, locale)
        .then((response) => {
            // dispatch update equipment
            dispatch(receivedCloseModal());
            return dispatch(receivedEditBranchEquipmentSuccess(response.data));
        })
        .catch((error) => dispatch(receivedEditBranchEquipmentError(error)));
};

export const editDivisionEquipment = (equipment: EquipmentType, equipmentId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return editEquipmentRequest(equipment, equipmentId, token, locale)
        .then((response) => {
            // dispatch update equipment
            dispatch(receivedCloseModal());
            return dispatch(receivedEditDivisionEquipmentSuccess(response.data));
        })
        .catch((error) => dispatch(receivedEditDivisionEquipmentError(error)));
};

export const associateDivisionEquipment = (
    organizationId: number,
    branchId: number,
    divisionId: number,
    equipmentId: number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    const data = {
        branchId: divisionId,
        equipmentId,
    };

    const url = `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/equipment`;

    return associateEquipmentRequest(url, data, token)
        .then((response) => {
            return dispatch(receivedAssociateDivisionEquipmentSuccess(response.data.data));
        })
        .catch((error) => {
            dispatch(receivedAssosciateDivisionEquipmentError(error));
        });
};

export const associateBranchEquipment = (
    organizationId: number,
    branchId: number,
    equipmentId: number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    const url = `${locale}/organizations/${organizationId}/branches/${branchId}/equipment`;

    return associateEquipmentRequest(url, { branchId, equipmentId }, token)
        .then((response) => dispatch(receivedAssociateBranchEquipmentSuccess(response.data.data)))
        .catch((error) => dispatch(receivedAssociateBranchEquipmentError(error)));
};

export const associateOrganizationEquipment = (organizationId: number, equipmentId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    const url = `${locale}/organizations/${organizationId}/equipment`;
    const data = {
        branchId: organizationId,
        equipmentId,
    };

    return associateEquipmentRequest(url, data, token)
        .then((response) =>
            dispatch(receivedAssociateOrganizationEquipmentSuccess(response.data.data))
        )
        .catch((error) => dispatch(receivedAssociateOrganizationEquipmentError(error)));
};

export const getEquipment = (page: number = 1, name: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return requestAllEquipment(page, name, token, locale)
        .then((response) => {
            if (name) {
                return dispatch(receivedEquipmentSearchResultsSuccess(response.data, name));
            }

            dispatch(receivedEquipmentResponseSuccess(response.data));
        })
        .catch((error) => dispatch(receivedEquipmentResponseError(error)));
};

export const equipmentError = (error: any) => (dispatch) => {
    dispatch(receivedCreateEquipmentError(error));
    return Object.keys(error).length === 0;
};

export const getAverageFuelLevel = (
    cardNumber: string,
    periodDateFrom: string = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
    ).toISOString(),
    periodDateTo: string = new Date().toISOString()
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    dispatch(loading());
    const token = getState().getIn(['auth', 'user', 'token']);
    return requestAverageFuelLevel(token, cardNumber, periodDateFrom, periodDateTo)
        .then((response: Object) => {
            if (response.data.status && typeof response.data.status === 'string') {
            }
            dispatch(receivedFuelLevelData(response.data));
        })
        .catch((error) => {});
};

export const clearFuelLevel = () => (dispatch) => {
    dispatch(receivedClearFuelLevel());
};
