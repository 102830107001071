// @flow

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import Header from 'components/Modals/Header';
import FormGroup from 'components/Modals/FormGroup';
import FormInputText from 'components/FormInputText';
import CheckInput from 'components/CheckInput';
import OutlineButton from 'components/OutlineButton';
import SelectField from 'components/SelectField';
import colors from 'styles/colors';
import get from 'lodash.get';
import {
    CREATE_BRANCH,
    CREATE_ORGANIZAITON,
    CREATE_DIVISION,
    EDIT_ORGANIZAITON,
    EDIT_BRANCH,
    EDIT_DIVISION,
} from 'services/Modal/actions';

import { selectModalAction } from 'services/Modal/selectors';

import { closeModal } from 'services/Modal/thunks';

import { selectOrganization } from 'services/Organization/selectors';

import { selectCurrentBranch } from 'services/Branch/selectors';

import { selectCurrentDivision } from 'services/Division/selectors';

import { Modal, Organization, CheckField, Submit } from 'styles/modal';
import { fromJS, Map } from 'immutable';
import { organizationError } from 'services/Organization/thunks';
import { selectOrganizationError } from '../../../services/Organization/selectors';

type State = {
    name: string,
    contactInformation: {
        name: string,
        email: string,
        homePhone: string,
        mobilePhone: string,
        otherPhone: string,
        fax: string,
    },
    address: {
        address: string,
        city: string,
        province: string,
        country: string,
        postalCode: string,
        timeZone: string,
    },
    comments: string,
    measurementSystem: string,
};

type Props = {
    header: string,
    data: ImmutableList<OrganizationType>,
    action: string,
    onSubmit: (organization: State) => void,
    closeModal: () => void,
};
const requiredFields = ['name'];
class CreateOrganizationModalComponent extends React.PureComponent<Props, State> {
    state = {
        name: undefined,
        contactInformation: {
            name: undefined,
            email: undefined,
            homePhone: undefined,
            mobilePhone: undefined,
            otherPhone: undefined,
            fax: undefined,
        },
        address: {
            address: undefined,
            city: undefined,
            province: undefined,
            country: undefined,
            postalCode: undefined,
            timeZone: undefined,
        },
        comments: undefined,
        measurementSystem: 'Metric',
    };

    componentDidMount() {
        // fill state with the entity we're editing.
        switch (this.props.action) {
            case EDIT_ORGANIZAITON:
            case EDIT_BRANCH:
            case EDIT_DIVISION: {
                this.hydrateState(this.props.data);
                break;
            }
            default:
                break;
        }
    }

    componentWillUnmount() {
        this.props.organizationError({});
    }

    hydrateState = (entity: any) => {
        let newState = entity;
        if (!entity.get('measurementSystem')) {
            newState = entity.set('measurementSystem', 'Metric');
        }
        if (!entity.get('api_credentials')) {
            newState = entity.set('api_credentials', entity.get('apiCredentials'));
        }
        this.setState((prev) => {
            const next = {
                ...prev,
                ...newState.toJS(),
            };
            if (!next.comments) next.comments = '';
            return next;
        });
    };

    setInState = (key, value) => {
        let next;
        if (key.indexOf('.') > -1) {
            const map = Map(this.state);
            next = map.setIn(key.split('.'), value).toJS();
        } else {
            next = {
                ...this.state,
                [key]: value,
            };
        }
        this.setState(next);
    };

    handleOnChangeText = (key: string) => (event: Event) => {
        const value = event.target.value;
        this.setInState(key, value);
    };

    handleToggleCheckField = (key: string) => () => {
        this.setState((prevState) => {
            return this.setInState(key, !prevState[key]);
        });
    };

    verifyMandatoryFields = () => {
        const errors = {};
        requiredFields.forEach((field) => {
            const value = get(this.state, field);
            if (!value || value.length === 0) {
                errors[field] = 'Field is required';
            }
        });
        return this.props.organizationError(errors);
    };

    handleSubmit = () => {
        if (this.verifyMandatoryFields()) {
            this.props.onSubmit(this.state);
        }
    };

    handleCancel = () => {
        this.props.closeModal();
    };

    handleOnChangeSelect = (key: string) => (value: string) => {
        this.setState(this.setInState(key, value));
    };

    render() {
        return (
            <Organization>
                <Header headerTextId={this.props.header} />
                <Organization.Content>
                    <FormGroup layout={[1]}>
                        <FormInputText
                            value={this.state.name}
                            placeholderId={'components.Modals.Form.Organization.Name'}
                            onChange={this.handleOnChangeText('name')}
                            error={this.props.error.name}
                            required
                        />
                    </FormGroup>
                    <FormGroup layout={[1]}>
                        <FormInputText
                            value={this.state.contactInformation.name}
                            placeholderId={'components.Modals.Form.Organization.Contact'}
                            onChange={this.handleOnChangeText('contactInformation.name')}
                            error={this.props.error['contactInformation.name']}
                        />
                    </FormGroup>
                    <FormGroup layout={[1, 1]}>
                        <FormInputText
                            value={this.state.contactInformation.email}
                            placeholderId={'components.Modals.Form.Organization.Email'}
                            onChange={this.handleOnChangeText('contactInformation.email')}
                            error={this.props.error['contactInformation.email']}
                        />
                        <FormInputText
                            value={this.state.contactInformation.mobilePhone}
                            placeholderId={'components.Modals.Form.Organization.CellPhone'}
                            onChange={this.handleOnChangeText('contactInformation.mobilePhone')}
                            error={this.props.error['contactInformation.mobilePhone']}
                        />
                    </FormGroup>
                    <FormGroup layout={[1, 1]}>
                        <FormInputText
                            value={this.state.contactInformation.otherPhone}
                            placeholderId={'components.Modals.Form.Organization.OtherPhone'}
                            onChange={this.handleOnChangeText('contactInformation.otherPhone')}
                            error={this.props.error['contactInformation.otherPhone']}
                        />
                        <FormInputText
                            value={this.state.contactInformation.fax}
                            placeholderId={'components.Modals.Form.Organization.Fax'}
                            onChange={this.handleOnChangeText('contactInformation.fax')}
                            error={this.props.error['contactInformation.fax']}
                        />
                    </FormGroup>
                    <FormGroup layout={[1]}>
                        <FormInputText
                            value={this.state.address.address}
                            placeholderId={'components.Modals.Form.Organization.Address'}
                            onChange={this.handleOnChangeText('address.address')}
                            error={this.props.error['address.address']}
                        />
                    </FormGroup>
                    <FormGroup layout={[1, 1]}>
                        <FormInputText
                            value={this.state.address.city}
                            placeholderId={'components.Modals.Form.Organization.City'}
                            onChange={this.handleOnChangeText('address.city')}
                            error={this.props.error['address.city']}
                        />
                        <FormInputText
                            value={this.state.address.province}
                            placeholderId={'components.Modals.Form.Organization.Province'}
                            onChange={this.handleOnChangeText('address.province')}
                            error={this.props.error['address.province']}
                        />
                    </FormGroup>
                    <FormGroup layout={[1, 1]}>
                        <FormInputText
                            value={this.state.address.country}
                            placeholderId={'components.Modals.Form.Organization.Country'}
                            onChange={this.handleOnChangeText('address.country')}
                            error={this.props.error['address.country']}
                        />
                        <FormInputText
                            value={this.state.address.postalCode}
                            placeholderId={'components.Modals.Form.Organization.PostalCode'}
                            onChange={this.handleOnChangeText('address.postalCode')}
                            error={this.props.error['contactInformation.postalCode']}
                        />
                    </FormGroup>
                    <FormGroup layout={[1, 1]}>
                        <div />
                        <SelectField
                            value={this.state.measurementSystem}
                            onChange={this.handleOnChangeSelect('measurementSystem')}
                            placeholderColor={colors.black}
                            options={[
                                {
                                    label: this.props.intl.formatMessage({
                                        id: 'views.Login.Distance.Metric',
                                    }),
                                    value: 'Metric',
                                },
                                {
                                    label: this.props.intl.formatMessage({
                                        id: 'views.Login.Distance.Imperial',
                                    }),
                                    value: 'Imperial',
                                },
                            ]}
                            borderColor={colors.grayDD}
                            textColor={colors.black}
                            padding={'0 0 0 20px'}
                            backgroundColor={'transparent'}
                            error={this.props.error.measurementSystem}
                            arrowColor={colors.green63}
                        />
                    </FormGroup>
                    {/* <FormGroup columns={3}>
                        <FormInputText
                            value={this.state.units}
                            placeholderId={'components.Modals.Form.Organization.Units'}
                            onChange={this.handleOnChangeText('units')}
                        />
                        
                        Commenting this out since it's not on the data structure
                        <FormInputText
                            value={this.state.contactInformation.timezone}
                            placeholderId={'components.Modals.Form.Organization.Timezone'}
                            onChange={this.handleOnChangeText('contactInformation.timezone')}
                        />
                    </FormGroup> */}
                    <FormGroup columns={1} size={130}>
                        <FormInputText
                            value={this.state.comments}
                            placeholderId={'components.Modals.Form.Organization.Comments'}
                            onChange={this.handleOnChangeText('comments')}
                            multiline
                            error={this.props.error.comments}
                        />
                    </FormGroup>
                    {/* <CheckField>
                        <CheckInput
                            value={this.state.isOrca}
                            placeholderId={'components.Modals.Form.Organization.IsOrca'}
                            onChange={this.handleToggleCheckField('isOrca')}
                        />
                        <CheckInput
                            value={this.state.isSystemBranch}
                            placeholderId={'components.Modals.Form.Organization.IsSystemBranch'}
                            onChange={this.handleToggleCheckField('isSystemBranch')}
                        />
                    </CheckField> */}
                </Organization.Content>
                <Submit>
                    <Submit.Button margin={'0 4.5px 0 0'}>
                        <OutlineButton
                            outlineColor={colors.green63}
                            backgroundColor={colors.green63}
                            hoverBackgroundColor={colors.white}
                            hoverTextColor={colors.green63}
                            textColor={colors.white}
                            onClick={this.handleSubmit}
                        >
                            <FormattedMessage id={'components.Modals.Send'} />
                        </OutlineButton>
                    </Submit.Button>
                    <Submit.Button margin={'0 0 0 4.5px'}>
                        <OutlineButton
                            outlineColor={colors.green63}
                            backgroundColor={colors.white}
                            hoverBackgroundColor={colors.green63}
                            hoverTextColor={colors.white}
                            textColor={colors.black}
                            onClick={this.handleCancel}
                        >
                            <FormattedMessage id={'components.Modals.Cancel'} />
                        </OutlineButton>
                    </Submit.Button>
                </Submit>
            </Organization>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    action: selectModalAction(),
    organization: selectOrganization(),
    branch: selectCurrentBranch(),
    division: selectCurrentDivision(),
    error: selectOrganizationError(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            closeModal,
            organizationError,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CreateOrganizationModalComponent));
