// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { insertMaintenanceInformation } from '../../../services/Maintenance/thunks';
import { FormattedMessage, injectIntl } from 'react-intl';
import colors from '../../../styles/colors';
import { Button, Modal, DatePicker } from 'antd';
import { maintenanceInputs } from './MaintenanceInputs';
import moment from 'moment';
import MaintenanceStyle from '../styles';
import { Select, Input, message } from 'antd';

const ParticularMaintenance = (props) => {
    const { TextArea } = Input;

    const [maintenanceInformation, setMaintenanceInformation] = useState({
        seizeDate: new Date(),
        entryDate: new Date(),
        exitDate: new Date(),
        department: '',
        sector: '',
        garage: '',
        odometer: null,
        horometer: null,
        vehicleType: '',
        cost: '',
        receiptNumber: '',
        repairType: '',
        comment: '',
    });

    const successNotif = (msg) => {
        message.success(msg, 2);
    };

    const failureNotif = (msg) => {
        message.warning(msg, 2);
    };

    const handleSetMaintenance = (event, item) => {
        let obj = maintenanceInformation;
        obj[item.type] = item.InputType === 'Date' ? moment(event).format('YYYY-MM-DD HH:mm:ss') : event;
        setMaintenanceInformation({ ...obj });
    };

    const handleApplyChanges = async () => {
        maintenanceInformation.horometer !== null ? maintenanceInformation.horometer = (parseFloat(maintenanceInformation.horometer).toFixed(2)) * 3600 : null;
        await props
            .insertMaintenanceInformation(props.selectedVehicle.id, maintenanceInformation)
            .then(() => {
                successNotif(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.VehicleDetail.Notif.updateInformationMaintenance',
                    }),
                );
                props.handleCancelMaintenance();
            })
            .catch(() => {
                failureNotif(
                    props.intl.formatMessage({
                        id: 'containers.Maintenance.Notif.Error',
                    }),
                );
            });
    };


    return (
        <div>
            <Modal
                title={props.intl.formatMessage({ id: 'containers.Maintenance.MaintenancePlus.Info' })}
                visible={props.isModalMaintenanceOpen}
                onCancel={props.handleCancelMaintenance}
                footer={[
                    <Button
                        key='cancel'
                        type='primary'
                        onClick={props.handleCancelMaintenance}
                        style={{
                            backgroundColor: colors.redA,
                            borderColor: colors.redA,
                        }}
                    >
                        <FormattedMessage id={'containers.Maintenance.Modal.Cancel'} />
                    </Button>,
                    <Button
                        key='confirmer'
                        type='primary'
                        onClick={handleApplyChanges}
                        style={{
                            backgroundColor: colors.green57,
                            borderColor: colors.green57,
                        }}
                    >
                        <FormattedMessage id={'containers.Maintenance.Modal.Confirm'} />
                    </Button>,

                ]}>
                <div>
                    {maintenanceInputs.map((item) => {
                        const label = <FormattedMessage id={'containers.Maintenance.MaintenancePlus.' + item.type} />;
                        switch (item.InputType) {
                            case 'Date' :
                                return (
                                    <div key={item.type}>
                                        <MaintenanceStyle.MaintenanceInput>
                                            {label}
                                            <DatePicker
                                                defaultValue={moment(
                                                    new Date(),
                                                )}
                                                format={'YYYY-MM-DD'}
                                                onChange={(event) => handleSetMaintenance(event !== null ? event._d : new Date(), item)}

                                            />
                                        </MaintenanceStyle.MaintenanceInput>
                                    </div>


                                );

                            case 'text':
                            case 'number':
                                return (
                                    <div key={item.type}>
                                        <MaintenanceStyle.MaintenanceInput>
                                            {label}
                                            <MaintenanceStyle.InputItem
                                                type={item.InputType}
                                                step={'0.01'}
                                                onChange={(event) => handleSetMaintenance(event.target.value, item)}
                                            />
                                        </MaintenanceStyle.MaintenanceInput>
                                    </div>

                                );

                            case 'Select':
                                return (
                                    <div key={item.type}>
                                        <MaintenanceStyle.MaintenanceInput>
                                            {label}
                                            <MaintenanceStyle.Select
                                                defaultValue=''
                                                onChange={(event) => handleSetMaintenance(event, item)}
                                            >
                                                {item.values.map((itemOption) => (
                                                        itemOption !== '' ? (
                                                            <Select.Option key={itemOption} value={itemOption}>
                                                                <FormattedMessage
                                                                    id={'containers.Maintenance.MaintenancePlus.' + itemOption} />
                                                            </Select.Option>
                                                        ) : (
                                                            <Select.Option key={''} value={''}>

                                                            </Select.Option>
                                                        )

                                                    ),
                                                )}
                                            </MaintenanceStyle.Select>
                                        </MaintenanceStyle.MaintenanceInput>
                                    </div>

                                );
                            case 'textArea':
                                return (
                                    <div key={item.type}>
                                        <MaintenanceStyle.MaintenanceInput>
                                            {label}
                                            <TextArea
                                                className={'textAreaInput'}
                                                rows={3}
                                                onChange={(event) => handleSetMaintenance(event.target.value, item)}
                                            />
                                        </MaintenanceStyle.MaintenanceInput>
                                    </div>

                                );
                        }
                    })}
                </div>
            </Modal>
        </div>
    );
};

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector({});

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ insertMaintenanceInformation }, dispatch);

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(ParticularMaintenance));
