// @flow

import request from 'services/request';
import requestFAPI from 'services/requestFAPI';
import adminRequest from 'services/adminRequest';
import type { Coordinates } from 'types';
import { MAPBOX_GL_TOKEN } from 'env';

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveMaintenanceVehicleHistoryById(
    token: string,
    locale: string,
    id: number,
): Promise<> {
    const url = `maintenance-vehicle/${id}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveMaintenanceByBranchId(
    token: string,
    locale: string,
    id: number
): Promise<> {
    const url = `maintenance/${id}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveMaintenanceVehicleByMaintId(
    token: string,
    locale: string,
    id: number
): Promise<> {
    const url = `maintenance-vehicle-group/${id}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function retrieveMaintenanceEmail(token: string, locale: string, id: number): Promise<> {
    const url = `maintenance-emails/${id}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function updateMaintenance(
    token: string,
    locale: string,
    id: number,
    data: object
): Promise<> {
    const url = `maintenance/${id}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data,
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function updateMaintenanceVehicle(
    token: string,
    locale: string,
    id: number,
    data: object
): Promise<> {
    const url = `maintenance-vehicle/${id}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data,
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {number} id
 */
export function addMaintenanceVehicleInformation(
    token: string,
    id: number,
    data: object
): Promise<> {
    const url = `maintenance-vehicle-information/${id}`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function addMaintenance(token: string, locale: string, id: number, data: object): Promise<> {
    const url = `maintenance/${id}`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {string} data
 */
export function addMaintenanceVehicle(
    token: string,
    locale: string,
    data: string
): Promise<> {
    const url = `maintenance-vehicle/`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token,
    );
}


/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {object} data
 * @param {number} branchId
 * @param {number} maintId
 */
export function addDeleteMaintenaceVehicle(
    token: string,
    locale: string,
    branchId: number,
    maintId: number,
    data: object,
): Promise<> {
    const url = `maintenances/${branchId}/maintenance-vehicles/${maintId}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data,
        },
        true,
        token,
    );
}


/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {object} data
 * @param {number} branchId
 * @param {number} maintId
 */
export function addDeleteMaintenaceEmails(
    token: string,
    locale: string,
    branchId: number,
    maintId: number,
    data: object,
): Promise<> {
    const url = `maintenances/${branchId}/maintenance-emails/${maintId}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data,
        },
        true,
        token,
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {string} data
 */
export function addMaintenanceEmail(
    token: string,
    locale: string,
    data: string
): Promise<> {
    const url = `maintenance-emails/`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function deleteMaintenance(token: string, locale: string, id: number): Promise<> {
    const url = `maintenance/${id}`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {string} data
 */
export function deleteMaintenanceVehicle(
    token: string,
    locale: string,
    data: string
): Promise<> {
    const url = `maintenance-vehicle/`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
            data,
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {number} id
 */
export function deleteAllMaintenanceVehicle(token: string, id: number): Promise<> {
    const url = `maintenance-vehicle-group/${id}`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
        },
        true,
        token
    );
}

/**
 *
 * @param {string} token
 * @param {string} locale
 * @param {string} data
 */
export function deleteMaintenanceEmail(
    token: string,
    locale: string,
    data: string
): Promise<> {
    const url = `maintenance-emails/`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
            data,
        },
        true,
        token
    );
}

/**
 * get vehicle maintenance message by id.
 *
 * @param {string} token
 * @param {number} id
 * @param {*} data
 */
export function getMaintMsgById(token: string, id: number): Promise<> {
    const url = `maintenance-msg/${id}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Edit vehicle maintenance message by id.
 *
 * @param {string} token
 * @param {number} id
 * @param {*} data
 */
export function editMaintMsgById(token: string, id: number, data: *): Promise<> {
    const url = `maintenance-msg/${id}/`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data: {
                ...data,
            },
        },
        false,
        token,
    );
}

export function retrieveAllMaintenances(
    token: string,
    locale: string,
    branchId: number,
): Promise<> {
    const url = `maintenances/${branchId}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token,
    );
}
