// @flow

import { push } from 'react-router-redux';

import {
    receivedDivisionDataSuccess,
    receivedEditDivisionSuccess,
    receivedDeleteDivisionSuccess,
    receivedDeleteDivisionEquipment,
} from './actions';

import { receivedCreateBranchDivisionSuccess } from 'services/Branch/actions';

import {
    retreiveDivision,
    retreiveDivisionEquipment,
    retreiveDivisionUsers,
    retreiveDivisionOperators,
    createDivisionRequest,
    editDivisionRequest,
} from 'services/Division/resources';

import { deleteOrganizationRequest } from 'services/Organization/resources';

import type {
    ReduxDispatch,
    ResponseErrorType,
    ImmutableMap,
    UserDataType,
    Organization,
} from 'types';

export const getDivisionData = (organizationId: number, branchId: number, divisionId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return Promise.all([
        retreiveDivision(organizationId, branchId, divisionId, token, locale),
        retreiveDivisionEquipment(organizationId, branchId, divisionId, token, locale),
        retreiveDivisionUsers(organizationId, branchId, divisionId, token, locale),
        retreiveDivisionOperators(organizationId, branchId, divisionId, token, locale),
    ])
        .then((responses) => {
            const division = {
                division: {
                    organizationId,
                    ...responses[0].data.data,
                },
                equipment: responses[1].data.data,
                users: responses[2].data.data,
                operators: responses[3].data.data,
            };

            dispatch(receivedDivisionDataSuccess(division));
        })
        .catch((error) => console.log('error', error));
};

export const createDivision = (data: Organization, organizationId: number, branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    const requestData = {
        parentId: branchId,
        ...data,
    };

    return createDivisionRequest(requestData, organizationId, branchId, token, locale).then(
        (response) => {
            return dispatch(receivedCreateBranchDivisionSuccess(response.data));
        }
    );
};

export const editDivision = (
    data: Organization,
    organizationId: number,
    branchId: number,
    divisionId: number
) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return editDivisionRequest(organizationId, branchId, divisionId, data, token, locale).then(
        (response) => dispatch(receivedEditDivisionSuccess(response.data))
    );
};

export const deleteDivision = (divisionId: number, branchId: number, organizationId, number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    const url = `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}`;
    return deleteOrganizationRequest(url, token).then((response) =>
        dispatch(receivedDeleteDivisionSuccess())
    );
};

export const deleteDivisionEquipment = (equipmentId: number) => (dispatch: ReduxDispatch) => {
    return dispatch(receivedDeleteDivisionEquipment(equipmentId));
};
