// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const SearchInput = styled.div`
    display: flex;
    background-color: ${colors.grayF8};
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => padding || '0 12px 0 17px'};
    border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};
    position: relative;
    padding-right: 36px;

    svg {
        display: block;
        position: absolute;
        height: 18px;
        right: 14px;
        top: 0;
        bottom: 0;
        margin: auto 0;

        path {
            fill: ${colors.grayDD};
        }
    }
`;

SearchInput.Icon = styled.img`
    width: ${(props) => props.width || '22px'};
    height: ${(props) => props.height || '22px'};
`;

const Input = styled.input`

    height: 100%;
    width: 100%;
    ${(props) =>
        props.fleetOverviewInput &&
        `
        height: 30px !important;
        width: 85% !important;
    `};
    border: none;
    background: none;
    &:focus {
        outline: none;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: OpenSans;
    ${(props) =>
        props.bold &&
        `
        font-weight: 400;
        font-family: OpenSans-SemiBold;
    `};
`;

export { SearchInput, Input };
