/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/ */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import { emphasize, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minWidth: 290,
    },
    select: {
        margin: '10px 0',
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'scroll',
        backgroundColor: theme.palette.background,
        maxHeight: 140,
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
        width: 140,
        height: 15,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.default,
    },
    divider: {
        height: theme.spacing(2),
    },
    options: {
        fontSize: 12,
    },
}));

export default useStyles;
