/* eslint-disable flowtype-errors/show-errors */
/* eslint-disable react/no-unused-state */
// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { List, fromJS } from 'immutable';

import 'react-slidedown/lib/slidedown.css';

import Loader from 'components/Loader';

import SearchInput from 'components/SearchInput';
import PasswordValidator from 'password-validator';

import 'antd/dist/antd.css';
import Select from 'antd/es/select';
import Table from 'antd/es/table';
import Tag from 'antd/es/tag';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Input from 'antd/es/input';

import UserDeleteOutlined from '@ant-design/icons/UserDeleteOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import UnlockOutlined from '@ant-design/icons/UnlockOutlined';
import UserAddOutlined from '@ant-design/icons/UserAddOutlined';

const { confirm } = Modal;

const { TextArea } = Input;

import { Container } from 'react-bootstrap';
import { Button as BootstrapButton } from 'react-bootstrap';
import {
    selectUsersState,
    selectUsers,
    selectNextLink,
    selectUsersLoading,
    selectUserLoading,
    selectShowError,
    selectRoles,
    selectCreatedUserId,
    selectInputLocked,
} from 'services/Admin/selectors';
import { selectLocale } from 'services/Language/selectors';
import { selectUnit } from 'services/Units/selectors';
import { selectUser } from 'services/Authentication/selectors';
import {
    getUsers,
    getNextUsersPage,
    updatePassword,
    deleteUser,
    updateUser,
    sendVerificationMail,
    getCurrentUserRoles,
    updateRoles,
    getUser,
    createUser,
    unlockInput,
} from 'services/Admin/thunks';
import { fetchUserGroups } from 'services/User/thunks';
import { parseJWT } from 'helpers';
import Fuse from 'fuse.js';
import UsersManagement from './styles';

type Props = {
    users: *,
    getUsers: *,
    nextLink: *,
    getNextUsersPage: *,
    updatePassword: *,
    deleteUser: *,
    updateUser: *,
    sendVerificationMail: *,
    getCurrentUserRoles: *,
    updateRoles: *,
    createUser: *,
    getUser: *,
    roles: *,
};

type State = {
    users: List,
    UsersSearchQuery: string,
    UsersSearchResults: List,
    visible: boolean,
    currentUserId: integer,
    currentUser: *,
    emailVisible: boolean,
    rolesVisible: boolean,
    roles: *,
    selectedRoles: Array,
    createUserVisible: boolean,
    newUserRoles: Array,
    schema: *,
    searchResults: List,
    found: boolean,
};
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
export class usersPageComponent extends React.Component<Props, State> {
    adminComponent: React.Component<Props, State>;

    fuseOptions: Object;

    constructor(props) {
        super(props);

        this.fuseOptions = {
            threshold: 0.2,
            tokenize: true,
            matchAllTokens: true,
            keys: ['name', 'roles', 'email'],
        };
    }

    state = {
        UsersSearchQuery: '',
        UsersSearchResults: fromJS([]),
        visible: false,
        loading: false,
        currentUserId: null,
        currentUser: null,
        emailVisible: false,
        rolesVisible: false,
        selectedRoles: [],
        createUserVisible: false,
        newUserRoles: [],
        schema: null,
        detailsVisible: false,
        searchResults: fromJS([]),
        found: false,
        groupsList: null,
        groups: [],
        selectedGroups: null,
        defaultGroup: null,
        didUpdate: '',
        saveButtonDisabled: false,
        isCreatingUser: false,
    };

    componentDidMount(props: Props) {
        this.props.getUsers();
        this.props.getCurrentUserRoles();
        this.props.fetchUserGroups().then((groups) => {
            const defaultGroup = groups.find((group) => {
                return group.isDefault;
            });
            this.setState({
                groupsList: groups,
                defaultGroup,
                selectedGroups: this.state.selectedGroups || [defaultGroup.name],
            });
        });
    }

    componentDidUpdate(props: Props) {
        if (
            !this.state.detailsUpdate &&
            this.state.detailsVisible &&
            this.detailsUserFormRef.current
        ) {
            this.detailsUserFormRef.current.setFieldsValue({
                firstName: this.state.currentUser.firstName,
                lastName: this.state.currentUser.lastName,
                email: this.state.currentUser.email,
                comments: this.state.currentUser.comments,
            });
        }
    }

    handleEmailSubmit = () => {
        const values = this.emailFormRef.current.getFieldValue();
        if (
            values.email !== '' &&
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(values.email)
        ) {
            const record = this.state.currentUser;
            record.email = values.email;
            this.props.updateUser(record).then(() => {
                if (this.props.showError) {
                    message.error(
                        this.props.intl.formatMessage({
                            id: 'components.Modals.Form.Admin.user.failed',
                        })
                    );
                } else {
                    this.handleEmailCancel();
                    this.updateUsersList();
                }
            });
        }
    };

    handlePasswordSubmit = () => {
        const values = this.passwordFormRef.current.getFieldValue();
        const schema = this.createSchema();
        if (!this.state.currentUser.isEmailVerified) {
            message.warning('email not verified!');
            this.handlePasswordCancel();
            return;
        }
        if (values.confirm === values.password && schema.validate(values.password)) {
            this.props
                .updatePassword({
                    userId: this.state.currentUserId,
                    password: values.password,
                })
                .then(() => {
                    if (this.props.passwordUpdateResponse) {
                        message.error(
                            this.props.intl.formatMessage({
                                id: 'components.Modals.Form.Admin.user.failed',
                            })
                        );
                    } else {
                        this.handlePasswordCancel();
                    }
                })
                .catch((error: Object) => {
                    console.log(error);
                });
        }
    };

    handlePasswordCancel = () => {
        if (this.passwordFormRef.current) {
            this.passwordFormRef.current.resetFields();
        }
        this.setState({ visible: false, currentUserId: null, currentUser: null });
    };

    passwordFormRef = React.createRef();

    passwordForm = () => {
        const { visible, loading } = this.state;
        const schema = this.createSchema();

        const passwordMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password', // 'Please enter your password!'
        });
        const passwordMin = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.min', // 'Minimum length 8, '
        });
        const passwordMax = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.max', // 'Maximum length 15, ';
        });
        const passwordLower = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.lower', //  'Must have lower letters, ';
        });
        const passwordSpaces = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.spaces', // 'Should not have spaces, ';
        });
        const passwordUpper = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.upper', // 'Must have uppercase letters, ';
        });
        const passwordDigits = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.digits', // 'Must have digits, ';
        });
        const passwordConfirm = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.confirm', // ' Please confirm your password!';
        });
        const passwordNotMatch = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.password.notmatch', // 'The two passwords that you entered do not match!';
        });

        const submit = this.props.intl.formatMessage({
            id: 'components.Modals.Save',
        });

        const cancel = this.props.intl.formatMessage({
            id: 'components.Modals.Cancel',
        });

        const confirmPasswordLabel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Password.ConfirmPassword',
        });

        const passwordLabel = this.props.intl.formatMessage({
            id: 'components.Login.Password',
        });

        return (
            <div>
                <Modal
                    open={visible}
                    title="Password"
                    onOk={this.handlePasswordSubmit}
                    onCancel={this.handlePasswordCancel}
                    footer={[
                        <BootstrapButton
                            key="back"
                            onClick={this.handlePasswordCancel}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                            }}
                        >
                            {cancel}
                        </BootstrapButton>,
                        <BootstrapButton
                            key="submit"
                            type="primary"
                            loading={this.props.userloading}
                            onClick={this.handlePasswordSubmit}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                            }}
                        >
                            {submit}
                        </BootstrapButton>,
                    ]}
                >
                    <Form {...layout} ref={this.passwordFormRef} name="control-ref">
                        <Form.Item
                            name="password"
                            label={passwordLabel}
                            rules={[
                                {
                                    required: true,
                                    message: passwordMessage,
                                },
                                ({ getFieldValue }) => ({
                                    validator(regle, value) {
                                        const validation = schema.validate(value, {
                                            list: true,
                                        });
                                        if (validation.length > 0) {
                                            let msg = '';
                                            validation.forEach((regle) => {
                                                switch (regle) {
                                                    case 'min':
                                                        msg += passwordMin;
                                                        break;
                                                    case 'max':
                                                        msg += passwordMax;

                                                        break;
                                                    case 'uppercase':
                                                        msg += passwordUpper;

                                                        break;
                                                    case 'lowercase':
                                                        msg += passwordLower;

                                                        break;
                                                    case 'digits':
                                                        msg += passwordDigits;

                                                        break;
                                                    case 'spaces':
                                                        msg += passwordSpaces;
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            });
                                            return Promise.reject(msg);
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label={confirmPasswordLabel}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: passwordConfirm,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(passwordNotMatch);
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    };

    emailFormRef = React.createRef();

    handleEmailCancel = () => {
        this.setState({ emailVisible: false, currentUser: null, currentUserId: null });
        this.emailFormRef.current.resetFields();
    };

    emailForm = () => {
        const { emailVisible, loading } = this.state;

        const emailValid = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.email.valid', // 'Enter a valid email!';
        });

        const submit = this.props.intl.formatMessage({
            id: 'components.Modals.Save',
        });

        const cancel = this.props.intl.formatMessage({
            id: 'components.Modals.Cancel',
        });

        const courriel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.User.Email',
        });

        return (
            <div>
                <Modal
                    open={emailVisible}
                    title="Email"
                    onOk={this.handleEmailSubmit}
                    onCancel={this.handleEmailCancel}
                    footer={[
                        <BootstrapButton
                            key="back"
                            onClick={this.handleEmailCancel}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                            }}
                        >
                            {cancel}
                        </BootstrapButton>,
                        <BootstrapButton
                            key="submit"
                            type="primary"
                            loading={this.props.userloading}
                            onClick={this.handleEmailSubmit}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                            }}
                        >
                            {submit}
                        </BootstrapButton>,
                    ]}
                >
                    <Form {...layout} ref={this.emailFormRef} name="control-ref">
                        <Form.Item
                            name="email"
                            label={courriel}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input an email!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
                                                value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(emailValid);
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    };

    handleRolesCancel = () => {
        this.setState({
            currentUserId: null,
            currentUser: null,
            rolesVisible: false,
            selectedRoles: [],
        });
    };

    handleRolesSubmit = () => {
        let toAdd = this.inFirstOnly(
            this.state.selectedRoles,
            this.state.currentUser.roles.toArray()
        );
        let toDelete = this.inSecondOnly(
            this.state.selectedRoles,
            this.state.currentUser.roles.toArray()
        );
        if (toAdd.length > 0) {
            toAdd = toAdd.map((role) => {
                return this.mapToRoleId(role);
            });
            this.props
                .updateRoles(this.state.currentUserId, toAdd, 'POST')
                .then(() => {
                    if (this.props.showError) {
                        message.error('failed');
                    } else {
                        if (toDelete.length === 0) {
                            this.handleRolesCancel();
                            this.updateUsersList();
                        }
                    }
                })
                .catch((error: Object) => {
                    console.log(error);
                });
        }
        if (toDelete.length > 0) {
            toDelete = toDelete.map((role) => {
                return this.mapToRoleId(role);
            });
            this.props
                .updateRoles(this.state.currentUser, toDelete, 'DELETE')
                .then(() => {
                    if (this.props.showError) {
                        message.error('failed');
                    } else {
                        this.handleRolesCancel();
                        this.updateUsersList();
                    }
                })
                .catch((error: Object) => {
                    console.log(error);
                });
        }
        //
    };

    userFormRef = React.createRef();

    handleCreateUserSubmit = async () => {
        if (this.state.isCreatingUser) return;
        this.setState({saveButtonDisabled: true, isCreatingUser: true});
        try {
            const values = this.userFormRef.current.getFieldValue();
            const roles = this.state.selectedRoles;
            const groups = this.handleGroupsSubmit();
            const user = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                comments: values.comments,
                isBranchOwner: false,
                groups,
                lang: this.props.locale,
                sendWelcomeEmail: true,
                units: this.props.unit,
            };
    
            const userCreationFailed = this.props.intl.formatMessage({
                id: 'components.Modals.Form.Admin.user.creationFailed',
            });
            const roleAssigningFailed = this.props.intl.formatMessage({
                id: 'components.Modals.Form.Admin.user.failed',
            });
    
            this.handleCreateUserCancel();
    
            const response = await this.props.createUser(user);
    
            if (this.props.showError) {
                message.error(userCreationFailed);
            } else {
                const userWithGroup = {
                    comments: values.comments,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    groups,
                    id: response.userId,
                    isBranchOwner: false,
                    lang: this.props.locale,
                    units: this.props.unit,
                };
    
                await new Promise((resolve) => setTimeout(resolve, 5000));
    
                await this.props.updateUser(userWithGroup);
    
                if (roles.length > 0) {
                    const toAdd = roles.map((role) => this.mapToRoleId(role));
    
                    await this.props.updateRoles(this.props.createdUserId, toAdd, 'POST');
    
                    if (this.props.showError) {
                        message.error(roleAssigningFailed);
                    }
                }
    
                this.updateUsersList();
                this.handleCreateUserCancel();
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({saveButtonDisabled: false, isCreatingUser: false});
        }
    };

    handleCreateUserCancel = () => {
        this.setState({
            createUserVisible: false,
            newUserRoles: [],
            selectedGroups: [this.state.defaultGroup.name],
            saveButtonDisabled: false,
        });
        this.userFormRef.current.resetFields();
    };

    handleCreateUserForm = () => {
        this.setState({ createUserVisible: true });
    };

    createSchema = () => {
        const schema = new PasswordValidator();
        schema
            .is()
            .min(8) // Minimum length 8
            .is()
            .max(15) // Maximum length 15
            .has()
            .uppercase() // Must have uppercase letters
            .has()
            .lowercase() // Must have lowercase letters
            .has()
            .digits() // Must have digits
            .has()
            .not()
            .spaces(); // Should not have spaces
        return schema;
    };

    createUserForm = () => {
        const { createUserVisible } = this.state;
        const schema = this.createSchema();
        const userNameMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.username', // 'Please input the user first name!'
        });
        const lastNameMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.lastname', // 'Please input the user last name!'
        });
        const emailMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.email', // Please input an email!'
        });
        const emailMessageAlert = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.emailAlert', // 'Enter a valid email!'
        });
        const selectMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.select', // 'Please select'
        });
        const groupsSelectMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.groupSelect', // 'Please select'
        });
        const submit = this.props.intl.formatMessage({
            id: 'components.Modals.Save',
        });

        const cancel = this.props.intl.formatMessage({
            id: 'components.Modals.Cancel',
        });

        const prenom = this.props.intl.formatMessage({
            id: 'components.Modals.Form.User.FirstName',
        });
        const nom = this.props.intl.formatMessage({
            id: 'components.Modals.Form.User.LastName',
        });

        const courriel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.User.Email',
        });

        const commentaire = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Organization.Comments',
        });

        const rolesLabel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.roles',
        });
        const groupsLabel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.groups',
        });
        return (
            <div>
                <Modal
                    open={createUserVisible}
                    title={this.props.intl.formatMessage({
                        id: 'components.Modals.Create.User',
                    })}
                    onOk={this.handleCreateUserSubmit}
                    onCancel={this.handleCreateUserCancel}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.handleCreateUserCancel}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                                color: 'white',
                                fontSize: '15px',
                            }}
                        >
                            {cancel}
                        </Button>,
                        <Button
                            disabled={this.state.saveButtonDisabled}
                            key="submit"
                            type="primary"
                            onClick={this.handleCreateUserSubmit}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                                color: 'white',
                                fontSize: '15px',
                            }}
                        >
                            {submit}
                        </Button>,
                    ]}
                >
                    <Form {...layout} ref={this.userFormRef} name="control-ref">
                        <Form.Item
                            label={prenom}
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: userNameMessage,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={nom}
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: lastNameMessage,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label={courriel}
                            rules={[
                                {
                                    required: true,
                                    message: emailMessage,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
                                                value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(emailMessageAlert);
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label={rolesLabel}>
                            <Select
                                mode="multiple"
                                size={10}
                                placeholder={selectMessage}
                                value={this.state.selectedRoles}
                                onChange={this.handleRoleChange}
                                onDeselect={this.handleRoleDeselect}
                                onSelect={this.handleRoleSelect}
                                style={{ width: '100%' }}
                            >
                                {this.props.roles &&
                                    this.props.roles.map((role) => {
                                        const jwt = parseJWT(this.props.user.get('token'));
                                        if (
                                            jwt.permissions &&
                                            Array.isArray(jwt.permissions) &&
                                            jwt.permissions.indexOf(`assign:${role.name}`) !== -1
                                        ) {
                                            return (
                                                <Select.Option key={role.name}>
                                                    {role.name}
                                                </Select.Option>
                                            );
                                        }
                                        return [];
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={groupsLabel}>
                            <Select
                                mode="multiple"
                                size={10}
                                placeholder={groupsSelectMessage}
                                value={this.state.selectedGroups}
                                onChange={this.handleGroupChange}
                                onDeselect={this.handleGroupDeselect}
                                onSelect={this.handleGroupSelect}
                                style={{ width: '100%' }}
                            >
                                {this.state.groupsList &&
                                    this.state.groupsList.map((group) => {
                                        return (
                                            <Select.Option key={group.name}>
                                                {group.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={commentaire} name="comments">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    };

    rolesForm = () => {
        const { rolesVisible } = this.state;

        const rolesMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.roles', // 'Roles ';
        });

        const selectMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.select', // 'Please select'
        });

        const submit = this.props.intl.formatMessage({
            id: 'components.Modals.Save',
        });

        const cancel = this.props.intl.formatMessage({
            id: 'components.Modals.Cancel',
        });
        return (
            <div>
                <Modal
                    open={rolesVisible}
                    title={rolesMessage}
                    onOk={this.handleRolesSubmit}
                    onCancel={this.handleRolesCancel}
                    footer={[
                        <BootstrapButton
                            key="back"
                            onClick={this.handleRolesCancel}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                            }}
                        >
                            {cancel}
                        </BootstrapButton>,
                        <BootstrapButton
                            key="submit"
                            type="primary"
                            loading={this.props.userloading}
                            onClick={this.handleRolesSubmit}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                            }}
                        >
                            {submit}
                        </BootstrapButton>,
                    ]}
                >
                    <Select
                        mode="multiple"
                        size={10}
                        placeholder={selectMessage}
                        value={this.state.selectedRoles}
                        onChange={this.handleRoleChange}
                        onDeselect={this.handleRoleDeselect}
                        onSelect={this.handleRoleSelect}
                        style={{ width: '100%' }}
                    >
                        {this.props.roles &&
                            this.props.roles.map((role) => {
                                const jwt = parseJWT(this.props.user.get('token'));
                                if (
                                    jwt.permissions &&
                                    Array.isArray(jwt.permissions) &&
                                    jwt.permissions.indexOf(`assign:${role.name}`) !== -1
                                ) {
                                    return (
                                        <Select.Option key={role.name}>{role.name}</Select.Option>
                                    );
                                }
                                return [];
                            })}
                    </Select>
                </Modal>
            </div>
        );
    };

    handleGroupSelect = (value) => {
        this.setState((prevState) => ({
            selectedGroups: [...prevState.selectedGroups, value],
        }));
    };

    handleGroupDeselect = (value) => {
        const array = [...this.state.selectedGroups]; // make a separate copy of the array
        const index = array.indexOf(value);
        array.splice(index, 1);
        this.setState({ selectedGroups: array });
    };

    handleGroupsSubmit = () => {
        const { groupsList, selectedGroups, defaultGroup } = this.state;
        let groups;
        if (defaultGroup) {
            groups = [defaultGroup.groupId];
        } else {
            groups = [groupsList[0].groupId];
        }

        if (selectedGroups.length > 0) {
            groups = selectedGroups.map((name) => {
                const group = groupsList.find((element) => element.name === name);
                return group.groupId;
            });
        }
        return groups;
    };

    handleRolesChange = (value) => {
        this.setState({ selectedRoles: value });
        console.log(`Selected: ${value}`);
    };

    handleRoleSelect = (value) => {
        this.setState((prevState) => ({
            selectedRoles: [...prevState.selectedRoles, value],
        }));
        console.log(`Selected: ${value}`);
    };

    handleRoleDeselect = (value) => {
        const array = [...this.state.selectedRoles]; // make a separate copy of the array
        const index = array.indexOf(value);
        const jwt = parseJWT(this.props.user.get('token'));
        if (
            jwt.permissions &&
            Array.isArray(jwt.permissions) &&
            jwt.permissions.indexOf(`assign:${value}`) !== -1 &&
            index !== -1
        ) {
            array.splice(index, 1);
            this.setState({ selectedRoles: array });
            console.log(`deSelected: ${value}`);
        } else {
            message.error(
                this.props.intl.formatMessage({
                    id: 'components.Modals.Form.Admin.unAuthorized', // 'unauthorized! ';
                })
            );
        }
    };

    showDeleteConfirm = (record) => {
        const confirmDelete = this.props.intl.formatMessage({
            id: 'components.Confirmation.DeleteUser.Message',
        });
        const yes = this.props.intl.formatMessage({
            id: 'components.Confirmation.DeleteUser.Confirm',
        });
        const cancel = this.props.intl.formatMessage({
            id: 'components.Confirmation.DeleteUser.Cancel',
        });
        confirm({
            title: confirmDelete,
            content: '',
            okText: yes,
            okType: 'danger',
            cancelText: cancel,
            onOk: () => {
                this.props
                    .deleteUser(record)
                    .then(() => {
                        if (this.props.showError) {
                            message.error('failed');
                        } else {
                            this.updateUsersList();
                        }
                    })
                    .catch((error: Object) => {
                        console.log(error);
                    });
            },
            onCancel() {
                console.log(record);
            },
        });
    };

    showBlockConfirm = (record) => {
        const confirmBlock1 = this.props.intl.formatMessage({
            id: 'components.Confirmation.BlockUser.par1',
        });

        const confirmBlock2 = this.props.intl.formatMessage({
            id: 'components.Confirmation.BlockUser.par2',
        });

        const block = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.block',
        });

        const unblock = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.unblock',
        });
        const confirmationMessage = `${confirmBlock1}  ${
            record.isBlocked ? unblock : block
        }  ${confirmBlock2}`;
        const yes = this.props.intl.formatMessage({
            id: 'components.Modals.Save',
        });
        const cancel = this.props.intl.formatMessage({
            id: 'components.Modals.Cancel',
        });
        confirm({
            title: confirmationMessage,
            content: '',
            okText: yes,
            okType: 'danger',
            cancelText: cancel,
            onOk: () => {
                record.isBlocked = !record.isBlocked;
                this.props.updateUser(record).then(() => {
                    if (this.props.showError) {
                        message.error(
                            this.props.intl.formatMessage({
                                id: 'components.Modals.Form.Admin.user.failed',
                            })
                        );
                    } else {
                        this.updateUsersList();
                    }
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    handleConvertResult = (results) => {
        let list = [];
        results.forEach((r) => {
            list.push(r.item);
        });
        return list;
    };

    handleSearchResults = (searchResults: SearchResultsType) => {
        if (searchResults.query !== '') {
            this.setState({
                UsersSearchQuery: searchResults.query,
            });
        } else {
            this.setState({ UsersSearchResults: null, found: false });
            return;
        }
        let query = searchResults.query ? searchResults.query : this.state.UsersSearchQuery;

        const results = fromJS(this.handleConvertResult(searchResults.fuse.search(query)));
        const res = results.map((user) => {
            return {
                name: user.get('name'),
                id: user.get('id'),
                key: user.get('key'),
                email: user.get('email'),
                emailVerified: user.get('emailVerified'),
                emailVerifiedText: `${user.get('email')}_${user.get('emailVerified')}`,
                roles: user.get('roles'),
                firstName: user.get('firstName'),
                lastName: user.get('lastName'),
                comments: user.get('comments'),
                isBlocked: user.get('isBlocked'),
                isBranchOwner: user.get('isBranchOwner'),
                groups: user.get('groups'),
                lastLogin: user.get('lastLogin') ? user.get('lastLogin') : '',
            };
        });
        if (res.size > 0) {
            this.setState({
                UsersSearchResults: res,
                searchResults,
                found: true,
            });
            this.props.unlockInput();
        } else if (query && query !== '') {
            this.setState({ UsersSearchResults: null, found: false });
            this.props.getNextUsersPage(this.props.nextLink, true).then(() => {
                const rs = this.state.searchResults;
                rs.fuse = new Fuse(this.props.users.toJS(), this.fuseOptions);
                rs.query = query;
                this.setState({
                    searchResults: rs,
                });
                this.handleSearchResults(rs);
            });
        }
    };

    handleGetNextUsersPage = () => {
        this.props.getNextUsersPage(this.props.nextLink, false);
    };

    sendVerificationMail = (record) => {
        this.props.sendVerificationMail(record).then(() => {
            if (this.props.showError) {
                message.error(
                    this.props.intl.formatMessage({
                        id: 'components.Modals.Form.Admin.user.failed', // 'failed';
                    })
                );
            } else {
                message.success(
                    this.props.intl.formatMessage({
                        id: 'components.Modals.Form.Admin.user.success', // 'success ';
                    })
                );
            }
        });
    };

    mapToRoleId = (role) => {
        for (let i = 0; i < this.props.roles.length; i++) {
            if (role === this.props.roles[i].name) {
                return this.props.roles[i].id;
            }
        }
    };

    compareArrays = (list1, list2, operationIsUnion) => {
        const result = [];

        for (let i = 0; i < list1.length; i++) {
            const item1 = list1[i];
            let found = false;
            for (let j = 0; j < list2.length; j++) {
                if (item1 === list2[j]) {
                    found = true;
                    break;
                }
            }
            if (found === operationIsUnion) {
                result.push(item1);
            }
        }
        return result;
    };

    inFirstOnly = (list1, list2) => {
        return this.compareArrays(list1, list2, false);
    };

    inSecondOnly = (list1, list2) => {
        return this.inFirstOnly(list2, list1);
    };

    inBoth = (list1, list2) => {
        return this.compareArrays(list1, list2, true);
    };

    menu = (record) => {
        const block = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.block',
        });

        const unBlock = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.unblock',
        });

        const assignRoles = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.roleAssign',
        });

        const sendMail = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.sendVerificationMail',
        });

        const changeEmail = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.changeEmail',
        });

        const changePassword = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.ChangePassword',
        });

        const deleteuser = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.Delete',
        });

        return (
            <Menu>
                {!record.emailVerified && (
                    <Menu.Item key="1" onClick={() => this.sendVerificationMail(record)}>
                        <MailOutlined />
                        {sendMail}
                    </Menu.Item>
                )}
                <Menu.Item key="2" onClick={() => this.showBlockConfirm(record)}>
                    {record.isBlocked ? <UnlockOutlined /> : <LockOutlined />}
                    {record.isBlocked ? unBlock : block}
                </Menu.Item>
                <Menu.Item key="3" onClick={() => this.showDeleteConfirm(record)}>
                    <UserDeleteOutlined />
                    {deleteuser}
                </Menu.Item>
            </Menu>
        );
    };

    comparetableaux = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = arr1.length; i--; ) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    };

    updateUsersList = () => {
        this.props.getUsers().then(() => {
            // temp
            if (this.state.searchResults.query && this.state.searchResults.query.length > 0) {
                this.setState({ didUpdate: this.state.searchResults.query });
                this.setState({ didUpdate: '' });
            }
        });
    };

    showDetails = (record) => {
        const roles = record.roles.toArray();
        let groups = [];
        record.groups.toArray().forEach((group) => {
            if (
                typeof group !== 'number' &&
                this.state.groupsList.find((g) => g.name === group.get('name'))
            ) {
                groups.push(group.get('name'));
            }
        });
        if (groups.length === 0) {
            groups = [];
        }
        this.setState({
            detailsVisible: true,
            currentUser: record,
            currentUserId: record.id,
            selectedRoles: roles,
            selectedGroups: groups,
        });
    };

    detailsUserFormRef = React.createRef();

    handleDetailsUserCancel = () => {
        this.detailsUserFormRef.current.resetFields();
        this.setState({
            currentUserId: null,
            currentUser: null,
            detailsVisible: false,
            selectedRoles: [],
            selectedGroups: [this.state.defaultGroup.name],
            detailsUpdate: false,
        });
    };

    handleDetailsUserSubmit = () => {
        this.setState({ detailsUpdate: true });
        const values = this.detailsUserFormRef.current.getFieldValue();
        const roles = this.state.selectedRoles;
        const groups = this.handleGroupsSubmit();
        const schema = this.createSchema();
        const user = {
            firstName: values.firstName ? values.firstName : this.state.currentUser.firstName,
            lastName: values.lastName ? values.lastName : this.state.currentUser.lastName,
            email: values.email ? values.email : this.state.currentUser.email,
            comments: values.comments ? values.comments : this.state.currentUser.comments,
            isBranchOwner: false,
            id: this.state.currentUser.id,
            groups,
            lang: this.props.locale,
            units: this.props.unit,
        };
        const union = this.inBoth(roles, this.state.currentUser.roles.toArray());

        let toAdd = this.inFirstOnly(
            this.state.selectedRoles,
            this.state.currentUser.roles.toArray()
        );
        let toDelete = this.inSecondOnly(
            this.state.selectedRoles,
            this.state.currentUser.roles.toArray()
        );
        if (
            user.firstName === this.state.currentUser.firstName &&
            user.lastName === this.state.currentUser.lastName &&
            user.email === this.state.currentUser.email &&
            user.comments === this.state.currentUser.comments &&
            toAdd.length === 0 &&
            toDelete.length === 0 &&
            groups === null
        ) {
            this.handleDetailsUserCancel();
            return;
        }
        const failed = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.failed',
        });

        this.props
            .updateUser(user)
            .then(() => {
                if (this.props.showError) {
                    message.error(failed);
                } else {
                    if (toAdd.length > 0) {
                        toAdd = toAdd.map((role) => {
                            return this.mapToRoleId(role);
                        });
                        this.props
                            .updateRoles(this.state.currentUserId, toAdd, 'POST')
                            .then(() => {
                                if (this.props.showError) {
                                    message.error(failed);
                                } else {
                                    if (toDelete.length === 0) {
                                        this.handleDetailsUserCancel();
                                        this.updateUsersList();
                                    }
                                }
                            })
                            .catch((error: Object) => {
                                console.log(error);
                            });
                    }
                    if (toDelete.length > 0) {
                        toDelete = toDelete.map((role) => {
                            return this.mapToRoleId(role);
                        });
                        this.props
                            .updateRoles(this.state.currentUserId, toDelete, 'DELETE')
                            .then(() => {
                                if (this.props.showError) {
                                    message.error(failed);
                                } else {
                                    this.handleDetailsUserCancel();
                                    this.updateUsersList();
                                }
                            })
                            .catch((error: Object) => {
                                console.log(error);
                            });
                    }
                    if (toAdd.length === 0 && toDelete.length === 0) {
                        this.handleDetailsUserCancel();
                        this.updateUsersList();
                    }
                }
            })
            .catch((error: Object) => {
                console.log(error);
            });
    };

    detailsUserForm = () => {
        const { detailsVisible } = this.state;
        const userNameMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.username', // 'Please input the user first name!'
        });
        const lastNameMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.lastname', // 'Please input the user last name!'
        });
        const emailMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.email', // Please input an email!'
        });
        const emailMessageAlert = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.emailAlert', // 'Enter a valid email!'
        });
        const selectMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.select', // 'Please select'
        });

        const submit = this.props.intl.formatMessage({
            id: 'components.Modals.Save',
        });

        const cancel = this.props.intl.formatMessage({
            id: 'components.Modals.Cancel',
        });
        const prenom = this.props.intl.formatMessage({
            id: 'components.Modals.Form.User.FirstName',
        });
        const nom = this.props.intl.formatMessage({
            id: 'components.Modals.Form.User.LastName',
        });

        const courriel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.User.Email',
        });

        const commentaire = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Organization.Comments',
        });

        const rolesLabel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.roles',
        });
        const groupsSelectMessage = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.user.groupSelect', // 'Please select'
        });
        const groupsLabel = this.props.intl.formatMessage({
            id: 'components.Modals.Form.Admin.groups',
        });
        return (
            <div>
                <Modal
                    open={detailsVisible}
                    title={this.props.intl.formatMessage({
                        id: 'components.Modals.Form.Admin.user.edit',
                    })}
                    onOk={this.handleDetailsUserSubmit}
                    onCancel={this.handleDetailsUserCancel}
                    footer={[
                        <Button
                            key="back"
                            onClick={this.handleDetailsUserCancel}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                                color: 'white',
                                fontSize: '15px',
                            }}
                        >
                            {cancel}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={this.handleDetailsUserSubmit}
                            style={{
                                backgroundColor: '#579730',
                                borderColor: '#579730',
                                color: 'white',
                                fontSize: '15px',
                            }}
                        >
                            {submit}
                        </Button>,
                    ]}
                >
                    <Form {...layout} ref={this.detailsUserFormRef} name="control-ref">
                        <Form.Item
                            label={prenom}
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: userNameMessage,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                defaultValue={
                                    this.state.currentUser && this.state.currentUser.firstName
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label={nom}
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: lastNameMessage,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                defaultValue={
                                    this.state.currentUser && this.state.currentUser.lastName
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label={courriel}
                            rules={[
                                {
                                    required: true,
                                    message: emailMessage,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
                                                value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(emailMessageAlert);
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input
                                defaultValue={
                                    this.state.currentUser && this.state.currentUser.email
                                }
                            />
                        </Form.Item>
                        <Form.Item label={rolesLabel}>
                            <Select
                                mode="multiple"
                                size={10}
                                placeholder={selectMessage}
                                value={this.state.selectedRoles}
                                onChange={this.handleRoleChange}
                                onDeselect={this.handleRoleDeselect}
                                onSelect={this.handleRoleSelect}
                                style={{ width: '100%' }}
                            >
                                {this.props.roles &&
                                    this.props.roles.map((role) => {
                                        const jwt = parseJWT(this.props.user.get('token'));
                                        if (
                                            jwt.permissions &&
                                            Array.isArray(jwt.permissions) &&
                                            jwt.permissions.indexOf(`assign:${role.name}`) !== -1
                                        ) {
                                            return (
                                                <Select.Option key={role.name}>
                                                    {role.name}
                                                </Select.Option>
                                            );
                                        }
                                        return [];
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={groupsLabel}>
                            <Select
                                mode="multiple"
                                size={10}
                                placeholder={groupsSelectMessage}
                                value={this.state.selectedGroups}
                                onChange={this.handleGroupChange}
                                onDeselect={this.handleGroupDeselect}
                                onSelect={this.handleGroupSelect}
                                style={{ width: '100%' }}
                            >
                                {this.state.groupsList &&
                                    this.state.groupsList.map((group) => {
                                        return (
                                            <Select.Option key={group.name}>
                                                {group.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={commentaire} name="comments">
                            <TextArea
                                rows={4}
                                defaultValue={
                                    this.state.currentUser && this.state.currentUser.comments
                                }
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    };

    columns = [
        {
            title: this.props.intl.formatMessage({
                id: 'components.Modals.Form.User.name', // Name
            }),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                if (!a || !a.name) {
                    return 1;
                }
                if (!b || !b.name) {
                    return -1;
                } else {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                }
            },
            sortDirections: ['descend', 'ascend'],
            render: (text) => <div>{text}</div>,
        },
        {
            title: this.props.intl.formatMessage({
                id: 'components.Modals.Form.User.Email',
            }),
            dataIndex: 'emailVerifiedText',
            key: 'emailVerifiedText',
            sorter: (a, b) => {
                if (!a || !a.email) {
                    return 1;
                }
                if (!b || !b.email) {
                    return -1;
                } else {
                    return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
                }
            },
            sortDirections: ['descend', 'ascend'],
            render: (emailVerified) =>
                emailVerified.split('_')[1] === 'false' ? (
                    <div>
                        <div>{emailVerified.split('_')[0]} </div>

                        <Tag color="red">{`🔒 ${this.props.intl.formatMessage({
                            id: 'components.Modals.Form.User.notVerified',
                        })}`}</Tag>
                    </div>
                ) : (
                    <div>
                        {' '}
                        <div>{emailVerified.split('_')[0]} </div>
                    </div>
                ),
        },
        {
            title: this.props.intl.formatMessage({
                id: 'components.DashboardData.Header.LastLogin',
            }), // 'last login'
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            sorter: (a, b) => {
                if (!a || !a.lastLogin) {
                    return 1;
                }
                if (!b || !b.lastLogin) {
                    return -1;
                } else {
                    return a.lastLogin.localeCompare(b.lastLogin);
                }
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: this.props.intl.formatMessage({
                id: 'components.Modals.Form.Admin.roles',
            }), // 'Roles';
            key: 'roles',
            dataIndex: 'roles',
            render: (roles) => (
                <span>
                    {roles.map((tag) => {
                        if (tag) {
                            const color = 'orange';
                            return (
                                <Tag
                                    style={{ whiteSpace: 'break-spaces', padding: '0px 2px' }}
                                    color={color}
                                    key={tag}
                                >
                                    {tag.toUpperCase()}
                                </Tag>
                            );
                        }
                    })}
                </span>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <div id="components-dropdown-demo-dropdown-button">
                    <Dropdown.Button
                        onClick={() => this.showDetails(record)}
                        overlay={() => this.menu(record)}
                    >
                        {this.props.intl.formatMessage({
                            id: 'components.DashboardData.Equipment.Edit',
                        })}
                    </Dropdown.Button>
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'isBlocked',
            key: 'isBlocked',

            sorter: (a, b) => (a.isBlocked === b.isBlocked ? 0 : a.isBlocked ? -1 : 1),
            sortDirections: ['descend', 'ascend'],
            render: (isBlocked) => (isBlocked ? <Tag color="red">{'🔒'}</Tag> : ''),
        },
    ];

    render() {
        let tableUsers = fromJS([]);
        if (
            this.state.UsersSearchQuery &&
            this.state.UsersSearchResults &&
            this.state.UsersSearchResults.size >= 0 &&
            this.state.didUpdate.length === 0
        ) {
            tableUsers = this.state.UsersSearchResults;
        } else {
            tableUsers = this.props.users ? this.props.users : tableUsers;
        }
        let sortedUsers = tableUsers;
        if (sortedUsers.size > 0) {
            sortedUsers = tableUsers.sort((a, b) => {
                if (!a || !a.firstName) {
                    return 1;
                }
                if (!b || !b.firstName) {
                    return -1;
                } else {
                    return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
                }
            });
        }

        return (
            <UsersManagement>
                <UsersManagement.TitleHeader>
                    <FormattedMessage id={'components.Admin.Form.GestionUsers'} />
                </UsersManagement.TitleHeader>

                <Container fluid>
                    <Loader loading={this.props.loading} />
                    <div style={{ width: '90%', display: 'inline-block' }}>
                        <SearchInput
                            onSearchResults={this.handleSearchResults}
                            didUpdate={this.state.didUpdate}
                            searchKeys={['name', 'roles', 'email']}
                            data={sortedUsers}
                            threshold={0.2}
                            locked={this.props.inputLocked}
                            found={this.state.found}
                        />
                    </div>
                    <Button
                        type="primary"
                        disabled={false}
                        icon={<UserAddOutlined />}
                        onClick={this.handleCreateUserForm}
                        style={{
                            backgroundColor: '#579730',
                            borderColor: '#579730',
                            width: '6%',
                            display: 'inline-block',
                            marginLeft: '22px',
                            height: '43px',
                        }}
                    />

                    <UsersManagement.Table>
                        <Table
                            className={'userTable'}
                            columns={this.columns}
                            dataSource={sortedUsers && sortedUsers.toArray()}
                            pagination={false}
                            size="small"
                            scroll={{ y: '65vh' }}
                        />
                    </UsersManagement.Table>

                    {this.passwordForm()}
                    {this.emailForm()}
                    {this.rolesForm()}
                    {this.createUserForm()}
                    {this.detailsUserForm()}
                </Container>
            </UsersManagement>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    usersData: selectUsersState(),
    users: selectUsers(),
    nextLink: selectNextLink(),
    loading: selectUsersLoading(),
    userloading: selectUserLoading(),
    showError: selectShowError(),
    roles: selectRoles(),
    user: selectUser(),
    createdUserId: selectCreatedUserId(),
    locale: selectLocale(),
    unit: selectUnit(),
    inputLocked: selectInputLocked(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getUsers,
            getNextUsersPage,
            updatePassword,
            deleteUser,
            updateUser,
            sendVerificationMail,
            getCurrentUserRoles,
            updateRoles,
            createUser,
            getUser,
            unlockInput,
            fetchUserGroups,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(usersPageComponent))
);
