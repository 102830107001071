/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype*/
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import { makeStyles } from '@material-ui/core/styles';

const borderedWindow = {
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1px',
    display: 'flex',
    flexDirection: 'column',
    height: '75vh',
    margin: '10px',
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        height: '100%',
        width: '80%',
        padding: '10vh 15vh',
    },
    groups: {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.primary.main,
        flex: 1,
        ...borderedWindow,
    },
    addItems: {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.primary.main,
        flex: 1,
        ...borderedWindow,
    },
    message: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        color: theme.palette.grey[600],
    },
    title: {
        fontSize: '1.75rem',
        padding: '20px 10px',
        textTransform: 'capitalize',
    },
    groupsControl: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    list: {
        height: '100%',
        boxShadow: 'inset 0 -3px 3px -3px #000000',
    },
}));

export default useStyles;
