// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Detail from './styles';
import colors from 'styles/colors';

import OutlineButton from 'components/OutlineButton';

import DummyImage from 'assets/dummy-logo.png';

type Props = {
    name: ?string,
    address: ?string,
    title: ?string,
    onClickEdit: ?() => void,
    onClickDelete: ?() => void,
    onClickDirectAccess: ?() => void,
};

function DetailHeader(props: Props) {
    return (
        <Detail>
            <Detail.Header>
                <Detail.Image src={DummyImage} width={'100px'} height={'100px'} />
                <Detail.TextContainer>
                    <Detail.Text fontSize={'16px'}>{props.title}</Detail.Text>
                    <Detail.Text fontSize={'32px'} fontWeight={400} marginTop={'15px'}>
                        {props.name}
                    </Detail.Text>
                    <Detail.Text fontSize={'16px'} marginTop={'5px'}>
                        {props.address}
                    </Detail.Text>
                </Detail.TextContainer>
            </Detail.Header>
            <Detail.Action>
                <Detail.ActionButton>
                    <OutlineButton
                        outlineColor={colors.green73}
                        onClick={props.onClickEdit}
                        hoverTextColor={colors.white}
                    >
                        <FormattedMessage id={'components.DetailHeader.Edit'} />
                    </OutlineButton>
                </Detail.ActionButton>
                <Detail.ActionButton>
                    <OutlineButton
                        outlineColor={colors.green73}
                        hoverBackgroundColor={colors.status.red}
                        hoverOutlineColor={colors.status.red}
                        hoverTextColor={colors.white}
                        onClick={props.onClickDelete}
                    >
                        <FormattedMessage id={'components.DetailHeader.Delete'} />
                    </OutlineButton>
                </Detail.ActionButton>
                <Detail.ActionButton>
                    <OutlineButton
                        outlineColor={colors.green73}
                        hoverBackgroundColor={colors.status.red}
                        hoverOutlineColor={colors.status.red}
                        hoverTextColor={colors.white}
                        onClick={props.onClickDirectAccess}
                    >
                        <FormattedMessage id={'containers.Admin.DirectAccess'} />
                    </OutlineButton>
                </Detail.ActionButton>
            </Detail.Action>
        </Detail>
    );
}

export default DetailHeader;
