// @flow

import styled from 'styled-components';
import colors from 'styles/colors';
import { Input, Select } from 'antd';

const MaintenanceStyle = styled.div`
    border-radius: .25rem;
    padding: .75rem 1.25rem;
    border: 1px solid ${colors.green61};
    background: rgba(0, 0, 0, 0.05);

`;


MaintenanceStyle.InputLabel = styled.div`
    align-self: center;
    display: flex;
    flex-direction: row;
`;


MaintenanceStyle.MaintenanceInput = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0;
    align-items: center;

    input {
        width: 52%;
        height: 70%;
        margin-top: 1px;
    }

    .ant-picker {
        width: 52%;
        margin-top: 1px;
    }

    .textAreaInput {
        width: 52%;
    }

`;
MaintenanceStyle.InputItem = styled(Input)`
    margin: 1% 0;

    .ant-input {
        width: ${(props) => props.width};
        height: ${(props) => props.height};
    }

`;

MaintenanceStyle.Select = styled(Select)`
    width: 52%;
    margin-top: 5px;
    margin-bottom: 5px;

`;

export default MaintenanceStyle;
