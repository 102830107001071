// @flow

export const GET_CATEGORIE_ERROR = 'app/services/Categorie/GET_CATEGORIE_ERROR';
export const LOADING_CATEGORIE = 'app/services/Categorie/LOADING_CATEGORIE';
export const GET_CATEGORIES_SUCCESS = 'app/services/Categorie/GET_CATEGORIES_SUCCESS';


export const startLoading = (): GenericActionType => ({
    type: LOADING_CATEGORIE,
});

export const receivedCategorieError = (): GenericActionType => ({
    type: GET_CATEGORIE_ERROR,
});

export const receivedGetCategoriesSuccess = (categories): GenericActionType => ({
    type: GET_CATEGORIES_SUCCESS,
    categories
})