/* eslint-disable react/prop-types */
/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype/require-valid-file-annotation */
import React from 'react';
import { injectIntl } from 'react-intl';
import GroupView from './components/GroupView';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { selectUser } from 'services/Authentication/selectors';
import { createStructuredSelector } from 'reselect';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import colors from '../../styles/colors';
import './index.css';
import * as axios from 'axios';
import auth0 from 'services/Authentication/auth0';
import { renewUserSession } from '../../services/Authentication/thunks';
import IntlContextProvider from './context/IntlContext';

const theme = createTheme({
    palette: {
        primary: {
            main: colors.green52,
            light: colors.green63,
        },
        secondary: {
            main: colors.black,
        },
        background: {
            paper: 'rgba(255, 255, 255, 1)',
            default: '#ffffff',
        },
    },
});

let renewTimer = null;

const renewSession = (props) => {
    auth0.renewSession((authResult) => {
        if (authResult && authResult.accessToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${authResult.accessToken}`;
        }
        props.renewUserSession();
    });
};
const renewToken = (props) => {
    renewTimer = setTimeout(() => {
        renewSession(props);
        renewToken(props);
    }, 1000 * 60 * 60);
};
export class GroupsComponent extends React.Component {
    componentDidMount() {
        const expiresAt = this.props.user.get('expiresAt');
        const token = this.props.user.get('token');

        if (!token || new Date().getTime() > expiresAt - 1000 * 60 * 60) {
            renewSession(this.props);
            renewToken(this.props);
        }

        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    componentWillUnmount() {
        clearTimeout(renewTimer);
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <IntlContextProvider intl={this.props.intl}>
                      <Helmet>
                          <title>
                             Admin - Groupes
                          </title>
                      </Helmet>
                      <GroupView />
                </IntlContextProvider>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
});

const mapDispatchToProps = (dispatch) => ({
    renewUserSession: () => dispatch(renewUserSession()),
});
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(GroupsComponent))
);
