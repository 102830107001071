/* eslint-disable flowtype*/
/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype/require-valid-file-annotation */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */

import React, { useCallback, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import useStyles from '../styles/TransitionsModal.styles';

const TransitionsModal = ({ children, button }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleToggleModal = useCallback(
        () => {
            setOpen(!open);
        },
        [open]
    );

    return (
        <React.Fragment>
            {button({ openModal: handleToggleModal })}
            <Modal className={classes.modal} open={open} onClose={handleToggleModal}>
                <Fade in={open}>{children({ closeModal: handleToggleModal })}</Fade>
            </Modal>
        </React.Fragment>
    );
};

export default TransitionsModal;
