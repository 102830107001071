/* eslint-disable flowtype/require-parameter-type,no-undefined */
/* eslint-disable no-console */
/* eslint-disable flowtype/require-valid-file-annotation */

import axios from 'axios';
import _ from 'lodash';
import { useCallback, useEffect, useReducer } from 'react';
import { ADMIN_API_URL } from 'env';

export const initialState = {
    isFetchingUsers: false,
    users: undefined,
    error: null,
};

const usersReducer = (state, action) => {
    switch (action.type) {
        case 'startFetching':
            return {
                ...state,
                isFetchingUsers: true,
                users: [],
            };
        case 'pageLoaded':
            // eslint-disable-next-line no-case-declarations
            const users = _.unionBy(action.payload, state.users, 'id');
            return { ...state, users };
        case 'usersLoaded':
            return { ...state, isFetchingUsers: false };
        default:
            throw new Error('Invalid action: ', action);
    }
};

export const useUsers = () => {
    const [state, dispatch] = useReducer(usersReducer, initialState);
    const { users, isFetchingUsers } = state;

    const getUsers = useCallback(async () => {
        try {
            const params = {
                pageNumber: 1,
                includeRoles: false,
            };
            let result = await axios.get(`${ADMIN_API_URL}users`, {
                params,
            });
            if (result.status !== 200) {
                console.warn('Error getting users', result);
            }

            dispatch({ type: 'pageLoaded', payload: result.data.users });

            let next = Array.isArray(result.data.links) ? result.data.links.find((link) => link.rel === 'next') : null;
            while (next) {
                result = await axios.get(`${ADMIN_API_URL}${next.href}`, {
                    params: { includeRoles: params.includeRoles },
                });
                if (result.status !== 200) {
                    console.warn('Error getting users', result);
                }

                dispatch({ type: 'pageLoaded', payload: result.data.users });

                next = Array.isArray(result.data.links) ? result.data.links.find((link) => link.rel === 'next') : null;
            }

            dispatch({ type: 'usersLoaded' });
        } catch (error) {
            console.error(error);
        }
    }, []);

    const fetchUsers = useCallback(
        () => {
            if (!isFetchingUsers) {
                dispatch({ type: 'startFetching' });
                getUsers();
            }
        },
        [getUsers, isFetchingUsers]
    );

    return { fetchUsers, users, isFetchingUsers };
};
