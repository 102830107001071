// @flow

import request from 'services/request';
import adminRequest from 'services/adminRequest';

import type { Organization, UserDataType, AssociateUserType } from 'types';

export function createUserRequest(data: UserDataType, token: string, locale: string): Promise<> {
    return request(
        `${locale}/users`,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function editUserRequest(
    userId: number,
    data: UserDataType,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/users/${userId}`,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function createUserAssociationRequest(
    url: string,
    data: AssociateUserType,
    token
): Promise<> {
    return request(
        url,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function fetchGroups(
    token: string,
    branchId: number,
): Promise<> {
    return adminRequest(
        `branches/${branchId}/groups`,
        {
            method: 'GET',
        },
        true,
        token
    );
}