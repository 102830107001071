import React from 'react';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { ApHomeIcon } from '../Icons';
import { ApVector } from '../Icons';
import { ApReservationIcon } from '../Icons';
import './index.scss';
import { openReservationModal } from '../../services/AutoPartage/thunks';
import { selectReservationModal } from '../../services/AutoPartage/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function ApNavigationBar(props) {
  const handleOnClickReservation = () => {

    props.openReservationModal();
  };
  return (
    <React.Fragment>
      <Container className="ap-nav-bar">
        <Breadcrumbs separator={(<ApVector />)}>
          <Link
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="/"
          >
            <ApHomeIcon />
          </Link>
          <Link
            underline="none"
            sx={{
              display: 'flex', alignItems: 'center',
              "&:hover": {
                color: 'black',
              }
            }}
            className="ap-links"
            color="#98A2B3"
            href="/"
          >
            <FormattedMessage id={'components.AP.NavigationBar.reservations'} />
          </Link>
        </Breadcrumbs>
        <Button variant="contained"
          sx={{ backgroundColor: '#62ab36' }}
          className="ap-reservation-button"
          onClick={handleOnClickReservation}
        >
          <ApReservationIcon />
          <div className="ap-reservation-text">
            <FormattedMessage id={'components.AP.NavigationBar.reservationButton'} />
          </div>
        </Button>
      </Container>
    </React.Fragment>
  )
}
const mapStateToProps: Object = createStructuredSelector({
  reservationModal: selectReservationModal(),
});

const mapDispatchToProps: Object = (dispatch) =>
  bindActionCreators(
    {
      openReservationModal,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(ApNavigationBar))
);