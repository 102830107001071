// @flow

import adminRequest from 'services/adminRequest';

// Categories
//* ******************************************//

/**
 * Request all categories from branch
 *
 * @param {string} token
 * @param {number} branchId
 */
export function retrieveCategories(token: string, branchId: number): Promise<> {
    const url = `branches/${branchId}/categories`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Request categorie by id
 *
 * @param {string} token
 * @param {number} categorieId
 */
 export function retrieveCategorie(token: string, categorieId: number): Promise<> {
    const url = `categories/${categorieId}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Request categorie by categorieId
 *
 * @param {string} token
 * @param {number} categorieId
 */
 export function retrieveCategorieById(token: string, categorieId: number): Promise<> {
    const url = `categories/${categorieId}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

/**
 * Create categorie for branch.
 *
 * @param {string} token
 * @param {number} branchId
 * @param data
 */
export function addCategorie(
    token: string,
    branchId: number,
    data
): Promise<> {
    const url = `branches/${branchId}/categories`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

/**
 * Delete categorie by categorieId.
 *
 * @param {string} token
 * @param {number} branchId
 * @param data
 */
 export function deleteCategorie(
    token: string,
    categorieId: number
): Promise<> {
    const url = `categories/${categorieId}`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
        },
        false,
        token
    );
}

/**
 * Edit categorie by categorieId.
 *
 * @param {string} token
 * @param {number} categorieId
 * @param data
 */
 export function editCategorie(
    token: string,
    categorieId: number,
    data
): Promise<> {
    const url = `categories/${categorieId}`;
    return adminRequest(
        url,
        {
            method: 'PUT',
            data: {
                ...data
            }
        },
        false,
        token
    );
}

/**
 * Replaces all the vehicles in the categorie with the new ones
 *
 * @param {string} token
 * @param {number} categorieId
 * @param data
 */
 export function updateCategorieVehicles(
    token: string,
    categorieId: number,
    data
): Promise<> {
    const url = `categories/${categorieId}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data: {
                ...data
            }
        },
        false,
        token
    );
}

/**
 * Add vehicles in the categorie
 *
 * @param {string} token
 * @param {number} categorieId
 * @param data
 */
 export function requestAddVehiclesToCategorie(
    token: string,
    categorieId: number,
    data
): Promise<> {
    const url = `categorie/${categorieId}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data: {
                ...data
            }
        },
        false,
        token
    );
}

/**
 * Delete vehicles from the categorie
 *
 * @param {string} token
 * @param {number} categorieId
 * @param data
 */
 export function requestDeleteVehiclesFromCategorie(
    token: string,
    categorieId: number,
    data
): Promise<> {
    const url = `categorie/${categorieId}`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
            data: {
                ...data
            }
        },
        false,
        token
    );
}