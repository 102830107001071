// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the auth state domain
 */
const selectAuthState = () => (state) => state.get('auth');

/**
 * Select the auth state
 */

const selectUser = () => createSelector(selectAuthState(), (substate) => substate.get('user'));

const selectPasswordResetMessage = () =>
    createSelector(selectAuthState(), (substate) => substate.get('passwordResetMessage'));

const selectRequest = () =>
    createSelector(selectAuthState(), (substate) => substate.get('authenticationRequest'));

const selectErrors = () => createSelector(selectAuthState(), (substate) => substate.get('errors'));

const selectWhoamiStatus = () =>
    createSelector(selectAuthState(), (substate) => substate.get('whoamiComplete'));

const selectLoggedInAsUser = () =>
    createSelector(selectAuthState(), (substate) => substate.get('loggedInAsUser'));

const selectShowModal = () =>
    createSelector(selectAuthState(), (substate) => substate.get('showModal'));

const selectPasswordResetSent = () =>
    createSelector(selectAuthState(), (substate) => substate.get('passwordResetSent'));

const selectForgotPasswordError = () =>
    createSelector(selectAuthState(), (substate) => substate.get('forgotPasswordError'));

const selectPasswordResetError = () =>
    createSelector(selectAuthState(), (substate) => substate.get('resetPasswordError'));

const selectPasswordCreateError = () =>
    createSelector(selectAuthState(), (substate) => substate.get('createPasswordError'));

export {
    selectWhoamiStatus,
    selectAuthState,
    selectUser,
    selectPasswordResetMessage,
    selectRequest,
    selectErrors,
    selectLoggedInAsUser,
    selectShowModal,
    selectPasswordResetSent,
    selectForgotPasswordError,
    selectPasswordResetError,
    selectPasswordCreateError,
};
