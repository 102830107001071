// @flow

import styled from 'styled-components';

import StyledSelect from 'components/SelectField/styles';
import { Container as OutlineButtonContainer } from 'components/OutlineButton/styles';

import colors from 'styles/colors';
import { Container } from 'styles/common';

export const Modal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 50px;
    background-color: ${colors.transparentBlack4};
    overflow-y: scroll;
    z-index: 1000;
`;

Modal.Container = styled.div`
    width: ${(props) => (props.size && props.size.width) || 100}%;
    height: ${(props) => (props.size && props.size.height) || 100}%;
    background-color: ${colors.white};
    border: 1px solid ${colors.grayDD};
    border-top: 5px solid ${colors.black};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
    margin: 0 auto;
    padding: 25px;
    position: relative;
`;

Modal.Image = styled.img`
    width: ${(props) => props.width || '40px'};
    height: ${(props) => props.height || '40px'};
    margin: ${(props) => props.margin || '0'};
`;

Modal.Close = styled.div`
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    position: absolute;
    right: -40px;
    top: -5px;
    background-color: ${colors.black};
    align-items: center;
    justify-content: center;
`;

Modal.SelectOverride = styled.div`
    display: flex;
    ${StyledSelect.Container} {
        max-width: 100% !important;
    }
`;

Modal.Submit = styled.div`
    display: flex;
    /*flex: 1;*/
    width: 100%;
    align-self: flex-end;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding-top: 10px;
    ${OutlineButtonContainer} {
        width: 100%;
    }
`;

Modal.Error = styled.div`
    display: flex;
    align-items: center;
    justfiy-content: center;
    max-height: 50px;
`;

export const Header = styled.div`
    display: flex;
    font-family: OpenSans;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 35px;
    color: ${colors.green63};
    border-bottom: 1px solid #000;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
`;

Header.Content = styled.div`
    display: flex;
    margin: 0 auto;
`;

export const Organization = styled.div`
    height: ${(props) => (props.size && props.size.width) || 100}%;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const FormGroup = styled.div.attrs({
    columns: ({ columns }) => columns || 3,
    size: ({ size }) => size || 45,
})`
    margin-top: 20px;
    display: grid;
    grid-template-columns: ${({ layout, columns }) =>
        layout ? layout.map((item) => `${item}fr`).join(' ') : `repeat(${columns}, 1fr)`};
    grid-template-rows: repeat(auto-fill, ${({ size }) => size}px);
    grid-gap: 10px;
`;

Organization.Content = styled.div`
    overflow-y: auto;
`;

export const Split = styled.div`
    height: 1px;
    background-color: ${colors.grayDD};
    width: 100%;
`;

export const Equipment = styled.div``;

Equipment.Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;

Equipment.Error = styled.div`
    text-align: center;
    color: ${colors.status.red};
    padding: 8px 0;
`;

export const CheckField = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 10px;
`;

export const Submit = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    width: ${({ width }) => width || 50}%;
    align-items: flex-end;
`;

Submit.Button = styled.div`
    flex: 1;
    margin: ${(props) => props.margin || '0'};
`;

export const Confirmation = styled.div`
    display: flex;
    flex-direction: column;
`;
