// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import Fuse from 'fuse.js';
import { fromJS } from 'immutable';

import { selectUser } from 'services/Authentication/selectors';
import { selectLocale } from 'services/Language/selectors';
import { selectOrganization } from 'services/Organization/selectors';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';

import {
    getOrganizationData,
    deleteOrganization,
    editOrganization,
    deleteOrganizationEquipment,
    createOrganization,
    getOrganizationExtraUsers,
} from 'services/Organization/thunks';
import { Loading } from 'views/Groups/utils/Loading';

import { createUser, editUser, deleteUser } from 'services/User/thunks';

import { adminDirectAccess } from 'services/Authentication/thunks';

import { DELETE_ORGANIZATION_USER } from 'services/Organization/actions';

import { selectModalType, selectModalAction } from 'services/Modal/selectors';
import {
    toggleOrganizationModal,
    toggleUserModal,
    toggleEquipmentModal,
    toggleConfirmationModal,
    closeModal,
} from 'services/Modal/thunks';
import {
    CREATE_BRANCH,
    CREATE_ORGANIZATION_USER,
    EDIT_ORGANIZATION_USER,
    EDIT_ORGANIZAITON,
    MODAL_TYPE,
    CREATE_ORGANIZATION_EQUIPMENT,
    EDIT_ORGANIZATION_EQUIPMENT,
    ASSOCIATE_EQUIPMENT,
    CONFIRMATION,
} from 'services/Modal/actions';

import {
    createEquipment,
    associateOrganizationEquipment,
    editOrganizationEquipment,
    deleteEquipment,
} from 'services/Equipment/thunks';

import SearchInput from 'components/SearchInput';
import DashboardDataTable from 'components/DashboardDataTable';
import TextWithBadge from 'components/TextWithBadge';
import SelectField from 'components/SelectField';
import DetailHeader from 'components/DetailHeader';
import OutlineButton from 'components/OutlineButton';

import Modal from 'components/Modals';
import OrganizationModal from 'components/Modals/CreateOrganizationModal';
import UserModal from 'components/Modals/CreateUserModal';
import EquipmentModal from 'components/Modals/EquipmentModal';
import UserDashboardItem from 'components/DashboardDataUserItem';
import EquipmentDashboardItem from 'components/DashboardEquipmentItem';

import ConfirmationModal from 'components/Modals/Confirmation';

import DeletedMessage from 'components/DeletedMessage';

import Loader from 'components/Loader';

import OrganizationPage from './styles';
import { Container } from 'styles/common';
import colors from 'styles/colors';
import CaretDownImage from 'assets/caret-down.png';
import CaretUpImage from 'assets/caret-up.png';

import { parseJWT } from 'helpers';
import type { SearchResults, ImmutableList, OrganizationType } from 'types';
import UserListComponent from '../../components/UserListComponent';

type Props = {
    organization: OrganizationType,
};
type State = {
    ascOrder: string,
    branchResults: ImmutableList<string>,
    branchQuery: string,
    userResults: ImmutableList<string>,
    userQuery: string,
    equipmentResults: ImmutableList<string>,
    equipmentQuery: string,
    usersList: object,
    nextPage: Number,
    loading: Boolean

};

export class OrganizationComponent extends React.Component<Props, State> {
    deletedUserId: number;

    state = {
        branchResults: fromJS([]),
        userResults: fromJS([]),
        equipmentResults: fromJS([]),
        branchQuery: '',
        userQuery: '',
        equipmentQuery: '',
        ascOrder: '',
        nextPage: 2,
        pageCount: 0,
        loading: false,
    };

    componentDidMount() {
        // fetch organization.
        this.fetchOrgData();
        if (this.props.organization.get('users')) {
            this.setState({ usersList: this.props.organization.get('users').toJS() })
            this.setState({ pageCount: this.props.organization.get('usersCount') / 50 })
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.organizationId !== this.props.match.params.organizationId) {
            this.fetchOrgData();
        }

        const currentUsers = this.props.organization.get('users');
        const prevUsers = prevProps.organization.get('users');
        const currentUsersCount = this.props.organization.get('usersCount');
        if (currentUsers && currentUsers.size > 0 && currentUsers !== prevUsers) {
            this.setState({ usersList: currentUsers.toJS() });
            this.setState({ pageCount: currentUsersCount / 50 })

        }
    }
    fetchOrgData = () => {
        const organizationId = this.props.match.params.organizationId;

        let token;
        if (this.props.user) {
            token = this.props.user.get('token');
        } else if (sessionStorage.getItem('api_token')) {
            token = sessionStorage.getItem('api_token');
        }

        if (token) {
            this.props.getOrganizationData(organizationId).then(
                () => {
                    this.handleSearchResultsUsers('');
                }
            )
        }
    };

    deletedUserId = -1;

    /**
     * Accepts a sortOrder string used to determin which colum we will order by.
     * On order, we remove existing order conditions and apply the currently selected one.
     */
    handleOrderOrganizations = (sortColumn: string) => () => {
        let ascOrder = sortColumn;

        if (this.state.ascOrder === sortColumn) {
            ascOrder = '';
        }

        this.setState({
            ascOrder,
        });
    };

    handleOnClickAllLocations = () => {
        console.log('clicked All Locations');
    };

    handleOnChangeSearchInput = (event: Event) => {
        console.log('searching ...');
    };

    handleSearchResultsBranches = (searchResults: SearchResults) => {
        this.setState({
            branchResults: searchResults.results,
            branchQuery: searchResults.query,
        });
    };

    handleSearchResultsUsers = (searchResults: SearchResults) => {
        this.setState({
            userQuery: searchResults.query,
            userResults: searchResults.results,
        });
    };

    handleSearchResultsEquipment = (searchResults: SearchResults) => {
        this.setState({
            equipmentQuery: searchResults.query,
            equipmentResults: searchResults.results,
        });
    };

    handleOnClickOrganization = (id: number) => {
        // pass id to branchDetailPage.
        const organizationId = this.props.match.params.organizationId;
        const token = this.props.user.get('token');
        this.props.getOrganizationData(id);
        this.props.history.push(`/${this.props.intl.locale}/interne/orgs/${id}`);
    };

    handleOnClickViewMoreUsers = () => {
        const organizationId = this.props.match.params.organizationId;
        const usersURL = `/${this.props.intl.locale
            }/interne/orgs/${organizationId}/users`;
        this.props.history.push(usersURL);
    };

    handleOnClickViewMoreEquipment = () => {
        const organizationId = this.props.match.params.organizationId;
        const url = `/${this.props.intl.locale
            }/interne/orgs/${organizationId}/equipment`;
        this.props.history.push(url);
    };

    handleOnChangeAction = (value: string) => {
        if (value === CREATE_BRANCH) {
            this.props.toggleOrganizationModal(value);
        }

        if (value === CREATE_ORGANIZATION_USER) {
            this.props.toggleUserModal(value);
        }

        if (value === CREATE_ORGANIZATION_EQUIPMENT) {
            this.props.toggleEquipmentModal(value);
        }

        if (value === ASSOCIATE_EQUIPMENT) {
            this.props.toggleEquipmentModal(value);
        }
    };

    handleOnClickDirectAccess = (user: UserType) => (e: Event) => {
        console.log(this.props.user);
        this.props
            .adminDirectAccess(this.props.user, this.props.organization.get('id'))
            .then(() => {
                // redirect user after loging-in as user.
                this.props.history.replace(`/${this.props.locale}/map`);
            });
    };

    handleOnClickEditOrganization = () => {
        // toggle editing modal;
        if (this.props.organization.get('deleted')) {
            return null;
        }
        // READ-ONLY organizations (2018-10-17)
        this.props.toggleOrganizationModal(EDIT_ORGANIZAITON);
    };

    handleOnClickEditUser = (userId: number) => {
        this.props.toggleUserModal(EDIT_ORGANIZATION_USER, userId);
    };


    handleUserEdit = (userId) => {
        this.handleOnClickEditUser(userId);
    };

    handleUserDelete = (userId) => {
        this.handleOnClickDeleteUser(userId);
    };

    handleOnClickDeleteUser = (userId: number) => {
        this.props.toggleConfirmationModal({ userId });
        this.deletedUserId = userId;
        // this.props.deleteUser(userId, DELETE_ORGANIZATION_USER);
    };

    handleConfirmDeleteUser = () => {
        if (this.deletedUserId && this.deletedUserId > 0) {
            this.props
                .deleteUser(this.deletedUserId, DELETE_ORGANIZATION_USER)
                .then(
                    () => {
                        this.fetchOrgData();
                        this.props.closeModal();
                    }
                );
        }
    };

    handleEditEquipment = (equipmentId: number) => {
        this.props.toggleEquipmentModal(EDIT_ORGANIZATION_EQUIPMENT, equipmentId);
    };

    handleOnSubmitOrganizationModal = (organization: OrganizationType) => {
        if (this.props.modalAction === EDIT_ORGANIZAITON) {
            this.props.editOrganization(organization, this.props.organization.get('id'));
        }

        if (this.props.modalAction === CREATE_BRANCH) {
            this.props.createOrganization({
                ...organization,
                parentId: Number(this.props.organization.get('id')),
            });
        }
    };

    handleOnSubmitUserModal = (user: UserDataType, userId: number) => {
        if (this.props.modalAction === CREATE_ORGANIZATION_USER) {
            return this.props.createUser(user, this.props.modalAction, this.props.organization.get('id'))
                .then((id) => {
                    this.fetchOrgData();
                    return id
                })
        }

        if (this.props.modalAction === EDIT_ORGANIZATION_USER) {
            this.props
                .editUser(userId, user, this.props.modalAction, this.props.organization.get('id'))
                .then(() => {
                    this.fetchOrgData();
                });
        }
    };

    handleOnSubmitEquipmentModal = (equipment: EquipmentType, equipmentId: ?number) => {
        if (equipmentId) {
            this.props.editOrganizationEquipment(equipment, equipmentId);
        } else {
            this.props.createEquipment(equipment);
        }
    };

    handleDeleteEquipment = (equipmentId: number) => {
        // READ-ONLY organizations (2018-10-17)
        this.props
            .deleteEquipment(equipmentId, this.props.match.params.organizationId)
            .then(() => this.props.deleteOrganizationEquipment(equipmentId));
    };

    handleOnClickDeleteOrganization = () => {
        // delete this organization
        if (this.props.organization.get('deleted')) {
            return null;
        }
        const message = '';
        if (window.confirm(message)) {
            this.props.deleteOrganization(
                this.props.organization.get('id'),
                undefined,
                this.props.organization.get('parentId')
            );
        }
    };

    getCaretSource = (column: string) => {
        if (this.state.ascOrder === column) {
            return CaretUpImage;
        }

        return CaretDownImage;
    };
    handleGetExtraUsers = () => {
        const organizationId = this.props.match.params.organizationId;
        this.setState({
            loading: true
        })
        this.props.getOrganizationExtraUsers(organizationId, this.state.nextPage)
            .then(() => {
                this.setState((prevState) => ({
                    nextPage: prevState.nextPage + 1,
                    loading: false
                }))
            }
            )

    }
    renderModal = () => {
        if (!this.props.modalType) {
            return false;
        }

        let organizationModalHeader = 'components.Modals.Create.Branch';
        if (this.props.modalAction === EDIT_ORGANIZAITON) {
            organizationModalHeader = 'components.Modals.Edit.Organization';
        }

        let userModalHeader = 'components.Modals.Create.User';
        let userModalAction = 'CREATE';
        if (this.props.modalAction === EDIT_ORGANIZATION_USER) {
            userModalHeader = 'components.Modals.Edit.User';
            userModalAction = 'EDIT';
        }

        let equipmentModalHeader = 'components.Modals.Create.Equipment';
        if (this.props.modalAction === EDIT_ORGANIZATION_EQUIPMENT) {
            equipmentModalHeader = 'components.Modals.Edit.Equipment';
        }
        if (this.props.modalAction === ASSOCIATE_EQUIPMENT) {
            equipmentModalHeader = 'components.Modals.Associate.Equipment';
        }

        if (this.props.modalType === MODAL_TYPE.USER) {
            return (
                <Modal>
                    <UserModal
                        header={userModalHeader}
                        data={this.props.organization.get('users')}
                        onSubmit={this.handleOnSubmitUserModal}
                        userAction={userModalAction}
                        branchId={this.props.match.params.organizationId}
                    />
                </Modal>
            );
        }

        if (this.props.modalType === MODAL_TYPE.ORGANIZATION) {
            return (
                <Modal>
                    <OrganizationModal
                        header={organizationModalHeader}
                        data={this.props.organization}
                        onSubmit={this.handleOnSubmitOrganizationModal}
                    />
                </Modal>
            );
        }

        if (this.props.modalType === MODAL_TYPE.EQUIPMENT) {
            return (
                <Modal>
                    <EquipmentModal
                        header={equipmentModalHeader}
                        data={this.props.organization.get('equipment')}
                        onSubmit={this.handleOnSubmitEquipmentModal}
                    />
                </Modal>
            );
        }

        if (this.props.modalType === MODAL_TYPE.CONFIRMATION) {
            return (
                <ConfirmationModal
                    headerTextId={'components.Confirmation.DeleteUser.Header'}
                    messageTextId={'components.Confirmation.DeleteUser.Message'}
                    confirmationCallback={this.handleConfirmDeleteUser}
                />
            );
        }
    };

    render() {
        const { vt } = this.props;

        let branchTableData = this.props.organization.get('branches');
        if (this.state.branchQuery && this.state.branchResults.size >= 0) {
            branchTableData = this.state.branchResults;
        }

        let userTableData = this.props.organization.get('users');
        if (this.state.userQuery && this.state.userResults.size >= 0) {
            userTableData = this.state.userResults;
        }

        let equipmentTableData = this.props.organization.get('equipment');
        if (this.state.equipmentQuery && this.state.equipmentResults.size >= 0) {
            equipmentTableData = this.state.equipmentResults;
        }

        const street = `${this.props.organization.getIn(['address', 'address'])}`;
        const city = `${this.props.organization.getIn(['address', 'city'])}`;
        const province = `${this.props.organization.getIn(['address', 'province'])}`;
        const postal = `${this.props.organization.getIn(['address', 'postalCode'])}`;
        const country = `${this.props.organization.getIn(['address', 'country'])}`;

        const address = `${street} - ${city} ${province}, ${postal} ${country}`;

        const branchActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.Branch.Create',
            }),
        };

        const userActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.User.Create',
            }),
        };

        const equipmentActionLabels = {
            create: this.props.intl.formatMessage({
                id: 'components.Action.Equipment.Create',
            }),
            associate: this.props.intl.formatMessage({
                id: 'components.Action.Equipment.Associate',
            }),
        };

        const branchColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'name',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Location',
                }),
                // This will work when integrating with ORCV2TM-6
                key: 'address.province+address.city',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.ActiveUsers',
                }),
                key: 'usersCount',
            },
        ];

        const equipmentColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'name',
            },
        ];

        const userColumns = [
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.Name',
                }),
                key: 'firstName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.LastLogin',
                }),
                key: 'lastLogin',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'components.DashboardData.Header.UserGroups',
                }),
                key: 'groups',
            },
        ];



        if (this.props.organization.size === 0) {
            return <Loader loading={true} />;
        }

        return (
            <OrganizationPage isOpen={this.props.isOpen}>

                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({ id: 'containers.HomePage.helmetTitle' })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'views.OrganizationPage.Head.Content',
                        })}
                    />
                </Helmet>
                {this.props.organization.get('deleted') && (
                    <DeletedMessage
                        text={this.props.intl.formatMessage({
                            id: 'views.OrganizaitonPage.Deleted',
                        })}
                    />
                )}
                <DetailHeader
                    title={this.props.intl.formatMessage({
                        id: 'components.SideMenu.Organizations',
                    })}
                    name={this.props.organization.get('branchName') || ''}
                    address={address || ''}
                    onClickEdit={this.handleOnClickEditOrganization}
                    onClickDelete={this.handleOnClickDeleteOrganization}
                    onClickDirectAccess={this.handleOnClickDirectAccess(this.props.user)}
                />
                <OrganizationPage.Split height={'1px'} color={colors.grayDD} />
                {this.props.organization.get('parent') &&
                    this.props.organization.getIn(['parent', 'name']) && (
                        <React.Fragment>
                            <OrganizationPage.ParentInfo>
                                <FormattedMessage id="views.OrganizaitonPage.Branch" />{' '}
                                <Link
                                    to={`/${this.props.match.params.locale
                                        }/interne/orgs/${this.props.organization.getIn([
                                            'parent',
                                            'id',
                                        ])}`}
                                >
                                    {this.props.organization.getIn(['parent', 'name'])}
                                </Link>
                            </OrganizationPage.ParentInfo>
                            <OrganizationPage.Split height={'1px'} color={colors.grayDD} />
                        </React.Fragment>
                    )}
                <OrganizationPage.DataHeader>
                    <TextWithBadge
                        text={this.props.intl.formatMessage({
                            id: 'components.TextWithBadge.Branches',
                        })}
                        badge={
                            (this.props.organization.get('branches') &&
                                this.props.organization.get('branches').size) ||
                            0
                        }
                    />
                </OrganizationPage.DataHeader>
                <OrganizationPage.FilterContainer>
                    <SearchInput
                        onSearchResults={this.handleSearchResultsBranches}
                        searchKeys={['name']}
                        borderColor={colors.grayDD}
                        data={this.props.organization.get('organizations')}
                    />
                    <SelectField
                        options={[{ label: branchActionLabels.create, value: CREATE_BRANCH }]}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.SelectField.Manage.Actions',
                        })}
                        value={null}
                        padding={'2px 0 0 0'}
                        onChange={this.handleOnChangeAction}
                        maxWidth={230}
                        center
                    />
                </OrganizationPage.FilterContainer>
                <DashboardDataTable
                    columns={branchColumns}
                    tableData={branchTableData}
                    id={'id'}
                    onClickDataItem={this.handleOnClickOrganization}
                />

                <OrganizationPage.DataHeader>
                    <TextWithBadge
                        text={this.props.intl.formatMessage({
                            id: 'components.TextWithBadge.Equipment',
                        })}
                        badge={
                            (this.props.organization.get('equipment') &&
                                this.props.organization.get('equipment').size) ||
                            0
                        }
                    />
                </OrganizationPage.DataHeader>
                <OrganizationPage.FilterContainer>
                    <SearchInput
                        onSearchResults={this.handleSearchResultsEquipment}
                        searchKeys={['name', 'equipmentType']}
                        borderColor={colors.grayDD}
                        data={this.props.organization.get('equipment')}
                    />
                    <SelectField
                        options={[
                            {
                                label: equipmentActionLabels.create,
                                value: CREATE_ORGANIZATION_EQUIPMENT,
                            },
                            // {
                            //     label: equipmentActionLabels.associate,
                            //     value: ASSOCIATE_EQUIPMENT,
                            // },
                        ]}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.SelectField.Manage.Actions',
                        })}
                        padding={'2px 0 0 0'}
                        onChange={this.handleOnChangeAction}
                        maxWidth={230}
                        center
                        value={null}
                    />
                </OrganizationPage.FilterContainer>
                <DashboardDataTable
                    columns={equipmentColumns}
                    id={'id'}
                    tableData={equipmentTableData}
                    component={EquipmentDashboardItem}
                    onClickViewMore={this.handleOnClickViewMoreEquipment}
                    onClickEditEquipment={this.handleEditEquipment}
                    onClickDeleteEquipment={this.handleDeleteEquipment}
                />
                <OrganizationPage.DataHeader>
                    <TextWithBadge
                        text={this.props.intl.formatMessage({
                            id: 'components.TextWithBadge.Users',
                        })}
                        badge={
                            (this.props.organization.get('users') &&
                                this.props.organization.get('users').size) ||
                            0
                        }
                    />
                </OrganizationPage.DataHeader>
                <OrganizationPage.FilterContainer>
                    <SearchInput
                        onSearchResults={this.handleSearchResultsUsers}
                        searchKeys={['firstName', 'lastName']}
                        borderColor={colors.grayDD}
                        data={this.props.organization.get('users')}
                    />
                    <SelectField
                        options={[
                            { label: userActionLabels.create, value: CREATE_ORGANIZATION_USER },
                        ]}
                        placeholder={this.props.intl.formatMessage({
                            id: 'components.SelectField.Manage.Users',
                        })}
                        padding={'2px 0 0 0'}
                        onChange={this.handleOnChangeAction}
                        maxWidth={230}
                        center
                    />
                </OrganizationPage.FilterContainer>
                {(this.state.loading) ? (<Loading />) :
                    (<div>
                        <UserListComponent
                            userList={userTableData ? userTableData.toJS() : []}
                            onEditUser={this.handleUserEdit}
                            onDeleteUser={this.handleUserDelete}
                        />
                        {((this.state.pageCount + 1) >= this.state.nextPage) ? (
                            <div onClick={this.handleGetExtraUsers}>
                                <OutlineButton outlineColor={colors.green73} hoverTextColor={colors.white}>
                                    <FormattedMessage id={'views.BranchPage.ViewMore'} />
                                </OutlineButton>
                            </div>
                        ) : null}
                    </div>)
                }
                {/* <DashboardDataTable
                    columns={userColumns}
                    tableData={userTableData}
                    id={'id'}
                    onClickViewMore={this.handleOnClickViewMoreUsers}
                    onClickEditUser={this.handleOnClickEditUser}
                    onClickDeleteUser={this.handleOnClickDeleteUser}
                    //onClickEditRoles={this.handleOnClickEditRoles}
                    component={UserDashboardItem}
                    users
                /> */}

                <Container.OverFlow margin={'50px'} />
                {this.renderModal()}
            </OrganizationPage>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    user: selectUser(),
    locale: selectLocale(),
    organization: selectOrganization(),
    modalType: selectModalType(),
    modalAction: selectModalAction(),
    isOpen: selectIsSideMenuOpen(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getOrganizationData,
            toggleOrganizationModal,
            toggleUserModal,
            toggleEquipmentModal,
            deleteUser,
            editUser,
            createUser,
            editOrganization,
            deleteOrganization,
            createEquipment,
            associateOrganizationEquipment,
            editOrganizationEquipment,
            deleteEquipment,
            deleteOrganizationEquipment,
            toggleConfirmationModal,
            closeModal,
            createOrganization,
            adminDirectAccess,
            getOrganizationExtraUsers,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(OrganizationComponent))
);
