// @flow

import { createSelector } from 'reselect';

/**
 * Direct selector to the auth state domain
 */
const selectModalState = () => (state) => state.get('modal');

/**
 * Select the modal state
 */
const selectShowModal = () =>
    createSelector(selectModalState(), (substate) => substate.get('showModal'));

const selectModalType = () =>
    createSelector(selectModalState(), (substate) => substate.get('modalType'));

const selectModalLoading = () =>
    createSelector(selectModalState(), (substate) => substate.get('modalLoading'));

const selectModalError = () =>
    createSelector(selectModalState(), (substate) => substate.get('modalError'));

const selectModalAction = () =>
    createSelector(selectModalState(), (substate) => substate.get('modalAction'));

const selectUserId = () => createSelector(selectModalState(), (substate) => substate.get('userId'));

const selectEquipmentId = () =>
    createSelector(selectModalState(), (substate) => substate.get('equipmentId'));

export {
    selectShowModal,
    selectModalType,
    selectModalLoading,
    selectModalError,
    selectModalAction,
    selectUserId,
    selectEquipmentId,
};
