import React, { Component } from 'react';

import { Alert } from 'react-bootstrap';
import colors from 'styles/colors';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getBranchData } from 'services/Organization/thunks';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectOrganizationNotFormating } from 'services/Organization/selectors';
import ReactJson from 'react-json-view';
import { editBranchByField } from 'services/Organization/thunks';
import { Row } from 'react-bootstrap';
import { selectUser } from 'services/Authentication/selectors';
import { parseJWT } from 'helpers';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Branch extends Component {
    state = {
        apiCredentials: '',
        initialJson: {},
        updatedApiCredentials: {},
        branchId: 0,
        branchName: '',
    };

    componentDidMount() {
        this.props.getBranchData().then(() => {
            this.setState({
                apiCredentials: this.props.organizations['api_credentials'],
                branchId: this.props.organizations['id'],
                branchName: this.props.organizations['name'],
            });
        }).then;
    }

    sendData = (updated_src: any) => {
        this.props.editBranchByField(
            { api_credentials: updated_src.updated_src },
            this.state.branchId
        );
    };

    onEdit = (updated_src: any) => {
        this.sendData(updated_src);
    };

    onAdd = (updated_src: any) => {
        this.sendData(updated_src);
    };

    onDelete = (updated_src: any) => {
        this.sendData(updated_src);
    };

    render() {
        const src = this.state.apiCredentials ? this.state.apiCredentials : this.state.initialJson;
        const jwt = parseJWT(this.props.user.get('token'));

        if (
            jwt.permissions &&
            Array.isArray(jwt.permissions) &&
            jwt.permissions.indexOf('access:admin') != -1
        ) {
            return (
                <div>
                    <span className="d-inline-block">{this.state.branchName}</span>
                    <Alert style={{ background: 'rgba(0,0,0,0.05)', borderColor: colors.green61 }}>
                        <span className="d-inline-block">
                            <FormattedMessage id={'components.Admin.Branch.BranchInformations'} />
                        </span>
                        <hr />
                        <Row style={{ marginLeft: '20px' }}>
                            <FormattedMessage id={'components.Admin.Branch.APICredentials'} />
                        </Row>
                        <hr />
                        <ReactJson
                            src={src}
                            onEdit={this.onEdit}
                            onAdd={this.onAdd}
                            onDelete={this.onDelete}
                            name={false}
                        />
                        <hr />
                    </Alert>
                </div>
            );
        } else return null;
    }
}

const mapStateToProps: Object = createStructuredSelector({
    organizations: selectOrganizationNotFormating(),
    user: selectUser(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            getBranchData,
            editBranchByField,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(Branch))
);
