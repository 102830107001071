// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Link } from 'react-router-dom';
import Organization from './styles';

import TestOrganizationImage from 'assets/test-organization.png';
//Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { openDrivingReportModal, setDrivingInfo } from 'services/Report/thunks';
import { selectDrivingReportModal, selectDrivingReport } from 'services/Report/selectors';
import { Tooltip } from 'antd';

import type { ColumnType } from 'types';

type Props = {
    rowSpan: ?number,
    data: Object<any>,
    columns: Array<ColumnType>,
};

class OrganizationListItem extends React.PureComponent<Props> {
    hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    intToARGB = (i) => {
        let hex =
            ((i >> 24) & 0xff).toString(16) +
            ((i >> 16) & 0xff).toString(16) +
            ((i >> 8) & 0xff).toString(16) +
            (i & 0xff).toString(16);
        hex += '000000';
        return hex.substring(0, 6);
    };

    convertStringToColor = (str) => {
        if (!str) return '';
        str = str.replace(/\s+/g, '');
        return `#${this.intToARGB(this.hashCode(str))}`;
    };

    hexToHSL = (H) => {
        // Convert hex to RGB first
        let r = 0;
        let g = 0;
        let b = 0;
        if (H.length == 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length == 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 100;
        g /= 280;
        b /= 100;
        const cmin = Math.min(r, g, b);
        const cmax = Math.max(r, g, b);
        const delta = cmax - cmin;
        let h = 0;
        if (delta == 0) h = 0;
        else if (cmax == r) h = ((g - b) / delta) % 6;
        else if (cmax == g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;
        h = Math.round(h * 60);
        if (h < 0) h += 360;

        return `hsl(${h},${80}%,${60}%)`;
    };
    handleOpenDrivingModal = () => {
        let driverChipColor = this.hexToHSL(
            this.convertStringToColor(this.props.data.get('driverName'))
        );
        let startTime = this.props.data.get('startTime');
        let endTime = this.props.data.get('endTime');
        let date = startTime.split(' ')[0];
        let time = `${startTime.split(' ')[1]} - ${endTime.split(' ')[1]}`;
        this.props.setDrivingInfo(
            this.props.data.get('vehicleNumb'),
            this.props.data.get('totalDistance'),
            date,
            time,
            this.props.data.get('speed'),
            this.props.data.get('speedLimit'),
            this.props.data.get('maximumSpeed'),
            this.props.data.get('driverName'),
            this.props.data.get('entryLat'),
            this.props.data.get('entryLong'),
            this.props.data.get('exitLat'),
            this.props.data.get('exitLong'),
            this.props.data.get('geoName'),
            driverChipColor
        );
        this.props.openDrivingReportModal();
    };
    render() {
        return (
            <Organization>
                <Organization.Grid rowSpan={this.props.rowSpan}>
                    {this.props.columns.map((column, index) => {
                        let field;
                        field = this.props.data.get(`${column.key}`);
                        // if (column.key.indexOf('+') > -1) {
                        //     field = column.key.split('+');
                        // } else {
                        //     field = [column.key];
                        // }
                        // field = field
                        //     .map((col) => {
                        //         return col.indexOf('.') > -1
                        //             ? this.props.data.getIn(col.split('.'))
                        //             : this.props.data.get(col);
                        //     })
                        //     .filter((i) => (i || '').length > 0)
                        //     .join(', ');
                        if (index === 8 && (field === null || field === '')) {
                            field = this.props.intl.formatMessage({
                                id: 'Component.Report.Overspeed.DefaultGeofence',
                            });
                        }
                        if (index === 1 && field === 'Non identifié') {
                            field = this.props.intl.formatMessage({
                                id: 'Component.Report.Overspeed.UnidentifiedDriver',
                            });
                        }

                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            ((index === 0) && (Object.keys(this.props.drivingReportData.toJS()).length > 0)) ? (
                                <Tooltip
                                    placement="bottomLeft"
                                    title={this.props.intl.formatMessage({
                                        id: 'Components.Reports.OverSpeed.HoverMessage',
                                    })}
                                >
                                    <Organization.Vehicule
                                        onClick={this.handleOpenDrivingModal}
                                        key={index}
                                    >
                                        {field}
                                    </Organization.Vehicule>
                                </Tooltip>
                            ) : (
                                <Organization.Item key={index}>{field}</Organization.Item>
                            )
                        );
                    })}
                </Organization.Grid>
            </Organization>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    drivingReportModal: selectDrivingReportModal(),
    drivingReportData: selectDrivingReport(),
});
const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            openDrivingReportModal,
            setDrivingInfo,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(OrganizationListItem))
);
