// @flow

import { fromJS } from 'immutable';

import type { Driver } from 'types';

import {
    GET_DRIVERS_SUCCESS,
    GET_DRIVERS_ERROR,
    LOADING_DRIVERS,
    DISMISS_DRIVER_LOADING,
    DISMISS_DRIVER_ERROR,
} from './actions';

const initialState = fromJS({
    drivers: {},
    driversError: false,
    loadingDriver: false,
});

function driverServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case GET_DRIVERS_SUCCESS: {
            const drivers = fromJS(action.drivers);
            return state.set('drivers', drivers).set('loadingDriver', false);
        }
        case GET_DRIVERS_ERROR: {
            return state.set('driversError', true).set('loadingDriver', false);
        }
        case LOADING_DRIVERS: {
            return state.set('loadingDriver', true);
        }
        case DISMISS_DRIVER_ERROR: {
            return state.set('driversError', false).set('loadingDriver', false);
        }
        case DISMISS_DRIVER_LOADING: {
            return state.set('loadingDriver', false);
        }
        default:
            return state;
    }
}

export default driverServiceReducer;
