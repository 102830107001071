// @flow

import adminRequest from 'services/adminRequest';
import { fromJS } from 'immutable';

/**
 *
 * @param {string} token
 */
export function retrieveUsers(token: string): Promise<> {
    const url = `users?includeBlocked=true&pageSize=50`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveUsersWithQuery(query, pageNumber, token: string): Promise<> {
    const url = `users/searchAllUsers?query=${query}&pageSize=50&pageNumber=${pageNumber}`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveNextUsersPage(nextLink, token: string): Promise<> {
    const url = nextLink;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function performDeleteUser(data, token: string, branchid): Promise<> {
    const url = `users/${data.id}`;
    return adminRequest(
        url,
        {
            method: 'DELETE',
        },
        true,
        token
    );
}
export function updateUserPassword(data, token: string, branchid): Promise<> {
    const url = `users/${data.userId}/password`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data: { branchId: branchid, password: data.password },
        },
        true,
        token
    );
}

export function patchUser(data, token: string, branchid): Promise<> {
    const url = `users/${data.id}`;
    return adminRequest(
        url,
        {
            method: 'PATCH',
            data: {
                branchId: branchid,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                isBlocked: data.isBlocked,
                comments: data.comments != null ? data.comments : '',
                lang: data.lang,
                units: data.units,
                groups: data.groups != null ? data.groups : [],
                updateMetadata: data.updateMetadata,
            },
        },
        false,
        token
    );
}

export function sendEmailVerification(data, token: string, branchid): Promise<> {
    const url = `users/${data.id}/email`;
    return adminRequest(
        url,
        {
            method: 'POST',
            data: {
                branchId: branchid,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                isBlocked: data.isBlocked,
            },
        },
        false,
        token
    );
}

export function getRoles(token: string): Promise<> {
    const url = `roles`;
    return adminRequest(
        url,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function updateUserRoles(userId, data, methode, token: string): Promise<> {
    const url = `users/${userId}/roles`;

    return adminRequest(
        url,
        {
            method: methode,
            data: { roles: data },
        },
        false,
        token
    );
}

export function createNewUser(data, token: string): Promise<> {
    const url = `users`;

    return adminRequest(
        url,
        {
            method: 'POST',
            data,
        },
        false,
        token
    );
}

export function getUserById(userId, token: string): Promise<> {
    const url = `users/${userId}`;

    return adminRequest(
        url,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function configLangUnit(
    userId: number,
    data: UserDataType,
    token: string,
    locale: string,
): Promise<> {
    return adminRequest(
        `users/${userId}/config`,
        {
            method: 'PATCH',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}
export function getLangUnit(
    userId: number,
    token: string,
): Promise<> {
    return adminRequest(
        `users/${userId}/config`,
        {
            method: 'GET',
        },
        true,
        token
    );
}