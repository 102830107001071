/* eslint-disable flowtype/require-parameter-type */
/* eslint-disable flowtype-errors/show-errors */
// @flow

import { createSelector } from 'reselect';
import { fromJS, toJS } from 'immutable';

/**
 * Direct selector to the organization state domain
 */
const selectUsersState = () => (state) => state.get('users');
const selectUsersLink = () => (state) => state.get('users').get('links');

const selectUsers = () =>
    createSelector(selectUsersState(), (substate) => {
        const usersList = substate.get('users').get('users');
        if (usersList) {
            return usersList.map((user) => {
                return {
                    name: `${user.get('firstName')} ${user.get('lastName')}`,
                    firstName: user.get('firstName'),
                    lastName: user.get('lastName'),
                    id: user.get('id'),
                    key: user.get('id'),
                    email: user.get('email'),
                    emailVerified: user.get('isEmailVerified'),
                    emailVerifiedText: `${user.get('email')}_${user.get('isEmailVerified')}`,
                    roles: user.get('roles').map((role) => role.get('name')),
                    comments: user.get('comments'),
                    isBlocked: user.get('isBlocked'),
                    isBranchOwner: user.get('isBranchOwner'),
                    groups: user.get('groups'),
                    lastLogin: user.get('lastLogin')
                        ? new Date(user.get('lastLogin')).toLocaleString('sv-SE')
                        : '',
                };
            });
        }
    });

const selectQueryUsers = () =>
    createSelector(selectUsersState(), (substate) => {
        const usersList = substate.get('users');
        if (usersList) {
            return usersList.map((user) => {
                return fromJS({
                    name: `${user.get('firstName')} ${user.get('lastName')}`,
                    firstName: user.get('firstName'),
                    lastName: user.get('lastName'),
                    id: user.get('organizationId'),
                    key: user.get('id'),
                    email: user.get('email'),
                    branchId: user.get('organizationId'),
                    branchName: user.get('organizationName')
                });
            });
        }
    });

const selectNextLink = () =>
    createSelector(selectUsersState(), (substate) => {
        if (substate.get('users').size > 0) {
            let nextLink = '';
            const links = substate
                .get('users')
                .get('links')
                .toMap();
            // if (links.get(0).get('rel') === 'next') {
            links.forEach((link) => {
                if (link.get('rel') === 'next') {
                    nextLink = link.get('href');
                }
            });
            return nextLink;
        }
    });
const selectUsersLoading = () =>
    createSelector(selectUsersState(), (substate) => substate.get('loading'));

const selectInputLocked = () =>
    createSelector(selectUsersState(), (substate) => substate.get('inputLocked'));

const selectUsersError = () =>
    createSelector(selectUsersState(), (substate) => substate.get('usersError'));

const selectShowError = () =>
    createSelector(selectUsersState(), (substate) => substate.get('showError'));

const selectUserLoading = () =>
    createSelector(selectUsersState(), (substate) => substate.get('userLoading'));

const selectRoles = () =>
    createSelector(selectUsersState(), (substate) => substate.get('userRoles'));

const selectCreatedUserId = () =>
    createSelector(selectUsersState(), (substate) => substate.get('createdUserId'));

export {
    selectUsersState,
    selectUsersLoading,
    selectUsersError,
    selectUsers,
    selectNextLink,
    selectShowError,
    selectUserLoading,
    selectRoles,
    selectCreatedUserId,
    selectInputLocked,
    selectQueryUsers,
};
