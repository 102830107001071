// @flow

import React, { type Node, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import dateFns from 'date-fns';
import moment from 'moment';

import { Split } from 'styles/common';
import colors from 'styles/colors';
import equal from 'fast-deep-equal';
import { Table, Spin, Space, Tag, Tooltip as AntdTooltip, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import Switch from 'react-switch';
import { jsPDF } from 'jspdf';

import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import styled, { createGlobalStyle } from 'styled-components';
import { isImperial, i18nDistanceUnit, setTextunit, changeUnit } from 'services/Units/thunks';

import {
    ReportGeneratorContainer,
    DrivingReportGenerator,
    InputLabel,
    Report,
    GeofencingHistoryGenerator,
} from 'styles/reports';

import { OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';

import ReportDataTable from 'containers/ReportDataTableMultipleHistory';

import GenerateReportHeader from 'containers/ReportGenerator/Header';

import ReportVehicleSearch from 'components/ReportVehicleSearch';
import { Dashboard } from 'styles/dashboard';
import FormInputTime from 'components/FormInputTime';
import FormInputDate from 'components/FormInputDate';

import Loader from 'components/Loader';

import Error from 'components/Error';
import Chip from 'components/Chip';
import SelectField from 'components/SelectField';

import MagnifyingGlass from 'assets/search-icon-white.svg';
import ReportIcon from 'assets/report-pen.svg';
import Calendar from 'assets/calendar-black.svg';
import { HeaderText } from './styles';
import { css } from 'emotion';

import { Excel } from 'antd-table-saveas-excel';
import {
    validateMoveReportGenerator,
    getLocations,
    clearMultipleHistoryReport,
    removeMoveReportError,
} from 'services/Report/thunks';
import {
    selectMoveReportGenerationErrors,
    selectIsLoading,
    selectMultipleHistoryRecords,
} from 'services/Report/selectors';

import { selectTemperatureVehicles, selectVehiclesLoading } from 'services/Vehicle/selectors';
import { searchInPolygons } from 'services/Geofence/thunks';

import {
    OUTPUT_FORMATS,
    REPORT_TYPES,
    PRESET_PERIODS,
    OUTPUT_TYPE,
} from 'services/Report/constants';

import AutoComplete from 'components/AutoComplete';
import ReportsInputDate from "../../../components/ReportsInputDate";

const today = dateFns.format(new Date(), 'YYYY-MM-DD');

let stop = false;
const calledLinks = new Map();
const body = css({
    '  .ant-table table': {
        width: '100%',
        textAlign: 'left',
        borderRadius: '2px 2px 0 0',
        borderCollapse: 'separate',
        borderSpacing: '0px',
        border: 'groove',
    },
    ' tbody tr:nth-child(odd)': {
        backgroundColor: '#bbe2b2',
    },
    ' tbody tr:nth-child(even)': {
        backgroundColor: '#cbeab0',
    },
    '.ant-table-thead > tr > th': {
        color: 'rgba(0, 0, 0, 0.85)',
        fontWeight: '500',
        textAlign: 'left',
        background: '#fafafa',
        borderBottom: '1px solid #f0f0f0',
        transition: 'background 0.3s ease',
        backgroundColor: '#5ca033',
    },
    '.ant-table-pagination.ant-pagination': {
        padding: '0px 0px 40px',
    },
});
type Props = {
    clearMultipleHistoryReport: *,
};

type State = {
    equipmentIds: Array<number>,
    periodFrom: string,
    periodTo: string,
    period: string,
    dateLimit:boolean,
    dateLimitPositionLeft: boolean,
    reportGeneratedFilterOpen: boolean,
    format: string,
    periodTimeFrom: string,
    periodTimeTo: string,
    reportData: *,
    dataForExcel: *,
};
export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    state = {
        equipmentIds: [],
        periodFrom: '',
        periodTo: '',
        period: PRESET_PERIODS.custom,
        dateLimit: false,
        dateLimitPositionLeft: true,
        reportGeneratedFilterOpen: false,
        format: OUTPUT_FORMATS.page,
        periodTimeFrom: '',
        periodTimeTo: '',
        reportData: new Map(),
        showAll: false,
        dataForExcel: [],
    };

    componentDidMount = () => {
        this.setState({ periodFrom: today, periodTo: today, reportGeneratedFilterOpen: false });
        stop = false;
        this.props.clearMultipleHistoryReport();
        calledLinks.clear();
    };

    /**
     */
    componentDidUpdate(prevProps) {
        if (!this.isReportPropsEqual(this.props.report, prevProps.report) && !stop) {
            console.log('update');

            const data = new Map();
            let dataExcel = [];
            this.props.report.forEach((r, vehicleId) => {
                const vehicleName = this.props.equipment
                    .filter((equipment) => equipment.get('id') === vehicleId)
                    .getIn([0, 'name']);
                const locations = [];
                r.locations.forEach((l, idx) => {
                    if (l.eventType === 'Ping') {
                        let addressIcon = (
                            <i className="fas fa-map-marker-alt" style={{ fontSize: '13px' }} />
                        );
                        const geoNames = this.props.searchInPolygons(l.longitude, l.latitude);
                        const { address, city, country, province } = l.address;
                        let fullAddress = l.address.address
                            ? `${address}, ${city}, ${province}, ${country}`
                            : '';
                        if (geoNames.length > 0) {
                            fullAddress = '';
                            addressIcon = <i className="fas fa-draw-polygon" />;
                            for (let i = 0; i < geoNames.length; i++) {
                                if (i < geoNames.length - 1) {
                                    fullAddress += `${geoNames[i]}, `;
                                } else {
                                    fullAddress += geoNames[i];
                                }
                            }
                        }

                        console.log(fullAddress);
                        // 223 DF  128 NC
                        // -273.15 nc  min -157.39447 max 136.1766

                        locations.push({
                            key: idx,
                            datetime: this.handleConvertUTC(l.datetime),
                            distance: this.props.i18nDistanceUnit(
                                parseFloat(l.distance.toString().replace(/\D/g, '')) /
                                    (this.props.isImperial() ? 1609.344 : 1000.0)
                            ),
                            speed:
                                parseFloat(l.speed.toString().replace(/\D/g, '')) /
                                (this.props.isImperial() ? 100.0 : 1),
                            eventType: l.eventType,
                            temp1: this.handleAnalogicTemperature(l.temperatures[0].valueCelsius),
                            temp2: this.handleAnalogicTemperature(l.temperatures[1].valueCelsius),
                            temp3: this.handleAnalogicTemperature(l.temperatures[2].valueCelsius),
                            ...(l.rs232
                                ? {
                                      rs232_1: this.handleAnalogicTemperature(
                                          l.rs232[0].valueCelsius
                                      ),
                                      rs232_2: this.handleAnalogicTemperature(
                                          l.rs232[1].valueCelsius
                                      ),
                                      rs232_3: this.handleAnalogicTemperature(
                                          l.rs232[2].valueCelsius
                                      ),
                                      rs232_4: this.handleAnalogicTemperature(
                                          l.rs232[3].valueCelsius
                                      ),
                                  }
                                : {}),
                            temp1Min: l.aux2TempMin,
                            temp1Max: l.aux2TempMax,
                            temp2Min: l.aux3TempMin,
                            temp2Max: l.aux3TempMax,
                            temp3Min: l.aux4TempMin,
                            temp3Max: l.aux4TempMax,
                            rs232Min: l.rs232TempMin,
                            rs232Max: l.rs232TempMax,
                            vehicleName,
                            locationForExcel: `${fullAddress} ${l.latitude},${l.latitude}`,
                            location:
                                fullAddress.length > 0 ? (
                                    <span>
                                        {addressIcon} {fullAddress}
                                        <br />
                                        <small
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                            }}
                                        >
                                            {l.latitude}, {l.longitude}
                                        </small>
                                    </span>
                                ) : (
                                    <span>
                                        <small
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                            }}
                                        >
                                            {l.latitude}, {l.longitude}
                                        </small>
                                    </span>
                                ),
                        });
                    }
                });
                const object = {
                    vehicleName,
                    finished: r.finished,
                    locations,
                    aux2TempActive: r.aux2TempActive,
                    aux3TempActive: r.aux3TempActive,
                    aux4TempActive: r.aux4TempActive,
                    rs232Active: r.rs232Active,
                    hasAux2Temp: r.hasAux2Temp,
                    hasAux3Temp: r.hasAux3Temp,
                    hasAux4Temp: r.hasAux4Temp,
                    hasRS232Temp1: r.hasRS232Temp1,
                    hasRS232Temp2: r.hasRS232Temp2,
                    hasRS232Temp3: r.hasRS232Temp3,
                    hasRS232Temp4: r.hasRS232Temp4,
                };
                data.set(vehicleId, object);
                dataExcel = dataExcel.concat(locations);
                if (r.nextUrl && !calledLinks.has(r.nextUrl)) {
                    calledLinks.set(r.nextUrl, r.nextUrl);
                    this.props.getLocations(r.nextUrl);
                }
            });

            this.setState({
                reportData: data,
                dataForExcel: dataExcel,
            });
        }
    }

    handleAnalogicTemperature = (temp) => {
        if (temp === -273.15) {
            return 'NC'; // not connected;
        } else if (temp < -157.39447 || temp > 136.1766) {
            return 'DF';
        } else {
            return this.props.isImperial() ? this.handleConvertCelsiusToFahrenheit(temp) : temp;
        }
    };

    handleRs232Temperature = (temp) => {
        if (temp === 223) {
            return 'NC'; // not connected;
        } else if (temp === 128) {
            return 'DF';
        } else {
            return this.props.isImperial() ? this.handleConvertCelsiusToFahrenheit(temp) : temp;
        }
    };

    handleConvertCelsiusToFahrenheit = (celsius) => {
        if (celsius) {
            return ((celsius * 9) / 5 + 32).toFixed(2);
        }
        return celsius;
    };

    handleConvertUTC = (date) => {
        const convertedDate = moment(date)
            .local()
            .format('YYYY-MM-DD HH:mm:ss A');
        return convertedDate;
        // return date;
    };

    getExcelColumns = () => {
        return [
            {
                title: 'Vehicle Name',
                dataIndex: 'vehicleName',
                key: 'vehicleName',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.dataMenu.date',
                }),
                dataIndex: 'datetime',
                key: 'datetime',
            },
            {
                title: 'Position',
                key: 'locationForExcel',
                dataIndex: 'locationForExcel',
            },
            {
                title: `${this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.dataMenu.distance',
                })} ${this.props.isImperial() ? 'miles' : 'km'}`,
                key: 'distance',
                dataIndex: 'distance',
            },
            {
                title: ` ${this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.dataMenu.speed',
                })} ${this.props.isImperial() ? 'miles/h' : 'km/h'}`,
                key: 'speed',
                dataIndex: 'speed',
            },
            {
                title: `Aux2 ${this.props.isImperial() ? '°F' : '°C'}`,
                key: 'temp1',
                dataIndex: 'temp1',
            },
            {
                title: `Aux3 ${this.props.isImperial() ? '°F' : '°C'}`,
                key: 'temp2',
                dataIndex: 'temp2',
            },
            {
                title: `Aux4 ${this.props.isImperial() ? '°F' : '°C'}`,
                key: 'temp3',
                dataIndex: 'temp3',
            },
            {
                title: `RS232 T1 ${this.props.isImperial() ? '°F' : '°C'}`,
                key: 'rs232_1',
                dataIndex: 'rs232_1',
            },
            {
                title: `RS232 T2 ${this.props.isImperial() ? '°F' : '°C'}`,
                key: 'rs232_2',
                dataIndex: 'rs232_2',
            },
            {
                title: `RS232 T3 ${this.props.isImperial() ? '°F' : '°C'}`,
                key: 'rs232_3',
                dataIndex: 'rs232_3',
            },
            {
                title: `RS232 T4 ${this.props.isImperial() ? '°F' : '°C'}`,
                key: 'rs232_4',
                dataIndex: 'rs232_4',
            },
        ];
    };

    getColumns = (record) => {
        const columns = [
            {
                title: this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.dataMenu.date',
                }),
                dataIndex: 'datetime',
                key: 'datetime',
            },
            {
                title: 'Position',
                dataIndex: 'location',
                key: 'location',
            },
            {
                title: `${this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.dataMenu.distance',
                })} ${this.props.isImperial() ? 'miles' : 'km'}`,
                key: 'distance',
                dataIndex: 'distance',
            },
            {
                title: ` ${this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.dataMenu.speed',
                })} ${this.props.isImperial() ? 'miles/h' : 'km/h'}`,
                key: 'speed',
                dataIndex: 'speed',
            },
            ...(record.aux2TempActive === true && record.hasAux2Temp
                ? [
                      {
                          title: `Aux2 ${this.props.isImperial() ? '°F' : '°C'}`,
                          key: 'temp1',
                          dataIndex: 'temp1',
                          render: (text, record) =>
                              (parseInt(record.temp1Max) !== 0 ||
                                  parseInt(record.temp1Min) !== 0) &&
                              (parseInt(text) > parseInt(record.temp1Max) ||
                                  parseInt(text) < parseInt(record.temp1Min)) ? (
                                  <AntdTooltip
                                      placement="bottom"
                                      title={`[min: ${record.temp1Min} , max:${record.temp1Max}]`}
                                  >
                                      <Tag color="magenta">{text}</Tag> <InfoCircleOutlined />
                                  </AntdTooltip>
                              ) : (
                                  <div>{text}</div>
                              ),
                      },
                  ]
                : []),
            ...(record.aux3TempActive === true && record.hasAux3Temp
                ? [
                      {
                          title: `Aux3 ${this.props.isImperial() ? '°F' : '°C'}`,
                          key: 'temp2',
                          dataIndex: 'temp2',
                          render: (text, record) =>
                              (parseInt(record.temp2Max) !== 0 ||
                                  parseInt(record.temp2Min) !== 0) &&
                              (parseInt(text) > parseInt(record.temp2Max) ||
                                  parseInt(text) < parseInt(record.temp2Min)) ? (
                                  <AntdTooltip
                                      placement="bottom"
                                      title={`[min: ${record.temp2Min} , max:${record.temp2Max}]`}
                                  >
                                      <Tag color="magenta">{text}</Tag> <InfoCircleOutlined />
                                  </AntdTooltip>
                              ) : (
                                  <div>{text}</div>
                              ),
                      },
                  ]
                : []),
            ...(record.aux4TempActive === true && record.hasAux4Temp
                ? [
                      {
                          title: `Aux4 ${this.props.isImperial() ? '°F' : '°C'}`,
                          key: 'temp3',
                          dataIndex: 'temp3',
                          render: (text, record) =>
                              (parseInt(record.temp3Max) !== 0 ||
                                  parseInt(record.temp3Min) !== 0) &&
                              (parseInt(text) > parseInt(record.temp3Max) ||
                                  parseInt(text) < parseInt(record.temp3Min)) ? (
                                  <AntdTooltip
                                      placement="bottom"
                                      title={`[min: ${record.temp3Min} , max:${record.temp3Max}]`}
                                  >
                                      <Tag color="magenta">{text}</Tag> <InfoCircleOutlined />
                                  </AntdTooltip>
                              ) : (
                                  <div>{text}</div>
                              ),
                      },
                  ]
                : []),
            ...(record.rs232Active === true && record.hasRS232Temp1
                ? [
                      {
                          title: `RS232 T1 ${this.props.isImperial() ? '°F' : '°C'}`,
                          key: 'rs232_1',
                          dataIndex: 'rs232_1',
                          render: (text, record) =>
                              (parseInt(record.temp1Max) !== 0 ||
                                  parseInt(record.temp1Min) !== 0) &&
                              (parseInt(text) > parseInt(record.temp1Max) ||
                                  parseInt(text) < parseInt(record.temp1Min)) ? (
                                  <AntdTooltip
                                      placement="bottom"
                                      title={`[min: ${record.temp1Min} , max:${record.temp1Max}]`}
                                  >
                                      <Tag color="magenta">{text}</Tag> <InfoCircleOutlined />
                                  </AntdTooltip>
                              ) : (
                                  <div>{text}</div>
                              ),
                      },
                  ]
                : []),
            ...(record.rs232Active === true && record.hasRS232Temp2
                ? [
                      {
                          title: `RS232 T2 ${this.props.isImperial() ? '°F' : '°C'}`,
                          key: 'rs232_2',
                          dataIndex: 'rs232_2',
                          render: (text, record) =>
                              (parseInt(record.temp2Max) !== 0 ||
                                  parseInt(record.temp2Min) !== 0) &&
                              (parseInt(text) > parseInt(record.temp2Max) ||
                                  parseInt(text) < parseInt(record.temp2Min)) ? (
                                  <AntdTooltip
                                      placement="bottom"
                                      title={`[min: ${record.temp2Min} , max:${record.temp2Max}]`}
                                  >
                                      <Tag color="magenta">{text}</Tag> <InfoCircleOutlined />
                                  </AntdTooltip>
                              ) : (
                                  <div>{text}</div>
                              ),
                      },
                  ]
                : []),
            ...(record.rs232Active === true && record.hasRS232Temp3
                ? [
                      {
                          title: `RS232 T3 ${this.props.isImperial() ? '°F' : '°C'}`,
                          key: 'rs232_3',
                          dataIndex: 'rs232_3',
                          render: (text, record) =>
                              (parseInt(record.temp3Max) !== 0 ||
                                  parseInt(record.temp3Min) !== 0) &&
                              (parseInt(text) > parseInt(record.temp3Max) ||
                                  parseInt(text) < parseInt(record.temp3Min)) ? (
                                  <AntdTooltip
                                      placement="bottom"
                                      title={`[min: ${record.temp3Min} , max:${record.temp3Max}]`}
                                  >
                                      <Tag color="magenta">{text}</Tag> <InfoCircleOutlined />
                                  </AntdTooltip>
                              ) : (
                                  <div>{text}</div>
                              ),
                      },
                  ]
                : []),
            ...(record.rs232Active === true && record.hasRS232Temp4
                ? [
                      {
                          title: `RS232 T4 ${this.props.isImperial() ? '°F' : '°C'}`,
                          key: 'rs232_4',
                          dataIndex: 'rs232_4',
                          render: (text, record) =>
                              (parseInt(record.rs232TempMax) !== 0 ||
                                  parseInt(record.rs232TempMin) !== 0) &&
                              (parseInt(text) > parseInt(record.rs232TempMax) ||
                                  parseInt(text) < parseInt(record.rs232TempMin)) ? (
                                  <AntdTooltip
                                      placement="bottom"
                                      title={`[min: ${record.rs232TempMin} , max:${
                                          record.rs232TempMax
                                      }]`}
                                  >
                                      <Tag color="magenta">{text}</Tag> <InfoCircleOutlined />
                                  </AntdTooltip>
                              ) : (
                                  <div>{text}</div>
                              ),
                      },
                  ]
                : []),
        ];
        return columns;
    };

    toggleDisplay = (checked) => {
        this.setState({ showAll: checked });
    };

    isReportPropsEqual = (map1, map2) => {
        let result = true;
        if (!map1 && !map2) {
            return true;
        }
        if (map1 && !map2) {
            return false;
        }
        if (map1.size !== map2.size) {
            return false;
        }
        map1.forEach((m, idx) => {
            if (m.locations.length !== map2.get(idx).locations.length) {
                result = false;
            }
        });
        return result;
    };

    generatePdf = () => {
        const result = this.state.report;
        const doc = new jsPDF({ putOnlyUsedFonts: true });
        /*  const title = this.props.intl
            .formatMessage({ id: 'components.HistoryDetail.title' })
            .toString();
        const generateAt = this.props.intl
            .formatMessage({ id: 'components.HistoryDetail.pdf.generate' })
            .toString();
        let totalDistance = 0;

        const bodyContent = [
            ...result,
            [
                {
                    content: `Total`,
                },
                { content: '' },
                { content: '' },
                { content: '' },
                { content: '' },
                { content: this.props.setTextunit(totalDistance / 1000, 'distance') },
            ],
        ];

        doc.setFont(undefined, 'bold');
        doc.text(`${title} - title`, 60, 27);
        doc.setFontSize(8);
        doc.text(generateAt + new moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 150, 10);
        doc.addImage(Logo, 'png', 12, 8);
        doc.autoTable({
            theme: 'grid',
            margin: { top: 35 },
            headStyles: { textColor: 'white', fillColor: '#63AC37' },
            bodyStyles: { textColor: 'black', fillColor: 'white' },
            body: result, //bodyContent,
            columns,
        });*/
        // handlePDFFooterDisplay(doc);
        /*     const headers = [
            { key: 'title', label: 'Title' },
            { key: 'release_date', label: 'Release' },
            { key: 'overview', label: 'Overview' },
            { key: 'vote_average', label: 'Vote Average' },
        ];

        // Here's the call for our pdf function
        // Because of the placeholder code in our pdf.js file,
        // clicking on this should render a save dialog
        // and downloading should render an empty pdf
        // will save the file in the current working directory
        doc.save(
            `${'fileName' +
                this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.pdf.fileName',
                })}.pdf`
        );
    */
        alert('coming soon!');
    };

    handleOnCheckVehicles = (id: number) => {
        const equipmentIds = this.state.equipmentIds.slice();
        if (equipmentIds.indexOf(id) >= 0) {
            equipmentIds.splice(equipmentIds.indexOf(id), 1);
        } else {
            equipmentIds.push(id);
        }
        if (equipmentIds.length > 0 && this.props.errors.get('equipmentIds')) {
            this.props.removeMoveReportError('equipmentIds');
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnChangePeriod = (key: string) => (event: Event) => {
        this.changePeriod(key, event.target.value);
        this.props.removeMoveReportError(key);
    };

    handleGenerateReport = () => {
        stop = false;
        this.props.clearMultipleHistoryReport();
        calledLinks.clear();
        this.state.equipmentIds.forEach((id) => {
            if (id > 2147483647) {
                return;
            }
            const data = {
                periodFrom: this.state.periodFrom,
                periodTo: this.state.periodTo,
                period: this.state.period,
                periodTimeFrom: this.state.periodTimeFrom,
                periodTimeTo: this.state.periodTimeTo,
            };
            this.props.getLocations(null, id, data, 1);
        });
        if (this.state.reportGeneratedFilterOpen) {
            this.setState({
                reportGeneratedFilterOpen: false,
            });
        }
    };

    changePeriod = (key: string, date: string) => {
        if(this.state.dateLimit){
            this.setState({
                dateLimit:false,
            });
        };
        if( (key==="periodFrom")
         && (dateFns.differenceInDays(this.state.periodTo,date) > 366) ){
            const newDateTo= dateFns.addDays(date,366);
            const formattedNewDate= dateFns.format(newDateTo,'YYYY-MM-DD');

        this.setState({
            dateLimit:true,
            periodTo:formattedNewDate,
            dateLimitPositionLeft:true,
        })
         } else if((key==="periodTo")
              && (dateFns.differenceInDays(date, this.state.periodFrom) > 366)){
                const newDateFrom= dateFns.subDays(date,366);
                const formattedNewDateFrom= dateFns.format(newDateFrom,'YYYY-MM-DD');
                this.setState({
                  dateLimit:true,
                  periodFrom:formattedNewDateFrom,
                  dateLimitPositionLeft:false,
               })
          };
        let dateToChange=date
        const dateCompare = 
        (key =="periodFrom")
        ? dateFns.compareAsc(date,this.state.periodTo)
        : dateFns.compareAsc(this.state.periodFrom,date);
        const todayCompare = dateFns.compareAsc(date,today);
        if(todayCompare===1){dateToChange=today}
         if (dateCompare === 1){
            this.setState(() => ({
           [(key=="periodFrom")
           ? "periodTo"
           :"periodFrom"
           ]:dateToChange,
            }))
        };
        this.setState((prevState) => ({
            [key]: dateToChange,
            period: PRESET_PERIODS.custom,
        }));
    };

    handleSetPeriod = (presetPeriod: string) => () => {
        const currentDate = new Date();
        const period = {};
        this.setState({
            dateLimit:false,
        });
        switch (presetPeriod) {
            case PRESET_PERIODS.today: {
                period.to = dateFns.startOfToday();
                period.from = currentDate;
                break;
            }
            case PRESET_PERIODS.yesterday: {
                const yesterday = dateFns.subDays(currentDate, 1);
                period.from = dateFns.startOfDay(yesterday);
                period.to = dateFns.endOfDay(yesterday);
                break;
            }
            case PRESET_PERIODS.lastWeek: {
                const lastWeek = dateFns.subWeeks(currentDate, 1);
                period.from = dateFns.startOfWeek(lastWeek);
                period.to = dateFns.endOfWeek(lastWeek);
                break;
            }
            case PRESET_PERIODS.lastMonth: {
                const lastMonth = dateFns.subMonths(currentDate, 1);
                period.from = dateFns.startOfMonth(lastMonth);
                period.to = dateFns.endOfMonth(lastMonth);
                break;
            }
            case PRESET_PERIODS.thisMonth: {
                period.from = dateFns.startOfMonth(currentDate);
                period.to = dateFns.endOfMonth(currentDate);
                if(dateFns.compareAsc(today,period.to) < 0){
                    period.to = today
                 };
                break;
            }
            case PRESET_PERIODS.thisWeek: {
                period.from = dateFns.startOfWeek(currentDate);
                period.to = dateFns.endOfWeek(currentDate);
                if(dateFns.compareAsc(today,period.to) < 0){
                    period.to = today
                 };
                break;
            }
            default: {
                period.from = '';
                period.to = '';
            }
        }
        if (this.props.errors.get('periodFrom')) {
            this.props.removeMoveReportError('periodFrom');
        }

        if (this.props.errors.get('periodTo')) {
            this.props.removeMoveReportError('periodTo');
        }

        this.setState({
            periodTo: dateFns.format(period.to, 'YYYY-MM-DD'),
            periodFrom: dateFns.format(period.from, 'YYYY-MM-DD'),
            period: presetPeriod,
        });
    };

    handleOnClickModifyReport = () => {
        stop = true;
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        source.cancel('Operation canceled by the user.');

        this.setState((prevState) => ({
            reportGeneratedFilterOpen: !prevState.reportGeneratedFilterOpen,
        }));
    };

    handleOnExportPDF = () => {
        this.generatePdf();
    };

    handleOnExportXLS = () => {
        const excel = new Excel();
        excel
            .addSheet(`temperatures`)
            .addColumns(this.getExcelColumns())
            .addDataSource(this.state.dataForExcel)
            .saveAs(`temperatures.xlsx`);
    };

    handleOnSelectAllVehicles = (searchedEquipments) => {
        let equipmentIds;
        if(searchedEquipments.toArray().length > 0) {
           equipmentIds = searchedEquipments.map((equipment) => equipment.get('id')).toArray();
        }else{
           equipmentIds = this.props.equipment.filter(eq=> {
            if(eq.toJS().cardNumber){
             return !eq.toJS().cardNumber.startsWith('B')
            }else{
                return true
            }
           }).map((equipment) => equipment.get('id')).toArray();
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnUnselectAllVehicles = () => {
        const equipmentIds = [];
        this.setState({
            equipmentIds,
        });
    };

    onChangeSelect = (key: string) => (value: string) => {
        this.setState({ [key]: value });
    };

    /**
     *
     */
    handleOnChangePeriodFrom = (field) => (event) => {
        const time = event.target.value;
        const timeCompare =  
        dateFns.compareAsc(this.state.periodTo,this.state.periodFrom);   
        if (field === 'periodTimeFrom') {
            if(timeCompare === 0)
            {
                if(time > this.state.periodTimeTo){
                    this.setState({
                    periodTimeTo: time,
                    });
                }
            }
        } else {
            if(timeCompare === 0)
            {
                if(time < this.state.periodTimeFrom){
                    this.setState({
                    periodTimeFrom: time,
                    });
                }
            }
        };
    
        this.setState({ 
            [field]: time,
        });
        
    };

    // To be refactored...
    renderGenerator = () => {
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        return (
            <div>
                <GeofencingHistoryGenerator>
                    <div className={'geofenceReport'}>
                        <ReportsInputDate
                            startDate={this.state.periodFrom}
                            onChangeStartDate={this.handleOnChangePeriod('periodFrom')}
                            errorsStartDate={this.props.errors.get('periodFrom')}
                            endDate={this.state.periodTo}
                            onChangeEndDate={this.handleOnChangePeriod('periodTo')}
                            errorEndDate={this.props.errors.get('periodTo')}
                            dateLimit={this.state.dateLimit}
                            dateLimitPositionLeft={this.state.dateLimitPositionLeft}
                        >
                        </ReportsInputDate>

                        <GeofencingHistoryGenerator.ColumnRep>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.OutputFormat'}
                                />
                            </InputLabel>
                            <GeofencingHistoryGenerator.SelectContainer>
                                <SelectField
                                    options={[
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Pdf',
                                            }),
                                            value: OUTPUT_FORMATS.pdf,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Xls',
                                            }),
                                            value: OUTPUT_FORMATS.xls,
                                        },
                                    ]}
                                    value={this.state.format}
                                    borderColor={colors.grayED}
                                    arrowColor={colors.green63}
                                    onChange={this.onChangeSelect('format')}
                                />
                            </GeofencingHistoryGenerator.SelectContainer>
                        </GeofencingHistoryGenerator.ColumnRep>
                    </div>
                    <GeofencingHistoryGenerator.ColumnRep>
                        <GeofencingHistoryGenerator.ButtonContainer>
                            <GeofencingHistoryGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.today)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.Today'}
                                />
                            </GeofencingHistoryGenerator.DateButton>
                            <GeofencingHistoryGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.yesterday)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.Yesterday'}
                                />
                            </GeofencingHistoryGenerator.DateButton>
                            <GeofencingHistoryGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.thisWeek)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.ThisWeek'}
                                />
                            </GeofencingHistoryGenerator.DateButton>
                            <GeofencingHistoryGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.lastWeek)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.LastWeek'}
                                />
                            </GeofencingHistoryGenerator.DateButton>
                            <GeofencingHistoryGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.thisMonth)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.ThisMonth'}
                                />
                            </GeofencingHistoryGenerator.DateButton>
                            <GeofencingHistoryGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.lastMonth)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.LastMonth'}
                                />
                            </GeofencingHistoryGenerator.DateButton>
                            <GeofencingHistoryGenerator.SubmitButton
                                onClick={this.handleGenerateReport}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.Generate'}
                                />
                            </GeofencingHistoryGenerator.SubmitButton>
                        </GeofencingHistoryGenerator.ButtonContainer>
                    </GeofencingHistoryGenerator.ColumnRep>

                    <div className={'geofenceReport'}>
                        <GeofencingHistoryGenerator.ColumnRep>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id="tooltip-right">
                                        <FormattedMessage
                                            id={'containers.Reports.Generators.Driving.timeshift.info'}
                                        />
                                    </Tooltip>
                                }
                            >
                                <InputLabel margin={'0 0 15px 0'}>
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.timeshift'}
                                    />{' '}
                                    <i className="fas fa-info-circle"/>{' '}
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.From'}
                                    />
                                </InputLabel>
                            </OverlayTrigger>
                            <FormInputTime
                                type={'time'}
                                value={this.state.periodTimeFrom}
                                onChange={this.handleOnChangePeriodFrom('periodTimeFrom')}
                                error={this.props.errors.get('periodTimeFrom')}
                                placeholderId={'views.MapPage.HistoryOptions.selectHour'}
                            />
                        </GeofencingHistoryGenerator.ColumnRep>
                        <GeofencingHistoryGenerator.ColumnRep>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage id={'containers.Reports.Generators.Driving.To'}/>
                            </InputLabel>
                            <FormInputTime
                                type={'time'}
                                onChange={this.handleOnChangePeriodFrom('periodTimeTo')}
                                error={this.props.errors.get('periodTimeTo')}
                                value={this.state.periodTimeTo}
                                placeholderId={'views.MapPage.HistoryOptions.selectHour'}
                            />
                        </GeofencingHistoryGenerator.ColumnRep>
                    </div>
                </GeofencingHistoryGenerator>
                <Split color={colors.splitGrayReports} opacity={0.2} />
                <InputLabel margin={'30px 0 20px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.Vehicles'} />
                </InputLabel>
                {this.props.errors.get('equipmentIds') && (
                    <GeofencingHistoryGenerator.Error>
                        <Error
                            text={this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.Driving.EquipmentIdsError',
                            })}
                        />
                    </GeofencingHistoryGenerator.Error>
                )}
                <ReportVehicleSearch
                    equipment={this.props.equipment.filter(eq=> {
                        if(eq.toJS().cardNumber){
                         return !eq.toJS().cardNumber.startsWith('B')
                        }else{
                            return true
                        }
                       })}
                    onCheckVehicles={this.handleOnCheckVehicles}
                    checkedInputs={this.state.equipmentIds}
                    onSelectAllVehicles={this.handleOnSelectAllVehicles}
                    onUnselectAllVehicles={this.handleOnUnselectAllVehicles}
                />
                <Split color={colors.splitGrayReports} opacity={0.2} />
            </div>
        );
    };

    renderReport = () => {
        const { reportData } = this.state;

        const tables = [];
        const reportTitle = this.props.intl.formatMessage({
            id: 'views.Reports.SideMenu.temperatureReport',
        });
        const periodetimeStart = this.state.periodTimeFrom;
        const periodetimeEnd = this.state.periodTimeTo;
        const reportFrom = dateFns.format(this.state.periodFrom, 'YYYY/MM/DD');
        const reportTo = dateFns.format(this.state.periodTo, 'YYYY/MM/DD');
        const reportFilterText = [];
        reportFilterText.date = `${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${reportFrom} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${reportTo}`;
        reportFilterText.time =
            periodetimeStart && periodetimeEnd
                ? `${this.props.intl.formatMessage({
                      id: 'containers.Reports.Generators.periode',
                  })} ${periodetimeStart} - ${periodetimeEnd}`
                : '';
        let i = 0;
        reportData.forEach((r, vehicleId) => {
            if (!this.state.showAll && r.locations.length > 0) {
                tables.push(
                    <TabPane
                        tab={
                            <span
                                style={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    fontFamily: 'OpenSans-SemiBold',
                                    color: '#63ac37',
                                    marginBottom: '28px',
                                }}
                            >{`${r.vehicleName}`}</span>
                        }
                        key={`${i}`}
                        tabBarExtraContent={<Spin />}
                        className={'sticky'}
                    >
                        <span>
                            <p>
                                {' '}
                                <Button
                                    style={{
                                        marginBottom: 20,
                                    }}
                                    onClick={() => {
                                        const excel = new Excel();
                                        excel
                                            .addSheet(`${r.vehicleName}`)
                                            .addColumns(this.getExcelColumns())
                                            .addDataSource(r.locations)
                                            .saveAs(`${r.vehicleName}.xlsx`);
                                    }}
                                >
                                    <FormattedMessage id={'containers.Reports.extractExcel'} />{' '}
                                </Button>
                                {!r.finished ? <Spin /> : null}
                            </p>
                            <Table
                                columns={this.getColumns(r)}
                                dataSource={r.locations}
                                className={body}
                                rowKey={vehicleId}
                                pageRowCount={50}
                            />
                        </span>
                    </TabPane>
                );
                i++;
            } else if (r.locations.length > 0) {
                tables.push(
                    <div>
                        <HeaderText>
                            {' '}
                            <Space size={8}>
                                {r.vehicleName}
                                {!r.finished ? <Spin /> : null}
                            </Space>
                        </HeaderText>
                        <Table
                            columns={this.getColumns(r)}
                            dataSource={r.locations}
                            className={body}
                            rowKey={vehicleId}
                        />
                    </div>
                );
            }
        });
        return (
            <Report.Header>
                <DrivingReportGenerator.ReportType style={{ marginTop: 15, marginBottom: 40 }}>
                    {!this.state.reportGeneratedFilterOpen && (
                        <React.Fragment>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={ReportIcon}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportTitle}
                            </Report.TextContainer>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={Calendar}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportFilterText.date}
                                <br />
                                {reportFilterText.time}
                            </Report.TextContainer>
                        </React.Fragment>
                    )}
                    <Report.ModifyReport onClick={this.handleOnClickModifyReport}>
                        <Report.Icon
                            src={MagnifyingGlass}
                            width={20}
                            height={20}
                            margin={'0 0 0 17px'}
                        />
                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                            <FormattedMessage id={'containers.Reports.Generators.Modify'} />
                        </p>
                    </Report.ModifyReport>
                </DrivingReportGenerator.ReportType>
                <SlideDown className={'generator'}>
                    {this.state.reportGeneratedFilterOpen ? this.renderGenerator() : null}
                </SlideDown>
                <p>
                    <FormattedMessage id={'containers.Reports.Generators.showAll'} />{' '}
                </p>
                <Switch
                    onChange={this.toggleDisplay}
                    checked={this.state.showAll}
                    height={22}
                    width={38}
                    handleDiameter={20}
                />
                {!this.state.showAll ? (
                    <Tabs defaultActiveKey="1" tabPosition="top">
                        {tables}
                    </Tabs>
                ) : (
                    tables
                )}
            </Report.Header>
        );
    };

    render() {
        return (
            <ReportGeneratorContainer>
                <GenerateReportHeader
                    report={this.props.report}
                    title={this.props.intl.formatMessage({
                        id: 'views.Reports.SideMenu.temperatureReport',
                    })}
                    /* onExportPDF={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportPDF : null
                    }*/
                    onExportXLS={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportXLS : null
                    }
                />
                <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} />
                {this.props.report && this.props.report.size > 0
                    ? this.renderReport()
                    : this.renderGenerator()}
            </ReportGeneratorContainer>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    errors: selectMoveReportGenerationErrors(),
    loading: selectIsLoading(),
    vehicleLoading: selectVehiclesLoading(),
    report: selectMultipleHistoryRecords(),
    equipment: selectTemperatureVehicles(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            validateMoveReportGenerator,
            getLocations,
            clearMultipleHistoryReport,
            removeMoveReportError,
            setTextunit,
            changeUnit,
            i18nDistanceUnit,
            isImperial,
            searchInPolygons,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
