// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import chunk from 'lodash/chunk';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import colors from 'styles/colors';

import Pagination from 'react-js-pagination';

import OutlineButton from 'components/OutlineButton';
import SelectField from 'components/SelectField';

import DashboardDataTable from 'components/DashboardDataTableMoveV1';

import { HeaderText, SelectContainer, TableWrapper, Table, SummaryTable, Button, SummaryHeaderText  } from './styles';

import type { ColumnType } from 'types';
import { selectMoveType } from 'services/Report/selectors';
type Props = {
    title: string,
    tableData: ImmutableList<any>,
    columns: Array<ColumnType>,
    tableFooter: ImmutableList<any>,
    reportFilterText: *,
};

type State = {
    itemsDisplayed: number,
    currentPage: number,
    tableData: ?ImmutableList<any>,
    tableFooter: ?ImmutableList<any>,
    sortKey: string,
};

const SELECT_VALUES = [10, 20, 30, 40, 50];

class ReportDataTable extends React.PureComponent<Props, State> {
    static defaultProps = {};

    state = {
        itemsDisplayed: 10,
        currentPage: 1,
        tableData: this.props.tableData,
        tableFooter: this.props.tableFooter,
        sortKey: '',
    };

    selectOptions = SELECT_VALUES.map((value) => ({
        label: this.props.intl
            .formatMessage({ id: 'containers.Reports.Generators.Driving.ShowResults' })
            .replace('$value', value),
        value,
    }));

    handleOnChangeSelect = (value: number) =>
        this.setState({ itemsDisplayed: value, currentPage: 1 });

    handleOnChangePage = (value: number) => {
        this.setState({ currentPage: value });
    };

    getDefaultSort = (previousKey: string, key: string) => {
        const ascSort = (a, b) => {
            if (a.get(key) < b.get(key)) {
                return -1;
            }

            if (a.get(key) > b.get(key)) {
                return 1;
            }

            if (a.get(key) === b.get(key)) {
                return 0;
            }
        };

        const descSort = (a, b) => {
            if (a.get(key) < b.get(key)) {
                return 1;
            }

            if (a.get(key) > b.get(key)) {
                return -1;
            }

            if (a.get(key) === b.get(key)) {
                return 0;
            }
        };

        return previousKey === key ? descSort : ascSort;
    };

    handleOnClickHeaderItem = (key: string, sortMethod: SortFunction = null) => () => {
        if (!this.state.tableData) {
            return;
        }

        const defaultSort = this.getDefaultSort(this.state.sortKey, key);

        const sort = sortMethod || defaultSort;

        const sortedData = this.state.tableData.sort(sort);
        this.setState({
            tableData: sortedData,
            sortKey: this.state.sortKey === key ? '' : key,
            currentPage: 1,
        });
    };

    getTableData = (data: ImmutableList<>) => {
        // slice up array into index's and
        if (!data || !data.size) {
            return null;
        }

        const { currentPage, itemsDisplayed } = this.state;

        const chunked = chunk(this.state.tableData.toArray(), itemsDisplayed);
        return fromJS(chunked[this.state.currentPage - 1]);
    };

    getFooterData = (data: ImmutableList<>) => (data && data.size && fromJS(data)) || null;

    renderPageButttons = () => {
        if (!this.state.tableData || !this.state.tableData.size) {
            return null;
        }

        return (
            <Button.Container>
                <Pagination
                    onChange={this.handleOnChangePage}
                    activePage={this.state.currentPage}
                    itemsCountPerPage={this.state.itemsDisplayed}
                    totalItemsCount={this.state.tableData.size}
                    pageRangeDisplayed={5}
                // disabledClass='disabled'
                // hideNavigation
                // hideDisabled
                // hideFirstLastPages
                />
            </Button.Container>
        );
    };

    render() {
        const { title, tableData, tableFooter } = this.props;
        const data = this.getTableData(tableData);
        const dataFooter = this.getFooterData(tableFooter);
        // console.log(dataFooter.toJS())
        return (
            this.props.moveReportSummary ?
                (<div>
                    <TableWrapper>
                    <SummaryHeaderText>{title}</SummaryHeaderText>
                        <SummaryTable>
                            <DashboardDataTable
                                vehicleId={this.props.vehicleId}
                                vehiculeNumb={this.props.title}
                                columns={this.props.columns}
                                tableData={data}
                                tableFooter={dataFooter}
                                totalItemsCount={this.state.tableData.size}
                                itemsDisplayed={this.state.itemsDisplayed}
                                showHeaderCaret={false}
                                onClickHeaderItem={this.handleOnClickHeaderItem}
                                report
                                reportFilterText={this.props.reportFilterText}
                            />
                        </SummaryTable>
                    </TableWrapper>
                </div>) :
                (<div>
                    <HeaderText>{title}</HeaderText>
                    <SelectContainer>
                        <SelectField
                            options={this.selectOptions}
                            value={this.state.itemsDisplayed}
                            maxWidth={200}
                            textColor={colors.black}
                            onChange={this.handleOnChangeSelect}
                        />
                    </SelectContainer>
                    <TableWrapper>
                        <Table>
                            <DashboardDataTable
                                vehicleId={this.props.vehicleId}
                                vehiculeNumb={this.props.title}
                                columns={this.props.columns}
                                tableData={data}
                                tableFooter={dataFooter}
                                totalItemsCount={this.state.tableData.size}
                                itemsDisplayed={this.state.itemsDisplayed}
                                showHeaderCaret={false}
                                onClickHeaderItem={this.handleOnClickHeaderItem}
                                report
                                reportFilterText={this.props.reportFilterText}
                            />
                        </Table>
                    </TableWrapper>
                    {this.state.tableData &&
                        this.state.tableData.size &&
                        this.state.tableData.size > 10 &&
                        this.renderPageButttons()}
                </div>)
        );
    }
}
const mapStateToProps: Object = createStructuredSelector({
    moveReportSummary: selectMoveType()

});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(injectIntl(ReportDataTable))
);
