// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { Excel } from 'antd-table-saveas-excel';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import moment from 'moment';
import dateFns from 'date-fns';

import { Split } from 'styles/common';
import colors from 'styles/colors';

import {
    ReportGeneratorContainer,
    DrivingReportGenerator,
    InputLabel,
    Report,
    GeofencingHistoryGenerator,
} from 'styles/reports';

import { OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';

import ReportDataTable from 'containers/ReportDataTableGeofencingHistory';

import GenerateReportHeader from 'containers/ReportGenerator/Header';

import ReportVehicleSearch from 'components/ReportVehicleSearch';
import { Dashboard } from 'styles/dashboard';
import FormInputTime from 'components/FormInputTime';
import FormInputDate from 'components/FormInputDate';

import Loader from 'components/Loader';

import Error from 'components/Error';
import Chip from 'components/Chip';
import SelectField from 'components/SelectField';

import MagnifyingGlass from 'assets/search-icon-white.svg';
import ReportIcon from 'assets/report-pen.svg';
import Calendar from 'assets/calendar-black.svg';

import {
    validateMoveReportGenerator,
    generateGeofencingHistoryReport,
    removeMoveReportError,
} from 'services/Report/thunks';
import {
    selectMoveReportGenerationErrors,
    selectIsLoading,
    selectGeofencingHistoryReport,
} from 'services/Report/selectors';

import { selectVehicles } from 'services/Vehicle/selectors';

import {
    OUTPUT_FORMATS,
    REPORT_TYPES,
    PRESET_PERIODS,
    OUTPUT_TYPE,
} from 'services/Report/constants';

import AutoComplete from 'components/AutoComplete';
import ReportsInputDate from "../../../components/ReportsInputDate";

const today = dateFns.format(new Date(), 'YYYY-MM-DD');

type Props = {};

type State = {
    equipmentIds: Array<number>,
    periodFrom: string,
    periodTo: string,
    period: string,
    dateLimit:boolean,
    dateLimitPositionLeft: boolean,
    reportGeneratedFilterOpen: boolean,
    format: string,
    type: string,
    position: Array<number>,
    periodTimeFrom: string,
    periodTimeTo: string,
};

let exportData = [];
export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    state = {
        equipmentIds: [],
        periodFrom: '',
        periodTo: '',
        dateLimit: false,
        dateLimitPositionLeft: true,
        period: PRESET_PERIODS.custom,
        reportGeneratedFilterOpen: false,
        format: OUTPUT_FORMATS.page,
        type: REPORT_TYPES.detailed,
        position: [],
        periodTimeFrom: '',
        periodTimeTo: '',
        outputType: OUTPUT_TYPE.vehicle,
    };

    componentDidMount = () => {
        this.setState({ periodFrom: today, periodTo: today });
    };

    componentDidUnMount = () => {
        exportData = [];
    };

    hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    secondsToHms = (d) => {
        if (d == 0) return 0;
        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);

        const hDisplay = h > 0 ? `${h} h, ` : '';
        const mDisplay = m > 0 ? `${m} m, ` : '';
        const sDisplay = s > 0 ? `${s} s` : '';
        return hDisplay + mDisplay + sDisplay;
    };

    getColumns = () => {
        const columns = [
            {
                title: 'Vehicule',
                dataIndex: 'vehicleNumb',
                key: 'vehicleNumb',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.geofenceName',
                }),
                dataIndex: 'geoName',
                key: 'geoName',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.entryDate',
                }),
                dataIndex: 'dateEntrance',
                key: 'dateEntrance',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.exitDate',
                }),
                dataIndex: 'dateExit',
                key: 'dateExit',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'components.Modals.Form.Config.GestionCond.DriverName',
                }),
                dataIndex: 'driverName',
                key: 'driverName',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.timeSpent',
                }),
                dataIndex: 'timeSpent',
                key: 'timeSpent',
            },
        ];
        return columns;
    };
    getHead = () => {
        const headPdf = [
            'Vehicule',
            this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.geofenceName',
            })
            ,
            this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.entryDate',
            })
            ,
            this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.exitDate',
            })
            ,
            this.props.intl.formatMessage({
                id: 'components.Modals.Form.Config.GestionCond.DriverName',
            })
            ,
            this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.timeSpent',
            })

        ];
        return headPdf;
    };
    intToARGB = (i) => {
        let hex =
            ((i >> 24) & 0xff).toString(16) +
            ((i >> 16) & 0xff).toString(16) +
            ((i >> 8) & 0xff).toString(16) +
            (i & 0xff).toString(16);
        hex += '000000';
        return hex.substring(0, 6);
    };

    convertStringToColor = (str) => {
        if (!str) return '';
        str = str.replace(/\s+/g, '');
        return `#${this.intToARGB(this.hashCode(str))}`;
    };

    hexToHSL = (H) => {
        // Convert hex to RGB first
        let r = 0;

        let g = 0;

        let b = 0;
        if (H.length == 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length == 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 100;
        g /= 280;
        b /= 100;
        const cmin = Math.min(r, g, b);

        const cmax = Math.max(r, g, b);

        const delta = cmax - cmin;

        let h = 0;

        if (delta == 0) h = 0;
        else if (cmax == r) h = ((g - b) / delta) % 6;
        else if (cmax == g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0) h += 360;

        return `hsl(${h},${80}%,${60}%)`;
    };

    handleOnCheckVehicles = (id: number) => {
        const equipmentIds = this.state.equipmentIds.slice();
        if (equipmentIds.indexOf(id) >= 0) {
            equipmentIds.splice(equipmentIds.indexOf(id), 1);
        } else {
            equipmentIds.push(id);
        }
        if (equipmentIds.length > 0 && this.props.errors.get('equipmentIds')) {
            this.props.removeMoveReportError('equipmentIds');
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnChangePeriod = (key: string) => (event: Event) => {
        this.changePeriod(key, event.target.value);
        this.props.removeMoveReportError(key);
    };

    handleOnChangeGasPrice = (e: Event) => {
        this.setState({
            gasPrice: e.target.value,
        });
    };

    handleGenerateReport = () => {
        exportData = [];
        this.props.validateMoveReportGenerator(this.state).then(() => {
            // submit generation form.

            const data = { ...this.state };
            data.fileName = this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.geofencingHistory',
            });
            this.props.generateGeofencingHistoryReport(data);
            if (this.state.reportGeneratedFilterOpen) {
                this.setState({
                    reportGeneratedFilterOpen: false,
                });
            }
        });
    };

    changePeriod = (key: string, date: string) => {
        if(this.state.dateLimit){
            this.setState({
                dateLimit:false,
            });
        };
        if( (key==="periodFrom")
         && (dateFns.differenceInDays(this.state.periodTo,date) > 366) ){
            const newDateTo= dateFns.addDays(date,366);
            const formattedNewDate= dateFns.format(newDateTo,'YYYY-MM-DD');
        
        this.setState({
            dateLimit:true,
            periodTo:formattedNewDate,
            dateLimitPositionLeft:true,
        })
         } else if((key==="periodTo")
              && (dateFns.differenceInDays(date, this.state.periodFrom) > 366)){
                const newDateFrom= dateFns.subDays(date,366);
                const formattedNewDateFrom= dateFns.format(newDateFrom,'YYYY-MM-DD');
                this.setState({
                  dateLimit:true,
                  periodFrom:formattedNewDateFrom,
                  dateLimitPositionLeft:false,
               })
          };
        let dateToChange=date
        const dateCompare = 
        (key =="periodFrom")
        ? dateFns.compareAsc(date,this.state.periodTo)
        : dateFns.compareAsc(this.state.periodFrom,date);
        const todayCompare = dateFns.compareAsc(date,today);
        if(todayCompare===1){dateToChange=today}
        console.log(dateCompare);
         if (dateCompare === 1){
            this.setState(() => ({
           [(key=="periodFrom")
           ? "periodTo"
           :"periodFrom"
           ]:dateToChange,
            }))
        };
        this.setState((prevState) => ({
            [key]: dateToChange,
            period: PRESET_PERIODS.custom,
        }));
    };

    handleSetPeriod = (presetPeriod: string) => () => {
        const currentDate = new Date();
        const period = {};
        this.setState({
            dateLimit:false,
        });
        switch (presetPeriod) {
            case PRESET_PERIODS.today: {
                period.to = dateFns.startOfToday();
                period.from = currentDate;
                break;
            }
            case PRESET_PERIODS.yesterday: {
                const yesterday = dateFns.subDays(currentDate, 1);
                period.from = dateFns.startOfDay(yesterday);
                period.to = dateFns.endOfDay(yesterday);
                break;
            }
            case PRESET_PERIODS.lastWeek: {
                const lastWeek = dateFns.subWeeks(currentDate, 1);
                period.from = dateFns.startOfWeek(lastWeek);
                period.to = dateFns.endOfWeek(lastWeek);
                break;
            }
            case PRESET_PERIODS.lastMonth: {
                const lastMonth = dateFns.subMonths(currentDate, 1);
                period.from = dateFns.startOfMonth(lastMonth);
                period.to = dateFns.endOfMonth(lastMonth);
                break;
            }
            case PRESET_PERIODS.thisMonth: {
                period.from = dateFns.startOfMonth(currentDate);
                period.to = dateFns.endOfMonth(currentDate);
                if(dateFns.compareAsc(today,period.to) < 0){
                    period.to = today
                 };
                break;
            }
            case PRESET_PERIODS.thisWeek: {
                period.from = dateFns.startOfWeek(currentDate);
                period.to = dateFns.endOfWeek(currentDate);
                if(dateFns.compareAsc(today,period.to) < 0){
                    period.to = today
                 };
                break;
            }
            default: {
                period.from = '';
                period.to = '';
            }
        }
        if (this.props.errors.get('periodFrom')) {
            this.props.removeMoveReportError('periodFrom');
        }

        if (this.props.errors.get('periodTo')) {
            this.props.removeMoveReportError('periodTo');
        }

        this.setState({
            periodTo: dateFns.format(period.to, 'YYYY-MM-DD'),
            periodFrom: dateFns.format(period.from, 'YYYY-MM-DD'),
            period: presetPeriod,
        });
    };

    handleOnClickModifyReport = () => {
        this.setState((prevState) => ({
            reportGeneratedFilterOpen: !prevState.reportGeneratedFilterOpen,
        }));
        exportData = [];
    };

    handleOnExportPDF = () => {
        const doc = new jsPDF()
        this.prepareRapportHistorique();
        // convert json to arrays
        let pdf = exportData.map(el => Object.values(el)).map(el => {
            (el[5])? el[5] = this.secondsToHms(el[5]):null;
            return el;
        });
        // creating pdf table ( head and body ) 
        // can add footer if needed 
        autoTable(doc, {   headStyles: { fillColor: colors.green63 }, head: [this.getHead()], body: pdf })
        doc.save('GeofencingHistory.pdf');

    };

    handleOnExportXLS = () => {
        this.prepareRapportHistorique();
        const excel = new Excel();
        excel
            .addSheet('GeofencingHistory')
            .addColumns(this.getColumns())
            .addDataSource(exportData)
            .saveAs('GeofencingHistory.xlsx');
        //  });
    };

    handleOnSelectAllVehicles = (searchedEquipments) => {
        let equipmentIds;
        if(searchedEquipments.toArray().length > 0) {
           equipmentIds = searchedEquipments.map((equipment) => equipment.get('id')).toArray();
        }else{
           equipmentIds = this.props.equipment.map((equipment) => equipment.get('id')).toArray();
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnUnselectAllVehicles = () => {
        const equipmentIds = [];
        this.setState({
            equipmentIds,
        });
    };

    onChangeSelect = (key: string) => (value: string) => {
        this.setState({ [key]: value });
    };

    /**
     *
     */
    handleOnChangePeriodFrom = (field) => (event) => {
        const time = event.target.value;
        const timeCompare =  
        dateFns.compareAsc(this.state.periodTo,this.state.periodFrom);   
        if (field === 'periodTimeFrom') {
            if(timeCompare === 0)
            {
                if(time > this.state.periodTimeTo){
                    this.setState({
                    periodTimeTo: time,
                    });
                }
            }
        } else {
            if(timeCompare === 0)
            {
                if(time < this.state.periodTimeFrom){
                    this.setState({
                    periodTimeFrom: time,
                    });
                }
            }
        };
    
        this.setState({ 
            [field]: time,
        });
        
    };

    // To be refactored...
    renderGenerator = () => {
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        return (
            <div>
                <GeofencingHistoryGenerator>
                    <div className={'geofenceReport'}>
                        <ReportsInputDate
                            startDate={this.state.periodFrom}
                            onChangeStartDate={this.handleOnChangePeriod('periodFrom')}
                            errorsStartDate={this.props.errors.get('periodFrom')}
                            endDate={this.state.periodTo}
                            onChangeEndDate={this.handleOnChangePeriod('periodTo')}
                            errorEndDate={this.props.errors.get('periodTo')}
                            dateLimit={this.state.dateLimit}
                            dateLimitPositionLeft={this.state.dateLimitPositionLeft}
                        >
                        </ReportsInputDate>

                        <GeofencingHistoryGenerator.ColumnRep>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.ReportType'}
                                />
                            </InputLabel>
                            <GeofencingHistoryGenerator.SelectContainer>
                                <SelectField
                                    options={[
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.ReportType.Vehicule',
                                            }),
                                            value: OUTPUT_TYPE.vehicle,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.ReportType.Conducteur',
                                            }),
                                            value: OUTPUT_TYPE.driver,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.ReportType.Geofence',
                                            }),
                                            value: OUTPUT_TYPE.geofences,
                                        },
                                    ]}
                                    value={this.state.outputType}
                                    borderColor={colors.grayED}
                                    arrowColor={colors.green63}
                                    onChange={this.onChangeSelect('outputType')}
                                />
                            </GeofencingHistoryGenerator.SelectContainer>
                        </GeofencingHistoryGenerator.ColumnRep>
                    </div>
                    <GeofencingHistoryGenerator.ColumnRep>
                        <GeofencingHistoryGenerator.ButtonContainer>
                            <div>
                                <GeofencingHistoryGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.today)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.Today'}
                                    />
                                </GeofencingHistoryGenerator.DateButton>
                                <GeofencingHistoryGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.yesterday)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.Yesterday'}
                                    />
                                </GeofencingHistoryGenerator.DateButton>
                                <GeofencingHistoryGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.thisWeek)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.ThisWeek'}
                                    />
                                </GeofencingHistoryGenerator.DateButton>
                                <GeofencingHistoryGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.lastWeek)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.LastWeek'}
                                    />
                                </GeofencingHistoryGenerator.DateButton>
                                <GeofencingHistoryGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.thisMonth)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.ThisMonth'}
                                    />
                                </GeofencingHistoryGenerator.DateButton>
                                <GeofencingHistoryGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.lastMonth)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.LastMonth'}
                                    />
                                </GeofencingHistoryGenerator.DateButton>
                            </div>

                            <GeofencingHistoryGenerator.SubmitButton
                                onClick={this.handleGenerateReport}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.Generate'}
                                />
                            </GeofencingHistoryGenerator.SubmitButton>
                        </GeofencingHistoryGenerator.ButtonContainer>
                    </GeofencingHistoryGenerator.ColumnRep>
                    <div className={'geofenceReport'}>
                        <GeofencingHistoryGenerator.ColumnRep>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id="tooltip-right">
                                        <FormattedMessage
                                            id={'containers.Reports.Generators.Driving.timeshift.info'}
                                        />
                                    </Tooltip>
                                }
                            >
                                <InputLabel margin={'0 0 15px 0'}>
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.timeshift'}
                                    />{' '}
                                    <i className="fas fa-info-circle" />{' '}
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.From'}
                                    />
                                </InputLabel>
                            </OverlayTrigger>
                            <FormInputTime
                                type={'time'}
                                value={this.state.periodTimeFrom}
                                onChange={this.handleOnChangePeriodFrom('periodTimeFrom')}
                                error={this.props.errors.get('periodTimeFrom')}
                                placeholderId={'views.MapPage.HistoryOptions.selectHour'}
                            />
                        </GeofencingHistoryGenerator.ColumnRep>
                        <GeofencingHistoryGenerator.ColumnRep>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage id={'containers.Reports.Generators.Driving.To'} />
                            </InputLabel>
                            <FormInputTime
                                type={'time'}
                                onChange={this.handleOnChangePeriodFrom('periodTimeTo')}
                                error={this.props.errors.get('periodTimeTo')}
                                value={this.state.periodTimeTo}
                                placeholderId={'views.MapPage.HistoryOptions.selectHour'}
                            />
                        </GeofencingHistoryGenerator.ColumnRep>
                    </div>

                </GeofencingHistoryGenerator>
                <Split color={colors.splitGrayReports} opacity={0.2} />
                <InputLabel margin={'30px 0 20px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.Vehicles'} />
                </InputLabel>
                {this.props.errors.get('equipmentIds') && (
                    <GeofencingHistoryGenerator.Error>
                        <Error
                            text={this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.Driving.EquipmentIdsError',
                            })}
                        />
                    </GeofencingHistoryGenerator.Error>
                )}
                <ReportVehicleSearch
                    equipment={this.props.equipment}
                    onCheckVehicles={this.handleOnCheckVehicles}
                    checkedInputs={this.state.equipmentIds}
                    onSelectAllVehicles={this.handleOnSelectAllVehicles}
                    onUnselectAllVehicles={this.handleOnUnselectAllVehicles}
                />
                <Split color={colors.splitGrayReports} opacity={0.2} />
            </div>
        );
    };

    /**
     * Render that shows
     * @param {number} columnSize Decides on how many columns
     * @param {boolean} showExtraColumns show or not total stop time
     * @param {boolean} tableFooter get totalVehicleUtilisationTime && totalVehicleDistance
     * @return {Component} rendered component
     */
    renderFooterAllVehiclesItem = (getTableSize, tableFooter, driversDataFooter) => {
        const columnSize = 4;
        const { report } = this.props;
        const reportFrom = dateFns.format(this.state.periodFrom, 'YYYY/MM/DD');
        const reportTo = dateFns.format(this.state.periodTo, 'YYYY/MM/DD');
        const driverFooterItems = [];

        driversDataFooter &&
            driversDataFooter.sort().forEach((s, index) => {
                driverFooterItems.push(
                    <Dashboard.FooterContainer columns={columnSize}>
                        <Dashboard.FooterItem>
                            <Chip
                                type="driver"
                                color={this.hexToHSL(
                                    this.convertStringToColor(
                                        /* s.get('data').get('driver')*/ 'zzzzzzzzzzz'
                                    )
                                )}
                            >
                                <i className="far fa-id-badge" />
                                &nbsp;
                                {/* s.get('data').get('driver')*/ 'xxxxxxxxxxxx'}
                            </Chip>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <empty />
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <empty />
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Chip type="personal">
                                {`${Number(/* s.get('data').get('distancePerso')*/ 9.99999).toFixed(
                                    0
                                )}km`}
                            </Chip>
                            <Chip type="work">
                                {`${Number(/* s.get('data').get('distanceWork')*/ 8.88888).toFixed(
                                    0
                                )}km`}
                            </Chip>
                        </Dashboard.FooterItem>
                    </Dashboard.FooterContainer>
                );
            });

        return getTableSize <= 1 && driverFooterItems.length == 0 ? (
            <empty />
        ) : (
            <Alert
                style={{
                    color: colors.black,
                    backgroundColor: '#bfbfbf33',
                    borderColor: colors.green63,
                }}
            >
                {getTableSize > 1 ? (
                    <Dashboard.FooterContainer columns={columnSize}>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                <FormattedMessage id={'containers.Reports.Generators.AllTotal'} />{' '}
                                {reportFrom}{' '}
                                <FormattedMessage id={'containers.Reports.Generators.To'} />{' '}
                                {reportTo}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                {tableFooter.get('totalAllVehicleUtilisationTime')}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                {tableFooter.get('totalAllVehicleTimeIdle')}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Chip type="distance">
                                {' '}
                                {tableFooter.get('totalAllVehicleDistance')}
                                km
                            </Chip>
                            <Chip type="personal">
                                {`${tableFooter.get('totalAllVehicleDistancePerso')}km`}
                            </Chip>
                            <Chip type="work">
                                {`${tableFooter.get('totalAllVehicleDistanceWork')}km`}
                            </Chip>
                        </Dashboard.FooterItem>
                    </Dashboard.FooterContainer>
                ) : (
                    <empty />
                )}
                {driverFooterItems.length > 0
                    ? [
                        getTableSize > 1 ? (
                            <hr style={{ borderTopColor: colors.green63 }} />
                        ) : (
                            <empty />
                        ),
                        <Dashboard.FooterContainer columns={columnSize}>
                            <Dashboard.FooterItem>
                                <Dashboard.FooterText>
                                    <FormattedMessage id={'views.Reports.Move.details.driver'} />
                                </Dashboard.FooterText>
                            </Dashboard.FooterItem>
                        </Dashboard.FooterContainer>,
                        driverFooterItems,
                    ]
                    : null}
            </Alert>
        );
    };

    handleConvertUTC = (date) => {
        if (!date) {
            return '';
        }
        const convertedDate = moment(date)
            .local()
            .format('YYYY-MM-DD HH:mm:ss A');
        return convertedDate;
    };


    prepareRapportHistorique = () => {
        const { report } = this.props;
        let elements = null;
        let outputType = 0;

        (report.get('drivers').size > 0) ? outputType = 1 : (report.get('geofences').size > 0) ? outputType = 2 : null;
        if (outputType === 0) {
            console.log('report vehicle ..................')
            elements = report.get('vehicles');
            this.vehiculeReport(elements);
        }
        else if (outputType === 1) {
            console.log('report driver ..................')
            elements = report.get('drivers');
            this.driverReport(elements);
        }
        else {
            console.log(' report geofence ..................')
            elements = report.get('geofences');
            this.geoReport(elements);
        }
    }
    // vehicle report
    vehiculeReport = (elements) => {


        let GrandTotal = 0;
        elements.forEach((data) => {
            const vehicleNumb = data.get('vehicle_number');
            const geofences = data.get('geofences');
            GrandTotal = data.get('totalTimeInGeofence');
            let totalTimeInGeofence = 0;
            geofences.forEach((geo) => {
                //geo
                const geoName = geo.get('geo_name');
                totalTimeInGeofence = geo.get('timeInGeofence');
                const history = geo.get('history');
                history.forEach((h) => {
                    //historique
                    const line = {
                        vehicleNumb,
                        geoName,
                        dateEntrance: this.handleConvertUTC(h.get('date_entrance')),
                        dateExit: this.handleConvertUTC(h.get('date_exit')),
                        driverName: h.get('driver_name'),
                        timeSpent: Math.round( h.get('time_spent')),

                    };
                    exportData.push(line);
                });

                exportData.push({
                    vehicleNumb: 'TOTAL',
                    geoName: '',
                    dateEntrance: '',
                    dateExit: '',
                    driverName: '',
                    timeSpent: Math.round(totalTimeInGeofence),

                });

            });
        });


    }
    driverReport = (elements) => {
        let GrandTotal = 0;
        elements.forEach((data) => {
            const driverName = data.get('driver_name');
            const geofences = data.get('geofences');
            GrandTotal = data.get('totalTimeInGeofence');
            let totalTimeInGeofence = 0;
            geofences.forEach((geo) => {
                //geo
                const geoName = geo.get('geo_name');
                totalTimeInGeofence = geo.get('timeInGeofence');
                const history = geo.get('history');
                history.forEach((h) => {
                    //historique
                    const line = {
                        vehicleNumb: h.get('vehicle_numb'),
                        geoName,
                        dateEntrance: this.handleConvertUTC(h.get('date_entrance')),
                        dateExit: this.handleConvertUTC(h.get('date_exit')),
                        driverName,
                        timeSpent:  Math.round( h.get('time_spent')),
                    };
                    exportData.push(line);
                });

                exportData.push({
                    vehicleNumb: 'TOTAL',
                    geoName: '',
                    dateEntrance: '',
                    dateExit: '',
                    driverName: '',
                    timeSpent: Math.round(totalTimeInGeofence),
                });
            });
        });

    }
    geoReport = (elements) => {
        let GrandTotal = 0;
        elements.forEach((data) => {
            const geoName = data.get('geo_name');
            const vehicles = data.get('vehicles');
            GrandTotal = data.get('totalTimeInGeofence');
            let totalTimeInGeofence = 0;
            const geoTitle = {
                vehicleNumb: geoName,
                geoName: '',
                dateEntrance: '',
                dateExit: '',
                driverName: '',
                timeSpent: '',
            };
            exportData.push(geoTitle);
            vehicles.forEach((geo) => {
                console.log('__geo');
                console.log(geo);
                const vehicleNumb = geo.get('vehicle_number');
                totalTimeInGeofence = geo.get('timeInGeofence');
                const history = geo.get('history');

                history.forEach((h) => {
                    console.log('___history');
                    console.log(h);

                    const line = {
                        vehicleNumb,
                        geoName,
                        dateEntrance: this.handleConvertUTC(h.get('date_entrance')),
                        dateExit: this.handleConvertUTC(h.get('date_exit')),
                        driverName: h.get('driver_name'),
                        timeSpent:  Math.round( h.get('time_spent')),
                    };
                    exportData.push(line);
                });

                exportData.push({
                    vehicleNumb: 'TOTAL',
                    geoName: '',
                    dateEntrance: '',
                    dateExit: '',
                    driverName: '',
                    timeSpent: Math.round(totalTimeInGeofence),
                });
            });

        });

    }

    renderReport = () => {
        const { report } = this.props;
        let outputType = 0;
        if (report.get('drivers').size > 0) {
            outputType = 1;
        } else if (report.get('geofences').size > 0) {
            outputType = 2;
        }
        const reportTitle = this.props.intl.formatMessage({
            id: 'views.Reports.SideMenu.geofencingHistory',
        });
        const periodetimeStart = this.state.periodFrom;
        const periodetimeEnd = this.state.periodTo;
        const reportFrom = dateFns.format(this.state.periodFrom, 'YYYY/MM/DD');
        const reportTo = dateFns.format(this.state.periodTo, 'YYYY/MM/DD');
        const reportFilterText = [];
        reportFilterText.date = `${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${reportFrom} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${reportTo}`;
        reportFilterText.time =
            periodetimeStart && periodetimeEnd
                ? `${this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.periode',
                })} ${periodetimeStart} - ${periodetimeEnd}`
                : '';
        const tables = [];
        const driversTables = [];
        let getTableSize;
        const dataTitle = 'TITLEEEEEEEEEE todo'; // data.get('meta').get('title');

        const entries = [
            {
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.geofenceName',
                }),
                sortMethod: null,
                key: 'name',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.entryDate',
                }),
                sortMethod: null,
                key: 'entryDate',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.exitDate',
                }),
                sortMethod: null,
                key: 'exitDate',
            },

            {
                label: this.props.intl.formatMessage({
                    id: 'components.Modals.Form.Config.GestionCond.DriverName',
                }),
                sortMethod: null,
                key: 'driverName',
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.timeSpent',
                }),
                sortMethod: null,
                key: 'timeSpent',
            },
        ];
        let elements = null;
        if (outputType === 0) {
            elements = report.get('vehicles');
        } else if (outputType === 1) {
            elements = report.get('drivers');
            entries[3] = {
                label: this.props.intl.formatMessage({
                    id: 'containers.Maintenance.Alarm.Vehicle',
                }),
                sortMethod: null,
                key: 'VehicleName',
            };
        } else {
            elements = report.get('geofences');
            entries[0] = {
                label: this.props.intl.formatMessage({
                    id: 'containers.Maintenance.Alarm.Vehicle',
                }),
                sortMethod: null,
                key: 'VehicleName',
            };
        }
        let element_holder = [];
        //let GrandTotal = 0;
        elements.forEach((data) => {
            let title = null;
            let dtable = null;
            switch (outputType) {
                case 1:
                    title = data.get('driver_name');
                    dtable = data.get('geofences');

                    break;
                case 2:
                    title = data.get('geo_name');
                    dtable = data.get('vehicles');

                    break;
                default:
                    title = data.get('vehicle_number');
                    dtable = data.get('geofences');
            }
            //add data to array to sort later.
            element_holder.push([
                title,
                entries,
                dtable,
                data.get('totalTimeInGeofence'),
                reportFilterText,
            ]);

        });
        element_holder.sort().forEach(function (item, index) {
            tables.push(
                <ReportDataTable
                    title={item[0]} //title
                    columns={item[1]} //entries
                    tableData={item[2]} //dtable
                    tableFooter={item[3]} //totalTimeInGeofence
                    reportFilterText={item[4]} //reportFilterText
                />
            );
        });
        getTableSize = tables.length;
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        return (
            <Report.Header>
                <DrivingReportGenerator.ReportType style={{ marginTop: 15, marginBottom: 40 }}>
                    {!this.state.reportGeneratedFilterOpen && (
                        <React.Fragment>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={ReportIcon}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportTitle}
                            </Report.TextContainer>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={Calendar}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportFilterText.date}
                                <br />
                                {reportFilterText.time}
                            </Report.TextContainer>
                        </React.Fragment>
                    )}
                    <Report.ModifyReport onClick={this.handleOnClickModifyReport}>
                        <Report.Icon
                            src={MagnifyingGlass}
                            width={20}
                            height={20}
                            margin={'0 0 0 17px'}
                        />
                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                            <FormattedMessage id={'containers.Reports.Generators.Modify'} />
                        </p>
                    </Report.ModifyReport>
                </DrivingReportGenerator.ReportType>
                <SlideDown className={'generator'}>
                    {this.state.reportGeneratedFilterOpen ? this.renderGenerator() : null}
                </SlideDown>
                {tables}
            </Report.Header>
        );
    };

    render() {
        return (
            <ReportGeneratorContainer>
                <GenerateReportHeader
                    report={this.props.report}
                    title={this.props.intl.formatMessage({
                        id: 'views.Reports.SideMenu.geofencingHistory',
                    })}
                    onExportPDF={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportPDF : null
                    }
                    onExportXLS={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportXLS : null
                    }
                />
                <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} />
                {this.props.report && this.props.report.size > 0
                    ? this.renderReport()
                    : this.renderGenerator()}
            </ReportGeneratorContainer>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    errors: selectMoveReportGenerationErrors(),
    loading: selectIsLoading(),
    report: selectGeofencingHistoryReport(),
    equipment: selectVehicles(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            validateMoveReportGenerator,
            generateGeofencingHistoryReport,
            removeMoveReportError,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
