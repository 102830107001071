// @flow

// TYPES
export const GET_ROUTIFIC_SUCCESS = 'app/services/Routific/GET_ROUTIFIC_SUCCESS';
export const GET_ROUTIFICS_SUCCESS = 'app/services/Routific/GET_ROUTIFICS_SUCCESS';
export const GET_ROUTIFIC_ERROR = 'app/services/Routific/GET_ROUTIFIC_ERROR';
export const GET_ROUTIFIC_STATUS_SUCCESS = 'app/services/Routific/GET_ROUTIFIC_STATUS_SUCCESS';
export const LOADING_ROUTIFICS = 'app/services/Routific/LOADING_ROUTIFICS';

/**
 *
 * @param {*} RoutificRecords
 */
export const receivedRoutificSuccess = (RoutificRecords): GenericActionType => ({
    type: GET_ROUTIFIC_SUCCESS,
    RoutificRecords,
});

/**
 *
 * @param {*} RoutificsRecords
 */
export const receivedRoutificsSuccess = (RoutificsRecords): GenericActionType => ({
    type: GET_ROUTIFICS_SUCCESS,
    RoutificsRecords,
});

/**
 *
 * @param {*} routificStatus
 */
export const receivedRoutificStatusSuccess = (routificStatus): GenericActionType => ({
    type: GET_ROUTIFIC_STATUS_SUCCESS,
    routificStatus,
});

/**
 *
 */
export const receivedRoutificError = (): GenericActionType => ({
    type: GET_ROUTIFIC_ERROR,
});

/**
 *
 */
export const startLoading = (): GenericActionType => ({
    type: LOADING_ROUTIFICS,
});
