// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, FormattedMessage } from 'react-intl';
import AlarmManagement from '../styles';
import { getMaintenanceVeh } from '../../../services/Maintenance/thunks';
import Tooltip from 'antd/es/tooltip';
import AlarmConfigStyle from './styles';
import { changeUnit, i18nDistanceSymbol, setTextunit } from '../../../services/Units/thunks';
import { selectUnit } from '../../../services/Units/selectors';
import { metersToKilometers } from '../helpers';
import { Container, Row, Col, Alert, Form, InputGroup } from 'react-bootstrap';


const AlarmConfig = (props) => {
        const [configAlert, setConfigAlert] = useState([{}]);
        const [datasInput, setDatasInput] = useState([]);
        const [actualKeyPressed, setActualKeyPressed] = useState('');

        useEffect(() => {

            if(props.alarmsDatas){
                    setConfigAlert([
                            {
                                key: 'km',
                                value: Math.round(parseFloat(props.setTextunit(props.alarmsDatas.distance, 'odometer'))),
                                label: props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.AlarmConfig.km' }) : props.intl.formatMessage({ id: 'containers.Maintenance.AlarmConfig.milles' }),
                                isValueExceed: false,
                            },
                            {
                                key: 'hrs_engine',
                                value: props.alarmsDatas.hrs,
                                label: props.intl.formatMessage({ id: 'containers.Maintenance.AlarmConfig.hr' }),
                                isValueExceed: false,
                            },
                            {
                                key: 'periodic',
                                value: props.alarmsDatas.periodic,
                                label: props.intl.formatMessage({ id: 'components.Modals.Form.Config.Maintenance.Days' }),
                                isValueExceed: false,
                            },
                            {
                                keyRemind: 'reminder_km',
                                value: Math.round(parseFloat(props.setTextunit(props.alarmsDatas.distanceReminder, 'odometer'))),
                                label: props.i18nDistanceSymbol() === 'km' ? props.intl.formatMessage({ id: 'containers.Maintenance.Reminder.km' }) : props.intl.formatMessage({ id: 'containers.Maintenance.Reminder.milles' }),
                                isValueExceed: false,
                            },
                            {
                                keyRemind: 'reminder_hrs_engine',
                                value: props.alarmsDatas.hrsReminder,
                                label: props.intl.formatMessage({ id: 'containers.Maintenance.Reminder.hr' }),
                                isValueExceed: false,
                            },
                            {
                                keyRemind: 'reminder_periodic',
                                value: props.alarmsDatas.periodicReminder,
                                label: props.intl.formatMessage({ id: 'containers.Maintenance.Reminder.days' }),
                                isValueExceed: false,
                            },
                        ],
                    );
            }
            },
            [props.alarmsDatas, props.i18nDistanceSymbol()],
        );
        useEffect(() => {
                if(props.alarmsDatas){
                    setDatasInput({
                        km: Math.round(parseFloat(props.setTextunit(props.alarmsDatas.distance, 'odometer'))),
                        hrs_engine: props.alarmsDatas.hrs,
                        name: props.alarmsDatas.name,
                        periodic: props.alarmsDatas.periodic,
                        periodic_factor: 'J',
                        reminder_km: Math.round(parseFloat(props.setTextunit(props.alarmsDatas.distanceReminder, 'odometer'))),
                        reminder_hrs_engine: props.alarmsDatas.hrsReminder,
                        reminder_periodic: props.alarmsDatas.periodicReminder,
                        reminder_periodic_factor: 'J',
                        users: [],
                        vehicles: [],
                    });
                }


            },
            [props.alarmsDatas],
        );


    const handleKeyDown = (event, item, index) => {
        setActualKeyPressed(event.key);
    };
    const isPreAlertHigher = (index, item, array, keyType) => {
        if (item.keyRemind && item.keyRemind === keyType) {
            if (parseInt(item.value) > parseInt(array[index].value)) {
                item.isValueExceed = true;
            } else {
                item.isValueExceed = false;
                array[index].isValueExceed = false;
            }
        }
        if (item.key && item.key === keyType) {
            if (parseInt(item.value) < parseInt(array[index].value)) {
                item.isValueExceed = true;
            } else {
                item.isValueExceed = false;
                array[index].isValueExceed = false;
            }
        }
    };
    const handleValueInut = (event, item, index) => {
        let valueInput = event.target.value
        if(valueInput===''){
            valueInput =0
        }

        let value;
        if (actualKeyPressed !== '.') {
            configAlert.forEach((val, alarmIndex) => {
                if (index === alarmIndex) {
                    // gerer les virgules
                    val.value = event.target.value.includes('.') ? event.target.value.slice(0, event.target.value.length - 2) : parseInt(valueInput);

                    if (val.key === 'km' || val.keyRemind === 'reminder_km') {
                        val.value = parseInt(val.value);
                    }

                    value = val.value;

                    if (item.key === 'km' || item.keyRemind === 'reminder_km') {
                        item.value = parseInt(item.value);
                    }

                    isPreAlertHigher(0, item, configAlert, 'reminder_km');

                    isPreAlertHigher(3, item, configAlert, 'km');

                    isPreAlertHigher(1, item, configAlert, 'reminder_hrs_engine');

                    isPreAlertHigher(4, item, configAlert, 'hrs_engine');

                    isPreAlertHigher(2, item, configAlert, 'reminder_periodic');

                    isPreAlertHigher(5, item, configAlert, 'periodic');

                    setConfigAlert([...configAlert], item);
                }
                });
                item.key !== undefined ? datasInput[item.key] = value : datasInput[item.keyRemind] = value;

                setDatasInput(datasInput);
                props.handleConfig(datasInput);
            }

        };

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className={'title'}>
                    <FormattedMessage id={'containers.Maintenance.AlarmConfig.Title'} />
                </div>

                <AlarmConfigStyle>

                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '45px' }}>
                            <FormattedMessage id={'containers.Maintenance.AlarmConfig.Alerte'} />

                            <Tooltip placement='rightTop'
                                     title={
                                         <AlarmConfigStyle.Tooltip>
                                             <div><FormattedMessage
                                                 id={'containers.Maintenance.AlarmConfig.AlerteTooltip'} /></div>
                                             <FormattedMessage
                                                 id={'containers.Maintenance.AlarmConfig.AlerteTooltipTxt'} />
                                         </AlarmConfigStyle.Tooltip>
                                     }
                                     arrowPointAtCenter>
                                <i className=' fa fa-question-circle' />
                            </Tooltip>
                        </div>

                        {configAlert.map((item, index) => {
                            if (item.hasOwnProperty('key')) {
                                return (
                                    <div key={item.label}>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <AlarmManagement.InputItem
                                                    min={'0'}
                                                    type={'number'}
                                                    value={item.value && !isNaN(item.value) ? item.value : 0}
                                                    onChange={(event) => handleValueInut(event, item, index)}
                                                    onKeyDown={(event) => handleKeyDown(event, item, index)}
                                                    style={{
                                                        width: '150px',
                                                        border: item.isValueExceed ? '2px solid red' : '',
                                                    }}
                                                />
                                            </div>

                                            <div style={{ paddingLeft: '10px' }}>
                                                <div>
                                                    {item.label}
                                                </div>
                                            </div>
                                        </div>
                                        {item.isValueExceed ? (
                                            <div style={{ fontSize: 'small', color: 'red' }}>
                                                <FormattedMessage id={'containers.Maintenance.Warning.PreAlert'} />
                                            </div>
                                        ) : null}
                                        {index < 2 &&
                                        (<div><FormattedMessage
                                            id={'components.Modals.Form.Config.Maintenance.Or'} />
                                        </div>)}
                                    </div>
                                );
                            }

                        })}
                    </div>

                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '45px' }}>
                            <FormattedMessage id={'containers.Maintenance.AlarmConfig.PreAlert'} />
                            <Tooltip placement='rightTop'
                                     title={
                                         <AlarmConfigStyle.Tooltip>
                                             <div><FormattedMessage
                                                 id={'containers.Maintenance.AlarmConfig.PreAlertTooltip'} /></div>
                                             <FormattedMessage id={'containers.Maintenance.AlarmConfig.TooltipText'} />
                                         </AlarmConfigStyle.Tooltip>
                                     }
                                     arrowPointAtCenter>
                                <i className=' fa fa-question-circle' />
                            </Tooltip>

                        </div>

                        {configAlert.map((item, index) => {
                            if (item.hasOwnProperty('keyRemind')) {
                                return (
                                    <div key={item.label}>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <AlarmManagement.InputItem
                                                    min={'0'}
                                                    type={'number'}
                                                    value={item.value && !isNaN(item.value) ? item.value : 0}
                                                    onChange={(event) => handleValueInut(event, item, index)}
                                                    onKeyDown={(event) => handleKeyDown(event, item, index)}
                                                    style={{
                                                        width: '150px',
                                                        border: item.isValueExceed ? '2px solid red' : '',
                                                    }}

                                                />
                                            </div>
                                            <div style={{ paddingLeft: '10px' }}>
                                                <div>
                                                    {item.label} <FormattedMessage
                                                    id={'containers.Maintenance.AlarmConfig.Send'} />
                                                </div>
                                            </div>
                                        </div>
                                        {item.isValueExceed ? (
                                            <div style={{ fontSize: 'small', color: 'red' }}>
                                                <FormattedMessage id={'containers.Maintenance.Warning.PreAlert'} />
                                            </div>
                                        ) : null}
                                        {index < configAlert.length - 1 &&
                                        (<div><FormattedMessage id={'components.Modals.Form.Config.Maintenance.Or'} />
                                        </div>)}
                                    </div>
                                );
                            }

                        })}

                    </div>
                </AlarmConfigStyle>
            </div>

        );
    }
;

// REDUX
/**
 *
 */
const mapStateToProps = createStructuredSelector(
    {
        unit: selectUnit(),
    },
);

/**
 *
 * @param
    {*
    }
    dispatch
 */
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getMaintenanceVeh,
            setTextunit,
            changeUnit,
            i18nDistanceSymbol,
        }
        ,
        dispatch,
    );

// EXPORTS
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(AlarmConfig));
