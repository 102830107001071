import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Modal from '@mui/material/Modal';
import { Grid, Box, Chip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import './index.scss';
import Organization from '../../OrganizationListItemMoveV1/styles';
import ToggleProPersoStyle from '../../ToggleProPerso/styles';
import { connect } from 'react-redux';
import { closeMoveReportModal, generateIsRouteProPerso } from 'services/Report/thunks';
import { selectMoveInfo } from 'services/Report/selectors';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { selectLocale } from '../../../services/Language/selectors';
import MoveReportMap from '../../../components/MoveReportMap';
import DriverChip from '../../Chip';
import { ArrowSvg } from '../../Icons';
import { StartPositionIcon, EndPositionIcon } from '../../Icons';

function MoveReportModal(props) {
    const [open, setOpen] = React.useState(true);
    const [isPrivateMode, setIsPrivateMode] = React.useState(props.moveInfo.isPrivateMode);
    const handleClose = () => {
        props.closeMoveReportModal();
        setOpen(false);
    };
    const handleIsJourneyPro = (e) => {
        setIsPrivateMode(e.target.checked);
        const checked = e.target.checked;
        if (props.moveInfo.toggleRef && props.moveInfo.toggleRef.current) {
            // Update the checked state directly without simulating a click
            props.moveInfo.toggleRef.current.click();
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={'Box'}>
                <Grid container className={'container'} mb={'10px'} mt={-5} spacing={2}>
                    <Grid item>
                        <div className={'title'}>{props.moveInfo.vehiculeNumb}</div>
                    </Grid>
                    <Grid item>
                        <Chip
                            label={props.moveInfo.distance}
                            sx={{
                                backgroundColor: '#63AC37',
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: '800',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        {props.moveInfo.driverName ? (
                            <Box>
                                <DriverChip type="driver" color={props.moveInfo.driverChipColor}>
                                    <i className="far fa-id-badge" />
                                    &nbsp;
                                    {props.moveInfo.driverName}
                                </DriverChip>
                            </Box>
                        ) : (
                            <empty />
                        )}
                    </Grid>
                    <Grid item>
                        <Box className={'address-box'}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={'address-title'}>
                                        {props.intl.formatMessage({
                                            id: 'Components.Reports.Move.AddressCard.Start',
                                        })}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={'address-text'}>
                                        <StartPositionIcon />
                                        {props.moveInfo.startAddress}
                                    </div>
                                </Grid>
                                <Grid>
                                    <div className="time-text">{`${
                                        props.moveInfo.periodDateFrom
                                    } à ${props.moveInfo.periodTimeFrom}`}</div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        <ArrowSvg />
                    </Grid>
                    <Grid item>
                        <Box className={'address-box'}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={'address-title'}>
                                        {props.intl.formatMessage({
                                            id: 'Components.Reports.Move.AddressCard.End',
                                        })}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={'address-text'}>
                                        <EndPositionIcon />
                                        {props.moveInfo.stopAddress}
                                    </div>
                                </Grid>
                                <Grid>
                                    <div className="time-text">{`${props.moveInfo.periodDateTo} à ${
                                        props.moveInfo.periodTimeTo
                                    }`}</div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item sx={{display:'grid'}}>
                        <div className="address-title">
                            {props.intl.formatMessage({
                                id: 'containers.Reports.Move.typeDeplacement',
                            })}
                        </div>
                        <div style={{  justifySelf: 'center' }}>
                        <Organization.isPrivateMode key="isPrivateMode">
                            <FormControlLabel sx={{m: 0}}
                                control={
                                    <ToggleProPersoStyle
                                        sx={{ m: 1 }}
                                        checked={isPrivateMode}
                                        onChange={handleIsJourneyPro}
                                    />
                                }
                            />
                        </Organization.isPrivateMode>
                        </div>
                    </Grid>
                </Grid>
                <MoveReportMap />
            </Box>
        </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
    moveInfo: selectMoveInfo(),
});
const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            closeMoveReportModal,
            generateIsRouteProPerso,
        },
        dispatch
    );
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(MoveReportModal))
);
