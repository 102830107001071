// @flow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import dateFns from 'date-fns';

import { Split } from 'styles/common';
import colors from 'styles/colors';

import {
    DrivingReportGenerator,
    InputLabel,
    Report,
    ReportGeneratorContainer,
} from 'styles/reports';

import ReportDataTable from 'containers/ReportDataTable';
import GenerateReportHeader from 'containers/ReportGenerator/Header';

import ReportVehicleSearch from 'components/ReportVehicleSearch';

import Loader from 'components/Loader';

import Error from 'components/Error';

import MagnifyingGlass from 'assets/search-icon-white.svg';
import ReportIcon from 'assets/report-pen.svg';
import Calendar from 'assets/calendar-black.svg';
import DrivingReportModal from '../../../components/Modals/DrivingReportModal';
import {
    clearDrivingReport,
    generateDrivingReport,
    removeDrivingReportError,
    validateDrivingReportGenerator,
} from 'services/Report/thunks';
import {
    selecDrivingReportGenerationErrors,
    selectDrivingReport,
    selectIsLoading,
    selectDrivingReportModal,
} from 'services/Report/selectors';

import { selectVehicles } from 'services/Vehicle/selectors';
import { selectUnit } from 'services/Units/selectors';

import { OUTPUT_FORMATS, PRESET_PERIODS, REPORT_TYPES } from 'services/Report/constants';
import ReportsInputDate from '../../../components/ReportsInputDate';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Logo from 'assets/orca-logo-black-min.png';
import * as Excel from 'exceljs';
import * as ExcelProper from 'exceljs';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import SelectField from '../../../components/SelectField';

const today = dateFns.format(new Date(), 'YYYY-MM-DD');

type Props = {};

type State = {
    equipmentIds: Array<number>,
    gasPrice: string,
    periodFrom: string,
    periodTo: string,
    period: string,
    dateLimit: boolean,
    dateLimitPositionLeft: boolean,
    reportGeneratedFilterOpen: boolean,
    format: string,
    type: string,
    isGenerated: boolean,
};

export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    state = {
        equipmentIds: [],
        gasPrice: '',
        periodFrom: '',
        periodTo: '',
        dateLimit: false,
        dateLimitPositionLeft: true,
        period: PRESET_PERIODS.custom,
        reportGeneratedFilterOpen: false,
        format: OUTPUT_FORMATS.page,
        type: REPORT_TYPES.detailed,
        isGenerated: false,
    };

    componentDidMount = () => {
        this.props.clearDrivingReport();
        this.setState({ periodFrom: today, periodTo: today });
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.unit && prevProps.unit !== this.props.unit) {
            this.handleGenerateReport();
        }
    };

    handleOnCheckVehicles = (id: number) => {
        const equipmentIds = this.state.equipmentIds.slice();
        if (equipmentIds.indexOf(id) >= 0) {
            equipmentIds.splice(equipmentIds.indexOf(id), 1);
        } else {
            equipmentIds.push(id);
        }
        if (equipmentIds.length > 0 && this.props.errors.get('equipmentIds')) {
            this.props.removeDrivingReportError('equipmentIds');
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnChangePeriod = (key: string) => (event: Event) => {
        this.changePeriod(key, event.target.value);
        this.props.removeDrivingReportError(key);
    };

    handleOnChangeGasPrice = (e: Event) => {
        this.setState({
            gasPrice: e.target.value,
        });
    };

    handleGenerateReport = () => {
        this.setState({ isGenerated: true });
        this.props.validateDrivingReportGenerator(this.state).then(() => {
            // submit generation form.
            const data = { ...this.state };
            data.fileName = this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.RapportDeConduite',
            });
            this.props.generateDrivingReport(data).then(() => {
                if (this.state.format === 'pdf') {
                    this.handleOnExportPDF();
                    this.setState({ isGenerated: false });
                }
                if (this.state.format === 'xlsx') {
                    this.handleOnExportXLS();
                    this.setState({ isGenerated: false });
                }
            });

            if (this.state.reportGeneratedFilterOpen) {
                this.setState({
                    reportGeneratedFilterOpen: false,
                });
            }
        });
    };

    changePeriod = (key: string, date: string) => {
        if (this.state.dateLimit) {
            this.setState({
                dateLimit: false,
            });
        }
        if (key === 'periodFrom' && dateFns.differenceInDays(this.state.periodTo, date) > 366) {
            const newDateTo = dateFns.addDays(date, 366);
            const formattedNewDate = dateFns.format(newDateTo, 'YYYY-MM-DD');

            this.setState({
                dateLimit: true,
                periodTo: formattedNewDate,
                dateLimitPositionLeft: true,
            });
        } else if (
            key === 'periodTo' &&
            dateFns.differenceInDays(date, this.state.periodFrom) > 366
        ) {
            const newDateFrom = dateFns.subDays(date, 366);
            const formattedNewDateFrom = dateFns.format(newDateFrom, 'YYYY-MM-DD');
            this.setState({
                dateLimit: true,
                periodFrom: formattedNewDateFrom,
                dateLimitPositionLeft: false,
            });
        }
        let dateToChange = date;
        const dateCompare =
            key == 'periodFrom'
                ? dateFns.compareAsc(date, this.state.periodTo)
                : dateFns.compareAsc(this.state.periodFrom, date);
        const todayCompare = dateFns.compareAsc(date, today);
        if (todayCompare === 1) {
            dateToChange = today;
        }
        if (dateCompare === 1) {
            this.setState(() => ({
                [key == 'periodFrom' ? 'periodTo' : 'periodFrom']: dateToChange,
            }));
        }
        this.setState((prevState) => ({
            [key]: dateToChange,
            period: PRESET_PERIODS.custom,
        }));
    };

    handleSetPeriod = (presetPeriod: string) => () => {
        const currentDate = new Date();
        const period = {};
        this.setState({
            dateLimit: false,
        });
        switch (presetPeriod) {
            case PRESET_PERIODS.today: {
                period.to = dateFns.startOfToday();
                period.from = currentDate;
                break;
            }
            case PRESET_PERIODS.yesterday: {
                const yesterday = dateFns.subDays(currentDate, 1);
                period.from = dateFns.startOfDay(yesterday);
                period.to = dateFns.endOfDay(yesterday);
                break;
            }
            case PRESET_PERIODS.lastWeek: {
                const lastWeek = dateFns.subWeeks(currentDate, 1);
                period.from = dateFns.startOfWeek(lastWeek);
                period.to = dateFns.endOfWeek(lastWeek);
                break;
            }
            case PRESET_PERIODS.lastMonth: {
                const lastMonth = dateFns.subMonths(currentDate, 1);
                period.from = dateFns.startOfMonth(lastMonth);
                period.to = dateFns.endOfMonth(lastMonth);
                break;
            }
            case PRESET_PERIODS.thisMonth: {
                period.from = dateFns.startOfMonth(currentDate);
                period.to = dateFns.endOfMonth(currentDate);
                if (dateFns.compareAsc(today, period.to) < 0) {
                    period.to = today;
                }
                break;
            }
            case PRESET_PERIODS.thisWeek: {
                period.from = dateFns.startOfWeek(currentDate);
                period.to = dateFns.endOfWeek(currentDate);
                if (dateFns.compareAsc(today, period.to) < 0) {
                    period.to = today;
                }
                break;
            }
            default: {
                period.from = '';
                period.to = '';
            }
        }
        if (this.props.errors.get('periodFrom')) {
            this.props.removeDrivingReportError('periodFrom');
        }

        if (this.props.errors.get('periodTo')) {
            this.props.removeDrivingReportError('periodTo');
        }

        this.setState({
            periodTo: dateFns.format(period.to, 'YYYY-MM-DD'),
            periodFrom: dateFns.format(period.from, 'YYYY-MM-DD'),
            period: presetPeriod,
        });
    };

    handleOnClickModifyReport = () =>
        this.setState((prevState) => ({
            reportGeneratedFilterOpen: !prevState.reportGeneratedFilterOpen,
        }));

    handleOnExportPDF = () => {
        const { report } = this.props;
        const newReport = report.toJS();
        const overSpeedLimitReport = newReport.data.overSpeedLimit;
        // const activitySummarySheetReport = newReport.data.activitySummary

        const doc = new jsPDF('l');
        //Header
        const generateAt = `${this.props.intl.formatMessage({
            id: 'components.Reports.move.pdf.generate',
        })}`;
        doc.addImage(Logo, 'png', 12, 8);
        doc.setFont(undefined, 'bold');
        doc.text(
            `${this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.Behavior',
            })}`,
            143,
            27,
            { align: 'center' }
        );
        doc.setFontSize(9);
        doc.text(
            `${this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.From',
            })} ${newReport.meta.filters.datetimeEnd} ${this.props.intl.formatMessage({
                id: 'containers.Reports.Generators.To',
            })} ${newReport.meta.filters.datetimeStart}`,
            120,
            32
        );
        doc.setFontSize(8);
        doc.text(generateAt + moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 250, 10);

        //Dépassement de vitesse
        doc.setFontSize(10);
        // doc.text(overSpeedLimitReport.meta.title, 12, 47);
        let overSpeedDatas = [];
        overSpeedLimitReport.data.forEach((item) => {
            if (item.geoName === null || item.geoName === '') {
                item.geoName = this.props.intl.formatMessage({
                    id: 'Component.Report.Overspeed.DefaultGeofence',
                });
            }
            if (item.driverName === 'Non identifié') {
                item.driverName = this.props.intl.formatMessage({
                    id: 'Component.Report.Overspeed.UnidentifiedDriver',
                });
            }
            overSpeedDatas.push([
                item.vehicleNumb,
                item.driverName,
                item.startTime,
                item.endTime,
                item.speed,
                item.maximumSpeed,
                item.speedLimit,
                item.totalDistance,
                item.geoName,
            ]);
        });
        const isImperial = newReport.meta.isImperial;

        const distanceUnit = isImperial ? '(mi)' : '(km)';

        const speedUnit = isImperial ? '(mph)' : '(km/h)';

        let headers = {
            vehicleNumb: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Vehicule',
            })}`,
            driverName: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Driver',
            })}`,
            startTime: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Start',
            })}`,
            endTime: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.End',
            })}`,
            speed: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Speed',
            })} ${speedUnit}`,
            maximumSpeed: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.MaxSpeed',
            })} ${speedUnit}`,
            speedLimit: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.SpeedLimit',
            })} ${speedUnit}`,
            totalDistance: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Distance',
            })} ${distanceUnit}`,
            geoName: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Geofence',
            })}`,
        };
        const columOverSpeed = Object.values(headers);

        const overSpeedTable = {
            head: [columOverSpeed],
            theme: 'grid',
            startY: 58,
            margin: { top: 20 },
            headStyles: { textColor: 'white', fillColor: '#63AC37', fontSize: '8' },
            bodyStyles: { textColor: 'black', fillColor: 'white', fontSize: '8' },
            body: overSpeedDatas,
        };

        doc.autoTable(overSpeedTable);

        //Acvitity Summary Report
        // const previousY = doc.autoTable.previous.finalY
        // doc.setFontSize(10);
        // doc.text(activitySummarySheetReport.meta.title, 12, (previousY + 28));

        // const previousTextY = doc.autoTable.previous.finalY

        // let summaryDatas = []
        // activitySummarySheetReport.data.forEach((item) => {
        //     summaryDatas.push(Object.values(item))
        // })

        // const startYTable = previousTextY + 38
        // const columActivitySummary = Object.values(activitySummarySheetReport.meta.headers)
        // const summaryTable = {
        //     head: [columActivitySummary],
        //     theme: 'grid',
        //     startY: startYTable,
        //     margin: { top: 20 },
        //     headStyles: { textColor: 'white', fillColor: '#63AC37', fontSize: '8', },
        //     bodyStyles: { textColor: 'black', fillColor: 'white', fontSize: '8', },
        //     body: summaryDatas,
        // }
        // doc.autoTable(summaryTable)

        doc.save(
            this.props.intl.formatMessage({
                id: 'components.HistoryDetail.eventType.overspeed',
            }) + '.pdf'
        );
    };

    timeToSeconds = (time) => {
        if (time === null) {
            return 0;
        }
        const matchesWithHoursMinutesSeconds = time.match(/(\d+)\s*h\s*(\d+)\s*m\s*(\d+)\s*s/);

        if (matchesWithHoursMinutesSeconds) {
            const hours = parseInt(matchesWithHoursMinutesSeconds[1], 10);
            const minutes = parseInt(matchesWithHoursMinutesSeconds[2], 10);
            const seconds = parseInt(matchesWithHoursMinutesSeconds[3], 10);
            return hours * 3600 + minutes * 60 + seconds;
        }

        const matchesWithHoursMinutes = time.match(/(\d+)\s*h\s*(\d+)\s*m/);

        if (matchesWithHoursMinutes) {
            const hours = parseInt(matchesWithHoursMinutes[1], 10);
            const minutes = parseInt(matchesWithHoursMinutes[2], 10);
            return hours * 3600 + minutes * 60;
        }

        const matchesWithMinutesSeconds = time.match(/(\d+)\s*m\s*(\d+)\s*s/);

        if (matchesWithMinutesSeconds) {
            const minutes = parseInt(matchesWithMinutesSeconds[1], 10);
            const seconds = parseInt(matchesWithMinutesSeconds[2], 10);
            return minutes * 60 + seconds;
        }

        const matchesWithSeconds = time.match(/(\d+)\s*s/);

        if (matchesWithSeconds) {
            const seconds = parseInt(matchesWithSeconds[1], 10);
            return seconds;
        }

        return 0;
    };

    handleOnExportXLS = () => {
        const { report } = this.props;
        const newReport = report.toJS();
        const overSpeedLimitReport = newReport.data.overSpeedLimit;
        // const activitySummarySheetReport = newReport.data.activitySummary

        // activitySummarySheetReport.meta.headers.utilisationTime = `${this.props.intl.formatMessage({
        //     id: 'containers.Reports.Generators.Driving.utilisationTimeInSec',
        // })}`
        // activitySummarySheetReport.meta.headers.totalTimeIdle = `${this.props.intl.formatMessage({
        //     id: 'containers.Reports.Generators.Driving.totalTimeIdleInSec',
        // })}`

        let blobType: string =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let overSpeedLimitSheet = workbook.addWorksheet(overSpeedLimitReport.meta.title, {
            headerFooter: { firstHeader: '', firstFooter: '' },
        });

        //Excel Header
        const generateAt = `${this.props.intl.formatMessage({
            id: 'components.Reports.move.pdf.generate',
        })}`;
        const reportText = `${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.Behavior',
        })} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${newReport.meta.filters.datetimeEnd} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${newReport.meta.filters.datetimeStart}`;

        overSpeedLimitSheet.mergeCells('A1', 'D1');
        overSpeedLimitSheet.getCell('A1').value = reportText;
        overSpeedLimitSheet.getCell('A3').value =
            generateAt + moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const isImperial = newReport.meta.isImperial;

        const distanceUnit = isImperial ? '(mi)' : '(km)';

        const speedUnit = isImperial ? '(mph)' : '(km/h)';

        let headers = {
            vehicleNumb: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Vehicule',
            })}`,
            driverName: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Driver',
            })}`,
            startTime: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Start',
            })}`,
            endTime: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.End',
            })}`,
            speed: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Speed',
            })} ${speedUnit}`,
            maximumSpeed: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.MaxSpeed',
            })} ${speedUnit}`,
            speedLimit: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.SpeedLimit',
            })} ${speedUnit}`,
            totalDistance: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Distance',
            })} ${distanceUnit}`,
            geoName: `${this.props.intl.formatMessage({
                id: 'containers.Reports.Overspeed.Headers.Geofence',
            })}`,
        };
        //Excel Body
        overSpeedLimitSheet.getRow(7).values = Object.values(headers);
        const columOverSpeed = Object.keys(headers);
        overSpeedLimitSheet.columns = columOverSpeed.map((item) => {
            return { key: item, width: 22 };
        });
        overSpeedLimitReport.data.forEach((item) => {
            if (item.geoName === null || item.geoName === '') {
                item.geoName = this.props.intl.formatMessage({
                    id: 'Component.Report.Overspeed.DefaultGeofence',
                });
            }
            if (item.driverName === 'Non identifié') {
                item.driverName = this.props.intl.formatMessage({
                    id: 'Component.Report.Overspeed.UnidentifiedDriver',
                });
            }
            overSpeedLimitSheet.addRow(item);
        });

        //Acvitity Summary Report
        // let activitySummarySheet = workbook.addWorksheet(activitySummarySheetReport.meta
        //     .title, {
        //     headerFooter: { firstHeader: "", firstFooter: "" }
        // });

        // activitySummarySheet.mergeCells('A1', 'D1');
        // activitySummarySheet.getCell('A1').value = reportText
        // activitySummarySheet.getCell('A3').value = generateAt + moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

        // activitySummarySheet.getRow(6).values = Object.values(activitySummarySheetReport.meta.headers);
        // const columSummary = Object.keys(activitySummarySheetReport.meta.headers);
        // activitySummarySheet.columns = columSummary.map((item) => {
        //     return { key: item, width: 20 }
        // })

        // activitySummarySheetReport.data.forEach((item, index) => {
        //     item.utilisationTime = this.timeToSeconds(item.utilisationTime);
        //     item.totalTimeIdle = this.timeToSeconds(item.totalTimeIdle);
        //     activitySummarySheet.addRow(item);
        // });

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: blobType });
            FileSaver.saveAs(
                blob,
                this.props.intl.formatMessage({
                    id: 'components.HistoryDetail.eventType.overspeed',
                })
            );
        });
    };

    handleOnSelectAllVehicles = (searchedEquipments) => {
        let equipmentIds;
        if (searchedEquipments.toArray().length > 0) {
            equipmentIds = searchedEquipments.map((equipment) => equipment.get('id')).toArray();
        } else {
            equipmentIds = this.props.equipment
                .filter((eq) => {
                    if (eq.toJS().cardNumber) {
                        return !eq.toJS().cardNumber.startsWith('B');
                    } else {
                        return true;
                    }
                })
                .map((equipment) => equipment.get('id'))
                .toArray();
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnUnselectAllVehicles = () => {
        const equipmentIds = [];
        this.setState({
            equipmentIds,
        });
    };

    onChangeSelect = (key: string) => (value: string) => {
        this.setState({ [key]: value });
    };

    // To be refactored...
    renderGenerator = () => {
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }
        return (
            <div>
                <DrivingReportGenerator style={{ display: 'flex' }}>
                    <ReportsInputDate
                        startDate={this.state.periodFrom}
                        onChangeStartDate={this.handleOnChangePeriod('periodFrom')}
                        errorsStartDate={this.props.errors.get('periodFrom')}
                        endDate={this.state.periodTo}
                        onChangeEndDate={this.handleOnChangePeriod('periodTo')}
                        errorEndDate={this.props.errors.get('periodTo')}
                        dateLimit={this.state.dateLimit}
                        dateLimitPositionLeft={this.state.dateLimitPositionLeft}
                    />

                    <DrivingReportGenerator.Column>
                        <InputLabel margin={'0 0 15px 0'}>
                            <FormattedMessage
                                id={'containers.Reports.Generators.Driving.OutputFormat'}
                            />
                        </InputLabel>
                        <DrivingReportGenerator.SelectContainer>
                            <SelectField
                                options={[
                                    {
                                        label: this.props.intl.formatMessage({
                                            id:
                                                'containers.Reports.Generators.Driving.OutputFormat.Page',
                                        }),
                                        value: OUTPUT_FORMATS.page,
                                    },
                                    {
                                        label: this.props.intl.formatMessage({
                                            id:
                                                'containers.Reports.Generators.Driving.OutputFormat.Pdf',
                                        }),
                                        value: OUTPUT_FORMATS.pdf,
                                    },
                                    {
                                        label: this.props.intl.formatMessage({
                                            id:
                                                'containers.Reports.Generators.Driving.OutputFormat.Xls',
                                        }),
                                        value: OUTPUT_FORMATS.xls,
                                    },
                                ]}
                                value={this.state.format}
                                borderColor={colors.grayED}
                                arrowColor={colors.green63}
                                onChange={this.onChangeSelect('format')}
                            />
                        </DrivingReportGenerator.SelectContainer>
                    </DrivingReportGenerator.Column>
                </DrivingReportGenerator>
                <DrivingReportGenerator.Column>
                    <DrivingReportGenerator.ButtonContainer>
                        <DrivingReportGenerator.DateButton
                            onClick={this.handleSetPeriod(PRESET_PERIODS.today)}
                        >
                            <FormattedMessage id={'containers.Reports.Generators.Driving.Today'} />
                        </DrivingReportGenerator.DateButton>
                        <DrivingReportGenerator.DateButton
                            onClick={this.handleSetPeriod(PRESET_PERIODS.yesterday)}
                        >
                            <FormattedMessage
                                id={'containers.Reports.Generators.Driving.Yesterday'}
                            />
                        </DrivingReportGenerator.DateButton>
                        <DrivingReportGenerator.DateButton
                            onClick={this.handleSetPeriod(PRESET_PERIODS.thisWeek)}
                        >
                            <FormattedMessage
                                id={'containers.Reports.Generators.Driving.ThisWeek'}
                            />
                        </DrivingReportGenerator.DateButton>
                        <DrivingReportGenerator.DateButton
                            onClick={this.handleSetPeriod(PRESET_PERIODS.lastWeek)}
                        >
                            <FormattedMessage
                                id={'containers.Reports.Generators.Driving.LastWeek'}
                            />
                        </DrivingReportGenerator.DateButton>
                        <DrivingReportGenerator.DateButton
                            onClick={this.handleSetPeriod(PRESET_PERIODS.thisMonth)}
                        >
                            <FormattedMessage
                                id={'containers.Reports.Generators.Driving.ThisMonth'}
                            />
                        </DrivingReportGenerator.DateButton>
                        <DrivingReportGenerator.DateButton
                            onClick={this.handleSetPeriod(PRESET_PERIODS.lastMonth)}
                        >
                            <FormattedMessage
                                id={'containers.Reports.Generators.Driving.LastMonth'}
                            />
                        </DrivingReportGenerator.DateButton>
                        <DrivingReportGenerator.SubmitButton onClick={this.handleGenerateReport}>
                            <FormattedMessage
                                id={'containers.Reports.Generators.Driving.Generate'}
                            />
                        </DrivingReportGenerator.SubmitButton>
                    </DrivingReportGenerator.ButtonContainer>
                </DrivingReportGenerator.Column>

                <Split color={colors.splitGrayReports} opacity={0.2} />

                <InputLabel margin={'30px 0 20px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.Vehicles'} />
                </InputLabel>

                {this.props.errors.get('equipmentIds') && (
                    <DrivingReportGenerator.Error>
                        <Error
                            text={this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.Driving.EquipmentIdsError',
                            })}
                        />
                    </DrivingReportGenerator.Error>
                )}

                <ReportVehicleSearch
                    equipment={this.props.equipment.filter((eq) => {
                        if (eq.toJS().cardNumber) {
                            return !eq.toJS().cardNumber.startsWith('B');
                        } else {
                            return true;
                        }
                    })}
                    onCheckVehicles={this.handleOnCheckVehicles}
                    checkedInputs={this.state.equipmentIds}
                    onSelectAllVehicles={this.handleOnSelectAllVehicles}
                    onUnselectAllVehicles={this.handleOnUnselectAllVehicles}
                />

                <Split color={colors.splitGrayReports} opacity={0.2} />
            </div>
        );
    };

    renderReport = () => {
        const { report } = this.props;
        const reportTitle = report.getIn(['meta', 'title']);
        const isImperial = report.getIn(['meta', 'isImperial']);
        const reportFilters = report.getIn(['meta', 'filters']);
        const reportFrom = dateFns.format(reportFilters.get('datetimeStart'), 'YYYY/MM/DD');
        const reportTo = dateFns.format(reportFilters.get('datetimeEnd'), 'YYYY/MM/DD');
        const reportFilterText = `${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${reportFrom} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${reportTo}`;
        const tables = [];

        const distanceUnit = isImperial ? '(mi)' : '(km)';
        const speedUnit = isImperial ? '(mph)' : '(km/h)';
        report.get('data').forEach((data, index) => {
            const dataTitle = data.get('meta').get('title');
            let entries = [
                [
                    'vehicleNumb',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.Vehicule',
                    })}`,
                ],
                [
                    'driverName',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.Driver',
                    })}`,
                ],
                [
                    'startTime',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.Start',
                    })}`,
                ],
                [
                    'endTime',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.End',
                    })}`,
                ],
                [
                    'speed',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.Speed',
                    })} ${speedUnit}`,
                ],
                [
                    'maximumSpeed',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.MaxSpeed',
                    })} ${speedUnit}`,
                ],
                [
                    'speedLimit',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.SpeedLimit',
                    })} ${speedUnit}`,
                ],
                [
                    'totalDistance',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.Distance',
                    })} ${distanceUnit}`,
                ],
                [
                    'geoName',
                    `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Overspeed.Headers.Geofence',
                    })}`,
                ],
            ];
            entries = entries.map((entry: [string, string]) => {
                return {
                    key: entry[0],
                    label: entry[1],
                    sortMethod: null,
                };
            });

            tables.push(
                <ReportDataTable
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    title={dataTitle}
                    columns={entries}
                    tableData={data.get('data')}
                />
            );
        });

        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        const getTitleReport = (title) => {
            switch (title) {
                case 'Rapport de Comportement de Conduite':
                    return this.props.intl.formatMessage({
                        id: 'containers.Reports.Generators.Behavior',
                    });
                case 'Driving report':
                    return this.props.intl.formatMessage({
                        id: 'views.Reports.SideMenu.Deplacements',
                    });

                default:
                    return reportTitle;
            }
        };
        return (
            <div style={{ height: '60px' }}>
                <DrivingReportGenerator.ReportType style={{ marginTop: 15, marginBottom: 40 }}>
                    {!this.state.reportGeneratedFilterOpen && (
                        <React.Fragment>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={ReportIcon}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />

                                {this.props.intl.formatMessage({
                                    id: 'containers.Reports.Generators.Behavior',
                                })}
                            </Report.TextContainer>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={Calendar}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportFilterText}
                            </Report.TextContainer>
                        </React.Fragment>
                    )}
                    <Report.ModifyReport onClick={this.handleOnClickModifyReport}>
                        <Report.Icon
                            src={MagnifyingGlass}
                            width={20}
                            height={20}
                            margin={'0 0 0 17px'}
                        />
                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                            <FormattedMessage id={'containers.Reports.Generators.Modify'} />
                        </p>
                    </Report.ModifyReport>
                </DrivingReportGenerator.ReportType>
                <SlideDown className={'generator'}>
                    {this.state.reportGeneratedFilterOpen ? this.renderGenerator() : null}
                </SlideDown>
                {tables}
            </div>
        );
    };
    renderDisplay = () => {
        let display;
        if (this.props.report && this.props.report.size > 0 && this.state.isGenerated === false) {
            display = this.renderGenerator();
        } else if (this.props.report && this.props.report.size > 0) {
            display = this.renderReport();
        } else {
            display = this.renderGenerator();
        }
        return display;
    };
    render() {
        return (
            <ReportGeneratorContainer>
                {this.props.drivingReportModal ? <DrivingReportModal /> : ''}
                <GenerateReportHeader
                    report={this.props.report}
                    title={this.props.intl.formatMessage({
                        id: 'views.Reports.SideMenu.Deplacements',
                    })}
                    onExportPDF={
                        !this.state.reportGeneratedFilterOpen &&
                        this.state.isGenerated === true &&
                        this.state.format === 'display'
                            ? this.handleOnExportPDF
                            : null
                    }
                    onExportXLS={
                        !this.state.reportGeneratedFilterOpen &&
                        this.state.isGenerated === true &&
                        this.state.format === 'display'
                            ? this.handleOnExportXLS
                            : null
                    }
                />
                <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} />

                {this.renderDisplay()}
            </ReportGeneratorContainer>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    errors: selecDrivingReportGenerationErrors(),
    loading: selectIsLoading(),
    report: selectDrivingReport(),
    equipment: selectVehicles(),
    drivingReportModal: selectDrivingReportModal(),
    unit: selectUnit(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            validateDrivingReportGenerator,
            generateDrivingReport,
            removeDrivingReportError,
            clearDrivingReport,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
