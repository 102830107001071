// @flow

import { fromJS } from 'immutable';
import {
    GET_CONFIG_BY_EQUIPEMENT_SUCCESS,
    GET_CONFIG_ERROR,
    GET_MODULE_BY_EQUIPEMENT_SUCCESS,
    GET_SETTINGS_BY_EQUIPEMENT_SUCCESS,
    GET_MODULE_ERROR,
    LOADING_CONFIG,
    DISMISS_CONFIG_LOADING,
    LOADING_EMAILS,
    GET_EMAIL_NOTIF_CONFIG_SUCCESS,
    GET_EMAILs_BRANCH_SUCCESS,
    GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS,
    DISMISS_CONFIG_ERROR,
    GET_ENGINE_CONFIG_GLOBAL_ACTIVATION,
    UPDATED_ENGINE_CONFIG_GLOBAL_ACTIVATION,
    GET_ENGINE_CONFIG_CONDITIONS,
    SET_ENGINE_CONDITION,
    SET_NEW_CONDITION,
    RESET_GLOBAL_ACTIVATION,
    GET_ENGINE_CONFIG_DELAYS ,
} from './actions';

import type { RoutificRecords } from 'types';

const initialState = fromJS({
    EmailNotifConfigRecords: {},
    ConfigRecords: {},
    ModuleRecords: {},
    SettingsRecords: {},
    UnauthorizedMoveRecords: {},
    Emails: {},
    configError: false,
    loading: false,
    loadingEmails: false,
    globalActivation: {},
    engineConditions: {},
    newCondition: true,
    engineConditionsDelays: {},
});

function configServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case GET_CONFIG_BY_EQUIPEMENT_SUCCESS: {
            return state.set('ConfigRecords', fromJS(action.ConfigRecords)).set('loading', false);
        }
        case GET_EMAIL_NOTIF_CONFIG_SUCCESS: {
            return state
                .set('EmailNotifConfigRecords', fromJS(action.EmailNotifConfigRecords))
                .set('loading', false);
        }
        case GET_UNAUTHORIZED_MOVE_CONFIG_SUCCESS: {
            return state
                .set('UnauthorizedMoveRecords', fromJS(action.UnauthorizedMoveRecords))
                .set('loading', false);
        }
        case GET_EMAILs_BRANCH_SUCCESS: {
            return state.set('Emails', fromJS(action.Emails)).set('loadingEmails', false);
        }
        case GET_CONFIG_ERROR: {
            return state.set('configError', true).set('loading', false);
        }
        case DISMISS_CONFIG_ERROR: {
            return state.set('configError', false).set('loading', false);
        }
        case GET_SETTINGS_BY_EQUIPEMENT_SUCCESS: {
            return state
                .set('SettingsRecords', fromJS(action.SettingsRecords))
                .set('loading', false);
        }
        case GET_MODULE_BY_EQUIPEMENT_SUCCESS: {
            return state.set('ModuleRecords', fromJS(action.ModuleRecords)).set('loading', false);
        }
        case LOADING_CONFIG:
            return state.set('configError', false).set('loading', true);
        case DISMISS_CONFIG_LOADING: {
            return state.set('loading', false);
        }
        case LOADING_EMAILS:
            return state.set('loadingEmails', true);
        case GET_MODULE_ERROR: {
            return state.set('configError', true).set('loading', false);
        }
        case GET_ENGINE_CONFIG_GLOBAL_ACTIVATION: {
            return state
                .set('globalActivation', fromJS(action.globalActivation))
                .set('loading', false);
        }
        case UPDATED_ENGINE_CONFIG_GLOBAL_ACTIVATION: {
            return state.set('loading', false);
        }
        case GET_ENGINE_CONFIG_CONDITIONS: {
            return state
                .set('engineConditions', fromJS(action.engineConditions))
                .set('loading', false);
        }
        case SET_ENGINE_CONDITION: {
            return state
                .set('conditionToEdit', fromJS(action.conditions))
                .set('newCondition', false)
                .set('loading', false);
        }
        case SET_NEW_CONDITION: {
            return state.set('newCondition', true).set('loading', false);
        }
        case RESET_GLOBAL_ACTIVATION: {
            return state.set('globalActivation', fromJS({}));
        }
        case GET_ENGINE_CONFIG_DELAYS: {
            return state
                .set('engineConditionsDelays', action.engineConditionsDelays)
                .set('loading', false);
        }
        default:
            return state;
    }
}

export default configServiceReducer;
