// @flow

import request from 'services/request';
import adminRequest from 'services/adminRequest';

import type { Organization, UserDataType, AssociateUserType } from 'types';

/**
 * retrieves an array of Organizations from the backend.
 * @param {*} token
 */
export function retrieveOrganizations(
    page: number,
    token: string,
    locale: string,
    query: string
): () => Promise<> {
    const perPage = 10;
    const queryString = query ? `&name=${query}` : '';
    return adminRequest(
        `branches?pageSize=${perPage}&pageNumber=${page}${queryString}`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveOrganizationBranches(id: number, token: string, locale: string): Promise<> {
    return request(
        `${locale}/organizations/${id}/branches`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveOrganizationUsers(id: number, token: string, locale: string, page: number): Promise<> {
    return adminRequest(
        `users?branchId=${id}&pageNumber=${page}`,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function retrieveOrganizationEquipment(
    id: number,
    token: string,
    locale: string
): Promise<> {
    return adminRequest(
        `vehicles?branchId=${id}&pageSize=10000`,
        {
            method: 'GET',
        },
        false,
        token
    );
}

export function retrieveOrganization(id: number, token: string, locale: string): Promise<> {
    return adminRequest(
        `branches?branchId=${id}&includeChildBranches=true`,
        {
            method: 'GET',
        },
        true,
        token
    ).then((res) => {
        let data;
        if (res.data.branches.length > 1) {
            data = res.data.branches.reduce(
                (prev, curr) => {
                    if (curr.id === Number(id)) {
                        prev.organization = curr;
                    } else {
                        prev.children.push(curr);
                    }
                    return prev;
                },
                {
                    children: [],
                    organization: {},
                }
            );
        } else {
            data = {
                organization: res.data.branches[0],
                children: [],
            };
        }
        return data;
    });
}

export function retrieveOrganizationParent(parentId: number, token: string) {
    return adminRequest(
        `branches/${parentId}`,
        {
            method: 'GET',
        },
        false,
        token
    ).then((res) => res.data);
}

export function retrievebranchesData(branchId: number, token: string) {
    return adminRequest(
        `branches/${branchId}`,
        {
            method: 'GET',
        },
        false,
        token
    ).then((res) => res.data);
}

export function retrieveBranch(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveBranchUsers(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/users`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retrieveBranchDivisions(
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivision(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivisionEquipment(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/equipment`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivisionUsers(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/users`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

export function retreiveDivisionOperators(
    organizationId: number,
    branchId: number,
    divisionId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}/operators`,
        {
            method: 'GET',
        },
        true,
        token
    );
}

const defaultOrganization = {
    parentId: null,
    branchName: '',
    contact: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    fax: '',
    cellPhone: '',
    otherPhone: '',
    email: '',
    isOrca: true,
    units: '',
    timezone: '',
    comments: '',
    isSystemBranch: false,
};

export function createOrganizationRequest(
    data: Organization,
    token: string,
    locale: string
): Promise<> {
    return adminRequest(
        `branches`,
        {
            method: 'POST',
            data,
        },
        false,
        token
    );
}

export function createBranchRequest(
    data: Organization,
    organizationId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches`,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function createDivisionRequest(
    data: Organization,
    organizationId: number,
    branchId: number,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions`,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function createUserRequest(data: UserDataType, token: string, locale: string): Promise<> {
    return adminRequest(
        `users`,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

export function editUserRequest(
    userId: number,
    data: UserDataType,
    token: string,
    locale: string
): Promise<> {
    return adminRequest(
        `users/${userId}`,
        {
            method: 'PATCH',
            data: {
                ...data,
            },
        },
        false,
        token
    );
}

export function createUserAssociationRequest(
    url: string,
    data: AssociateUserType,
    token
): Promise<> {
    return request(
        url,
        {
            method: 'POST',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

const removeNulls = (obj) => {
    const keys = Object.keys(obj);
    let newObj = {};
    if (obj instanceof Array) {
        newObj = [];
    }
    for (const key of keys) {
        if (obj[key] instanceof Object && obj[key] !== null) {
            newObj[key] = removeNulls(obj[key]);
        } else {
            if (obj[key]) {
                newObj[key] = obj[key] || '';
            }
        }
    }
    return newObj;
};

export function editOrganizationRequest(
    organizationId: number,
    org: Organization,
    token: string,
    locale: string
): Promise<> {
    const { equipment, users, ...data } = org;
    if (!data.parentId) delete data.parentId;
    const noNullData = removeNulls(data);
    return adminRequest(
        `branches/${organizationId}`,
        {
            method: 'PUT',
            data: noNullData,
        },
        false,
        token
    );
}

export function editBranchRequestByField(branchId: number, data: Any, token: string): Promise<> {
    const noNullData = removeNulls(data);
    return adminRequest(
        `branches/${branchId}`,
        {
            method: 'PATCH',
            data: noNullData,
        },
        false,
        token
    );
}

export function deleteOrganizationRequest(branchId: string, token: string): Promise<> {
    return adminRequest(
        `branches/${branchId}`,
        {
            method: 'DELETE',
        },
        false,
        token
    );
}

export function editBranchRequest(
    organizationId: number,
    branchId: number,
    data: Organization,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}`,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function editDivisionRequest(
    organizationId: number,
    branchId: number,
    divisionId: number,
    data: Organization,
    token: string,
    locale: string
): Promise<> {
    return request(
        `${locale}/organizations/${organizationId}/branches/${branchId}/divisions/${divisionId}`,
        {
            method: 'PUT',
            data: {
                ...data,
            },
        },
        true,
        token
    );
}

export function deleteUserRequest(userId: number, token: string, locale: string): Promise<> {
    return adminRequest(
        `users/${userId}`,
        {
            method: 'DELETE',
        },
        true,
        token
    );
}
