// @flow

import { fromJS } from 'immutable';
import {
    GET_DIVISION_DATA_SUCCESS,
    GET_DIVISION_SUCCESS,
    GET_DIVISION_USER_SUCCESS,
    EDIT_DIVISION_SUCCESS,
    DELETE_DIVISION_SUCCESS,
    DELETE_DIVISION_USER_SUCCESS,
    DELETE_DIVISION_EQUIPMENT,
} from './actions';

import {
    ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS,
    EDIT_EQUIPMENT_DIVISION_SUCCESS,
} from 'services/Equipment/actions';

import type { GenericActionType } from 'types';

const initialState = fromJS({
    selectedDivision: {},
});

function divisionServiceReducer(state: Object = initialState, action: GenericActionType) {
    switch (action.type) {
        case GET_DIVISION_DATA_SUCCESS: {
            const { division, ...data } = action.division;
            return state.set('selectedDivision', fromJS({ ...division, ...data }));
        }
        case GET_DIVISION_USER_SUCCESS: {
            return state.setIn(
                ['selectedDivision', 'users'],
                state.getIn(['selectedDivision', 'users']).push(fromJS(action.user))
            );
        }
        case EDIT_DIVISION_SUCCESS: {
            return state.set(
                'selectedDivision',
                state.get('selectedDivision').merge(action.division)
            );
        }
        case DELETE_DIVISION_SUCCESS: {
            return state.setIn(['selectedDivision', 'deleted'], true);
        }
        case DELETE_DIVISION_USER_SUCCESS: {
            const userIndex = state
                .getIn(['selectedDivision', 'users'])
                .findIndex((value) => value.get('userId') === action.userId);

            return state.setIn(
                ['selectedDivision', 'users'],
                state.getIn(['selectedDivision', 'users']).delete(userIndex)
            );
        }
        case ASSOCIATE_EQUIPMENT_DIVISION_SUCCESS: {
            return state.setIn(
                ['selectedDivision', 'equipment'],
                state.getIn(['selectedDivision', 'equipment']).push(fromJS(action.equipmentType))
            );
        }
        case DELETE_DIVISION_EQUIPMENT: {
            const equipmentIndex = state
                .getIn(['selectedDivision', 'equipment'])
                .findIndex((equipment) => equipment.get('id') === action.equipmentId);
            return state.setIn(
                ['selectedDivision', 'equipment'],
                state.getIn(['selectedDivision', 'equipment']).delete(equipmentIndex)
            );
        }
        case EDIT_EQUIPMENT_DIVISION_SUCCESS: {
            const equipmentIndex = state
                .getIn(['selectedDivision', 'equipment'])
                .findIndex((equipment) => equipment.get('id') === action.equipment.id);
            return state.setIn(
                ['selectedDivision', 'equipment'],
                state
                    .getIn(['selectedDivision', 'equipment'])
                    .update(equipmentIndex, () => fromJS(action.equipment))
            );
        }
        default:
            return state;
    }
}

export default divisionServiceReducer;
