// @flow

export const CREATE_USER_FAIL = 'app/services/Organization/CREATE_USER_FAIL';

export const DELETE_USER_ERROR = 'app/services/Organization/DELETE_USER_ERROR';
export const EDIT_USER_SUCCESS = 'app/services/Organization/EDIT_USER_SUCCESS';

export const CREATE_USER_VALIDATION_FAILED =
    'app/services/Organization/CREATE_USER_VALIDATION_FAILED';

/**
 * Unconventional action here.
 * The type of the action is dependant on the type of user we create, ie: Organization user, branch user, or division user.
 * We'll store each user object differently.
 * @param {string} type
 * @param {UserDataType} user
 */
export const receivedCreateUserSuccess = (type: string, user: UserDataType) => ({
    type,
    user,
});

export const receivedCreateUserFailed = (error: any) => ({
    type: CREATE_USER_FAIL,
    error,
});

export const receivedDeleteUserError = (property: ?string) => ({
    type: DELETE_USER_ERROR,
    property,
});

export const receivedEditUserSuccess = (user) => ({
    type: EDIT_USER_SUCCESS,
    user,
});

export const receivedCreateUserValidationError = (errors: any) => ({
    type: CREATE_USER_VALIDATION_FAILED,
    errors,
});
