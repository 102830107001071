// @flow

// IMPORTS
import dateFns from 'date-fns';

// THUNKS
import { setSelectedVehicles } from 'services/SideMenu/thunks';
import { renewUserSession } from 'services/Authentication/thunks';
import moment from 'moment';

// ACTIONS
import {
    receivedMaintenanceVehHistorySuccess,
    receivedMaintenanceSuccess,
    receivedMaintenanceError,
    receivedVehicleSuccess,
    receivedVehicleAlarmSuccess,
    receivedConfigMaintenanceSuccess,
    receivedMaintenanceVehByMaintIdSuccess,
    receivedReinitializeAlarmsSuccess,
    receivedMaintenanceEmailSuccess,
    receivedMaintenanceMsgSuccess,
} from './action';

// RESOURCES
import {
    retrieveMaintenanceVehicleHistoryById,
    retrieveMaintenanceVehicleByMaintId,
    retrieveMaintenanceEmail,
    updateMaintenanceVehicle,
    retrieveMaintenanceByBranchId,
    addMaintenance,
    addMaintenanceVehicle,
    deleteMaintenance,
    deleteMaintenanceVehicle,
    deleteMaintenanceEmail,
    updateMaintenance,
    addMaintenanceVehicleInformation,
    addMaintenanceEmail,
    getMaintMsgById,
    editMaintMsgById,
    deleteAllMaintenanceVehicle, retrieveAllMaintenances, addDeleteMaintenaceVehicle, addDeleteMaintenaceEmails,
} from './resources';

// TYPES
import type { ImmutableMap } from 'types';

// SERVICES
import auth0 from 'services/Authentication/auth0';
import { MAPBOX_GL_TOKEN } from '../../env';

/**
 *
 * @param {*} id
 */
export const getMaintenanceVeh = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return retrieveMaintenanceVehicleHistoryById(token, locale, id)
        .then((response: Object) => {
            const vehAlarms = response.data;
            // console.log(vehAlarms);
            dispatch(receivedMaintenanceVehHistorySuccess(vehAlarms));
            return vehAlarms;
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 */
export const getMaintenanceVehByMaintId = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return retrieveMaintenanceVehicleByMaintId(token, locale, id)
        .then((response: Object) => {
            // const vehAlarms = response.data;
            return response.data;
            //dispatch(receivedMaintenanceVehByMaintIdSuccess(vehAlarms));
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 */
export const getMaintenance = (id: number) => (dispatch: ReduxDispatch, getState: ImmutableMap) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return retrieveMaintenanceByBranchId(token, locale, id)
        .then((response: Object) => {
            const alarms = response.data;
            dispatch(receivedMaintenanceSuccess(alarms));
            return alarms;
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 */
export const getMaintenanceEmail = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return retrieveMaintenanceEmail(token, locale, id)
        .then((response: Object) => {
            // const data = response.data;
            return response.data;
            // dispatch(receivedMaintenanceEmailSuccess(data));
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 */
export const getMaintenanceMessage = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return getMaintMsgById(token, id)
        .then((response: Object) => {
            const data = response.data;
            dispatch(receivedMaintenanceMsgSuccess(data));
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} config
 */
export const setConfigMaintenance = (config: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    dispatch(receivedConfigMaintenanceSuccess(config));
};

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const patchMaintenance = (id: number, data: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return updateMaintenance(token, locale, id, data)
        .then((response: Object) => {
            // dispatch(receivedMaintenanceSuccess(alarms));
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const patchMaintenanceVehicle = (id: number, data: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return updateMaintenanceVehicle(token, locale, id, data)
        .then((response: Object) => {
            // dispatch(receivedMaintenanceSuccess(alarms));
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const insertMaintenance = (id: number, data: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return addMaintenance(token, locale, id, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const insertMaintenanceVehicle = (data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return addMaintenanceVehicle(token, locale, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};


/**
 *
 * @param {object} data
 * @param {number} branchId
 * @param {number} maintId
 */
export const linkUnlinkVehicleMaintenance = (branchId: number, maintId: number, data: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return addDeleteMaintenaceVehicle(token, locale, branchId, maintId, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};


/**
 *
 * @param {object} data
 * @param {number} branchId
 * @param {number} maintId
 */
export const linkUnlinkEmailsMaintenance = (branchId: number, maintId: number, data: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return addDeleteMaintenaceEmails(token, locale, branchId, maintId, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};
/**
 *
 * @param {*} id
 * @param {*} data
 */
export const insertMaintenanceEmail = (data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return addMaintenanceEmail(token, locale, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const insertMaintenanceMessage = (id: number, data: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return editMaintMsgById(token, id, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} id
 * @param {*} data
 */
export const insertMaintenanceInformation = (id: number, data: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return addMaintenanceVehicleInformation(token, id, data)
        .then((response: Object) => {
            // dispatch(receivedMaintenanceSuccess(alarms));
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} vehicle
 */
export const SetSelectedVehicle = (vehicle: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    dispatch(receivedVehicleSuccess(vehicle));
};

/**
 *
 * @param {*} vehicle
 */
export const setSelectedVehicleAlarms = (vehicle: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    dispatch(receivedVehicleAlarmSuccess(vehicle));
};

/**
 *
 * @param {*} vehicle
 */
export const setReinitializeAlarms = (alarms: object) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    dispatch(receivedReinitializeAlarmsSuccess(alarms));
};

/**
 *
 * @param {*} vehicle
 */
export const removeAlarmVehicle = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return deleteMaintenance(token, locale, id)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} vehicle
 */
export const removeMaintenanceAlarmVehicle = (data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return deleteMaintenanceVehicle(token, locale, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} vehicle
 */
export const removeMaintenanceEmail = (data: string) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);
    return deleteMaintenanceEmail(token, locale, data)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};

/**
 *
 * @param {*} vehicle
 */
export const removeAllMaintenanceVehicle = (id: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    return deleteAllMaintenanceVehicle(token, id)
        .then((response: Object) => {
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};


/**
 *
 * @param {*} branchId
 */
export const getAllMaintenances = (branchId: number) => (
    dispatch: ReduxDispatch,
    getState: ImmutableMap,
) => {
    const token = getState().getIn(['auth', 'user', 'token']);
    const locale = getState().getIn(['language', 'locale']);

    return retrieveAllMaintenances(token, locale, branchId)
        .then((response: Object) => {
            return response.data;
        })
        .catch((error) => {
            if (error) return dispatch(receivedMaintenanceError());
        });
};
