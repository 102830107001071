// @flow

import React from 'react';
import { Close, Open } from './styles';
import { Image } from 'styles/sidemenu';

import OpenArrow from 'assets/open-arrow.svg';
import { ArrowLeft, ArrowRight } from 'components/Icons';

type Props = {
    onClick: (event: Event) => void,
    isOpen: boolean,
    offset: ?number,
    mapPage: ?boolean,
    zIndex: ?number,
    leftStyle: ?string,
    top: ?number,
    size: ?number,
    backgroundColor: ?string,
};

function CloseButton(props: Props) {
    return props.isOpen ? (
        <Close
            mapPage={props.mapPage}
            onClick={props.onClick}
            offset={props.offset}
            rightOffset={props.rightOffset}
            leftOffset={props.leftOffset}
            zIndex={props.zIndex}
            leftStyle={props.leftStyle}
            top={props.top}
            size={props.size}
            backgroundColor={props.backgroundColor}
        >
            <ArrowLeft />
        </Close>
    ) : (
        <Open
            mapPage={props.mapPage}
            onClick={props.onClick}
            offset={props.offset}
            rightOffset={props.rightOffset}
            leftOffset={props.leftOffset}
            zIndex={props.zIndex}
            leftStyle={props.leftStyle}
            top={props.top}
            size={props.size}
            backgroundColor={props.backgroundColor}
        >
            <ArrowRight />
        </Open>
    );
}

export default CloseButton;
