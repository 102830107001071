import React, { useEffect, useState } from 'react';
import '/app/styles/scss/global.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import './styles.scss';
import { ApDelete } from '../Icons';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import { cancelReservations } from '../../services/AutoPartage/thunks';
import { bindActionCreators } from 'redux';
import { selectRows } from "../../services/AutoPartage/selectors";
import { createStructuredSelector } from 'reselect';
const ApDeleteConfirmation = (props) => {
    const [open, setOpen] = React.useState(false);
 
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        props.cancelReservations(props.selectedRows);
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Button className={'delete'} onClick={handleClickOpen}>
                <ApDelete />
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id={'components.ap.delete.Confirmation.title'} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={'components.ap.delete.Confirmation.message'} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={'refuse_btn'}>
                        <FormattedMessage id={'components.ap.delete.Confirmation.No'} />
                    </Button>
                    <Button onClick={handleCancel} autoFocus className={'agree_btn'}>
                        <FormattedMessage id={'components.ap.delete.Confirmation.Yes'} />
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
const mapStateToProps = createStructuredSelector({
  selectedRows: selectRows(),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            cancelReservations,
        },
        dispatch
    );

export default withRouter(
    connect(
      mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ApDeleteConfirmation))
);


