// @flow

import React, { type Node, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { fromJS } from 'immutable';
import { selectIsSideMenuOpen } from 'services/SideMenu/selectors';

import OutlineButton from 'components/OutlineButton';
import DashboardDataTable from 'components/DashboardDataTable';
import SelectField from 'components/SelectField';
import Modal from 'components/Modals';
import CreateModal from 'components/Modals/CreateOrganizationModal';
import Loader from 'components/Loader';

import DataListHeaderContainer from 'containers/DataListHeaderContainer';

import { UsersList, SearchInput, Input } from './styles';
import { TableWrapper } from '../../styles/dashboard';
import colors from 'styles/colors';

import CaretDownImage from 'assets/caret-down.png';
import CaretUpImage from 'assets/caret-up.png';
import { Search } from 'components/Icons';

import { CREATE_ORGANIZAITON, MODAL_TYPE } from 'services/Modal/actions';

// Redux
import { fetchUsersWithQuery } from 'services/Admin/thunks';
import { selectQueryUsers, selectUsersLoading } from 'services/Admin/selectors';

export const UsersListView = (props) => {
    const {
        getUserData,
        isOpen,
        loading,
        users,
        intl,
        fetchUsersWithQuery,
    } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [usersPagination, setUsersPagination] = useState(0);

    const handleViewMoreUsers = () => {
        const page = usersPagination.get('currentPage') || 0;
        //fetchUsers(page + 1, searchQuery);
    };

    const handleOnChange = async (e: Event) => {
        const searchQuery = e.target.value.trim();
        setSearchQuery(searchQuery);
        if (searchQuery.length > 2 && !loading) {
            fetchUsersWithQuery(searchQuery, 1);
            setUsersPagination(1);
        }
    };

    const handleKeyUp = async (e: Event) => {
        if (e.key === 'Escape') {
            e.target.value = '';
            const searchQuery = '';
        } else {
            await handleOnChange(e);
        }
    };

    const handleOnClickDataItem = (id) => {
        props.history.push(`/${props.intl.locale}/interne/orgs/${id}`);
    }

    const organizationsHeaders = [
        {
            label: intl.formatMessage({
                id: 'components.DashboardData.Header.Name',
            }),
            key: 'name',
        },
        {
            label: intl.formatMessage({
                id: 'components.Modals.Form.Organization.Email',
            }),
            key: 'email',
        },
        {
            label: intl.formatMessage({
                id: 'views.OrganizationPage.Head.Title',
            }),
            key: 'branchName',
        },
    ];

    return (
        <UsersList
            isOpen={isOpen}
        >
            <Helmet>
                    <title>
                         Service Client - Admin
                    </title>
                <meta
                    name="description"
                    content={intl.formatMessage({
                        id: 'components.SideMenuOrganization.Users',
                    })}
                />
            </Helmet>
            <DataListHeaderContainer
                name={intl.formatMessage({ id: 'components.SideMenuOrganization.Users' })}
            />
            <UsersList.Split height={'1px'} color={colors.grayDD} />

            <UsersList.FilterContainer>
                <UsersList.SearchContainer>
                    <SearchInput>
                        <Input
                            placeholder={intl.formatMessage({ id: 'components.SearchInput.Placeholder' })}
                            onKeyUp={async (e) => { await handleKeyUp(e) }}
                        />
                        <Search />
                </SearchInput>
                </UsersList.SearchContainer>
            </UsersList.FilterContainer>

            <Loader loading={loading} />

            <TableWrapper>
                <DashboardDataTable
                    id={'branchId'}
                    columns={organizationsHeaders}
                    tableData={users}
                    onClickDataItem={handleOnClickDataItem}
                    onClickViewMore={()=>{}}
                    loading={loading}
                    list
                />
            </TableWrapper>
        </UsersList>
    );
}

const mapStateToProps: Object = createStructuredSelector({
    isOpen: selectIsSideMenuOpen(),
    users: selectQueryUsers(),
    loading: selectUsersLoading(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            fetchUsersWithQuery,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(UsersListView))
);
