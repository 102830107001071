// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { ReportGeneratorContainer } from 'styles/reports';
import { selectDrivingBehaviourReport, selectIsLoading } from 'services/Report/selectors';
import Loader from 'components/Loader';
import { SISENSE_URL } from 'env';

export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    render() {

        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        return (
            <div>
                <ReportGeneratorContainer>
                    <iframe
                        style={{
                            width: '100%',
                            height: '97vh',
                            border: 'none',
                        }}
                        frameborder="0"
                        src={`${SISENSE_URL}/app/main#/dashboards/${this.props.match.params.dashboardId}?embed=true&l=false&r=true&t=true&h=false&filter=${encodeURIComponent(JSON.stringify([]))}`}
                        scrolling="no"
                    />
                </ReportGeneratorContainer>
            </div>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    loading: selectIsLoading(),
    selectDrivingBehaviourReport: selectDrivingBehaviourReport(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
