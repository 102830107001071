// @flow

import React from 'react';
import type { ReduxDispatch } from 'types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import Modal from 'components/Modals/ChangePassword';
import { selectModalType, selectModalLoading, selectModalError } from 'services/Modal/selectors';
import { closeModal, toggleChangePasswordModal, changeUserPassword } from 'services/Modal/thunks';

class ChangePasswordContainer extends React.Component<Props, State> {
    state = {
        title: this.props.intl.formatMessage({
            id: 'components.Modals.Edit.Password',
        }),
        old_password: {
            value: '',
            error: null,
            placeholder: this.props.intl.formatMessage({
                id: 'components.Modals.Form.Password.OldPassword',
            }),
        },
        new_password: {
            value: '',
            error: null,
            placeholder: this.props.intl.formatMessage({
                id: 'components.Modals.Form.Password.NewPassword',
            }),
        },
        confirm_password: {
            value: '',
            error: null,
            placeholder: this.props.intl.formatMessage({
                id: 'components.Modals.Form.Password.ConfirmPassword',
            }),
        },
        error: null,
        loading: false,
    };

    handleModalInputChange = (event) => {
        const modal = this.state;
        modal[event.currentTarget.name].value = event.currentTarget.value;
        this.setState({
            modal,
        });
    };

    handleModalSubmit = (event) => {
        this.props.changeUserPassword(
            this.state.old_password.value,
            this.state.new_password.value,
            this.state.confirm_password.value
        );
        event.preventDefault();
    };

    render() {
        return (
            <Modal
                title={this.state.title}
                change={this.handleModalInputChange}
                loading={this.props.modalLoading}
                old={this.state.old_password}
                newer={this.state.new_password}
                confirm={this.state.confirm_password}
                error={
                    this.props.modalError || this.props.modalError === 403
                        ? {
                              message: this.props.intl.formatMessage({
                                  id: 'components.Modals.Form.Password.Error.NotAuthorized',
                              }),
                          }
                        : null
                }
                submit={{
                    label: this.props.intl.formatMessage({
                        id: 'components.Modals.Send',
                    }),
                    action: this.handleModalSubmit,
                }}
                cancel={{
                    label: this.props.intl.formatMessage({
                        id: 'components.Modals.Cancel',
                    }),
                    action: this.props.closeModal,
                }}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    modalType: selectModalType(),
    modalLoading: selectModalLoading(),
    modalError: selectModalError(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            toggleChangePasswordModal,
            changeUserPassword,
            closeModal,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ChangePasswordContainer));
