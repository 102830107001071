// @flow

import React, { useState, type Node } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import dateFns from 'date-fns';

import { Split } from 'styles/common';
import colors from 'styles/colors';

import {
    ReportGeneratorContainer,
    DrivingReportGenerator,
    InputLabel,
    Report,
} from 'styles/reports';

import { OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';

import ReportDataTable from 'containers/ReportDataTableMove';
import GenerateReportHeader from 'containers/ReportGenerator/Header';

import ReportVehicleSearch from 'components/ReportVehicleSearch';
import { Dashboard } from 'styles/dashboard';
import FormInputTime from 'components/FormInputTime';
import FormInputDate from 'components/FormInputDate';

import Loader from 'components/Loader';

import Error from 'components/Error';
import Chip from 'components/Chip';
import SelectField from 'components/SelectField';

import MagnifyingGlass from 'assets/search-icon-white.svg';
import ReportIcon from 'assets/report-pen.svg';
import Calendar from 'assets/calendar-black.svg';

import {
    validateMoveReportGenerator,
    generateMoveReport,
    removeMoveReportError,
    resetMoveReport,
} from 'services/Report/thunks';
import {
    selectMoveReportGenerationErrors,
    selectIsLoading,
    selectMoveReport,
} from 'services/Report/selectors';

import { selectVehicles } from 'services/Vehicle/selectors';
import { selectUnit } from 'services/Units/selectors';
import { isImperial } from 'services/Units/thunks';

import { OUTPUT_FORMATS, REPORT_TYPES, PRESET_PERIODS } from 'services/Report/constants';
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import Logo from 'assets/orca-logo-black-min.png';
import AutoComplete from 'components/AutoComplete';

import Paper from '@material-ui/core/Paper';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import {
    Grid,
    TableHeaderRow,
    VirtualTable,
    TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import ReportsInputDate from "../../../components/ReportsInputDate";
import { CollectionsBookmarkRounded } from '@material-ui/icons';

const today = dateFns.format(new Date(), 'YYYY-MM-DD');
const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} />;
type Props = {};

type State = {
    equipmentIds: Array<number>,
    dynamicData: Array,
    periodFrom: string,
    periodTo: string,
    period: string,
    dateLimit: boolean,
    dateLimitPositionLeft: boolean,
    reportGeneratedFilterOpen: boolean,
    format: string,
    type: string,
    street: string,
    placeName: string,
    position: Array<number>,
    periodTimeFrom: string,
    periodTimeTo: string,
    expandedRowIds: Array<string>,
};
let exportData = [];
const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter((r) => r.parentId === (row ? row.id : null));
    return childRows.length ? childRows : null;
};
export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    state = {
        dynamicData: [],
        equipmentIds: [],
        periodFrom: '',
        periodTo: '',
        dateLimit: false,
        dateLimitPositionLeft: true,
        period: PRESET_PERIODS.custom,
        reportGeneratedFilterOpen: false,
        format: OUTPUT_FORMATS.page,
        type: REPORT_TYPES.detailed,
        street: '',
        placeName: '',
        position: [],
        periodTimeFrom: '',
        periodTimeTo: '',
        expandedRowIds: [],
        data: [],
        columns: [],
        tableColumnExtensions: [],
        reportFilter: 'vehicle',
        gridHeight: `${document.body.clientHeight - 180}px`,
    };

    componentDidMount = () => {
        this.props.resetMoveReport();
        this.setState({ periodFrom: today, periodTo: today });

        if (this.props.report && this.props.report.size > 0) {
            this.handleGenerateReport(false);
        }

        const reportWindowSize = () => {
            this.setState({ gridHeight: `${document.body.clientHeight - 180}px` });
        };
        window.addEventListener('resize', reportWindowSize);
    };
    componentDidUnMount = () => {
        exportData = [];
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.unit && prevProps.unit !== this.props.unit) {
            if (this.props.report.size > 0) {
                this.handleGenerateReport(true);
            }
        }
    };

    hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    intToARGB = (i) => {
        let hex =
            ((i >> 24) & 0xff).toString(16) +
            ((i >> 16) & 0xff).toString(16) +
            ((i >> 8) & 0xff).toString(16) +
            (i & 0xff).toString(16);
        hex += '000000';
        return hex.substring(0, 6);
    };

    convertStringToColor = (str) => {
        if (!str) return '';
        str = str.replace(/\s+/g, '');
        return `#${this.intToARGB(this.hashCode(str))}`;
    };

    hexToHSL = (H) => {
        // Convert hex to RGB first
        let r = 0;

        let g = 0;

        let b = 0;
        if (H.length == 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length == 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 100;
        g /= 280;
        b /= 100;
        const cmin = Math.min(r, g, b);

        const cmax = Math.max(r, g, b);

        const delta = cmax - cmin;

        let h = 0;

        if (delta == 0) h = 0;
        else if (cmax == r) h = ((g - b) / delta) % 6;
        else if (cmax == g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0) h += 360;

        return `hsl(${h},${80}%,${60}%)`;
    };

    handleOnCheckVehicles = (id: number) => {
        const equipmentIds = this.state.equipmentIds.slice();
        if (equipmentIds.indexOf(id) >= 0) {
            equipmentIds.splice(equipmentIds.indexOf(id), 1);
        } else {
            equipmentIds.push(id);
        }
        if (equipmentIds.length > 0 && this.props.errors.get('equipmentIds')) {
            this.props.removeMoveReportError('equipmentIds');
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnChangePeriod = (key: string) => (event: Event) => {
        this.changePeriod(key, event.target.value);
        this.props.removeMoveReportError(key);
    };

    handleOnChangeGasPrice = (e: Event) => {
        this.setState({
            gasPrice: e.target.value,
        });
    };

    displayReport = () => {
        const { report } = this.props;
        if (!report) {
            return;
        }
        const entries = report.getIn(['data', 'moveSummary', 'meta', 'headers']);
        const columns = [
            {
                name: 'info',
                title: (
                    <div>
                        <b>{entries.get('info')}</b>
                    </div>
                ),
            },
            {
                name: 'startTime',
                title: (
                    <div>
                        <b>{entries.get('startTime')}</b>
                    </div>
                ),
            },
            {
                name: 'startAddress',
                title: (
                    <div>
                        <b>{entries.get('startAddress')}</b>
                    </div>
                ),
            },
            {
                name: 'startHorometer',
                title: (
                    <div>
                        <b>{entries.get('startHorometer')}</b>
                    </div>
                ),
            },
            {
                name: 'endTime',
                title: (
                    <div>
                        <b>{entries.get('endTime')}</b>
                    </div>
                ),
            },
            {
                name: 'stopAddress',
                title: (
                    <div>
                        <b>{entries.get('stopAddress')}</b>
                    </div>
                ),
            },
            {
                name: 'stopHorometer',
                title: (
                    <div>
                        <b>{entries.get('stopHorometer')}</b>
                    </div>
                ),
            },
            {
                name: 'engineTime',
                title: (
                    <div>
                        <b>{entries.get('engineTime')}</b>
                    </div>
                ),
            },
            {
                name: 'totalTimeIdle',
                title: (
                    <div>
                        <b>{entries.get('totalTimeIdle')}</b>
                    </div>
                ),
            },
            {
                name: 'totalDistance',
                title: (
                    <div>
                        <b>{entries.get('totalDistance')}</b>
                    </div>
                ),
            },
            {
                name: 'stopGeofence',
                title: (
                    <div>
                        <b>{this.props.intl.formatMessage({
                            id: 'containers.Reports.Move.geofence',
                        })}</b>
                    </div>
                ),
            },
        ];
        if (!report.getIn(['data', 'moveSummary', 'displaydata'])) {
            return;
        }
        const temp = report.getIn(['data', 'moveSummary', 'displaydata']).toArray();
        const data = temp.map((e) => Object.fromEntries(e));
        let addressData = data.filter(i => i.rowType === "VEHICLE");
        data.forEach(i => {
            if (i.parentId) {
                let dataEntry = addressData.filter(el => i.parentId.includes(el.id))
                if (i.startAddress === undefined) { i.startAddress = dataEntry[0].startAddress }
            }
        }
        )

        // data = data.map(o=>{
        //     return {...o, startAddress: this.reformatAddress(o.startAddress), stopAddress: this.reformatAddress(o.stopAddress)}
        //  })
        const tableColumnExtensions = [
            { columnName: 'info', width: '285px', wordWrapEnabled: true },
            { columnName: 'startTime', align: 'center', wordWrapEnabled: true },
            { columnName: 'startAddress', width: '185px', align: 'center', wordWrapEnabled: true },
            {
                columnName: 'startHorometer',
                width: '100px',
                align: 'center',
                wordWrapEnabled: true,
            },
            { columnName: 'endTime', align: 'center', wordWrapEnabled: true },
            { columnName: 'stopAddress', width: '185px', align: 'center', wordWrapEnabled: true },
            {
                columnName: 'stopHorometer',
                width: '100px',
                align: 'center',
                wordWrapEnabled: true,
            },
            {
                columnName: 'engineTime',
                width: '100px',
                align: 'center',
                wordWrapEnabled: true,
            },
            { columnName: 'totalTimeIdle', align: 'center' },
            { columnName: 'totalDistance', align: 'center' },
             { columnName: 'stopGeofence', align: 'center' },
        ];
        this.setState({ dynamicData: data })
        this.setState({
            data,
            columns,
            tableColumnExtensions,
            expandedRowIds: [],
        });
    };

    handleGenerateReport = (fetch) => {
        if (fetch) {
            exportData = [];

            const data = { ...this.state };
            data.fileName = this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.dynamicMove',
            });
            let format = data.format;
            data.format = "display";
            this.props.generateMoveReport(data).then(() => {
                this.displayReport();
                format === "pdf" ? this.handleOnExportPDF() : format === "xlsx" ? this.handleOnExportXLS() : null;
            });
        } else {
            this.displayReport();
        }
        if (this.state.reportGeneratedFilterOpen) {
            this.setState({
                reportGeneratedFilterOpen: false,
            });
        }
    };

    changePeriod = (key: string, date: string) => {
        if (this.state.dateLimit) {
            this.setState({
                dateLimit: false,
            });
        };
        if ((key === "periodFrom")
            && (dateFns.differenceInDays(this.state.periodTo, date) > 366)) {
            const newDateTo = dateFns.addDays(date, 366);
            const formattedNewDate = dateFns.format(newDateTo, 'YYYY-MM-DD');

            this.setState({
                dateLimit: true,
                periodTo: formattedNewDate,
                dateLimitPositionLeft: true,
            })
        } else if ((key === "periodTo")
            && (dateFns.differenceInDays(date, this.state.periodFrom) > 366)) {
            const newDateFrom = dateFns.subDays(date, 366);
            const formattedNewDateFrom = dateFns.format(newDateFrom, 'YYYY-MM-DD');
            this.setState({
                dateLimit: true,
                periodFrom: formattedNewDateFrom,
                dateLimitPositionLeft: false,
            })
        }
        let dateToChange = date
        const dateCompare =
            (key == "periodFrom")
                ? dateFns.compareAsc(date, this.state.periodTo)
                : dateFns.compareAsc(this.state.periodFrom, date);
        const todayCompare = dateFns.compareAsc(date, today);
        if (todayCompare === 1) { dateToChange = today }

        if (dateCompare === 1) {
            this.setState(() => ({
                [(key == "periodFrom")
                    ? "periodTo"
                    : "periodFrom"
                ]: dateToChange,
            }))
        };
        this.setState((prevState) => ({
            [key]: dateToChange,
            period: PRESET_PERIODS.custom,
        }));
    };

    handleSetPeriod = (presetPeriod: string) => () => {
        const currentDate = new Date();
        const period = {};
        this.setState({
            dateLimit: false,
        });
        switch (presetPeriod) {
            case PRESET_PERIODS.today: {
                period.to = dateFns.startOfToday();
                period.from = currentDate;
                break;
            }
            case PRESET_PERIODS.yesterday: {
                const yesterday = dateFns.subDays(currentDate, 1);
                period.from = dateFns.startOfDay(yesterday);
                period.to = dateFns.endOfDay(yesterday);
                break;
            }
            case PRESET_PERIODS.lastWeek: {
                const lastWeek = dateFns.subWeeks(currentDate, 1);
                period.from = dateFns.startOfWeek(lastWeek);
                period.to = dateFns.endOfWeek(lastWeek);
                break;
            }
            case PRESET_PERIODS.lastMonth: {
                const lastMonth = dateFns.subMonths(currentDate, 1);
                period.from = dateFns.startOfMonth(lastMonth);
                period.to = dateFns.endOfMonth(lastMonth);
                break;
            }
            case PRESET_PERIODS.thisMonth: {
                period.from = dateFns.startOfMonth(currentDate);
                period.to = dateFns.endOfMonth(currentDate);
                if (dateFns.compareAsc(today, period.to) < 0) {
                    period.to = today
                };
                break;
            }
            case PRESET_PERIODS.thisWeek: {
                period.from = dateFns.startOfWeek(currentDate);
                period.to = dateFns.endOfWeek(currentDate);
                if (dateFns.compareAsc(today, period.to) < 0) {
                    period.to = today
                };
                break;
            }
            default: {
                period.from = '';
                period.to = '';
            }
        }
        if (this.props.errors.get('periodFrom')) {
            this.props.removeMoveReportError('periodFrom');
        }

        if (this.props.errors.get('periodTo')) {
            this.props.removeMoveReportError('periodTo');
        }

        this.setState({
            periodTo: dateFns.format(period.to, 'YYYY-MM-DD'),
            periodFrom: dateFns.format(period.from, 'YYYY-MM-DD'),
            period: presetPeriod,
        });
    };

    handleOnClickModifyReport = () => {
        this.setState((prevState) => ({
            reportGeneratedFilterOpen: !prevState.reportGeneratedFilterOpen,
        })
        );
        exportData = [];
    };
    handleExportReport = (format) => {
        const vehicles_name = []
        exportData = [];
        console.log('dynamicData', this.state.dynamicData);
        const monthData = [];
        const weekData = [];
        const dayData = [];
        this.state.dynamicData.forEach((el, key) => {
            switch (el.rowType) {
                case "VEHICLE":
                    vehicles_name.push({ el, key });
                    break;
                case "DAY":
                    dayData.push({ el, key });
                    break;
                case "WEEK":
                    weekData.push({ el, key });
                    break;
                case "MONTH":
                    monthData.push({ el, key });
                    break;
                default:
                    break;
            }
        })
        //vehicule name init
        exportData.push({
            Regroupement: vehicles_name[0].el.info,
            startTime: "",
            startAddress: "",
            startHonometer: "",
            endTime: "",
            stopAddress: "",
            stopHonometer: "",
            motorTime: "",
            idleTime: "",
            distance: "",
            stopGeofence: ""
        })
        let vehNameKey = vehicles_name[0].key;
        let vehCounter = 0;
        //Month init
        exportData.push({
            Regroupement: monthData[0].el.info,
            startTime: monthData[0].el.startTime,
            startAddress: monthData[0].el.startAddress,
            startHonometer: monthData[0].el.startHorometer,
            endTime: monthData[0].el.endTime,
            stopAddress: monthData[0].el.stopAddress,
            stopHonometer: monthData[0].el.stopHorometer,
            motorTime: format === 'pdf' ? monthData[0].el.utilisationTime : format === 'xls' ? monthData[0].el.utilisationTimeInSec : '',
            idleTime: format === 'pdf' ? monthData[0].el.totalTimeIdle : format === 'xls' ? monthData[0].el.totalTimeIdleInSec : '',
            distance: monthData[0].el.totalDistance,
            stopGeofence:monthData[0].el.stopGeofence
        })
        let monthKey = monthData[0].key;
        let monthCounter = 0;
        //Week Init
        exportData.push({
            Regroupement: weekData[0].el.info,
            startTime: weekData[0].el.startTime,
            startAddress: weekData[0].el.startAddress,
            startHonometer: weekData[0].el.startHorometer,
            endTime: weekData[0].el.endTime,
            stopAddress: weekData[0].el.stopAddress,
            stopHonometer: weekData[0].el.stopHorometer,
            motorTime: format === 'pdf' ? weekData[0].el.utilisationTime : format === 'xls' ? weekData[0].el.utilisationTimeInSec : '',
            idleTime: format === 'pdf' ? weekData[0].el.totalTimeIdle : format === 'xls' ? weekData[0].el.totalTimeIdleInSec : '',
            distance: weekData[0].el.totalDistance,
            stopGeofence:weekData[0].el.stopGeofence
        })
        let weekKey = weekData[0].key;
        let weekCounter = 0;
        //Day Init
        exportData.push({
            Regroupement: dayData[0].el.info,
            startTime: dayData[0].el.startTime,
            startAddress: dayData[0].el.startAddress,
            startHonometer: dayData[0].el.startHorometer,
            endTime: dayData[0].el.endTime,
            stopAddress: dayData[0].el.stopAddress,
            stopHonometer: dayData[0].el.stopHorometer,
            motorTime: format === 'pdf' ? dayData[0].el.utilisationTime : format === 'xls' ? dayData[0].el.utilisationTimeInSec : '',
            idleTime: format === 'pdf' ? dayData[0].el.totalTimeIdle : format === 'xls' ? dayData[0].el.totalTimeIdleInSec : '',
            distance: dayData[0].el.totalDistance,
            stopGeofence:dayData[0].el.stopGeofence
        })
        let dayKey = dayData[0].key;
        let dayCounter = 0;
        this.state.dynamicData.forEach((l, key) => {

            if (key === vehNameKey) {
                vehCounter = vehCounter + 1;
                if(vehicles_name[vehCounter]){
                   vehNameKey = vehicles_name[vehCounter].key;
                exportData.push({
                    Regroupement: vehicles_name[vehCounter].el.info,
                    startTime: "",
                    startAddress: "",
                    startHonometer: "",
                    endTime: "",
                    stopAddress: "",
                    stopHonometer: "",
                    motorTime: "",
                    idleTime: "",
                    distance: "",
                    stopGeofence: ""
                })
            }
            }else if (key === monthKey) {
                monthCounter = monthCounter + 1;
                if(monthData[monthCounter]){
                    monthKey = monthData[monthCounter].key;
                exportData.push({
                    Regroupement: monthData[monthCounter].el.info,
                    startTime: monthData[monthCounter].el.startTime,
                    startAddress: monthData[monthCounter].el.startAddress,
                    startHonometer: monthData[monthCounter].el.startHorometer,
                    endTime: monthData[monthCounter].el.endTime,
                    stopAddress: monthData[monthCounter].el.stopAddress,
                    stopHonometer: monthData[monthCounter].el.stopHorometer,
                    motorTime: format === 'pdf' ? monthData[monthCounter].el.utilisationTime : format === 'xls' ? monthData[monthCounter].el.utilisationTimeInSec : '',
                    idleTime: format === 'pdf' ? monthData[monthCounter].el.totalTimeIdle : format === 'xls' ? monthData[monthCounter].el.totalTimeIdleInSec : '',
                    distance: monthData[monthCounter].el.totalDistance,
                    stopGeofence:monthData[monthCounter].el.stopGeofence
                })
            }
            }else if (key === weekKey) {
                weekCounter = weekCounter + 1;
                if(weekData[weekCounter]){
                     weekKey = weekData[weekCounter].key;
                exportData.push({
                    Regroupement: weekData[weekCounter].el.info,
                    startTime: weekData[weekCounter].el.startTime,
                    startAddress: weekData[weekCounter].el.startAddress,
                    startHonometer: weekData[weekCounter].el.startHorometer,
                    endTime: weekData[weekCounter].el.endTime,
                    stopAddress: weekData[weekCounter].el.stopAddress,
                    stopHonometer: weekData[weekCounter].el.stopHorometer,
                    motorTime: format === 'pdf' ? weekData[weekCounter].el.utilisationTime : format === 'xls' ? weekData[weekCounter].el.utilisationTimeInSec : '',
                    idleTime: format === 'pdf' ? weekData[weekCounter].el.totalTimeIdle : format === 'xls' ? weekData[weekCounter].el.totalTimeIdleInSec : '',
                    distance: weekData[weekCounter].el.totalDistance,
                    stopGeofence:weekData[weekCounter].el.stopGeofence
                })
            }
            } else if (key === dayKey) {
               dayCounter = dayCounter + 1;
                if(dayData[dayCounter]){
                     dayKey = dayData[dayCounter].key;
                exportData.push({
                    Regroupement: dayData[dayCounter].el.info,
                    startTime: dayData[dayCounter].el.startTime,
                    startAddress: dayData[dayCounter].el.startAddress,
                    startHonometer: dayData[dayCounter].el.startHorometer,
                    endTime: dayData[dayCounter].el.endTime,
                    stopAddress: dayData[dayCounter].el.stopAddress,
                    stopHonometer: dayData[dayCounter].el.stopHorometer,
                    motorTime: format === 'pdf' ? dayData[dayCounter].el.utilisationTime : format === 'xls' ? dayData[dayCounter].el.utilisationTimeInSec : '',
                    idleTime: format === 'pdf' ? dayData[dayCounter].el.totalTimeIdle : format === 'xls' ? dayData[dayCounter].el.totalTimeIdleInSec : '',
                    distance: dayData[dayCounter].el.totalDistance,
                    stopGeofence:dayData[dayCounter].el.stopGeofence
                })
            }
           }else if( l.rowType === "MOVE" ){
                exportData.push({
                    Regroupement: l.info,
                    startTime: l.startTime,
                    startAddress: l.startAddress,
                    startHonometer: l.startHorometer,
                    endTime: l.endTime,
                    stopAddress: l.stopAddress,
                    stopHonometer: l.stopHorometer,
                    motorTime: format === 'pdf' ? l.utilisationTime : format === 'xls' ? l.utilisationTimeInSec : '',
                    idleTime: format === 'pdf' ? l.totalTimeIdle : format === 'xls' ? l.totalTimeIdleInSec : '',
                    distance: l.totalDistance,
                    stopGeofence:l.stopGeofence
                })
            }
        })
        // exportData.push({
        //     Regroupement: "Total",
        //     startTime: "",
        //     startAddress: "",
        //     startHonometer: "",
        //     endTime: "",
        //     stopAddress: "",
        //     stopHonometer: "",
        //     motorTime: format === 'pdf' ? l.utilisationTime : l.utilisationTimeInSec,
        //     idleTime: format === 'pdf' ? l.totalTimeIdle : l.totalTimeIdleInSec,
        //     distance: l.totalDistance,
        //     geofenceName: ""
        // })
    }
    getColumns = (format) => {
        const columns = [
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.regroupement',
                }),
                dataIndex: 'Regroupement',
                key: 'Regroupement',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.heuredepart',
                }),
                dataIndex: 'startTime',
                key: 'startTime',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.adressedepart',
                }),
                dataIndex: 'startAddress',
                key: 'startAddress',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.horometerDepart',
                }),
                dataIndex: 'startHonometer',
                key: 'startHonometer',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.heureArrive',
                }),
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.adresseArrive',
                }),
                dataIndex: 'stopAddress',
                key: 'stopAddress',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.horometeArrive',
                }),
                dataIndex: 'stopHonometer',
                key: 'stopHonometer',
            },
            {
                title: format === "pdf" ? this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.tempsMoteur',
                }) : this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.tempsMoteurSec',
                }),
                dataIndex: 'motorTime',
                key: 'motorTime',
            },
            {
                title: format === "pdf" ? this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.tempsRalenti',
                }) : this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.tempsRalentiSec',
                }),
                dataIndex: 'idleTime',
                key: 'idleTime',
            },

            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.distance',
                }),
                dataIndex: 'distance',
                key: 'distance',
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.geofence',
                }),
                dataIndex: 'stopGeofence',
                key: 'stopGeofence',
            }
        ]
        return columns;
    }

    handleOnExportPDF = () => {
        this.handleExportReport("pdf");
        const generateAt = this.props.intl
            .formatMessage({ id: 'components.Reports.move.pdf.generate' })
            .toString();
        const doc = new jsPDF("l")

        let pdf = exportData.map(el => Object.values(el));
        doc.setFontSize(8);
        doc.text(generateAt + new moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 150, 10);
        doc.addImage(Logo, 'png', 2, 2);
        autoTable(doc, {
            headStyles: { fillColor: colors.green63, fontSize: '7', }, head: [this.getColumns('pdf')],

            bodyStyles: { fontSize: '7' }, body: pdf
        })

        doc.save(`${this.props.intl.formatMessage({
            id: 'views.Reports.SideMenu.dynamicMove',
        })}.pdf`);
    };

    handleOnExportXLS = () => {
        this.handleExportReport("xls");
        const excel = new Excel();
        excel
            .addSheet('DynamicMoveReport')
            .addColumns(this.getColumns('xls'))
            .addDataSource(exportData)
            .saveAs(`${this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.dynamicMove',
            })}.xlsx`);
    };

    handleOnSelectAllVehicles = (searchedEquipments) => {
        let equipmentIds;
        if (searchedEquipments.toArray().length > 0) {
            equipmentIds = searchedEquipments.map((equipment) => equipment.get('id')).toArray();
        } else {
            equipmentIds = this.props.equipment.filter(eq => {
                if(eq.toJS().cardNumber){
                 return !eq.toJS().cardNumber.startsWith('B')
                }else{
                    return true
                }
               }).map((equipment) => equipment.get('id')).toArray();
        }
        this.setState({
            equipmentIds,
        });
    };
    handleOnUnselectAllVehicles = () => {
        const equipmentIds = [];
        this.setState({
            equipmentIds,
        });
    };

    onChangeSelect = (key: string) => (value: string) => {
        this.setState({ [key]: value });
    };

    handleAddress = (place) => {
        if (place !== null) {
            this.setState({
                placeName: place.place_name,
                street: place.text,
                position: place.geometry.coordinates,
            });
        }
    };

    handleClearAddress = () => {
        this.setState({
            placeName: '',
            street: '',
            position: [],
        });
    };

    /**
     *
     */
    handleOnChangePeriodFrom = (field) => (event) => {
        if (field == 'periodDateFrom') {
            this.setState({
                [field]: event.target.value,
                periodDateTo: event.target.value,
            });
        } else {
            this.setState({ [field]: event.target.value });
        }
    };

    // To be refactored...
    renderGenerator = () => {
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        return (
            <div>
                <DrivingReportGenerator>
                    <div className={"economieReport"}>
                        <ReportsInputDate
                            startDate={this.state.periodFrom}
                            onChangeStartDate={this.handleOnChangePeriod('periodFrom')}
                            errorsStartDate={this.props.errors.get('periodFrom')}
                            endDate={this.state.periodTo}
                            onChangeEndDate={this.handleOnChangePeriod('periodTo')}
                            errorEndDate={this.props.errors.get('periodTo')}
                            dateLimit={this.state.dateLimit}
                            dateLimitPositionLeft={this.state.dateLimitPositionLeft}
                        >
                        </ReportsInputDate>

                        <DrivingReportGenerator.ColumnRep>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.OutputFormat'}
                                />
                            </InputLabel>
                            <DrivingReportGenerator.SelectContainer>
                                <SelectField
                                    options={[
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Page',
                                            }),
                                            value: OUTPUT_FORMATS.page,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Pdf',
                                            }),
                                            value: OUTPUT_FORMATS.pdf,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Xls',
                                            }),
                                            value: OUTPUT_FORMATS.xls,
                                        },
                                    ]}
                                    value={this.state.format}
                                    borderColor={colors.grayED}
                                    arrowColor={colors.green63}
                                    onChange={this.onChangeSelect('format')}
                                />
                            </DrivingReportGenerator.SelectContainer>
                        </DrivingReportGenerator.ColumnRep>
                    </div>
                    <DrivingReportGenerator.ColumnRep>
                        <DrivingReportGenerator.ButtonContainer>
                            <DrivingReportGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.today)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.Today'}
                                />
                            </DrivingReportGenerator.DateButton>
                            <DrivingReportGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.yesterday)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.Yesterday'}
                                />
                            </DrivingReportGenerator.DateButton>
                            <DrivingReportGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.thisWeek)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.ThisWeek'}
                                />
                            </DrivingReportGenerator.DateButton>
                            <DrivingReportGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.lastWeek)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.LastWeek'}
                                />
                            </DrivingReportGenerator.DateButton>
                            <DrivingReportGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.thisMonth)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.ThisMonth'}
                                />
                            </DrivingReportGenerator.DateButton>
                            <DrivingReportGenerator.DateButton
                                onClick={this.handleSetPeriod(PRESET_PERIODS.lastMonth)}
                            >
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.LastMonth'}
                                />
                            </DrivingReportGenerator.DateButton>
                            <DrivingReportGenerator.SubmitButton
                                onClick={() => this.handleGenerateReport(true)}
                            >
                                <FormattedMessage id={'containers.Reports.Generators.Driving.Generate'} />
                            </DrivingReportGenerator.SubmitButton>
                        </DrivingReportGenerator.ButtonContainer>
                    </DrivingReportGenerator.ColumnRep>
                </DrivingReportGenerator>
                <Split color={colors.splitGrayReports} opacity={0.2} />
                <InputLabel margin={'30px 0 20px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.Vehicles'} />
                </InputLabel>
                {this.props.errors.get('equipmentIds') && (
                    <DrivingReportGenerator.Error>
                        <Error
                            text={this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.Driving.EquipmentIdsError',
                            })}
                        />
                    </DrivingReportGenerator.Error>
                )}
                <ReportVehicleSearch
                    equipment={this.props.equipment.filter(eq => {
            if(eq.toJS().cardNumber){
             return !eq.toJS().cardNumber.startsWith('B')
            }else{
                return true
            }
           })}
                    onCheckVehicles={this.handleOnCheckVehicles}
                    checkedInputs={this.state.equipmentIds}
                    onSelectAllVehicles={this.handleOnSelectAllVehicles}
                    onUnselectAllVehicles={this.handleOnUnselectAllVehicles}
                />
                <Split color={colors.splitGrayReports} opacity={0.2} />

            </div>
        );
    };

    /**
     * Render that shows
     * @param {number} columnSize Decides on how many columns
     * @param {boolean} showExtraColumns show or not total stop time
     * @param {boolean} tableFooter get totalVehicleUtilisationTime && totalVehicleDistance
     * @return {Component} rendered component
     */
    renderFooterAllVehiclesItem = (getTableSize, tableFooter, driversDataFooter) => {
        const columnSize = 4;
        const { report } = this.props;
        const reportFilters = report.getIn(['meta', 'filters']);
        const reportFrom = dateFns.format(reportFilters.get('datetimeStart'), 'YYYY/MM/DD');
        const reportTo = dateFns.format(reportFilters.get('datetimeEnd'), 'YYYY/MM/DD');
        const driverFooterItems = [];

        driversDataFooter &&
            driversDataFooter.sort().forEach((s, index) => {
                driverFooterItems.push(
                    <Dashboard.FooterContainer columns={columnSize}>
                        <Dashboard.FooterItem>
                            <Chip
                                type="driver"
                                color={this.hexToHSL(
                                    this.convertStringToColor(s.get('data').get('driver'))
                                )}
                            >
                                <i className="far fa-id-badge" />
                                &nbsp;
                                {s.get('data').get('driver')}
                            </Chip>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <empty />
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <empty />
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Chip type="personal">
                                {`${Number(s.get('data').get('distancePerso')).toFixed(0)} ${this.props.isImperial() ? 'mi' : 'km'
                                    }`}
                            </Chip>
                            <Chip type="work">
                                {`${Number(s.get('data').get('distanceWork')).toFixed(0)} ${this.props.isImperial() ? 'mi' : 'km'
                                    }`}
                            </Chip>
                        </Dashboard.FooterItem>
                    </Dashboard.FooterContainer>
                );
            });

        return getTableSize <= 1 && driverFooterItems.length == 0 ? (
            <empty />
        ) : (
            <Alert
                style={{
                    color: colors.black,
                    backgroundColor: '#bfbfbf33',
                    borderColor: colors.green63,
                }}
            >
                {getTableSize > 1 ? (
                    <Dashboard.FooterContainer columns={columnSize}>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                <FormattedMessage id={'containers.Reports.Generators.AllTotal'} />{' '}
                                {reportFrom}{' '}
                                <FormattedMessage id={'containers.Reports.Generators.To'} />{' '}
                                {reportTo}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                {tableFooter.get('totalAllVehicleUtilisationTime')}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                {tableFooter.get('totalAllVehicleTimeIdle')}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Chip type="distance">
                                {tableFooter.get('totalAllVehicleDistance')}
                            </Chip>
                            <Chip type="personal">
                                {`${tableFooter.get('totalAllVehicleDistancePerso')}`}
                            </Chip>
                            <Chip type="work">
                                {`${tableFooter.get('totalAllVehicleDistanceWork')}`}
                            </Chip>
                        </Dashboard.FooterItem>
                    </Dashboard.FooterContainer>
                ) : (
                    <empty />
                )}
                {driverFooterItems.length > 0
                    ? [
                        getTableSize > 1 ? (
                            <hr style={{ borderTopColor: colors.green63 }} />
                        ) : (
                            <empty />
                        ),
                        <Dashboard.FooterContainer columns={columnSize}>
                            <Dashboard.FooterItem>
                                <Dashboard.FooterText>
                                    <FormattedMessage id={'views.Reports.Move.details.driver'} />
                                </Dashboard.FooterText>
                            </Dashboard.FooterItem>
                        </Dashboard.FooterContainer>,
                        driverFooterItems,
                    ]
                    : null}
            </Alert>
        );
    };

    numDigits = (x) => {
        return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
    };

    getMonthIds = (data) => {
        this.setState({ reportFilter: 'month' });
        return this.filterBy(data, ['VEHICLE']);
    };

    getWeekIds = (data) => {
        this.setState({ reportFilter: 'week' });

        return this.filterBy(data, ['VEHICLE', 'MONTH']);
    };

    getDayIds = (data) => {
        this.setState({ reportFilter: 'day' });
        return this.filterBy(data, ['VEHICLE', 'MONTH', 'WEEK']);
    };

    filterBy(data, row_type) {
        if (data) {
            const ids = data.filter((row) => row_type.includes(row.rowType)).map((row) => row.id);
            return ids;
        }
    }

    showAllIds = (data) => {
        const allIds = data.map((row) => row.id);
        return allIds;
    };

    setExpandedRowIds = (ids) => {
        this.setState({ expandedRowIds: ids });
    };

    showHideAll = (ids) => {
        if (this.state.expandedRowIds.length > 0) {
            this.setExpandedRowIds([]);
            this.setState({ reportFilter: 'vehicle' });
        } else {
            this.setExpandedRowIds(ids);
            this.setState({ reportFilter: 'default' });
        }
    };

    renderReport = () => {
        const { report } = this.props;
        const reportTitle = report.getIn(['meta', 'title']);
        const reportFilters = report.getIn(['meta', 'filters']);
        const periodetimeStart = reportFilters.get('periodetimeStart');
        const periodetimeEnd = reportFilters.get('periodetimeEnd');
        const reportFrom = dateFns.format(reportFilters.get('datetimeStart'), 'YYYY/MM/DD');
        const reportTo = dateFns.format(reportFilters.get('datetimeEnd'), 'YYYY/MM/DD');
        const reportFilterText = [];
        reportFilterText.date = `${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${reportFrom} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${reportTo}`;
        reportFilterText.time =
            periodetimeStart && periodetimeEnd
                ? `${this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.periode',
                })} ${periodetimeStart} - ${periodetimeEnd}`
                : '';

        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }
        return (
            <Report.Header>
                <DrivingReportGenerator.ReportType style={{ marginTop: 0, marginBottom: 20 }}>
                    {!this.state.reportGeneratedFilterOpen && (
                        <React.Fragment>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={ReportIcon}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportTitle}
                            </Report.TextContainer>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={Calendar}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportFilterText.date}
                                <br />
                                {reportFilterText.time}
                            </Report.TextContainer>
                        </React.Fragment>
                    )}
                </DrivingReportGenerator.ReportType>
                <SlideDown className={'generator'}>
                    {this.state.reportGeneratedFilterOpen ? this.renderGenerator() : null}
                </SlideDown>
                <DrivingReportGenerator.ButtonContainer>
                    <DrivingReportGenerator.DateButton
                        onClick={() => {
                            this.setExpandedRowIds(this.getMonthIds(this.state.data));
                        }}
                    >
                        <FormattedMessage id={'containers.Reports.Generators.months'} />
                    </DrivingReportGenerator.DateButton>
                    <DrivingReportGenerator.DateButton
                        onClick={() => {
                            this.setExpandedRowIds(this.getWeekIds(this.state.data));
                        }}
                    >
                        <FormattedMessage id={'containers.Reports.Generators.weeks'} />
                    </DrivingReportGenerator.DateButton>
                    <DrivingReportGenerator.DateButton
                        onClick={() => {
                            this.setExpandedRowIds(this.getDayIds(this.state.data));
                        }}
                    >
                        <FormattedMessage id={'containers.Reports.Generators.days'} />
                    </DrivingReportGenerator.DateButton>
                    <DrivingReportGenerator.DateButton
                        onClick={() => {
                            this.showHideAll(this.showAllIds(this.state.data));
                        }}
                    >
                        <FormattedMessage
                            id={
                                this.state.expandedRowIds.length === 0
                                    ? 'containers.Reports.Generators.showAll'
                                    : 'containers.Reports.Generators.hideAll'
                            }
                        />
                    </DrivingReportGenerator.DateButton>
                    <Report.ModifyReport onClick={this.handleOnClickModifyReport}>
                        <Report.Icon
                            src={MagnifyingGlass}
                            width={20}
                            height={20}
                            margin={'0 0 0 17px'}
                        />
                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                            <FormattedMessage id={'containers.Reports.Generators.Modify'} />
                        </p>
                    </Report.ModifyReport>
                </DrivingReportGenerator.ButtonContainer>
                <Paper
                    style={{
                        fontSize: '11px',
                        marginBottom: '20px',
                        height: this.state.gridHeight,
                    }}
                >
                    <Grid
                        rows={this.state.data}
                        columns={this.state.columns}
                        getRowId={(row) => row.id}
                        rootComponent={Root}
                    >
                        <TreeDataState
                            expandedRowIds={this.state.expandedRowIds}
                            onExpandedRowIdsChange={this.setExpandedRowIds}
                        />
                        <CustomTreeData getChildRows={getChildRows} />
                        <VirtualTable
                            columnExtensions={this.state.tableColumnExtensions}
                            height="auto"
                        />
                        <TableHeaderRow />
                        <TableTreeColumn for="info" />
                    </Grid>
                </Paper>
                {/* this.renderFooterAllVehiclesItem(
                    this.state.data.length,
                    report.getIn(['data', 'moveSummary', 'total']),
                    report.getIn(['data', 'moveSummary', 'driverdata'])
                )*/}
            </Report.Header>
        );
    };

    render() {
        return (
            <ReportGeneratorContainer>
                <GenerateReportHeader
                    report={this.props.report}
                    title={this.props.intl.formatMessage({
                        id: 'views.Reports.SideMenu.dynamicMove',
                    })}
                    format={this.state.format}
                    onExportPDF={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportPDF : null
                    }
                    onExportXLS={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportXLS : null
                    }
                />
                {/* <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} /> */}
                {this.props.report && this.props.report.size > 0
                    ? this.renderReport()
                    : this.renderGenerator()}
            </ReportGeneratorContainer>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    errors: selectMoveReportGenerationErrors(),
    loading: selectIsLoading(),
    report: selectMoveReport(),
    equipment: selectVehicles(),
    unit: selectUnit(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            validateMoveReportGenerator,
            generateMoveReport,
            removeMoveReportError,
            isImperial,
            resetMoveReport,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
