// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Text = styled.div`
    font-size: 26px;
`;

Text.Badge = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color: ${(props) => props.color};
`;

const Badge = styled.div`
    color: ${(props) => props.textColor || `${colors.black}`};
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    font-family: OpenSans-SemiBold;
`;

Container.Badge = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 5px;
`;

export { Text, Badge, Container };
